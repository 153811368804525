import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from 'semantic-ui-react';
import IcosmPartners from './components/IcosmPartners';
import IcosmGridInfo from './components/IcosmGridInfo';
import Tiles from './components/Tiles';
import IcosmHero from './components/IcosmHero';
import IcosmBanner from './components/IcosmBanner';


const tiles = [
    {
        title: "Get Started Quick Smart",
        desc: "Our Team will guide you through the setup of your clinic from beginning to end, offering the highest clinical standards to ensure full compliance and excellence.",
        bg:'bg-icosm-tertiary'
    },
    {
        title: "Access cutting-edge training",
        desc: "Access cutting-edge training resources internally and through our external partners and aesthetic suppliers.",
        bg:'bg-icosm-primary'
    },
    {
        title: "Script & Purchase with Ease",
        desc: "Deliver patient care effortlessly, with minimal wait times for doctor consultations, seamlessly integrated scripting platforms, and a streamlined shopping experience for product purchases.",
        bg:'bg-icosm-quaternary'
    }
    
]
const grid_info = ["Shop for all your preferred brands in one place.", "Expand your knowledge and clinical skills.",  "Quality doctors, dedicated to helping you grow.", "Support when you need it."]

const Nurse = () => {

	return <div>

		<Helmet>
			<title>Become a Nurse</title>
		</Helmet>

       <Container>
            
            <IcosmHero title="Looking to start your own business as a cosmetic nurse?" desc="InstantCosmetics simplifies the process of launching your own business while also guiding you to acquire the necessary skills and knowledge to effectively manage and thrive in the aesthetics industry." link="" order="1" 
            img='2/nurse.jpg'/>
            
            <Tiles list={tiles} />
            
            <IcosmGridInfo list={grid_info} title="Ways we make building a cosmetic business easier" desc="Starting out in your own cosmetic business shouldn’t be difficult. That’s why we’ve built a solution that ensures best practice for all our nurses." cls="pt-[90px]" />
            
        </Container>

        <IcosmPartners />
        
        <IcosmBanner light img='2/Rectangle320.jpg' title="Get Started Today!" desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started."/>

    </div>
}

export default Nurse;
