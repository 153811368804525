import React from 'react';
import { Modal, Button, Icon, Form, Divider, Message } from 'semantic-ui-react';
import Section from 'views/med/Section';
import UniFieldSUI from 'xAppLib/UniForm/UniField-SUI';
import UniForm from '../UniForm/UniForm';
import script_model from 'models/script_model';
import API_service from 'xAppLib/providers/API_service';
import {useAsyncUpdate} from "../Hooks/useAsync";
import logger from 'xAppLib/libs/logger';


const SPACE_CLASS = 'flex-vertical gap-3';

const MedicalHistoryModal = ({ onClose }) => {
	const updateProfile = useAsyncUpdate(async (d) => {
		const req_data = {
			form_data: d,
			script_type: 'doccons',
			req_type: 'cosm',
			alt_cat: '_cosm',
			cosm_det: { doc: 'InstCons' },
			med: { id: 'DocConsCosm' },
			client_form_valid: true,
			doc: app.user.claims.doc_id,
			usr : app.user.user_det,
			prof: app.user.prof.uid,
			runt: app.runtime,
			dvc: app.dvc,
		};
		const { res, err, sid, cosm_wr_key } = await API_service.api_promise('reqScriptForm', req_data) ?? {};
		if (res === 'ok') {
			app.history.push(`/instcons_doc/${sid}/${cosm_wr_key}`);
			logger.usg_log('np_minor_comp_submitted');
			onClose();
		} else {
			return Promise.reject(err ?? 'Error submitting form');
		}
	});

	return (
		<>
			<Modal.Content scrolling>
				<UniForm
					name="PatientDetails"
					fields={script_model.COMM_USR_FIELDS.map((f) => (['address', 'full_address', 'medicare'].includes(f.name) ? { ...f, valid_not_required: false } : f))}
					onActForm={updateProfile.fn}
				>
					{(values, valids, uf_this, fields) => {
						const args = { values, valids, uf_this };
						return (
							<Form>
								<Section.Header>Patient Details</Section.Header>

								<div className={`${SPACE_CLASS} bg-white p-4 border border-is-gray-30 rounded-md`}>
									<Form.Group widths="equal" className={SPACE_CLASS}>
										<UniFieldSUI display_errors fl={fields.first_name} {...args} />
										<UniFieldSUI display_errors fl={fields.last_name} {...args} />
									</Form.Group>

									<Form.Group widths="equal" className={SPACE_CLASS}>
										<UniFieldSUI display_errors fl={fields.dob} {...args} />
										<UniFieldSUI display_errors fl={fields.sex} {...args} />
									</Form.Group>

									<UniFieldSUI display_errors fl={fields.email} {...args} />

									<UniFieldSUI fl={fields.atsi} {...args} />

									<UniFieldSUI display_errors fl={fields.medicare} {...args} />

									<UniFieldSUI fl={fields.conc_card} {...args} />

									<UniFieldSUI display_errors fl={fields.mobile} {...args} />

									<UniFieldSUI display_errors fl={fields.address} {...args} show_val={true} />
								</div>

								{updateProfile.error && <Message negative header="We couldn't process your request" content="Please check the patient details. If the issue persists please contact support"/>}

								<Divider />

								<div className="flex justify-end gap-4">
									<Button type="button" data-testid="button-cancel" onClick={onClose}>
										<Icon name="undo" /> Cancel
									</Button>
									<Button positive data-testid="button-save" onClick={() => uf_this.actForm()} loading={updateProfile.loading}>
										Submit
									</Button>
								</div>
							</Form>
						);
					}}
				</UniForm>
			</Modal.Content>
		</>
	);
};

export default MedicalHistoryModal;
