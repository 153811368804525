import React, { useState, useEffect } from "react";
import { Form, List, Button, Message } from "semantic-ui-react";
import med_model from "models/med_model";
import org_model from "models/org_model";
import { Link } from "react-router-dom";
import Badge from "views/NUI/Badge";
import Grid from "views/NUI/Grid";
import { ExternalLinkIcon, ArrowRightIcon, ExclamationIcon } from '@heroicons/react/outline'
import Alert from "views/NUI/Alert";
import gtm from "../../../xAppLib/providers/gtm";
import SIcon from "../../NUI/StreamlineIcons/SIcon";

const MedDets = (props) => {
	const { med_data, script_type, req_type } = props

	const det_fields = med_model.det_fields_show_user()
	med_data.repeats = (med_data.repeats??0) + ''
	const details = Object.keys(det_fields).filter( (k, i) => k!='name' && med_data[k] && !(script_type==='medsub' && k==='repeats'))

	if (!med_data || details.length<=1)
			return null
	return (
		<>
		{med_data.alt_name && <p className='text-gray-500 leading-4 mt-2'>
									Generic name: {med_data.alt_name}
							</p>}

		<Grid.Column width={12}>
		{
		details
			.map( (k, i) => <Badge key={'med_data'+i} small className='bg-[#E6E6E6] capitalize mr-2 mb-2 px-4' color='gray'>
						{/*<List.Header style={{textTransform:'capitalize'}}>{det_fields[k]}</List.Header>*/}
						{det_fields[k]}: {med_data?.[k] || ''}
					</Badge>
					)
		}
		</Grid.Column>
		<Grid.Column width={12}>
			{med_data.CMI && <a className="text-is-blue font-[350] underline decoration-1 underline-offset-2" onClick={(e) => { e.preventDefault(); gtm.log('', 'outbound', {link: med_data.CMI}); window.open(med_data.CMI, '_blank').focus(); }} href={med_data.CMI} target='info'>View medication information<SIcon type="external" size="xs"/></a>}
		</Grid.Column>
		

			{/* <List size="big"  className="med-details" relaxed horizontal>
					{
						details
							.map( (k, i) => <List.Item key={'med_data'+i}>
										<b>{det_fields[k]}: </b><span>{med_data && med_data[k] || ''}</span>
									</List.Item>
								 )
					}
					{med_data.CMI && 
						<List.Item >
										<a href={med_data.CMI} target='info'>Medication Info</a>
						</List.Item>
					}
					</List> */}
					
					{['medcons', 'exprcons', 'qualcons'].includes(script_type) &&
						<>
							{/*<div className='my-4'>* If you require a <strong>different size, amount, or repeats</strong>, {!org_model.org_conf_itm(app.runtime.org, 'hide_consults') && <Link className='link' to='/med/DocConsCov'>request a doctor consultation</Link> || 'request a doctor consultation'} instead.</div> */}
							{/*<div className='my-4'>InstantScripts prescriptions allow you to choose any generic/brand of medication when dispensed at a pharmacy - just ask the pharmacist</div>*/}
						</>
					}



		</>
	);
};

export default MedDets;

