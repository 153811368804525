import React from "react";
import { Dropdown } from "semantic-ui-react";
import exprms_model from "../../models/exprms_model";
import Experiment from "../AB/Experiment";
import { useAppContext, useSiteStatus } from "../Hooks";

// e.g. ex3 => Experiment 3
const makeExperimentName = name => name.replace(/^ex(\d+)$/, 'Experiment $1');

// e.g. t1 => VAR 1, t2_1 => VAR 2-1
const makeVariantName = variant => variant.replace(/^t(\d+)(?:_(\d+))?$/, (_, a, b) => `VAR ${a}${b ? `-${b}` : ''}`);

function overrideExperiment(experiments, id, variant) {
    app.trigger(
        app.events.CONTEXT,
        {exprms: exprms_model.calc_exprms(experiments, {[id]: variant})}
    );
    if (exprms_model.need_upd) {
        exprms_model.save_all();
    }
}

function VariantSelector({text, value, variants, onClick}) {
    return (
        <Dropdown text={text}>
            <Dropdown.Menu>
                {Object.keys(variants).map((v) => (
                    <Dropdown.Item key={v} onClick={() => onClick(v)} active={v === value}>
                        {makeVariantName(v)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export function DebugExperiments() {
    const all = useSiteStatus('exprms');
    const selected = useAppContext('exprms');

    return (
        <div>
            <div className={`text-sm ${app.dev_env ? "text-white" : "hidden"}`}>
                {JSON.stringify(selected)}
            </div>

            {(app.dev_env || app.acl?.is_admin || app.acl?.is_supp || app.acl?.is_mark) && Object.entries(all || {}).map(([id, variants]) => (
                <div key={id}>
                    {makeExperimentName(id)}: &nbsp;
                    <Experiment id={id} quiet>
                        {Object.keys(variants).map((v, i) => (
                            <Experiment.Variant variant={v} key={v} defaultVariant={i === 0}>
                                <VariantSelector
                                    text={makeVariantName(selected[id]?.n || '?')}
                                    variants={variants}
                                    value={selected[id]?.n}
                                    onClick={(v) => overrideExperiment(all, id, v)}
                                />
                            </Experiment.Variant>
                        ))}
                    </Experiment>
                </div>
            ))}
        </div>
    );
}