import React, {useMemo} from "react";
import script_model from "models/script_model";
import UniFormMed from "views/med/UniFormMed";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import Photos from "xAppLib/Photos/Photos";
import user_model from "models/user_model";

const CosmTreat = (props) => {
	const {docs_data_db, Section } = props;

	const fields = useMemo(() => {
		const fields = [...script_model.COMM_TRTM_FIELDS, ...(!(app.user.claims.doc && app.user.claims.doc_id) && !app.settings.is_COSM_INSTCONS_NEED && script_model.COMM_COSM_DOC_FIELDS || [] )]

		const cosm_doc = fields.filter(f=>f.name=='cosm_doc').pop()
		if (cosm_doc && docs_data_db)
			cosm_doc.options = [
				{c:'No',n:'No'},
				...docs_data_db.map(d=>({c:d.m,n:d.name}))
			]

		return fields;
	}, [docs_data_db,app.settings.is_COSM_INSTCONS_NEED]);

	return (
		<UniFormMed
			{...props}
			section="cosmtreat"
			fields={fields}
		>
			{(values, valids, uf_this, fields) => {
				const args = {values, valids, uf_this}
				return <Section>
					<Section.Header>Treatment Details</Section.Header>
					<Section.Content>
						<UniFieldSUI fl={fields.cosm_qua} {...args} />
						<UniFieldSUI fl={fields.cosm_loc} {...args} />
						<UniFieldSUI fl={fields.cosm_note} {...args} />
						{!app.settings.is_COSM_INSTCONS_ENABLED && <UniFieldSUI fl={fields.cosm_doc} {...args} /> }

						<hr/>
						<h3>Cost</h3>
						<p className="text-sm text-is-black-50">
							Please be advised that cosmetic procedures are not covered by Medicare
						</p>
						<UniFieldSUI fl={fields.cosm_total_cost} {...args} />
						<UniFieldSUI fl={fields.cosm_payment} {...args} />
						<UniFieldSUI fl={fields.cosm_refund} {...args} />
						<UniFieldSUI fl={fields.cosm_follow_up_payment} {...args} />
						<UniFieldSUI fl={fields.cosm_further_costs} {...args} />

						{user_model.check_access('phts_add') &&
							<React.Fragment>
								<hr/>
								<h3>Take treatment photos (you can also do it later)</h3>

								<Photos
									/*
                                        <Photos/> doesn't handle cDU, and values['phts'] starts off undefined, so
                                        repopulating the form on subsequent re-renders doesn't work. This is a quick
                                        hack to force a re-render with new data to avoid implementing cDU which might
                                        have other implications.
                                    */
									key={!!values['phts']}
									show_disclaim
									inline
									size='calc(100% / 3 - 2em)'
									data = {values['phts'] || []}
									onChange = { ps => uf_this.handleInputChange(null, {name:'phts', value:ps}) }
								/>
							</React.Fragment>
						}
					</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);
};

export default CosmTreat;

