/* eslint-env node */

//	------------------------	------------------------	------------------------
//	Description: Main script item
//  eRx SCRIPT -> items[s] -> content -> clinician, patient, prescribed item
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

//const erx_item_model =  require('./erx_item_model')
const erx_item_model =  require('./erx_item_model')

const get_set =         require('../../helpers/erx/get_set_function')

const erx_enums =   require('../erx/resources/erx_enums')
const erx_formats = require('../erx/resources/erx_formats')
const erx_props =   require('../erx/resources/erx_props')

const moment =          require('moment-timezone')

const stopwatch =       require('../../helpers/stopwatch')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DEFAULTS = { // TODOOOOOO enum and reference these
    
    $:                              { "xmlns": "http://erx.com.au/integration/v1", "xmlns:i": "http://www.w3.org/2001/XMLSchema-instance" }, // xml header garbage
    //SCID:                           "REPLACED_LATER",
    //CreatedDate:                    "2020-06-11T00:04:29+10:00", //Just leave this to come from the blank
    //OriginalSCID:                   {"$": {"i:nil": "true"}},
    //OriginalGUID:                   {"$": {"i:nil": "true"}},
    //NotificationConsentFlag:        "true",
    //Status:                         "Valid",
    //PrescriberToNotify:             "1QPRJ",
    
}

//	------------------------	------------------------	------------------------

const raw_prop_id =     '_RAW'

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class erx_script_model {

    constructor(script_data, run_validation = true) {

        this._script_data = {}

        this.setup_props()

        if (script_data) {
            if (run_validation) this.script_data = script_data
            else this._script_data = script_data
        }
        else this.set_default()


    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get script_data() { return this._script_data }
    set script_data(val) {
        // Validate here
        this._script_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------

    get item_count() {
    
        if (!this['Item' + raw_prop_id]) return 0
        return this['Item' + raw_prop_id].length

    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------

    set_blank() { // You probably don't want to call this
        
        this.clear()

        erx_props.SCRIPT_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })

        this.CreatedDate = moment().format()//.format('YYYY-MM-DD')
    
    }

    //	------------------------	------------------------	------------------------

    set_default() {

        this.set_blank()

        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))

        this.CreatedDate = moment().format()//format('YYYY-MM-DD')

        this.add_item()

        this.get_item().set_default()

    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {

        let s_w = new stopwatch()

        let result = {res: 'ok', item_res: []}
        // Fix self

        // remove unexpected objects... this might not be ideal
        this.remove_ignored_and_non_prop()

        // Fix children
        let items = this.get_items()
        items.forEach((item, i) => {
            const fix_res = item.fix_data(remove_errors)
            result.item_res.push(fix_res)
            if (fix_res.res != 'ok'){
                result.res = 'err'
                if (!result.err) result.err = ''
                result.err += 'Error fixing child item ' + (i + 1) + '. Error: ' + fix_res.err + '\n'
                if (!result.errs) result.errs = []
                result.errs.push(fix_res.err)
                if (!result.fix_res) result.fix_res = []
                result.fix_res.push(fix_res)
            }
        })

        result.ms = s_w.duration()
        return result

    }

    //	------------------------	------------------------	------------------------

    remove_ignored_and_non_prop() {

        // The easiest way to do this is to simply remake the object from base
        let blank_script = new erx_script_model()
        blank_script.clear() //remove any default or other data - very important

        erx_props.SCRIPT_PROPS.forEach(item => {
            if (!erx_props.SCRIPT_PROPS_IGNORE.includes(item.name) && typeof this[item.name] != 'undefined' && this[item.name] != null){
                //log(item)
                blank_script[item.name] = this[item.name]
            }  
        })

        this._script_data = JSON.parse(JSON.stringify(blank_script.inner_data))

    }

    //	------------------------	------------------------	------------------------

    check_valid(params = {}) {

        let s_w = new stopwatch()
        let result = {res: 'ok'}

        try {
            //First do our own checks
            for (let i = 1; i < this.item_count + 1; i++){
                const c_item = this.get_item(i) // We could probably check the sequence here? maybe later
                const c_item_valid_res = c_item.check_valid(params)
                if (c_item_valid_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), 'Item number ' + i + ' is invalid: ' + c_item_valid_res.err], c_item_valid_res}
            }

        } catch (e) {
            return {...result, res: 'err', err: [...(result.err || []), 'Unknown error while checking script validity: ' + e], e}
        }

        result.ms = s_w.duration()
        return result

    }

    //	------------------------	------------------------	------------------------

    compare(target_script, subset = false, item_sequencese = []) { // Compares this script's items to the target scripts items. Subset means only compare the props on the source

        let s_w = new stopwatch()
        let result = {res: 'ok'}
        let diff = []

        //log("Checking script props match")
        // Check props
        erx_props.SCRIPT_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_script && (typeof target_script[item.name] != undefined) && (target_script[item.name]!= '') && target_script[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Script property ' + item.name, source, target}) 
            } 
        })

        //log("Checking script items match")
        // Check items on source
        let source_items = []
        if (item_sequencese == []) source_items = this.get_items() //compare all items    
        else source_items = this.get_specific_items(item_sequencese)

        source_items.forEach(item => {
            const target_item = target_script && target_script.get_item && target_script.get_item(item.Sequence) || null
            let compare_item_res = {}
            if (item && item.compare) compare_item_res = item.compare(target_item, subset)
            if (compare_item_res.res != 'ok') diff.push({name: 'Source script item ' + item.Sequence + '. Error: ' + compare_item_res.err, compare_item_res, source: item, target: target_item})
        })
        
        // Check items on target
        if (!subset) {
            let target_items = target_script && target_script.get_items && target_script.get_items() || []
            target_items.forEach(item => {
                const source_item = this.get_item(item.Sequence)
                let compare_item_res = {}
                if (item && item.compare) compare_item_res = item.compare(source_item, subset)
                if (compare_item_res.res != 'ok') diff.push({ name: 'Target script item ' + item.Sequence + '. Error: ' + compare_item_res.err, compare_item_res, source: source_item, target: item })
            })
        }

        // Return
        if (diff.length != 0){ result = {res: 'err', err: 'Scripts do not match by ' + diff.length + ' field(s)/item(s). Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff} }
        result.ms = s_w.duration()
        return result

    }

    //	------------------------	------------------------	------------------------

    get_item(item_number = 1) { // Returns the item from the script, specified by the item_number, default item number 1. TODO - in future we could cache the created object

        if (!item_number) return null// {res:'err', err:'No item number'}
        if (typeof item_number != 'number')  return null//{res:'err', err:'No item number not number'}
        if (item_number < 1)  return null//{res:'err', err:'Item number < 1'}
        if (!this['Item' + raw_prop_id]) return null//{res:'err', err:'No items'}

        try {
            for (let i = 0; i < this['Item' + raw_prop_id].length; i++){
                if (this['Item' + raw_prop_id][i] && 
                    this['Item' + raw_prop_id][i].Sequence &&
                    (this['Item' + raw_prop_id][i].Sequence[0] == item_number || Number(this['Item' + raw_prop_id][i].Sequence[0]) == item_number)){ // We can't assume the script order is correct. Also coerse string into number for safety
                        return new erx_item_model(this['Item' + raw_prop_id][i])
                }
            }
            return null//{res:'err', err:'Item not found'}

        } catch(e){
            return null//{res: 'err', err: 'Unknown error: ' + e, e}
        }

    }

    //	------------------------	------------------------	------------------------

    get_last_item() {
        if (!this['Item' + raw_prop_id] || this['Item' + raw_prop_id].length == 0) return null//{res:'err', err:'No items'}
        return new erx_item_model(this['Item' + raw_prop_id][this['Item' + raw_prop_id].length - 1])
    }

    //	------------------------	------------------------	------------------------

    get_items() { // Returns an array of all the items
        if (!this.script_data || !this['Item' + raw_prop_id]) return []
        try {
            let ret = []
            for (let i = 0; i < this['Item' + raw_prop_id].length; i++){
                ret.push(new erx_item_model(this['Item' + raw_prop_id][i]))
            }
            return ret
        } catch (e) {
            throw {err: 'Unable to get items: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------

    get_specific_items(sequence_numbers) { // Returns an array of the item sequence numbers specified
        if (!this.script_data || !this['Item' + raw_prop_id]) return []
        try {
            let ret = []
            for (let i = 0; i < sequence_numbers.length; i++){
                ret.push( this.get_item(sequence_numbers[i]))
            }
            return ret
        } catch (e) {
            throw {err: 'Unable to get items: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------

    add_item(item_data = null) { //Adds an item to the item list.

        let new_item = new erx_item_model(item_data)

        if (!this['Item' + raw_prop_id] || !Array.isArray(this['Item' + raw_prop_id])) this['Item' + raw_prop_id] = []

        new_item.Sequence = (this['Item' + raw_prop_id].length + 1).toString()

        this['Item' + raw_prop_id].push(new_item.item_data)

    }

    //	------------------------	------------------------	------------------------

    remove_all_items() {

        this['Item' + raw_prop_id] = []

    }

    //	------------------------	------------------------	------------------------
    
    remove_item_at(index = this['Item' + raw_prop_id].length - 1, number_of_items = 1) {//removes an item to the item list. You probably don't want to call this

        if (this['Item' + raw_prop_id].length < 1) return
        this['Item' + raw_prop_id].splice(index, number_of_items)

    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.SCRIPT_PROPS.forEach(p => {
        if (p.name == 'Item') {
            let t_prop = JSON.parse(JSON.stringify(p))
            t_prop.name += raw_prop_id
            this.define_prop(t_prop)
            Object.defineProperty(this, p.name, { get() { return this.get_items() }, set(val) {
                if (!Array.isArray(val)) val = [val]
                this.remove_all_items()
                val.forEach(i => i && i.inner_data && this.add_item(i.inner_data))
            }})
        } else this.define_prop(p)
    })}

    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.script_data }
    set inner_data(val) { this.script_data = val }

    //	------------------------	------------------------	------------------------

    get SCID() { throw { override_msg: 'get SCID' }}
    set SCID(val) { throw { override_msg: 'set SCID' }}

    get GUID() { throw { override_msg: 'get GUID' }}
    set GUID(val) { throw { override_msg: 'set GUID' }}

    get CreatedDate() { throw { override_msg: 'get CreatedDate' }}
    set CreatedDate(val) { throw { override_msg: 'set CreatedDate' }}

    get OriginalSCID() { throw { override_msg: 'get OriginalSCID' }}
    set OriginalSCID(val) { throw { override_msg: 'set OriginalSCID' }}

    get NotificationConsentFlag() { throw { override_msg: 'get NotificationConsentFlag' }}
    set NotificationConsentFlag(val) { throw { override_msg: 'set NotificationConsentFlag' }}

    get Status() { throw { override_msg: 'get Status' }}
    set Status(val) { throw { override_msg: 'set Status' }}

    get PrescriberToNotify() { throw { override_msg: 'get PrescriberToNotify' }}
    set PrescriberToNotify(val) { throw { override_msg: 'set PrescriberToNotify' }}

    get Item() { return this.get_items() }
    set Item(val) { throw { override_msg: 'set Item' }}

    get SourceType() { throw { override_msg: 'get SourceType' }}
    set SourceType(val) { throw { override_msg: 'set SourceType' }}

    get ConsolidatedSCIDs() { throw { override_msg: 'get ConsolidatedSCIDs' }}
    set ConsolidatedSCIDs(val) { throw { override_msg: 'set ConsolidatedSCIDs' }}

    get ViewConsent() { throw { override_msg: 'get ViewConsent' }}
    set ViewConsent(val) { throw { override_msg: 'set ViewConsent' }}

    //get PatientRef() { throw { override_msg: 'get PatientRef' }}
    //set PatientRef(val) { throw { override_msg: 'set PatientRef' }}

    get MedchartMetadata() { throw { override_msg: 'get MedchartMetadata' }}
    set MedchartMetadata(val) { throw { override_msg: 'set MedchartMetadata' }}

    get Tags() { throw { override_msg: 'get Tags' }}
    set Tags(val) { throw { override_msg: 'set Tags' }}
    
    get OperationResult() { throw { override_msg: 'get OperationResult' }}
    set OperationResult(val) { throw { override_msg: 'set OperationResult' }}

}