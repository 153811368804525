import React from 'react';



const FeedbackText = ({danger,warning,children}) => (
	<p className={['text',danger&&'red' || warning && 'orange' || 'green'].join(' ')} style={{whiteSpace:'pre-line'}}>{children}</p>
);


export default FeedbackText;
