import BigHeaderWithNavbar from "./editorTools/BigHeaderWithNavbar/BigHeaderWithNavbar";
import EditorCards from "./editorTools/Cards/EditorCards";
import InfoSegment from "./editorTools/InfoSegment/InfoSegment";
import CreditBlock from "./editorTools/CreditBlock/CreditBlock";
import FullHelpTile from "./editorTools/FullHelpTile/FullHelpTile";
import ServicesLinks from "xAppLib/UIelems/MainTiles";

export const TOOLS = {
    BigHeaderWithNavbar,
    EditorCards,
    InfoSegment,
    FullHelpTile,
    ServicesLinks,
    CreditBlock,
}