import React, { useEffect, useRef, useState } from 'react';
import { useDisclosure } from '../../xAppLib/Hooks/useDisclosure';
import { Button, Icon, Loader, Modal, ModalContent, ModalHeader, Popup } from 'semantic-ui-react';
import ErrorScreen from './ErrorScreen';
import myhr_model from '../../models/myhr_model';

const UploadDocumentButton = ({ uid, doc, sid, onRefresh, granted  }) => {
	const contextRef = useRef()
	const { isOpen,  onOpen, onClose } = useDisclosure();
	const [up_res,setRes] = useState({})
	const [loading,setLoading] = useState(false)
	const [d,setD] = useState(doc)
	const uploaded = !!d

	if (!sid)
		return null

	const version = d?.version||0
	const new_version = version+1

	return (
		<>
		<span ref={contextRef} ><Button size='tiny' basic color={uploaded?"green":'grey'} onClick={async ()=>{
			if (loading)
				return false
			const confirm_res = await app.confirm('Upload document',<>{!granted?<><Icon name='warning'/> <strong>Patient has requested not to upload.</strong><br/></>:''}Are you sure you want to {new_version>1?'supersede':'upload'} this document to My Health Record as version {new_version}?</>)
			if(confirm_res != 'yes')
				return false
			setLoading(true)
			const resp = await myhr_model.upload_document({sid}).catch(e=>({msg:e.message||e})) || {msg:'Unable to upload document'}
			console.log({resp})
			setLoading(false)
			setRes(resp)
			if (!resp.ok) {
				onOpen()
			} else {
				setD(resp.doc)
			}
			onRefresh?.()
			
		}}><Icon loading={loading} name={loading ? 'spinner' : uploaded ? 'check' : 'upload'} /> {uploaded ?  `Document Uploaded V${version}` : "Upload Document"}</Button></span>
		
		<Popup
			position='bottom right'
			open={isOpen}
			context={contextRef}
			onClose={onClose}
			content={ <ErrorScreen message={up_res.message || up_res.msg} errors={up_res.errors}/> }
		/>
		
		</>
		
	);
};


export default UploadDocumentButton;
