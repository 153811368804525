import React from "react";
import { Button, FormField, TextArea } from "semantic-ui-react";

export const HELP_INIT = {
    title: "A question?",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: "/",
    text: "Home",
};

const HelpTile = ({ title, content, text, link, editMode }) =>
    editMode ? (
        <>
            <FormField>
                <label>Title</label>
                <input
                    name="title"
                    placeholder="Enter title"
                    defaultValue={title}
                />
            </FormField>
            <FormField>
                <label>Content</label>
                <TextArea
                    name="content"
                    placeholder="Enter content"
                    defaultValue={content}
                />
            </FormField>
            <FormField>
                <label>Button Text</label>
                <input
                    name="text"
                    placeholder="Enter button text"
                    defaultValue={text}
                />
                <label>Button Link</label>
                <input
                    name="link"
                    placeholder="Enter button link"
                    defaultValue={link}
                />
            </FormField>
        </>
    ) : (
        <div
            style={{ borderRadius: "32px", background: "#E2F3FF" }}
            className="p-12"
        >
            <h2 className="section-title font-bold md:font-normal">{title}</h2>
            <p>{content}</p>
            {text && (
                <Button primary size="large" as="a" href={link} target="blank">
                    {text}
                </Button>
            )}
        </div>
    );

export default HelpTile;
