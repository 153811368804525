import React, { useState } from 'react'
import { Form, Icon } from 'semantic-ui-react'

export default function PasswordWithValidator({ validate = true, label = '', style = {}, ...props }) {
    const [see, setSee] = useState(false);
    const [vals, setVals] = useState({
        len: false,
        l: false,
        n: false
    })
    return <div style={{ fontSize: '1rem', ...style }}>
        <Form.Input {...props} label={label} data-testid='input-password' icon={<Icon name={see ? 'eye slash' : 'eye'} onClick={() => setSee(!see)} link />} onChange={s => {
            setVals({
                len: s.target.value.length > 7,
                l: /[\w]/g.test(s.target.value),
                n: /[\d]/g.test(s.target.value)
            });
            props.onChange(s);
        }} type={see ? 'text' : 'password'} />
        {validate && <div className='text-sm mt-4'>
            To keep your account safe, your password must comply with our Password Policy. Your password should:
            <ul className='mt-2'>
                <li className='mb-1'>{vals.len ? <Icon name="check circle outline" color="green" /> : <Icon name="circle outline" />}be at least 8 characters long</li>
                <li className='mb-1'>{vals.l ? <Icon name="check circle outline" color="green" /> : <Icon name="circle outline" />}have a letter</li>
                <li>{vals.n ? <Icon name="check circle outline" color="green" /> : <Icon name="circle outline" />}have a number</li>
            </ul>
        </div>}
    </div>
}