/* eslint-env node */

//	------------------------	------------------------	------------------------
//	Description: Functions to help generate class getter and setter methods from
//  props and other data, based on eRx schema.
//	------------------------	------------------------	------------------------

module.exports = {

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    make_get_function: (obj, prop) => { return () => { return obj.get_object_value(obj.inner_data, prop.path) } },
    make_set_function: (obj, prop) => { return (val) => { return obj.set_object_value(obj.inner_data, prop.path, val) } },

    //	------------------------	------------------------	------------------------

    get_object_value: (obj, path) => {
        if (!obj || !path || !path.length || path.length < 1) return undefined
        let c_obj = obj //might not need this
        for (let i = 0; i < path.length; i++) {
            if (typeof c_obj == 'undefined' || c_obj == null) return undefined
            c_obj = c_obj[path[i].replace(/[\[\]]/g, "")] //remove array indicators
        }
        return c_obj
    },

    //	------------------------	------------------------	------------------------

    set_object_value: (obj, path, val) => { // Asssigns the value val to the obj in the path specified. Will create objects as needed
        if (!obj || !path || !path.length || path.length < 1) throw { err: 'Unable to set: bad obj or path', obj, path, val }
        if (typeof val === 'undefined') throw { err: 'Cannot set value to undefined for ' + path.join('.'), obj, path, val }
        let c_obj = obj, p_obj = obj, n_obj, n_path, p_path
        for (let i = 0; i < path.length; i++) {
            n_path = path[i].replace(/[\[\]]/g, "")
            n_obj = c_obj[n_path]
            if (typeof n_obj == 'undefined') { //We need to make the key
                if (n_path != path[i]) { //it wants an array :/ make one
                    if (i == 0) throw 'Cannot change base object to array'
                    p_obj[p_path] = []
                    for (let j = 0; j < parseInt(n_path) + 1; j++) p_obj[p_path].push({})
                    c_obj = p_obj[p_path]
                } else c_obj[n_path] = {}
            }
            p_obj = c_obj
            p_path = n_path
            c_obj = c_obj[n_path]
        }
        p_obj[n_path] = JSON.parse(JSON.stringify(val)) //Finally, assign the value. This is one layer up the tree //c_obj = val wont work - stringify for safety, hope this is okay
    }

}