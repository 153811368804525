import React, {Component} from "react";
import { Link } from "react-router-dom";
import {Modal, Button, Icon, Container, Message, Form} from "semantic-ui-react";

import UniForm from "../UniForm/UniForm";
import UniFormSUI from "../UniForm/UniForm-SUI";
import contact_model from "models/contact_model";

export default class ContactForm extends Component {

	state = {sending:false, sent_res:null, values:{}};

	// 		--------------------------------		--------------------------------		---------

	render_ok() {
		return <React.Fragment>
					<Message
						positive
						header='Success'
						content='Your contact request has been sent'
					  />
				  	<Button fluid color="green" size='large' onClick={this.close} content="Close" />
			</React.Fragment>
	}
	
	render_form() {
		const {sending, values, contact_chapha} = this.state;
		const {onClose} = this.props;

		const show_fields = contact_chapha && contact_model.DET_FIELDS_CHAPHA || contact_model.DET_FIELDS

		return <UniForm

			onActForm = {  d => contact_model
									.add_record( 
													'',
													d,
													{	timestamp: true		},
													() => this.setState({sent_res: 'ok'}),
													() => this.setState({sent_res: 'err'})
												)
						}

			onUpdate = { v => this.setState({ contact_chapha: v?.type=="Change script pickup pharmacy" }) }

			render_children = {true}
			debug = {false}

			fields = { [
							{
								type: "hidden",
								name: "cont_form",
								value: 'popup-contact'
							},

							{
								type: "hidden",
								name: "curr_pg",
								value: document.location.href
							},

							...show_fields,
					] }

			defaultValues={{
							name: app.user?.user_det?.displayName || '',
							email: app.user?.user_det?.email || '',
							phone: app.user?.prof?.mobile || '',
						}}
		>

			{(values, valids, uf_this) => {
				return <UniFormSUI
							fields={show_fields}
							values={values}
							valids={valids}
							uf_this={uf_this}
							loading={sending}
							show="fields_form"
							size={'large'}
							btns={
								contact_chapha &&
									<Button 
										fluid color="yellow" size='large' 
										content="Ask to change pharmacy" 
										onClick={d => { app.history.push('/history'); onClose() }} 
										/>
								||
									<Button 
										fluid color="green" size='large' 
										content="Submit" 
										onClick={d => uf_this.actForm(d)} 
										/>
								}
						/>
			}}
		</UniForm>
	}

	// 		--------------------------------		--------------------------------		---------

	render() {
		const {sent_res} = this.state;

		return	sent_res=='ok' &&
					<Message
						success
						header={`Your message was sent successfully. An ${app.runtime.name} customer support representative will respond to you.`}
					/>
				|| sent_res=='err' &&
					<Message
						negative
						header='Error sending your message'
						content='Please try again'
					/>
				|| this.render_form()
	}
}
