import React from 'react';
import { Button } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { useAppHistory } from 'xAppLib/Hooks';


const BackButton = ({parent, btn_text}) => {
    const location = useLocation()
    const hist = useAppHistory()

    const l = hist?.length || 0
    const is_home = location.pathname=='/'
    return <Button 
                disabled={l==0&&is_home}
                type="button"
                basic
                onClick={_=>{
                    if (l)
                        app.history.goBack()
                    else {
                        app.history.push(parent || '/', { replace: true })
                    }
                        
                }}> {btn_text||'Back'}</Button> ;
}
 
export default BackButton;