import React, { Component } from 'react';
import {
		Icon,
		Button,
		Popup,
		List,
		Table,
	} from 'semantic-ui-react'
import user_model from 'models/user_model'

const roles = user_model.ROLE_LIST

class ClaimsEditor extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			claims:this.getBaseClaims(this.props.claims, this.props.inline, this.props.onSetClaims),
			isOpen:false
		};
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.claims != this.props.claims) {
			this.setState({claims:this.getBaseClaims(this.props.claims)})
		}
	}
	
	getBaseClaims(received_claims = {}, inline, onSetClaims) {
		const claims = {}
		Object.keys(roles).forEach(k=>{claims[roles[k].c]= received_claims[roles[k].c] || false})
		inline && onSetClaims(claims)
		return claims
	}
	handleOpen = () => {
		this.setState({ isOpen: true })
	}

	handleClose = () => {
		this.setState({ isOpen: false })
	}
	render() {
		const { onSetClaims, inline } = this.props
		const { claims } = this.state
		
		if (inline) 
			return this.render_claims()
			
		return (
	
		   <Popup 
				trigger={<Button compact style={{maxWidth:'300px',overflow:'hidden',textOverflow:'ellipsis'}}><Icon name='lock'/> {user_model.role_name(claims) || '-'}</Button>} 
				on='click'
				wide='very'
				open={this.state.isOpen}
				onClose={this.handleClose}
				onOpen={this.handleOpen}
				>	
				{ this.render_claims()}

			</Popup>
		);
	}
	
	updateClaim = (k,val) => {
		const { onSetClaims, inline } = this.props
		const claims = {...this.state.claims,[k]:!val}
		this.setState({claims})
		inline && onSetClaims(claims)
		// inline && onSetClaims(JSON.parse(JSON.stringify(claims, (k,v) => !v?undefined:v)))
	}
	
	render_claims() {
		const { onSetClaims, inline } = this.props
		const { claims } = this.state
		
		return <Table basic='very' compact size='small'>
			<Table.Header>
				<Table.Row>
					{Object.keys(roles).map(k=>
						 user_model.check_any(roles[k].p) && //Only display the role if we have permission to. Should check on BE too
						 <Table.Cell key={'h_'+roles[k].c}>{roles[k].n}</Table.Cell>)
					}
					{!inline && <Table.Cell></Table.Cell>}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row>
					{Object.keys(roles).map(k=>{
						const val = claims[roles[k].c]
						return user_model.check_any(roles[k].p) && 
						<Table.Cell key={'r_'+roles[k].c}>
									<Button
										type="button"
										basic
										icon={val?'check':'minus'}
										color={val?'red':'green'}
										onClick={ _=>this.updateClaim(roles[k].c,val) }
								 />
						 </Table.Cell>})
					 }
					 {!inline && <Table.Cell><Button type="button" primary onClick={ _=> {
							this.handleClose();
							onSetClaims(claims)
							// onSetClaims(JSON.parse(JSON.stringify(claims, (k,v) => !v?undefined:v)))
						}}>Confirm</Button></Table.Cell>}
				</Table.Row>
			</Table.Body>
		</Table>
	}

}

export default ClaimsEditor;
