import moment from 'moment';
import React, { useState } from 'react';
import { Divider, Icon, Table, TableHeader, TableHeaderCell, TableCell, TableBody, TableRow, Button } from 'semantic-ui-react';
import { interprateIndigenousStatusCode } from '../../helpers/myhr';

const HealthRecordOverviewRenderer = ({ data, changeView }) => {
	const dataObj = (data && JSON.parse(atob(data))) || '';
	const { healthRecordOverviewResponse } = dataObj || {};
	const { newDocuments, otherLinks, recentDocuments, sharedHealthSummary, viewMetadata } = healthRecordOverviewResponse || {};
	const individualProfile = { ...viewMetadata?.individualProfile, ...viewMetadata?.individualProfile?.individual };
	const onOpenDocument = (document) => changeView(undefined,{
			name: 'view_type',
			value: 'All Documents',
			document
		})
		
	const sections = [
		<DocumentsSinceSHS {...{ newDocuments, key: 1, onOpenDocument }} />,
		<SharedHealthSummary {...{ sharedHealthSummary, key: 2 }} />,
		<ViewsLinks {...{ otherLinks, key: 3, changeView }} />,
		<RecentDocuments {...{ recentDocuments, key: 4, onOpenDocument }} />,
	];

	return (
		<>
			<Divider className='!m-0' />

			<div className='grid grid-cols-2'>
				<p className='pt-4'>
					This is not a complete view of the individual’s health information. For more information about the individual’s health
					record or data, please consult the individual or other healthcare professionals as needed.
				</p>
				<div className='text-right my-auto border-left-2'>
					<p className='mb-0'>
						<strong>Indigenous status:</strong> {interprateIndigenousStatusCode(individualProfile?.indigenousStatus)}
					</p>
					<p>
						<strong>Veteran and ADF status:</strong> {individualProfile?.DVA || 'No status'}
					</p>
				</div>
			</div>
			<Divider className='!m-0' />
			{sections.map((section) => {
				return (
					<React.Fragment key={section.key}>
						<Divider />
						{section}
					</React.Fragment>
				);
			})}
		</>
	);
};

const DocumentsSinceSHS = ({ newDocuments, onOpenDocument }) => {
	const isAvailable = newDocuments?.informationAvailable === 'true';
	return (
		<>
			<h4>Documents available on the My Health Record since the last Shared Health Summary</h4>
			{!isAvailable && (
				<ErrorComp {...{ err: 'No documents available on the My Health Record since the last Shared Health Summary.' }} />
			)}
			{isAvailable && <DocumentTable {...{ documents: newDocuments.document, onOpenDocument }} />}
		</>
	);
};

const SharedHealthSummary = ({ sharedHealthSummary }) => {
	const isAvailable = sharedHealthSummary?.informationAvailable === 'true';

	const authorHeaders = ['Author Name', 'Author Role', 'Organisation', 'Address', 'Phone', 'Email'];

	const { sharedHealthSummaryAtomicData } = sharedHealthSummary;
	const { shsAuthorAddress, shsAuthorContactDetails, shsAuthorDesignation, shsAuthorName, shsAuthorOrgName, documentDate } =
		sharedHealthSummaryAtomicData || {};

	const creDate = documentDate;

	const { nameTitle, givenName, familyName } = shsAuthorName || {};
	const prettyAuthorName = [nameTitle, givenName, familyName].join(' ');

	const { streetAddressLine, city, state, postalCode } = shsAuthorAddress || {};
	const prettyAuthorAddress = [streetAddressLine, city, state, postalCode].join(' ');

	// Not sure if this is corrent as values arn't present. Need to check other IHIs
	const [phone, email] = shsAuthorContactDetails || [];

	// Null replacement value
	const BRV = 'Unavailable';

	return (
		<>
			<div className='flex justify-between'>
				<h4>Shared Health Summary</h4>
				{creDate && <p>{moment(creDate).format('DD-MMM-YYYY')}</p>}
			</div>
			{!isAvailable && <ErrorComp {...{ err: 'No Shared Health Summary available.' }} />}
			{isAvailable && (
				<>
					<Table>
						<TableHeader>
							<TableRow>
								{authorHeaders.map((h) => (
									<TableHeaderCell key={h}>{h}</TableHeaderCell>
								))}
							</TableRow>
						</TableHeader>
						<TableBody>
							<TableRow>
								<TableCell>{prettyAuthorName || BRV}</TableCell>
								<TableCell>{shsAuthorDesignation?.displayName || BRV}</TableCell>
								<TableCell>{shsAuthorOrgName || BRV}</TableCell>
								<TableCell>{prettyAuthorAddress || BRV}</TableCell>
								<TableCell>{phone || BRV}</TableCell>
								<TableCell>{email || BRV}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					{/* TODO - Need to look through different IHI's */}
					{/* Current/Past med hist */}
					{/* Medicines */}
					{/* Allergies */}
					{/* Immunisations */}
				</>
			)}
		</>
	);
};

const RecentDocuments = ({ recentDocuments, onOpenDocument }) => {
	const { document } = recentDocuments || {};
	const documents = [document].flat();

	return (
		<>
			<h4>Documents available on the My Health Record in the last 12 months</h4>
			{recentDocuments.informationAvailable !== 'true' && (
				<ErrorComp {...{ err: 'No Documents available on the My Health Record in the last 12 months available.' }} />
			)}
			{recentDocuments.informationAvailable === 'true' && <DocumentTable {...{ documents, onOpenDocument }} />}
		</>
	);
};

const ViewsLinks = ({ otherLinks, changeView }) => {
	const availableLinks = otherLinks?.link?.filter((link) => link.informationAvailable === 'true');
	const dedicatedViews = ['Prescription and Dispense View', 'Medicare Overview', 'Pathology Index View']; // Pathology Index View is actually Pathology Reports View
	
	return (
		<div className='grid grid-cols-2 gap-4 divide-x-full'>
			{availableLinks &&
				// Sort available views/links, with available views first, then ones which go to All documents
				availableLinks.sort((a, b) => {
					return dedicatedViews.indexOf(b.linkTitle) - dedicatedViews.indexOf(a.linkTitle);
				}).map((link) => (
					<React.Fragment key={link.linkTitle}>
						<div
							className='max-w-sm min-w-full overflow-hidden cursor-pointer hover:bg-gray-50 rounded'
							onClick={() =>
								changeView(undefined, {
									name: 'view_type',
									value: dedicatedViews.includes(link.linkTitle)
										? link.linkTitle === 'Pathology Index View'
											? 'Pathology Report View'
											: link.linkTitle
										: 'All Documents',
								})
							}
						>
							<div className='px-6 py-4 flex flex-col gap-2'>
								<span className='font-bold text-md'>{link.linkTitle}</span>
								<span className='text-gray-700 text-base'>{link.linkTitle} available on the My Health Record.</span>
								{!dedicatedViews.includes(link.linkTitle) ? (
									<span className='text-red-700 text-base'>
										No view available - Click to view All Documents and filter accordingly.
									</span>
								) : (
									''
								)}
							</div>
						</div>
					</React.Fragment>
				))}
		</div>
	);
};

const ErrorComp = ({ err }) => {
	return (
		<div className='bg-red-200 w-full p-4 flex rounded'>
			<div className='flex gap-4'>
				<Icon name='exclamation circle' color='red' size='large' />
				<p>{err}</p>
			</div>
		</div>
	);
};

const DocumentTable = ({ documents = [], onOpenDocument, sorted = true }) => {
	const [expandedRows, setExpandedRows] = useState([]);
	const headers = ['', 'Document Date', 'Document Type', 'Author'];

	const toggleExpandedRow = (documentLink) => {
		expandedRows.includes(documentLink)
			? setExpandedRows(expandedRows.filter((r) => r !== documentLink))
			: setExpandedRows([...expandedRows, documentLink]);
	};
	documents = documents.map(d=>({...d, effectiveDateTime:moment(d.effectiveDateTime)}))
	if (sorted) {
		documents = [...documents].sort((a,b)=>b.effectiveDateTime.valueOf()-a.effectiveDateTime.valueOf())
	}

	return (
		<Table>
			<TableHeader>
				<TableRow>
					{headers.map((h) => (
						<TableHeaderCell key={h}>{h}</TableHeaderCell>
					))}
					<TableHeaderCell></TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{documents?.map((doc) => {
					const {
						effectiveDateTime,
						documentTypeName,
						documentAuthorOrganisationName,
						documentAuthorPersonName,
						documentAuthorRole,
						clinicalSynopsis,
						documentLink,
					} = doc;
					const { givenName, familyName, nameTitle } = documentAuthorPersonName;
					const authorPretty = [nameTitle, givenName, familyName].join(' ');

					const isExpandable = !!clinicalSynopsis;
					const isExpanded = !!expandedRows.includes(documentLink);

					return (
						<React.Fragment key={documentLink}>
							<TableRow
								{...(isExpandable && {
									onClick: () => toggleExpandedRow(documentLink),
									className: 'hover:cursor-pointer hover:bg-gray-100',
								})}
							>
								<TableCell>
									{isExpandable &&
										(isExpanded ? <Icon name='minus square outline' /> : <Icon name='plus square outline' />)}
								</TableCell>
								<TableCell>{moment(effectiveDateTime).format('DD-MMM-YYYY')}</TableCell>
								<TableCell>{documentTypeName}</TableCell>
								<TableCell>
									{authorPretty} {documentAuthorRole!='Not Available' && <>({documentAuthorRole})</>}
									<br/>{documentAuthorOrganisationName}
								</TableCell>
								<TableCell><Button size='tiny'
									onClick={()=>{
										const [ whatever, RepositoryUniqueId, DocumentUniqueId] = documentLink.split(/[:/]/)
										onOpenDocument({RepositoryUniqueId, DocumentUniqueId})
									}}>Open</Button></TableCell>
							</TableRow>
							{isExpanded && (
								<TableRow>
									<Table.Cell colspan={100}>{clinicalSynopsis}</Table.Cell>
								</TableRow>
							)}
						</React.Fragment>
					);
				})}
			</TableBody>
		</Table>
	);
};

export default HealthRecordOverviewRenderer;
