/* eslint-env node */
//	------------------------	------------------------	------------------------
//	Description: Used for timing functions - could be integrated with all functions
//  and awaited returns.
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const moment = require('moment-timezone')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const START_ON_CREATION = true
const START_ON_RESET = true

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class stopwatch {

    constructor(start = START_ON_CREATION) {

        this.init(start)

    }

    //	------------------------	------------------------	------------------------

    init(start) {

        this.start_time = null
        this.stored_duration = 0
        this.total_duration = 0

        this.state = 'stopped'
        this.laps_data = []

        if (start) this.start()

    }

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    start() {

        if (this.state != 'started') {

            this.start_time = moment()
            this.state = 'started'

        }

    }

    //	------------------------	------------------------	------------------------

    stop() {

        if (this.state != 'stopped') {

            this.stored_duration = this.lap_duration()
            this.start_time = moment()
            //this.total_duration += this.stored_duration

            this.state = 'stopped'

        }

    }

    //	------------------------	------------------------	------------------------

    reset(start = START_ON_RESET) {

        this.init(start)

    }

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    add_lap() {

        const lap_time = this.lap_duration()
        this.laps_data.push(lap_time)

        this.start_time = moment()
        this.total_duration += this.stored_duration + lap_time
        this.stored_duration = 0

    }

    //	------------------------	------------------------	------------------------

    duration() { // Returns the total duration of the stopwatch including all laps

        return this.total_duration + this.lap_duration()

    }

    //	------------------------	------------------------	------------------------

    lap_duration() { // Returns the current lap duration including stops and starts

        const current_duration = this.current_duration()
        return this.stored_duration + current_duration

    }

    //	------------------------	------------------------	------------------------

    current_duration() {

        const c_time = moment()
        if (this.state == 'started') return c_time.diff(this.start_time)
        else return 0

    }

    //	------------------------	------------------------	------------------------

    delay(duration) {

        return new Promise( _ => setTimeout(_, duration))

    }

    //	------------------------	------------------------	------------------------
    //  Getters and setters
    //	------------------------	------------------------	------------------------

    get_state() { return this.state }

    get_lap_count() { return this.laps_data.length }

    get_last_lap() {

        if (this.laps_data.length > 0) return this.laps_data[this.laps_data.length - 1]
        else return 0

    }

    //	------------------------	------------------------	------------------------

    get laps() { return clean_data(this.laps_data) }
    set laps(val) { if (Array.isArray(val)) this.laps_data = val }

}

//	------------------------	------------------------	------------------------
//	Functions
//	------------------------	------------------------	------------------------

function clean_data(data) {

    return JSON.parse(JSON.stringify(data))

}