//	------------------------	------------------------	------------------------
//	Description: Validation of data
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const moment = require('moment-timezone')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DVA_CARD = {
	"Gold": 	"G",
	"White": 	"W",
	"Orange": 	"O",
}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default class validations {

	// based on https://stackoverflow.com/questions/3589345/how-do-i-validate-an-australian-medicare-number
	static validate_medicare(value) {
		let isValid = false;

		value = value.replace(/[\s\-_]/g, '')

		if (value && value.length === 11) {
			const matches = value.match(/^(\d{8})(\d)(\d)(\d)/);
			if (!matches) {
				return { invalid: true };
			}

			const base = matches[1];
			const checkDigit = matches[2];
			const issueNumber = matches[3];
			const individual = matches[4];
			const weights = [1, 3, 7, 9, 1, 3, 7, 9];

			let sum = 0;
			for (let i = 0; i < weights.length; i++) {
				sum += parseInt(base[i], 10) * weights[i];
			}

			isValid = !isNaN(sum) && sum % 10 === parseInt(checkDigit, 10) && parseInt(individual) > 0;
		}

		return isValid;
	}

	//	------------------------	------------------------	------------------------

	//Based on https://stackoverflow.com/questions/40123747/check-if-date-is-a-valid-one
	static validate_date(value) {
		const date = moment(value)
		return date.isValid()
	}

	//	------------------------	------------------------	------------------------

	static validate_dva(value) {
		if (typeof value != 'object') return false
		if( value.colour == '' && value.number == '' ) return true
		if (!validations.validate_dva_colour(value.colour)) return false // Check the colour
		return validations.validate_dva_number(value.number) // Check the number
	}

	//	------------------------	------------------------	------------------------

	// Based on https://meteor.aihw.gov.au/content/index.phtml/itemId/339127
	static validate_dva_number(value) {
		if (typeof value != 'string') return false // Check the structure
		return value.match(/^[NVQWST]([A-Za-z ]\d{6}|[A-Za-z]{2}\d{5}|[A-Za-z]{3}\d{4})[A-Za-z]?$/) // Check the number
	}

	//	------------------------	------------------------	------------------------

	static validate_dva_colour(value) {
		if (typeof value != 'string') return false // Check the structure
		return (Object.values(DVA_CARD).includes(value)) // Check the colour
	}

}