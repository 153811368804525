import React from "react";
import { Container, FormField, List, ListItem } from "semantic-ui-react";

export const LIST_INIT = {
    data: ["item 1", "item 2", ["nested item 1", "nested item 2"], "item 3"],
};

const DataList = ({ data, editMode }) =>
    editMode ? (
        <>
            <FormField>
                <label>
                    Data - enter list as JSON, IE: {JSON.stringify(LIST_INIT.data)}
                </label>
                <input
                    name="data"
                    placeholder="Enter list as JSON"
                    defaultValue={JSON.stringify(data)}
                />
            </FormField>
        </>
    ) : (
        <Container className="px-2">
            <List as="ol">
                {data?.map((item, i) => typeof item === "string" ? (
                    <ListItem key={i} as='li' value='&bull;'>{item}</ListItem>
                ) : (
                    <Container className="lg:px-3">
                        <List as="ul" className="ml-6">
                            {item.map((nested, j) =>
                                <ListItem
                                    key={j}
                                    as='li'
                                    value='&#9675;'
                                >
                                    {nested}
                                </ListItem>
                            )}
                        </List>
                    </Container>
                ))}
            </List>
        </Container>
    );

export default DataList;
