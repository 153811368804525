import React, {useEffect, useState} from 'react'
import {Table, Popup, Button, Icon, Loader, Container} from 'semantic-ui-react'
import {ObjectInspector, chromeLight} from 'react-inspector';
import {DataShow} from 'xAppLib/DataTable'
import ActBtns from 'views/UIelems/fields/ActBtns'
import {useCurrent} from "../../xAppLib/Hooks/useCurrent";
import logger from "../../xAppLib/libs/logger";
import {AsyncButton} from "../UIelems/AsyncButton";
import instcons_global_model from "../../models/instcons_global_model";
import {cls} from "../NUI/utils";
import {useCallback} from 'react';
import {useSiteStatus, useUserData} from 'xAppLib/Hooks';
import Badge from 'views/NUI/Badge';
import {DataConnector, ListPagination} from 'xAppLib/DataTable'
import OverlayDialog from './OverlayDialog';
import {useCanApprove} from './Hooks';
import API_service from 'xAppLib/providers/API_service';
import { CheckIcon } from '@heroicons/react/outline'
import { useRef } from 'react';
import GetMyHRPatientRecords, { MyHRListBtn } from 'views/myhr/GetMyHRPatientRecords';
import scripts_list_model from "../../models/scripts_list_model";
import {DOCID_NODR} from "../../constants";


const DEBUG = false

const MAX_REVIEW_TIME = 1000 * 60 * 5 // 5 minutes
const ENFORCE_REVIEW = false;

const WrapComponent = ({embedded,children}) => embedded 
			? <div>{children}</div> 
			: <Container className='wide-content' fluid>
				{children}
			</Container>

function is_escript(r) { 
	return (['escript', 'c2u', 'home'].includes(r.form_data?.delivery) || r.form_data?.delivery?.includes('EM_'))
}

function WR({embedded, title = "WR", onConsultTaken}) {

	const [records] = useUserData('approval', []);

	const [page,setPage] = useState(1)
	const [pageSize,setPageSize] = useState(5)

	const can_approve = useCanApprove()

	const timers = useRef({})

	const script_hold = useSiteStatus('script_hold') || false

	
	DEBUG && console.log("WR Records",records);
	
	useEffect(()=>{
		if (!records)
			return
		for (const r of records) {
			if (!r.owner && timers.current[r.sid]) 
				clearTimer(r.sid)
		}
	},[records])


	// tracking live time for distance from the initial assignment of a script (rev_tm)
	const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const intervalHandle = setInterval(() => {
            setCurrentTime(() => new Date());
        }, 1000);
        return () => clearInterval(intervalHandle);
    }, []);
	const clearTimer = useCallback(async sid=>{
		if (!timers.current[sid])
			return
		clearTimeout(timers.current[sid])
		delete timers.current[sid]
	},[])

	const own = useCallback(async (sid, extra)=>{
		clearTimer(sid)
		await instcons_global_model.own(sid, true, extra)
		timers.current[sid] = setTimeout(async ()=>{
			const latest = await instcons_global_model.get_record(sid,null,{refresh:true})
			if (!latest)
				return
			if (latest.status == 'auto_approved_delay' && latest.owner?.uid == app.user.uid && !latest.pending) {
				instcons_global_model.own(sid,false)
			}
		},MAX_REVIEW_TIME)
	},[])


	const onAct = useCallback(async (row, act, params, mod) => {

		DEBUG && console.log('onAct', row, act,params);
		const delta = -row.showAt.diff(Date.now(), 's', true);
		logger.usg_log('InstCons_Approve_WR', 'wr', act, {mod, d: delta, s: row.sid})

		switch(act) {

			case 'approve':
				if(row.req_type === 'medcert' && ['med-cert-uni', 'med-cert-school', 'med-cert'].includes(row.med?.id) && (await app.confirm("Confirmation", "I have read the patient’s description and approve a single day medical certificate based on the symptoms described.")) !== 'yes')
					break;
				await instcons_global_model.update_status(row,'doc_approved', {is_scr: true, eml: true},);
				break;

			case 'reject':
				await instcons_global_model.update_status(row,'doc_declined', {is_scr: false, eml: true, ...params},);
				break;

			case 'review': {
				await own(row.sid, mod.x)
				break;
			}
			case 'return': 
				await instcons_global_model.own(row.sid,false)
				break;

			case 'release': {
				await API_service.load_data('chgStat', {sid: row.sid, doc: DOCID_NODR, stat: 'auto_approved_delay', ...params})
				await instcons_global_model.own(row.sid,false)
				break;
			}

			case 'consult': {
				await clearTimer(row.sid);
				const result = await API_service.load_data('chgStat', {sid: row.sid, doc: app.user.claims.doc_id, stat: 'in_doccall', ...params})
				if (result?.res === 'ok') {
					onConsultTaken?.();
					app.history.push(`/exprcons/${row.sid}/_`);
				}

				break;
			}

			case 'script note': {
				
				const result = await API_service.load_data('chgStat', {sid: row.sid, stat: act, ...params}) 
				return result;
			}

		}

		return {res: 'ok'};

	},[])

	const filtered = records.filter(r=>!script_hold || !is_escript(r)) 

	const is_max_locked = !!(
		app.settings.appscr_max_lock &&
		(filtered?.filter(r => r.owner?.uid == app.user.uid && !r.pending).length || 0) >= app.settings.appscr_max_lock
	);
	const is_max_locked_ref = useCurrent(is_max_locked);

	if (!can_approve)
		return null

	const ql = records.length-filtered.length

	return <WrapComponent embedded={embedded}>

				{!!title && <h3>{title}</h3>}


				<DataConnector
						pageSize={pageSize}
						page={page}
						data={ filtered }
						>

					{ (data, loading, pages, total, pageSize) => {
						return <>
							{script_hold && <p className='bg-red-700 text-white p-4'>eScript approval under maintenance. {ql} hidden eScript{ql==1?'':'s'} in the queue. </p> || null}
							<Table selectable compact className='approve-wr'>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>
											{/* <Checkbox onClick={(e,d)=>{
												setChecked(data.reduce((o,r)=>({...o,[r.sid]:d.checked}),{}))
											}}/> */}
										</Table.HeaderCell> 
										{
											instcons_global_model.LIST_DET_FIELDS.map(
																	(f, i) => <Table.HeaderCell key={`tbl_hdr_${i}`}>{f.name}</Table.HeaderCell>
																)
										}
										{!app.acl.is_supp && <Table.HeaderCell>Act btns</Table.HeaderCell>}
										{(app.acl.is_admin || app.acl.is_supp) && <Table.HeaderCell>Raw data</Table.HeaderCell>}
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{loading && <Table.Row><Table.Cell singleLine><Loader active inline/></Table.Cell></Table.Row>

									|| 
										(data.length > 0 &&
											data.map(
												(r, i) => {

													// return <Table.Row><Table.Cell>Data</Table.Cell></Table.Row>
													const mine = r.owner?.uid == app.user.uid
													const owned = r.owner?.uid && !mine
													const pending = !!r.pending || r.loading
													const blocked = pending || owned
													const consulting = r.status !== 'auto_approved_delay';
													const can_approve = !consulting && !owned && (mine || !(r.must_review && ENFORCE_REVIEW));
													// lockTimer starts at the MAX_REVIEW_TIME (120) seconds and count towards enabling the unlock feature
													const lockTimer = ((new Date(r.rev_tm + MAX_REVIEW_TIME) - currentTime) / 1000);
													const rowStyle = cls('relative',
																				{
																					'bg-slate-50': !!(i % 2),
																					"opacity-40":owned,
																					admin:(app.acl.is_admin || (lockTimer <= 0 && app.acl.is_supp)),
																					mine,
																					owned,
																					blocked,
																					pending
																				}
																				)
													const borderStyle = cls('border-l-4 border-transparent',
																			mine && "border-emerald-400" ,
																			owned && "border-gray-400",
																			// blocked && "pointer-events-none"
																			)
													const type = is_escript(r) ?'eScript':'Paper script'
									
													return <Table.Row
																key={"view-rec-"+r.key}
																className={rowStyle}
																>

																<Table.Cell className={borderStyle}>
																	{owned && <Icon name='lock' size='large' />}
																	{pending && <Icon size='large' name='spinner' loading />}

																</Table.Cell>

																{instcons_global_model.LIST_DET_FIELDS.map((f, fi) => (
																	<Table.Cell key={`tbl_val_${r.key}_${fi}`}>
																		{DataShow.show_data_field(r, f, null, null, null, null, {})}
																	</Table.Cell>
																))}

																{!app.acl.is_supp && <Table.Cell>
																			<ActBtns 
																				as={AsyncButton}
																				show_stat={consulting && (app.acl.is_admin || app.acl.is_supp || mine)}
																				row={r}
																				f={{
																						name: 'Status',
																						btns_list_func: (row) => {
																							const delay_lock = !!(app.settings.appscr_review_delay && row.rev_tm && row.rev_tm + (1000 * app.settings.appscr_review_delay) > Date.now() );

																							const btns_arr = [
																								can_approve && mine && {
																									n: 'Approve ' + type,
																									a: 'approve',
																									c: 'green',
																									i: 'check',
																									d: delay_lock,
																								},
																								can_approve && mine && {
																									n: 'Reject ' + type,
																									a: 'reject',
																									cust: 'stat_w_msg',
																									pl_hld: 'Reason to reject (min 30 characters)',
																									c: 'red',
																									i: 'exclamation',
																									min_len: 30,
																								},
																								!mine && !owned && !is_max_locked && {
																									n: 'Review',
																									a: 'review',
																									c: 'orange',
																									i: 'question',
																								},
																								can_approve && mine && {
																									n: 'add note',
																									a: 'script note',
																									cust: 'stat_w_msg',
																									pl_hld: 'Add Note',
																									info: r => r.meta?.note,
																									c: 'olive',
																									i: 'write',
																								},
																								mine && {
																									n: 'Return',
																									a: consulting ? 'release' : 'return',
																									c: 'yellow',
																									i: 'recycle',
																								},
																								...(mine && (consulting || app.settings.can_promote_appscr) ? [
																									{t: 'separator'},
																									{
																										n: 'Emergency Consult',
																										a: 'consult',
																										c: 'orange',
																										i: 'exclamation triangle',
																									}
																								] : []),
																							].filter(Boolean);

																							return btns_arr;
																						},

																						btns_stat_param: 'status',
																						btns_stat_dict: scripts_list_model.stats_dict,
																						act_run: async (b, row, mod) => {
																							if (b.a == 'review' && is_max_locked_ref.current) {
																								return alert(`You already have ${app.settings.appscr_max_lock} script${app.settings.appscr_max_lock===1?'':'s'} locked, please review first`);
																							}
																							return onAct(row, b.a, b.p, mod)
																						},
																						onClickBtn:(b,act) => {
																							if (!['review','return'].includes(b.a))
																								own(r.sid)
																							// console.log("onClickBtn",b.a,{b});
																						}
																					}}
																				/>
																				{mine && <MyHRListBtn pts_uid={r.pts_uid} myhr={r.myhr} row={r} disableUpload />}
																</Table.Cell>}

																{(app.acl.is_admin || app.acl.is_supp) && 
																<Table.Cell>

																	{app.acl.is_admin && <Popup
																			trigger={<Button icon='add' size='tiny' />}
																			position='left center'
																			on='click'
																			style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll'}}
																	>
																		<div>
																			<br/>
																				<ObjectInspector
																						data={ r }
																						expandLevel={ 1 }
																						theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
																					/>
																			<br/>
																		</div>

																	</Popup>}

																	{owned && !consulting && <Button
																		color='red'
																		icon={lockTimer <= 0 && 'lock open' || undefined} 
																		size='tiny'
																		style={{
																			width: "44px"
																		}}
																		onClick={async ()=>{
																			if (await app.confirm(`Unlock?`, `This will remove ownership on the record.` ) == 'yes')
																					instcons_global_model.update_record(r.sid,
																						{
																							pending:false,
																							owner:null
																						})
																			}}
																	>{lockTimer > 0 && `${lockTimer.toFixed(0)} ` || undefined}</Button>}



																</Table.Cell>}

																
															</Table.Row>
												})
										||
											<Table.Row>
												<Table.Cell colSpan='5' className='text-green-600'><Icon name='check'/> No records</Table.Cell>
											</Table.Row>)
									}

								</Table.Body>

							</Table>

							{pages > 1 && <ListPagination 
										page={page} 
										pages={pages} 
										pageSize={pageSize}
										loading={loading} 
										data={data} 
										total={total} 
										boundaryRange={3}
										pageSizeOptions={[5,10,20]}
										showPageSizeSelector={true}
										onPageSizeChange={pageSize=>setPageSize(pageSize)}
										onPageChange={page=>setPage(page)}/>}

						</>
					}}
				</DataConnector>

			

			
		</WrapComponent>;
}

export default React.memo(WR)

export const AppScriptsCount = () => useUserData('approval')[0]?.length || 0;

export const ApprovalBadge = () => {
	const [records] = useUserData('approval',null)
	const [open,setOpen] = useState(false)

	const can_approve = useCanApprove()

	if (!can_approve)
		return null
	return <>
		<Badge title='Pending Scripts Approvals' color={records?.length ? 'red' : 'green'}  onClick={()=>setOpen(true)} className='cursor-pointer'>{records?.length || <CheckIcon className="h-4 w-4" />}</Badge>
		{open && <OverlayDialog open={open} setOpen={setOpen} closeButton>
			<div className='text-sm' style={{ width: '100%', overflowX: 'scroll', overflowY: 'inherit' }}>
				<WR embedded title={null} onConsultTaken={() => setOpen(false)}/>
			</div>
		</OverlayDialog>}
	</>	
}
