import React, { useEffect, useState } from 'react';
import { List, Input, Icon, Button, Table, Container, Segment, Header, Form, Checkbox, Card, Image, Label, Loader , Dimmer, Divider } from 'semantic-ui-react'
import med_model from 'models/med_model'
import { Link } from 'react-router-dom'


const Meds = ({medsList, heading, callDoctor=true, stars}) => {
	const [meds, setMeds] = useState(null)
	useEffect(_=>{
		if (medsList && medsList.length)
			Promise.all(medsList.map(async k=>{
				const record = await med_model.get_record( k.k || k )
				return record && {...record,k}
			})).then(meds=>setMeds(meds.filter(Boolean)))
	},[medsList])
	if (!meds)
		return null
	return <div className='ui field' style={{marginTop:20}}>
		{heading && <Header size="small">{heading}</Header>}
		<Card.Group className='cards-list' stackable style={{marginLeft:'-0.87em '}}>
			{
				meds.map( (el,i)=> <Card
								as={Link}
								to={ ['/m/pharm/',el.k].join('')}
									key={i}
							>
								<Card.Content className="card-header">
									<Card.Header>
											<div>
												{el.name.toLowerCase()}<br/>
												{el.size}
												{el.alt_name && <Card.Meta  className='cat-alt' >
													{el.alt_name}
												</Card.Meta>}
												{stars && stars.includes(el.m) && 
													<Card.Meta  className='cat-alt' >
														<Icon name='star' color="green" size="small"/>
												</Card.Meta>}
												
											</div>
									</Card.Header>
									
									
								</Card.Content>
								
							</Card>
						)
			}
		</Card.Group>
		
	</div>
}
	
export default Meds;
