import db_lib from 'xAppLib/libs/db_lib';
import {ERX_ENUM, CLINICIAN_ENUM} from './erx/resources/erx_enums';

const _FRDB_LOC = 'docs';

const _DET_FIELDS = {
	'firstname':		'First Name',
	'familyname':		'Last Name',
	'name':			 	'Doctor Name',
	'email':			'Contact email',
	'phone':			'Phone number',
	'pub_phone':		'(Optional) Phone number to use on scripts',
	'fax':				'Fax number',
	'skype':			'Skype ID',
	'addr_str':			'Street address',
	'addr_sub':			'Suburb',
	'practicename':		'Practice Name',
	'address1':			'Practice Address line 1',
	'address2':			'Practice Address line 2',
	'pass_to':			'Pass To Dr',
	'suburb':			'Practice Suburb',
	'postcode':			'Practice Postcode',
	'state':			'Practice State',
	'practiceemail':	'Practice Email',
	'provider':			'Provider number',
	'prescriber':		'Prescriber number',
	'prescribertype':	'Prescriber Type',
	'hpio':			 	'HPIO',
	'hpii':				'HPII',
	'ahpra':			'AHPRA Number',
	'qualification':	'Qualification',
	'erx_id':			'eRx identity number',
	'minorID':			'Medicare Location ID',
	'signature':		'Signature file',
	'notes':			'Notes',
	'moratorium_date':			'Moratorium date',
}

const _SIGNUP_FIELDS = {
	group: {
		label:'Group',
		placeholder: 'Select Group',
		valid_not_required: true
	},
	organisation: {
		label:'Organisation',
		readOnly: true,
		valid_not_required: true
	},
	firstname: {
		label:'First Name',
		placeholder: 'First Name',
	},
	familyname: {
		label:'Last Name',
		placeholder: 'Last Name',
	},
	name: {
		label:'Doctor Name',
		placeholder: 'Doctor Name',
	},
	email: {
		label:'Email',
		type:'email',
		placeholder:'Preferred Email'
	},
	phone: {
		label:'Phone Number',
		placeholder:'Preferred Number'
	},
	pub_phone: {
		label:'Optional To Use On Scripts',
		placeholder:'Public Number',
		valid_not_required: true
	},
	fax: {
		label:'Fax Number',
		placeholder:'Fax Number',
		valid_not_required: true
	},
	skype: {
		label:'Skype ID',
		placeholder:'Skype ID',
		valid_not_required: true
	},
	// Legacy duplicates
	addr_str: {
		label:'Street Address',
		placeholder:'Street Address',
		valid_not_required: true
	},
	addr_sub: {
		label:'Suburb',
		placeholder:'Suburb',
		valid_not_required: true
	},
	// /
	practicename: {
		label:'Practice Name',
		placeholder:'Practice Name',
		readOnly: true
	},
	address1: {
		label:'Practice Address line 1',
		placeholder:'Practice Address line 1',
		readOnly: true
	},
	address2: {
		label:'Practice Address line 2',
		placeholder:'Practice Address line 2',
		readOnly: true,
		valid_not_required: true
	},
	suburb: {
		label:'Suburb',
		placeholder:'Practice Suburb',
		readOnly: true
	},
	postcode: {
		label:'Postcode',
		placeholder:'Practice Postcode',
		readOnly: true
	},
	state: {
		label:'State',
		placeholder:'Practice State',
		type: 'select',
		options: Object.entries(ERX_ENUM.STATE).map(([text, value]) => ({ text, value, key: value }))
	},
	practiceemail: {
		label:'Email',
		type:'email',
		placeholder:'Practice Email',
	},
	provider: {
		label:'Provider Number',
		placeholder:'Provider Number',
	},
	prescriber: {
		label:'Prescriber Number',
		placeholder:'Prescriber Number',
	},
	prescribertype: {
		label: 'Prescriber Type',
		type: 'select',
		options: Object.entries(CLINICIAN_ENUM.PRESCRIBER_TYPE).map(
			([text, value]) => ({
				// Format the options enum text e.g.
				// "Medical Practitioner" -> "Medical Practitioner"
				// "Eye/Optometrist": "Eye / Optometrist"
				// "nUrse": "Nurse"
				// "midwiFe": "Midwife"
				text:
					text
						.replace(/\//g, " / ")
						.toLowerCase()
						.split(" ")
						.map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
						.join(" "),
				value,
				key: value
			})
		),
	},
	hpio: {
		label:'HPIO',
		placeholder:'HPIO'
	},
	hpii: {
		label:'HPII',
		placeholder:'HPII'
	},
	ahpra: {
		label:'AHPRA Number',
		placeholder:'AHPRA Number'
	},
	qualification: {
		label:'Qualification',
		placeholder:'Qualification'
	},
	has_moratorium: {
		label: `I'm NOT under a moratorium`,
		type: 'checkbox',
		value: true,
		valid_not_required: true,
	},
	moratorium_date: {
		label: 'When does your moratorium end?',
		type: 'date',
		value: 'No',
		valid_not_required: (v) => v.has_moratorium
	},
	erx_id: {
		label:'eRx identity number',
		placeholder:'eRx identity number',
		valid_not_required: true,
	},
	signature: {
		label:'Signature File',
		placeholder:'Signature file',
		get readOnly() {
			return !app.acl.can_onboard_doc;
		},
		get valid_not_required() {
			return app.acl.can_onboard_doc;
		}
	},
	notes: {
		label:'Notes',
		placeholder:'Notes',
		valid_not_required: true
	},
	date_created: {
		readOnly: true
	},
	recap: {
		name: 'recaptcha',
		get valid_not_required() {
			return app.acl.is_admin;
		}
	}
}


const _MODEL_NAME = 'doc_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class doc_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get SIGNUP_FIELDS() { return _SIGNUP_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_sel_docs_list (refresh = false) {

		// console.log(_MODEL_NAME+' :: get_sel_docs_list ');

		if (refresh || !app.app_data.ws_data?.docs_sel_list) {

			const docs = await doc_model.get_record()

			const docs_data = {'-':{name: 'Empty'}, ...docs}

			const docs_sel_list = docs_data ? Object.keys(docs_data).map( k => ({key: k, value: k, text: docs_data[k].name}) ) : null 

			app.app_data.ws_data.docs_sel_list = docs_sel_list
		}

		return app.app_data.ws_data.docs_sel_list

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static providerNumberTransformer(v){
		return v.toUpperCase().replace(/[^0-9A-Z]/g, '');
	}
}

			// .text(params.alt_cat!="_cosm" ? 'Dr. Asher Freilich' :			 'Dr. Greg Scher', 55, 18)

			// .text(params.alt_cat!="_cosm" ? '50 Orrong Crescent' :			 '2A Mawby Rd', 55, 37)

			// .text(params.alt_cat!="_cosm" ? 'Caulfield North - VIC 3161' :			 'Bentleigh East', 55, 56)

			// .text('Prescriber number:			 ' + (params.alt_cat!="_cosm" ? '2596769' :			 '2368034'), 55, 117)

			// .text('Provider number:			 ' + (params.alt_cat!="_cosm" ? '4449747J' :			 '289833UW'), 441, 117)

			// .image(params.alt_cat!="_cosm" ? './imgs/sign - asher.png' :			 './imgs/sign - greg.png', 275, 725, {width:			 189})
