import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from 'semantic-ui-react';
import IcosmHero from './components/IcosmHero';
import IcosmBanner from './components/IcosmBanner';
import IcosmInfo from './components/IcosmInfo';
import IcosmSlider from './components/IcosmSlider';

const Support = () => {

	return <div>

		<Helmet>
			<title>Support</title>
		</Helmet>

       <Container>
		
            <IcosmHero img='2/iStock-1429060901-2.jpg' title="A support team that cares about your business" desc="Our support team is your go-to for all business enquiries regarding product ordering, script queries, clinic audits and software support." link="" order="2" />
			
			<IcosmInfo img='2/iStock-1661396598-1.jpg' title="Ways to get in touch with support" desc="We have a dedicated online ChatBot for all enquiries whilst your using our software. As well as open community based communication on our state based WhatsApp groups." />
        
		</Container>

        <div className="flex justify-center flex-col lg:flex-row relative bg-icosm-quaternary">
			<IcosmSlider />
		</div>

        <IcosmBanner  img='2/iStock-2032356737-1.jpg'  title="Get started today!" desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." />
	</div>
}

export default Support;
