import React, { Component, Fragment } from 'react';
import { 	Container,
			List,
			Input,
			Button,
			Table,
			Form,
			TextArea,
			Divider,
			Popup,
			Icon,
			Radio,
			Message,
			Segment,
			Grid,
			Accordion,
			Header,
			Dropdown,
			Checkbox,
			Modal
		} from 'semantic-ui-react'
import medicare_model from 'models/medicare_model'
import currency from 'currency.js'
import MBSSelector from './MBSSelector'
import moment from 'moment'

class MedicareClaim extends Component {
	
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	componentDidMount() {
		this.parseDetails()
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.scr_obj.sid != this.props.scr_obj.sid) {
			this.parseDetails()
		}
	}
	
	parseDetails() {
		const { scr_obj } = this.props
		let claim = scr_obj.paym?.mdcr_claim || scr_obj.paym?.mdcr_itm?.claim
		/* Backup rescue code
		if (!claim) {
			const backup = scr_obj.hist.filter(h=>{
				// console.log({h},h.diff.part,h.diff.upd.mdcr_itm);
				return h.diff.part=='paym-mdcr' && h.diff.upd?.mdcr_itm?.claim
			}).pop()
			if (backup) {
				console.log("Got backup",{backup});
				claim = backup.diff.upd.mdcr_itm?.claim
			}
		} */
		const payment = scr_obj.paym?.mdcr_payment || scr_obj.paym?.mdcr_itm?.payment
		const prefill = scr_obj.paym?.mdcr_itm
		// const claimable = !!scr_obj.paym?.mdcr_itm
		const claimable = true
		const medicare_item = prefill?.num || prefill?.code || null
		const prefill_extras = scr_obj.paym?.mdcr_extras
		const medicare_item_extra = prefill_extras && prefill_extras.reduce((o,v)=>Object.assign(o,{[v.code]:true}),{}) || {}
		const pending_items = [medicare_item].concat(medicare_item_extra && Object.keys(medicare_item_extra)).filter(n=>n)
		const copay = !!scr_obj.med_db_data.xtra?.copay
		this.setState( {
			claimModal:false,
			claiming:false,
			verifying:false,
			medicare_item,
			claimable,
			claims:claim && [claim] || [],
			payments:payment && [payment] || [],
			prefill,
			verified:scr_obj.meta?.medicare_verified,
			concession_verified:scr_obj.meta?.concession_verified,
			validation:scr_obj.meta?.medicare_validation,
			concession_validation:scr_obj.meta?.concession_validation || scr_obj.meta?.medicare_validation?.concession,
			pending_items,
			medicare_item_extra,
			copay
		});
	}
	
	onOpenClaim = () => this.setState({ claimModal: true })

	onCloseClaim = () => this.setState({ claimModal: false })
	
	async submitMedicare() {
		const { medicare_item,medicare_item_extra } = this.state
		const { scr_obj } = this.props
		this.setState({claiming:true})
		const items = [
			medicare_item,
			...Object.keys(medicare_item_extra)
		].filter(n=>n)

		const result = await medicare_model.medicare_claim(scr_obj.sid, items)
		this.setState({claiming:false})
		this.onCloseClaim()
		
		if (result.res!='ok') {
			alert(result.error)
		} else {
			this.setState({claims:[result]})
		}
		
	}
	
	onSetIncorrect = async () => {
		if (!confirm('Are you sure?'))
			return
		const { scr_obj } = this.props
		this.setState({claiming:true})

		const result = await medicare_model.set_incorrect(scr_obj.sid)
		this.setState({claiming:false})
		
		if (result.res!='ok') {
			alert(result.error)
		} else {
			this.setState({claims:[result]})
		}
		
	}
	
	async verifyMedicare() {
		const { medicare_item } = this.state
		const { scr_obj } = this.props
		this.setState({verifying:true})
		const result = await medicare_model.medicare_verify({ sid: scr_obj.sid })
		this.setState({verifying:false})
		this.setState({
				validation:result,
				verified:result.verified,
				concession_validation:result.concession,
				concession_verified:result.concession?.verified,
			})
		if (result.res!='ok') {
			alert(result.message)
		} 
		
	}
	
	async checkStatus() {
		const { scr_obj } = this.props
		this.setState({checking:true})
		const result = await medicare_model.medicare_status(scr_obj.sid)
		this.setState({checking:false})
		
		if (result.res!='ok') {
			alert(result.error)
		} else {
			this.setState({claims:[result.claim].filter(n=>n),payments:[result.payment].filter(n=>n)})
		}
	}


	render() {
		const { claims, payments, verified, concession_verified, claimable, prefill,medicare_item, medicare_item_extra,validation,concession_validation,pending_items,copay } = this.state
		const { scr_obj } = this.props
		
		if (!claimable)
			return null
		
		if (this.props.summary || !(app.user?.claims?.admin || app.user?.claims?.supp))
			return this.render_summary()

		if (!claims)
			return null

		return (
			<div style={{minWidth:300,maxWidth:700,overflow:'hidden'}}>
				<Grid >
 					<Grid.Row style={this.props.stacked&&{flexDirection:'column'}||{}}>
					  <Grid.Column width={this.props.stacked ? 16: 10 } style={{paddingTop:10}}>
						  {/*claims.length > 0 && <p><strong>{claims.length} Claim{claims.length>1&&'s'}</strong></p>*/}
						  {claims.map((claim,i)=>{
							const error = ['INCORRECT','ERROR', 'MEDICARE_REJECTED'].includes(claim.status)

							  const payment = payments[0]
					  		// if (!claim.claimId && error.includes(claim.status)) 
							// 	return <Fragment key={i}>
							// 		<p><strong>Claim Details</strong></p>
							// 		<ul>
							// 	   <li>Claim Status: {claim.status}</li>
							// 	   <li>Id: {claim.claimId || 'Unclaimed'}</li>
							// 	   <li>Error: {claim.error_message || 'Unable to claim'}</li>
							// 		{claim.claimId && <Button onClick={_=>this.checkStatus()} loading={this.state.checking}>Check Status</Button>}

							// 	   </ul>
							// 	</Fragment>
							
							return <Fragment key={i}>
									 {/* <pre style={{fontSize:10}}>{JSON.stringify(claim,null,2)}</pre> */}
									 <p><strong>Claim Details</strong></p>
									 <ul>
				  					<li>Status: {claim.status}</li>
				  					<li>Id: {claim.claimId || 'Unclaimed'}</li>
									 {error && <li>Error: {claim.error_message || 'Unable to claim'}</li> || ''}
									
									{claim.assessmentNotes?.length && <li>Assessment Notes: {claim.assessmentNotes.map((a,i)=><span className='whitespace-normal w-full' key={i}>{a.code}: {medicare_model.REASON_CODES[a.code]} {a.note} </span>)}</li>||''}

									{claim.timeSubmitted && <li>Submitted: {moment.unix(claim.timeSubmitted).format("DD-MM-YYYY")}</li>}
									{claim.items && <li>
										Items:
										<ul className='pl-4'>
											{claim.items.map(i=><li key={i.itemNumber}>
												<div>MBS #{i.itemNumber}</div>
												<div>Charge:  {currency(i.chargeAmount||0).format()}</div>
												{i.benefit && <div>Paid: {i.benefit && i.benefit.map((b,i)=><span key={i}>{b.assessor} {currency(b.benefitPaid||0).format()}</span>)}</div>}
												{i.assessmentNotes?.length && <div>Assessment Notes: {i.assessmentNotes.map((a,i)=><span className='whitespace-normal w-full' key={i}>{a.code}: {medicare_model.REASON_CODES[a.code]} {a.note}</span>)}</div> || ''}

											</li>)}
										</ul>
									
									</li>
									}
									</ul>
									{payment && <Fragment>
										<br/>
										<p><strong>Payment Details</strong></p>
										<ul>
										<li><strong>Amount:</strong> {currency(payment.claim?.amount||0).format()}</li>
										<li><strong>Paid to:</strong> {payment.bank.accountName} – {payment.bank.bsb} {payment.bank.accountNumber}</li>
										<li><strong>Payment Batch:</strong> {payment.paymentId}</li>
										</ul>
									</Fragment>}
									<br/>
									{claim.claimId && <Button onClick={_=>this.checkStatus()} loading={this.state.checking}>Check Status</Button>}
				  				</Fragment>
						  })}
							{claims.length == 0 && prefill && medicare_item && <p>Recorded pending item {medicare_item}</p>}
							{copay && <p>Co-pay medicare claim</p>}
							{(claims.length == 0 || claims[0].status == 'ERROR' || ((claims[0].status == 'REJECTED'||claims[0].status == 'MEDICARE_REJECTED') && app.user?.claims?.admin) || (claims[0].status == 'INCORRECT' && app.user?.claims?.admin)) && <Button color="green" onClick={this.onOpenClaim} disabled={!this.state.verified}>Add Claim</Button>}
							{(app.user?.claims?.admin && (claims.length == 0 || (claims.length != 0 && ['REJECTED','ERROR'].includes(claims[0].status)))) && <Button color="red" onClick={this.onSetIncorrect} >Incorrect Data</Button>}

					  </Grid.Column>
					  <Grid.Column width={this.props.stacked ? 16:6} style={{paddingTop:10}}>
						  {verified == 'ok' && <Fragment>
							<p>Medicare Number <strong className='text green'>Verified</strong></p>
						  </Fragment>||<Fragment>
						  <p>Medicare Number <strong className='text red'>Not Verified ({verified || "New"})</strong></p>
						  {validation && <p>{validation.message}</p>}
						  
						</Fragment>}
						
						{concession_verified == 'ok' && <Fragment>
						  <p>Concession Card <strong className='text green'>Verified</strong></p>
						</Fragment>||<Fragment>
						  <p>Concession Card <strong className='text red'>Not Verified ({concession_verified || "New"})</strong></p>
						  {concession_validation && <p>{concession_validation.message}</p>}
						 </Fragment>}
						 
						 { (verified != 'ok' || concession_verified!='ok') && <Button
								 onClick={ _=> this.verifyMedicare() }
								 loading={this.state.verifying}
								 color="blue"
							  >
							 Verify {verified != 'ok' && "Medicare" || "Concession" }
						 </Button>}
						
						  
					  </Grid.Column>
					</Grid.Row>
				</Grid>
				<Modal
			       
			        open={this.state.claimModal}
			        onClose={this.onCloseClaim}
					closeIcon
			        size='small'
			      >
			        <Header icon='file alternate outline' content='Medicare Claim' />
			        <Modal.Content>
						<MBSSelector 
							medicare_item={medicare_item}
							medicare_item_extra={medicare_item_extra}
							onSelect={value=>this.setState(value)}
							/>
			          
					<br /><br />
						
					 
			        </Modal.Content>
			        <Modal.Actions>
						<Button
								disabled={!this.state.medicare_item || !this.state.verified}
								size="big"
								onClick={ _=> this.submitMedicare() }
								loading={this.state.claiming}
								color="green"
							 >
							 Submit to Medicare
						</Button>
			        </Modal.Actions>
			      </Modal>
			</div>
		);
	}
	
	render_summary() {
		const { claims, payments, verified, concession_verified, prefill,medicare_item, medicare_item_extra,validation,pending_items } = this.state
		const { scr_obj } = this.props

		if (!claims)
			return null
		if (claims.length == 0) {
			const amount = prefill && medicare_item && pending_items.reduce((total,i)=>total+(medicare_model.MEDICARE_ALL_ITEMS[i]?.benefit || medicare_model.MEDICARE_ALL_ITEMS[i]?.amount || 0),0) || 0
			
			return prefill && medicare_item && <Fragment>
				<Icon name='medkit' /> {currency(amount).format()}
				<div>Status: <strong>PENDING</strong><br/>MBS item{pending_items.length>1&&'s'} {pending_items.join(", ")}</div>
			</Fragment> || <div>No MBS Items</div>
		}
		return claims.map((claim,i)=>{
			const payment = payments[0]
			
			return <div key={i}>
				
				<Icon name={claim.status=='COMPLETE'&&'check'||'medkit'} />
				{claim.status}
				<div>
					
				{(['INCORRECT','ERROR'].includes(claim.status)) 
						&& <Fragment key={i}>
							<div>
							<div>MBS item{pending_items.length>1&&'s'} {pending_items.join(", ")}</div>
						  	{app.user?.claims?.admin && 
								<>
									<div>Id: {claim.claimId || 'Unclaimed'}</div>
								</>
							}
						   {claim.status !='REJECTED' && <div>Error: {claim.error_message || 'Unable to claim'}</div>}
						   </div>
						</Fragment>
					}
					{claim.timeSubmitted && <div>Submitted: {moment.unix(claim.timeSubmitted).format("DD-MM-YYYY")}</div>}
					{claim.items && <div>
							{claim.items.map(i=><div key={i.itemNumber}>
								<div>MBS #{i.itemNumber}</div>
									<div style={{paddingLeft:'10px',borderLeft:'3px solid #ccc',fontSize:'.9em'}}>
									{i.benefit && <div>Paid: {i.benefit && i.benefit.map((b,i)=><span key={i}>{b.assessor} {currency(b.benefitPaid||0).format()}</span>)}</div>}
									{i.assessmentNotes?.length && <div>Assessment Notes: {i.assessmentNotes && i.assessmentNotes.map((a,i)=><span key={i}>{a.code}: {medicare_model.REASON_CODES[a.code]}</span>)}</div> || null}
								</div>
	
							</div>)}
					
					</div>
					}
				
				</div>
			</div>
			
		})
	}

}

export default MedicareClaim;
