import React from 'react';

const TreatmentPlanTermsOfService = ({ embedded }) => (
	<div className="terms">
		{!embedded && <h1><b>Treatment Plan - Terms of Service</b></h1>}
		<p>We, InstantScripts Pty Ltd (ACN 629 120 234), own and operate the "InstantScripts" platform, which is accessible via instantscripts.com.au and the InstantScripts App.</p>

		<p>Throughout these Terms of Use, the terms "we", "us" and "our" refer to InstantScripts.</p>

		<p>The use of Treatment Plans by any user (you) is subject to these Terms of Use. By using the Treatment Plans, you agree to these Terms of Use which, together with the Privacy Policy, Platform Terms of Use and Customer Terms of Use govern your access to and use of Treatment Plans.</p>

		<h3>1. Treatment Plan Charges</h3>
		<p>InstantScripts offers products and services that can be purchased on an automatically renewing subscription basis for a period of time.</p>
		<p>For subscription-based products and services, you will be billed for an initial doctor's consultation. If eligible for the treatment place, you will also be billed for medication and delivery and then again for medication and delivery at regular intervals, as described during the checkout process, until your treatment plan is complete or your treatment plan is cancelled.</p>
		<p>You expressly authorise InstantScripts to process the payment for the treatment plan via your relevant payment method at the time a checkout is completed. We may, upon written notice to you, change the price for your current treatment plan.</p>
		<p>Prior to the end of your treatment plan, you will receive a reminder for a doctor's consultation to review your condition and determine if it is appropriate to continue your treatment plan on a subscription-basis for a further period of time.</p>

		<h3>2. Treatment Plan Cancellations</h3>
		<p>You may cancel your treatment plan at any time. There are two ways to cancel your treatment plan:</p>
		<ol className="list-decimal">
			<li>via your Personal Account, by selecting  "Order history & reorder" from the menu and clicking on the "Cancel" button for the relevant Treatment Plan under the "Subscriptions" heading.</li>
			<li>by sending an email to info@instantscripts.com.au</li>
		</ol>
		<p>When you cancel your treatment plan, you will not be charged for and will not receive future orders under that treatment plan, subject to the Treatment Plan Refund section below.</p>

		<h3>3. Treatment Plan Refund</h3>
		<p>Generally, we are not able to issue refunds for orders on the day they are processed.</p>
		<p>Subject to rights that cannot be waived under Australian Consumer Law, cancellations after the Refund Cutoff Time will not be subject to a refund.</p>
		<p>The Refund Cutoff Time is 11:59pm two days before the applicable order processing date of your subscription.</p>
		<p>For example: If your order is due to process on the 15th of July, you must cancel your subscription by 11:59pm on the 13th July. If you cancel your subscription after this cut off period, you will not be eligible for a refund on the order.</p>
		<p>InstantScripts will send out a billing reminder three days before payment is due, noting the date of when the next payment is due, allowing satisfactory time for patients to cancel this next repeat if desired. Patients are ineligible for refunds if they claim to not be aware of the “automatic billing” process.</p>
		<p>In some circumstances, if you cancel your order after the Refund Cutoff Time you may receive a refund. Those circumstances are:</p>

		<ol className="list-alpha">
			<li>our Partner Pharmacy cannot fulfil your order due to lack of stock</li>
			<li>a product or pricing error is identified</li>
			<li>a product is faulty</li>
		</ol>
		<p>If you are eligible for a refund, it will be made using the payment method you used for the original transaction. We cannot process refunds to alternative cards or payment methods.</p>
		<p>With all medications, efficacy cannot be guaranteed, some treatments may prove effective for patients that have proved ineffective for others. Finding the correct treatment can be trial and error, this is indicative of the processes within hospital and GP healthcare settings also.</p>

		<p>Last update: November 2022</p>
	</div>
);

export default TreatmentPlanTermsOfService;