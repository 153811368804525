import React from 'react'
import { Icon, Popup, Button } from 'semantic-ui-react'

import db_lib from 'xAppLib/libs/db_lib';
import MedSelect from 'xAppLib/UIelems/MedSelect'
import ProdsSelect from '../xAppLib/UIelems/ProdsSelect';

const DEBUG = false;

export const _BASE_CONF = {
	MEDCONS:false,
	MEDBUY:false,
	QUALCONS:false,
	CERT:false,
	REFR:false,
	PATHO:false,
	DOCCONS:false,
	SHIPPING:false,
	new_user:false
}


export const DISCOUNT_SCRIPT_TYPES = [
    "doccons", // cons
    "medcons", // express
	"medbuy", // medication cost (when delivery is selected)
	"qualcons", // review consults
    "patho", // patho
    "refr", // referral
    "cert", // med certs
	"shipping",
	"prods", // cosm prods
];
const SCRIPT_TYPES_MAP = {
    doccons: "Consultation",
    medcons: "Express Script",
	medbuy: "Medication Cost",
	qualcons: "Review Consult",
    patho: "Pathology Referral",
    refr: "Specialist Referral",
    cert: "Medical Certificate",
	shipping: "Shipping",
	prods: "Products",
};

export const _TYPES = {
	free_script : {
		label:'Free Scripts',
		conf : {
			rule:'free',
			MEDCONS:true,
		}
	},
	free_cert : {
		label:'Free Cert',
		conf : {
			rule:'free',
			CERT: true,
		}
	},
	free_refr : {
		label:'Free Referral',
		conf : {
			rule:'free',
			REFR: true,
		}
	},
	free_doccons : {
		label:'Free Telehealth Consultation',
		conf : {
			rule:'free',
			DOCCONS:true,
		}
	},
	free_patho : {
		label:'Free Pathology Consultation',
		conf : {
			rule:'free',
			PATHO: true,
		}
	},
	free_shipping : {
		label:'Free Shipping',
		conf : {
			rule:'free',
			SHIPPING:true,
		}
	},
	percentage : {
		label:'Percentage',
		conf : {
			rule:'percentage',
			amount:0
		}
	},
	dollar : {
		label:'Dollar Amount',
		conf : {
			rule:'dollar',
			amount:0
		}
	},
	friend_referral : {
		label:'New Friend',
		conf : {
			rule:'dollar',
			amount:5,
			new_user:true,
		}
	},
	reward_referral: {
		label:'Reward for Referral',
		conf : {
			rule:'dollar',
			amount:5,
		}
	},
	org_referral: {
		label:'Paid Org Referral',
		conf : {
			rule:'percentage',
			amount:0,
		}
	}
}



const _LIST_FLDS_ADMIN = [
						{
							name: 'Num',
							jpath: 'dnum',
						},
						{
							name: 'Code',
							jpath: 'code',
						},
						{
							name: 'Enabled',
							jpath: 'enabled',
							template : ({value:enabled}) => <React.Fragment>
											<Icon name={enabled && 'check circle outline' || 'x'} color={enabled&&'green'||'red'} size='large'/>
										</React.Fragment>
						},
						{
							name: 'Type',
							jpath: 'type',
							template : ({value:type}) => <React.Fragment>
											{_TYPES[type] && _TYPES[type].label || `Unknown ${type}`}
										</React.Fragment>
						},
						{
							name: <>
									Configuration
									<Popup
									    trigger={<Button style={{marginLeft:5,transform:'scale(.7,.7)'}} icon='question' size='mini' circular/>}
									    basic
									  >
									  <Popup.Header>Legend</Popup.Header>
									    <Popup.Content>
											<Icon name="shopping basket" /> Medbuy<br/>
											<Icon name="mobile" /> Medcons<br/>
											<Icon name="doctor" /> Doccons<br/>
											<Icon name="call" /> Call a doctor<br/>
											<Icon name="truck" /> Shipping<br/>
											<Icon name="user plus" /> For New users<br/>
											<Icon name="user circle"  /> Restricted to a user<br/>
											<Icon name="hand peace" /> Free Standard Shipping<br/>
									    </Popup.Content>
								  </Popup>
								</>,
							jpath: 'conf',
							template : ({value:conf,row}) => {
								const { type } = row
								const { MEDCONS, MEDBUY, REFR, CERT, PATHO, SHIPPING, DOCCONS, new_user, rule, amount, free_standard_shipping } = conf || {};
								return <React.Fragment>
												{(_=>{
													switch (rule) {
														case 'dollar':
															return `$${amount}`
														case 'percentage':
															return `${amount}%`
														case 'free':
															return `FREE`
													}
														
												})()}<br/>
												<Icon name="shopping basket" disabled={!MEDBUY} />
												<Icon name="mobile" disabled={!MEDCONS} />
												<Icon name="call" disabled={!REFR} />
												<Icon name="edit" disabled={!CERT} />
												<Icon name="lab" disabled={!PATHO} />
												<Icon name="truck" disabled={!SHIPPING} />
												<Icon name="doctor" disabled={!DOCCONS} />
                                                <Icon name="hand peace" disabled={!free_standard_shipping}/>
												<Icon name="user plus" disabled={!new_user}/>
												<Icon name="user circle" disabled={!row?.users?.email} />
											</React.Fragment>
							}
						},
						{
							name: 'Expiry',
							jpath: 'expiry_date',
							type:'date'
						},
						{
							name: 'Organisation',
							jpath: 'orgs',
							template : ({value:orgs}) => <React.Fragment>
											{orgs?.name}
										</React.Fragment>
						},
						{
							name: 'Recipients',
							jpath: 'compound',
							parts: [
								{
									name: 'users',
									jpath: 'users'
								},
								{
									name: 'recipients',
									jpath: 'recipients'
								},
							],
							template : ({ row }) => (
								<React.Fragment>
									{row?.users?.email || row?.recipients?.map(r => {
										if (!r.email) return null;

										const count = row.usages?.find(u => u.email === r.email)?.count;
										return (
											<p>{r.email} {count ? `(${count})` : ""}</p>
										)
									})}
								</React.Fragment>
							)
							
						},

						{
							name: 'Usage',
							type: 'compound',
							parts: [
								{
									name: 'max_use',
									jpath: 'max_use'
								},
								{
									name: 'used',
									jpath: 'used'
								},
								{
									name: 'max_use_user',
									jpath: 'max_use_user'
								},
							],
							template : ({max_use,used,max_use_user}) => <React.Fragment>
											{used||0}/{max_use === null ? '∞' : max_use}
											{max_use !== null && max_use_user && <React.Fragment>/<Icon size='small' name='user'/></React.Fragment>}
										</React.Fragment>
						},


					];

const _LIST_FLDS_MANAGER = _LIST_FLDS_ADMIN.filter(c => c.jpath !== "orgs");

const _LIST_FLDS_ORG = [
						{
							name: 'Code',
							jpath: 'code',
						},
						
						{
							name: 'Recipients',
							jpath: 'compound',
							parts: [
								{
									name: 'users',
									jpath: 'users'
								},
								{
									name: 'recipients',
									jpath: 'recipients'
								},
							],
							template : ({ row }) => (
								<React.Fragment>
									{row?.users?.email || row?.recipients?.map(r => {
										if (!r.email) return null;

										const count = row.usages?.find(u => u.email === r.email)?.count;
										return (
											<p>{r.email} {count ? `(${count})` : ""}</p>
										)
									})}
								</React.Fragment>
							)
							
						},

						{
							name: 'Usage',
							type: 'compound',
							parts: [
								{
									name: 'max_use',
									jpath: 'max_use'
								},
								{
									name: 'used',
									jpath: 'used'
								},
							],
							template : ({max_use,used}) => <React.Fragment>
											{used||0}/{max_use || '∞'}
										</React.Fragment>
						},


					];
					
const _EDIT_FLDS_MANG = [
	{
		label: 'Recipients',
		name: 'recipients',
		type: 'json',
		valid_not_required:true,
	},
];

					
const _EDIT_FLDS_ADMIN = [
	{
		label: 'Code',
		name: 'code',
		type: 'text',
		placeholder:"Leave blank to auto generate code",
		valid_not_required:true,
	},
	{
		label: 'Enabled',
		name: 'enabled',
		type: 'bool',
	},
	{
		type: "org",
		name: "orgs",
		label: "Organisation",
		valid_not_required:true,
	},
	{
		type: "org",
		name: "recipient_orgs",
		label: "Recipient Organisations",
		multiple: true,
		valid_not_required:true,
	},
	{
		label: 'Expiry',
		name: 'expiry_date',
		type:'date',
		valid_not_required:true,
	},
	{
		label: 'Recipient (email)',
		name: 'users',
		valid_not_required:true,
	},
	{
		label: 'Recipients (email)',
		name: 'recipients',
		type: 'json',
		valid_not_required:true,
	},
	{
		label: 'Max Use',
		name: 'max_use',
		valid_not_required:true,
	},
	{
		label: 'Max Use is per user',
		name: 'max_use_user',
		type:'bool',
	},
	{
		label: 'New User Only',
		name: 'new_user',
		type: 'bool',
	},
	{
		name: 'meds',
		label: 'Meds Select',
		type: MedSelect,
		valid_not_required: true,
	},
	{
		name: 'products',
		label: 'Products Select',
		type: ProdsSelect,
		valid_not_required: true,
	},
	{
		label: 'Type',
		name: 'type',
		type: 'radio',
		options: [
			{
				c: 'percentage',
				n: 'Percentage'
			},
			{
				c: 'dollar',
				n: 'Dollar Amount'
			}
		],
	},
	{
		label: 'Value',
		name: 'value',
	},
	...DISCOUNT_SCRIPT_TYPES.map((t, index) => ({
		label: index ? " " : "Applied To:",
		name: t,
		type: 'checkbox',
		valid_not_required: true,
		options: [{
			c: t,
			n: SCRIPT_TYPES_MAP[t],
		}]
	})),
    {
		label: 'Free Standard Shipping',
		name: 'free_standard_shipping',
		type:'bool',
        valid_not_required:true
	},
	{
		label: 'Min Age',
		name: 'min_age',
		valid_not_required:true
	},
	{
		label: 'Max Age',
		name: 'max_age',
		valid_not_required:true
	},
];

const _EDIT_FLDS_ORG = [
						{
							label: 'Recipient (email)',
							name: 'users',
						},

					];





const _MODEL_NAME = 'discounts_model';

const _USE_DATA = 'API'
const _API_NAME = 'discounts'


// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class discounts_model extends db_lib {

	static get LIST_FLDS_ADMIN() { return _LIST_FLDS_ADMIN; }
	static get LIST_FLDS_MANAGER() { return _LIST_FLDS_MANAGER; }
	static get LIST_FLDS_ORG() { return _LIST_FLDS_ORG; }
	static get EDIT_FLDS_ADMIN() { return _EDIT_FLDS_ADMIN; }
	static get EDIT_FLDS_ORG() { return _EDIT_FLDS_ORG; }
	static get EDIT_FLDS_MANG() { return _EDIT_FLDS_MANG; }
	static get BASE_CONF() { return _BASE_CONF; }
	static get TYPES() { return _TYPES; }
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }



	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	
	static async load_discounts (data) {

		DEBUG && console.log('discounts_model :: load_discounts sending ', data);

		return await discounts_model.get_record(null, null, {act:'discounts_list', ...data});


	}
	
	
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async delete_discount(did) { 
			const res = await discounts_model.get_record(null, null, {act:'del', did});
			return res
		}
		
			
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async get_discount_by_code(code, meta, rest = {}) { 
			const res = await discounts_model.get_record(null, null, {act:'from_code', code, meta, ...rest});
			return res
		}
		
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async get_referral_code(create = false) {
			const res = await discounts_model.get_record(null, null, {act:'referral_code', create});
			return res
		}
		
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async get_org_referral_code(target_email, create = false) { 
			const res = await discounts_model.get_record(null, null, {act:'org_referral_code', target_email, create});
			return res
		}
	
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------

		static async save_discount( did, d, ca ) {
			if (!d.conf)
				d.conf = {}
			let res
			if (did) {
				res = await discounts_model.get_record(null, null, {act:'upd', did, data:{...d}});
			} else {
				res = await discounts_model.get_record(null, null, { act:'add', data:{...d} } );
			}
			DEBUG && console.log({res});
			ca && ca(res)
		}
}
