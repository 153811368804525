import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import UniForm from "xAppLib/UniForm/UniForm";
import script_model from "models/script_model";
import { useProfile } from "xAppLib/Hooks";
import ProfileSelect from "xAppLib/Users/ProfileSelect";
import { useContextProfile } from "xAppLib/Hooks/Users";
import UniFormMed from "views/med/UniFormMed";
import { missing_profile_fields, PROFILE_FIELDS } from "views/med/utils";
import user_model from "models/user_model";

function validate_fields(fields,{need_ihi,has_ihi,use_profile,no_medicare,script_type,req_type, prof_miss, store}) {
	return fields.map(({...f})=>{
		if (PROFILE_FIELDS.includes(f.name)) {
			f.name === 'sex' && (f.disallow_null = true);
			f.valid_not_required = false
		} else if (f.name == 'medicare') {
			if (script_model.is_bulk_billed(script_type, req_type)) {
				f.valid_not_required = false;
			} else if (app.acl.app_use) {
				f.valid_not_required = (!need_ihi || has_ihi) && (use_profile || !!no_medicare)
			}
		}
		else if (f.name == 'address') {
			f.valid_not_required = /*script_type=='cosm' || */ prof_miss?.includes('full_address') || ['medbuy','exprbuy','qualbuy'].includes(script_type)
		}
		else if (f.name == 'full_address') {
			f.valid_not_required = /*script_type=='cosm' || */['medbuy','exprbuy','qualbuy'].includes(script_type)
		}

		if (prof_miss?.includes(f.name)) {
			if (f.name === 'full_address') {
				f.type = 'address';
			}
			f.valid_not_required = f.name === 'cons_allergy' ? store.cons_has_allergies === 'no' : false;
		}

		return f
	})
}

const ProfileSection = (props) => {
	const { enabled, med_data, script_type, req_type, onUpdate, use_profile, no_medicare, defaultValues, Section, prof_compl, prof_miss, need_ihi, has_ihi, validsErrors, store, showErrors, acc_must_verif } = props
	const [fields, setFields] = useState([]);
	const [prof_fields, setProfFields] = useState([]);
	const [profile, profiles] = useProfile()
	const [activeIndex, setActiveIndex] = useState(defaultValues.profile);
	const shouldInlineEdit = !!prof_miss.length;

	useEffect(() => {
		if (!enabled)
			return 

		// This is kind of annoying but because of the nested uniforms we need both to handle data at different levels
		const fields = validate_fields(script_model.COMM_USR_FIELDS,{need_ihi,has_ihi,use_profile,no_medicare,script_type,req_type, prof_miss, store})
		const prof_fields = validate_fields(user_model.PROF_FIELDS_MY,{need_ihi,has_ihi,use_profile,no_medicare,script_type,req_type})
		setFields(fields);
		setProfFields(prof_fields)

	}, [med_data, script_type, req_type, has_ihi, no_medicare,enabled, store.cons_has_allergies, prof_miss]);



	const selectProfile = useCallback((p,i)=>{
		setActiveIndex(i)
		onUpdate?.({profile:i})
	},[setActiveIndex,profiles])

	const activeProfile = profiles[activeIndex]

	if (!enabled)
		return null


	return (
		<UniFormMed
		{...props}
		section="profile"
		fields={fields}
	>
		{(values, valids, uf_this, fields) => {
			return <Section>

					<Section.Header>Verify your details</Section.Header>
					<Section.Content>
						{!prof_compl && <p className='text error'>This profile is incomplete.</p> || null}

						{prof_miss.length > 0 && 
							<p className='text error'>
								To request this script, it is necessary to provide details regarding your {prof_miss.map((p) => validsErrors[p]).join(', ')}.
							</p>
						}
						{/*need_ihi: {need_ihi?.toString()}, has_ihi: {has_ihi?.toString()}, prof_compl: {prof_compl?.toString()}, prof_miss: {prof_miss?.toString()}*/}
						
						<ProfileSelect
							fields={prof_fields}
							requireAddress
							canSelect
							canBrowse={false}
							medForm={true}
							onSelect={selectProfile}
							active={activeIndex}
							prof_compl={prof_compl}
							need_ihi={need_ihi}
							medform={true}
							shouldInlineEdit={shouldInlineEdit}
							showErrors={shouldInlineEdit}
							acc_must_verif={acc_must_verif}
						/>					
					</Section.Content>

			</Section>
		}}
		</UniFormMed>
	);
};

export default ProfileSection;
