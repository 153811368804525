import React, { useState } from "react";
import { Button } from "semantic-ui-react";

/**
 * Extends semantic UI button with basic async handling.
 * Will disable the button and show a spinner until the `onClick` callback settles.
 * Prevents double-clicking from making duplicate API calls, and provides feedback
 * to the user the request is in progress.
 * The `disabled` and `loading` attributes can still be set so a button can still
 * be disabled while the user hasn't finished filling out a form, for example.

 * @example
 * const fakeApiCall = () => new Promise(resolve => setTimeout(resolve, 1000));
 * <AsyncButton basic color='orange' onClick={() => fakeApiCall()}>
 *     I will be spinning and disabled for 1 second
 * </AsyncButton>
 * @param onClick
 * @param buttonProps
 * @return {JSX.Element}
 * @constructor
 */
export function AsyncButton({onClick, ...buttonProps}) {
	const [inFlight, setInFlight] = useState(false);
	const loading = inFlight || buttonProps.loading;
	const disabled = inFlight || buttonProps.disabled;

	const handleClick = async (...args) => {
		setInFlight(true);
		try {
			await onClick?.(...args);
		} finally {
			setInFlight(false);
		}
	}

	return <Button {...buttonProps} onClick={handleClick} disabled={disabled} loading={loading} />;
}

