import React, { useEffect } from "react";
import { useExperiment } from "xAppLib/Hooks";

const DEBUG = false;

const Experiment = ({ id, children, quiet = false }) => {
	const v = useExperiment(id);
	const selected = React.Children.map(children, (element) => {
		DEBUG && console.log("variant", element.props.variant, v, v?.n === element.props.variant);
		if (v?.n == element.props.variant || (v === undefined && element.props.defaultVariant))
			return element;
	}).filter(Boolean);

	if (selected.length == 0 && children?.length) {
		console.log("could not find a variant, using first one");
		selected.push(children[0])
	}

	useEffect(()=>{
		if (!quiet)
			v?.f?.()
	},[id,v])

	return (
		<>
			{DEBUG && <>{id}/{v}</>}
			{selected}
		</>
	);
};

const ExperimentVariant = ({children}) => {
	return children;
};

const ExperimentVariantFunc = ({children}) => {
	return children?.();
};

Experiment.Variant = ExperimentVariant
Experiment.VariantFunc = ExperimentVariantFunc

export default Experiment;
