import db_lib from 'xAppLib/libs/db_lib';

const _USE_DATA = 'API';
const _API_NAME = 'media';

export const MEDIA_TYPE = {
  PAGE: 'pages'
}

export default class media extends db_lib {
	static get USE_DATA() {
		return _USE_DATA;
	}
	static get API_NAME() {
		return _API_NAME;
	}

	static async get_media_list(data) {
		return await media.get_record(null, null, { act: 'list', ...data });
	}

	static async find_media(query, type) {
		return await media.get_record(null, null, { act: 'fnd', query, type });
	}

	static async save_image(data) {
		return await media.get_record(null, null, { act: 'add', ...data });
	}
}
