import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'prod_cat';
const _DET_FIELDS = [
						// {
						// 	name: "type",
						// 	label:'Type', 
						// 	type: "radio",
						// 	options: [{c:'g', n:'Group'}, {c:'i', n:'prodanisation'}],
						// 	placeholder: "Type?",
						// 	param_name: "type",
						// 	req_label: "*",
						// },

						{
							name: "pid",
							label:'pid', 
							type: "text",
							// placeholder: "pid",
							param_name: "pid",
							// req_label: "*",
							read_only: true,

							// view_link_to: true,
						},

						{
							name: "onum",
							label:'onum', 
							type: "number",
							// placeholder: "pid",
							param_name: "onum",
							// req_label: "*",
							read_only: true,

							// view_link_to: true,
						},

						{
							name: "name",
							label:'Name', 
							type: "text",
							placeholder: "Name",
							param_name: "name",
							req_label: "*",

							view_link_to: true,
						},

						// {
						// 	name: "code",
						// 	label:'Unique code', 
						// 	type: "text",
						// 	placeholder: "Code",
						// 	pattern: "a-zA-Z0-9_-",
						// 	param_name: "c",
						// 	req_label: "*",
						// },

						{
							name: "desc",
							label:'Description', 
							type: "textarea",
							placeholder: "Description",
							param_name: "desc",
							req_label: "*",
							valid_not_required: true,
						},

					];

const _CAT_FIELDS = [
						{
							name: "ord",
							label:'ord', 
							type: "number",
							placeholder: "order",
							param_name: "ord",
							valid_not_required: true,
						},

						{
							name: "code",
							type: "text",
							label: "Code",
							placeholder: "Code ID (optional)",
							// pattern: "a-zA-Z0-9_-",
							param_name: "pid",
							req_label: "*",
							valid_not_required: true,

							view_link_to: true,
						},

						{
							name: "name",
							label:'Name', 
							type: "text",
							placeholder: "Name",
							param_name: "name",
							req_label: "*",

							view_link_to: true,
						},

						{
							name: "desc",
							label:'Description', 
							type: "textarea",
							placeholder: "Description",
							param_name: "desc",
							req_label: "*",
							valid_not_required: true,
						},

					];


const _MODEL_NAME = 'prods_model';

const _USE_DATA = 'API'
const _API_NAME = 'prodsAdmin'

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class prods_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get CAT_FIELDS() { return _CAT_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_prods_tree (group) {

		console.log('tree data cache check', !(app.app_data.ws_data), !(app.app_data.ws_data&&app.app_data.ws_data.prods), !(app.app_data.ws_data&&app.app_data.ws_data.prods&&app.app_data.ws_data.prods.tree));

		if (!(app.app_data.ws_data&&app.app_data.ws_data.prods&&app.app_data.ws_data.prods.tree)) {

			const res = await prods_model.get_record(null, null, {act:'tree',group});

			if (!app.app_data.ws_data)				app.app_data.ws_data = {prods: {tree: res}};
			else if (!app.app_data.ws_data.prods)	app.app_data.ws_data.prods = {tree: res};
			else app.app_data.ws_data.prods.tree = res;

			console.log('tree data cached');
		} else {
			console.log('tree data from cache');
		}

		return app.app_data.ws_data.prods.tree;

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_prod (pid) {

		return await prods_model.get_record(null, null, {act:'get', pid:pid});


		// if (!(app.app_data.ws_data&&app.app_data.ws_data.prods&&app.app_data.ws_data.prods.by_onum&&app.app_data.ws_data.prods.by_onum[onum])) {

		// 	const res = await prods_model.get_record(null, null, {act:'get', onum:onum});

		// 	if (!app.app_data.ws_data)					app.app_data.ws_data = {prods: {by_onum: {[onum]: res}}};
		// 	else if (!app.app_data.ws_data.prods)		app.app_data.ws_data.prods = {by_onum: {[onum]: res}};
		// 	else if (!app.app_data.ws_data.prods.by_onum)	app.app_data.ws_data.prods.by_onum = {[onum]: res};
		// 	else app.app_data.ws_data.prods.by_onum[onum] = res;

		// 	console.log('prod data cached');
		// } else {
		// 	console.log('prod data from cache');
		// }

		// return app.app_data.ws_data.prods.by_onum[onum];

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

}
