import React from 'react';
import { Loader } from 'semantic-ui-react';
const LoadingScreen = ({loadingWhat}) => {
	return (
		<>
			<div className='mt-6 h-[60vh] flex items-center justify-center'>
				<Loader inverted inline='centered'>
					Loading {loadingWhat}
				</Loader>
			</div>
		</>
	);
};

export default LoadingScreen;
