import React, { Component } from 'react';
import {
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Form,
	} from 'semantic-ui-react'
import notes_model from 'models/notes_model'	

export const Flag_Levels = {
	'info':"info circle",
	'warn':"warning circle",
	'consult':"doctor",
	'alert':"warning sign",
//	'default': 'flag checkered'
}	

export const Flag_LevelColours = {
	'info': "green",
	'warn': "yellow",
	'consult': 'blue',
	'alert': "red",
	'default': 'grey',
}
	
class UserFlag extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			show:false,
			data:this.props.notes || {}
			
		};
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.notes != this.props.notes) {
			this.setState({data:{...this.state.data,...(this.props.notes||{})}})
		}
	}
	
	handleChange = (e, { name, value }) => this.setState({ data:{...this.state.data,[name]: value} })

	async onSubmit() {
		const nid = this.state.data.nid
		let data = {
			type:'usr_flag',
			...this.state.data,
		}
		
		if (!nid) {
			data = {
				...data,
				uid:this.props.uid || null,
				ui:this.props.ui|| null,
				email: this.props.email || null
			}
		}
		this.setState({loading:true})
		const { res, id }  = await notes_model.save_note(nid,data)
		this.setState({loading:false})
		if (res == 'ok') {
			this.setState({show:false})
			this.props.onUpdate && this.props.onUpdate({notes:data})
		} else {
			alert("Unable to flag user.")
		}
		
	}

	render() {
		const { show, data, loading } = this.state
		
		const levels = Object.keys(Flag_Levels).map(k=>({ key: k, text: k, value: k, icon:Flag_Levels[k] }))
		
		return (
			<Popup
					trigger={<Button 
							icon={this.props.icon || "flag"}
							color={Flag_LevelColours[data.lvl]}
							onClick={ _=> this.setState({show:!show}) }
						/>}
					open = {show}
					position='left center'
					style={{}}
			>
				<Popup.Header>
					<Icon 
							link 
							name='close' 
							style={{float:'right'}} 
							onClick={_=>this.setState({show:false})} 
						/>
					<p>{this.props.title || 'Flag User'}</p>
						
				</Popup.Header>
				<br/>
				<Form>
					<Form.Select
			            fluid
			            label='Severity Level'
			            options={levels}
						name='lvl'
			            placeholder=''
						value={data.lvl}
						onChange={this.handleChange}
			          />
		          
				  <Form.Input fluid label='Summary' name='cont' value={data.cont} placeholder='Reason for flag' onChange={this.handleChange} />
          
					<Form.TextArea label='Details' name='desc' value={data.desc} placeholder='Any relevant information' onChange={this.handleChange} />
        	
		
					<Button type='submit' 
							fluid color="green" 
							loading={loading} 
							onClick={_=>this.onSubmit()}>Save</Button>
				</Form>


			</Popup>
		);
	}

}

export default UserFlag;
