import React, { useState } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import MedicationSelector from 'views/UIelems/MedicationSelector';

const SearchPBSButton = ({ onChange, buttonProps, label = 'Search PBS Medicine' }) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<Button {...buttonProps} onClick={() => setShowModal(true)} data-testid="button-search-pbs">
				<Icon name="search" />
				{label}
			</Button>
			{showModal && (
				<Modal open onClose={() => setShowModal(false)}>
					<MedicationSelector
						onChange={(p) => {
							onChange?.(p);
							setShowModal(false);
						}}
					/>
				</Modal>
			)}
		</>
	);
};

export default SearchPBSButton;
