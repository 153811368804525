import moment from "moment/moment";
import groupBy from "xAppLib/helpers/groupBy";
import {obj_map} from "xAppLib/helpers/obj_map";
import cart_model from "../../../models/cart_model";
import logger from "../../../xAppLib/libs/logger";
import API_service from "../../../xAppLib/providers/API_service";
import gtm from "../../../xAppLib/providers/gtm";
import {get_gtm_product, log_scr_req} from "../../med/utils";

export const isLargeScreen = width => width >= 1024; // tailwindcss definition of large

/**
 * Convert a list to a lookup object. The keyFn is used to determine the key for each item, and the valFn is used to
 * determine the value for each item. If valFn is not provided, the item itself is used.
 * This is for a single record per key. Throws an error if duplicate keys.
 * @param list
 * @param keyFn
 * @param valFn
 * @return {Object}
 */
export function toDictionary(list, keyFn, valFn = item => item) {
	const lookup = {};

	for (const item of list) {
		const key = keyFn(item);
		if (lookup[key]) {
			throw new Error(`Duplicate key: ${key}`);
		}
		lookup[key] = valFn(item);
	}

	return lookup;
}

/**
 * Like `Promise.all`, but for an object. Will resolve to an object with the same keys, but with the values resolved.
 * If any promise rejects, the entire result rejects
 * @param objOfPromises
 * @return {Promise<{[p: string]: unknown}>}
 */
export async function resolveValues(objOfPromises) {
	const entries = await Promise.all(
		Object.entries(objOfPromises).map(
			async ([key, val]) => [key, await val]
		)
	);

	return Object.fromEntries(entries);
}

export function rejectOnResErr(data) {
	return data?.res === 'err' ? Promise.reject(data) : data;
}

export function categorisePhotos(photos = []) {
	const photosByType = groupBy(
		photos,
		photo => ['before', 'after'].includes(photo.type) ? photo.type : 'other'
	);

	return {
		before: photosByType.before ?? [],
		after: photosByType.after ?? [],
		other: photosByType.other ?? [],
	};
}

export function trimStringValues(obj) {
	return obj_map(obj, val => typeof val === 'string' ? val.trim() : val);
}

export function checkMinimumAge(dob, allow_age) {
	const isOldEnough = moment().diff(dob, 'years') >= allow_age;

	if (isOldEnough) {
		return [];
	}

	return [
		{
			message: `You must be at least ${allow_age} years old to use this service.`,
			type: 'error',
		}
	];
}

export function checkAnswers(values, qs) {
	return Object.entries(qs)
		.map(([questionId, config]) => {
			const answerId = values[questionId];
			const answer = config.a[answerId];

			if (answer?.err_txt) {
				return {
					message: answer.err_txt,
					type: answer.res === 'p' ? 'warn' : 'error',
				}
			}
		})
		.filter(Boolean);
}



// for lack of a better name...
export function makeTelemetry(form, med_data) {
	const script_type = 'cosm';
	const req_type = 'cosm';

	const reqScriptFormData = {
		script_type,
		req_type,
		sign: form.form_data.sign,
		...form,
	};

	const form_track = (evt, status) => {
		gtm.event(evt, `${evt}_${status}`, {error: status !== 'success'})
	}

	const gtm_products = form.form_data.products.map(product => (
		get_gtm_product({
			script_type,
			req_type,
			med_data: {m: product.mid, name: product.name},
			cart: new cart_model(),
			cat_nm: 'cosm',
			cat_route: product.mid,
			cat_avail_list: {[product.mid]: {n: product.path}}
		})
	));

	return {
		validationStarted() {
			form_track('continue_submit', 'pressed');
		},
		validationPassed() {
			form_track('continue_submit', 'success')
			gtm.checkout({step: 'submit'}, gtm_products);
		},
		validationFailed() {
			form_track('continue_submit', 'validation error');
		},
		businessRulesPassed() {
			gtm.checkout({step: 'sending'}, gtm_products)
		},
		businessRulesFailed() {
			form_track('send', 'before-sending-error')
			gtm.checkout({step: 'error'}, gtm_products)
			log_scr_req({
				sid: null,
				err: 'form_errors',
				cart: new cart_model(),
				script_type,
				req_type,
				med_data,
			});

			API_service.api_promise('reqScriptForm_err', reqScriptFormData).catch(null);
		},
		apiResolved(res) {
			if (res?.res === 'ok') {
				form_track('send', 'success')
				gtm.purchase({
						id: res.sid,
						tax: 0,
						revenue: 0,
						shipping: 0,
						affiliation: 'InstantScripts'
					},
					gtm_products
				);

				gtm.mf(true, med_data?.name)

				logger.log_med_req_suc({
					sid: res.sid,
					price: 0,
					script_type,
					req_type,
					partnm: 'InstantScripts',
					med_nm: med_data?.name,
				})

				form_track('send', 'success')
			} else {
				logger.report_error(
					'ERROR Sending Script Request - CreateTreatmentForm.apiResolved()',
					'error',
					{
						req_data: reqScriptFormData,
						res,
					}
				);

				form_track('send', 'response_error')
				gtm.mf(false, med_data?.name)
			}

			log_scr_req({
				sid: res.sid,
				err: res.res !== 'ok' && res,
				cart: new cart_model(),
				script_type,
				req_type,
				req_data: reqScriptFormData
			});

			return res;
		},
		apiRejected(err) {
			logger.report_error(
				'ERROR Sending Script Request - CreateTreatmentForm.apiRejected()',
				'error',
				{
					req_data: reqScriptFormData,
					res: err,
				}
			);

			return Promise.reject(err);
		}
	}
}
