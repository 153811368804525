import API_service from 'xAppLib/providers/API_service'
import db_lib from 'xAppLib/libs/db_lib';
import doc_model from './doc_model'

const _FRDB_LOC = 'doc_cat';
const _DET_FIELDS = [
						{
							name: "type",
							label:'Type', 
							type: "radio",
							options: [{c:'g', n:'Group'}, {c:'i', n:'Doctor'}],
							placeholder: "Type?",
							param_name: "t",
							req_label: "*",
						},
						{
							name: 'active',
							label: 'Active',
							type: 'bool',
						},
						{
							name: "code",
							type: "text",
							label: "Code",
							placeholder: "Code ID (optional)",
							// pattern: "a-zA-Z0-9_-",
							param_name: "c",
							prop_name: "k",
							req_label: "*",
							valid_not_required: true,
							view_link_to: true,
						},

						{
							name: "name",
							label:'Name', 
							type: "text",
							placeholder: "Name",
							param_name: "n",
							req_label: "*",

							view_link_to: true,
						},

						// {
						// 	name: "code",
						// 	label:'Unique code', 
						// 	type: "text",
						// 	placeholder: "Code",
						// 	pattern: "a-zA-Z0-9_-",
						// 	param_name: "c",
						// 	req_label: "*",
						// },

						{
							name: "desc",
							label:'Description', 
							type: "textarea",
							placeholder: "Description",
							param_name: "d",
							req_label: "*",
							valid_not_required: true,
						},

					];
const _MODEL_NAME = 'docs_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

const cosm_base_path = '-LU96WxoXbHLbOy1vZPG/-LU96bkgGkgNBrTPe-lz'
const cosm_docs_path = cosm_base_path.replace(/\//g, '/i/') + /i/ 
const roster_path = cosm_base_path.replace(/\//g, '/i/') + /rost_weekly/ 
const GPs_path = '-M313GuMGr1fQ0xitD38/i/'

export default class docs_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

	static watch_roster(day, ca) {
		const path = roster_path + (day || '')
		return docs_model.watch_record(path, ca)
	}

// ---- --------------------------------------------  --------------------------------------------  

	static async get_flat_cats() {

		const docs_recs = await this.get_record()
		// console.log({docs_recs});

		let flat_cat_list = {}

		const recur_list_trav = (cat_data, par='', par_db='', par_nm='') => (
					cat_data && 
						Object.keys(cat_data)
							// .sort( (a, b) => cat_data[a].n.localeCompare(cat_data[b].n) )
							.map(
								(objk, i) => {
										// if (cat_data[objk].active==false) return;
										// flat_cat_list[par+objk] = cat_data[objk]
										flat_cat_list[objk] = JSON.parse(JSON.stringify(cat_data[objk]));
										flat_cat_list[objk].path_name = par_nm + (par_nm&&'/'||'') + flat_cat_list[objk].n
										flat_cat_list[objk].l = par
										flat_cat_list[objk].l_db = par_db
										cat_data[objk].i && recur_list_trav(cat_data[objk].i, par+objk+'/', par_db+objk+'/i/', flat_cat_list[objk].path_name)
										delete flat_cat_list[objk].i
									}
							)
		)

		// const cat_tree_data = await cat_model.get_record()
		
		// console.log(cat_tree_data);

		recur_list_trav(docs_recs)

		// console.log('flat_cat_list', flat_cat_list);

		app.app_data.ws_data.docs = { ...app.app_data.ws_data.docs, flat_cat_list }

		return flat_cat_list;
	}

// ---- --------------------------------------------  --------------------------------------------  

	static async get_roster(day) {
		const path = roster_path + (day || '')
		return (docs_model.get_record(path)) || []
	}
	
	static save_roster(roster) {
		docs_model.set_record( roster_path, roster )
	}
	
	static async get_docs() {
		return docs_model.get_record(cosm_docs_path)
	}
	
	static async get_GPs() {
		return docs_model.get_record(GPs_path)
	}
	
	static async get_docs_dets(docs) {
		if (!docs) {
			docs = await docs_model.get_docs()
			docs = Object.keys(docs)
		}
		const docs_dets = []
		await Promise.all(
				docs.map( async k => docs_dets.push({k, ...(await doc_model.get_record( k ))}) )
			)
		return docs_dets
	}

	static async get_gp_dets(docs) {
		if (!docs) {
			docs = await docs_model.get_GPs()
			docs = Object.keys(docs)
		}
		const docs_dets = []
		await Promise.all(
				docs.map( async k => docs_dets.push({k, ...(await doc_model.get_record( k ))}) )
			)
		return docs_dets
	}


	static async doc_signup (data, existing_doctor, existing_group) {
		return await API_service.load_data ('docsAdmin', {
			act: 'doc-signup',
			data: data,
			existing_doctor: existing_doctor,
			existing_group: existing_group
		})		
	}

	static async get_prefill_data() {
		try {
			return API_service.load_data('docsAdmin', {act: 'prefill'}).then(rsp => rsp.data);
		} catch (e) {
			console.error('get_prefill_data', e);
			return null;
		}
	}

}
