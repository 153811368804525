import React, { Component } from 'react';

import { Modal, Icon, Button, Header } from 'semantic-ui-react'


class Confirm extends Component {
	
	state = {
		showConfirm:false
	}

	componentDidMount() {
		// console.log("componentDidMount()")
		app.on(app.events.CONFIRM, this.showConfirm)
	}

	componentWillUnmount() {
		app.off(app.events.CONFIRM, this.showConfirm)
	}
	
	showConfirm = ({title,content, basic=false, primary, yes = 'Yes', no = 'No'},callback) => {
		this.setState({title,content,basic,yes,no, showConfirm:true,primary})
		this.callback = callback
	}
	
	confirm = (answer) => {
		this.setState({showConfirm:false})
		this.callback && this.callback(answer)
	}
	
	render() {
		let { title = 'Confirm Operation',
				content = 'Are you sure?',
				basic = false,
				yes,
				no,
				primary = false,
			} = this.state

			if (typeof content === 'string')
				content = <p className="text-md">{content}</p>
		return (
			<Modal size='small'
				 open={this.state.showConfirm}
				 data-testid="modal-confirm"
				>
			    <Modal.Header as='h1' content={title} />
			    <Modal.Content>
					{content}
			    </Modal.Content>
			    <Modal.Actions>
					{basic && <Button color='green' size='large' onClick={_=>{this.confirm('ok')}}>
			        Ok
			      </Button> || <>
						{no && <Button basic color={!primary ? 'red' : undefined} size='large' onClick={_=>{this.confirm('no')}}>
							<Icon name='remove' /> {no}
						</Button>}
						{yes && <Button primary={primary} color='green' size='large' onClick={_=>{this.confirm('yes')}}>
							<Icon name='checkmark' /> {yes}
						</Button>}
				  </>}
			      
			    </Modal.Actions>
			  </Modal>
		);
	}

}

export default Confirm;
