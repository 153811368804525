//	------------------------	------------------------	------------------------
//	Description: Pathology Formula
//  Version: 1.0.0
//  Updates: See https://docs.google.com/spreadsheets/d/1P8ygTBEoiXZKc3pAcjzYbek7ufv_eLiJUuUjDhWdJ-8/edit#gid=0
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { useEffect, useState } from 'react';
import { List, Input, Icon, Button, Table, Container, Segment, Header, Form, Checkbox, Card, Image, Label, Loader, Dimmer, Divider } from 'semantic-ui-react'
import { v4 as generate_id } from 'uuid';
import patho_model from 'models/patho_model'
import functions from 'models/patho/formula'
import DisplayField from '../components/DisplayField'
import moment from 'moment-timezone'
import FeedbackText from '../components/FeedbackText'
import Meds from '../components/Meds'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Definition
//	------------------------	------------------------	------------------------

const FormulaDefinition = ({ onUpdate, value = FormulaDefinition.defaultDefinition, children }) => {
	const sendUpdate = (name, new_value) => onUpdate({ ...value, [name]: new_value, id: value.id || generate_id() })
	const handleChange = (e, { name, value }) => sendUpdate(name, value)
	return 1 &&
		<Segment>
			<Form.Group widths='equal' style={{ alignItems: 'flex-end' }}>
				<Form.Input fluid label='Measurement' name='measure' value={value.measure} onChange={handleChange} />
				{children}
			</Form.Group>
			<Form.Group widths='equal' >
				<Form.Input fluid label='Unit' name='unit' value={value.unit} onChange={handleChange} />
			</Form.Group>
			<Form.Group widths='equal' style={{ alignItems: 'flex-end' }}>
				<Checkbox
					checked={!!value.negative_pass}
					onChange={(e, d) => handleChange(null, { name: 'negative_pass', value: d.checked })}
					toggle
					label="Pass if not found"
				/>
			</Form.Group>
			<Form.Group widths='equal' style={{ alignItems: 'flex-end' }}>
				<Form.TextArea label='Formula' name='formula' value={value.formula} onChange={handleChange} />
			</Form.Group>
			<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
				<Form.TextArea  label='Pathology certificate disclaimer' name='disclaimer' value={value.disclaimer} onChange={handleChange} />
			</Form.Group>
		</Segment>
}

Object.assign(FormulaDefinition, patho_model.MEASURE_TYPES.formula)

//	------------------------	------------------------	------------------------
//	Input
//	------------------------	------------------------	------------------------

const FormulaInput = ({ onUpdate, value = FormulaInput.defaultDefinition, children }) => {
	const handleChange = (e, { name, value: new_value }) => onUpdate({ ...value, [name]: new_value })
	return <>
		<Form.Group widths='equal' style={{ alignItems: 'flex-end' }}>
			<DisplayField fluid label={value.measure} />
		</Form.Group>

		<Form.Group widths='equal' style={{ alignItems: 'flex-end' }}>
			<Form.Input fluid label='Result' name='result' value={value.result || ''} onChange={handleChange} />
			<Form.Input width="3" disabled fluid label='Unit' value={value.unit} />
		</Form.Group>

	</>
}

Object.assign(FormulaInput, patho_model.MEASURE_TYPES.formula)

//	------------------------	------------------------	------------------------
//	View
//	------------------------	------------------------	------------------------

const FormulaView = ({ onUpdate, value = FormulaView.defaultDefinition, children, index, onNotify, extra }) => {

	let { result, em_low, low, high, em_high, measure, rec_low, rec_high, unit, feed_good, feed_em_low, feed_low, feed_em_high, feed_high, disable_emergency, formula } = value;
	
	[result, em_low, low, high, em_high] = [result, em_low, low, high, em_high].map(n => !!n ? Number(n) : undefined)

	const run_formula_res = run_formula({ value: result, formula, extra }) // So here we need the user, and the script info

	const level = run_formula_res && run_formula_res.res == 'ok' && run_formula_res.level

	const is_danger_low = level == 'em_low'
	const is_low = level == 'low'
	const is_danger_high = level == 'em_high'
	const is_high = level == 'high'
	const is_error = run_formula_res.res != 'ok'
	const is_good = !is_danger_low && !is_low && !is_danger_high && !is_high && !is_error
	
	const danger = !disable_emergency && (is_danger_low || is_danger_high)
	const warning = is_low || is_high || (disable_emergency && (is_danger_low || is_danger_high))

	useEffect(_ => {

		onNotify?.(index, {
			danger,
			warning,
			measure
		})

	}, [value])

	em_low = run_formula_res && run_formula_res.res == 'ok' && run_formula_res.em_low
	low = run_formula_res && run_formula_res.res == 'ok' && run_formula_res.low
	em_high = run_formula_res && run_formula_res.res == 'ok' && run_formula_res.em_high
	high = run_formula_res && run_formula_res.res == 'ok' && run_formula_res.high
	feed_good = feed_low = feed_high = feed_em_high = feed_em_low = run_formula_res && run_formula_res.res == 'ok' && run_formula_res.msg

	let range = null
	if (low!==undefined && high!==undefined)
		range = <>{low} - {high} {unit}</>
	else if (low===undefined)
		range = <>{"<"} {high} {unit}</>
	else if (high===undefined)
		range = <>{">"} {low} {unit}</>	

	return <>
		<Header>
			{measure}
			<Label size='small' color={danger || is_error ? 'red' : warning ? 'orange' : 'green'} horizontal>
				<Icon name={is_good ? 'check' : 'warning'} />
				{is_danger_low && (!disable_emergency && "Extremely low level. Please see your doctor urgently or visit a hospital ED." || "Very Low Level")}
				{is_danger_high && (!disable_emergency && "Extremely high level. Please see your doctor urgently or visit a hospital ED." || "Very High Level")}
				{is_low && "Low level"}
				{is_high && "High level"}
				{is_good && "Good"}
				{is_error && "Error"}
			</Label>
		</Header>

		<p>{result} {unit} {range && <small className='text grey'><br />Normal Range: {range}</small>}</p>

		{is_good && <FeedbackText>{feed_good}</FeedbackText>}

		{is_danger_low && <FeedbackText danger={!disable_emergency} warning={!!disable_emergency}>{feed_em_low}</FeedbackText>}
		{is_low && <FeedbackText warning>{feed_low}</FeedbackText>}

		{is_danger_high && <FeedbackText danger={!disable_emergency} warning={!!disable_emergency}>{feed_em_high}</FeedbackText>}
		{is_high && <FeedbackText warning>{feed_high}</FeedbackText>}

		{is_low && <Meds callDoctor={!is_good} heading={`Recommended Medicines For Low Levels ${measure}`} medsList={rec_low} />}
		{is_high && <Meds callDoctor={!is_good} heading={`Recommended Medicines For High Levels ${measure}`} medsList={rec_high} />}
		{!disable_emergency && (is_danger_low || is_danger_high) && <p>
			Your {measure} levels are dangerously {is_danger_low ? 'low' : 'high'}. Please see your doctor urgently or visit your local hospital emergency department.
		</p>}

		{is_error && <React.Fragment>
			<FeedbackText danger>Unable to process result</FeedbackText>
	 			<p>Please <a href="https://www.instantscripts.com.au/contact-us" target="_blank">contact InstantScripts Support</a> and quote:</p>
	 			<p>Pathology error: {measure}, {run_formula_res.err || 'Unknown'}</p>
			</React.Fragment>}

	</>
}

Object.assign(FormulaView, patho_model.MEASURE_TYPES.formula)

//	------------------------	------------------------	------------------------

function run_formula(params = {}) {

	let result = { res: 'ok' }

	try {

		const {value, formula, extra} = params

		// Additional
		const dob = moment(params?.extra?.spd_data?.dob, 'YYYY-MM-DD')
		const age = dob.isValid() && moment().diff(dob, 'years') || 0

		// Run formula
		const func = functions[formula]
		const run_res = func({value, extra, age})
		result = {...result, ...run_res}

	} catch (e) {
		//console.log(e)
		result = { ...result, res: 'err', err: `Error running run_formula: ${e}` }
	}

	return result

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export {
	FormulaDefinition,
	FormulaInput,
	FormulaView
}