import React from "react";

const proofs = [
  { link: "/ileg/images/proof1.jpg" },
  { link: "/ileg/images/proof2.png" },
];

const Proofs = () => {
  return (
    <div className="grid place-content-center md:grid-cols-2 grid-cols-1 gap-8 md:px-10">
      {" "}
      {proofs.map((proof) => {
        return (
          <div className="md:flex md:flex-col bg-white" key={proof.link}>
            <img
              className="w-2/3 md:w-full m-auto"
              src={proof.link}
              alt={proof.link}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Proofs;
