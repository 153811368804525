import React, {Component} from 'react'
import { Link } from 'react-router-dom'

import { Table, Input, TextArea, Radio, Checkbox, Select, Form, Button, List, Header } from 'semantic-ui-react'
import SimpleShowMore from '../UIelems/SimpleShowMore'
import UniFieldSUI from './UniField-SUI';


export default class UniFormSUI extends Component {

	constructor (props) {
		super(props)

		this.state = {
			values: [],
		}

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	fld_list_edit_render (fl) {

		const { fields, values, valids, uf_this, btns, admin_link_to_func, size, vertical, cust_fields } = this.props
		const { show='view' } = this.props
		const { valid_not_required } = this.props

		// console.log('UniFormSUI::fld_form_edit_render', values, fl);
		
		const wrapStyle = {display:'flex',alignItems:(vertical?'normal':'center'),flexDirection:(vertical?'column':'row'), marginBottom:(vertical?'15px':'0px')}
		const headerStyle = {marginRight:'30px', flex:1, textAlign:(vertical?'left':'right'),marginBottom:(vertical?'5px':'0px')}
	
		return <List >
				{
					fields
						// .map ( f => delete f.label )
						.map( (fl, i) => !['hidden', 'separator'].includes(fl.type) && !fl.edit_hide &&
						(!fl.showif || this.validate_showif(fl.showif, values)) &&
						<List.Item key={'itm_data'+fl.name+i} style={{...wrapStyle}}>
						<Header as='h4' style={{...headerStyle,...(fl.type=='address'&&fl.mode=='components'&&{paddingTop:'5px'}||{})}}>
							{fl.label || `UFO ${fl.name}`} {' '}
							{!fl.read_only && !fl.valid_not_required && !valid_not_required && (fl.req_label || '*')}
						</Header>

						<div style={{flex:3}}>
							{/*delete fl.label*/} 
							<UniFieldSUI

									fl = {fl}
									values = {values}
									valids = {valids}
									uf_this = {uf_this}

									valid_not_required = {valid_not_required}

									show_label = {false}

									cust_fields = {cust_fields}

									key = {'fld_edt_'+fl.name+i}
								/>
						</div>

					</List.Item>

						 )
				}

				{btns}
									
			</List>

		// return <React.Fragment>
		// 				{
		// 					fields.map( (fl, i) => <UniFieldSUI

		// 							fl = {fl}
		// 							values = {values}
		// 							valids = {valids}
		// 							uf_this = {uf_this}
		// 							size = {size}

		// 							valid_not_required = {valid_not_required}

		// 							key = {'fld_edt_'+fl.name+i}
		// 						/>
		// 								)
		// 				}

		// 				{btns}

		// 			</React.Fragment>

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	validate_showif (condition, field_values) {//showif: {type: 'value_check', param_tree: ['claims', 'doc'], value: true, test: '='}
		if (condition.type == 'value_check'){// This has been left nested as more conditions will be added in future
			if (this.check_param_tree(field_values, condition.param_tree)){
				if (condition.test == '='){
					if (this.value_param_tree(field_values, condition.param_tree) == condition.value) return true
				}
			}		
		} else if (condition.type == 'showIfNot' && condition.value) {
			return !field_values[condition.value]
		}
		return false
	}

	value_param_tree (field_values, tree) { // Returns an object value based on an array of object keys
		let current = field_values, c_index
		for (c_index = 0; c_index < tree.length; c_index++) current = current[tree[c_index]]
		return current
	}

	check_param_tree (field_values, tree) {
		let current = field_values, c_index
		for (c_index = 0; c_index < tree.length; c_index++){
			if (!(tree[c_index] in current)) return false
			current = current[tree[c_index]]
		}
		return true
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	fld_td_edit_render (fl) {

		const { fields, values, valids, uf_this, btns, admin_link_to_func } = this.props
		// console.log('UniFormSUI::fld_td_edit_render', values, fl);

								switch (fl.type) {

									case "text":
									case "email":
									case "number":
										return  <Table.Cell key={"UniForm-field-"+fl.name}>
												<Input 
														name={fl.name}
														value={values[fl.name] || ''}
														onChange={uf_this.handleInputChange}
														type={fl.type} 
														placeholder={fl.placeholder} 
														pattern={fl.pattern}
														error = {valids && !valids[fl.name]} 
													/>*
											</Table.Cell>

									case "textarea":
										return  <Table.Cell key={"UniForm-field-"+fl.name}>
												<TextArea  
														name={fl.name}
														value={values[fl.name] || ''}
														onChange={uf_this.handleInputChange}
														placeholder={fl.placeholder} 
													></TextArea>
											</Table.Cell>

									case "json-text":
										return  <Table.Cell key={"UniForm-field-"+fl.name}>
												<TextArea  
														name={fl.name}
														obj={fl}
														value={values[fl.name] || ''}
														onChange={uf_this.handleInputChange}
														placeholder={fl.placeholder} 
													></TextArea>
											</Table.Cell>


									case "select":
										return <select 
													name={fl.name}
													value={values[fl.name]}
													onChange={this.handleInputChange}
													key={"UniForm-FieldHolder-field-"+fl.name}
												>
													<option value=''>Choose:</option>
													{
														fl.options.map( (op, oi) => <option key={"UniForm-FieldHolder-field-"+fl.name+oi}
																			>{op}</option> )
													}														
											</select>


									case "radio":
									case "checkbox":
										return 	<Table.Cell 
														// negative={valids && !valids[fl.name]} 
														key={"UniForm-field-"+fl.name}
													>

												<label> {fl.label} </label>

												{
													fl.options && fl.options.map( (op, oi) => <Form.Field 
															control={Radio}
															label={op.n} 
															name={fl.name}
															value={op.c?.toString()}
															checked={fl.toggle ? values[fl.name] : values[fl.name] == op.c}
															onChange={()=>uf_this.handleInputChange({target:{name:fl.name, value: fl.toggle ? !values[fl.name] : op.c}})}
															error={valids && !valids[fl.name]}
															toggle={fl.toggle}
															key={"UniForm-field-"+fl.name+oi}
														 />
													 )
												}

											</Table.Cell>


									case "separator":
										return <hr />
									case "bool":
										return <Table.Cell key={fl.name}></Table.Cell>
								}

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	fld_td_view_render (fl) {

		const { fields, values, valids, uf_this, btns, admin_link_to_func, onSave_dets_fld } = this.props
		// console.log('UniFormSUI::fld_td_view_render', values, fl);

		const  fl_val = fl.prop_name && this.props[fl.prop_name] || values[fl.param_name||fl.name];


								switch (fl.type) {
									case "text":
									case "email":
									case "textarea":
										return  <Table.Cell key={"UniForm-field-"+fl.name}>

												{fl.view_link_to && <Link to={admin_link_to_func(values)}>
														<SimpleShowMore content={fl_val} />
													</Link>}

												{!fl.view_link_to && <SimpleShowMore content={fl_val} />}

											</Table.Cell>

									case "json-text":
										return  <Table.Cell key={"UniForm-field-"+fl.name}>
												<pre>
													{JSON.stringify(fl_val, null, 4)}
												</pre>
											</Table.Cell>

									case "number":
										return  <Table.Cell key={"UniForm-field-"+fl.name}>

												{fl.view_link_to && <Link to={admin_link_to_func(values)}>
														{fl_val}
													</Link>}

												{!fl.view_link_to && fl_val}

											</Table.Cell>

									case "select":
										return <select 
													name={fl.name}
													value={values[fl.name]}
													onChange={this.handleInputChange}
													key={"UniForm-FieldHolder-field-"+fl.name}
												>
													<option value=''>Choose:</option>
													{
														fl.options.map( (op, oi) => <option key={"UniForm-FieldHolder-field-"+fl.name+oi}
																			>{op}</option> )
													}														
											</select>


									case "radio":
									case "checkbox":
										return <Table.Cell key={"UniForm-field-"+fl.name}>
												{fl.options && fl.options.filter( o => o.c==fl_val ) && fl.options.filter( o => o.c==fl_val )[0]?.n}
											</Table.Cell>

									case "bool":
										return <Table.Cell key={"UniForm-field-"+fl.name}>
												{false && fl_val}

												<div style = {{flex:3,alignSelf:'center',display:'flex',alignItems:'center'}} >
													<Checkbox	
														defaultChecked = {fl_val==undefined&&true || fl_val}
														onChange = { (e, d) => onSave_dets_fld&&onSave_dets_fld(fl.name, d.checked) }
														//onChange = { (e, d) => console.log(fl.name, d.checked) }
														toggle
														style = {{padding:0,alignSelf:'center'}} 
													/>
												
													<span style={{display:'inline-block',marginLeft:'10px'}}>{fl_val!=undefined&&fl_val.toString() || '?'}</span>
												</div>
											</Table.Cell>


									case "separator":
										return <hr />
								}

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	fields_render () {

		const { show='view', fields, btns } = this.props
		const { values, valids, uf_this, size='mini' } = this.props
		const { valid_not_required, display_errors = false } = this.props

		return <React.Fragment>
						{
							fields.map( (fl, i) => (show=='view' || show=='fields_td' && fl.read_only) && this.fld_td_view_render(fl)
												|| show=='fields_td' && this.fld_td_edit_render(fl) 
												// || show=='fields_form' && this.fld_form_edit_render(fl) 
												|| show=='fields_form' && <UniFieldSUI

															fl = {fl}
															values = {values}
															valids = {valids}
															uf_this = {uf_this}
															//size = {size}
															display_errors={display_errors}

															valid_not_required = {valid_not_required}

															key = {'fld_edt_'+fl.name+i}
														/>

												|| null
										)
						}

						{btns}
					</React.Fragment>
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render () {

		const { autocomplete, show='view', show_form=true, loading = false, size } = this.props
		return <React.Fragment>

				{ 
					show=='fields_list' && <Form autoComplete={autocomplete} loading={loading} size={size}>{this.fld_list_edit_render()}</Form>
				||	show=='fields_form' && show_form && <Form autoComplete={autocomplete} loading={loading} size={size}>{this.fields_render()}</Form>
					||	(show!='fields_form' || !show_form) && this.fields_render()
				}

			</React.Fragment>
	}

}
