
import * as Sentry from '@sentry/browser';

const DEBUG = false;

export default class log_sentry {

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static report_msg(msg, lvl, xtr) {

		Sentry.withScope( scope => { 
							scope.setLevel( lvl );        // 'debug' / 'info' / 'warning' / 'error' / 'fatal' 
							// scope.setExtra( 'extra', xtr ); 
							scope.setExtras(xtr);
							scope.setTag('sh', app.shid)
							scope.setTag('rtm', app.runtime.mode)
							scope.setTag('rta', app.runtime.app)
							scope.setTag('vr', __GIT_HASH__ + ' ' + __BUILD__)
							Sentry.captureMessage( msg );
						} );

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static report_exc(err, xtr) {

		Sentry.withScope( scope => { 
							// scope.setExtra( 'extra', xtr ); 
							scope.setExtras(xtr);
							scope.setTag('sh', app.shid)
							scope.setTag('rtm', app.runtime.mode)
							scope.setTag('rta', app.runtime.app)
							scope.setTag('vr', __GIT_HASH__ + ' ' + __BUILD__)
							// scope.setTags(context.tags);
							Sentry.captureException( err );
						} );

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

}