import React, { useEffect, useState } from 'react';
import { List, Input, Icon, Button, Table, Container, Segment, Header, Form, Radio, Card, Image, Label, Loader , Dimmer, Divider } from 'semantic-ui-react'
import { v4 as generate_id } from 'uuid';
import patho_model from 'models/patho_model'
import PharmMedSelector from '../components/PharmMedSelector'
import DisplayField from '../components/DisplayField'
import Meds from '../components/Meds'
import FeedbackText from '../components/FeedbackText'
import { cleanOBXRefRange, getChartRanges } from 'xAppLib/Results/util'


/**
----
Definition
---
*/
const LevelsDefinition = ({onUpdate,value=LevelsDefinition.defaultDefinition, children}) => {
	const sendUpdate = (name,new_value) => onUpdate({...value,[name]:new_value,id:value.id||generate_id()})
	const handleChange = (e, { name, value }) => sendUpdate(name, value)
	const handleChangeNumber = (e, { name, value }) => sendUpdate(name, value)
	const handleChangeSwitch = (e, { name, checked }) => { if (confirm('This affects the way the results are shown to the patient. Are you sure you want to modify this setting?')) sendUpdate(name, checked)   }
	const handleMedChange = ({ target:{name , value }}) => sendUpdate(name, value)
	return <Segment>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <Form.Input fluid label='Measurement' name='measure' value={value.measure} onChange={handleChange} />
			{children}
		</Form.Group>
		
		<Form.Group widths='equal' >
		  <Form.Input fluid label='Unit' name='unit' value={value.unit} onChange={handleChange} />
		</Form.Group>
		
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <Form.Input fluid label='Emergency Low' name='em_low' value={value.em_low} onChange={handleChangeNumber} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Feedback Emergency Low' name='feed_em_low' value={value.feed_em_low} onChange={handleChange} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <Form.Input fluid label='Low' name='low' value={value.low} onChange={handleChangeNumber} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Feedback Low' name='feed_low' value={value.feed_low} onChange={handleChange} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.Field>
				<label>Recommended Meds for Low</label>
				<PharmMedSelector name='rec_low' value={value.rec_low} onChange={handleMedChange} />
			</Form.Field>
		</Form.Group>
		
		
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <Form.Input fluid label='High' name='high' value={value.high} onChange={handleChangeNumber} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Feedback High' name='feed_high' value={value.feed_high} onChange={handleChange} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.Field>
				<label>Recommended Meds for High</label>
				<PharmMedSelector name='rec_high' value={value.rec_high} onChange={handleMedChange} />
			</Form.Field>
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <Form.Input fluid label='Emergency High' name='em_high' value={value.em_high} onChange={handleChangeNumber} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Feedback Emergency High' name='feed_em_high' value={value.feed_em_high} onChange={handleChange} />
		</Form.Group>

		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Radio toggle checked={!!value.disable_emergency} label='Disable Emergency Warning (caution)' name='disable_emergency' onChange={handleChangeSwitch} />
		</Form.Group>
	
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Pathology certificate disclaimer' name='disclaimer' value={value.disclaimer} onChange={handleChange} />
		</Form.Group>
		
	</Segment>
}

Object.assign(LevelsDefinition,patho_model.MEASURE_TYPES.levels)

/**
----
Input
---
*/
const LevelsInput = ({onUpdate,value=LevelsInput.defaultDefinition, children}) => {
	const handleChange = (e, { name, value : new_value }) => onUpdate({...value, [name]: new_value })
	return <>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <DisplayField fluid label={value.measure} />
		</Form.Group>
		
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.Input fluid label='Result' name='result' value={value.result!==undefined?value.result:''} onChange={handleChange} />
			<Form.Input width="3" disabled fluid label='Unit' value={value.unit} />
		</Form.Group>
		
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
		  <DisplayField fluid label='Emergency Low' name='em_low' value={value.em_low} onChange={handleChange} />
		  <DisplayField fluid label='Low' name='low' value={value.low} onChange={handleChange} />
		  <DisplayField fluid label='High' name='high' value={value.high} onChange={handleChange} />
		  <DisplayField fluid label='Emergency High' name='em_high' value={value.em_high} onChange={handleChange} />
		</Form.Group>
		
	</>
}


Object.assign(LevelsInput,patho_model.MEASURE_TYPES.levels)

/**
----
View
---
*/
const LevelsView = ({onUpdate,value=LevelsView.defaultDefinition, children, index, onNotify}) => {
	
	let { result, result_lab, em_low, low, high, em_high, ref_range, measure, rec_low, rec_high, unit, units, feed_good, feed_em_low, feed_low, feed_em_high, feed_high, disable_emergency } = value;

	([result, em_low, low, high, em_high] = [result, em_low, low, high, em_high].map(n=>n!==''&&n!==null?Number(n):undefined).map(n=>isNaN(n)?undefined:n))

	//April 2023, now using the lab result units and reference ranges. Still using our result calcs to test for emergency values.
	const is_danger_low = em_low!==undefined && result <= em_low
	const is_danger_high = em_high!==undefined && result >= em_high
	let is_low = low!==undefined && result < low && !is_danger_low
	let is_high = high!==undefined && result > high && !is_danger_high
	if (result_lab !== undefined && result_lab !== null) { //result_lab is the litmus test field for when we moved to using lab reference ranges 
		const obx_range = cleanOBXRefRange(ref_range)
		const chartRange = getChartRanges(result_lab, obx_range)
		result = result_lab //Using lab now
		unit = units
		low = chartRange.ref_lower
		high = chartRange.ref_upper
		is_low = chartRange.inRange === 'low' && !is_danger_low
		is_high = chartRange.inRange === 'high' && !is_danger_high
	}

	const is_good = !is_danger_low && !is_low && !is_danger_high && !is_high
	
	const danger = !disable_emergency && (is_danger_low || is_danger_high)
	const warning = is_low || is_high || (disable_emergency && (is_danger_low || is_danger_high))
	
	const rec_meds = [...(is_low && rec_low || is_high && rec_high || [])]

	
	useEffect(_=>{
			
			
			onNotify?.(index,{
				danger,
				warning,
				measure
			})
			
	},[value])

	let range = null
	if (low!==undefined && high!==undefined)
		range = <>{low} - {high} {unit}</>
	else if (low===undefined)
		range = <>{"<"} {high} {unit}</>
	else if (high===undefined)
		range = <>{">"} {low} {unit}</>
					


	
	return <>
		  <Header>
			  {measure}
		  
			  <Label size='small' color={danger?'red':warning?'orange':'green'} horizontal>
				  <Icon name={is_good?'check':'warning'} />
				  { is_danger_low && (!disable_emergency && `${feed_em_low}` || "Very Low Level")}
				  { is_danger_high && (!disable_emergency && `${feed_em_high}` || "Very High Level")}
				  { is_low && "Low level"}
				  { is_high && "High level"}
				  { is_good && "Good"}
			  </Label>
		  </Header> 
		
				
	   		<p>{result} {unit} {range &&<small className='text grey'><br/>Normal Range: {range}</small>}</p>
			
			
			{is_good && <FeedbackText>{feed_good}</FeedbackText>}
			
			{is_danger_low && <FeedbackText danger={!disable_emergency} warning={!!disable_emergency}>{feed_em_low}</FeedbackText>}
			{is_low && <FeedbackText warning>{feed_low}</FeedbackText>}
			
			{is_danger_high && <FeedbackText danger={!disable_emergency} warning={!!disable_emergency}>{feed_em_high}</FeedbackText>}
			{is_high && <FeedbackText warning>{feed_high}</FeedbackText>}
			
			
			{is_low && <Meds callDoctor={!is_good} heading={`Recommended Medicines For Low Levels ${measure}`} medsList={rec_low} />}
			{is_high && <Meds callDoctor={!is_good} heading={`Recommended Medicines For High Levels ${measure}`} medsList={rec_high} />}
			{!disable_emergency && (is_danger_low) && <p>{feed_em_low}</p>}
			{!disable_emergency && (is_danger_high) && <p>{feed_em_high}</p>}
		
		
	</>
}

Object.assign(LevelsView,patho_model.MEASURE_TYPES.levels)

export {
	LevelsDefinition,
	LevelsInput,
	LevelsView
}
