import React from 'react';
import { Link } from 'react-router-dom'
import { 
		Button,
	} from 'semantic-ui-react'

import { toast } from 'react-toastify';

import db_lib from 'xAppLib/libs/db_lib';


export const _BASE_CONF = {
	MEDCONS:false,
	MEDBUY:false,
	CADO:false,
	DOCCONS:false,
	SHIPPING:false,
	new_user:false
}

export const _TYPES = {
	req_doccons_flup : {
		label: 'Doctor Follow Up Consultation',
		params: {
			dets: {msg: "Please request a follow up doctor consultation", cost: 20},
			med: {mid: "DocConsFU"}
		}
	},
	req_icanncons_flup : {
		label: 'Benicura Follow Up consultation',
		params: {
			dets: {msg: "Please request a follow up Benicura consultation", cost: 59},
			med: {mid: "DocConsICannFU"}
		}
	},
	req_doccons_res : {
		label: 'Doctor Test Results Consultation',
		params: {
			dets: {msg: "Please request a follow up doctor consultation", cost: 20},
			med: {mid: "DocConsFU"}
		}
	},
	pre_prep : {
		label: 'PrEP Follow Up',
		params: {
			dets: {msg: "Please request a follow up doctor consultation", cost: 20},
			med: {mid: "DocConsFU"}
		}
	},
	presc_docconswl : {
		label: 'Weight Loss Follow Up',
		params: {
			dets: {msg: "Please request a Weight Loss follow up doctor consultation", cost: 40},
			med: {mid: "DocConsWLFU"}
		}
	},
	presc_docconswli: {
		label: 'Weight Loss Follow Up (Self-injections)',
		params: {
			dets: {msg: "Please request a Weight Loss follow up doctor consultation", cost: 49},
			med: {mid: "DocConsWLFUI"}
		}
	},
	presc_docconswlo: {
		label: 'Weight Loss Follow Up (Oral medications)',
		params: {
			dets: {msg: "Please request a Weight Loss follow up doctor consultation", cost: 49},
			med: {mid: "DocConsWLFUO"}
		}
	},
}

					
const _EDIT_FLDS_ADMIN = [

						{
							label: 'Enabled',
							name: 'enabled',
							type: 'bool',
						},
						{
							label:'Type',
							name: 'type',
							type:'select',
							options:Object.keys(_TYPES).map(t=>({key:t,value:t,text:_TYPES[t].label}))
						},
						{
							label: 'Created',
							name: 'cre_tm',
							type: 'date',
							valid_not_required:true
						},
						{
							label: 'Expiry',
							name: 'exp_tm',
							type: 'date',
							valid_not_required:false
						},
						{
							label:'Status',
							name: 'status',
							type:'select',
							options:[{key:'active', value:'active', text:'Active'}]
						},
						{
							label: 'Max Use',
							name: 'max_use',
							valid_not_required:true
						},
						{
							label: 'User Email',
							name: 'email',
							valid_not_required:false
						},
						{
							label: 'User',
							name: 'uid',
							valid_not_required:true
						},
						{
							label: 'Meds Select',
							name: 'med__mid',
							// jpath: 'med.mid',
							// type: MedSelect,
							valid_not_required: false,
						},
						{
							label: 'Message',
							name: 'dets__msg',
							// jpath: 'med.mid',
							// type: MedSelect,
							valid_not_required: true,
						},
						{
							label: 'Cost',
							name: 'dets__cost',
							// jpath: 'med.mid',
							// type: MedSelect,
							valid_not_required: true,
						},
						{
							label: 'Doc',
							name: 'doc__doc_id',
							// jpath: 'med.mid',
							// type: MedSelect,
							type: 'sel_doc',
							valid_not_required: true,
						},
						{
							label: 'Ref scr',
							name: 'ref__sid',
							// jpath: 'med.mid',
							// type: MedSelect,
							valid_not_required: true,
						},
						// {
						// 	name: 'conf',
						// 	label: 'Config',
						// 	type: 'json',
						// },

					];
					
const _EDIT_FLDS_ORG = [
						{
							label: 'Recipient (email)',
							name: 'users',
						},

					];

const _EDIT_FLDS_DOC = [

];


const _MODEL_NAME = 'presc_model';

const _USE_DATA = 'API'
const _API_NAME = 'prescs'

export default class presc_model extends db_lib {


	static get EDIT_FLDS_ADMIN() { return _EDIT_FLDS_ADMIN; }
	static get EDIT_FLDS_ORG() { return _EDIT_FLDS_ORG; }
	static get EDIT_FLDS_DOC() { return _EDIT_FLDS_DOC; }

	static get BASE_CONF() { return _BASE_CONF; }
	static get TYPES() { return _TYPES; }


	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	

}
