import React from 'react';
import { Helmet } from "react-helmet";
import { Button, Container } from 'semantic-ui-react';
import IcosmHero from './components/IcosmHero';
import IcosmBanner from './components/IcosmBanner';
import IcosmAppBanner from './components/IcosmAppBanner';
import { Link } from 'react-router-dom';
import IcosmInfo from './components/IcosmInfo';

const Oversight = () => {

    const slides = [
        {
            img: "iphone-scripting.png",
            title: "Enjoy access to fast scripting",
            sub: "",
            desc: "With most wait times of under a minute, you’ll find InstantCosmetics a fast, efficient and reliable platform to use."
        },
        {
            img: "iphone-treatment-plans.png",
            title: "Give precise insight on treatment",
            sub: "",
            desc: "Provide patients with tailored treatment plans."
        },
        {
            img: "iphone-oversight-emergency.png",
            title: "Help is just a click away",
            sub: "",
            desc: "Our 24/7 emergency escalation button can be used in situations where you require medical complications management fast."
        },
    ]

	return <div>

		<Helmet>
			<title>Medical Oversight</title>
		</Helmet>
     

        <Container>
            <IcosmHero title="Medical Oversight & Complications Management" desc="Ensure medical oversight with fast access to experienced cosmetic doctors and 24/7 complications management and support." link="" link_text="Join today" order="2" 
                img='2/iStock-1283385763_2.jpg'
            />
            
            <IcosmInfo title={<>Meet our <br/><span className='text-icosm-tertiary'>Medical Director</span></>} 
                sub_title="Dr. Built Varinporn Amporndanai"
                desc="Dr. Built has been practicing medicine for over 6 years, with experience in public hospitals and expertise in general medicine and psychiatry. A long-time enthusiast of cosmetic medicine, she takes great pleasure in guiding her patients and mentoring nurses through their transformations and practice enhancements. She is also dedicated to supporting her fellow doctors in the fast-paced field of cosmetic scripting, especially through her work with InstantCosmetics." 
                img="2/team-drbuilt.jpg"
                />
            
            <IcosmInfo title={<>Meet our <span className='text-icosm-tertiary'>Cosmetic<br/> Complications Director</span></>} 
                sub_title="Dr. Jonathan Hopkirk"
                desc="Dr Hopkirk is the former Global Medical Director of the Laser Clinics Group, and has built a strong skillset and clinical acumen for managing rare and severe adverse events. In 2021 Dr Hopkirk’s large-scale experience in managing complications across diverse population groups earned him a seat on Dr Lee Walker’s ‘Complications Medical Aesthetics Collaborative’ (CMAC) international expert board. In 2022, he shared the stage with chosen industry professionals from around the globe at the first-ever CMAC ‘New Frontiers Safety Congress’ in London. He was again invited back in 2023 to participate in expert panel discussions on various aesthetic complications topics. The same year, he was invited to present in the main hall on rare adverse events at Australia’s largest aesthetic medicine conference, the ‘Non-Surgical Symposium’."
                img="2/team-drhopkirk.jpg" 
                reverse />

          
            <IcosmBanner reverse img='2/Website-Platform-Images-script.jpg'  title="Access to Experienced Doctors" desc="Getting in touch with a doctor through our platform is fast and convenient. Our doctors specialise in aesthetics and adhere strictly to compliance standards when prescribing treatments, prioritising your safety and peace of mind." />

           </Container>

           <IcosmAppBanner reverse title="Access to advanced tools, and quality doctors" desc="Know your in capable hands with our ready to use system." slides={slides}/>

        <IcosmBanner  img='2/iStock-914319798.jpg'  title="Grow today, excel tomorrow." desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." />
        
	</div>

}

export default Oversight;
