import React, { useRef, useState } from 'react';
import { Header } from 'semantic-ui-react';
import { cls } from 'views/NUI/utils';


const Section = ({children}) => {
    return <>
            {children}
        </>;
}



const SectionContent = ({children, border=true, end=false, start=false, inner=false, enabled=true, className}) => {
    return enabled && <div className={className || cls(`bg-white p-4`, inner && 'border border-is-gray-30 rounded-md' || border && `border-x border-is-gray-50 shadow ${start && 'border-t rounded-t-md' || end && 'border-b rounded-b-md' || 'border-y rounded-md'}`)}>
            {children}
        </div>;
}

Section.Content = SectionContent


const SectionHeader = ({children, small = false, meta, onMeta, className}) => {
    
	const Wrap = small && 'small' || 'span'
	const color = small && 'grey' || undefined
	const style = small && {borderBottom:'none'} || {}

	return <h4 className={className || (cls('pb-4 md:mx-0 mb-0 mt-8', small ? 'text-base' : ''))}>
            {children}
        </h4>
};
 
Section.Header = SectionHeader
 
export default Section;