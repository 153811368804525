import instcons_model from "../../models/instcons_model";
import {from_melb_ui_tm} from "../../helpers/datetime";

export function omit_assigned_to_other_doctor(records) {
    if (app.acl.is_admin) return records;

    return records.filter(record => !record.doc?.doc_id || record.doc.doc_id === app.user.claims.doc_id);
}

export function group_similar_requests(records) {
    return records.reduce((out, record, i) => {
        if (!out.seen[record.key]) {
            const similar = [record, ...instcons_model.get_similar_records(record, records.slice(i + 1))].filter(rec => !out.seen[rec.key]);

            for (const rec of similar) {
                out.seen[rec.key] = true;
            }

            const [main, ...others] = similar;
            out.grouped.push({
                ...main,
                others
            });
        }

        return out;
    }, {grouped: [], seen: {}}).grouped
}

export function sort_by_least_recently_actioned(records) {
    return records
        .map(record => ({
            record,
            tm: Math.max(
                from_melb_ui_tm(record.add_tm).toDate().getTime(),
                ...Object.values(record.hist || {}).map(h => h.tm)
            )
        }))
        .sort((a, b) => a.tm - b.tm)
        .map(x => x.record)
}

export function touch_count(record) {
    return Object.values(record?.hist || {}).filter(h => h.to?.status === 'instcons_taken').length;
}

export function sort_by_least_touches(records) {
    return records
        .map(record => ({ record, touches: touch_count(record) }))
        .sort((a, b) => a.touches - b.touches)
        .map(x => x.record)
}

export function review_only_first(records) {
    return records
        .map(record => ({
            record,
            // give priority to review-only _if_ it hasn't yet been touched, otherwise we're having trouble
            // with the consult so move it down to avoid clogging the top of the list
            review_only: instcons_model.needs_consult(record) || touch_count(record) > 0 ? 1 : -1
        }))
        .sort((a, b) => a.review_only - b.review_only)
        .map(x => x.record)
}

export function taken_consults_first(records, docid) {
    function taken_by_doc(row) {
        if (row.status === 'instcons_taken') {
            return !docid || row.doc?.doc_id === docid;
        }
        return false;
    }

    return records.sort((x, y) => {
        // There should only be one taken at a time, but prioritise token consults to the top since
        // they're "further along" the flow. There's an issue here that if somehow the nurse is
        // awaiting a consult on treatment X but the doctor has somehow taken treatment Y, the sessions
        // are never going to align. A bit of a worry, but not much we can do :(
        if (taken_by_doc(x)) return -1;
        if (taken_by_doc(y)) return 1;
        return 0;
    });
}

export function prioritorise_waiting_room(records) {
    const grouped = app.settings.is_COSM_GROUP_SIMILAR ? group_similar_requests(records) : records;
    return taken_consults_first(review_only_first(sort_by_least_touches(grouped)), app.user.claims.doc_id)
}

function consulting_first(records, docid) {
	function is_consulting(row) {
		return row.doc?.doc_id === docid;
	}

	return records.sort((x, y) => {
		if (is_consulting(x)) return -1;
		if (is_consulting(y)) return 1;
		return 0;
	});
}

export function prioritorise_app_scripts(records) {
	const oldest_to_newest = sort_records_oldest_to_newest(records);

	if (app.user.claims.doc_id) {
		return consulting_first(oldest_to_newest, app.user.claims.doc_id);
	}

	return oldest_to_newest;
}

export function sort_records_oldest_to_newest(records) {
	return Object.keys(records || {})
		.map(key => ({
			sort_key: from_melb_ui_tm(records[key].add_tm).toDate().getTime(),
			record: {
				...records[key],
				key,
			}
		}))
		.sort((a, b) => {
			const res = a.sort_key - b.sort_key;
			return isNaN(res) ? 0 : res;
		})
		.map(x => x.record)
}