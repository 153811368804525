import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
    Loader,
    ListItem,
    ListIcon,
    ListHeader,
    ListDescription,
    ListContent,
    List,
} from "semantic-ui-react";
import moment from "moment-timezone";
import { useUser } from "xAppLib/Hooks";
import { useAsync, useAsyncUpdate } from "xAppLib/Hooks/useAsync";
import {
    get_patient_appointments,
    patient_cancel_appointment,
} from "../../../model/patient";
import { FRIENDLY_DATETIME_FULL } from "../../formatting";
import { getClientBookingTimezone } from "../../shared";

const CancelAppointmentButton = ({
    appointment_id,
    time,
    onComplete,
}) => {
    const {
        loading,
        error,
        data,
        fn: onCancelAppointment,
    } = useAsyncUpdate(async (_data) => {
        const response = await patient_cancel_appointment({
            appointment_id
        });
        app.trigger(
            app.events.SHOW_POPUP,
            { txt: `Your ${time} appointment has been cancelled` }
        );
        onComplete();
        return response;
    }, [appointment_id, time, onComplete]);

    if (loading) {
        return (
            <div className="cursor-default">
                <small>Cancel this appointment</small>
                {' '}
                <Loader active inline size="tiny" />
            </div>
        );
    }
    if (data) {
        return null;
    }
    if (error) {
        return (
            <span>Error cancelling appointment</span>
        );
    }
    return (
        <a onClick={onCancelAppointment}>
            <small>Cancel this appointment</small>
        </a>
    );
}

const OrgPatientAppointments = () => {
    const user = useUser();
    const [refresh, setRefresh] = useState(null);
    const onComplete = useCallback(() => {
        setRefresh(new Date().getTime());
    }, [setRefresh]);

    const {
        data,
    } = useAsync(async () => {
        if (!user?.uid) {
            return null;
        }
        return await get_patient_appointments({ timeframe: "future" });
    }, [user?.uid, refresh]);

    if (!user?.uid || !app?.acl?.is_usr || !data?.data?.length) {
        return null;
    }

    const timezone = getClientBookingTimezone();
    const momentNow = moment();

    return (
        <div style={{ background: "white", padding: "32px 55px" }}>
            <h3>Your Appointments</h3>
            <List divided relaxed>
                {
                    data.data.map(
                        ({
                            appointment_id,
                            title,
                            start,
                            booked_spd_data,
                            oid,
                            org_name,
                        }) => {
                            const momentTime = moment(start).tz(timezone);
                            const time = momentTime.format(FRIENDLY_DATETIME_FULL);
                            const showCancellation = momentTime.isAfter(momentNow);
                            return (
                                <ListItem className="!py-4" key={appointment_id}>
                                    <ListIcon name="calendar alternate outline" size="large" verticalAlign="top" />
                                    <ListContent>
                                        <ListHeader>{title}</ListHeader>
                                        <ListDescription>
                                            <div className="py-2">
                                                <strong>
                                                    {time}
                                                </strong> ({timezone.replace("Australia/", '')} time)
                                            </div>
                                            <div className="pb-2">
                                                {booked_spd_data?.first_name ? (
                                                    <span>with <strong>{booked_spd_data?.first_name} {booked_spd_data?.last_name}</strong>{' '}</span>
                                                ) : null}
                                                at <Link to={`/clinic/${oid}`}><strong>{org_name}</strong></Link>
                                            </div>
                                            {showCancellation ? (
                                                <CancelAppointmentButton
                                                    appointment_id={appointment_id}
                                                    time={time}
                                                    onComplete={onComplete}
                                                />
                                            ) : null}
                                        </ListDescription>
                                    </ListContent>
                                </ListItem>
                            )
                        }
                    )
                }
            </List>
        </div>
    );
}

export default OrgPatientAppointments;