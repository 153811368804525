import React, { useEffect,useRef } from 'react';
import gtm from 'xAppLib/providers/gtm';
import UniForm from 'xAppLib/UniForm/UniForm';

const DEBUG = false

const excludeReport = ['summary','yogp','cado']

const UniFormMed = ({children, store, visible, enabled = true, section, fields, med_data, defaultValues, script_type, req_type, onUpdate, onFormChecked, onMountSection, onChange, showErrors}) => {
  	const formRef = useRef()
  	const completeRef = useRef(false)

	const active = enabled && visible
	useEffect(()=>{
		if (!section || !active)
			return
		onMountSection?.(section,formRef,fields)
		formRef.current.notifyValids()
		return () => {
			onMountSection?.(section,null,fields)
		}
	},[formRef, active, fields, onMountSection, section]);

	return <UniForm
			ref={formRef}
			fields={fields}
			onUpdate={active && onUpdate||undefined}
			onChange={active && onChange||undefined}
			onFormChecked={(ret, valids, validsErrors)=>{
				if (!active)
					return
				if (!excludeReport.includes(section) && Object.keys(valids).length) {
					if (completeRef.current !== ret){
						const event = ret ? 'form_step_complete' : 'form_step_incomplete'
						DEBUG && console.log(event,section);
						completeRef.current = ret
						gtm.event(event,`${event}_${section}`,{fields:fields.length})
					} 
				}
							
				onFormChecked(ret, valids, validsErrors)
			}}
			alwaysCheck
			updateOnDefaults
			showErrors={showErrors}
			defaultValues={defaultValues}
			render_children={true}
		>{active && children || null}</UniForm>

}
 
export default UniFormMed;