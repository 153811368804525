import React from 'react';
import Gravatar from 'xAppLib/Gravatar/Gravatar';
import AppendIf from 'xAppLib/UIelems/AppendIf';
import { useProfile, useUser } from 'xAppLib/Hooks';
import { Icon } from 'semantic-ui-react';
import UserPic from './UserPic';

const UserAvatar = ({showOnline=false, size=24}) => {
	const user = useUser()
	const [ profile ] = useProfile()

	const { user_det } = user

	if (!user_det)
		return null
		
	return <div className='user-area'>
					<UserPic
						photoURL={user_det.photoURL}
						email={user_det.email||profile.email}
						name={user.name}
						size={size}
					/>
					<div className='name'>
						{app.state.goback_token && <Icon name='user secret'/>}
						<AppendIf
							cont={user.short_name}
							/>
						{showOnline && <span style={{color:user.online?'#76ff03':'red', marginLeft:'5px'}} >*</span>}
					</div>
			</div>

}
 
export default UserAvatar;