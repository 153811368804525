import React, { useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Input, Button, Container, Menu, Icon } from "semantic-ui-react";
import useOnSettingsButtonClick from "../../utlis/hooks/useOnSettingsButtonClick";
import ImagePicker from "xAppLib/UIelems/ImagePicker";
import { useToggleState } from "xAppLib/Hooks";

const BLUE = "#0065f2";
const DEMO = {
    desktopSrc:
        "https://storage.googleapis.com/is-media/240104132657_undefined_undefined_278162429_media",
    mobileAndTabletSrc: 'https://www.instantscripts.com.au/wp-content/uploads/2022/10/ibs-woman-lying-down.jpg',
    header: "Suffering from constant tummy troubles?",
    text: "Find out what’s going on in your gut with an Irritable Bowel Syndrome (IBS) test from our Australian-registered doctors.",
};
const SCROLL_OFFSET = 140;

const BigHeaderWithNavbar = ({
    data,
    onDataChange,
    readOnly,
    settingsWrapper,
    viewerOnly,
}) => {
    const Link = viewerOnly ? RouterLink : Container;
    const [toggleSettings, setToggleSettings] = useState(true);
    const [state, setState] = useState(data);
    const [isModelOpen, openModel, closeModel] = useToggleState();
    const imagePickerRef = useRef();
    const [navLink, setNavLink] = useState("");

    const {
        desktopSrc,
        mobileAndTabletSrc,
        header,
        text,
        buttonText,
        buttonLink,
        navItems = [],
    } = state;

    useEffect(() => {
        if (!readOnly) {
            setState((prev) => ({ ...DEMO, ...prev }));
        }
    }, []);

    const handleStateChange = (name) => {
        return (_, { value }) => {
            setState((prev) => {
                const updatedState = { ...prev, [name]: value };
                onDataChange(updatedState);
                return updatedState;
            });
        };
    };

    const addNavLink = (link) => {
        setState((prev) => {
            const updatedState = {
                ...prev,
                navItems: [...navItems, link],
            };
            onDataChange(updatedState);
            return updatedState;
        });
        setNavLink("");
    };

    const removeNavLink = (link) => {
        setState((prev) => {
            const updatedState = {
                ...prev,
                navItems: navItems.filter((l) => l !== link),
            };
            onDataChange(updatedState);
            return updatedState;
        });
    };

    const handleClear = () => {
        if (confirm("Are you sure?")) {
            setState(
                Object.keys(state).reduce(
                    (obj, key) => ({ ...obj, [key]: "" }),
                    {}
                )
            );
            onDataChange({});
        }
    };

    !viewerOnly &&
        useOnSettingsButtonClick(settingsWrapper, "settings", () =>
            setToggleSettings((prev) => !prev)
        );

    const handleOnImageClick = (_, imgUrl) => {
        handleStateChange(imagePickerRef.current)(null, { value: imgUrl });
        handleCloseModal();
    };

    const handleCloseModal = () => {
        closeModel();
        imagePickerRef.current = undefined;
    };

    const handleOpenModal = (image) => {
        return () => {
            openModel();
            imagePickerRef.current = image;
        };
    };

    const content = (
        <>
            {(header && (
                <h2 className={`font-bold text-[25px] lg:text-[35px] lg:max-w-[50%]`}>
                    {header}
                </h2>
            )) ||
                null}
            {(text && <p className={`lg:max-w-[50%]`}>{text}</p>) ||
                null}
            {(buttonText && <Link to={buttonLink}>{buttonText}</Link>) || null}
        </>
    );

    return (
        <div className="sticky">
            <>
                {/* Desktop */}
                <div
                    style={{
                        backgroundImage: `url(${desktopSrc})`,
                        backgroundColor: BLUE,
                    }}
                    className="landing-page-main-header hidden lg:flex md:flex min-h-[458px] flex-col justify-end justify-center items-start bg-no-repeat bg-right-top bg-contain"
                >
                    <Container className="pb-4 text-[#fff]">{content}</Container>
                </div>

                {/* Mobile and tablet */}
                <div className="block md:hidden">
                    <div
                        style={{
                            backgroundImage: `url(${mobileAndTabletSrc})`,
                            backgroundColor: BLUE,
                        }}
                        className="landing-page-main-header min-h-[400px] bg-cover bg-center"
                    />
                    <Container className={`pb-4 text-[${BLUE}]`}>{content}</Container>
                </div>

                <Menu
                    secondary
                    pointing
                    style={{ marginTop: 0, position: "relative", zIndex: 10 }}
                >
                    <Container className="landing-page-main-nav mx-auto flex items-center">
                        {navItems.map((link, index) => (
                            <Menu.Item
                                key={index}
                                className="main"
                                component={Button}
                                onClick={() => {
                                    const el = document.getElementById(
                                        link.replace(/\s/g, "").toLowerCase()
                                    );
                                    const top =
                                        el.getBoundingClientRect().top +
                                        window.scrollY - SCROLL_OFFSET;
                                    window.scrollTo({
                                        top,
                                        behavior: "smooth",
                                    });
                                }}
                            >
                                {link}
                                {!readOnly && (
                                    <Icon
                                        name="delete"
                                        onClick={() => removeNavLink(link)}
                                    />
                                )}
                            </Menu.Item>
                        ))}
                    </Container>
                </Menu>
            </>

            {!readOnly && toggleSettings && (
                <Container className="wide-content z-10" fluid>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    primary
                                    onClick={handleClear}
                                    content="Clear"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid stackable columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Desktop URL</h4>
                                <Input
                                    onChange={handleStateChange("desktopSrc")}
                                    type={"text"}
                                    placeholder="Image URL"
                                    value={desktopSrc}
                                    fluid
                                    action={{
                                        color: "primary",
                                        icon: "images",
                                        onClick: handleOpenModal("desktopSrc"),
                                    }}
                                />
                            </Grid.Column>
							<Grid.Column>
								<h4>Tablet and mobile URL</h4>
								<Input
									onChange={handleStateChange('mobileAndTabletSrc')}
									type={'text'}
									placeholder="Tablet and mobile URL"
									value={mobileAndTabletSrc}
									fluid
									action={{
										color: 'primary',
										icon: 'images',
										onClick: handleOpenModal('mobileAndTabletSrc')
									}}
								/>
							</Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid stackable columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Header</h4>
                                <Input
                                    onChange={handleStateChange("header")}
                                    type={"text"}
                                    placeholder="Enter header"
                                    value={header}
                                    fluid
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h4>Subhead</h4>
                                <Input
                                    onChange={handleStateChange("text")}
                                    type={"text"}
                                    placeholder="Enter subhead"
                                    value={text}
                                    fluid
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid stackable columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Button text</h4>
                                <Input
                                    onChange={handleStateChange("buttonText")}
                                    type={"text"}
                                    placeholder="Button text"
                                    value={buttonText}
                                    fluid
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <h4>Button link</h4>
                                <Input
                                    onChange={handleStateChange("buttonLink")}
                                    type={"text"}
                                    placeholder="Button link"
                                    value={buttonLink}
                                    fluid
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid stackable columns={navItems.length + 1}>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Navbar Links</h4>
                                <Input
                                    onChange={(v) => setNavLink(v.target.value)}
                                    type={"text"}
                                    placeholder="Add navbar link"
                                    value={navLink}
                                    action={{
                                        color: "primary",
                                        icon: "plus",
                                        onClick: () => addNavLink(navLink),
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <ImagePicker
                        open={isModelOpen}
                        onClose={handleCloseModal}
                        onImageClick={handleOnImageClick}
                        bucket="is-media"
                    />
                </Container>
            )}
        </div>
    );
};

export default BigHeaderWithNavbar;
