import { useCallback, useEffect, useState } from "react";

export function useCopyToClipboard() {
    const [copied, setCopied] = useState(0);

    const copyToClipboard = useCallback(async (value) => {
        await navigator.clipboard.writeText(value);
        setCopied(Date.now());
    }, []);

    useEffect(() => {
        if (copied) {
            const id = setTimeout(() => {
                setCopied(0);
            }, 2000);

            return () => clearTimeout(id);
        }
    }, [copied]);

    return {copyToClipboard, copied};
}
