import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'contacts';
const _DET_FIELDS = [
						{
							name: "type",
							label:'Contact Type', 
							type: "select",
							options: [
										{ key: 'gn', value: 'General question', text: 'General question' },
										{ key: 'us', value: 'Help with usage', text: 'Help with usage' },
										// { key: 'cp', value: 'Change script pickup pharmacy', text: 'Change script pickup pharmacy' },
										{ key: 'er', value: 'Report an error', text: 'Report an error' },
										// { key: 'ps', value: 'Partnership', text: 'Partnership' },
										{ key: 'ot', value: 'Other', text: 'Other' },
									],
							placeholder: "Select",
							// param_name: "t",
							req_label: "*",
						},

						{
							name: "name",
							label:'Your Name', 
							type: "text",
							placeholder: "Name",
							// param_name: "n",
							req_label: "*",

							view_link_to: true,
							disabled: true
						},

						{
							name: "email",
							label:'Email', 
							type: "email",
							placeholder: "email",
							// param_name: "e",
							req_label: "*",
							disabled: true
						},

						{
							name: "phone",
							label:'Phone', 
							// type: "number",
							placeholder: "phone",
							// pattern: "0-9",
							// param_name: "p",
							valid_not_required: true,
						},

						{
							name: "message",
							label:'Message', 
							type: "textarea",
							placeholder: "Message",
							// param_name: "m",
							req_label: "*",
						},

					];

const _DET_FIELDS_CHAPHA = [
						{
							name: "type",
							label:'Contact Type', 
							type: "select",
							options: [
										{ key: 'gn', value: 'General', text: 'General' },
										{ key: 'us', value: 'Help with usage', text: 'Help with usage' },
										{ key: 'cp', value: 'Change script pickup pharmacy', text: 'Change script pickup pharmacy' },
										{ key: 'er', value: 'Report an error', text: 'Report an error' },
										{ key: 'ps', value: 'Partnership', text: 'Partnership' },
									],
							placeholder: "Select",
							// param_name: "t",
							req_label: "*",
						},
					];

const _MODEL_NAME = 'contact_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class contact_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get DET_FIELDS_CHAPHA() { return _DET_FIELDS_CHAPHA; }
	static get MODEL_NAME() { return _MODEL_NAME; }

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  


}