import React from "react";
import { Link } from "react-router-dom";
import { Container, Accordion, Message } from "semantic-ui-react";
import { cls } from '../../views/NUI/utils';
import { WithInfoIcon } from "../../views/med/Sections/HDR";
import { TRIPLE_ZERO } from "../../strings";

const FAQ = ({data, readmore, clsn, faq_cls, hide_warn}) => {

        const faq_data = data.map((el, i) => ({ key:i, title: el.title, content: {content : el.content} }))
    
        return <div className={cls("py-4 md:py-24 ui 4 faq", clsn, )}>
            <h1 className="text-center mb-4 md:mb-6">Frequently asked questions</h1>
            <Container>
                <Accordion styled panels={faq_data} className={faq_cls}/> 
            </Container>
            {readmore && <div className="text-is-blue w-full mt-4 text-center"><Link className="more-link" to="/faq" >Read more</Link></div>}

			{!hide_warn && <Container className="mt-12">
				<Message content={<WithInfoIcon>{TRIPLE_ZERO}</WithInfoIcon>} info />
			</Container>}
        </div>
}

export default FAQ;