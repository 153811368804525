import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'semantic-ui-react';


const NotFound = () => {
    return <div className='not-found h-full'>
        <Helmet>
            <title>Page not found</title>
            <meta name="robots" content="noindex" />

        </Helmet>

        <Container className='py-8'>
            <h1 className='text-center'>That page can’t be found.</h1>
            <p className='text-center'>It looks like nothing was found at this location. Maybe try an option from our navigation menu.</p>
        </Container>
    
    </div>;
}
 
export default NotFound;