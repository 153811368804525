import React, { useEffect, useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import ErrorScreen from './ErrorScreen';
import myhr_model from '../../models/myhr_model';
import { generatePrettyIndividualsDetails } from '../../helpers/myhr';

const GetMyHRIndividualDetails = ({ uid, has_access }) => {

	const [loading, setLoading] = useState(true);
	const [ptsDets, setPtsDets] = useState({});
	const [err, setErr] = useState(false);

	useEffect(() => {
		loadMyHRPatientDetails({uid})
	}, [uid, has_access]);

    const loadMyHRPatientDetails = async ({uid}) => {
		setErr(false)
        const resp = await myhr_model.get_individual_details_view({uid})
		if(resp.res !== 'ok'){
			setErr(resp.msg)
			setLoading(false)
		} else {
			setPtsDets({...resp?.individual})
			setLoading(false)
		}		
    }

	if (loading) {
		return (
			<div className="flex flex-row align-middle py-4">
				<Loader active inline size="mini"></Loader>
				<span className='ml-2 text-sm font-normal'>Loading My Health Record Patient Details</span>
			</div>
		)
	}

    if(err){
        return <ErrorScreen message={`Failed to load - ${err}`} size='small'/> 
    }
	const { name, sex, dob, ihiNumber,indigenousStatus } = generatePrettyIndividualsDetails(ptsDets)
	return (
		<>
		<p className='text-sm'>
			<div className='flex flex-row'>
				<span>
					{name} <Icon name={sex=='F' && 'woman' || sex=='M' && 'man' || 'question'} color={sex=='F' && 'pink' || sex=='M' && 'blue' || 'green'} />
					<br/>DOB: {dob}
					<br/>{indigenousStatus}	
				</span>
				<span className='flex-auto text-right'>IHI {ihiNumber}</span>

			</div>
		</p>
		</>
	);
};

export default GetMyHRIndividualDetails;
