import React, { PureComponent } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Confirm,
		TextArea,
		Form
	} from 'semantic-ui-react'

import { ObjectInspector, chromeLight } from 'react-inspector';

const Request = ({row, index}) => (
			<Popup
						trigger={<Button icon='add' />}
						position='left center'
						on='click'
						style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll', maxHeight:'90vh'}}
				>
					<ObjectInspector
							data={ row }
							expandLevel={ 1 }
							theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
						 />
				</Popup>
);

export default Request
