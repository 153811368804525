import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const MAP_CALLBACK = 'initMap';
const MAP_SRC = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB_-cG80WEuN-IMrKkjfoOmlqIgXigHDoY&callback=${MAP_CALLBACK}`;

function Map({ items, renderInfoWindow, center = { location: { lat: -25.344, lng: 131.036 } }, showCenterPin = false, onMarkerClick, onMarkerSelect, showClusters = true, streetViewControl = true, externallySelectedMarker=undefined }, ref) {
	const [google, setGoogle] = useState();
  	const mapRef = useRef(null);
	const markers = useRef([]);
	const markerCluster = useRef();
	const infoWindow = useRef()
	const map = useRef();

	const refreshClusters = () => {
		markerCluster.current.clearMarkers();
		markerCluster.current.addMarkers(
			markers.current.filter((marker) => {
				return marker.getVisible() && map.current.getBounds().contains(marker.getPosition());
			})
		);
	};

	const setMarkersVisibility = (markersArray = []) => {
		markers.current.forEach((marker) => {
			marker.setVisible(markersArray.includes(marker.index));
		});
		refreshClusters();
	};

	const setAllMarkersVisible = () => {
		markers.current.forEach((marker) => marker.setVisible(true));
		refreshClusters();
	};

	useImperativeHandle(ref, () => ({
		setMarkersVisibility,
		setAllMarkersVisible
	}));

	useEffect(() => {
		if (google) return; // Prevent loading the map multiple times on dev when saving the page
		const script = document.createElement('script');
		script.src = MAP_SRC;
		script.async = true;
		typeof onMarkerSelect === 'function' && (window.markerSelectCallback = function(p){ onMarkerSelect(p) })

		window[MAP_CALLBACK] = () => {
			setGoogle(window.google);
		};

		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if(externallySelectedMarker){
			const markerIndex = markers.current.findIndex(marker => marker.title === externallySelectedMarker?.name)
			// If found - set the marker
			if(markerIndex !== -1){
				infoWindow.current.setContent(renderInfoWindow?.(externallySelectedMarker));
				infoWindow.current.open(map.current, markers.current[markerIndex])
			}
		}
	}, [externallySelectedMarker])

	useEffect(() => {
		if (!items?.length || !google) return;

		map.current = new google.maps.Map(mapRef.current, {
			center: center.location,
			disableDefaultUI: true,
			zoomControl: true,
			streetViewControl,
			rotateControl: true
			//styles:map_styles
		});

		infoWindow.current = new google.maps.InfoWindow();

		const svgMarker = {
			path: 'm8.8 26.3c-2.7-3.4-8.8-11.6-8.8-16.2 0-5.6 4.5-10.1 10-10.1 5.5 0 10 4.5 10 10.1 0 4.6-6.1 12.8-8.8 16.2-0.6 0.8-1.8 0.8-2.4 0zm4.5-16.2c0-1.8-1.5-3.4-3.3-3.4-1.8 0-3.3 1.6-3.3 3.4 0 1.9 1.5 3.4 3.3 3.4 1.8 0 3.3-1.5 3.3-3.4z',
			fillColor: '#0065F2',
			fillOpacity: 0.8,
			strokeWeight: 0,
			rotation: 0,
			scale: 1,
			anchor: new google.maps.Point(10, 25), 
		};

		const priceLineMarker = {
			url: "https://storage.googleapis.com/instant-med-public/icons/priceline.svg",
			scaledSize: new google.maps.Size(32, 32),
		}

		const youMarker = {
			path: "M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z",
			fillColor: '#0065F2',
			fillOpacity: 1,
			strokeWeight: 0,
			rotation: 0,
			scale: 1.5,
			anchor: new google.maps.Point(10, 25), 
			zIndex: 2,
		};

		const genericPharmMarker = {
			url: 'https://storage.googleapis.com/instant-med-public/pharmacy.svg',
			scaledSize: new google.maps.Size(42, 42), // scaled size
		}

		let bounds = new google.maps.LatLngBounds();

		[...items, showCenterPin && {...center, isCenter: true} ].forEach((item, index) => {
			const location = item.location || (item && item.geo && item.geo.geometry.location);
			if (!location) return;

			const marker = new google.maps.Marker({
				map: map.current,
				position: location,
				title: item.name,
				icon: item.isCenter ? youMarker : item.name?.match(new RegExp(/(priceline)/i)) ? priceLineMarker : (item?.type === 'pharmacy' ? genericPharmMarker : svgMarker),
				optimized: false, 
				zIndex: item?.zIndex || 1
			});
			marker.index = index;
			google.maps.event.addListener(
				marker,
				'click',
				((marker, item) => () => {
					infoWindow.current.setContent(renderInfoWindow?.(item));
					infoWindow.current.open(map.current, marker);
					typeof onMarkerClick === 'function' && onMarkerClick(item);
				})(marker, item)
			);

			markers.current.push(marker);
			bounds.extend(marker.getPosition());

		});
		// https://mapstyle.withgoogle.com/
		map.current.setOptions({ styles: [
			{
			  "featureType": "administrative.land_parcel",
			  "elementType": "labels",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "poi",
			  "elementType": "labels.text",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "poi.business",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "poi.park",
			  "elementType": "labels.text",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "road.arterial",
			  "elementType": "labels",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "road.highway",
			  "elementType": "labels",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "road.local",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			},
			{
			  "featureType": "road.local",
			  "elementType": "labels",
			  "stylers": [
				{
				  "visibility": "off"
				}
			  ]
			}
		  ] });
		map.current.fitBounds(bounds);
		map.current.setZoom(map.current.getZoom()-1);

		const renderer = {
			render: ({ count, position }) => {
				// change color if this cluster has more markers than the mean cluster
				const color = '#0065F2';
				// create svg url with fill color
				const svg = window.btoa(`
          <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity=".6" r="70" />
            <circle cx="120" cy="120" opacity=".3" r="90" />
            <circle cx="120" cy="120" opacity=".2" r="110" />
          </svg>`);
				// create marker using svg icon
				return new google.maps.Marker({
					position,
					icon: {
						url: `data:image/svg+xml;base64,${svg}`,
						scaledSize: new google.maps.Size(45, 45)
					},
					label: {
						text: String(count),
						color: 'rgba(255,255,255,0.9)',
						fontSize: '12px'
					},
					title: `Cluster of ${count} pharmacies`,
					// adjust zIndex to be above other markers
					zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
					optimized: true
				});
			}
		};

		let boundsListener = google.maps.event.addListener(map.current, 'bounds_changed', () => {});
		if(showClusters){
			markerCluster.current = new MarkerClusterer({ map: map.current, markers: markers.current, renderer });
			boundsListener = google.maps.event.addListener(map.current, 'bounds_changed', refreshClusters);
		}
		
		return () => {
      		google.maps.event.removeListener(boundsListener)
		};
	}, [items, google]);

	return <div ref={mapRef} className="h-[500px] bg-[#ccc] flex justify-center items-center mb-10" />;
}


export default forwardRef(Map);
