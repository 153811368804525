import React, { Component } from 'react';

const IlegEula = () => (
	<div>
		<h3>My Legal Doc End User Agreement</h3>

		<p>Carefully read all the terms and conditions in this End User Agreement <b>(EUA).</b></p>

		<p><b>Your right to use the My Legal Doc Services is conditional upon your acceptance of this EUA.</b> If you (the User) are using the My Legal Doc Services on behalf of a Customer, the User represents and warrants that they are authorised by that Customer to accept the terms of this EUA in their personal capacity and on the Customer’s behalf.  </p>
		
		<p>If the User does not have such authority or you do not agree to the terms of this EUA, do not click the "I Agree" button and/or use the My Legal Doc Services. </p>


		<h3>Definitions and interpretation</h3>

		<p>In this EUA unless the context otherwise requires:</p>

		<p><b>Agreement</b> means the Master Services Agreement in respect of the My Legal Doc Services; </p>
		
		<p><b>Authorised</b> User means a Customer User and any other person expressly authorised by InstantScripts to use the My Legal Doc Services;</p>

		<p><b>Client</b> means a client or potential client of the Customer who is the subject of a Referral; </p>

		<p><b>Confidential Information</b> of a party (the ‘Discloser’) means information of or relating to the Discloser relating to the business, operations or affairs of the Discloser.  It includes: </p>

			<p class="ml-2">(a) trade secrets, know-how, technology, techniques, processes, ideas or concepts, and technical and product information, even if not reduced to material form; </p>
			<p class="ml-2">(b) the My Legal Doc Services and the terms of the Master Services Agreement and;</p>

		<p><b>Customer</b> means the entity that entered into the Master Services Agreement with InstantScripts and who has authorised the User to access and use the My Legal Doc Services on its behalf;</p>

		<p><b>Customer User </b> means an employee of the Customer and any other person or representative of the Customer who is authorised by InstantScripts to use the My Legal Doc Services, including the User; </p>
		
		<p><b>Documentation</b> means all documentation and other written information published or otherwise created and made available by or on behalf of InstantScripts (whether distributed or made available in hard copy format, and/or in the form of documents or electronic content viewable on or downloadable from InstantScripts’ service/support portal or other website, or otherwise) describing the functions and operational and technical characteristics of, and/or explaining how to access, install, configure, use, maintain, or support, the My Legal Doc Services, including any and all technical manuals, operator and user manuals, flow diagrams, file descriptions, and the like; </p>
		
		<p><b>Effective Date</b> means the date that the User signifies their acceptance of the terms and conditions in this EUA by first accessing or using the My Legal Doc Services; </p>
		
		<p><b>InstantScripts</b> means InstantScripts Pty Ltd ACN 629 120 234; </p>

		<p><b>InstantScripts Practitioner</b> means any medical practitioner who is a representative of InstantScripts who provides Medical Services to a Client as a result of a Referral; </p>
		
		<p><b>Master Services Agreement</b> means the written agreement between the Customer and InstantScripts, under which InstantScripts has agreed to make available the My Legal Doc Services to the Customer;</p>
		
		<p><b>Medical Records</b> includes a Medical Services Report, clinical notes, treatment cards, reports, consents, correspondence, memoranda, file notes, test results, x-ray reports, radiological results, radiological imaging and reports, draft reports and letters of referral;</p>

		<p><b>Medical Services</b> means, in respect of each Referral, the services provided by an InstantScripts Practitioner to a Client, including a medical consultation and the preparation of a Medical Services Report or other Medical Record;</p>
		
		<p><b>Medical Services Report</b> means a written report as to the state of health of a Client prepared by an InstantScripts Practitioner following the provision of Medical Services;</p>
		
		<p><b>My Legal Doc Services </b> means: (a) providing access to the Platform and the services made available by InstantScripts online via the Platform, including the facilitation of Referrals and document storage and management of Medical Records for Customers and their Clients, (b) the online, web-deployed, or other My Legal Doc Services provided (or to be provided) by InstantScripts to the Customer via the Platform, any and all software applications or other applications made available or accessible through (or provided as a feature of) the My Legal Doc Services or the Platform, any InstantScripts content that may appear in any output produced by the Customer using the My Legal Doc Services, and (c) all updates to the foregoing, inclusive of any applicable Third Party Products;</p>

		<p><b>Platform</b> means the ‘My Legal Doc’ online technology platform made available by InstantScripts via www.mylegaldoc.com.au; </p>
		
		<p><b>Privacy Approval</b> means any authorisation or approval required from a Client under a Privacy Law for the purposes of InstantScripts providing the My Legal Doc Services;</p>

		<p><b>Privacy Law</b> means the Privacy Act 1988 (Cth) and any other law that applies to the collection, use, disclosure or handling of information about individuals or health records (including a law of a State or Territory relating to health records or privacy);</p>
		
		<p><b>Referral</b> means a referral made by the Customer of a Client to an InstantScripts Practitioner via the Platform; </p>
		
		<p><b>Related Party</b> means:</p>

		
			<p class="ml-2">(a) in respect of a body corporate, anyone who is an associate of that body corporate under sections 11 to 15 (inclusive) of the Corporations Act 2001 (Cth); and  </p>
			
			<p class="ml-2">(b) in respect of an individual, an 'associate' of that individual as defined in section 318 of the Income Tax Assessment Act 1936 (Cth);</p>
		
		
		<p><b>Term</b> means the term of the Agreement;</p>

		<p><b>Third Party Product</b> means any product or service that is supplied to the User or the Customer by or on behalf of a third party;</p>
		
		<p><b>Update(s)</b> means any new releases, versions, enhancements, updates, corrections, patches, bug-fixes, or other modifications (regardless of how characterised) to the My Legal Doc Services or any part of the My Legal Doc Services; and  </p>
		
		<p><b>User Data</b> means any data inputted into the My Legal Doc Services by the Customer or an Authorised User, including Medical Records uploaded by an Authorised User. </p>
		
		

		<h3>Grant of access rights</h3>

		<p>In consideration of the payments under the Agreement, InstantScripts grants the User, and the User accepts, on the terms and conditions contained in this EUA, a non-transferable, non-exclusive limited right to access and use the My Legal Doc Services during the Term in accordance with the Agreement and the Documentation.</p>

		<h3>Use of the My Legal Doc Services</h3>

		<p>The User will:</p>

			<p class="ml-2">(a) not use the My Legal Doc Services, or add any information or material to the My Legal Doc Services, or alter any information or material on the My Legal Doc Services: </p>
			
				<p class="ml-4">(i) unless the User holds all necessary rights, licences, permits, and consents to do so, including Privacy Approvals;</p>
				<p class="ml-4">(ii) that would result in a breach of any law, regulation, rule, code or other legal obligation, including any consumer protection legislation or any applicable Privacy Laws; </p>
				<p class="ml-4">(iii) that is or could reasonably be considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive, pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous, in breach of confidence, or in breach of any obligation relating to privacy; </p>
				<p class="ml-4">(iv) that infringes the rights of any person, including any intellectual property rights;</p>
				<p class="ml-4">(v) that is false, misleading, deceptive, or inaccurate; and</p>
				<p class="ml-4">(vi) that would or may bring InstantScripts or the My Legal Doc Services into disrepute;</p>
			
			<p class="ml-2">(b) not:</p>
			
				<p class="ml-4">(i) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the My Legal Doc Services available to any third party, other than Authorised Users or as expressly permitted by this Agreement; </p>
				<p class="ml-4">(ii) use the My Legal Doc Services to process data on behalf of any third party other than Authorised Users; </p>
				<p class="ml-4">(iii) modify, adapt, or attempt to gain unauthorised access to the My Legal Doc Services; </p>
				<p class="ml-4">(iv) use the My Legal Doc Services in any unlawful manner; </p>
				<p class="ml-4">(v) use the My Legal Doc Services to send unsolicited or unauthorised mail, spam, other forms of duplicative or unsolicited communications; </p>
				<p class="ml-4">(vi) use the My Legal Doc Services to store or transmit files, materials, data, text, audio, video, images or other content that infringes on any person’s intellectual property rights; </p>
				<p class="ml-4">(vii) use the My Legal Doc Services in any manner that interferes with or disrupts the integrity or performance of the My Legal Doc Services and its components; </p>
				<p class="ml-4">(viii) attempt to decipher, decompile, reverse engineer or otherwise discover the source code of any software which comprises the My Legal Doc Services; </p>
				<p class="ml-4">(ix) use the My Legal Doc Services to knowingly post, transmit, upload, link to, send or store any viruses, malware, or any other similar harmful software; </p>
				<p class="ml-4">(x) use the My Legal Doc Services or allow the My Legal Doc Services to be used in respect of any business other than the Customer’s business as a law firm;</p>
				<p class="ml-4">(xi) access the My Legal Doc Services through the use of a robot, spider, scraper or other automated means; </p>
				<p class="ml-4">(xii) cause or allow the My Legal Doc Services to be reverse-engineered, disassembled or de-compiled, or used (except as expressly authorised in this EUA) any part of the My Legal Doc Services or its features or functionality, or information or materials contained on or obtained from or through the My Legal Doc Services; </p>
				<p class="ml-4">(xiii) allow the My Legal Doc Services to be used by, or disclosed to, any person; </p>
				<p class="ml-4">(xiv) provide access to any part of the My Legal Doc Services to third parties (for example, as a service bureau platform);</p>
				<p class="ml-4">(xv) sell, resell, license or sublicense access to the My Legal Doc Services, or archive, modify, reproduce, redistribute, display, perform, publish, license, create derivative works from, offer for sale to any person, or frame or mirror the My Legal Doc Services or any part of it; </p>
				<p class="ml-4">(xvi) access or use the My Legal Doc Services in order to build a competing product or service, or to copy any feature or function of the My Legal Doc Services; </p>
				<p class="ml-4">(xvii) circumvent, remove, amend, deactivate, degrade or thwart any security protections on the My Legal Doc Services, or attempt to do so;</p>
				<p class="ml-4">(xviii) introduce any malicious code or damaging content, information or material to the My Legal Doc Services, or otherwise send or transmit any content, information or material that is designed to, or that has the effect of, interrupting, destroying or limiting the My Legal Doc Services' My Legal Doc functionality or InstantScripts' or a third party's computing systems or networks;</p>
				<p class="ml-4">(xix) interfere with or disrupt access to or use of the My Legal Doc Services by anyone authorised by InstantScripts; </p>
				<p class="ml-4">(xx) remove or alter any of InstantScripts' intellectual property rights notices embedded in or accessible on the My Legal Doc Services; or</p>
				<p class="ml-4">(xxi) permit or procure anyone else to do so. </p>
			
		
		<p>The User acknowledges and agrees that InstantScripts is under no obligation to monitor any information or material, including any User Data that the User transmits or otherwise makes available through the My Legal Doc Services, however InstantScripts has the right to remove any information or material from the My Legal Doc Services at any time in its sole discretion.</p>

		<h3>User Data</h3>

		
			<p class="ml-2">(a) The User is responsible for: </p>
			
				<p class="ml-4">(i) the accuracy, completeness and legality of its User Data and legality of the means by which the User acquired its User Data; </p>
				<p class="ml-4">(ii) effecting and maintaining appropriate security measures to secure its access to and use of the My Legal Doc Services, and to prevent the unauthorised access to or use of the My Legal Doc Services or to the User Data when at rest at the User's or the Customer's systems or in transit between the User's or the Customer's systems and the My Legal Doc Services; and</p>
				<p class="ml-4">(iii) promptly notifying InstantScripts of any breach of security or any unauthorised access to or use of the My Legal Doc Services or User Data (regardless of the cause of such breach or unauthorised access or use).</p>
			
			<p class="ml-2">(b) Despite any other provisions of this EUA, the User acknowledges and agrees that InstantScripts may aggregate anonymous or de-identified data, including regarding the User's usage of the My Legal Doc Services and, potential improvements of or functioning of the My Legal Doc Services and analytics (collectively, ‘Analytics’).  As between the User and InstantScripts, InstantScripts will own all right, title and interest in and to such Analytics and the intellectual property rights relating to them, and such Analytics are InstantScripts' Confidential Information.</p>

		

		<h3>Interaction with Third Party Products</h3>
		<p>If the User uses the My Legal Doc Services in conjunction with any Third Party Products, the User acknowledges and agrees that:</p>
		
			<p class="ml-2">(a) any issues relating to the User's access to and use of those Third Party Products, or relating to any exchange of data or settlement of funds between the Customer and the third party or the Third Party Product, are solely between the User and the applicable third party and are not matters for which InstantScripts is responsible or liable;</p>
			<p class="ml-2">(b) InstantScripts does not warrant or support any Third Party Products or any interface between them and the My Legal Doc Services; and</p>
			<p class="ml-2">(c) InstantScripts is not liable for any disclosure, modification or deletion of User Data or personal information resulting from access to or use of those Third Party Products or any exchange of data with them.</p>

		

		<h3>Updates and Modifications</h3>
		<p>InstantScripts may from time to time without notice to the User, as part of the My Legal Doc Services:</p>
		
			<p class="ml-2">(a) implement Updates to the My Legal Doc Services which may be implemented automatically; or</p>
			<p class="ml-2">(b) make modifications to the My Legal Doc Services or particular components of the My Legal Doc Services including to add or remove features and functionality from time to time. </p>
		

		<h3>Limits on requirement to provide services</h3>
		
			<p class="ml-2">(a) Nothing in this EUA will require InstantScripts to provide (and InstantScripts will not be liable for failing to provide) any of the My Legal Doc Services where providing any part of the My Legal Doc Services would:</p>
			
				<p class="ml-4">(i) amount to a contravention or infringement of any law;</p>
				<p class="ml-4">(ii) place InstantScripts or a Related Party of InstantScripts in breach of any contract to which it is a party; or</p>
				<p class="ml-4">(iii) infringe the Intellectual Property Rights, rights under any Privacy Laws or any contractual or other legal rights of a third party.</p>
			
		

		<h3>Privacy and Regulated Information </h3>
		
			<p class="ml-2">(a) In respect of any Regulated Information, the User must comply with all applicable Privacy Laws.</p>
			<p class="ml-2">(b) Without limiting clause 8(a), the User must ensure that Regulated Information is protected against loss and against unauthorised access, use, modification, disclosure or other misuse and that only authorised personnel have access to Regulated Information.</p>
			<p class="ml-2">(c) The User must promptly inform InstantScripts in writing of any complaint that it receives concerning the use, disclosure, storage, transfer or handling of Regulated Information.  The User must cooperate with InstantScripts in respect of any such complaint.</p>
			<p class="ml-2">(d) The User must ensure that at all relevant times it holds and maintains all necessary Privacy Approvals required for the User to permit InstantScripts to provide the My Legal Doc Services to the User under this EUA and for the User to access the My Legal Doc Services, the User Data and the Medical Records of each Client.</p>
			<p class="ml-2">(e) The User must inform InstantScripts as soon as it becomes aware that it does not hold any such Privacy Approval.</p>
			<p class="ml-2">(f) The User acknowledges and agrees that InstantScripts will rely on any representation made by the User that the User has a Privacy Approval in respect of a Client or any other person without undertaking its own enquiries as to whether the Privacy Approval has in fact been given or is adequate for the purposes for which InstantScripts requires that Privacy Approval.  However, if required by InstantScripts, the User must procure Privacy Approvals in a form that has been approved by InstantScripts. The User must upload copies of a Client’s Privacy Approvals to the relevant Client’s profile on the Platform, or provide evidence of such Privacy Approvals to InstantScripts upon request.</p>
			<p class="ml-2">(g) The User agrees to indemnify and keep indemnified InstantScripts, its Related Parties and their directors, officers, employees, contractors and agents (‘Indemnified Parties’) at all times against all actions, proceedings, liability, claims, damages, costs and expenses of any nature or description whatsoever suffered or incurred by the Indemnified Parties arising in any way out of reliance by any Indemnified Party on a representation by the User that any Privacy Approval has been obtained. </p>
			<p class="ml-2">(h) The indemnity in clause 8(g) is a continuing indemnity and will be irrevocable and remain in full force and effect notwithstanding the termination of this EUA or the Agreement.</p>

		

		<h3>Term and termination</h3>
		
			<p class="ml-2">(a) The rights granted under this EUA to access the My Legal Doc Services begin on the Effective Date and continue for the term of the Agreement unless terminated earlier in accordance with this EUA.</p>
			<p class="ml-2">(b) InstantScripts may suspend or terminate the User’s access to the My Legal Doc Services if:</p>
			
				<p class="ml-4">(i) the User ceases to be a Customer User under the Master Services Agreement; or </p>
				<p class="ml-4">(ii) breaches any terms and conditions of this EUA and does not remedy that breach within 30 days from the date of InstantScripts' notice to do so (to the extent that such breach can be remedied). If the relevant breach cannot be remedied, the suspension or termination will be given immediate effect. </p>
			
			<p class="ml-2">(c) Upon this EUA ending, the User must immediately stop using the My Legal Doc Services and destroy or delete all copies of the Documentation. </p>
		

		<h3>Warranty</h3>
		
			<p class="ml-2">(a) The User acknowledges and agrees that the My Legal Doc Services cannot be guaranteed error-free, and that the existence of any error does not amount to a breach of the terms and conditions of this EUA. </p>
			<p class="ml-2">(b) InstantScripts warrants to the User that InstantScripts has the right to grant the access rights referred to in this EUA and that the User's access and use of the My Legal Doc Services in accordance with this EUA will not infringe the rights of any third party.</p>
			<p class="ml-2">(c) In the event of a claim that any of the My Legal Doc Services or that access to or use of them by the User infringes any third party's intellectual property rights, then InstantScripts may, at InstantScripts' option:</p>
			
				<p class="ml-4">(i) obtain the right for the User to have access to or use the My Legal Doc Services, as contemplated by this EUA; or</p>
				<p class="ml-4">(ii) replace or modify the My Legal Doc Services so that they become non-infringing, while still complying with all the requirements of this EUA.</p>
			
		

		<h3>Limitation of liability</h3>
		Clause 11 of the Master Services Agreement applies to this EUA, specifically that:
		
			<p class="ml-2">(a) other than as expressly set out in this EUA, InstantScripts makes no warranties or representations in relation to the My Legal Doc Services, including in relation to their fitness for purpose whether any purpose is specified or otherwise; and</p>
			<p class="ml-2">(b) to the extent permitted by law: </p>
			
				<p class="ml-4">(i) InstantScripts’ total aggregate liability (including liability for negligence) under or in any way related to the Master Services Agreement, including under or in any way related to this EUA, or the My Legal Doc Services will not exceed, in aggregate, the fees paid by the Customer to InstantScripts during the 12 month period immediately prior to the date the liability arose; </p>
				<p class="ml-4">(ii) InstantScripts will not be liable to the User in relation to, and excludes, any liability (including liability for negligence) under or in any way related to this Agreement for any loss which cannot fairly and reasonably be considered to arise naturally or in the ordinary course of things from the breach of the Master Services Agreement or this EUA or the fact, matter or circumstance giving rise to the Claim;</p>
				<p class="ml-4">(iii) InstantScripts excludes all implied conditions and warranties, except any implied condition or warranty the exclusion of which would contravene any statute or cause any part of this clause to be void ('<b>Non-excludable Condition</b>'); and</p>
				<p class="ml-4">(iv) InstantScripts’ liability for breach of any Non-excludable Condition (other than an implied warranty of title) is limited, at InstantScripts’ option, to refunding the price of the services in respect of which the breach occurred or to providing those services again.</p>
			
		

		<h3>Acknowledgements</h3>
		<p>The User acknowledges and agrees that: </p>
		
			<p class="ml-2">(a) InstantScripts relies on hosting services provided by third parties to host the Platform. InstantScripts makes reasonable commercial efforts to ensure that it selects and engages reputable host service providers and to seek commitments from those host service providers to minimise the likelihood and duration of any downtime for the hosting service and hence access to and use of the Platform and the My Legal Doc Services. However, the User acknowledges that InstantScripts will not be liable to User for any loss or damage occurring in the event that the third party hosting service on which the Platform relies experiences disruptions or downtime. Further, without limiting any other provision of this EUA, the User agrees and acknowledges that InstantScripts will not be liable to the User for any loss or damage occurring due to failure malfunction or inadequacy of any system, equipment, hardware or software utilised by the User in connection with the use of the Platform;</p>
			<p class="ml-2">(b) InstantScripts may not be held responsible for, and has no input into or control over, the provision of Medical Services and/or contents of any Medical Records (including Medical Services Reports) stored or transmitted via the Platform; </p>
			<p class="ml-2">(c) InstantScripts may not be held responsible or liable for the User’s or a Client’s use of, or reliance on, any Medical Services or the contents of any Medical Records (including Medical Services Reports) stored or transmitted via the Platform; and </p>
			<p class="ml-2">(d) InstantScripts may not be held liable to the User or any Client in respect of, arising out of or in any way connected with the provision of Medical Services, the contents of any Medical Records (including Medical Services Reports) stored or transmitted via the Platform or the User’s or a Client’s use of, or reliance on, Medical Services or the contents of any Medical Records (including Medical Services Reports).</p>
		

		<h3>Australian Consumer Law</h3>
		
			<p class="ml-2">(a) If the User is a ‘consumer’ within the meaning of the Australian Consumer Law (which is set out under Schedule 2 of the Competition and Consumer Act 2010 (Cth)), nothing in this EUA is intended to remove the User’s rights under the Australian Consumer Law, including to statutory guarantees that may apply to the My Legal Doc Services, Documents and anything else supplied by InstantScripts under this EUA. If InstantScripts is entitled to limit the remedies available to the Customer for breach of such guarantees, it expressly limits its liability as set out below.</p>
			<p class="ml-2">(b) The User agrees that InstantScripts’ liability for a failure to comply with a guarantee under the ACL in relation to any goods or services supplied under the Agreement is limited to, at the option of InstantScripts, one or more of the following: (i) the replacement of the goods or services, or the supply of equivalent goods or services; or (ii) the payment of the cost of replacing the goods or of supplying the services again. </p>

		

		<h3>Confidentiality</h3>
		<p>The parties acknowledge to each other the confidentiality of each other’s Confidential Information.  Neither party will gain a right, entitlement or interest in the other party’s Confidential Information, other than for the purposes contemplated by this EUA.  The parties must keep all of the other’s Confidential Information confidential, and only use it for the purposes of fulfilling this EUA.  However, this will not apply to information which: </p>
		
			<p class="ml-2">(a) was in the public domain when it was provided, or later enters the public domain, through no fault of the other party; or </p>
			<p class="ml-2">(b) the party is obliged by law to disclose, provided that it has first advised the other party of this obligation. </p>
		

		<h3>General</h3>
		
			<p class="ml-2">(a) (<b>Amendments</b>) InstantScripts may, at any time and at its sole discretion, vary this EUA by publishing the varied terms through the My Legal Doc Services and/or InstantScripts' website. InstantScripts recommends that the User checks InstantScripts' My Legal Doc Services and/or website regularly to ensure that the User is aware of InstantScripts' current EUA.  Continuing to use the My Legal Doc Services will be confirmation of the User's agreement to the varied EUA.</p>
			<p class="ml-2">(b) (<b>Severability</b>) If any clause or part of any clause is in any way unenforceable, invalid or illegal, it is to be read down so as to be enforceable, valid and legal.  If this is not possible, the clause (or where possible, the offending part) is to be severed from this EUA without affecting the enforceability, validity or legality of the remaining clauses (or parts of those clauses) which will continue in full force and effect.</p>
			<p class="ml-2">(c) (<b>Force majeure</b>) InstantScripts is not liable for any failure to perform or delay in performing its obligations under the Master Services Agreement or this EUA if that failure or delay is due to anything beyond its reasonable control.</p>
			<p class="ml-2">(d) (<b>Waiver</b>) A waiver of any right, power or remedy under this Agreement must be in writing signed by the party granting it.  A waiver only affects the particular obligation or breach for which it is given.  It is not an implied waiver of any other obligation or breach or an implied waiver of that obligation or breach on any other occasion.</p>
			<p class="ml-2">(e) (<b>Survival</b>) Clauses 8(g), 9(c), 11, 12, 13 and 14 of this EUA shall survive termination of this EUA. </p>
			<p class="ml-2">(f) (<b>Governing Law</b>) This EUA is governed by the law of Victoria, Australia.  The parties submit to the non-exclusive jurisdiction of its courts and courts of appeal from them.  The parties will not object to the exercise of jurisdiction by those courts on any basis.</p>
		
	</div>
	
);

export default IlegEula
