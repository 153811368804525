const serialize_params = function (obj) {

	return Object.keys(obj).map( k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]) ).join('&')

	// return Object.entries(obj).map( i => [i[0], encodeURIComponent(i[1])].join('=') ) .join('&')
	// return Object.entries(obj).map( (k, v) => [k, encodeURIComponent(v)].join('=') ) .join('&')

	// return Object.keys(obj).reduce(function(a,k){a.push(encodeURIComponent(k)+'='+encodeURIComponent(obj[k]));return a},[]).join('&')
}

export default serialize_params