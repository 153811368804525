import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'org_cat';
const _DET_FIELDS = [
						// {
						// 	name: "type",
						// 	label:'Type', 
						// 	type: "radio",
						// 	options: [{c:'g', n:'Group'}, {c:'i', n:'Organisation'}],
						// 	placeholder: "Type?",
						// 	param_name: "type",
						// 	req_label: "*",
						// },

						{
							name: "oid",
							label:'oid', 
							type: "text",
							// placeholder: "oid",
							param_name: "oid",
							// req_label: "*",
							read_only: true,

							// view_link_to: true,
						},

						{
							name: "onum",
							label:'onum', 
							type: "number",
							// placeholder: "oid",
							param_name: "onum",
							// req_label: "*",
							read_only: true,

							// view_link_to: true,
						},

						{
							name: "name",
							label:'Name', 
							type: "text",
							placeholder: "Name",
							param_name: "name",
							req_label: "*",

							view_link_to: true,
						},

						// {
						// 	name: "code",
						// 	label:'Unique code', 
						// 	type: "text",
						// 	placeholder: "Code",
						// 	pattern: "a-zA-Z0-9_-",
						// 	param_name: "c",
						// 	req_label: "*",
						// },

						{
							name: "desc",
							label:'Description', 
							type: "textarea",
							placeholder: "Description",
							param_name: "desc",
							req_label: "*",
							valid_not_required: true,
						},

					];

const _MODEL_NAME = 'orgs_model';

const _USE_DATA = 'API'
const _API_NAME = 'orgsAdmin'

const _IT_ID = 'oid'
const _IT_KEY = 'onum'
const _PAR_KEY = 'par_onum'
const _IT_ICO = 'building outline'
const _IT_LABEL = 'name'

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class orgs_model extends db_lib {

	static get IT_ID() { return _IT_ID; }
	static get IT_KEY() { return _IT_KEY; }
	static get PAR_KEY() { return _PAR_KEY; }
	static get IT_ICO() { return _IT_ICO; }
	static get IT_LABEL() { return _IT_LABEL; }

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_orgs_tree (refresh = false, p) {

		// console.log('tree data cache check', !!refresh, !(app.app_data.ws_data), !(app.app_data.ws_data&&app.app_data.ws_data.orgs), !(app.app_data.ws_data&&app.app_data.ws_data.orgs&&app.app_data.ws_data.orgs.tree));

		if (refresh || !app.app_data.ws_data?.orgs?.tree) {

			const res = await orgs_model.get_record(null, null, {act:p?.cont&&'tree_cont'||'tree'});

			if (!app.app_data.ws_data)				app.app_data.ws_data = {orgs: res};
			else if (!app.app_data.ws_data.orgs)	app.app_data.ws_data.orgs = res;
			else app.app_data.ws_data.orgs.tree = res.tree;

			// console.log('tree data cached');
		} else {
			// console.log('tree data from cache');
		}

		return app.app_data.ws_data.orgs.tree;

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_org (onum) {

		return await orgs_model.get_record(null, null, {act:'get', onum:onum});


		// if (!(app.app_data.ws_data&&app.app_data.ws_data.orgs&&app.app_data.ws_data.orgs.by_onum&&app.app_data.ws_data.orgs.by_onum[onum])) {

		// 	const res = await orgs_model.get_record(null, null, {act:'get', onum:onum});

		// 	if (!app.app_data.ws_data)					app.app_data.ws_data = {orgs: {by_onum: {[onum]: res}}};
		// 	else if (!app.app_data.ws_data.orgs)		app.app_data.ws_data.orgs = {by_onum: {[onum]: res}};
		// 	else if (!app.app_data.ws_data.orgs.by_onum)	app.app_data.ws_data.orgs.by_onum = {[onum]: res};
		// 	else app.app_data.ws_data.orgs.by_onum[onum] = res;

		// 	console.log('org data cached');
		// } else {
		// 	console.log('org data from cache');
		// }

		// return app.app_data.ws_data.orgs.by_onum[onum];

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static get_oid_to_name (oid) {

		const o = app.app_data.ws_data?.orgs?.tree?.filter( o => o.oid == oid )

		return o&&o[0]&&o[0].name || oid;

	}
	
	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------


	static async get_org_by_oid (oid) {

		return await orgs_model.get_record(null, null, {act:'get', oid});

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async get_public_org_by_oid (oid) {
		return await orgs_model.get_record(null, null, { act: 'get_public_org', oid });
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

}
