import API_service from 'xAppLib/providers/API_service'


const e = (q) => `myhr/${q}`


// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class myhr_model { 

	/**
	 * This toggle_enrollment is used by a user to enrol.
	 * @param {Boolean} user_upload_pref - The value of if they are enrolled.
	 */
	static async toggle_enrollment(user_upload_pref) {
		return await API_service.load_data(e('toggle_enrollment'), {user_upload_pref}) 
	}

	static async has_access(uid) {
		
		return await API_service.load_data (e('has_access'), {uid}) 
	
	}

	/**
	 * This gain_access function processes the following data.
	 * @param {Object} data - The data object.
	 * @param {string} data.uid - uid of the patient to gain access to.
	 * @param {string} data.code - The authorization code required to gain access if need_code is true
	 * @param {string} data.emergency - Emergency access flag if access is required.
	 */
	static async gain_access(data) {
		
		return await API_service.load_data (e('gain_access'), data) 
	
	}
	
	static async revoke_access(data) {
		
		return await API_service.load_data (e('revoke_access'), data) 
	
	}

		/**
	 * This get_view function processes the following data.
	 * @param {Object} data - The data object.
	 * @param {string} data.uid 
	 * @param {string} data.view_type 
	 * @param {string} data.start_date
	 * @param {string} data.end_date
	 */
	static async get_view(data) {
		return await API_service.load_data (e('get_view'), data) 
	}
		/**
	 * This find_documents function processes the following data.
	 * @param {Object} data - The data object.
	 * @param {string} data.uid 
	 * @param {Object} data.filters 
	 */
	static async find_documents(data) {
		return await API_service.load_data (e('find_documents'), data) 
	}
		/**
	 * This get_document function processes the following data.
	 * @param {Object} data - The data object.
	 * @param {string} data.uid 
	 * @param {Object} data.document 
	 * @param {string} data.document.documentId 
	 * @param {string} data.document.repositoryUniqueId 
	 */
	static async get_document(data) {
		return await API_service.load_data (e('get_document'), data) 
	}

		/**
	 * This remove_document function processes the following data.
	 * @param {Object} data - The data object.
	 * @param {string} data.documentID 
	 * @param {string} data.uid 
	 */

	static async remove_document(data) {
		return await API_service.load_data (e('remove_document'), data) 
	}

		/**
	 * This get_individual_details_view function processes the following data.
	 * @param {Object} data - The data object.
	 * @param {string} data.uid 
	 */

	static async get_individual_details_view(data) {
		return await API_service.load_data (e('get_individual_details_view'), data) 
	}

			/**
	 * Uploads document to My HR.
	 * @param {Object} data - The data object.
	 * @param {string} data.sid 
	 */

	static async upload_document(data) {
		return await API_service.load_data (e('upload_document'), data) 
	}

}