import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import {
	Menu,
	Dropdown,
	Icon
	} from 'semantic-ui-react'
import SideAwareDropdown from 'xAppLib/MenuNav/SideAwareDropdown'
import menu_model from 'models/menu_model'
import { useUserData } from 'xAppLib/Hooks';
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';

const SHOW_ICONS = false;
const FLAT_SINGLE = true;


const MainMenuItems = ({ inSideMenu, cls='main' }) => {
	const loc = useLocation()
	const [unseen_count] = useUserData('unseen_results', 0)
	const [unseen_count_express] = useUserData('unseen_results_express', 0)


	const curr_page = loc.pathname

	const menu = menu_model.get_menu(inSideMenu, curr_page, unseen_count, unseen_count_express).filter(Boolean)

	return (
		<React.Fragment>
			{menu.map( (el,i)=> {
				const key=`${i}${el.name}${el.menu}`
				if (el.component) 
					return <React.Fragment key={key}>{el.component}</React.Fragment>
				if (el.type=='separator') 
					return <Dropdown.Divider key={key}/>
				if (el.menu) {
					
					const count = el.count?.value || el.count?.obj && el.count?.path && el.count.obj[el.count.path]
					const items = el.items.filter(Boolean).map( (el,i)=> el.type=='separator' && <Dropdown.Divider key={i}/> 
									|| el.type=='spacer' && <br key={i} style={{lineHeight: '2em', display: 'block'}}/>
									|| el.type=='header' && <Dropdown.Header key={i}>{el.name}</Dropdown.Header>
									|| <Menu.Item
											key = {i}
											icon = {SHOW_ICONS&&el.icon}
											active = {curr_page === el.link}
											as = {!el.act && (el.link?.startsWith('https://') && 'a' || Link) || undefined}
											to = {!el.act && el.link || undefined}
											href = {!el.act && el.link?.startsWith('https://') && el.link || undefined}
											onClick = {el.act && (_=>el.act())}
											className = {`${cls} ${el.cls||''}`}
											target = {el.targ}
										>
											{el.name}{el.counter?.()}
										</Menu.Item>
								)
					
					if (inSideMenu)
						return <Menu inverted fluid vertical key={key}>
							<Menu.Item>
							<Menu.Header>{el.menu} Menu</Menu.Header>
							<Menu.Menu>{items}</Menu.Menu>
						</Menu.Item>
						</Menu>
						
					return FLAT_SINGLE && items.length<=1 ? 	items 	:
						<SideAwareDropdown 
							key={key}
							items={items}
							trigger={<React.Fragment>{SHOW_ICONS&&<Icon name={el.icon} />}{el.menu}</React.Fragment>} 
							/>
							
				}
				
				return <Menu.Item
						key = {key}
						content = {<>{el.name} {(app.settings.iscr && inSideMenu) && 
							<SIcon name="chevron" size="xs" svg_cls='-rotate-90 mr-1' cls="absolute right-4 text-is-black hover:text-is-blue" bold/>}</>}
						icon = {SHOW_ICONS&&el.icon}
						active = {curr_page === el.link}
						as = {!el.act && (el.link?.startsWith('https://') && 'a' || Link) || undefined}
						to = {!el.act && el.link || undefined}
						href = {!el.act && el.link?.startsWith('https://') && el.link || undefined}
						onClick = {el.act && (_=>{el.act()}
							)}
						className = {`${cls} ${el.cls||''}`}
						target = {el.targ}
					/>
			})}
			
		</React.Fragment>
	);
}
 
export default MainMenuItems;
