import React from 'react';
import { Card, Label, Segment } from 'semantic-ui-react';
import moment from 'moment';

const IMAGE_HEIGHT = 200;

export const composeBctUrl = ({ bct, img_fn, bct_slug }) => {
	const slug = bct_slug || `${bct}/${img_fn}`
	return `https://storage.googleapis.com/${slug}`;
};

export default function ImagesPreview({ list, show_tag, isLoading, onImageClick, scrollable, children, height = IMAGE_HEIGHT }) {
	return (
		<Segment basic loading={isLoading}>
			<Card.Group itemsPerRow={6} stackable={true} doubling={true} style={scrollable && { overflowY: 'auto', maxHeight: '50vh'}}>
				{list?.map((p, index) => {
					const { bct, img_fn, bct_slug, comn, tag_label, tm } = p;
					return (
						<Card key={index} onClick={() => onImageClick?.(p, composeBctUrl({ bct, img_fn, bct_slug }))}>
							<div
								style={{
									height,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundImage: `url(${composeBctUrl({ bct, img_fn, bct_slug })})`
								}}
							></div>
							<Card.Content>
								<Card.Header style={{ fontSize: '1em' }}>{comn || 'Untitled'}</Card.Header>
								<Card.Meta>
									{moment(tm).format('D/M/YY HH:mm')}
									{show_tag && <Label attached="top">{tag_label}</Label>}
								</Card.Meta>
							</Card.Content>
						</Card>
					);
				})}

				{children}
			</Card.Group>
		</Segment>
	);
}
