import React from 'react';
import { Popup, Card, Image, Icon } from 'semantic-ui-react'

const outline = type => {
	if (type === 'name') {
		return <>
			<div style={{ position: 'absolute', top: 81, left: 26, width: 49, height: 25, border: '4px solid red' }}></div>
			<div style={{ position: 'absolute', top: 104, left: 16, color: 'red', fontSize: 14, fontWeight: 'bold' }}>First Name</div>
			<div style={{ position: 'absolute', top: 81, left: 86, width: 74, height: 25, border: '4px solid red' }}></div>
			<div style={{ position: 'absolute', top: 104, left: 136, color: 'red', fontSize: 14, fontWeight: 'bold' }}>Last Name</div>
		</>
	}
	else if (type === 'number') {
		return <div style={{ position: 'absolute', top: 55, left: 50, width: 160, height: 30, border: '4px solid red' }}></div>
	}
	else if (type === 'ref') {
		return <>
			<div style={{ position: 'absolute', top: 81, left: 7, width: 25, height: 25, border: '4px solid red' }}></div>
		</>
	}
}

const label = type => {
	if (type === 'name') {
		return {
			header: 'First & Last Name',
			description: 'Do NOT include the names of others listed on your Medicare card.'
		}
	}
	else if (type === 'number') {
		return {
			header: 'Card Number',
			description: 'Enter all 10 digits on your Medicare card. Do NOT include spaces.'
		}
	}
	else if (type === 'ref') {
		return {
			header: 'Individual Reference Number',
			description: 'This is the number next to your name on the Medicare Card.'
		}
	}
}

export default function MedicareToolTip({ type, position = 'bottom center' }) {
	return <Popup on='click' position={position} style={{minWidth: 'fit-content'}} content={
		<Card className='relative'>
			<Image style={{ height: 180, width: 290 }} src='/images/medicare-card-sample.jpeg' ui={false} />
			{outline(type)}
			<Card.Content>
				<Card.Header>{label(type).header}</Card.Header>
				<Card.Description>{label(type).description}</Card.Description>
			</Card.Content>
		</Card>
	} trigger={<Icon name='question circle outline' />} />
}