import React from "react";
import { Button, Dropdown, Icon, Label, Popup } from "semantic-ui-react";
import { ObjectInspector } from "react-inspector";
import { PARTN } from "../constants";

import API_service from "../xAppLib/providers/API_service";
import { useAsync } from "../xAppLib/Hooks/useAsync";
import SimpleShowMore from "../xAppLib/UIelems/SimpleShowMore";
import CopyField from "../xAppLib/UIelems/CopyField";
import ActBtns from "../views/UIelems/fields/ActBtns";

const STATUS_COLOURS = {
	active: 'green',
	annul: 'orange',
	cancel: 'red',
	pending: 'grey',
	scheduled: 'blue',
	skip: 'purple',
	used: 'yellow',
}

const PRESC_STATUS_LABELS = {
	active: 'Needs Review',
	annul: 'Annulled',
	cancel: 'Cancelled',
	pending: 'Pending',
	scheduled: 'Scheduled',
	skip: 'Skipped',
	used: 'Filled',
};

function includeStatusColour(b) {
	return {...b, c: STATUS_COLOURS[b.a]}
}

function StatusLabel({status}) {
	return (
		<span className="flex items-center my-2">
			<Label circular color={STATUS_COLOURS[status]} empty/> <strong className="mx-2">{status}</strong>
		</span>
	);
}

function TermsOfServiceLabel() {
	const showPopup = () => {
		app.trigger(app.events.SHOW_POPUP, {
			pt: "cx_txt",
			txt: 'Treatment Plan - Terms of Service',
			pn: 'treatment-plan-tos'
		})
	}

	const tos = <a className='underline' onClick={showPopup} target='_blank'>Treatment Plan Terms of Service</a>;

	return <label>I have read and accept the {app.runtime.name} {tos}</label>
}

function ChangeStatusDropdown({value, options, loading, onChange}) {
	return (
		<Button.Group size="tiny">
			<Dropdown
				text="Change Status"
				icon="caret down"
				floating
				labeled
				button
				className='icon'
				loading={loading}
				disabled={loading}
			>
				<Dropdown.Menu>
					{options.map((option) => (
						<Dropdown.Item key={option.a}
									   text={option.n}
									   value={option.a}
									   label={{color: option.c, empty: true, circular: true}}
									   active={option.a === value}
									   onClick={() => onChange(option)}
						/>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</Button.Group>
	);
}

function isViaEvermed(row) {
	return !row.dets?.sup_id || row.dets?.sup_id === PARTN.EVERMED;
}

function deliveryPartner(sup_id) {
	return ({
		[PARTN.PA]: 'C2U/PA',
		[PARTN.DUKASA]: 'Dukasa'
	})[sup_id] || sup_id || 'unknown';
}

const _FORM_FIELDS = [
	// {
	// 	name: 'subscription',
	// 	label: 'Treatment Plan - Pricing Schedule',
	// 	type: 'checkbox',
	// 	options: [
	// 		{c: 'yes', n: 'I agree to listed schedule and understand that pricing is indicative'}
	// 	],
	// },
	{
		name: 'subscription_tos',
		label: 'Treatment Plan - Terms of Service',
		type: 'checkbox',
		options: [
			{c: 'yes', n: TermsOfServiceLabel}
		],
	},
	// {
	// 	// submits the actual indicative price displayed to the user for the record
	// 	name: 'indicative_price',
	// 	label: 'Indicative Price',
	// 	type: 'hidden'
	// },
];

// Shouldn't need this any more, but leaving just for existing treatment plans
export const TYPE_DICT = {
	'presc_medsubasm': 'Asthma',
	'presc_medsubhb': 'Reflux/Heartburn',
	'presc_medsubchol': 'Cholesterol',
}

const FIELD_PRNUM = {
	name: 'Num',
	path: 'prnum',
	cell: {collapsing: true},
};

const FIELD_EXTRA_ROW = {
	name: ' ',
	template: ({extraRow}) => (
		<Button basic icon type='button' onClick={() => {
			if (!extraRow.visible) {
				extraRow.load?.();
			}
			extraRow.toggle();
		}}>
			<Icon name={extraRow.visible ? 'triangle down' : 'triangle right'}/>
		</Button>
	),
	cell: {collapsing: true},
};

const FIELD_RAW = {
	name: 'Raw',
	template: ({row}) => (
		<Popup
			trigger={<Button icon='add'/>}
			position='left center'
			on='click'
			style={{
				height: '80%',
				width: '80%',
				minWidth: 700,
				overflow: 'scroll'
			}}
		>
			<ObjectInspector data={row} expandLevel={2}/>
		</Popup>
	),
	can_view: () => app.acl.is_admin || app.acl.is_supp
};

const _LIST_FIELDS = [
	FIELD_EXTRA_ROW,
	{
		name: 'Type',
		path: 'type',
		template: ({value, row}) => <>
			<span className="whitespace-nowrap">{row.consult_name?.replace('Treatment Plan', '') || TYPE_DICT[value] || ''}</span><br />{value}
			<br />
			<CopyField val={row.prnum} />
			{row.ur && <><br /><CopyField val={row.ur} /></>}
		</>
	},
	{
		name: 'Created',
		path: 'cre_tm',
		type: 'time',
		embedded: false,
	},
	{
		name: 'Patient',
		template: ({row}) => (
			<div>
				{row.email}
				<br/>
				{row.first_name} {row.last_name}
			</div>
		),
		embedded: false,
	},
	{
		name: 'Doc',
		template: ({row, docs = {}}) => docs[row.doc_id] || row.doc_id || '-',
		can_view: () => app.acl.is_admin || app.acl.is_supp,
	},
	{
		name: 'Med',
		path: 'med',
		template: ({value, row}) => <>
			<div><strong>{value.name}</strong> {value.size}</div>
			{!isViaEvermed(row) && <div className="text-orange-800 mt-2">
				<Icon name="warning sign" color="orange"/> Delivery via {deliveryPartner(row.dets?.sup_id)}
			</div>}
		</>
	},
	{
		name: 'Details',
		template: ({row}) => (
			<>
				<div><SimpleShowMore cut_length={30} content={row.dets?.msg}/></div>
				<div><SimpleShowMore cut_length={30} content={row.dets?.note}/></div>
				{row.err && (
					<div className="text-orange-800 mt-2">
						<Icon name="warning sign" color="orange"/>{row.err}
					</div>
				)}
			</>
		)
	},
	{
		name: 'Used',
		template: ({row}) => `${row.used} / ${row.total}`
	},
	{
		name: 'Act btns',
		template: function Actions({row, action: fn}) {
			const action = useAsync({immediate: false, fn});
			const admin = app.acl.is_admin || app.acl.is_supp;
			const statuses = [
				admin && {n: 'Active', a: 'active'},
				admin && {n: 'Annul', a: 'annul'},
				{n: 'Cancel', a: 'cancel'},
				admin && {n: 'Pending', a: 'pending'},
				admin && {n: 'Used', a: 'used'},
			].filter(Boolean).map(includeStatusColour);

			return (<>
				<StatusLabel status={row.status}/>

				<ChangeStatusDropdown
					value={row.status}
					options={statuses}
					onChange={b => action.fn(b, row)}
					loading={action.loading}
				/>

				<ActBtns row={row} f={{
					btns_list_func: (row) => (
						[
							admin && ['active', 'pending'].includes(row.status) && {
								n: 'Cancel',
								a: 'cancel',
								c: 'red',
								i: 'lightning',
							},
							admin && !isViaEvermed(row) && {
								n: <span className="whitespace-nowrap">Use Evermed</span>,
								a: 'evermed',
								c: 'green',
								q: 'Are you sure you want to set the delivery method to Evermed?',
								i: 'tree',
							},
						].filter(Boolean)
					),
					act_run: async (b, row) => {
						const ans = await app.confirm('Confirm Action', b.q || `Are you sure you want to ${b.a} this treatment plan?`);
						return ans === 'yes' && action.fn(b.a, row);
					}
				}}/>
			</>);
		}
	},
	FIELD_RAW
];

const _SCHEDULE_FIELDS = [
	FIELD_EXTRA_ROW,
	FIELD_PRNUM,
	{
		name: 'Status',
		path: 'status',
		template: ({value, row}) => {
			return <>
				{value}
				{row.scripts?.map(scr => <div key={scr.sid}><small>&gt; {scr.sid}</small></div>)}
			</>
		}
	},
	{
		name: 'Scheduled',
		path: 'activate_at',
		type: 'time',
	},
	{
		name: 'Delivery Target',
		path: 'dets.deliver',
		type: 'time',
		embedded: false,
	},
	{
		name: 'Error',
		template: ({row}) => row.err && (
			<>
				<div>{row.err}</div>
				<div>{row.dets?.internal_err}</div>
			</>
		)
	},
	{
		name: 'Act Btns',
		template: function ScheduleActions({row, action: fn}) {
			const action = useAsync({immediate: false, fn});
			const admin = app.acl.is_admin || app.acl.is_supp;
			const statuses = [
				admin && {n: 'Scheduled', a: 'scheduled'},
				admin && {n: 'Active', a: 'active'},
				admin && {n: 'Annul', a: 'annul'},
				{n: 'Cancel', a: 'cancel'},
				admin && {n: 'Pending', a: 'pending'},
				admin && {n: 'Skip', a: 'skip'},
				admin && {n: 'Used', a: 'used'},
			].filter(Boolean).map(includeStatusColour);

			return (<div className="flex flex-col items-start space-y-2">
				<StatusLabel status={row.status}/>

				<ChangeStatusDropdown
					value={row.status}
					options={statuses}
					onChange={b => action.fn(b, row)}
					loading={action.loading}
				/>

				<ActBtns row={row} f={{
					btns_list_func: (row) => (
						[
							admin && row.status === 'pending' && {
								n: 'Schedule Now',
								a: 'schedule',
								c: 'blue',
								i: 'time',
							},
							admin && ['scheduled', 'pending'].includes(row.status) && false /* FIXME RUNS AS ADMIN ON BACKEND AND REJECTS */ && {
								n: 'Order Now',
								a: 'activate',
								c: 'green',
								i: 'send',
							},
						].filter(Boolean)
					),
					act_run: async (b, row) => {
						const ans = await app.confirm(`Are you sure you want to ${b.a} this prescription?`);
						return ans === 'yes' && action.fn(b.a, row);
					}
				}}/>
			</div>);
		}
	},
	FIELD_RAW
];


export default class treatment_plan_model {
	static get FORM_FIELDS() {
		return _FORM_FIELDS;
	}

	static get LIST_FIELDS() {
		return _LIST_FIELDS;
	}

	static get SCHEDULE_FIELDS() {
		return _SCHEDULE_FIELDS;
	}

	static async get_treatment_plan(tpid, {uid} = {}) {
		const res = await API_service.load_data(`treatment_plan/${tpid}`, {uid});
		if (res?.treatment_plan) {
			return res.treatment_plan;
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error loading treatment plan'))
	}

	static async list_treatment_plans(payload = {}) {
		const res = await API_service.load_data(`treatment_plans`, payload);
		if (res?.treatment_plans) {
			return res.treatment_plans;
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error loading treatment plans'))
	}

	static async admin_list_treatment_plans(payload) {
		const res = await API_service.load_data(`admin/treatment_plans`, payload);
		if (res?.treatment_plans) {
			return res;
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error loading treatment plans'))
	}

	static async admin_manage_presc(act, prid) {
		return await API_service.load_data(`admin/treatment_plans/${act}/${prid}`, {/* force post*/});
	}

	static async admin_get_treatment_plan(tpid) {
		const res = await API_service.load_data(`admin/treatment_plans`, {filters: {tpid}});
		if (res?.treatment_plans?.length === 1) {
			return res.treatment_plans[0];
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error loading treatment plan'))
	}

	static async cancel_treatment_plan(tpid) {
		const res = await API_service.load_data(`treatment_plan/${tpid}/cancel`, {/* force post */});
		if (res?.res === 'ok') {
			return true;
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error cancelling treatment plan'))
	}

	static async skip_repeat(tpid, prid) {
		const res = await API_service.load_data(`treatment_plan/${tpid}/skip`, {prid});
		if (res?.res === 'ok') {
			return true;
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error skipping repeat'))
	}

	static async update_treatment_plan(tpid, payload) {
		const res = await API_service.load_data(`treatment_plan/${tpid}/update`, payload);
		if (res?.res === 'ok') {
			return true;
		}
		return Promise.reject(new Error(res?.err || res?.msg || 'Unknown error updating treatment plan'))
	}

	static presc_status_label(status) {
		return PRESC_STATUS_LABELS[status] || status;
	}
}
