import React from 'react';
import moment from 'moment';

/**
 * 
 * @param {String} dob Date of birth in the format YYYY-MM-DD or unixtimestamp
 * @returns 
 */
export default function Age({ dob, seed = new Date() }) {
    return <>{moment(dob).format('D/M/YYYY')} ({moment(seed).diff(dob, 'years')} years old)</>
}