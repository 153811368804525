import React from "react";
import { Container, Icon } from "semantic-ui-react";

export default function InlineController({
    target,
    order,
    dispatch,
    first,
    last,
    visible,
}) {

    if (!visible) return null;

    return (
        <Container className="z-10">
            <Icon
                name="close"
                color="red"
                onClick={() =>
                    dispatch({
                        type: "REMOVE",
                        target,
                        order,
                    })
                }
            />
            {first && (
                <Icon
                    name="arrow up"
                    color="red"
                    onClick={() =>
                        dispatch({
                            type: "UP",
                            target,
                            order,
                        })
                    }
                />
            )}
            {last && (
                <Icon
                    name="arrow down"
                    color="red"
                    onClick={() =>
                        dispatch({
                            type: "DOWN",
                            target,
                            order,
                        })
                    }
                />
            )}
        </Container>
    );
}
