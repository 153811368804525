import React, { useState, useRef, useEffect } from "react";
import { cls } from "./utils";

const Accordion = ({ title, content, active }) => {
    const [open, setOpen] = useState(false);
    const contentEl = useRef(null);

    useEffect(() => {
        setOpen(active);
    }, [active]);

    const handleShow = () => {
        setOpen(!open);
    };

    return (
        <div className="bg-is-gray-20 rounded accordion">
            <div
                onClick={handleShow}
                className={cls(
                    "px-4 py-5 flex justify-between bg-white border-is-gray-50 cursor-pointer",
                    open
                        ? "open border-t border-x rounded-t"
                        : "rounded mb-4 border"
                )}
            >
                <h4 className="font-semibold mb-0">{title}</h4>
                {open ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                    >
                        <line y1="10" x2="19" y2="10" stroke="black" />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                    >
                        <line
                            x1="9.5"
                            y1="19.5"
                            x2="9.5"
                            y2="0.5"
                            stroke="black"
                        />
                        <line y1="10" x2="19" y2="10" stroke="black" />
                    </svg>
                )}
            </div>
            <div
                ref={contentEl}
                className={cls(
                    "bg-white h-fit transition-all duration-200 border-is-gray-50",
                    open ? "block border-x border-b rounded-b mb-4" : "hidden"
                )}
            >
                {content}
            </div>
        </div>
    );
};

export default Accordion;
