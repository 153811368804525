import React, { useState, useEffect } from 'react'
import { Button, Container, Loader, Segment } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { useUser } from '../../xAppLib/Hooks';
import { cls } from '../NUI/utils';
import discounts_model from 'models/discounts_model';
import ShareToSocialsButton from '../../xAppLib/UIelems/ShareToSocialsButton';
import gtm from '../../xAppLib/providers/gtm';

const discount = 5;

const Refer = ({ page = 'landing' }) => {

    const { user_in } = useUser();
    const img_path = "https://storage.googleapis.com/instant-med-public";
    const [loading, setLoading] = useState(false);
    const [code, setRefCode] = useState(user_in?.prof?.referral_code || null);
    const [copied, setCopied] = useState(false);
    const lg = page === 'landing';
    const [redeem, setRedeemTotal] = useState();

    const subj = `Here's $${discount} off InstantScripts`;
    const msg = `Hi! I'm inviting you to try InstantScripts -- Australia's largest online medical clinic. Use my code below to get $${discount} off your first healthcare service (Terms and Conditions apply). That could be a Telehealth Consultation or a request from a doctor for a Script, Medical Certificate or even Blood Test. Visit InstantScripts website to learn more. \n\nMy referral code: ${code} \n\nHow it works: \n
    1. Create an InstantScripts account.
    2. When the time comes, select the healthcare service you need.
    3. Use my referral code in the checkout process.
    `;

    const loadCode = async (create) => {
        setLoading(true);
        const { res, discount } = await discounts_model.get_referral_code(create)
        if(res == 'ok') {
            const reward = await discounts_model.get_discount_by_code(discount?.code)
            const uses = reward?.max_use - reward?.used;
            setRedeemTotal(uses)
            setRefCode(discount?.code)
        }
        setLoading(false);
    }

    useEffect(_ => {
        user_in && loadCode(false);
    }, [user_in])

    const getCode = code ?
        <>
            <p className={`${lg ? 'text-xl !mb-[30px]' : 'text-base my-4'} font-bold `}>Your unique code:</p>
            <div className={`${lg ? 'bg-[#FFF4E3] lg:w-[411px] px-10 py-5 ' : 'bg-is-gray-10 lg:w-[400px] p-4'} flex w-full justify-between items-center gap-4 rounded mb-[30px]`}>
                <h3 className={`${!lg && 'text-[1.25rem]'} font-bold mb-0 text-[#3E454B]`}>{code}</h3>
                {copied ? <p className="uppercase mb-0 text-is-black-50">Copied</p> : 
                <button className="text-is-blue uppercase text-base hover:underline" onClick={() => navigator.clipboard.writeText(code).then(() => { setCopied(true)})}>
                    Copy code
                </button>}
            </div>
            <ShareToSocialsButton msg={msg} subj={subj} cls={lg && "!text-is-blue"} />
        </>
        : <Button basic className="w-full md:w-fit"
            onClick={async _ => {
                loadCode(true);
                gtm.log('', 'gen_ref_code');
            }}>Generate code</Button>

    return (
        page === 'profile' ?
            <Segment loading={loading}>
                <h5>Refer a Friend Program</h5>
                <p>Introduce your friends and family to InstantScripts and you'll both get ${discount} off your next healthcare service.&nbsp; <Link to="/refer-a-friend" className="underline ">Terms and conditions apply.</Link></p>
                {getCode}
                {!!redeem && <p className="mt-4 text-sm">
                            How many friends have successfully used your referral code: {redeem}<br/>
                            Your reward/s will automatically be applied to your next healthcare service.</p>}
            </Segment>
            :
            <div className="bg-is-blue text-white">
                <div className="h-auto relative flex flex-wrap w-full">
                    <div className={cls("h-[400px] w-full lg:w-auto lg:h-auto static lg:absolute top-0 bottom-0 lg:left-1/2 right-0 bg-cover bg-no-repeat bg-center rounded-bl-[140px] lg:rounded-bl-[280px] lg:left-[50%_-_.8rem]")} style={{ backgroundImage: `url(${img_path}/refer-a-friend.jpg)` }}></div>

                    <Container>
                        <div className="w-full lg:w-1/2 py-4 pb-20 lg:py-[108px] text-center lg:text-left">
                            <img className="mb-4 w-[80px] lg:w-[116px] mx-auto md:mx-0" src={`${img_path}/chat-icon.svg`} />
                            <h2 className="text-xl lg:text-[3rem] lg:leading-[72px] mt-2 mb-4 sm:mb-8 font-bold  lg:w-3/4">
                                Refer a friend and get ${discount} off your next InstantScripts service.*
                            </h2>
                            <p className="lg:w-3/4 mb-8 lg:mb-16">Introduce your friends and family to InstantScripts and you'll both get ${discount} off your next healthcare service. <Link to="/refer-a-friend-terms" className="no-underline hover:underline hover:!text-white">Terms and conditions apply.</Link></p>

                            {loading && <div className="relative"><Loader active inverted className="!left-4" /></div>
                                || (user_in ? getCode
                                    : <>
                                        <p className="mt-4 font-bold ">To reveal your referral code log into your <br />InstantScripts account.</p>
                                        <Button basic className="!text-is-blue w-full md:w-fit" onClick={() => app.trigger(app.events['REQUEST_LOGIN'])}>Log in</Button>
                                    </>)
                            }
                        </div>
                    </Container>
                </div>

                <div className="bg-white -mb-8">
                    <Container>
                    <p className="text-sm text-is-black-80 py-[25px]">*By accepting this offer you agree to the <Link to="/refer-a-friend-terms">Terms and Conditions</Link> and our <Link to="/privacy">Privacy Policy</Link>.</p>
                    </Container>
                </div>
            </div>
    )
}

export default Refer;