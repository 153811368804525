//	------------------------	------------------------	------------------------
//	Description: Send Attachments component
//
// 	Expected props: 
// 		show_send_all: 			shows the send all button
// 		show_send_individual: 	shows the send individual button
// 		scr_obj: 				the script data
//		hide_privy:		 		hide the privy confirm box
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { Button, Modal, Icon, Form, Table, Input, Segment, Checkbox } from 'semantic-ui-react'
import moment from 'moment-timezone'

import API_service from 'xAppLib/providers/API_service'
import MissingResults from './MissingResults'
import { prettifyMC } from '../../helpers/prettify'

//import UniForm from '../UniForm/UniForm'
//import UniFormSUI from 'xAppLib/UniForm/UniForm-SUI'

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default class SendAttachments extends Component {

	constructor(props) {

		super(props)

		this.state = {
			sending_all: false,
		}

		this.init({ constructor: true })

	}

	// 		--------------------------------		--------------------------------		---------

	async init(params = {}) { // Decided to go against using state for these largely static vars

		this.script_sid = this.props.scr_obj?.sid
		this.has_script = !!(this.script_sid)
		this.patient_email = this.props.scr_obj?.spd_data?.email || this.props.scr_obj?.email
		this.has_patient_email = !!(this.patient_email)
		this.doc_email = this.props.scr_obj?.doc_db_data?.email
		this.has_doc_email = !!(this.doc_email)
		this.user_email = app?.user?.user_obj?.email
		this.has_user_email = !!(this.user_email)
		this.file_attach = this.props.scr_obj && Array.isArray(this.props.scr_obj?.attch) && this.props.scr_obj?.attch?.length > 0 && (this.props.scr_obj.attch.filter(a => typeof a.file_fn == 'string'))
		this.has_attachments = !!(this.file_attach && this.file_attach.length > 0)
		this.hide_privy = !!(this.props.hide_privy)

		//console.log({script_sid: this.script_sid, has_script: this.has_script, patient_email: this.patient_email, has_patient_email: this.has_patient_email, file_attach: this.file_attach, has_attachments: this.has_attachments, hide_privy: this.hide_privy})

		if (!params?.constructor) this.setState({})

	}

	// 		--------------------------------		--------------------------------		---------

	// static getDerivedStateFromProps(props, current_state) { // Update on props change
	// 	if (current_state.value !== props.value) {
	// 		//console.log('Prop change!')
	// 		init()
	// 		return { value: props.value }
	// 	}
	// 	return null
	// }

	// 		--------------------------------		--------------------------------		---------
	//      Functions
	// 		--------------------------------		--------------------------------		---------

	showSendAttachmentsModal() {

		const email_bccs = {}

		if (this.has_user_email) email_bccs[this.user_email] = true
		if (this.has_doc_email) email_bccs[this.doc_email] = true

		this.send_attach_email_bcc = `${Object.keys(email_bccs).join(',')}`
		this.send_attach_email_to = this.patient_email || ''
		this.send_attach_sid = this.script_sid

		this.setState({ showSendAttachments: true, send_attach_accept: false, send_files: this.file_attach, send_files_selected: {}, sending_email: false, send_res: null })

	}

	//	------------------------	------------------------	------------------------

	async handleSendAll() {

		if (this.state.sending_all) console.log('Already sending, please wait...')
		else this.setState({ sending_all: true }, async _ => {

			const con_res = await app.confirm(`Are you sure?`, `Send all results to ${this.patient_email}?`)

			if (con_res == 'yes') {

				const send_params = {
					to: this.patient_email,
					bcc: '',
					files: this.file_attach,
					message: undefined,
					sid: this.script_sid,
				}

				const send_res = await API_service.load_data('sendattch', send_params)

				app.trigger(app.events.SHOW_MESSAGE,
					'Send all results',
					send_res.res == 'ok' && `Sent results to ${this.patient_email}` || `Error sending results: ${send_res.err}`,
					send_res.res == 'ok' && 'positive' || 'negative')

			}

			this.setState({ sending_all: false })

		})

	}

	//	------------------------	------------------------	------------------------

	async sendAttachments() {

		if (this.state.sending_email) console.log('Already sending, please wait...')
		else {
			this.setState({ sending_email: true, send_res: null }, async _ => {

				const send_params = {
					to: this.send_attach_email_to,
					bcc: this.send_attach_email_bcc,
					files: Object.values(this.state.send_files_selected),
					message: this.send_attach_msg,
					sid: this.send_attach_sid,
				}

				const send_res = await API_service.load_data('sendattch', send_params)

				this.setState({ sending_email: false, send_res })
			})
		}

	}

	// 		--------------------------------		--------------------------------		---------
	//      UI
	// 		--------------------------------		--------------------------------		---------

	render() {

		const already_sending_all = this.state.sending_all

		return 1 &&

			<React.Fragment>

				{false && this.props.show_send_all &&
					<Button
						content={`Send all`}
						icon='send'
						size='small'
						color='blue'
						onClick={_ => this.handleSendAll()}
						loading={already_sending_all}
						disabled={!this.has_script || !this.has_patient_email || !this.has_attachments || already_sending_all}
					/>
				}

				{this.props.show_send_individual &&
					<Button
						content={`Send`} // Send results
						icon='send'
						size='small'
						color='violet'
						onClick={_ => this.showSendAttachmentsModal()}
						disabled={!this.has_script || !this.has_attachments}
					/>
				}

				{this.props.show_submit_path_followup_ticket &&
					<MissingResults scr_obj={this.props.scr_obj}/>
				}

				{this.render_send_file_modal()}

			</React.Fragment>
	}

	render_pts_details(params, format_mc = false){
		const { first_name, middle_name, last_name, dob, phone, mobile, medicare, street, city, state, postcode, address } = params || {};
		const formatted_name = [first_name, middle_name, last_name].filter(Boolean).join(' ');
		const formatted_address = address ? address : [street, city, state, postcode].filter(Boolean).join(', ');
		let combined_pts_dets = [dob && `DOB: ${dob}`, medicare && `MC: ${format_mc ? prettifyMC(medicare) : medicare}`, phone || mobile && `Ph: ${phone || mobile}`].filter(Boolean).join(' - ');

		if (!formatted_name && !formatted_address && !combined_pts_dets) {
			return <span>No patient details found</span>;
		}

		return (
			<div className='flex flex-col gap-0'>
				<b>{formatted_name.toUpperCase()}</b>
				<span>{combined_pts_dets.toUpperCase()}</span>
				<span>{formatted_address.toUpperCase()}</span>
			</div>
		);
	}

	//	------------------------	------------------------	------------------------

	render_send_file_modal() {
		return (

			<Modal open={!!this.state.showSendAttachments} size='fullscreen'>
				<Modal.Header><Icon name='send' /> Send Attachments</Modal.Header>
				<Modal.Content className='overflow-x-auto'>
					<Form>
						<div className="flex flex-row justify-between items-center">
							<h4 className='mt-0 text-center'>Attachments to send</h4>
							<div className='text-end'>
								{this.render_pts_details(this.props.scr_obj?.spd_data)}
							</div>
						</div>
						<Table basic>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Send?</Table.HeaderCell>
									<Table.HeaderCell>Date Time</Table.HeaderCell>
									<Table.HeaderCell>Patient</Table.HeaderCell>
									<Table.HeaderCell>Contents</Table.HeaderCell>
									<Table.HeaderCell>File Type</Table.HeaderCell>

								</Table.Row>
							</Table.Header>
							<Table.Body>
								
								{
									this.state.send_files && this.state.send_files.length > 0 && this.state.send_files.map((e, i) => {
										const tm_parse = moment(e.tm)
										const tm_show = tm_parse && tm_parse.isValid() && tm_parse.format('YYYY-MM-DD HH:mm') || 'unknown date'

										const results_data =  e.rid && this.props.scr_obj?.results_raw?.find(r => r.rid === e.rid) || {}
										const { patient, observations } = results_data?.data_json || {}
										const tests =
											observations &&
											observations
												.filter((o) => o.type !== 'FT')
												.map((o) =>
													o.obs_id
														.filter((o) => /[a-zA-Z]/.test(o))
														.sort((a, b) => b.length - a.length)[0]
														.toUpperCase()
												)
												.join(', ');

										const show_desc = e.comn || 'No description'
										const show_type = e.orig_type || 'Unknown file type'
										return 1 &&
											<Table.Row key={`e_${i}`}>
												<Table.Cell>
													<Checkbox
														toggle
														checked={typeof this.state.send_files_selected?.[i] != 'undefined'}
														onChange={_ => this.handle_file_select({ file: e, key: i })}
													/>
												</Table.Cell>
												<Table.Cell>{tm_show}</Table.Cell>
												<Table.Cell>{this.render_pts_details(patient, true)}</Table.Cell>
												<Table.Cell width={8}>{tests || show_desc}</Table.Cell>
												<Table.Cell>{show_type}</Table.Cell>
											</Table.Row>
									}) || <Table.Row><p>No sendable attachments</p></Table.Row>
								}
							</Table.Body>
						</Table>

						<h4>Recipient</h4>

						<Table basic>
							<Table.Body>
								<Table.Row>
									<Table.Cell collapsing>To</Table.Cell>
									<Table.Cell><Input
										//value={this.state.send_attach_email_to} //Dirty slow use of state, use default and non-state to manage
										defaultValue={this.send_attach_email_to}
										onChange={(e, d) => { this.send_attach_email_to = d.value }}
										type={'text'}
										placeholder={'Email address'}
										fluid
									/></Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell collapsing>BCC</Table.Cell>
									<Table.Cell><Input
										//value={this.state.send_attach_email_bcc}
										defaultValue={this.send_attach_email_bcc}
										onChange={(e, d) => { this.send_attach_email_bcc = d.value }}
										type={'text'}
										placeholder={'Email address'}
										fluid
									/></Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>

						<h4>Additional message</h4>

						<Input
							onChange={(e, d) => { this.send_attach_msg = d.value }}
							type={'text'}
							placeholder={'Additional message'}
							fluid
						/>

						{!this.hide_privy &&
							<React.Fragment>
								<h4>Confirmation</h4>
								<Segment>

									<p>Please check all files to ensure the all recipients are privy to the information. Faxes may contain more than one patient's information.</p>
									<Checkbox
										toggle
										label={'I have checked all the selected files and the email recipients are privy to these files'}
										checked={this.state.send_attach_accept}
										onChange={_ => { this.setState({ send_attach_accept: !this.state.send_attach_accept }) }}
									/>
								</Segment>
							</React.Fragment>
						}

					</Form>
					{(this.state.send_res?.res == 'ok') && <h4 style={{ color: 'green' }}>Send successful!</h4>}
					{(this.state.send_res?.res == 'err') && <h4 style={{ color: 'red' }}>Send unsuccessful: {this.state.send_res?.err || 'unknown error'}</h4>}
				</Modal.Content>
				<Modal.Actions>
					<Button color='green' onClick={_ => this.sendAttachments()} disabled={(!this.hide_privy && !this.state.send_attach_accept) || !(this.state.send_files_selected && Object.keys(this.state.send_files_selected).length > 0) || this.state.sending_email} loading={this.state.sending_email}>
						<Icon name='checkmark' /> Send
					</Button>
					<Button color='red' onClick={_ => this.setState({ showSendAttachments: false })} disabled={this.state.sending_email}>
						<Icon name='close' /> Close
					</Button>
				</Modal.Actions>
			</Modal>
		)

	}

	// 		--------------------------------		--------------------------------		---------

	async handle_file_select(params = {}) {

		const { file, key } = params

		if (typeof key == 'undefined' || typeof file == 'undefined') return
		if (typeof this.state.send_files_selected[key] == 'undefined') {
			this.state.send_files_selected[key] = file
			this.setState({ send_attach_accept: false })
		}
		else {
			delete this.state.send_files_selected[key]
			this.setState({}) // Need this for UI update
		}

	}

}

// 		--------------------------------		--------------------------------		---------

function bytes_to_text(size) {

	if (typeof size != 'number' || size < 0) return '? b'
	if (size < Math.pow(10, 3)) return `${size.toFixed(0)} b` // Can do 2 base if we want kib, mib, gib
	if (size < Math.pow(10, 6)) return `${(size / Math.pow(10, 3)).toFixed(1)} kb`
	if (size < Math.pow(10, 9)) return `${(size / Math.pow(10, 6)).toFixed(1)} mb`
	return `${(size / Math.pow(10, 9)).toFixed(1)} gb`

}