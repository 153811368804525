import React, { useState, useEffect } from 'react';
import { Dropdown, Loader, Button } from 'semantic-ui-react';
import API_service from '../../xAppLib/providers/API_service';
import currency from 'currency.js';

const DeliverySelector = ({ med_params, shipping_address_components, api_params, orig_delivery, handleSubmit }) => {
	const [items, setItems] = useState([]);
	const [feedback, setFeedback] = useState({ loading: true, error: false });
	const [shiftDown, setShiftDown] = useState(false);
	const [selectedItem, setSelectedItem] = useState('');
	const [bgColor, setBgColor] = useState('bg-white')
	const [formLoading, setFormLoading] = useState(false)

	const {price: charged_price, name: original_method, code} = orig_delivery

	useEffect(async () => {
		if(shipping_address_components){
			setFeedback({ ...feedback, loading: true });
			let delivery_res
			const default_params = {
				showCourier: true,
				...shipping_address_components,
				...api_params,
				isClickAndCollect: false,
			}
			if(med_params && shipping_address_components ){
				delivery_res = await API_service.load_data('evermed/deliveryAndMedOpts', {
					...default_params,
					...med_params
				});
			} else {
				delivery_res = await API_service.load_data('evermed/getDeliveryMethods', {
					...default_params
				});
			}
			if (delivery_res.delRes !== 'ok') {
				setFeedback({ loading: false, error: delivery_res.delRes });
			} else {
				const flatOpts = [];
				delivery_res?.delOpts?.forEach((obj) => {
					if (obj.options && Array.isArray(obj.options)) {
						flatOpts.push(...obj.options);
					}
				});
				setItems(flatOpts);
				setFeedback({ ...feedback, loading: false });
			}
		}
	}, []);

	const selectedItemPrice = selectedItem && items.find((i) => i.code === selectedItem)?.price;
	const priceDifference = (selectedItemPrice && charged_price && currency(selectedItemPrice).subtract(charged_price).format()) || false;

	const handleKeyDown = (e) => {
		if (e.shiftKey) {
			setShiftDown(true);
		} else {
			setShiftDown(false);
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		setBgColor('bg-blue-500 text-white')
		setTimeout(() => {
			setBgColor('bg-white text-black transition-all duration-500');
		  }, 500);
	}, [code])

	if (feedback.loading) {
		return (
			<>
				<Loader active inline />
				<p>Loading...</p>
			</>
		);
	}

	if (feedback.error) {
		return (
			<p className='text-red-400'>
				{feedback.error === 'bad_address'
					? 'Shipping address is not valid - Please change before changing shipping method'
					: feedback.error}
			</p>
		);
	}

	return (
		<div className='flex flex-col gap-4'>
			<div className='flex flex-col gap-1'>
				{original_method && <span>Original Delivery Method: {original_method} - {charged_price ? currency(charged_price).format(true) : 'price unknown'}</span>}
				{<span>Current Delivery Code: <b className={`px-2 max-w-sm rounded-xl ${bgColor}`}>{code}</b></span>}
			</div>
			{items.length > 0 && <Dropdown
				placeholder='Select an item'
				fluid
				selection
				clearable
				value={selectedItem}
				onChange={(e, { value }) => setSelectedItem(value)}
				options={items.map((i) => {
					return {
						key: i.code,
						value: i.code,
						text: `${i.providerName} ${i.displayName} ${i.displaySubtext} - $${i.price}`,
						disabled: orig_delivery.code === i.code || shiftDown ? false : charged_price < i.price,
						style: { ...(charged_price < i.price && !shiftDown && { backgroundColor: '#fff2f2', color: 'black' }) },
					};
				})}
			/>}
			{items.length === 0 && <b className="text-red-600">No alternative delivery options available</b>}
			{priceDifference && (
				<div className="flex flex-col gap-2">
					<span>Price difference: <b>{priceDifference}</b></span>
					<span>If you proceed with the change, <b>please ensure the order is either not submitted or cancelled.</b></span>
					<span>If there is a negative difference, please refund this amount to the patient, if situation permits.</span>
					<span><b>You will need to reprocess the order via Evermed Actions after changing the shipping method.</b></span>

				</div>
			)}	

			{handleSubmit && (
				<Button
					color='blue'
					disabled={!selectedItem}
					onClick={async () => {
						setFormLoading(true)
						await handleSubmit({ delivery: `EM_${selectedItem}` })
						setFormLoading(false)
					}}
					loading={formLoading}
				>
					Update
				</Button>
			)}
		</div>
	);
};

export default DeliverySelector;
