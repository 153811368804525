//	------------------------	------------------------	------------------------
//	Description: View to select clinician details in eRx clinician format
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { 	Input,
			Button,
            Icon,
            Accordion,
            Label,
            Checkbox,
            Dropdown
        } from 'semantic-ui-react'

import erx_model from '../../models/erx/erx_model'
import {ERX_FORMAT} from '../../models/erx/resources/erx_formats'
import {CLINICIAN_ENUM} from '../../models/erx/resources/erx_enums'
import debounce from 'xAppLib/libs/debounce'

import doc_model from 'models/doc_model'

import './style/prescribeditemselector.scss'

import * as j_f from '../../helpers/json_functions'


//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const clinician_props = erx_model.CLINICIAN_PROPS

const input_size = 'mini'

const clinician_defaults = {

}

const debounce_time = 250 //ms

const qualification_names = {
    'NSW':      'Prescriber specialist qualification',
    'NT':       'Prescriber specialist qualification',
    'WA':       'Prescriber specialist qualification',
    'TAS':      'Prescriber specialist qualification',
    'QLD':      'Prescriber qualification',
    'ACT':      'Prescriber qualification',
    'SA':       'Prescriber specialist qualification',
    'VIC':      'Prescriber specialist qualification',
}

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

class ClinicianSelector extends Component {

    constructor (props) {
        super(props)
        
        // Probably want to limit access here

        this.state = { clinician: clinician_defaults, clinician_raw: clinician_defaults }

        this.view_mode = !!props.viewOnlyMode

        this.debounce_update_clinician_context = debounce(this.update_clinician_context, debounce_time)

	}

	//	------------------------	------------------------	------------------------

	componentDidMount () {

       this.load_clinician_details(this.props.clinicianDetails)
    
	}

	//	------------------------	------------------------	------------------------

	componentDidUpdate(prevProps, prevState) {

    }
    
    //	------------------------	------------------------	------------------------

    render() {

        return (
            <React.Fragment>
                
                
                <Accordion  fluid>
                    
                    <Accordion.Title 
						onClick={ _=> this.setState({refine_clinician_open: !this.state.refine_clinician_open})}
                        index={0}
					>
                        <h4>{this.render_check_header()} Clinician: {this.clinician_summary()}</h4>

                    </Accordion.Title>

					<Accordion.Content active={this.props.open || this.state.refine_clinician_open} className='acc_cont'>

                        {this.render_clinician_modifier()}

                    </Accordion.Content>

                    {this.render_check_content()}

                </Accordion>

            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_check_content() {

        const { clinician_check } = this.props

        let practice_state = j_f.obj_val(this, ['props', 'script', 'clinician', 'PracticeState'])
        let qualification_name =  practice_state && qualification_names[practice_state] && `${qualification_names[practice_state]}` || '?'


        return (

            clinician_check?.res == 'err' && clinician_check.err?.map &&

            <React.Fragment>

                <Accordion.Title onClick={_ => this.setState({ err_open: !this.state.err_open })}>
                    <h4 className='txt_valid_error'><Icon name={this.state.err_open && 'triangle down' || 'triangle right'} />Clinician errors ({clinician_check.err.length})</h4>
                </Accordion.Title>

                <Accordion.Content active={this.state.err_open} className='acc_cont'>

                    {clinician_check.err.map((err, i) => {
                        return (
                            <React.Fragment key={`e_m_${i}`}>
                                <h4 className='txt_valid_invalid'><Icon name='exclamation' />{typeof err == 'string' && err.replace('Reserved 1',qualification_name) || JSON.stringify(err)}</h4>
                            </React.Fragment>
                        )
                    })}

                </Accordion.Content>

            </React.Fragment>

        )
    }

    //	------------------------	------------------------	------------------------

    render_check_header() {
        const { clinician_check } = this.props

        return (
            clinician_check?.res == 'ok' && <Icon className='txt_valid_valid' name='checkmark' /> ||
            clinician_check?.res == 'err' && <Icon className='txt_valid_invalid' name='exclamation' /> ||
            <Icon className='txt_valid_checking' name='checkmark' />
        )
    }

    //	------------------------	------------------------	------------------------

    render_clinician_modifier() {

        return (
            <React.Fragment>
                <div className='field_container'>
                    {clinician_props.map((prop, i) => {
                        if (prop.fe_show){

                            let inner = ''
                            let clinician_key = `f_k_${i}`

                            const clear_button = (
                                <Button
                                        size={input_size}
                                        icon
                                        basic
                                        color='grey'
                                        className='field_button'
                                        onClick={_=>{this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: null}}, this.post_clinician_change)}}
                                        content={<Icon name='delete' />}
                                    />
                            )

                            const revert_button = (
                                <Button
                                        size={input_size}
                                        icon
                                        basic
                                        color='green'
                                        className='field_button'
                                        content={<Icon name='undo' />}
                                        onClick={_=>{this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: this.state.clinician_raw[prop.name]}}, this.post_clinician_change)}}
                                    />
                            )

                            let allow_edit = true
                            if (typeof prop.fe_edit == 'function') allow_edit = prop.fe_edit(this.props.script || {})
                            else allow_edit = !!prop.fe_edit

                            // Conformance on presciber's qualification
                            let label_text = prop.label, practice_state = j_f.obj_val(this, ['props', 'script', 'clinician', 'PracticeState'])
                            if (prop.name == 'Clinician_Reserved_01' && practice_state && qualification_names[practice_state]) label_text = `${qualification_names[practice_state]} (${practice_state})`

                            const label = <Label className='field_label'>{label_text} {!((prop.nillable == true) || (prop.minOccurs === 0)) && '*' /*<Icon name='asterisk' />*/}</Label>

                            // String
                            if (prop.format == 'string' || prop.format == 'cons_num' || prop.format == 'safe_num' || prop.format == 'dva_num') inner = (
                                <div className='field_cls' key={clinician_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        value={ this.state.clinician && this.state.clinician[prop.name] || ''}
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        onChange={(e, d)=>{this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: (d.value == '') ? null : d.value}}, this.post_clinician_change)}}
                                    />
                                    {allow_edit && (this.state.clinician[prop.name] != this.state.clinician_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.clinician[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Boolean
                            else if (prop.format == 'boolean') inner = (
                                <div className='field_cls' key={clinician_key}>
                                    {label}
                                    <div className='field_input'>
                                    <Checkbox
                                        //size={input_size}
                                        className='field_check'
                                        checked={(typeof this.state.clinician[prop.name] == 'string' && this.state.clinician[prop.name] == 'true') || (typeof this.state.clinician[prop.name] == 'boolean' && this.state.clinician[prop.name]) || false}
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        label={(typeof this.state.clinician[prop.name] == 'undefined' || this.state.clinician[prop.name] == null) && 'Unfilled' || this.state.clinician[prop.name] && 'Yes' || 'No'}
                                        indeterminate={typeof (this.state.clinician && this.state.clinician[prop.name]) == 'undefined' || (this.state.clinician && this.state.clinician[prop.name] == null)}
                                        onChange={_=>{this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: !(this.state.clinician && this.state.clinician[prop.name])}}, this.post_clinician_change)}}
                                    />
                                    </div>
                                    {allow_edit && (this.state.clinician[prop.name] != this.state.clinician_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.clinician[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Number
                            else if (prop.format == 'number' || prop.format == 'unumber') inner = (
                                <div className='field_cls' key={clinician_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        value={this.state.clinician && this.state.clinician[prop.name] || ''}
                                        onChange={(e, d)=>{
                                            let new_num = Number(d.value)
                                            if (isNaN(new_num)) new_num = 0
                                            if (prop.format == 'unumber' && new_num < 0) new_num = 0
                                            this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: new_num}}, this.post_clinician_change)}
                                        }
                                    />
                                    {allow_edit && (this.state.clinician[prop.name] != this.state.clinician_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.clinician[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Numeric
                            else if (prop.format == 'numeric') inner = (
                                <div className='field_cls' key={clinician_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        value={this.state.clinician && this.state.clinician[prop.name] || ''}
                                        onChange={(e, d)=>{
                                            let new_num = d.value.replace(/[^0-9]/g, '')
                                            if (new_num == '') new_num = null
                                            this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: new_num}}, this.post_clinician_change)}
                                        }
                                    />
                                    {allow_edit && (this.state.clinician[prop.name] != this.state.clinician_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.clinician[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )
                            
                            // Check for enums
                            else if (ERX_FORMAT[prop.format] && ERX_FORMAT[prop.format].enums){
                                
                                // Build the list of enums for selection
                                const enum_list = []

                                Object.keys(ERX_FORMAT[prop.format].enums).forEach((k, j) => {
                                    enum_list.push({key: `drop_${i}_${j}`, text: `${k} (${ERX_FORMAT[prop.format].enums[k]})`, value: ERX_FORMAT[prop.format].enums[k]})
                                })

                                inner = (
                                    <div className='field_cls' key={clinician_key}>
                                        {label}
                                        <div className='field_input'>
                                        <Dropdown
                                            selection
                                            fluid
                                            size={input_size}
                                            placeholder={prop.label}
                                            disabled={!allow_edit || this.view_mode}
                                            icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                            options={enum_list}
                                            value={this.state.clinician && this.state.clinician[prop.name] || ''}
                                            onChange={(e, d)=>{this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: d.value}}, this.post_clinician_change)}}
                                        />
                                        </div>
                                        {allow_edit && (this.state.clinician[prop.name] != this.state.clinician_raw[prop.name]) && !this.view_mode && revert_button}
                                        {allow_edit && (this.state.clinician[prop.name] != undefined) && !this.view_mode && clear_button}
                                    </div>
                                )

                            }

                            // Date
                            else if (prop.format == 'xml_date') inner = (
                                <div className='field_cls' key={clinician_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        type={'date'}
                                        //maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        value={this.state.clinician && this.state.clinician[prop.name] || ''}
                                        onChange={(e, d)=>{this.setState({clinician: {...(this.state.clinician && this.state.clinician), [prop.name]: d.value}}, this.post_clinician_change)}}
                                    />
                                    {allow_edit && (this.state.clinician[prop.name] != this.state.clinician_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.clinician[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Unknown
                            else {
                                inner = (
                                    <div key={clinician_key}>
                                        {label}
                                        {`Unknown - ${prop.label} (${prop.format})`}
                                    </div>
                                )
                            }

                            return inner
                            
                        }
                    })}
                </div>
                <br/>
                {!this.view_mode &&
                    <React.Fragment>
                        <Button
                            size={input_size}
                            icon
                            basic
                            label='Clear all'
                            color='red'
                            className='field_button'
                            onClick={_ => {
                                const clinician = this.state.clinician
                                clinician_props.forEach(prop => {
                                    let allow_edit = true
                                    if (typeof prop.fe_edit == 'function') allow_edit = prop.fe_edit(this.props?.script || {})
                                    else allow_edit = !!prop.fe_edit
                                    if (allow_edit) clinician[prop.name] = null
                                    //if (prop.fe_edit) clinician[prop.name] = null
                                })
                                this.setState({ clinician }, this.post_clinician_change)
                            }}
                            content={<Icon name='delete' />}
                            floated='right'
                        />
                        <Button
                            size={input_size}
                            icon
                            basic
                            label='Revert all'
                            color='green'
                            className='field_button'
                            content={<Icon name='undo' />}
                            onClick={_ => { this.setState({ clinician: JSON.parse(JSON.stringify(this.state.clinician_raw)) }, this.post_clinician_change) }}
                            floated='right'
                        />
                        <br /><br />
                    </React.Fragment>}
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    clinician_summary() {

        const clinician =   (this.state.clinician['DoctorFirstName'] && (this.state.clinician['DoctorFirstName'] + ' ') || '? ') +
                            (this.state.clinician['DoctorFamilyName'] && (this.state.clinician['DoctorFamilyName'] + ' ') || '? ') + 
                            (this.state.clinician['DoctorProviderNumber'] && ('(' + this.state.clinician['DoctorProviderNumber']) || '(?') + 
                            (this.state.clinician['DoctorPrescriberNumber'] && ('/' + this.state.clinician['DoctorPrescriberNumber'] + ')') || '/?)')

        return clinician

    }

    //	------------------------	------------------------	------------------------


    post_clinician_change() {

        // Any custom functions to check data should go in here

        // Pass the update to context
        this.props.onUpdate?.(this.state.clinician)

    }

    //	------------------------	------------------------	------------------------

    async load_clinician_details(data) {

        if (data) return await this.load_clinician_details_from_data(data)
        else return await this.load_clinician_details_from_app()

    }

    //	------------------------	------------------------	------------------------

    async load_clinician_details_from_data(data) {

        const new_clinician = {}

        // Load the data for each clinician prop
        clinician_props.forEach(prop => {
            if (typeof data[prop.name] != 'undefined') new_clinician[prop.name] = data[prop.name]
        })

        this.setState({ clinician: JSON.parse(JSON.stringify(new_clinician)), clinician_raw: JSON.parse(JSON.stringify(new_clinician)) }, _ => { this.post_clinician_change() })//this.post_clinician_change.bind(this))

    }

    //	------------------------	------------------------	------------------------

    async load_clinician_details_from_app() {

        const new_clinician = {}

        // Load the details from the app.user
        const user_details = await this.load_app_user_detail()

        // Load the data for each clinician prop
        clinician_props.forEach(prop => {
            if (typeof user_details[prop.name] != 'undefined') new_clinician[prop.name] = user_details[prop.name]
        })

        // Note - this function is called during init so no setstate availible
        this.setState({ clinician: JSON.parse(JSON.stringify(new_clinician)), clinician_raw: JSON.parse(JSON.stringify(new_clinician)) }, _ => { this.post_clinician_change() })//this.post_clinician_change())//this.post_clinician_change.bind(this))

    }

    //	------------------------	------------------------	------------------------

    async load_app_user_detail() {

        const data = {}

        if (app && app.user){

            const doc_id = app.user.prof && app.user.prof.claims && app.user.prof.claims.doc_id

            if (doc_id){

                const docs = await doc_model.get_record()

                if (docs){

                    const c_doc = docs[doc_id]
                    
                    // Get the doc info
                    data['DoctorPrescriberNumber'] = c_doc.prescriber
                    data['DoctorFirstName'] = c_doc.firstname
                    data['DoctorFamilyName'] = c_doc.familyname
                    data['DoctorProviderNumber'] = c_doc.provider
                    data['DoctorMobileNumber'] = c_doc.pub_phone || c_doc.phone
                    data['DoctorEmail'] = c_doc.email
                    data['PracticeName'] = c_doc.practicename
                    data['PracticeAddress1'] = c_doc.address1
                    data['PracticeAddress2'] = c_doc.address2
                    data['PracticeSuburb'] = c_doc.suburb
                    data['PracticePostcode'] = c_doc.postcode
                    data['PracticeState'] = c_doc.state
                    data['PracticeEmail'] = c_doc.practiceemail
                    data['DoctorPhoneNumber'] = c_doc.pub_phone || c_doc.phone
                    data['DoctorFaxNumber'] = c_doc.fax
                    data['PrescribingSystemUsed'] = 'InstantScripts'
                    data['PrescriberType'] = c_doc.prescribertype
                    data['PrescriberHPIO'] = c_doc.hpio
                    data['PrescriberHPII'] = c_doc.hpii
                    data['PrescriberAHPRANumber'] = c_doc.ahpra
                    data['Clinician_Reserved_01'] = c_doc.qualification

                }

            }

        }

        return data

    }

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default ClinicianSelector
