import db_lib from 'xAppLib/libs/db_lib';
import React from 'react'
import { Icon } from 'semantic-ui-react';
import API_service from 'xAppLib/providers/API_service'
import CopyField from 'xAppLib/UIelems/CopyField'
import Age from '../views/patients/Age';

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------


const _SRCH_PTS_FIELDS = [

						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'First',
									jpath: 'spd_data.first_name'
								},
								{
									name: 'Last',
									jpath: 'spd_data.last_name'
								},
								{
									name: 'DOB',
									jpath: 'spd_data.dob'
								},
								{
									name: 'Sex',
									jpath: 'spd_data.sex',
								},
								{
									name: 'Medicare',
									jpath: 'spd_data.medicare'
								},
								{
									name: 'Email',
									jpath: 'spd_data.email',
									type: 'show_more'
								},
								{
									name: 'Mobile',
									jpath: 'spd_data.mobile',
									// type: 'show_more'
								},
								{
									name: 'UR',
									jpath: 'ur',
									// type: 'show_more'
								},
							],
							template : ({First,Last,DOB,Sex,Medicare,Email, Mobile, UR})=> <React.Fragment>
												{First} {Last}
												<br/>DOB: <Age dob={DOB} />
												{Sex && <><br/>Sex: <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} /></>}
												{Medicare && <React.Fragment><br/>Medicare: {Medicare}</React.Fragment>}
												<br/>{Email}
												<br/>{Mobile}
												<br/><CopyField val={UR} />
											</React.Fragment>
						},

						// {
						// 	name: 'Pts?',
						// 	jpath: 'pts_uid',
						// 	type: 'bool',
						// },

						{
							name: 'Scripts num',
							jpath: 'tot',
						},

						{
							name: 'Scripts Dates',
							type: 'compound',
							parts: [
								{
									name: 'dt_fro',
									jpath: 'dt_fro',
								},								
								{
									name: 'dt_to',
									jpath: 'dt_to',
								},								
							],
							template : ({dt_fro, dt_to})=> <React.Fragment>
											from: {dt_fro}
											<br />
											to: {dt_to}
										</React.Fragment>
						},

					];

// 		--------------------------------		--------------------------------		---------

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class patients_model extends db_lib {

	static get SRCH_PTS_FIELDS() { return _SRCH_PTS_FIELDS; }


	/*static get DET_FIELDS_PHARM_STATS() { return _DET_FIELDS_PHARM_STATS; }
	static get DET_FIELDS_NURSE_STATS() { return _DET_FIELDS_NURSE_STATS; }
	static get DET_FIELDS_MANG_STATS() { return _DET_FIELDS_MANG_STATS; }
	static get DET_FIELDS_DOC_STATS() { return _DET_FIELDS_DOC_STATS; }
	static get DET_FIELDS_ADMIN_STATS() { return _DET_FIELDS_ADMIN_STATS; }
	static get DET_FIELDS_ROOT_STATS() { return _DET_FIELDS_ROOT_STATS; }*/
	// static get DET_FIELDS_COSM() { return _DET_FIELDS_COSM; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

	// static get SCRIPT_PATIENT_FIELDS() { return _SCRIPT_PATIENT_FIELDS; }

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static set_db(c) {
		patients_model.alt_db = c || false;
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async patients_search_list( params, ca ) {

		return await API_service.load_data(
										'patients/search',
										params,
										ca
									)
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async get_pts( params, ca ) {

		return await API_service.load_data(
										'patient/load',
										params,
										ca
									)
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

}
