import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Icon, Message} from "semantic-ui-react";

import {useUser, useUserPrefs} from "../../xAppLib/Hooks";
import user_model from "../../models/user_model";
import instcons_model from "../../models/instcons_model";
import app from "ampersand-app";
import {useCurrent} from "../../xAppLib/Hooks/useCurrent";
import {omit_assigned_to_other_doctor,  prioritorise_waiting_room} from "./util";
import logger from "../../xAppLib/libs/logger";

const TOAST_ID = 'InstConsWrNotifier';
const CONTAINER_ID = 'actionable';

function redirect(record) {
    app.history.push(instcons_model.consult_route(record));
}

function InstConsWrNotifierToast({waitingRoom, inConsult}) {
    const firstInQueue = inConsult[0] || waitingRoom[0];
    const isInConsult = inConsult.length > 0;
    const wrTotal = waitingRoom.length;
    const wrTotalWas = useRef(0);
    useEffect(() => {
        if (!isInConsult && wrTotal > wrTotalWas.current && !app.settings.is_local) {
            try {
                new Audio('https://storage.googleapis.com/instant-med-public/sounds/start-vid.mp3').play().catch(() => {
                    // ignore
                });
            } catch (e) {
                // no sound
            }
        }
        wrTotalWas.current = wrTotal;
    }, [wrTotal, isInConsult]);

    const [loading, setLoading] = useState(false);

    const takePatient = useCallback(async () => {
        const {key: ic_wr_key, sid} = firstInQueue;

        if (isInConsult) {
            redirect(firstInQueue);
        } else {
            setLoading(true);
            try {
                if (instcons_model.is_treatment_plan(firstInQueue)) {
                    await instcons_model.take_treatment_plan(firstInQueue);
                } else {
                    logger.usg_log('InstConsWrNotifierToast', 'take', { ic_wr_key, sid });
                    await instcons_model.update_status(ic_wr_key, sid, 'instcons_taken');
                    // Try update other records to taken, but if it fails, dr can handle on the consult screen
                    Promise.all((firstInQueue.others || []).map(
                        other => instcons_model.update_status(other.key, other.sid, 'instcons_taken').catch(e => {
                            console.warn(`InstConsWrNotifierToast :: takePatient :: other(${other.key})`, String(e));
                        })
                    )).then(() => {
                        // ignore
                    });
                }
                redirect(firstInQueue);
            } catch (e) {
                alert("Consult already taken by another doctor");
            } finally {
                setLoading(false);
            }
        }
    }, [isInConsult, firstInQueue]);

    return (
        <div className="flex flex-col">
            <strong>
                InstCons cosm WR {wrTotal > 0 && (<>
                    (<Link to={`/instcons_wr/cosm`} className="text-blue-500 underline">{wrTotal} waiting</Link>)
                </>
            )}
            </strong>

            {!firstInQueue
                ? <>Waiting room empty</>
                : <>
                    <Message className="flex flex-col">
                        <small>{isInConsult ? 'Current' : 'Next'} Consult</small>
                        {firstInQueue?.org_data?.name && <strong>{firstInQueue?.org_data?.name}</strong>}
                        {firstInQueue?.auth?.displayName && <span>{firstInQueue?.auth?.displayName}</span>}
                    </Message>

                    <Button fluid icon labelPosition="left" size="mini" color="purple"
                            onClick={takePatient} loading={loading} disabled={loading}>
                        <Icon name="video"/> {isInConsult ? 'Go to consult' : 'Take patient'}
                    </Button>
                </>}
        </div>
    )
}

function MonitorWaitingRoom() {
    const [waitingRoom, setWaitingRoom] = useState([]);
    const [inConsult, setInConsult] = useState([]);

    useEffect(() => {
        const unwatch = instcons_model.watch_incomplete(obj => {
            const data_with_key = Object.keys(obj || {}).map(key => ({...obj[key], key}));
            const sorted = omit_assigned_to_other_doctor(prioritorise_waiting_room(data_with_key));
            setWaitingRoom(sorted.filter(r => r.status === 'instcons_await'));
            setInConsult(sorted.filter(r => r.status === 'instcons_taken'));
        });

        return () => {
            unwatch();
            toast.clearWaitingQueue({containerId: CONTAINER_ID});
            toast.dismiss(TOAST_ID);
        };
    }, []);

    // This is a bit dirty, but as mentioned below the records will update every time *any* change happens, which
    // includes logging when video sessions start and end for example. We _don't_ want some arbitrary log/history
    // updates to cause the notification to reappear. So instead just make a list of the id+status and if _that_
    // changes we know to update/open the toast.
    // The trigger is a string otherwise a new array will trigger it to reopen.
    const records = useCurrent({waitingRoom, inConsult});
    const trigger = JSON.stringify([...waitingRoom, ...inConsult].map(x => [x.key, x.status]));
    useEffect(() => {
        // The firebase records can update multiple times in rapid succession (also because we have two watches
        // going), so set a short delay before rendering the actual content to avoid flicker and multiple alert
        // sounds being played. Not a great solution, but it works ok
        const id = setTimeout(() => {
            const isEmpty = inConsult.length === 0 && waitingRoom.length === 0;

            const content = <InstConsWrNotifierToast {...records.current} />;

            const config = {
                containerId: CONTAINER_ID,
                toastId: TOAST_ID,
                autoClose: isEmpty ? 1000 : false,
                pauseOnHover: false,
                pauseOnFocusLoss: false,
            };

            if (toast.isActive(TOAST_ID)) {
                toast.update(TOAST_ID, {...config, render: content});
            } else if (!isEmpty) {
                toast(content, config);
            }
        }, 500);

        return () => {
            clearTimeout(id);
        };
    }, [trigger]);

    return null;
}

export function InstConsWrNotifier() {
    const user = useUser();
    const [prefs] = useUserPrefs();
    const hasAccess = useMemo(() => user_model.check_access('doc'), [user]);
    const [visible, setVisibility] = useState(true);

    useEffect(() => {
        function handleToggle(val = true) {
            setVisibility(!!val)
        }

        app.on(app.events.SET_INSTCONS_WR_NOTIFICATION_VISIBILITY, handleToggle);

        return () => {
            app.off(app.events.SET_INSTCONS_WR_NOTIFICATION_VISIBILITY, handleToggle);
        };
    }, [])

    if (hasAccess && visible && !!prefs.instconst_wr_toast) {
        return <MonitorWaitingRoom />;
    }

    return null;
}