import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import cat_model from 'models/cat_model';
import med_model from 'models/med_model';
import patho_model from 'models/patho_model';
import org_model from 'models/org_model';
import { useProfile } from 'xAppLib/Hooks';
import { calculate_types } from './utils';
import logger from 'xAppLib/libs/logger';
import { omitHidden, withCustomQuestions } from "./questionnaire";

const HOME_DELIVERY_ENABLED = true

const MedData = ({alt_cat, children, med_code, req_type : prop_req_type, script_type : prop_script_type, medbuy_only, free_consult, allow_set_usr, data}) => {
    const params = useParams()
    const [med,setMed] = useState(null)
    const [med_error,setMedError] = useState(null)
    const [patho_request,setPathoRequest] = useState(null)
    med_code ??= params.med_code
    medbuy_only ??= app.runtime.medbuy_only || med?.xtra?.medbuy_only
    free_consult ??= app.runtime.free_consult
    allow_set_usr ??= app.runtime.allow_set_usr
    const cat_nm = alt_cat?.replace(/^_/, '') || params.cat_nm || 'pharm';
    const cat_route = params.cat_route
    const sid = params.sid 

    let med_mb = app.app_data.ws_data?.cat_data?.['pharm']?.meds_mb_list?.[med?.m] || {}
    
    const set_data = useCallback(async  (d) => {

		// console.log('set_data', d, JSON.stringify(d.q['-LOIwYxLXa2mghaGtH2j']));
		// console.log('set_data', app.dvc.org.conf.extra_spec_Qs);
      
			if (!d) {
				setMedError(true)
				logger.report_error('ERROR Cannot load med', 'error', {med_code, cat_nm, cat_route});
				return
			}
            d.key = med_code
            if (d.items) {
                d = {...d,items: await med_model.items(d)}
            }
			if (!d.q) {
				// we now have consults with no questionnaire... but we'll still have to inject some common ones here
				d = {...d, q: {}}
			}

            //TODO from cart code, tidy up
            med_mb = app.app_data.ws_data?.cat_data?.['pharm']?.meds_mb_list?.[d.m] || {}
            d.can_delivery = cat_model.can_deliver(med_mb);
                                
        	const PARTN_PHARM = app.runtime.partn_pharm_oid || false

            const home_delivery_available = app.acl.app_use 
                                            && (!PARTN_PHARM || org_model.org_conf_itm(app.runtime.org, 'is_mb_disp')) 
                                            && HOME_DELIVERY_ENABLED 
                                            && d.can_delivery 
                                            && org_model.org_conf_itm(app.runtime.org, 'is_mb') 
                                            && !app.runtime.phapick_only 
                                            && !org_model.org_conf_itm(app.runtime.org, 'phapick_only')

            const [req_type, script_type] = calculate_types({values:{}, med_code, med_data:d, cat_nm, cat_route, prop_req_type, prop_script_type, PARTN_PHARM, home_delivery_available, medbuy_only })
            // const [req_type, script_type] = calculate_types({values:{}, med_code, med_data:d, medbuy_only, cat_nm, cat_route, prop_req_type,prop_script_type, PARTN_PHARM})
    
            if (script_type=='pathoreq') {
                setPathoRequest(await patho_model.request(d))
            }

			d.q = omitHidden({
				...withCustomQuestions(d.q, {
					// cosmVaccine: ["-LOIw2bbLtoWgsSjvbks", "-LOIycusPtvIVFCbWUQc"].includes(cat_route),
					k10: d?.xtra?.use_k10,
					extra: script_type !== 'pthl-needlestick' && app.dvc.org && org_model.org_conf_arr(app.dvc.org, 'extra_spec_Qs'),
					inAustralia: req_type !== 'cosm',
					understand: !['doccons', 'subcons'].includes(script_type) && req_type != 'paas',
				}),
			});

            setMed(d)


    },[med_code, medbuy_only, cat_nm, cat_route, prop_req_type,prop_script_type])

    useEffect(()=>{
		setMed(null)
        return med_code!='prescription' ? med_model.watch_med(med_code, set_data ) : setMed(app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_tree_list?.[cat_route])
    },[med_code])
    


    return children?.({ 
        med_data:med || med_code === 'prescription' && {n: 'Online Prescription'}, 
        med_mb, 
        loading:!med && !med_error && med_code !== 'prescription', med_code, 
        cat_nm, cat_route, 
        prop_req_type, prop_script_type, 
        medbuy_only,
        free_consult,
        patho_request,
        allow_set_usr,
        dataProp : data,
        sid,
		med_error
    })
}
 
export default MedData;