import { useState, useCallback, useContext, useEffect } from "react";
import { useHistory } from "xAppLib/Hooks";
import { useUser } from "xAppLib/Hooks";


export function usePrepStatus() {
    const [history] = useHistory()
    const user = useUser()

	const most_recent_patho_req = history.find(s => s.med_db_data.m == '-MdM99oAFZEtgGgABh2n')
	const has_presc = app.user?.prof?.pts_prescs?.filter(p => p.type == 'pre_prep').length > 0
	const medbuy_after_last_patho = history.slice(0, history.indexOf(most_recent_patho_req)).filter(s => s.script_type === 'medbuy' && s.req_type === 'hivprevent');

	// This is a bit convoluted, but we want to treat patho results as stale if
	// they have PrEP purchases _after_ the patho results and there's no longer
	// an active presc. This indicates a presc has been given and either all
	// repeats used or the presc has expired, In either case, we need a new
	// pathology request.
	const is_stale = medbuy_after_last_patho.length > 0 && !has_presc;
	const patho_req = !is_stale && most_recent_patho_req;

	// TODO complete this. Check order of scripts, check patho is negative, etc..
	const has_patho = !!patho_req; 
	const has_results = patho_req?.status=="patho_res"; 
    const user_in = user.user_in
	const step = [user_in,has_patho,has_results,has_presc].filter(Boolean).length + 1


    return {
        user_in,
        patho_req,
        has_patho,
        has_results,
        has_presc,
        step,
		can_proceed: app.settings.iprep_avail || step > 2
    }
}
