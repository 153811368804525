//	------------------------	------------------------	------------------------
//	Description: Enums specified by erx for fields in their scripts
//
//  Refer to eRx schema definitions
//	------------------------	------------------------	------------------------

// eslint-disable-next-line no-undef
module.exports = {

    ERX_ENUM: {

        STATE: {
            "Victoria": "VIC",
            "New South Wales": "NSW",
            "Australian Capital Territory": "ACT",
            "Queensland": "QLD",
            "Northern Territory": "NT",
            "Western Australia": "WA",
            "South Australia": "SA",
            "Tasmania": "TAS"
        },

        ITEM_STATE: {
            "Active": "Active",
            "Pending": "Pending",
            "Dispensed": "Dispensed",
            "Deferred": "Deferred",
            "Cancelled": "Cancelled",
            "Reactivated": "Reactivated",
            "Ceased": "Ceased"
        }

    },

    //	------------------------	------------------------	------------------------

    PATIENT_ENUM: {

        DVA_CARD: {
            "Gold": "G",
            "White": "W",
            "Orange": "O"
        },

        SEX: {
            "Male": "M",
            "Female": "F",
            "Unknown": "U",
        },

    },

    //	------------------------	------------------------	------------------------

    CLINICIAN_ENUM: {

        PRESCRIBER_TYPE: {
            "Medical Practitioner": "M",
            "Eye/Optometrist": "E",
            "nUrse": "U",
            "midwiFe": "F",
            "Dentist": "D",
            "Veterinarian": "V",
            "PodiatrisT": "T",
        },

    },

    //	------------------------	------------------------	------------------------

    PRESCRIBED_ENUM: {

        GENERIC_INTENTION: {
            "Brand": "B",
            "Generic": "G"
        },

        SCRIPT_CTG_ANNOTATION: {
            "Handwritten": "CTG00B",
        },

        SCHEDULE_NUMBER: {
            "This Schedule is intentionally blank": "1",
            "Pharmacy Medicine": "2",
            "Pharmacist Only Medicine": "3",
            "Prescription Only Medicine OR Prescription Animal Remedy": "4",
            "Caution": "5",
            "Poison": "6",
            "Dangerous Poison": "7",
            "Controlled Drug": "8",
            "Prohibited Substance": "9",
            "Substances of such danger to health as to warrant prohibition of sale, supply and use": "10",
            "Schedule 4 Appendix B drugs - Special restricted substances":"4B",
            "Schedule 4 Appendix D drugs - Prescribed restricted substances":"4D",
            //"Exempt from scheduling": "EFS",
            //"Not scheduled after consideration by Commitee": "S10",
            //"Substances of such danger to health as to warrant prohibition of sale, supply and use": "S10",
        }

    }

}