import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import instcons_global_model from "../../models/instcons_global_model";

export default function NeedReviewBanner() {
	const [scripts, setScripts] = useState([]);
	useEffect(() => {
		return instcons_global_model.watch_need_review(recs => setScripts(Object.values(recs || {})));
	}, []);

	const count = scripts.length;
	if (count === 0) return null;

	return (
		<Link to="/scripts/list/need_review">
			<Message
				warning
				icon="map signs"
				header={count > 1
					? `${count} scripts requiring manual review`
					: `${count} script requiring manual review`
				}
			/>
		</Link>
	)
}
 
