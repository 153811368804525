import React from "react";
import { Helmet } from "react-helmet";
import RequestButton from "./components/RequestButton";
import CardGroup from "./components/CardGroup";
import NewLine from "./components/NewLine";
import Proofs from "./components/Proofs";
import Process from "./components/Process";
import { Container } from "semantic-ui-react";

const process = [
  {
    link: "#",
    image: "/ileg/images/process1.png",
    title: "Step One",
    text: "Create or sign into your My Legal Doc account.",
  },
  {
    link: "#",
    image: "/ileg/images/process2.png",
    title: "Step Two",
    text: "Register your client for a priority medical consultation.",
  },
  {
    link: "#",
    image: "/ileg/images/process3.png",
    title: "Step Three",
    text: "A ‘My Legal Doc’ doctor will complete the medical consultation and send through the required medical documentation to the client and the legal team.",
  },
  {
    link: "#",
    image: "/ileg/images/process4.png",
    title: "Step Four",
    text: "Legal teams can request an additional client consultation or a lawyer/doctor consultation if required.",
  },
];

const Homepage = () => {
  return (
    <div className="ileg home bg-gray-100">
      <Helmet>
        <title>Home</title>
      </Helmet>

      {/* Header ‘request a demo’ */}
      <section className="bg-white hero">
        <Container>
          <div className="grid md:grid-cols-2">
            <div className="my-2 md:pr-8 flex flex-col justify-center lg:py-5 text-center md:text-left md:pl-30">
              <h1 className="hero lg:pt-6">
                A premium Healthcare concierge for all your clients’ medical case management
              </h1>
              <h2 className="text-imperial text-center md:text-left">
                Powered by InstantScripts
              </h2>
              <div className="md:text-left mt-4 mb-8">
                <RequestButton />
              </div>
            </div>
        
            <div className="relative h-64 sm:h-72 md:h-96">
              <img
                className="absolute inset-0 w-full h-full object-cover object-center rounded md:rounded-lg md:mt-2 lg:mt-10"
                src="/ileg/images/home1.jpg"
                alt="My Legal Doc"
              />
            </div>
          </div>
        </Container>
      </section>

      {/* Why MLD Value */}
      <section className="bg-white md:pb-10">
        <Container>
          <CardGroup />
        </Container>
      </section>

      {/* My legal doc */}
      <section className="bg-majestic">
      <Container>
        <div className=" py-3 grid md:grid-cols-5">
          <div className="my-2 px-4 text-center md:space-y-4 md:col-span-3 md:text-left 2xl:space-y-6">
            <div className="md:hidden relative">
              <img
                className="rounded-lg mx-auto w-1/2 h-1/2"
                src="/ileg/images/home2.png"
                alt="home image 2"
              />
            </div>
            <img
              className="h-10 mx-auto mt-4 md:mx-0 md:mt-0"
              src="/ileg/images/ileg-white.svg"
              alt="logo white"
            />
            <p className="text-slate xl:text-xl lg:text-lg md:text-md">
              My Legal Doc, powered by InstantScripts is an online medical concierge that provides premium patient consultations on behalf of legal case management teams. 
              Legal teams can easily register and refer their patients to My Legal Doc.
              <br />
              <br />
              Our doctors will contact the patient within 15 minutes of request, complete a medical consultation and provide the relevant referrals and assessments necessary to facilitate case management.
            </p>
          </div>
          <div className="text-center md:col-span-2 md:mb-4">
            <div className="hidden md:block px-16 py-6">
              <img
                className="mx-auto rounded-lg"
                src="/ileg/images/home2.png"
                alt="home image 2"
              />
            </div>
            <RequestButton />
          </div>
        </div>
        </Container>
      </section>

      <section className="bg-white">
      <Container>
        <div className="md:space-y-4 md:pb-4">
          {/* The Process */}
          <h1 className="px-5 py-8 blue text-center">The Process</h1>
          <Process process={process} titleStyle="md:text-xl"/>
        </div>
        </Container>
      </section>

      {/* Get Started */}
      <section className="bg-majestic md:py-20">
      <Container>
        <div className="grid md:grid-cols-5 py-4 md:pb-4">
          <div className="my-2 px-4 text-center md:space-y-8 md:col-span-3 md:text-left">
            <div className="md:hidden relative">
              <img
                className="rounded-lg mx-auto w-1/2 h-1/2"
                src="/ileg/images/home2.png"
                alt="home image 2"
              />
            </div>
            <h1 className="text-slate">Get started with My Legal Doc</h1>
            <p className="text-slate xl:text-xl lg:text-lg md:text-md">
              It's easy to get started with My Legal Doc. Simply request a demo
              below and we'll get in touch and organise a platform demonstration
              to you and your team.
            </p>
            <div className="text-center">
              <RequestButton />
            </div>
          </div>
          <div className="hidden relative md:block md:col-span-2">
            <img
              className="absolute inset-0 w-3/4 h-3/4 m-auto object-cover object-center rounded-lg"
              src="/ileg/images/home2.png"
              alt="home image 2"
            />
          </div>
        </div>
        </Container>
      </section>

      <section className="bg-white md:py-32">
        <Container>
        <div className="md:space-y-4">
          {/* Proof points */}
          <Proofs />
        </div>
        </Container>
      </section>
    </div>
  );
};

export default Homepage;
