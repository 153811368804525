/**
 * * cls
 * flattens class name params to string
 * arg can be a string, a nested array or an object of { className: boolean } values
 * eg. cls("base",error&&"error") => "base error"
 * eg. cls("base",{error:true,something:false, large:true}) => "base error large"
 */
export function cls(...args) {
    return args.filter(Boolean).reduce((out,v)=>{
        if (Array.isArray(v))
            out.push(cls(...v))
        else if (typeof v == 'string')
            out.push(v)
        else 
            out.push(Object.keys(v).map(k=>v[k]&&cls(k)).filter(Boolean).join( ' '))
        return out
    },[]).join(' ')

}

cls.opt = (prop,val) => prop && val

export function col(col,def, cols) {
    return cols[col] || cols[def]

}