import React, { useState, useEffect } from "react";
import SectionHeader from 'views/med/SectionHeader';
import script_model from "models/script_model";
import { Form } from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";

const DocScr = (props) => {
	const { med_data, script_type, req_type, Section } = props

	return (
		<UniFormMed
			{...props}
			section="docscr"
			fields={script_model.COMM_DCNS_FIELDS}
		>
			{(values, valids, uf_this, fields) => {
				const args = {values, valids, uf_this}

				return <Section>
						<Section.Header>
							Prescription Request Notes
						</Section.Header>

						<Section.Content>
							<UniFieldSUI fl={{...fields.cons_desc, label:'Note / description'}} {...args} />

							<UniFieldSUI fl={{...fields.cons_allergy, label:'Patient allergies'}} {...args} />
						</Section.Content>

					</Section>;
				}}
		</UniFormMed>
	);
};

export default DocScr;

