import React, {useMemo} from "react";
import { Form, Checkbox } from "semantic-ui-react";
import script_model from "models/script_model";
import UniFormMed from "views/med/UniFormMed";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";

const TermPtsDets = (props) => {
	const { store, enabled, med_data, script_type, req_type, onUpdate, Section } = props

	const { no_medicare = false } = store

	const fields = useMemo(() => {
		if (!enabled)
			return [];

		return script_model.COMM_USR_FIELDS.map(_f => {
			const f = {..._f}
			if (f.name == 'medicare' && app.acl.app_use) {
				f.valid_not_required = !!no_medicare 
				f.disabled = !!no_medicare
			} else if (f.name == 'medicare' && (script_type=='doccons' && req_type == 'cosm' || script_type=='pharm' || script_model.is_bulk_billed(script_type, req_type))) {
				f.valid_not_required = false
			}
			if (f.name == 'address') {
				if (req_type === 'cosm') {
					f.type = 'address';
					f.disable_profile = true;
				}
				f.valid_not_required = /*script_type=='cosm' || */['medbuy','exprbuy','qualbuy'].includes(script_type)
			}
			if (f.name == 'full_address') {
				f.valid_not_required = /*script_type=='cosm' || */['medbuy','exprbuy','qualbuy'].includes(script_type)
			}
			return f
		})
	}, [med_data, no_medicare, script_type, req_type]);


	return (
		<UniFormMed 
			{...props} 
			section="ptsdets"
			fields={fields}
		>
			{(values, valids, uf_this, fields) => {
				const args = { values, valids, uf_this };
				return (
					<Section>
						<Section.Header>Patient Details</Section.Header>

						<Section.Content>
							<Form.Group widths="equal">
								<UniFieldSUI fl={fields.first_name} {...args} />
								<UniFieldSUI fl={fields.last_name} {...args} />
							</Form.Group>

							<Form.Group widths="equal">
								<UniFieldSUI fl={fields.dob} {...args} />
								<UniFieldSUI fl={fields.sex} {...args} />
							</Form.Group>

							<Form.Group widths="equal">
								<UniFieldSUI fl={fields.email} {...args} />
							</Form.Group>

							<Form.Group widths="equal">
								<UniFieldSUI fl={fields.atsi} {...args} />
							</Form.Group>

							<UniFieldSUI fl={fields.medicare} {...args} />

							{app.acl.app_use && !app.acl.is_spon && (
								<React.Fragment>
									<Form.Field
										label="do not have a Medicare card"
										control={Checkbox}
										name="no_medicare"
										checked={!!no_medicare}
										onChange={(e,{checked}) => {
											onUpdate({no_medicare:checked})
										}}
									/>
									{/*no_medicare && (script_type=='medbuy' || this.state.home_delivery) && <p><em>Note: Medication price is ${currency(med_data.medbuy_nomedicare_price).format()} without a valid Medicare card</em></p>*/}
								</React.Fragment>
							)}

							<UniFieldSUI fl={fields.conc_card} {...args} />

							<UniFieldSUI fl={fields.mobile} {...args} />

							{['medbuy', 'exprbuy', 'qualbuy'].includes(script_type) ? (
								<UniFieldSUI fl={fields.conc_card} {...args} />
							) : (
								<UniFieldSUI
									fl={fields.address}
									{...args}
									show_val={true}
								/>
							)}

						</Section.Content>
					</Section>
				);
			}}
		</UniFormMed>
	);
};

export default TermPtsDets;
