import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from 'semantic-ui-react';
import IcosmHero from './components/IcosmHero';
import IcosmBanner from './components/IcosmBanner';
import IcosmAppBanner from './components/IcosmAppBanner';
import IcosmInfo from './components/IcosmInfo';
	
const img_path = "https://storage.googleapis.com/icosm/";

const Compliance = () => {

	return <div>

		<Helmet>
			<title>Compliance & Safety</title>
		</Helmet>

       <Container>
            <IcosmHero img='2/iStock-1389992890_2.jpg' title="Manage Compliance in your clinic with InstantCosmetics" desc="Stay compliant with InstantCosmetics, knowing our exceptional doctors, online drug book and booking system. Work together to keep your clinic on track and compliant." link="" order="2" />

            <IcosmInfo img='2/iStock-1272479912.jpg' title="Support. Compliance. Software." desc="Need support? We are only a message away. Our dedicated support team offer fast and precise feedback. Whilst our experienced doctors offer medical oversight." />

            <IcosmInfo img='2/iStock-1352271025_2.jpg'  title="Know we have your back" desc="We seek advice regularly from legal and National bodies to ensure our nurses uphold AHPRA & TGA industry standards." reverse />
        </Container>

        <IcosmBanner light img='2/iStock-1170104852.jpg' title="Get started today!" desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." />
        
	</div>

}

export default Compliance;
