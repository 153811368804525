import { useState, useEffect } from 'react';
import firebase_database from '../providers/firebase_database';

export const useWatchRecord = (loc, dependencies = []) => {
	const [value, setValue] = useState();

	useEffect(() => {
		return firebase_database.watch_record(loc, setValue);
	}, dependencies);

	return value;
};
