import React, {Component, Suspense } from 'react';




export default class JSONEditorReact extends Component {
  async componentDidMount () {
    const options = {
      mode: this.props.mode || 'tree',
      modes: ['tree', 'code' ,'view' , 'form'  , 'text' , 'preview'],
      search:false,
      onChangeText: (jsonText) => {
        try {
          this.props.onChange?.(JSON.parse(jsonText));
        } catch (err) {
          console.error('Invalid JSON format', err);
        }
      }
    };
    
    const JSONEditor = (await import('jsoneditor')).default;

    this.jsoneditor = new JSONEditor(this.container, options);
    this.jsoneditor.set(this.props.value);
  }

  componentWillUnmount () {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  componentDidUpdate({value}) {
    if (value!==this.props.value) {
      if (!this.jsoneditor) {
        setTimeout(_=>this.componentDidUpdate({value}),100)
        return
      }
      this.jsoneditor.update(this.props.value)
    }
      
  }

  render() {
    return (
        <div className="jsoneditor-react-container" 
            ref={elem => this.container = elem}
            style={{
              width:'100%',
              height:'100%',
            }}
           />
    );
  }
}
