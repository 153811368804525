import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Icon, Loader, Dimmer, Table } from 'semantic-ui-react';

import { useToggleState } from 'xAppLib/Hooks';
import user_model from 'models/user_model';
import AppUser from 'models/app_user';
import obj_deep_diff from 'xAppLib/helpers/obj_deep_diff';
import API_service from 'xAppLib/providers/API_service';

const formatTableValues = (val) => {
	return {
		string: val || '""',
		boolean: String(val),
		undefined: 'undefined',
		object: <pre className='text-sm' >{JSON.stringify(val, null, 2)}</pre>
	}[typeof val] || val;
}

const TableRow = ({ field, fromVal, toVal }) => {
  return (
    <Table.Row>
      <Table.Cell>{field.replaceAll('_', ' ')}</Table.Cell>
      <Table.Cell>{formatTableValues(fromVal)}</Table.Cell>
      <Table.Cell>{formatTableValues(toVal)}</Table.Cell>
    </Table.Row>
  );
};

export default function ProfileSyncModal({ open, onClose, script_profile, syncProfile: { scr_data, onScriptChange } }) {
	const [isUpdateLoading, turnIsUpdateLoadingOn, turnIsUpdateLoadingOff] = useToggleState();
	const [isLoading,, turnIsLoadingOff] = useToggleState(true);
	const [diff, setDiff] = useState({});
  const profileToSpdRef = useRef({});

  const isDiff = diff.to && !!Object.keys(diff.to).length;

	useEffect(() => {
		(async () => {
			const { pts_uid } = scr_data;
			if (pts_uid) {
				const res = await user_model.load_prof(pts_uid);
				const [prof] = AppUser.parse_profile(res);
				// Keep only spd data properties
				profileToSpdRef.current = Object.keys(script_profile).reduce((acc, key) => ({ ...acc, [key]: prof[key] }), {});
				const diff = obj_deep_diff(script_profile, profileToSpdRef.current);
				setDiff(diff);
        turnIsLoadingOff();
			}
		})();
	}, []);

	const updateScript = async () => {
		turnIsUpdateLoadingOn();
		const { sid } = scr_data;
		const params = { sid, diff: { ...diff, part: 'spd_data' } };
		await API_service.load_data('scripts/upd_script', params);
		turnIsUpdateLoadingOff();
    onScriptChange?.({ ...scr_data, spd_data: profileToSpdRef.current })
		onClose?.();
	};


	const renderDiff = () => {
		if (!isDiff) return "The profile in the script is in sync with the patient's profile.";

		const { fro, to } = diff;
		return (
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Field</Table.HeaderCell>
						<Table.HeaderCell>Script</Table.HeaderCell>
						<Table.HeaderCell>User</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{Object.entries(fro).map(([key, value]) => (
						<TableRow key={key} field={key} fromVal={value} toVal={to[key]} />
					))}
				</Table.Body>
			</Table>
		);
	};

	return (
		<Modal open={open} onClose={onClose} style={{ width: 'fit-content', minWidth: '50%' }}>
			<Modal.Header as="h3" content="Sync Script With Profile" />
			<Modal.Content>{isLoading ? <Dimmer active={true} inverted><Loader /></Dimmer> : renderDiff()}</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>
					<Icon name="undo" /> Cancel
				</Button>
				<Button positive onClick={updateScript} disabled={!isDiff} loading={isUpdateLoading}>
					<Icon name="save" /> Sync From User Profile
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
