import React, { useEffect, useState } from 'react';
import { Button, Modal, Step } from "semantic-ui-react";
import { Link } from "react-router-dom";

import UniFormMed from "../../UniFormMed";
import { useCurrent } from "../../../../xAppLib/Hooks/useCurrent";
import { PreviewDocument, viewScriptUrl } from "../../../UIelems/PreviewDocument";
import user_model from "../../../../models/user_model";
import instcons_global_model from "../../../../models/instcons_global_model";
import { useProfile } from "../../../../xAppLib/Hooks";

const FIELDS = [
	{
		name: 'patho_res_sid',
		label: 'Pathology Results',
		type: 'text'
	}
];

function PathologyResults({profile = app.user?.prof, med_data, onLoad, error}) {
	const currentOnLoad = useCurrent(onLoad);
	const errorText = error ? 'text error font-bold' : '';
	const patho_id = med_data.need_patho;

	// assumes hist is sorted newest to oldest
	const hist = (profile?.hist || []).find(hs => hs.pts_uid === profile?.uid && hs.med_db_data.m == patho_id);
	const [frdbRec, setFrdbReq] = useState();

	const pathoReq = frdbRec || hist || {};
	const {sid, status} = pathoReq;

	useEffect(() => {
		// reset so changing profiles doesn't "remember" the previous value
		currentOnLoad.current(null);

		if (sid) {
			if (status === 'patho_res') {
				// update the form with the completed pathology
				currentOnLoad.current(sid);
			} else {
				// watch the record so we automatically update once approved
				return instcons_global_model.watch_record(sid, setFrdbReq);
			}
		} else {
			// refetch to update history, in case pt has just submitted patho and come back to consult
			user_model.load_prof().then(profile => {
				app.user.set_profile(profile)
				app.trigger(app.events.USER_UPD);
			});
		}
	}, [sid, status]);

	const [hasOpenedForm, setHasOpenedForm] = useState(false);
	const has_request = !!sid;
	const is_approved = !!pathoReq?.is_script;
	const has_results = pathoReq?.status === 'patho_res';
	const form_downloaded = hasOpenedForm || has_results;

	return (
		<>
			<p className={errorText}>
				We require some pathology results before we can proceed with your request.
				Please complete the steps below before continuing.
			</p>
			<Step.Group vertical fluid ordered>

				<Step completed={has_request} className="!flex-nowrap before:min-w-[70px]">
					<Step.Content>
						<Step.Title>Request pathology test</Step.Title>
						<Step.Description>
							Complete a short digital consultation form.
						</Step.Description>
					</Step.Content>
					{!has_request && <Step.Content className="!flex flex-grow justify-end whitespace-nowrap mt-4 md:mt-0 md:ml-4">
						<Step.Description>
							<Button as={Link}
									primary
									to={`/m/pharm/patho/${patho_id}`}
									state={{
										pts_uid: profile?.uid,
										src_mid: med_data.m
									}}>
								Request Pathology
							</Button>
						</Step.Description>
					</Step.Content>}
				</Step>

				<Step completed={is_approved} className="!flex-nowrap before:min-w-[70px]">
					<Step.Content>
						<Step.Title>Await doctor approval</Step.Title>
						<Step.Description>
							A doctor will review your answers and, if your request is approved, email you a pathology form.
						</Step.Description>
					</Step.Content>
				</Step>

				<Step completed={form_downloaded} className="!flex-nowrap before:min-w-[70px]">
					<Step.Content>
						<Step.Title>Print pathology form</Step.Title>
						<Step.Description>
							Take your pathology form to your nearest pathology collection centre.
							Our doctors will receive your results within 2-3 days.
						</Step.Description>
					</Step.Content>
					{is_approved && !has_results && <Step.Content className="!flex flex-grow justify-end whitespace-nowrap mt-4 md:mt-0 md:ml-4">
						<Step.Description>
							<PreviewDocument
								doc_name="Pathology Form"
								url={viewScriptUrl(sid)}
								onClosed={() => setHasOpenedForm(true)}
							/>
						</Step.Description>
					</Step.Content>}
				</Step>

				<Step completed={has_results} className="!flex-nowrap before:min-w-[70px]">
					<Step.Content>
						<Step.Title>Receive pathology results</Step.Title>
						<Step.Description>
							Once your results have been received, you can continue with this request.
						</Step.Description>
					</Step.Content>
					{has_results && <Step.Content className="!flex flex-grow justify-end whitespace-nowrap mt-4 md:mt-0 md:ml-4">
						<Step.Description>
							<Button as={Link} color="green" to={`/history/${sid}`}>
								View Pathology Results
							</Button>
						</Step.Description>
					</Step.Content>}
				</Step>

			</Step.Group>
		</>
	);
}

export default function NeedPathology(props) {
	const {visible, enabled, Section, med_data, formData} = props;
	const [profile, profiles] = useProfile();
	const active = profiles[formData.profile || 0] || profile;

	if (!(visible && enabled && med_data.need_patho)) {
		return null;
	}

	return (
		<Section>
			<Section.Header>Pathology Required</Section.Header>
			<Section.Content>
				<UniFormMed {...props} section="need_pathology" fields={FIELDS}>
					{(values, valids, uf_this) => (<>
						<PathologyResults
							profile={active}
							med_data={med_data}
							error={valids?.patho_res_sid === false}
							onLoad={sid => uf_this.handleInputChange({target: {name: 'patho_res_sid', value: sid}})}
						/>
					</>)}
				</UniFormMed>
			</Section.Content>
		</Section>
	);
}