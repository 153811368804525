import React from 'react';
import {cls} from '../../NUI/utils'

const IcosmIconList = ({list, dark}) => {
    const img_path = "https://storage.googleapis.com/icosm/"

    return  <ul className={dark ? `text-icosm-primary mt-12` : `text-icosm-primary mt-12`}>
                {list.map((l, i) => {
                    return <li key={i} className="flex gap-5 mb-8">
                                <div className="w-[114px] shrink-0">
                                    <img className="h-[104px]" src={img_path+l?.icon} style={{filter:dark?'brightness(0) saturate(100%) invert(97%) sepia(41%) saturate(4362%) hue-rotate(296deg) brightness(105%) contrast(87%)':'brightness(0) saturate(100%) invert(0%) sepia(94%) saturate(4571%) hue-rotate(209deg) brightness(95%) contrast(109%)'}} />
                                </div>
                                <span className="text-icosm_md md:text-icosm_base">
                                    <span className={cls('font-bold  block mb-2',dark?'text-icosm-tertiary':'text-icosm-tertiary')}>{l?.title}</span>
                                    {l?.desc} 
                                </span>
                            </li>
                })}
            </ul>
}

export default IcosmIconList;
