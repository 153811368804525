import React, { Component } from 'react';
import { Player, ControlBar, BigPlayButton } from 'video-react';

class Video  extends Component {
	
	state = { play:true, muted:false }
	
	componentDidMount() {
		this.setState({portrait:window.innerHeight > window.innerWidth})
		if (window.DeviceOrientationEvent) {
			  window.addEventListener("orientationchange", this.deviceOrientationListener);
		} 
	}
	
	deviceOrientationListener = (e) => {
		this.setState({portrait:window.innerHeight < window.innerWidth})
	}

	render() {
		
		const { videos = [], videosPortrait, autoPlay = false } = this.props
		const { play, portrait } = this.state
		const sources = portrait && videosPortrait ? videosPortrait : videos
		return (
			<div style={{maxWidth:'100%',margin:'10px auto',boxShadow:'0px 1px 3px 1px rgba(0,0,0,.1)'}}>
				<Player
					ref="player"
					autoPlay={autoPlay}
					muted={this.state.muted}
					playsInline
					>
					{sources.map((source,index)=>(
						<source src={source} key={`${portrait?'portrait':'landscape'}_${index}`}/>
					))}
					<ControlBar disableCompletely />
					<BigPlayButton className="hidden" position="center" />
				</Player>
				
			</div>
		);
	}

}

export default  Video;
