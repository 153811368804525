import React from 'react';
import { Link } from 'react-router-dom';

export default function LearnMore({ text = ' Learn more.' }) {
	return (
		<Link className="text-blue-800" onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: 'cx_txt', pn: 'prescriptions-update', noHeader: true })}>
			{text}
		</Link>
	);
}
