/* eslint-env node */
//	------------------------	------------------------	------------------------
//	Description: base component within an erx script
//  eRx script -> item[s] -> content -> CLINICIAN, patient, prescribed item
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const get_set =     require('../../helpers/erx/get_set_function')

const random_f =    require('../../helpers/erx/random_function')

const erx_enums =   require('../erx/resources/erx_enums')
const erx_formats = require('../erx/resources/erx_formats')
const erx_props =   require('../erx/resources/erx_props')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DEFAULTS = {
    PracticeName:                   "InstantScripts",
    PracticeAddress1:               "Level 8 / 637 Flinders St.",
    PracticeAddress2:               "",
    PracticeSuburb:                 "DOCKLANDS",
    PracticePostcode:               "3008",
    PracticeState:                  "VIC",
    PracticeEmail:                  "info@instantscripts.com.au",
    PrescribingSystemUsed:          "instantscripts.com.au",
}

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class erx_clinician_model {

    constructor(clinician_data, run_validation = true) {

        this._clinician_data = {}
        this.setup_props()

        if (clinician_data){
            if (run_validation) this.clinician_data = clinician_data
            else this._clinician_data = clinician_data
        } 
        else this.set_default()

    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get clinician_data() { return this._clinician_data }
    set clinician_data(val) { 
        this._clinician_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------

    set_blank() { // You probably don't want to call this
        this.clear()

        erx_props.CLINICIAN_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })
    }

    //	------------------------	------------------------	------------------------

    set_default() { 
        this.set_blank()
        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------

    toJSON() { // Reduce didn't work
        let ret = {}
        erx_props.CLINICIAN_PROPS.forEach(i => ret[i.name] = this[i.name])
        return ret
    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {
        
        // Fix self
        let result = {res: 'ok', fixes: [], err: []}

        for (let i = 0; i < erx_props.CLINICIAN_PROPS.length; i++){
            const c_prop = erx_props.CLINICIAN_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format - see if we can convert any strings to expected format
            if ((typeof c_item !== 'undefined') && c_item !== '' && c_item !== null && c_prop.format){ //Note - content props for patient, clinician and item wont have a format and will be ignored
                
                const formatter = erx_formats.ERX_FORMAT[c_prop.format]
                if (!formatter || !formatter.validate) result.err.push('Cannot find validator for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                else {

                    const valid_res = formatter.validate(c_item)

                    if (valid_res.res != 'ok'){

                        if (remove_errors) this[c_prop.name] = null // The value may be fixed below

                        if (!formatter.fromValue) result.err.push('Cannot find formatter (fromValue function) for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                        else {

                            const from_res = formatter.fromValue(c_item)

                            if (from_res.res != 'ok') result.err.push('Could not parse item to type ' + c_prop.format + ' for prop ' + c_prop.name + '. Error: ' + from_res.err)
                            else {

                                result.fixes.push( { name: c_prop.name, from: c_item, to: from_res.val } )
                                this[c_prop.name] = from_res.val

                            }
                        }
                    } 
                }              
            }
        }
        
        if (result.err.length > 0) result.res = 'err'
        else delete result.err
        return result

    }

    //	------------------------	------------------------	------------------------

    check_valid(params = {}) {

        let result = {res: 'ok'}

        for (let i = 0; i < erx_props.CLINICIAN_PROPS.length; i++){
            const c_prop = erx_props.CLINICIAN_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format
            if (typeof c_item != 'undefined' && c_item != '' && c_item != null && c_prop.format){
                const check_format_res = erx_formats.check_erx_format(c_item, c_prop.format)
                if (check_format_res.res != 'ok')
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is wrong format. ' + check_format_res.err)] } //, errs: [...(result.errs || []), check_format_res]
                    //return {res: 'err', err: c_prop.name + ' is wrong format. ' + check_format_res.err}
            }

            // Check the length
            if (typeof c_item != 'undefined' && c_item != null && c_prop.length){
                if (c_item.toString().length > c_prop.length)
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString())] }
                    //return {res: 'err', err: c_prop.name + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString()}
            }

            // Check max occurance
            if (!c_prop.minOccurs === 0){
                if (typeof c_item === 'undefined')
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is must have at least one occurance but has none. Value: ' + c_item)] }
                    //return {res: 'err', err: c_prop.name + ' is must have at least one occurance but has none. Value: ' + c_item}
            }

            //Check nillable
            if (!c_prop.nillable === true){
                if (c_item === null && !c_prop.minOccurs === 0)
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is not nillable but is null. Value: ' + c_item)] }
                    //return {res: 'err', err: c_prop.name + ' is not nillable but is null. Value: ' + c_item}
            }

            // Check the mandatory
            if (c_prop.mandatory && c_prop.mandatory({...params, clinician: this})){
                if (typeof c_item == 'undefined' || c_item == null || c_item?.length===0)
                result = {...result, res: 'err', err: [...(result.err || []), ((c_prop.name=='Clinician_Reserved_01' ? "Qualification" : c_prop.label) + ' is mandatory but absent.')] }
                    //return {res: 'err', err: c_prop.name + ' is mandatory but absent. Value: ' + c_item}
            }
        }

        return result

    }

    //	------------------------	------------------------	------------------------

    compare(target_clinician, subset = false) { // Compares this script's items to the target scripts items. Subset means only compare the props on the source

        let diff = []

        //log("Checking clinician props match")
        // Check props

        erx_props.CLINICIAN_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_clinician && (typeof target_clinician[item.name] != undefined) && (target_clinician[item.name]!= '') && target_clinician[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Clinician property ' + item.name + ', source: ' + source + ', target: ' + target, source, target}) 
            }
        })

        // Patient
        // Any future wrapped sub fields will need to go here - equivilant to get_item() on script or get_patient() on content

        // Return
        if (diff.length == 0) return {res: 'ok'}
        else return {res: 'err', err: 'Clinicians do not match by ' + diff.length + ' field(s). Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff}

    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.CLINICIAN_PROPS.forEach(p => this.define_prop(p)) }
    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.clinician_data }
    set inner_data(val) { this.clinician_data = val }

    //	------------------------	------------------------	------------------------

    get DoctorPrescriberNumber() { throw { override_msg: 'get DoctorPrescriberNumber' }}
    set DoctorPrescriberNumber(val) { throw { override_msg: 'set DoctorPrescriberNumber' }}

    get DoctorFirstName() { throw { override_msg: 'get DoctorFirstName' }}
    set DoctorFirstName(val) { throw { override_msg: 'set DoctorFirstName' }}

    get DoctorFamilyName() { throw { override_msg: 'get DoctorFamilyName' }}
    set DoctorFamilyName(val) { throw { override_msg: 'set DoctorFamilyName' }}

    get DoctorProviderNumber() { throw { override_msg: 'get DoctorProviderNumber' }}
    set DoctorProviderNumber(val) { throw { override_msg: 'set DoctorProviderNumber' }}

    get DoctorMobileNumber() { throw { override_msg: 'get DoctorMobileNumber' }}
    set DoctorMobileNumber(val) { throw { override_msg: 'set DoctorMobileNumber' }}

    get DoctorEmail() { throw { override_msg: 'get DoctorEmail' }}
    set DoctorEmail(val) { throw { override_msg: 'set DoctorEmail' }}

    get PracticeName() { throw { override_msg: 'get PracticeName' }}
    set PracticeName(val) { throw { override_msg: 'set PracticeName' }}

    get PracticeAddress1() { throw { override_msg: 'get PracticeAddress1' }}
    set PracticeAddress1(val) { throw { override_msg: 'set PracticeAddress1' }}

    get PracticeAddress2() { throw { override_msg: 'get PracticeAddress2' }}
    set PracticeAddress2(val) { throw { override_msg: 'set PracticeAddress2' }}

    get PracticeSuburb() { throw { override_msg: 'get PracticeSuburb' }}
    set PracticeSuburb(val) { throw { override_msg: 'set PracticeSuburb' }}

    get PracticePostcode() { throw { override_msg: 'get PracticePostcode' }}
    set PracticePostcode(val) { throw { override_msg: 'set PracticePostcode' }}

    get PracticeState() { throw { override_msg: 'get PracticeState' }}
    set PracticeState(val) { throw { override_msg: 'set PracticeState' }}

    get PracticeEmail() { throw { override_msg: 'get PracticeEmail' }}
    set PracticeEmail(val) { throw { override_msg: 'set PracticeEmail' }}

    get DoctorPhoneNumber() { throw { override_msg: 'get DoctorPhoneNumber' }}
    set DoctorPhoneNumber(val) { throw { override_msg: 'set DoctorPhoneNumber' }}

    get DoctorFaxNumber() { throw { override_msg: 'get DoctorFaxNumber' }}
    set DoctorFaxNumber(val) { throw { override_msg: 'set DoctorFaxNumber' }}

    get PrescribingSystemUsed() { throw { override_msg: 'get PrescribingSystemUsed' }}
    set PrescribingSystemUsed(val) { throw { override_msg: 'set PrescribingSystemUsed' }}

    get PrescriberType() { throw { override_msg: 'get PrescriberType' }}
    set PrescriberType(val) { throw { override_msg: 'set PrescriberType' }}

    get PrescriberHPIO() { throw { override_msg: 'get PrescriberHPIO' }}
    set PrescriberHPIO(val) { throw { override_msg: 'set PrescriberHPIO' }}

    get PrescriberHPII() { throw { override_msg: 'get PrescriberHPII' }}
    set PrescriberHPII(val) { throw { override_msg: 'set PrescriberHPII' }}

    get PrescriberAHPRANumber() { throw { override_msg: 'get PrescriberAHPRANumber' }}
    set PrescriberAHPRANumber(val) { throw { override_msg: 'set PrescriberAHPRANumber' }}

    get Clinician_Reserved_01() { throw { override_msg: 'get Clinician_Reserved_01' }}
    set Clinician_Reserved_01(val) { throw { override_msg: 'set Clinician_Reserved_01' }}

    get Clinician_Reserved_02() { throw { override_msg: 'get Clinician_Reserved_02' }}
    set Clinician_Reserved_02(val) { throw { override_msg: 'set Clinician_Reserved_02' }}

    get Clinician_Reserved_03() { throw { override_msg: 'get Clinician_Reserved_03' }}
    set Clinician_Reserved_03(val) { throw { override_msg: 'set Clinician_Reserved_03' }}

    get Clinician_Reserved_04() { throw { override_msg: 'get Clinician_Reserved_04' }}
    set Clinician_Reserved_04(val) { throw { override_msg: 'set Clinician_Reserved_04' }}
    
    get Clinician_Reserved_05() { throw { override_msg: 'get Clinician_Reserved_05' }}
    set Clinician_Reserved_05(val) { throw { override_msg: 'set Clinician_Reserved_05' }}

}