import React from "react";
import ErrorScreen from "./ErrorScreen"

class DocumentErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log(error, errorInfo, this.props);
      // Add GCP logging here
    }
  
    render() {
      if (this.state.hasError) {
        return <ErrorScreen message={"Something went wrong when trying to render this document. Contact the Dev Team"}/>;
      }
  
      return this.props.children; 
    }
  }
  
  export default DocumentErrorBoundary;