import React from 'react';
import { Link } from 'react-router-dom'
import {
		Image,
		Icon,
	} from 'semantic-ui-react'

import db_lib from 'xAppLib/libs/db_lib';
import API_service from 'xAppLib/providers/API_service'
import gravatar_url from 'xAppLib/Gravatar/gravatar_url'
import UserIHI from 'xAppLib/Users/UserIHI';
import StartConsult from 'views/admin/scripts/StartConsult';
import { ChroCond_keysToLabels } from 'xAppLib/UIelems/ChronicConditionsModal';
import CopyField from 'xAppLib/UIelems/CopyField'
import SimpleShowMore from 'xAppLib/UIelems/SimpleShowMore'
import { QualificationStatusIndicator } from "../views/UIelems/QualificationStatusIndicator";

// const _FRDB_LOC = 'MedQsForm';
const _LIST_DET_FIELDS_ADM = [

						
						{
							name: 'Details',
							type: 'compound',
							parts : [
								{
									name: 'Email',
									jpath: 'email',
								},

								{
									name: 'Fname',
									jpath: 'first_name',
								},
								{
									name: 'Lname',
									jpath: 'last_name',
								},
								{
									name: 'Photo',
									jpath: 'ava'
								},
								{
									name: 'Age',
									jpath: 'dob',
									type: 'age',
								},
								{
									name: 'Sex',
									jpath: 'sex',
								},
								{
									name: 'Active',
									jpath: 'active',
									type: 'bool_neg',
									// actt: 'field',
									// actf: 'disabled',
								},
								{
									name: 'medicare',
									jpath: 'medicare',
								},
								{
									name: 'conc_card',
									jpath: 'conc_card',
								},
								{
									name: 'ihi_num',
									jpath: 'ihi_ihinumber',
								},
								{
									name: 'ihi_stat',
									jpath: 'ihi_status',
									// type: 'if_val_bool',
									// if_val: 'escript',
								},
								{
									name: 'prof',
									jpath: 'prof',
									// type: 'if_val_bool',
									// if_val: 'escript',
								},

								{
									name: 'chro_cond',
									jpath: 'chro_cond',
								},
								{
									name: 'ur',
									jpath: 'ur',
								},
								{
									name: 'em_con_name',
									jpath: 'em_con_name'
								},
								{
									name: 'em_con_mobile',
									jpath: 'em_con_mobile'
								},
								{
									name: 'em_con_rel',
									jpath: 'em_con_rel'
								},
								{
									name: 'qualcons',
									jpath: 'qualcons'
								},
								{
									name: 'onepass',
									jpath: 'onepass'
								}
							],
							template : ({Email,Fname,Lname,Photo, Age, Sex, Active, ihi_num, ihi_stat, medicare, conc_card,prof, chro_cond,ur, em_con_name, em_con_mobile, em_con_rel, qualcons, onepass}) => <React.Fragment>
												<Image floated="left" avatar src={Photo != '' && Photo || gravatar_url({ email: Email, name: Fname+' '+Lname, }) || null} />
												<div>
													{prof?.par && <Icon name='child' title='Child account'/>} {Active} {Fname} {Lname}{" "}
													{Sex && <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} />}
													<QualificationStatusIndicator qualcons={qualcons || []} />
													<br/>
													{Email}<br/>
													{Age}{Age && <br/>}

													{(em_con_name || em_con_mobile || em_con_rel) && <i><SimpleShowMore cut_length={20} content={`Emergency: ${em_con_name}, ${em_con_mobile}, ${em_con_rel}`} /><br /></i>}
													
													MdC:<Icon size='small' 
															name={medicare&&'plus'||'minus'} 
															color={medicare&&'green'||'red'||'yellow'} />&nbsp;
													{conc_card && <>Conc:<Icon size='small' 
															name={conc_card&&'plus'||'minus'} 
															color={conc_card&&'green'||'red'||'yellow'} />&nbsp;</>}
													IHI:<Icon size='small' 
															name={ihi_stat=='ok'&&'check'||'close'} 
															color={ihi_stat=='ok'&&'green'||'red'||'yellow'} />
													OP:<Icon size='small' 
															name={onepass ? 'check' : 'x'} 
															color={onepass? 'green' : 'red'} />

													{chro_cond && <i><br/><b>{chro_cond.length}:</b> {ChroCond_keysToLabels(chro_cond)?.join(', ').substr(0, 25)}</i>}
													<br/><CopyField val={ur} />
												</div>
											</React.Fragment>
						},

						{
							name: 'Prtns / Suburb',
							type: 'compound',
							parts : [
								{
									name: 'prtns',
									jpath: 'prtns',
								},

								{
									name: 'suburb',
									jpath: 'suburb'
								},

								{
									name: 'state',
									jpath: 'state'
								},
							],
							template : ({suburb, state, prtns})=> <React.Fragment>
												{prtns && <b>{Object.keys(prtns).join(', ')}<br/><br/></b>}
												{suburb}
												<br/>
												{state} 
											</React.Fragment>
						},


						{
							name: 'Scrs',
							type: 'multiline',
							lines: [
										{
											name: 'Pts Scrs',
											jpath: 'pts_scrs',
											jpath_tot: 'pts_scrs_tot',
											type: 'custom',
											cust_fld: 'show_scripts',
										},
										{type: 'br'},
										{type: 'br'},
										{
											name: 'Req Scrs',
											jpath: 'by_scrs',
											jpath_tot: 'by_scrs_tot',
											type: 'custom',
											cust_fld: 'show_scripts',
										},
									]
						},



						{
							name: 'History',
							jpath: 'hist',
							type: 'popup_hist',
							ico: 'history',
							compact: true,
						},

						{
							name: 'Role',
							type: 'multiline',
							lines: [
										{
											name: 'Role',
											jpath: 'claims',
											type: 'role',
											actt: 'claim',
										},
										{type: 'br'},
										{
											type: 'val',
											val: 'AHPRA:',
											show_if: {
												jpath: 'xtra.AHPRA',
											}
										},
										{
											jpath: 'xtra.AHPRA',
										},
									],

						},
						{
							name: 'Role setup - Org / Doc',
							type: 'multiline',
							lines: [
										{
											name: 'Org',
											type: 'custom',
											cust_fld: 'org_set',
										},

										{type: 'br'},
										{type: 'br'},
										{type: 'val', val: 'Select dr: ' },

										{
											name: 'Doc',
											type: 'custom',
											cust_fld: 'doc_set',
										},
									],
						},

						{
							name: 'Bookable',
							type: 'custom',
							cust_fld: 'set_booking_types',
						},

						{
							name: 'Created',
							jpath: 'cre_tm',
							type: 'time'
						},
						// {
						// 	name: 'SignIn',
						// 	jpath: 'acc_tm',
						// 	type: 'time'
						// },
						{
							name: 'SgUp',
							type: 'compound',
							parts : [
								{
									type: 'dict',
									name: 'orig',
									jpath: 'meta.sgup',
									vals: {
											adm: 'User Admin',
											usr: 'Open Reg',
											pts: 'Patient',
											sgph: 'Pharm Signup',
										}
								},

								{
									type: 'dict',
									name: 'fe',
									jpath: 'meta.sgup_fe',
									vals: {
											wa: 'WebApp',
											ia: 'ionApp',
										}
								},

								{
									name: 'soc_prov',
									jpath: 'meta.sgup_prov',
									type: 'dict',
									vals: {
											'facebook.com': 'facebook f',
											'google.com': 'google',
											'apple.com': 'apple',
											'oidc.onepass': 'openid',
										}
								},
							],
							template : ({orig, fe, soc_prov})=> <React.Fragment>
													{orig}&nbsp; <Icon name={soc_prov} size_='small' /><br/>{fe}
											</React.Fragment>
						},
						{
							name: 'IHI',
							template : ({row})=> <React.Fragment>
													<UserIHI row={row}/>
											</React.Fragment>
						},
						// {
						// 	name: 'Active',
						// 	jpath: 'active',
						// 	type: 'bool',
						// 	// actt: 'field',
						// 	// actf: 'disabled',
						// },
					];

const _LIST_DET_FIELDS_MANG = [

						
						{
							name: 'Details',
							type: 'compound',
							parts : [
								{
									name: 'Email',
									jpath: 'email'
								},

								{
									name: 'Fname',
									jpath: 'first_name'
								},
								{
									name: 'Lname',
									jpath: 'last_name'
								},
								{
									name: 'Photo',
									jpath: 'ava'
								},
								{
									name: 'Age',
									jpath: 'dob',
									type: 'age',
								},
								{
									name: 'Sex',
									jpath: 'sex',
								},
								{
									name: 'Active',
									jpath: 'active',
									type: 'bool_neg',
									// actt: 'field',
									// actf: 'disabled',
								},
							],
							template : ({Email,Fname,Lname,Photo, Age, Active})=> <React.Fragment>
												<Image floated="left" avatar src={Photo != '' && Photo || gravatar_url({ email: Email, name: Fname+' '+Lname, }) || null} />
												<div>{Active} {Fname} {Lname}<br/>{Email}<br/>{Age}</div>
											</React.Fragment>
						},

						{
							name: 'Suburb',
							type: 'compound',
							parts : [
								{
									name: 'suburb',
									jpath: 'suburb'
								},

								{
									name: 'state',
									jpath: 'state'
								},
							],
							template : ({suburb, state})=> <React.Fragment>
												{suburb}
												<br/>
												{state} 
											</React.Fragment>
						},

						{
							name: 'Req Scrs',
							jpath: 'by_scrs',
							jpath_tot: 'by_scrs_tot',
							type: 'custom',
							cust_fld: 'show_scripts',
						},

						{
							name: 'Role',
							type: 'multiline',
							lines: [
										{
											name: 'Role',
											jpath: 'claims',
											type: 'role',
											actt: 'claim',
										},
										{type: 'br'},
										{
											type: 'val',
											val: 'AHPRA:',
											show_if: {
												jpath: 'xtra.AHPRA',
											}
										},
										{
											jpath: 'xtra.AHPRA',
										},
									],

						},

						{
							name: 'Org',
							type: 'custom',
							cust_fld: 'org_set',
						},

						{
							name: 'Booking',
							type: 'compound',
							parts: [
								{
									name: 'claims',
									jpath: 'claims'
								},
								{
									name: 'uid',
									jpath: 'uid'
								},
								{
									name: 'first_name',
									jpath: 'first_name'
								},
								{
									name: 'last_name',
									jpath: 'last_name'
								},
							],
							template: ({ claims, uid, first_name, last_name }) => {
								if (!claims?.nurse) {
									return null;
								}
								return (
									<div>
										<Link
											to={`/admin/org-booking/users/${uid}`}
											className='flex text-center'
											title={`Booking roster and settings for ${first_name} ${last_name}`}
										>
											Roster
										</Link>
									</div>
								)
							}
						},

						{
							name: 'Created',
							jpath: 'cre_tm',
							type: 'time'
						},

					];

const _LIST_DET_FIELDS_AUTH = [

						
						{
							name: 'Details',
							type: 'compound',
							parts : [
								{
									name: 'Email',
									jpath: 'providerData.0.email'
								},

								{
									name: 'Name',
									jpath: 'displayName'
								},
								{
									name: 'Photo',
									jpath: 'photoURL'
								},
							],
							template : ({Email,Name,Photo})=> <React.Fragment>
												<Image floated="left" avatar src={Photo != '' && Photo || null} /><div>{Name}<br/>{Email}</div>
											</React.Fragment>
						},

						{
							name: 'Role',
							jpath: 'customClaims',
							type: 'role',
							actt: 'claim',
						},

						{
							name: 'Org',
							type: 'custom',
							cust_fld: 'org_set',
						},

						{
							name: 'Doc',
							type: 'custom',
							cust_fld: 'doc_set',
						},

						{
							name: 'Created',
							jpath: 'metadata.creationTime',
							type: 'time'
						},
						// {
						// 	name: 'Last SignIn',
						// 	jpath: 'metadata.lastSignInTime',
						// 	type: 'time'
						// },


						{
							name: 'provider',
							jpath: 'providerData.0.providerId',
						},
						{
							name: 'Eml Verif',
							jpath: 'emailVerified',
							type: 'bool',
						},
						{
							name: 'Active',
							jpath: 'active',
							type: 'bool',
							// actt: 'field',
							// actf: 'disabled',
						},
					];

const _LIST_SIMP_FIELDS = [


						{
							name: 'Email',
							jpath: 'providerData.0.email'
						},

						{
							name: 'Name',
							jpath: 'displayName'
						},

						{
							name: 'Role',
							jpath: 'customClaims',
							type: 'role',
						},


						{
							name: 'Org path',
							jpath: 'org_path',
						},

						{
							name: 'Org',
							jpath: 'org_name',
						},


						{
							name: 'Doc',
							jpath: 'customClaims.doc_id',
							type: 'stlookup',
							stdata: 'docs_data',
							showfld: 'name',
						},

						{
							name: 'Created',
							jpath: 'metadata.creationTime',
							type: 'time'
						},
						// {
						// 	name: 'Last SignIn',
						// 	jpath: 'metadata.lastSignInTime',
						// 	type: 'time'
						// },

					];

const _LIST_DET_FIELDS_DOC = [

	{
		name: 'Details',
		type: 'compound',
		parts: [
			{
				name: 'Email',
				jpath: 'email'
			},

			{
				name: 'Fname',
				jpath: 'first_name'
			},
			{
				name: 'Lname',
				jpath: 'last_name'
			},
			{
				name: 'Photo',
				jpath: 'ava'
			},
			{
				name: 'Age',
				jpath: 'dob',
				type: 'age',
			},
			{
				name: 'Sex',
				jpath: 'sex',
			},
			{
				name: 'Active',
				jpath: 'active',
				type: 'bool_neg',
				// actt: 'field',
				// actf: 'disabled',
			},
		],
		template: ({ Email, Fname, Lname, Sex, Photo, Age, Active }) => <React.Fragment>
			<Image floated="left" avatar src={Photo != '' && Photo || gravatar_url({ email: Email, name: Fname + ' ' + Lname, }) || null} />
			<div>{Active} {Fname} {Lname} {Sex && <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} />}<br />{Email}<br />{Age}</div>
		</React.Fragment>
	},

	{
		name: 'Req Scrs',
		jpath: 'by_scrs',
		jpath_tot: 'by_scrs_tot',
		type: 'custom',
		cust_fld: 'show_scripts',
	},

	{
		name: 'Created',
		jpath: 'cre_tm_melb',
		type: 'time'
	},

	{
		name: 'Consult',
		template : ({row})=> <StartConsult row={row}/>
	},
	{
		name: 'IHI',
		template : ({row})=> <React.Fragment>
								<UserIHI row={row}/>
						</React.Fragment>
	},

]

// const _NEW_USER_FORM_FIELDS = [
// 						{
// 							name: "nu_name",
// 							label:'Name',
// 							type: "text",
// 							placeholder: "Name",
// 							param_name: "name",
// 							req_label: "*",
// 							valid_not_required: true,
// 						},

// 						{
// 							name: "nu_email",
// 							label:'Email',
// 							type: "email",
// 							placeholder: "Email",
// 							param_name: "email",
// 							req_label: "*",
// 						},

// 						{
// 							name: "nu_password",
// 							label:'Password',
// 							type: "text",
// 							placeholder: "Password",
// 							param_name: "password",
// 							req_label: "*",
// 						},
						
// 						{
// 							name: "nu_org",
// 							label:'Organisation',
// 							type: "org",
// 							param_name: "oid",
// 							valid_not_required: true,
// 						},
// 						{
// 							name: 'claims',
// 							label:'Role',
// 							type: 'role',
// 							valid_not_required: true,
// 						},
						
					
// 						{
// 							name: "no_email",
// 							label:'Do Not Email User',
// 							type: "checkbox",
// 							options: [ {c:'no', n:'No Email'} ],
// 							// placeholder: "Type?",
// 							// param_name: "t",
// 							// req_label: "*",
// 							valid_not_required: true,
// 						},

// 						{
// 							name: "note",
// 							label:'Additional note to email',
// 							type: "textarea",
// 							value: "To start using your account and see all your scripts, please login at https://pharmapp.instantscripts.com.au",
// 							// param_name: "p",
// 						},

						

// 					];

// const _MODEL_NAME = 'admin_users_model';

const _EMBEDDED_FIELDS = ["Details", 'Role', 'Created', 'Last SignIn']

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class admin_users_model extends db_lib {

	// static get FRDB_LOC() { return _FRDB_LOC; }
	static get LIST_DET_FIELDS_ADM() { return _LIST_DET_FIELDS_ADM; }
	static get LIST_DET_FIELDS_MANG() { return _LIST_DET_FIELDS_MANG; }
	static get LIST_DET_FIELDS_AUTH() { return _LIST_DET_FIELDS_AUTH; }
	static get LIST_DET_FIELDS_DOC() { return _LIST_DET_FIELDS_DOC; }
	static get LIST_SIMP_FIELDS() { return _LIST_SIMP_FIELDS; }
	// static get NEW_USER_FORM_FIELDS() { return _NEW_USER_FORM_FIELDS; }
	static get EMBEDDED_FIELDS() { return _EMBEDDED_FIELDS; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static pharms_dd_field( phs ) {

		return {
							name: "pharm",
							label:'Pharmacy',
							type: "select",
							options: (() => {
									let pa = [];
									Object.keys(phs)
										.map( k =>
												pa.push({
														key: k,
														value: k,
														text:
															phs[k].name + "  ::  " +
															(phs[k].email||"NO EMAIL") + "  ::  " +
															phs[k].address
													})
											)
									return pa
								})()
								,
									// [
									// 	// { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
									// 	{ key: 'gn', value: 'General', text: 'General' },
									// 	{ key: 'er', value: 'Report an error', text: 'Report an error' },
									// 	{ key: 'us', value: 'Help with usage', text: 'Help with usage' },
									// 	{ key: 'ps', value: 'Partnership', text: 'Partnership' },
									// ],
							placeholder: "Select",
							// param_name: "t",
							req_label: "*",
							// valid_not_required: true,
						}

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async load_users_list( params = {}, ca ) {

		return await API_service.load_data(
									'UserAdmin/listAllUsers', 
									{type: 'all', ...params},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async get_all_records( ca, filters = {}, load_data ) {

		return await API_service.load_data(
									'UserAdmin/listAllUsers', 
									{type: 'all', filters, load_data},
									r => ca && ca(r)
								) 

		// firebase_functions.call(
		// 							'listAllUsersCall',
		// 							{type: 'all'},
		// 							r => ca && ca(r)
		// 						)

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async add_new_user( d, ca ) {

		return await API_service.load_data(
									'UserAdmin/addNewUser', 
									d,
									r => ca && ca(r)
								) 

		// firebase_functions.call(
		// 							'addNewUserCall',
		// 							d,
		// 							r => ca && ca(r)
		// 						)

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async edit_user_claims( u, c, ca ) {

		return await API_service.load_data(
									'UserAdmin/editUserClaims', 
									{u, c},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async edit_user_org( u, o, ca ) {

		return await API_service.load_data(
									'UserAdmin/editUserOrg', 
									{u, o},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async edit_user_booking_types( u, value, ca ) {

		return await API_service.load_data(
									'UserAdmin/editUserBookingTypes', 
									{u, value},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async archive_user( u, ca ) {

		return await API_service.load_data(
									'UserAdmin/archiveUser', 
									{u},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async delete_factor( uid, factorUID, extend, ca ) {

		return await API_service.load_data(
									'UserAdmin/removeFactor', 
									{uid, factorUID, extend},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async extend_grace( uid, ca ) {

		return await API_service.load_data(
									'UserAdmin/extend_grace', 
									{uid},
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	// static edit_user_field( u, f, ca ) {

	// 	firebase_functions.call(
	// 								'editUserFieldCall',
	// 								{u, f},
	// 								r => ca && ca(r)
	// 							)

	// }

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async get_user_byuid( u, ca ) {

		return await API_service.load_data(
									'UserAdmin/findUserUID', 
									{u},
									r => ca && ca(r)
								) 

		// firebase_functions.call(
		// 							'findUserCall',
		// 							{u},
		// 							r => ca && ca(r)
		// 						)

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async send_user_password( u, password, ca ) {

		return await API_service.load_data(
									'UserAdmin/sendUserPassword', 
									{uid:u.uid,password},
									r => ca && ca(r)
								) 

		// firebase_functions.call(
		// 							'sendUserPasswordCall',
		// 							{uid:u.uid,password},
		// 							r => ca && ca(r)
		// 						)

	}

	// ---- --------------------------------------------  --------------------------------------------

	static async set_user_newname( u, newname, ca ) {

		return await API_service.load_data(
									'UserAdmin/setUserNewName', 
									{uid:u.uid, newname},
									r => ca && ca(r)
								) 

		// firebase_functions.call(
		// 							'setUserNewNameCall',
		// 							{uid:u.uid, newname},
		// 							r => ca && ca(r)
		// 						)

	}

	// ---- --------------------------------------------  --------------------------------------------

	static async set_user_newemail( u, newemail, ca ) {

		return await API_service.load_data(
									'UserAdmin/setUserNewEmail', 
									{uid: u.prof?.par || u.uid, newemail},
									r => ca && ca(r)
								) 

		// firebase_functions.call(
		// 							'setUserNewEmailCall',
		// 							{uid:u.uid, newemail},
		// 							r => ca && ca(r)
		// 						)

	}

	static async set_cred( u, cred, ca ) {

		return await API_service.load_data('UserAdmin/setUserCred', {uid: u.uid, cred}) 

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	// ---- --------------------------------------------  --------------------------------------------

	static async upd_user_prof( params, ca ) {

		// console.log('upd_user_prof');

		return await API_service.load_data(
									'UserAdmin/updUserProf', 
									params,
									r => ca && ca(r)
								) 
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

}
