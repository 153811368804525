import React, { useState } from 'react';

import { Modal, Header, Divider, Button, Icon } from 'semantic-ui-react';

const ClosePopup = ({scrReqAvail}) => {
    if (!scrReqAvail)
        return null

    const [close, setClose] = useState(false)

    const header = scrReqAvail.title !== undefined 
        ? scrReqAvail.title
        : scrReqAvail.open && 'Note' || 'Error';

    return	<Modal
                open={!!scrReqAvail && !close}
                style={{maxHeight:'70vh'}}
                onClose={_=> !scrReqAvail.open ? app.history.push('/') : setClose(true)}
                closeIcon={true}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
              >

                <Modal.Content style={{overflowY:'scroll',maxHeight:'calc(70vh - 70px)'}} >

                    <Header as='h3' color="blue" content={header} />
                    
                    {header && <Divider/>}
                    
                    <p><strong>{scrReqAvail?.msg || ''}</strong></p>

                </Modal.Content>

                <Modal.Actions>
                    <Button positive onClick={ _=> !scrReqAvail.open ? app.history.push('/') : setClose(true) } >
                        <Icon name='checkmark' /> OK
                    </Button>
                    {scrReqAvail.reload && (
                        <Button positive onClick={ _=> app.history.push(0) } >
                            <Icon name='refresh' /> Reload
                        </Button>
                    )}
                </Modal.Actions>
               
            </Modal>;
}
 
export default ClosePopup;