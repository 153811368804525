

import React from 'react';
import { cls } from '../../NUI/utils';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const IcosmInfo = ({title, sub_title, desc, reverse, btn_link, btn_txt, img, size}) => {
    const img_path = "https://storage.googleapis.com/icosm/"

    return  <div className="flex flex-col lg:flex-row gap-[25px] lg:gap-[73px] py-9 md:pb-[74px] md:pt-[65px] border-b border-[#A39383] last-of-type:border-none">
                <div className={cls(size==="lg" ? "lg:w-[2/5]" : "lg:w-1/2", reverse ? "order-2" : "order-2 lg:order-none")}>
                    <h2>{title}</h2>
                    {sub_title && <p className="!font-bold text-[1.56rem] leading-[1.43rem] mb-4">{sub_title}</p>}
                    <p className="text-icosm_md md:text-icosm_base">{desc}</p>
                    {btn_link && <Button className="cta cta-tertiary icon" as={Link} size="large" type="button" to={btn_link}>{btn_txt || 'Sign me up'}</Button>}
                </div>
                <div className={cls((size==="lg" && "w-3/5 xl:w-[800px] md:h-[450px] shrink-0 bg-cover bg-center" || size==="sm" && "!bg-white md:bg-contain lg:w-1/3" || "lg:w-1/2 xl:w-[548px] bg-cover"),size==="lg" ? "md:h-[450px]" : "md:h-[548px]", "h-[250px] w-full bg-[50%_25%] bg-no-repeat bg-[#D9D9D9]")} style={{backgroundImage:`url(${img_path}${img})`}}></div>
            </div>
}

export default IcosmInfo;
