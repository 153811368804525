export const NoAnswerTemplate = `Hi, 

We have tried calling and were unable to get through.

Please ensure your phone is switched on and accepting calls from private numbers.

We will try calling again soon.`;

export const NoAnswerCancelConsTemplate = `Hi,

We have not been able to reach you on multiple attempts at contact, and have refunded and cancelled your
booking.

If you still require medical attention please either re-book or present to your regular GP or local emergency
department.`;

export const WrongDetailsTemplate = `We are unable to help with your request via Telehealth and encourage you to see a face-to-face GP or present
to your local emergency department as discussed with the doctor today.

We have refunded the cost of your consultation.`;