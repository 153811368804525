import React, { PureComponent } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Confirm,
		TextArea,
		Form,
		Checkbox,
	} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import API_service from 'xAppLib/providers/API_service'
import Alert from '../../NUI/Alert';
import { WithInfoIcon } from '../../med/Sections/HDR';


class ActBtns extends PureComponent {
	
	constructor(props) {
		super(props);
		this.state = {};
	}


	render() {
		const {row, index, show_stat = true} = this.props
		const btns_list = this.state.btns_list || this.props.f?.btns_list_func?.(row);
		return <>
					{show_stat && <div className="mb-3"><b>{this.props.f?.btns_stat_dict?.(row?.[this.props.f?.btns_stat_param])}</b></div>}
					
					{btns_list?.map((b,i) => <div key={i} style={{marginBottom:'.2em'}}>{this.act_btn(b, row, index, btns_list, i)}</div>)}
			</>
	}
	
	act_btn(b, row, index, btns_list, button_index) {
		const ButtonComponent = this.props.as || Button;
		return b.t=='separator' && <p><Icon name={b.i} size='small' compact='true' />{b.n}</p>
							|| (b.u || b.l || b.a || b.p || b.n || b.f) 
								&& <>
									{!this.state[`cust_stat_${b.n}`] &&
										<ButtonComponent
											content={b.n}
											icon={ this.state['tstts_load'] 
													? 'spinner' 
													: (b.i||'bolt') }
											color={b.c}
											as = {b.u && 'a' || b.l && Link}
											to = {b.l && b.l(row)}
											href = {b.u && b.u(row)}
											target = {b.u && ('statwin')}

											onClick={ 
													async e=>{
														try{
															this.props.onActionInit?.(b);
															await this.props.f?.onClickBtn?.(b,this);
															await (['stat_dd', 'stat_w_msg'].includes(b.cust) && ( _=>{
																	if (!this.state[`cust_${b.n}_loading`]) this.setState({[`cust_stat_${b.n}`]:!this.state[`cust_stat_${b.n}`]}) 
																	else console.log('Already working...') 
																} ) 
															|| b.cf && ( _=>this.setState({[`cnf_${b.n}`]:true}) ) 
															|| ( e=>this.act_btn_run(b, row,e, index) ))(e);
														}
														finally{
															this.props.onActionComplete?.(b);
														}
													}
											}

											loading={this.state['tstts_load'] || this.state[`cust_${b.n}_loading`]}
											labelPosition='left'
											disabled={b.d || (row[this.props.f?.btns_stat_param]==b.n && !b.cust)}
											inverted={row[this.props.f?.btns_stat_param]==b.n}
											compact
											size='mini'
										/>
									}

									{this.state[`cust_stat_${b.n}`] && 
										<>
										<Form>
											{b.cust=='stat_dd' && 
												<Form.Dropdown 
													placeholder='Status'
													selection
													search
													defaultValue={row[this.props.f?.btns_stat_param]}
													//value={this[`cust_${b.n}_value`]} //sucks that this doesnt work and we have to use onchange
													onChange={(e, d)=>this[`cust_${b.n}_value`] = d.value}//save the value for use if the status is set
													loading={this.state[`cust_${b.n}_loading`]}
													options={ this.props.f?.btns_stat_dd_opts.map( s => ({ key: 'md'+s, value: s, text: s }) ) }
													size='tiny'
												 />
											|| b.cust=='stat_w_msg' && 
												<>
													{ b.cann_dd_ops && <Form.Dropdown
														text='Select Message'
														selection
														onChange={(e, m)=>this.setState({[`cust_${b.n}_value_msg`]:m.value})}
														loading={this.state[`cust_${b.n}_loading`]}
														options={ b.cann_dd_ops.map( (m, i) => ({ key: `msg_opt_${i}`, value: m, text: m }) ) }
														size='tiny'
														style={{marginTop: "0.5em"}}
														defaultValue=''
													/> }
													{b.info && <pre className='my-5'>{b.info(row)}</pre>}
													<Form.TextArea 
														placeholder={b.pl_hld || 'Message'}
														defaultValue={b.msg_init?.(row)}
														value={this.state[`cust_${b.n}_value_msg`]}
														onChange={(e, d)=>this.setState({[`cust_${b.n}_value_msg`]:d.value})}
														readOnly={this.state[`cust_${b.n}_loading`]} //When loading dont allow text editing
														size='tiny'
													/>
											</>
										
										}

											{b.alert && (
												<Alert className="mb-4" {...b.alert}>
													{b.alert.contentWithIcon && <WithInfoIcon color={b.alert.iconColor}>{b.alert.contentWithIcon}</WithInfoIcon>}
												</Alert>
											)}

											{b.checkbox && (
												<Checkbox
													style={{ marginBottom: 16 }}
													onChange={() => {
														if(b.checkbox.changeProOnChange) {
															btns_list[button_index] = { ...b, ...b.checkbox.changeProOnChange(!this.state.checkbox) };
														}
														this.setState((prev) => ({ checkbox: !prev.checkbox, ...(b.checkbox.changeProOnChange && { btns_list }) }), () => b.onChange?.(this.state.checkbox))
													}}
													checked={this.state.checkbox != undefined ? this.state.checkbox : b.checkbox.defaultChecked}
													label={b.checkbox.label}
												/>
											)}

											<Form.Group widths={'equal'}>
												<Form.Field>
													<ButtonComponent
														loading={this.state[`cust_${b.n}_loading`]}
														color={'green'}
														size='mini'
														content='Send'
														// icon='warning'
														fluid
														onClick={ async () =>{

																// console.log(this.state[`cust_${b.n}_value_msg`]);

																if (b.cust=='stat_dd' && !this[`cust_${b.n}_value`]) {
																	alert('Please select status first!')
																	return;
																}
																if (b.cust=='stat_w_msg' && !this.state[`cust_${b.n}_value_msg`] && b.msg_req!==false) {
																	alert('Please enter a message first!')
																	return;
																}
																if (b.cust=='stat_w_msg' && b.min_len && this.state[`cust_${b.n}_value_msg`]?.length < b.min_len) {
																	alert(`Message length needs to have at least ${b.min_len} characters`)
																	return;
																}

																if (this.state[`cust_${b.n}_loading`]) {
																	alert('Already working...')
																	return;
																}
																
																this.setState({[`cust_${b.n}_loading`]:true})

																if (b.cust=='stat_dd') {
																	b.a = this[`cust_${b.n}_value`]
																}

																if (b.cust=='stat_w_msg') {
																	if (!b.p)	b.p = {}
																	b.p.msg = this.state[`cust_${b.n}_value_msg`]
																}

																await this.act_btn_run(b, row)

																this.setState({
																		[`cust_${b.n}_loading`]:false,
																		[`cust_stat_${b.n}`]:false
																	})

															} }
													 />
												 </Form.Field>
												 <Form.Field>
												 
													<ButtonComponent
														loading={this.state[`cust_${b.n}_loading`]}
														color={'red'}
														size='mini'
														content='Cancel'
														// icon='x'
														fluid
														onClick={ _=> !this.state[`cust_${b.n}_loading`] ? this.setState({[`cust_stat_${b.n}`]:false}) : console.log('Already working...') }
													 />
												 </Form.Field>
										
											</Form.Group>
										</Form>
													 
										</>
									}

									{b.cf && this.state[`cnf_${b.n}`] && 
										<Confirm
												content={b.cf(b, row)}
												open={!!this.state[`cnf_${b.n}`]}
												onCancel={ _=> this.setState({[`cnf_${b.n}`]:false})}
												onConfirm={ _=> { this.setState({[`cnf_${b.n}`]:false}); this.act_btn_run(b, row); } }
												size='large'
												// confirmButton={this.state[`cnf_${b.n}_ldg`]&&'Loading'}
											/>
									}
								</>
		
	}
	
	async act_btn_run (b, row, e, index) {
	
		this.setState({['tstts_load']:true});

		const { ctrlKey:c=false, altKey:a=false, metaKey:m=false, shiftKey:s=false } = (e || {})

		const x = (() => {
			try {
				// trusted means mouse or keyboard, otherwise programmatic
				const t = typeof e?.isTrusted === 'boolean' ? e.isTrusted : '?';
				// mouse, keyboard, or code?
				const w = t ? (e?.nativeEvent?.clientX||e?.nativeEvent?.clientY) ? 'm' : 'k' : 'c';

				const evt = e?.nativeEvent;
				const x = evt?.clientX;
				const y = evt?.clientY;

				if (!(x || y)) return null;

				// find the relative position of the click within the element (e.g. the middle will be 50%/50%)
				const box = evt.target.getBoundingClientRect();
				const xpct = +((x - box.x) / box.width * 100).toFixed(2)
				const ypct = +((y - box.y) / box.height * 100).toFixed(2)

				return {t, w, x, y, xpct, ypct, n: evt.target.tagName};
			} catch (e) {
				return e?.message || String(e);
			}
		})();

		const mod = {c,a,m,s,x};

		function msg(result) {
			const messages = result.results || [result]
			for (const r of messages) {
				const msg = r.msg || r.error
				msg &&
					app.trigger(app.events.SHOW_MESSAGE, 
							'Action Result', 
							msg,
							r.res=='ok' && 'positive' || 'negative'
						)
			}
		

			
		}
	
		if (!b.u && !b.l && b.f) {
			const result = await b.f(row, index)
			if (result) {
				this.setState(result)
				msg(result)
				
			}
		}
			

		else if (!b.u && !b.l && this.props.f?.act_run) {

			const result = await this.props.f.act_run(b, row,mod)

			// const result = await API_service.load_data(
			// 					'chgStat', 
			// 					{sid: row.sid, stat: b.a, ...b.p}
			// 				) 

			// // console.log('act_btn_run', 'chgStat', {sid: row.sid, stat: b.a, ...b.p}, result);

			// if (b.a!=null) row[this.props.f?.btns_stat_param] = result.to?.status || b.a
			// if (b.p?.is_scr!=null) row.is_script = result.to?.is_script || b.p?.is_scr

			this.setState({
					['tstat'] : b.n,
					['tstts_msg']: result?.res,
					[`cnf_${b.n}`]: false,
				})

			if (result) {
				msg(result)
			}
			
			

		}
		
		this.setState({['tstts_load']:false});

		this.props.onRefreshList?.()

	}
	
	
}


export default ActBtns
