//	------------------------	------------------------	------------------------
//	Description: Helper functions for patho formula type
//  Version: 1.0.0
//  Updates: For testing of formula, please see
//           https://docs.google.com/spreadsheets/d/1P8ygTBEoiXZKc3pAcjzYbek7ufv_eLiJUuUjDhWdJ-8/edit#gid=0
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import moment from 'moment-timezone'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const menopause_q_id = '-MhCcC79Wq3BC3CiHROJ'
const pregnant_q_id = '-MhCcjfDcB2wM35Sayth'

const fsh_bald_patho_id = '2760278b-65ba-4f1b-b076-83acd0e5495b'
const fsh_pcos_patho_id = 'ab978ae4-2d1b-4d50-bfba-a74f9afbc25a'

const menopause_msg_pos = '(for post-menopause female)'
const menopause_msg_neg = '(for pre-menopause female)'

const pregnant_msg_pos = '(for pregnant female)'
const pregnant_msg_neg = '(for non-pregnant female)'

//	------------------------	------------------------	------------------------
//	Export
//	------------------------	------------------------	------------------------

export default {

    why_bald_dhea,
    why_bald_fsh,
    why_bald_lh,
    why_bald_prolactin,
    pcos_fsh,
    pcos_lh,
    pcos_prolactin,
    fert_amh,
}

//	------------------------	------------------------	------------------------
//	Definitions
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//  Bald
//	------------------------	------------------------	------------------------

function why_bald_dhea(params = {}) {

    const { age, value } = params   

    const value_num = Number(value)

    const return_ok = { level: 'ok', msg: 'Normal DHEA level' }
    // const return_low = { level: 'low', msg: 'Your DHEA levels are low. This hormone is produced by the adrenal glands above your kidneys in response to messages from your pituitary gland in your brain. A low level may suggest a malfunctioning in either of these glands and you should see your doctor.' }
    const return_low = { level: 'low', msg: 'Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.' }
    // const return_high = { level: 'high', msg: 'Your DHEA levels are high. This is produced by your adrenal glands and can cause symptoms such as acne, increased muscle mass and hair loss. Please consult your doctor.' }
    const return_high = { level: 'high', msg: 'Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.' }

    let levels = {}

    if (isNaN(value_num)) return { ...return_ok, msg: 'No value' }
    else if (age < 18) return { ...return_ok, msg: 'Age too young for DHEA assessment' }
    else if (age < 20) levels = { low: 3.92, high: 10.66 }
    else if (age < 30) levels = { low: 1.75, high: 10.26 }
    else if (age < 40) levels = { low: 1.22, high: 7.29 }
    else if (age < 50) levels = { low: 0.86, high: 6.48 }
    else if (age < 60) levels = { low: 0.70, high: 5.4 }
    else return { ...return_ok, msg: 'Age too old for DHEA assessment' }

    if (value < levels.low) return { ...levels, ...return_low }
    if (value > levels.high) return { ...levels, ...return_high }

    return { ...levels, ...return_ok }

}

//	------------------------	------------------------	------------------------

function why_bald_fsh(params = {}) {

    const { age, value, extra } = params

    const value_num = Number(value)

    const menopause = extra?.answs?.[menopause_q_id]?.a == 'Yes'

    const menopause_msg = menopause && menopause_msg_pos || menopause_msg_neg

    const return_ok = { level: 'ok', msg: `Normal FSH level ${menopause_msg}` }
    // const return_low = { level: 'low', msg: `Your FSH is quite low ${menopause_msg}. This may be the result of being malnourished, underweight, pregnant or not ovulating. If you are on the contraceptive pill this may also reduce your FSH.` }
    const return_low = { level: 'low', msg: `Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.` }
    // const return_high = { level: 'high', msg: `Your FSH is on the high side ${menopause_msg}. This may be due to menopause but may also be due to a condition known as Polycystic Ovarian Syndrome (PCOS). You should consult your doctor about this.` }
    const return_high = { level: 'high', msg: `Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.` }

    let levels = {}

    if (isNaN(value_num)) return { ...return_ok, msg: 'No value' }
    else if (!menopause) levels = { low: 4.7, high: 21.5 }
    else levels = { low: 25.8, high: 134.8 }

    if (value < levels.low) return { ...levels, ...return_low }
    if (value > levels.high) return { ...levels, ...return_high }

    return { ...levels, ...return_ok }

}

//	------------------------	------------------------	------------------------

function why_bald_lh(params = {}) {

    const { age, value, extra } = params

    const value_num = Number(value)

    const menopause = extra?.answs?.[menopause_q_id]?.a == 'Yes'

    const menopause_msg = menopause && menopause_msg_pos || menopause_msg_neg

    const return_ok = { level: 'ok', msg: `Normal LH level ${menopause_msg}` }
    // const return_ratio = { level: 'high', msg: 'Your LH is more than double your FSH. This is commonly seen in people with Polycystic Ovarian Syndrome. This condition can cause hair loss, acne, facial hair and fatigue. You are advised to see your doctor for further tests.' }
    // const return_low = { level: 'low', msg: `Your LH is on the low side ${menopause_msg}. This may be due to malnutrition, stress or more rarely a pituitary disorder. Please see your doctor.` }
    const return_low = { level: 'low', msg: `Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.` }
    // const return_high = { level: 'high', msg: `Your LH levels are high ${menopause_msg}. This is often associated with Polycystic Ovarian Syndrome, a condition that can cause hair loss in women. Please consult your doctor to find out more about this condition.` }
    const return_high = { level: 'high', msg: `Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.` }

    const patho = extra?.attch && Array.isArray(extra.attch) && extra.attch.find(e => e.type == 'patho') || extra?.patho_results
    const fsh_result = patho?.value && Array.isArray(patho.value) && patho.value.find(e => (e.id == fsh_bald_patho_id) || (e.id == fsh_pcos_patho_id))
    const fsh_level = Number(fsh_result?.result)

    const lh_to_fsh_ratio = fsh_level && !isNaN(fsh_level) && value_num / fsh_level

    let levels = {}

    if (isNaN(value_num)) return { ...return_ok, msg: 'No value' }
    if (isNaN(fsh_level)) return { ...return_ok, msg: 'No FSH value' }
    else if (!menopause) levels = { low: 5, high: 25 }
    else levels = { low: 14.2, high: 52.3 }

    // if (lh_to_fsh_ratio > 2) return { ...levels, ...return_ratio }
    if (value < levels.low) return { ...levels, ...return_low }
    if (value > levels.high) return { ...levels, ...return_high }

    return { ...levels, ...return_ok }

}

//	------------------------	------------------------	------------------------

function why_bald_prolactin(params = {}) {

    const { age, value, extra } = params

    const value_num = Number(value)

    const pregnant = extra?.answs?.[pregnant_q_id]?.a == 'Yes'

    const preg_msg = pregnant && pregnant_msg_pos || pregnant_msg_neg

    const return_ok = { level: 'ok', msg: `Normal Prolactin level ${preg_msg}` }
    // const return_low = { level: 'low', msg: `Your prolactin levels are low ${preg_msg}. Please consult your doctor immediately.` }
    const return_low = { level: 'low', msg: `Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.` }
    // const return_high = { level: 'high', msg: `Your prolactin levels are high ${preg_msg}. This may be the result of pregnancy, a pituitary condition or possible Polycystic Ovarian Syndrome (PCOS). PCOS can cause hair loss. Please see your doctor to investigate further.` }
    const return_high = { level: 'high', msg: `Please request a consultation with an InstantScripts doctor at your earliest convenience to review your blood results.` }

    let levels = {}

    if (isNaN(value_num)) return { ...return_ok, msg: 'No value' }
    else if (!pregnant) levels = { low: 0, high: 24 }
    else levels = { low: 80, high: 400 }

    if (value < levels.low) return { ...levels, ...return_low }
    if (value > levels.high) return { ...levels, ...return_high }

    return { ...levels, ...return_ok }

}

//	------------------------	------------------------	------------------------
//  PCOS
//	------------------------	------------------------	------------------------

function pcos_fsh(params = {}) {

    const why_bald_fsh_res = why_bald_fsh(params) // This is idential to the bald test it seems
    // Customise result here
    return why_bald_fsh_res

}

//	------------------------	------------------------	------------------------

function pcos_lh(params = {}) {

    const why_bald_lh_res = why_bald_lh(params) // This is idential to the bald test it seems
    // Customise result here
    return why_bald_lh_res

}
//	------------------------	------------------------	------------------------

function pcos_prolactin(params = {}) {

    const why_bald_prolactin_res = why_bald_prolactin(params) // This is idential to the bald test it seems
    // Customise result here
    return why_bald_prolactin_res

}

//	------------------------	------------------------	------------------------
//  Fertility
//	------------------------	------------------------	------------------------

function fert_amh(params = {}) { //Andy or Asher need to get back about high\low response\values

    const { age, value } = params   

    const value_num = Number(value)

    const return_ok = { level: 'ok', msg: 'Normal Anti Mullerian Hormone (AMH) level.' }
    const return_low = { level: 'low', msg: 'Your Anti Mullerian Hormone (AMH) levels are low.' }
    //const return_high = { level: 'high', msg: 'Your Anti Mullerian Hormone (AMH) levels are high.' }

    let levels = {}

    if (isNaN(value_num)) return { ...return_ok, msg: 'No value' }
    else if (age < 18) return { ...return_ok, msg: 'Age too young for Anti Mullerian Hormone (AMH) assessment' }
    else if (age < 35) levels = { low: 1.5, high: 4.0 }
    else if (age < 60) levels = { low: 1.5, high: 4.0 }
    else return { ...return_ok, msg: 'Age too old for Anti Mullerian Hormone (AMH) assessment' }

    if (value < levels.low) return { ...levels, ...return_low }
    //if (value > levels.high) return { ...levels, ...return_high }

    return { ...levels, ...return_ok }

}