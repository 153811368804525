import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { excludedCats } from '../../xAppLib/Cat/constants';
import { Container } from 'semantic-ui-react'
import cat_model from '../../models/cat_model';
import getNestedObject from '../../xAppLib/helpers/getNestedObject';
import user_model from '../../models/user_model';
import Badge from 'views/NUI/Badge';
import SIcon from '../NUI/StreamlineIcons/SIcon';

const commonConditions = [
    {
        name: "Asthma",
        img: "man-taking-asthma-inhaler",
        order: 1
    },
    {
        name: "Blood Pressure",
        img: "couple-outside-smiling",
        order: 7
    },
    {
        name: "Cholesterol",
        img: "senior-couple-checking-blood-pressure",
        order: 8
    },
    {
        name: "Contraception",
        img: "contraception-banner-sm",
        order: 6
    },
    {
        name: "Mental Health",
        img: "woman-worried",
        order: 3
    },
    {
        name: "Urinary Tract Infections",
        img: "woman-using-phone-at-kitchen-table",
        order: 5
    },
    {
        name: "Erectile Dysfunction",
        img: "ed-banner-sm",
        order: 9
    },
    {
        name: "Weight Loss",
        img: "healthy-food-heart",
        order: 4
    },
    {
        name: "COVID-19",
        img: "woman-on-couch-using-laptop",
        order: 2
    }
]

const servicesData = [
    {
        lnk: "/c/pharm/DocCons",
        d: "Speak to a doctor from anywhere in Australia.",
        n: "Telehealth Consultations",
        t: "c",
        img: "smartphone-cross-hand"
    },
    {
        lnk: "/c/pharm/certs",
        d: "Request a medical certificate online and have it sent via email.",
        n: "Medical Certificates",
        t: "c",
        img: "med-cert"
    },
    {
        lnk: "/c/pharm/subscr",
        d: "Never run out of your medication again with home delivery.",
        n: "Treatment Plans",
        t: "c",
        img: "doc-sign-pen"
    },
    {
        lnk: "/c/pharm/patho",
        d: "Request a referral for a range of blood tests.",
        n: "Blood Tests",
        t: "c",
        img: "patho-microscope"
    },
    {
        lnk: "/c/pharm/express-specialist-referrals",
        d: "Request a referral to a specialist and have it emailed to you.",
        n: "Specialist Referrals",
        t: "c",
        img: "doc-steth"
    },
    {
        lnk: "/m/pharm/DocCons/DocConsMHCPCopay",
        d: "Have a consultation with a doctor and receive a psychologist referral.",
        n: "Mental Health Care Plans",
        t: "c",
        img: "head-cross"
    }
].filter(Boolean)

export default React.memo(function ({ showCommon, showOther, showServices, tileClicked, exclCond, cmnTitle }) {
    const [data, setData] = useState({
        common: null,
        other: null,
        services: servicesData,
        loading: true
    })

    const fetchData = useCallback(async () => {
        const { cat_tree_list } = await cat_model.get_cat_list({ cat_nm: 'pharm' }, true);
        const catData = getNestedObject(cat_tree_list, '');
        const mappedData = Object.keys(catData).filter(k => user_model.check_access('show_medcat', { k, cat_nm: 'pharm' }) && ![...excludedCats, 'mens', 'womens'].includes(k))
            .map(k => ({ k, ...catData[k] }));
        const cmn = commonConditions.filter((n) => !([exclCond||"COVID-19"]).includes(n.name));

        const [cmnCond, othCond] = mappedData.reduce((res, el) => { 
            const id = cmn.findIndex(f => f.name === el.n);
            res[id >= 0 ? 0 : 1].push({ ...el, img:cmn[id]?.img, o:cmn[id]?.order}); 
            return res; }, [[], []]);
    
        setData({
            ...data,
            common: cmnCond.sort((a, b) => a.o - b.o),
            other: othCond,
            loading: false
        })
    }, [])

    useEffect(() => {
        (showCommon || showOther) ? fetchData() : setData({ loading: false })

    }, [showCommon, showOther]);

    return !data.loading && <Container className="cond-service-tiles">
        {showCommon && data?.common &&
            <div className='my-4 md:my-8'>
                {cmnTitle || <h4 className="head gap-2 mb-6">What we treat</h4>}
                <div className="mb-4 md:mb-8 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-6">
                    {data.common.map((el, i) =>
                        <Link to={`${app.consts.routes.cat_route}/pharm/${el.k}`} className="w-full bg-white rounded-lg" key={el.k}>
                            <div className="flex items-center gap-4 rounded-lg shadow-md min-h-[80px]">
                                <div className="bg-no-repeat bg-center bg-cover rounded-l-lg h-[80px] w-[80px] shrink-0" style={{ backgroundImage: `url(https://storage.googleapis.com/instant-med-public/landing-pages/${el.img}.jpg)` }}></div>
                                <p data-testid='header-tile' className="font-semibold text-base max-w-[60%]">{el.n}</p>
                            </div>
                        </Link>
                    )}
                </div>
            </div>}
        {showOther && data?.other && <div className="my-8">
            <div className="flex gap-4 flex-wrap justify-between md:justify-start">
                {data.other.sort((x, y) => x.n.localeCompare(y.n)).map((el, i) =>
                    <Badge small className='w-[47.5%] md:w-fit py-3 px-6 bg-white shadow-md' key={`${el.k}-${i}`}>
                        <Link to={`${app.consts.routes.cat_route}/pharm/${el.k}`} className="w-full text-center">
                            <p className="text-sm font-semibold">{el.n}</p>
                        </Link>
                    </Badge>
                )}
            </div>
        </div>}
        {showServices && <div className="my-8">
            <h4 className="head gap-2 mb-6">{showCommon && 'What we do' || 'Services we offer'}</h4>
            <div className='cards-list gap-4'>
                {servicesData.map((el, i) =>
                    <div className='bg-white rounded-lg shadow-md' key={el.lnk}>
                        <Link to={el.lnk} className="flex flex-grow p-4 text-gray-800 hover:text-is-blue" onClick={tileClicked}>
                            <div className="bg-is-blue-20 flex items-center justify-center shrink-0 rounded-full h-[56px] w-[56px] mr-4 text-is-blue"><SIcon size="s" name={el.img} bold/></div>
                            <div className='flex flex-grow flex-col' data-testid="header-tile">
                                <div className='flex items-center'>
                                    <p className="font-bold">{el.n}</p>
                                </div>
                                <p className='flex-grow mb-0 mt-2 text-gray-500'>{el.d}</p>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>}
    </Container>
});