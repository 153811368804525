import { initializeApp } from 'firebase/app'

import firebase_conf from 'conf/firebase_conf'


const firebaseApp = initializeApp(firebase_conf)


export default firebaseApp;

export { firebase_conf }
