import {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import API_service from 'xAppLib/providers/API_service';
import docs_model from 'models/docs_model';

export function useDocs(script_type, include_doc_id/*optional. include doc even if not rostered on*/) {
	const [error, setError] = useState(null);
	const [docs,setDocs] = useState(null) 
	const [docs_rost,setDocs_rost] = useState(null) 

	useEffect(  () => {
		let mounted = true;
		const cleanup = { fn: () => {
			// void
		} };

		async function get_docs() {
			const timeMelb = await API_service.load_data( 'timeMelb' )
			const day = timeMelb?.wd || moment().format('dddd');

			app.dvc.time_off = moment(timeMelb?.ts, 'YYMMDDHHmmss').diff( moment(), 'minutes' );

			if (mounted) {
				cleanup.fn = docs_model.watch_roster(day, docs_rost_arr => {
					if (mounted) {
						setDocs_rost(docs_rost_arr);
					}
				});
			}
		}

		if (script_type=='cosm')
			get_docs().catch(setError)

		return () => {
			mounted = false;
			cleanup.fn();
		};
	},[script_type])

	useEffect(() => {
		let mounted = true;
		if (docs_rost) {
			const doc_ids = Object.keys(
				docs_rost.filter(r => r.k === include_doc_id || r.active).reduce((ids, doc) => {
					ids[doc.k] = true;
					return ids;
				}, { [include_doc_id]: true })
			).filter(Boolean);

			docs_model.get_docs_dets(doc_ids).then(docs_arr => {
				if (mounted) {
					setDocs(docs_arr);
				}
			}).catch(setError);
		}
		return () => {
			mounted = false;
		};
	}, [docs_rost, include_doc_id]);

	return {docs, docs_rost, error}
}

export function useRoster(script_type, preferred_doc_id, only_preferred_doc) {
	const {docs, docs_rost, error} = useDocs(script_type, preferred_doc_id);

	const roster = useMemo(() => {
		const check_doc_rost_hours = doc_rost => (
			doc_rost?.active
			&& (!doc_rost.fr || moment(doc_rost.fr, 'HH:mm').isSameOrBefore(moment().add(app.dvc.time_off, 'minutes')))
			&& (!doc_rost.to || moment(doc_rost.to, 'HH:mm').isSameOrAfter(moment().add(app.dvc.time_off, 'minutes')))
		);

		const doc_data_db = docs && preferred_doc_id && only_preferred_doc
			? docs.filter(d => d.k === preferred_doc_id)
			: docs;

		if (!doc_data_db) {
			return null
		}

		return doc_data_db
			.map(doc => ({
				...doc,
				...docs_rost.find(r => doc.k === r.k),
				href: `skype:${doc.skype}?call`
			}))
			.filter(d => preferred_doc_id === d.k || check_doc_rost_hours(d))
			.sort((a, b) => {
				if (preferred_doc_id === a.k || app.dvc?.org?.conf?.doc_rost_pri?.includes(a.m)) {
					return -1;
				}
				if (preferred_doc_id === b.k || app.dvc?.org?.conf?.doc_rost_pri?.includes(b.m)) {
					return 1;
				}
				return 0
			});
	}, [docs, docs_rost, preferred_doc_id, only_preferred_doc]);

	return {roster, error};
}