import React, { Component } from 'react';

import { Icon } from 'semantic-ui-react'

import admin_users_model from 'models/admin_users_model'
import user_model from 'models/user_model'

export default class ShowUserName extends Component {

	constructor(props) {
		super(props);
	}

	state = {
		loaded: false,
	}

	// 		--------------------------------		--------------------------------		---------

	componentDidMount () {
		const { uid } = this.props

		if (uid && uid != '')
			admin_users_model.get_user_byuid( uid, d => !this.donotshow && this.data_loaded(d) )
		else 
			this.setState({loaded:true})

	}

	// 		--------------------------------		--------------------------------		---------

	componentWillUnmount () {
		
		this.donotshow = true;

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	data_loaded(d) {
		const { uid, noemail } = this.props

		// console.log('ShowUserName ::data_loaded', d);

		d && 
		this.setState({
						// loaded: d.res == 'ok',
						loaded: true,
						val: d.u && `${d.u.displayName} - ${!noemail&&(d.u.email||d.u.providerData[0].email)||''} - ${user_model.role_name(d.u.customClaims)}` 
								|| d.res == 'err' && d.err && d.err.message 
								|| `...${d.u}`,
					})

	}

	// 		--------------------------------		--------------------------------		---------

	render() {

		const { u, uid, style, noid=true } = this.props
		const { loaded, val } = this.state

		// console.log('ShowUserName', u, '|||', val);

		return 	loaded && <span style={style}>{val || "None"} {uid && !noid && `(${uid})`}</span> 
						|| <span style={style}>{uid} <Icon loading size='small' name='spinner' /></span>
	}

}
