import React from "react";
import {Icon} from "semantic-ui-react";
import ConferenceTok from "views/UIelems/ConferenceTok";
import Conference from "views/UIelems/Conference";
import instcons_model from "models/instcons_model";


const UserConsult = ({ row, id, onClose, onPeersConnected, onMediaAuthChange, showControls }) => {
	return (
		<div>
				<div className="text-center">
					{/* <Dialog.Title
						as="h3"
						className="text-lg leading-6 font-medium text-gray-900"
					>
						Consultation for {row.spd_data?.first_name} {row.spd_data?.last_name} {row.doc && <>with {row.doc.nm}</> || <>... awaiting doctor</>}
					</Dialog.Title> */}
					<div className="mt-2">
                        {app.settings.use_COSM_INSTCONS_TOK && row.sessionId && <>
							<ConferenceTok key={id}
								showControls={showControls}
								sessionId={row.sessionId}
								autoStart
								name={`${app.user.prof?.first_name} ${app.user.prof?.last_name}`}
								onPeersConnected={onPeersConnected}
							    onMediaAuthChange={onMediaAuthChange}
								onSubmitLog={(label, args) => {
									instcons_model.add_record(`${id}/logs`, {
										...JSON.parse(JSON.stringify(args)), label, tm: Date.now()
									});
								}}
							/> 
						</>
						|| <>
							<Conference key={id} 
								token={id} 
								peersRecord={row.peers || {}}
								autoStart
								name={`${app.user.prof?.first_name} ${app.user.prof?.last_name}`}
								joinHandler={instcons_model.join_handler}
								// onPeerConnected={(peer_id,peers)=>console.log("PEER CONNECTED",peer_id,peers)}
								onPeersConnected={onPeersConnected}
							/>
						</> }
					</div>
				</div>
		</div>
	);
};

export default UserConsult;
