import React, { useState } from 'react';
import { Helmet } from "react-helmet"
import { Container, Form, Segment, Button, Icon } from 'semantic-ui-react'
import UniForm from 'xAppLib/UniForm/UniForm'
import UniFieldSUI from 'xAppLib/UniForm/UniField-SUI';
import API_service from 'xAppLib/providers/API_service'

const CONTACT_FIELDS = [
    {
        name:'first_name',
        label:'First Name*',
    },
    {
        name:'last_name',
        label:'Last Name*',
    },
    {
        name:'phone',
        label:'Mobile Phone Number:',
        valid_not_required: true
    },
    {
        name:'email',
        label:'Email*',
        type:'email',
    },
    {
        name:'details',
        label:'Message*',
        type:'textarea',
    },
    
]
const contact_details = [
    {
        title: 'Email',
        desc: 'assist@instantcosmetics.com.au',
        href:`mailto:assist@instantcosmetics.com.au?subject=${encodeURI('InstantCosmetics Enquiry')}`
    },
]

const Contact = () => {
    const [loading, setLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
    const img_path = "https://storage.googleapis.com/icosm/"

	
	async function submitForm(d) {
        setLoading(true)
        await API_service.api_promise('/icosm/enquiry',d)
		setLoading(false)
		setSubmitted(true)
	}

	return <div className="contact bg-white">

		<Helmet>
			<title>Contact</title>
		</Helmet>

		<Container className="!flex flex-col lg:flex-row gap-[45px] xl:gap-[105px] px-4 pt-[60px] !pb-[88px]">
            <div className="w-full lg:w-1/2 text-left flex flex-col justify-between">
                <div>
                    <h1 className="mb-4 md:pt-[44px]">Like to <br/>know more?</h1>
                    <p className="text-icosm_md md:text-icosm_base">Leave us a message and we’ll get back to you!</p>
                </div>
                <div className="h-[338px] w-full bg-[#D9D9D9] bg-cover mt-8 md:mt-[70px]" style={{backgroundImage:`url('${img_path}2/iStock-1200863698-1.jpg')`}}></div>
            </div>
                
            <Segment className="w-full lg:w-1/2 lg:min-w-[471px] !m-0 !p-0" loading={loading} basic id="icosm-contact">
                {!submitted && <>
               
                <UniForm 
                    onActForm = { submitForm }
                    render_children = {true}
                    fields = { CONTACT_FIELDS	}
                    >
                        {
                            (values, valids, uf_this,fields) => {
                                const args = {values, valids, uf_this}
                                return <Form size="large">
                                    <UniFieldSUI fl={fields.first_name} {...args} />
                                    <UniFieldSUI fl={fields.last_name} {...args} />
                                    <UniFieldSUI fl={fields.phone} {...args} />
                                    <UniFieldSUI fl={fields.email} {...args} />
                                    <div className="w-full details"><UniFieldSUI fl={fields.details} {...args} /></div>

                                    <Button 
                                        size="large" 
                                        type='button'
                                        className='cta cta-tertiary icon'
                                        // style={{backgroundColor: "#D1E0EE"}}
                                        onClick={_=>uf_this.actForm() }
                                        >Submit</Button>
                                </Form>
                            }
                        }
                    </UniForm></>
                    || <><h3 className="text-center">Thank you</h3>
                    <p className="text-center text-icosm_xl font-bold mb-8">We will be in contact</p></>
                }
            </Segment>
		</Container>
	
	</div>
}

export default Contact;
