import { useEffect } from "react";
import instcons_global_model from "../../models/instcons_global_model";

export function useDocConsPtReady(sid) {
	// Want to notify the dr the pt is ready either on the doccons_req page or the doccons_view page,
	// so put logic in a hook that can be used in both places.
	useEffect(() => {
		instcons_global_model.update_record(sid, { pt_ready: true });
		const dc = instcons_global_model.on_disconnect(sid);
		dc.update({ pt_ready: null });
		return () => {
			dc.cancel();
			instcons_global_model.update_record(sid, { pt_ready: null });
		}
	}, [sid]);
}