import React, { Component } from "react";
import { Helmet } from "react-helmet"

import {
	Popup,
	Button,
	Segment,
	Container,
	Tab,
} from "semantic-ui-react";

import { ObjectInspector, chromeLight } from 'react-inspector';
import API_service from 'xAppLib/providers/API_service';
import { DataConnector, ListTable, ListFilters, ListPagination } from 'xAppLib/DataTable';
import user_model from 'models/user_model';
import trans_model, { trans_type, trans_status } from 'models/trans_model';

const WrapComponent = ({ embedded, children }) => {
	return embedded ? <div>{children}</div> :
		<Container className='wide-content' fluid>
			<Helmet>
				<title>Transactions</title>
			</Helmet>

			{children}
		</Container>
}


class TransList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			filters: props.filters || {},
			filterOptions: {},
			trans_filters: props.filters || {},
			pageSize: 50,
			page: 1,
		};
	}

	componentDidMount() {

		let flds;

		if (user_model.check_access('view_all_prod_trans')) {
			flds = [...trans_model.LIST_FLDS_ADMIN, ...trans_model.cust_flds()];
		}
		else if (app.user.org.type == 'supp') {
			flds = trans_model.LIST_FLDS_SUPPLIER;
		}
		else if (app.user.claims.mang) {
			flds = trans_model.LIST_FLDS_BUYER;
		}
		else if (app.user.claims.nurse) {
			flds = trans_model.LIST_FLDS_BUYER;
		}
		else {
			flds = trans_model.LIST_FLDS_BUYER;
		}

		this.setState({
			flds,
		})

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	getData = async (filters, page, pageSize) => {

		const result = await trans_model.load_transactions({
			filters,
			limit: pageSize,
			offset: (page - 1) * pageSize,
			get_stats: true,
			total_count: true,
		})

		if (result) {
			return { data: result.trans, total: result.total };
		}
		return null;
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	onFilterChange = (selection, commit_now = false) => {
		const { filters } = this.state

		const state = {
			filters: !selection ? { ...this.props.filters } : { ...filters, ...selection }
		}
		if (commit_now) {
			state.trans_filters = { ...state.filters };
			state.activeTab = 0;
		}

		this.setState(state);
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	onSearch = () => {
		this.setState({ trans_filters: this.state.filters });
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render() {
		const { page, pageSize, refresh, trans_filters, activeTab } = this.state;

		const { embedded = false } = this.props;

		const filter_fields = [
			{
				label: "Date From",
				name: "date_from",
				type: "date"
			},
			{
				label: "Date To",
				name: "date_to",
				type: "date"
			},
			{
				label: "Buyer",
				name: "buyer",
				type: "text",
				placeholder: "Name / Email"
			},
			{
				label: "Supplier",
				name: "supplier",
				type: "text",
				placeholder: "Name / Email"
			},
		]

		return <WrapComponent embedded={embedded}>
			<h1 className="text-is-blue text-xl">Transactions</h1>

			<DataConnector
				pageSize={pageSize}
				page={page}
				filters={trans_filters}
				refresh={refresh}
				data={this.getData}
			>
				{(data, loading, pages, total, pageSize) => {
					return <React.Fragment>
						<ListFilters popup selected={this.state.filters} onReset={_ => this.onFilterChange(null, true)} start_open={Object.keys(this.state.filters).length}
							btn_search={true}
							onSearch={this.onSearch}
						>
							<Segment.Group horizontal className='!flex-col lg:!flex-row'>
								{filter_fields.map((f, i) => {
									return <Segment className="green" key={i}>
										<h6 className="font-bold mb-2">{f.label}</h6>
										<ListFilters.Filter
											onFilterChange={this.onFilterChange}
											name={f.name}
											value={this.state.filters[f.name]}
											type={f.type}
											fluid
											placeholder={f?.placeholder}
										/>
									</Segment>
								})}
							</Segment.Group>
							<Segment.Group>
								{user_model.check_access('view_all_prod_trans') && !embedded &&
									<Segment color="green">
										<h6 className="font-bold mb-2">Type</h6>
										<Tab
											panes={[
												{ menuItem: 'Show All', show_mode: 'all' },
												{ menuItem: 'Buys only', show_mode: 'buy' },
												{ menuItem: 'Supplier transactions', show_mode: 'supp_ord' },
												{ menuItem: 'Pay Us', show_mode: 'pay_us' },
												{ menuItem: 'Buys BPM', show_mode: 'bpm' },
											]}
											activeIndex={activeTab}
											onTabChange={(e, { activeIndex, panes }) => this.setState({ filters: { ...this.state.filters, type: panes[activeIndex].show_mode }, activeTab: e.target.value })}
											menu={{ color: 'green', attached: false, tabular: false }}
										/>
									</Segment>}
							</Segment.Group>
							<br />
						</ListFilters>

						<ListPagination
							page={page}
							pages={pages}
							pageSize={pageSize}
							loading={loading}
							data={data}
							total={total}
							boundaryRange={3}
							showPageSizeSelector={true}
							onPageSizeChange={pageSize => this.setState({ pageSize })}
							onPageChange={page => this.setState({ page })} />

						<ListTable data={data} loading={loading}>
							{(row, index) => {
								return <React.Fragment>
									<ListTable.Cell row={row} index={index} label='No'>
										{(value, r, index) => (index + 1 + (page - 1) * pageSize)}
									</ListTable.Cell>

									{
										this.state.flds && this.state.flds.map((f, fi) => {
											if (f.jpath == 'status')
												return <ListTable.Cell singleLine row={row} index={index} key={fi} f={f}>
													{(value, row) => (
														trans_status[row.status] || row.status
													)}
												</ListTable.Cell>
											if (f.jpath == 'type')
												return <ListTable.Cell singleLine row={row} index={index} key={fi} f={f}>
													{(value, row) => (
														trans_type[row.type] || row.type
													)}
												</ListTable.Cell>
											return <ListTable.Cell singleLine row={row} index={index} key={fi} f={f} onRefreshList={this.onRefreshList} />
										})
									}

									<ListTable.Cell
										visible={user_model.check_access('view_all_prod_trans') || app.user.org.type == 'supp' || app.user.org?.conf?.prods_sale?.includes('med_cosm') || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')}
										row={row} index={index} label='View PDF'
									>
										{(value, row, index) => (<Button
											content={row.type == 'supp_ord' && 'Supplier Order' || row.type == 'prod_sale' && 'Sale Invoice' || row.type == 'pay_us' && 'Payment Invoice'}
											icon='eye'
											as='a'
											href={API_service.API_BASE_URL + 'trans/' + (row.type == 'supp_ord' && 'viewSuppOrder' || row.type == 'prod_sale' && 'viewSaleInvoice' || row.type == 'pay_us' && 'viewPaymentInvoice') + '/' + row.tid}
											target={'pbs_script' + index}
											compact
											size='mini' />)}
									</ListTable.Cell>

									<ListTable.Cell
										visible={user_model.check_access('view_all_prod_trans')}
										row={row} index={index} label='Full data'
									>
										{(value, row) => (<Popup
											trigger={<Button icon='add' />}
											position='left center'
											on='click'
											style={{ height: '80%', width: '80%', minWidth: 700, overflow: 'scroll' }}
										>
											<ObjectInspector
												data={row}
												expandLevel={2}
												theme={{ ...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 }) }}
											/>
										</Popup>)}
									</ListTable.Cell>

								</React.Fragment>
							}}


						</ListTable>

						<ListPagination
							page={page}
							pages={pages}
							pageSize={pageSize}
							loading={loading}
							data={data}
							total={total}
							boundaryRange={3}
							showPageSizeSelector={true}
							onPageSizeChange={pageSize => this.setState({ pageSize })}
							onPageChange={page => this.setState({ page })} />
					</React.Fragment>
				}}
			</DataConnector>
		</WrapComponent>
	}
}

export default TransList;
