import React from 'react'

import { 
    Container,
    Grid,
    List,
    Icon,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const menu1 = [
  			{
  				name:<>Home</>,
  				link:'/'
  			},	
  			app.settings.iprep_avail && {
  				name:<>Process</>,
  				link:'/#process-overview'
  			},	
  			app.settings.iprep_avail && {
  				name:<>What is PrEP?</>,
  				link:'/#what-is-prep'
  			},
  			app.settings.iprep_avail && {
  				name:<>How much?</>,
  				link:'/#how-much'
  			},	
  			
].filter(Boolean)
const menu2 = [
  			app.settings.iprep_avail && {
  				name:<>Who  are we?</>,
  				link:'/#who-are-we'
				  
  			},	
  			{
  				name:'FAQ',
  				link:'/prep/faq'
  			},
  			{
				name:'Privacy',
				link: "#",
			},
			{
				name: "Customer Terms & Conditions",
				link: "#",
			},
			{
				name: "Platform Terms & Conditions",
				link: "#",
			},
  			{
  				name:'Support',
  				link:'/prep/support'
  			},
].filter(Boolean)

const Footer = ({curr_page}) => {
		return <div className='ui inverted vertical segment' id="site-footer">
			<Container >
				<Grid inverted stackable columns={4}>
					<Grid.Row>
                        <Grid.Column><img src='/iprep/images/iprep-white.svg' alt='HIV Prevent' className='iprep'/></Grid.Column>
                        
						<Grid.Column >
							<List  link inverted >
								{
									menu1.map( ({name,link}, i) => <List.Item
												content={name}
												name={name}
												as={Link}
												to={link} 
												// active={curr_page === link} 
												// onClick={()=>it.tp=='pu' && app.trigger(app.events.SHOW_POPUP, it)}
												style={{cursor:'pointer'}}
												key={'foot_menu_more'+i}
											/>
										)
								}

							</List>
						</Grid.Column>
						<Grid.Column >
							<List  link inverted >
								{
									menu2.map( ({name,link}, i) => <List.Item
												content={name}
												name={name}
												as={Link}
												to={link} 
												// active={curr_page === link} 
												onClick={() => name === 'Privacy' && app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Privacy', pn:'privacy-policy'})
														 || name === 'Customer Terms & Conditions' && app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt", txt:'Customer Terms and Conditions', pn:'customer-terms-and-conditions'})
														 || name === 'Platform Terms & Conditions' && app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt", txt:'Platform Terms and Conditions', pn:'platform-terms-and-conditions'})}
												style={{cursor:'pointer'}}
												key={'foot_menu_more'+i}
											/>
										)
								}

							</List>
						</Grid.Column>
						<Grid.Column  >
                            <p>Powered By</p>
                            <img src='/images/logos/instant-scripts-logo-white2.svg' alt='InstantScripts' className='is'/>
                            </Grid.Column>
						
					</Grid.Row>
                    <Grid.Row>
                    <Grid.Column width={10} verticalAlign='bottom'>
							<p>	© {new Date().getFullYear()} {app.runtime.name}®</p>
						</Grid.Column>
						<Grid.Column width={6} className='version'>
							{app.dvc.org&&<b>{app.dvc.org.name}</b> || null}
							{app.runtime.org&&<i>{app.runtime.org.name}</i> || null}
							Build: {__GIT_HASH__} - {__BUILD__}
							{app.dev_env &&  ' - dev' || app.test_env &&  ' - test' || ''}
							&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
							<Icon
									compact="true"
									color="green"
									name="refresh"
									circular
									onClick={_=>location.reload(true)}
									style={{ cursor: 'pointer' }}
								  />
						</Grid.Column>

                    </Grid.Row>
				</Grid>
			</Container>
		</div>
}
 
export default Footer;