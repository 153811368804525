import React, { useEffect, useState } from 'react';
import { List, Input, Icon, Button, Dropdown, Table, Container, Segment, Header, Form, Checkbox, Card, Image, Label, Loader , Dimmer, Divider } from 'semantic-ui-react'
import { v4 as generate_id } from 'uuid';
import patho_model from 'models/patho_model'
import PharmMedSelector from '../components/PharmMedSelector'
import DisplayField from '../components/DisplayField'
import Meds from '../components/Meds'
import FeedbackText from '../components/FeedbackText'

/**
----
Definition
---
*/
const CulturesDefinition = ({onUpdate,value=CulturesDefinition.defaultDefinition, children}) => {
	const sendUpdate = (name,new_value) => onUpdate({...value,[name]:new_value,id:value.id||generate_id()})
	const handleChange = (e, { name, value }) => sendUpdate(name, value)
	const handleMedChange = ({ target:{name , value }}) => sendUpdate(name, value)
	const handleIndexedChange = (e,{name,part,value : new_value,index}) => {
		const v = [...(value[part]||[])]
		v[index][name] = new_value
		sendUpdate(name,v)
	}
	return <Segment>
		<Form.Group widths='equal'>
			<Form.Input fluid label='Measurement' name='measure' value={value.measure} onChange={handleChange} />
			{children}
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea label='Feedback Good Result' name='feed_good' value={value.feed_good} onChange={handleChange} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea label='Feedback Bad Result' name='feed_bad' value={value.feed_bad} onChange={handleChange} />
		</Form.Group>

		<Segment>
			<p><strong>Organisms Searched</strong></p>
			{(value.organisms||[]).map((o,i)=><Form.Group key={i} widths='equal'>
				<Form.Input fluid label='Name' name='name' part='organisms' index={i} value={o.name} onChange={handleIndexedChange} />
			</Form.Group>)}
			<Form.Group widths='equal'>
				<Button fluid onClick={_=>sendUpdate('organisms',[...(value.organisms||[]),{name:''}])}>Add Organism</Button>
			</Form.Group>
		</Segment>
		
		<Segment>
			<p><strong>Treatments/Antibiotic</strong></p>
			{(value.treatments||[]).map((o,i)=><Segment key={i}><Form.Group  widths='equal'>
				<Form.Input fluid label='Name' name='name' part='treatments' index={i} value={o.name} onChange={handleIndexedChange} />
			</Form.Group>
			<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
				<Form.Field>
					<label>Recommended Meds for Treatment</label>
					
					<PharmMedSelector name='rec_treat' value={o.rec_treat}  onChange={({ target:{name , value }}) => handleIndexedChange(null,{name,part:'treatments',value,index:i})} />
				</Form.Field>
			</Form.Group></Segment>)}
			<Form.Group widths='equal'>
				<Button fluid onClick={_=>sendUpdate('treatments',[...(value.treatments||[]),{name:'',rec_treat:null}])}>Add Treatment</Button>
			</Form.Group>
			
		</Segment>
		
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Pathology certificate disclaimer' name='disclaimer' value={value.disclaimer} onChange={handleChange} />
		</Form.Group>

	</Segment>
}

Object.assign(CulturesDefinition,patho_model.MEASURE_TYPES.cultures)

/**
----
Input
---
*/

const CulturesInput = ({onUpdate,value=CulturesInput.defaultDefinition, children}) => {
	console.log({value});
	const result = value.result || {}
	const { org = Array.from(value.organisms,n=>null) } = result
	const { treat = Array.from(value.treatments,n=>Array.from(value.organisms,n=>null) ) } = result
	const sendResult = (name, new_value) => onUpdate({...value, result: {...result,[name]:new_value} })
	console.log({result,org,treat});
	return <>
		<Form.Group  widths='equal' style={{alignItems:'flex-end'}}>
			<DisplayField fluid label={value.measure} />
		</Form.Group>
		<DisplayField fluid label="Organisms Detected" />
		{(value.organisms||[]).map((o,i)=><Form.Group key={i} widths='equal'>
			
				<Checkbox	
				  checked = {!!org[i]}
				  onChange={ (e, d) => {
					  org[i] = d.checked
					  sendResult('org',[...org])
				  } }
				  defaultIndeterminate={org[i]==undefined||org[i]==null}
				  label={`${o.name}`}
			  />
		</Form.Group>)}
		
		<DisplayField  fluid label="Treatments/Antibiotics" />
		
		<Table celled padded>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell></Table.HeaderCell>
					{(value.organisms||[]).map((o,i)=><Table.HeaderCell key={i}>{o.name}</Table.HeaderCell>)}
				</Table.Row>
			
			</Table.Header>
			<Table.Body>
				{(value.treatments||[]).map((o,i)=><Table.Row key={i}>
					<Table.Cell>{o.name}</Table.Cell>
					{(value.organisms||[]).map((o,j)=><Table.Cell key={j}>
								<Dropdown
									fluid
									selection
									clearable
									disabled={!org[j]}
									options={
										[
											{
											    key: 'S',
											    text: 'S',
											    description: 'Yes/S',
											    value: 'S',
											  },
											{
											    key: 'R',
											    text: 'R',
											    description: 'No/R',
											    value: 'R',
											  },
											{
											    key: 'I',
											    text: 'I',
											    description: 'Intermediate/I',
											    value: 'I',
											  },
										]
									}
									onChange={(e, { value }) => {
										console.log("onChange()",value,i,j);
										treat[i][j] = value
										console.log("=>",treat);
										// handleChange(null,{name:'result', value:{...result, treat:[...treat]}})
										sendResult('treat',[...treat])
									}}
									value={treat[i][j]}
									/>
									
						</Table.Cell>)}
				</Table.Row>)}
			</Table.Body>
		
		</Table>

		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
	   		
		</Form.Group>

	</>
}
Object.assign(CulturesInput,patho_model.MEASURE_TYPES.cultures)


/**
----
View
---
*/
const CulturesView = ({onUpdate,value=CulturesView.defaultDefinition, children, index, onNotify}) => {
	let { measure, result, feed_good, feed_bad, organisms,treatments } = value;
	const { org  } = result
	const { treat  } = result
	
	
	const found_organisms = organisms.filter((o,i)=>org[i])
	
	const danger = found_organisms.length > 0
	
	const recommend = (organisms).map((o,i)=>{
		if (!org[i])
		   return {...o}
	   let solutions = treat.map((t,ti)=>{
		   const r = t[i]
		   return r=='S' ? ti : null
	   }).filter(ti=>ti!==null).map(ti=>treatments[ti])
	   
	   let final = solutions.reduce((all,{rec_treat})=>[...all,...rec_treat],[])
	   final = final.reduce((unique,item)=>unique.includes(item)?unique:[...unique,item],[])
	   return {...o,solutions:final}
	})

	const matches = {}
	for (let r of recommend) {
		if (r.solutions) {
			for (let s of r.solutions) {
				matches[s] = (matches[s]||0) + 1
			}
		}
	}
	const highlights = found_organisms.length > 1 && Object.keys(matches).filter(k=>matches[k]==found_organisms.length) || []
	
	return <div>
		
		<Header>
		 {measure}
	 
		 <Label size='small' color={danger?'red':'green'} horizontal>
			 <Icon name={danger?'warning':'check'} />
			 {danger && "Found" || "Good"}
		 </Label>
		 
		 
	 </Header> 
	 {found_organisms.length > 0 && <>
			<FeedbackText danger>{feed_bad}</FeedbackText>
			
			<p>The following {found_organisms.length > 1 && 'organisms have' || 'organism has' } been found:</p>
			
		 </> || <FeedbackText>{feed_good}</FeedbackText>}
		 
		 {(recommend).map((o,i)=>{
			 if (!o.solutions)
			 	return null
			
			return <div key={i} style={{paddingBottom:'1em'}}>
				 	 <Header color="red">{o.name}</Header>
				 	{o.solutions.length > 0 && <Segment>
							<Meds heading={`Recommended Medicines to treat ${o.name}`} medsList={o.solutions} stars={highlights} />
						</Segment> || <p>We could not find any available treatment for this in our catalog. Please consult one of our doctors for further recommendations.<br/><br/></p>}
			 </div>
		 })}
		 {
			 highlights.length > 0 && <p>
					 <small><Icon name='star' color="green" size="small"/> Highlighted medicines can treat all those conditions</small>
				 </p>
		  }
	</div>

}

Object.assign(CulturesView,patho_model.MEASURE_TYPES.cultures)

export {
	CulturesDefinition,
	CulturesInput,
	CulturesView
}
