import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useAsync } from '../Hooks/useAsync';
import { fetch_prods, IS_PRODUCT } from '../../views/prods/ShopUtil';

const ProdsSelect = React.memo(({ onChange, value, name, fl, fluid = true, multiple = true, clearable = true, placeholder = 'Products' }) => {
	const { data: productsOptions = [] } = useAsync(async () => {
		const prods = (await fetch_prods()) ?? [];
		return prods.reduce((acc, prod) => (IS_PRODUCT(prod) ? [...acc, { key: prod.pid, value: prod.pid, text: prod.name }] : acc), []);
	});

	return (
		<Dropdown
			onChange={(_, { value }) => onChange({ target: { name: name || (fl && fl.name), value } })}
			value={multiple && !value ? [] : value?.include}
			options={productsOptions}
			placeholder={placeholder}
			clearable={clearable}
			multiple={multiple}
			fluid={fluid}
			selection
			search
		/>
	);
}, (prevProps, nextProps) => JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value));

export default ProdsSelect;
