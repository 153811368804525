
	const DEBUG = false;

	let CSV_helper = {}

	CSV_helper.cell2csv = (c, sp=false) => {
		DEBUG && console.log('cell2csv', c && typeof c, c );

		return c && typeof c === 'object' && ('"' + JSON.stringify(c).replace(/\"/g, "'") + '"') 
			|| c && typeof c === 'string' && (sp || c.search('"') >=0 || c.search(',') >=0) && `"${sp&&' '||''}${c}"` 
			|| c && typeof c === 'symbol' && `sym` 
			|| c && typeof c === 'function' && 'f()'
			|| (c != null ? c : '')
	}

	CSV_helper.json2csv = (j, flds=[]) => {
		DEBUG && console.log('json2csv', j);

		if (!j.length)			return;

		let csvStr = '';
		
		// If flds not provided - create from keys from first object.
		if(flds.length === 0){
			Object.keys(j[0]).map( f => flds.push(f) );
		} 
		
		flds.map( f => csvStr += CSV_helper.cell2csv(f)+',' )

		csvStr += "\n";

		j.map( row => {
				flds.map( f => csvStr += CSV_helper.cell2csv(row[f])+',' );
				csvStr += "\n";
			} )

		return csvStr;
	}

	CSV_helper.download = (file, name = 'data') => {
		const link = document.createElement('a');
		link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(file));
		link.setAttribute('download', `${name}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

export default CSV_helper;