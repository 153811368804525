import React, { useEffect, useState, useReducer, useMemo } from 'react';
import API_service from '../providers/API_service';
import { v4 as generate_id } from 'uuid';
import { useDebouncedValue } from '../Hooks';
import { useDisclosure } from '../Hooks/useDisclosure';
import { states } from '../../helpers/address';
import { useProfile } from '../Hooks';
import {
  getFinalAddress,
  reverseGeoCode,
  formatComponents,
} from 'helpers/address';
import { Search, Form, Button, Modal, Grid } from 'semantic-ui-react';
import logger from 'xAppLib/libs/logger';

import UniForm from 'xAppLib/UniForm/UniForm';
import UniFieldSUI from 'xAppLib/UniForm/UniField-SUI';

const DEBUG = false;

const MINIMUM_SEARCH_CHAR = 6;

const reducer = (state, action) => {
  switch (action.type) {
    case 'changeSearchValue':
      return {
        ...state,
        searchValue: action.searchValue,
        hasSelectedResult: false,
      };
    case 'searchAddress':
      return {
        ...state,
        isLoading: true,
        resultsSearchStatus: 'searching',
        results: null,
      };
    case 'searchAddressComplete':
      return {
        ...state,
        isLoading: false,
        results: [
          ...(action.results || []),
          {
            title: (
              <p className="font-bold !text-left">Can't find your address? Click here</p>
            ),
            onClick: () => action.openModal?.(),
          },
        ],
        resultsSearchStatus:
          action.results?.length > 0 ? 'foundResults' : 'noResults',
      };
    case 'removeSearchTerm':
      return {
        ...state,
        results: null,
        searchValue: '',
        isLoading: false,
        resultsSearchStatus: 'waitingToSearch',
      };

    case 'handleResultSelect':
      return {
        ...state,
        results: null,
        searchValue: action.formatted,
        hasSelectedResult: true,
        isLoading: false,
      };

    case 'changeDefaultAddress': 
      return {
        ...state,
        searchValue: action.newAddress?.formatted,
        isLoading: false,
        lastSubmittedAddress: action.newAddress,
      }

    case 'storeLastSubmittedAddress':
      return {
        ...state,
        lastSubmittedAddress: action.newAddressObj,
      };

    default:
      return state;
  }
};

const AddressSearchNew = (props) => {
  const { value, icon, onChange, activeProfInd, showLocate, isPrimaryAddress, setIsPrimaryAddress, field = {} } = props;

  const [profile, profiles] = useProfile()

  const defaultAddress = useMemo(() => value || !field.disable_profile && (profiles?.[activeProfInd] || profile)?.full_address, [activeProfInd])

  const [sessionToken] = useState(() => generate_id());

  const [state, dispatch] = useReducer(reducer, {
    searchValue: defaultAddress?.formatted,
    isLoadingResults: false,
    results: null,
    resultsSearchStatus: 'waitingToSearch', // waitingToSearch, searching, foundResults, noResults
    hasSelectedResult: false,
    lastSubmittedAddress: {},
  });

  const { isOpen, onOpen, onClose } = useDisclosure({idProp: 'shipping-address-modal'});

  const debouncedSearchValue = useDebouncedValue(state.searchValue, 500);

  useEffect(() => {
    if (
      state.searchValue?.length >= MINIMUM_SEARCH_CHAR &&
      !isPrimaryAddress &&
      !state.hasSelectedResult
    ) {
      findPlace();
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    dispatch({type: 'changeDefaultAddress', newAddress: defaultAddress })
    notifyChange(defaultAddress)
    setIsPrimaryAddress?.(true)
  }, [defaultAddress])

  useEffect(() => {
    if (isPrimaryAddress != undefined) {
      notifyChange(isPrimaryAddress ? defaultAddress : {});
      isPrimaryAddress
        ? dispatch({ type: 'changeDefaultAddress', newAddress: defaultAddress }) 
        : dispatch({ type: 'removeSearchTerm' });
    }
  }, [isPrimaryAddress]);

  useEffect(() => {
    if(state.lastSubmittedAddress.formatted && state.searchValue !== state.lastSubmittedAddress.formatted){
      console.log("Clearing")
      notifyChange()
    }
  }, [state.searchValue]);

  const handleResultSelect = async (e, { result }) => {
    logger.usg_log('address_selected', location.pathname, null, { isManual: false });
    DEBUG && console.log('handleResultSelect(%o)', { e, result });
    const { final, data } = await getFinalAddress(
      result.title, null,
      props?.allowLocalArea
    );
    DEBUG && console.log('handleResultSelect output %o', { final, data });
    dispatch({
      type: 'handleResultSelect',
      formatted: final,
      hasSelectedResult: true,
    });
    notifyChange(data);
  };

  const findPlace = async () => {
    DEBUG && console.log('findPlace(%o)', state.searchValue);
    dispatch({ type: 'searchAddress' });
    const addressResponse = await new Promise((resolve, reject) => {
      API_service.call_api(
        'autocomplete',
        {
          address: state.searchValue,
          sessionToken,
          addressOnly: true,
        },
        resolve,
        reject
      );
    });
    DEBUG && console.log('result = %o', addressResponse);
    let results = null;
    if (!addressResponse.error) {
      results = addressResponse.map((r) => ({
        ...r,
        title: r.description,
        description: null,
      }));
    }
    dispatch({
      type: 'searchAddressComplete',
      results,
      openModal: onOpen,
    });
  };

  const getUserLocation = async () => {
    DEBUG && console.log('get_user_location()');
    dispatch({ type: 'searchAddress' });
    const { latitude, longitude } = await new Promise((resolve, _reject) => {
      navigator.geolocation &&
        navigator.geolocation.getCurrentPosition(
          (position) => {
            DEBUG && console.log('got ', position);
            resolve(position.coords);
          },
          (error) => {
            alert('Could not detect your location.');
            resolve({ latitude: null, longitude: null });
          }
        );
    });

    if (latitude) {
      logger.usg_log('get_user_location_FE', location.pathname, null, {
        latitude: latitude,
        longitude: longitude,
      });
      const address = await reverseGeoCode({ latitude, longitude });
      DEBUG && console.log('address=', address);
      const { final, data } = await getFinalAddress(
        address.formatted_address,
        '',
        false,
        address
      );
      if (final) {
        dispatch({
          type: 'handleResultSelect',
          formatted: final,
          hasSelectedResult: true,
        });
        notifyChange(data);
      }
    }
  };

  const handleOnModalSubmit = (data) => {
    logger.usg_log('address_selected', location.pathname, null, { isManual: true });
    // Create data
    const formatted = formatComponents(data);
    notifyChange({ ...data, formatted, geo: null });
    // Clear out data and reset form
    dispatch({ type: 'handleResultSelect', formatted });
  }

  // Should receive {formatted, address, address2, suburb, state, postcode, geo}
  const notifyChange = (data) => {
    const { formatted, address, address2, suburb, state, postcode, geo } =
      Object.assign({}, data);
    const newAddressObj = {
      formatted,
      address,
      address2,
      suburb,
      state,
      postcode,
      geo,
    };
    dispatch({ type: 'storeLastSubmittedAddress', newAddressObj });
    onChange(newAddressObj);
  };

  return (
    <div className="spacer-x-5 flex-column" data-testid="input-address">
      {field.title && <h5>{field.title}</h5>}
      {isPrimaryAddress ? <p>{state.searchValue}</p> : <Search
        fluid
        placeholder={field?.placeholder || 'Please search for your address'}
        input={{
          fluid: true,
          autoComplete: 'false',
          iconPosition: icon && 'left',
        }}
        loading={state.isLoading}
        onResultSelect={handleResultSelect}
        minCharacters={MINIMUM_SEARCH_CHAR}
        onSearchChange={(e, { value }) => {
          dispatch({
            type: 'changeSearchValue',
            searchValue: value,
            notifyChangeFn: notifyChange,
          });
        }}
        results={state.results}
        value={state.searchValue}
        autoComplete="false"
        resultRenderer={({ title }) => <p className='!text-left'>{title}</p>}
        noResultsMessage={'Searching...'}
        onBlur={() =>
          !state.hasSelectedResult && dispatch({ type: 'removeSearchTerm' })
        }
        showNoResults={!state.hasSelectedResult}
      />}
      <ManualShippingAddressModal
        {...{ ...props, isOpen, onOpen, onClose, handleOnModalSubmit }}
      />
      {showLocate && !isPrimaryAddress && (
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '.5rem 0 0 0',
          }}
          inverted
          icon
          loading={false}
          type="button"
          onClick={(_) => getUserLocation()}
        >
          <svg
            className="text-is-blue mr-2"
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.05025 2.05025C4.78392 -0.683417 9.21608 -0.683418 11.9497 2.05025C14.6834 4.78392 14.6834 9.21608 11.9497 11.9497L7 16.8995L2.05025 11.9497C-0.683418 9.21608 -0.683418 4.78392 2.05025 2.05025ZM7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z"
              fill="#0065F2"
            />
          </svg>
          <p className="text-is-blue text-sm">Use my current location</p>
        </Button>
      )}
    </div>
  );
};

export default AddressSearchNew;



const ManualShippingAddressModal = (props) => {
  const { isOpen, onOpen, onClose, handleOnModalSubmit, field = {} } = props;

  const handleSubmit = (data) => {
    handleOnModalSubmit?.(data);
    onClose();
  };

  const fields = [
    {
      name: 'address2',
      label: 'Unit',
      placeholder: 'Unit',
      type: 'text',
      valid_not_required: true,
    },
    {
      name: 'address',
      label: 'Street Address',
      placeholder: 'Eg. 637 Flinders St.',
      type: 'text',
      valid_not_required: field.valid_not_required,
    },
    {
      name: 'suburb',
      label: 'Suburb',
      placeholder: 'Suburb',
      type: 'text',
      valid_not_required: field.valid_not_required,
    },
    {
      name: 'state',
      label: 'State',
      placeholder: 'State',
      type: 'select',
      options: Object.keys(states).map((v) => ({ key: v, value: v, text: v })),
      valid_not_required: field.valid_not_required,
    },
    {
      name: 'postcode',
      label: 'Postcode',
      placeholder: 'Postcode',
      type: 'text',
      pattern: "^[0-8]\\d{3}$",
      max_length: 4,
      valid_not_required: field.valid_not_required,
    },
  ];

  return (
    <Modal onClose={onClose} onOpen={onOpen} open={isOpen} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
      <UniForm
        onActForm={(d) => handleSubmit(d)}
        btn_text="Save"
        className="itm_det_edit"
        render_children={true}
        fields={fields}
      >
        {(values, valids, uf_this, fields) => {
          const args = { values, valids, uf_this, fields };
          return (
            <>
              <Modal.Header>{field.modalHeader || 'Shipping Address'}</Modal.Header>
              <Modal.Content>
                <Form>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={4}
                        computer={4}
                        largeScreen={4}
                        widescreen={4}
                        verticalAlign="bottom"
                      >
                        <UniFieldSUI fl={fields.address2} {...args} />
                      </Grid.Column>
                      <Grid.Column
                        mobile={16}
                        tablet={12}
                        computer={12}
                        largeScreen={12}
                        widescreen={12}
                        verticalAlign="bottom"
                      >
                        <UniFieldSUI fl={fields.address} {...args} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0 }}>
                      <Grid.Column
                        mobile={16}
                        tablet={6}
                        computer={6}
                        largeScreen={6}
                        widescreen={6}
                      >
                        <UniFieldSUI fl={fields.suburb} {...args} />
                      </Grid.Column>
                      <Grid.Column
                        mobile={16}
                        tablet={5}
                        computer={5}
                        largeScreen={5}
                        widescreen={5}
                      >
                        <UniFieldSUI fl={fields.state} {...args} />
                      </Grid.Column>
                      <Grid.Column
                        mobile={16}
                        tablet={5}
                        computer={5}
                        largeScreen={5}
                        widescreen={5}
                      >
                        <UniFieldSUI fl={fields.postcode} {...args} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Modal.Content>
              <Modal.Actions className="flex justify-between">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  content={field.confirm_text || "Confirm Shipping Address"}
                  labelPosition="right"
                  icon="checkmark"
                  onClick={(_) => uf_this.actForm()}
                  positive
                />
              </Modal.Actions>
            </>
          );
        }}
      </UniForm>
    </Modal>
  );
};
