import React from "react";

const img_path = "https://storage.googleapis.com/instant-med-public/BLSClinics/";

const process = [
  {
    title: "Step One",
    text: <>Create an account and request a telehealth consultation <br/>(3 minutes)</>,
  },
  {
    title: "Step Two",
    text: "A doctor will contact you that same day (typically within an hour)",
  },
  {
    title: "Step Three",
    text: "If eligible your medication will be delivered to you via an express courier service",
  },
  {
    title: "Step Four",
    text: "We'll provide a timely reminder before your supply runs out",
  },
];

const promo = <span className="text-base lg:text-[1.15rem] leading-7 text-center block font-bold text-[#1275B4]"><strong>For a limited time only BLS Clinics is offering $50 off the cost of your initial consult.</strong> <br className="hidden md:block"/>Simply use the promotion code <strong>"ACZZWQW"</strong> when booking your initial consultation.</span>

const proposition = [
  {
    image: img_path+"icon-message-blue.svg",
    text: "Easily connect with one of our specialist doctors and explore whether alternative medication can work for you.",
  },
  {
    image: img_path+"icon-map-australia-blue.svg",
    text: "All our doctors are registered and based in Australia.",
  },
  {
    image: img_path+"icon-padlock-blue.svg",
    text: "Our services are registered via Australian Digital Health Agency (ADHA).",
  },
  {
    image: img_path+"icon-delivery-blue.svg",
    text: "Our pharmacies will have your medication express delivered straight to your door.",
  },
]

const eligibilities = [
  {
    image: img_path+"icon-heart-hands-blue.svg",
    text: "All patients complete BLS Clinic’s short digital consultation, which takes only a few minutes. This easy and automated process will help determine if you are eligible for the specialist medical consultation and alternative medications.",
  },
  {
    image: img_path+"icon-message-blue.svg",
    text: "Patients in Australia who are prescribed alternative medication typically must be suffering from a chronic condition and have attempted various other treatment options.",
  },
  {
    image: img_path+"icon-stethoscope-blue.svg",
    text: "To be eligible for a prescription repeat, patients may be requested by their specialist doctor to attend a further consultation to review their healthcare plan and its effectiveness.",
  },
];

const steps = {
  image: img_path+"bls-icon.png",
  step_one: <><span className="font-bold mb-2">Initial consultation<br/>$149<br/></span>Complete a digital questionnaire, consult one of our doctors to see you are eligible they will prescribe the appropriate alternative medication for you.</>,
  step_two: <><strong>Order Prescription<br/>From $75<br/></strong>Once the doctor has issued your script, simply pay for your script. Once paid, your script order will be sent to our Partner Pharmacy where it will be made for you specifically. Expect to receive your prescription within a week from the day the script is received by the pharmacy.</>,
  step_three: <><strong>Follow up<br/>$59<br/></strong>Our doctors will arrange regular follow ups to see how you’re going and continue your treatments.</>,
}

const pricing = [
  {
    title: "Standard Consultation:",
    price: "$149",
    text: "This is a long consultation which takes place at the beginning of your journey. You will generally only require this once and all subsequent consults will be brief and charged at $59.",
  },
  {
    title: "Prescribed Therapies:",
    price: "From $75",
    text: <>The price of the Therapies prescribed from one of our specialist doctors will vary depending on Therapy type, dosage and quantity required. Almost all of the alternative therapies are currently not subsidised through the Therapeutic Goods Administration.<br/>
    <br/>Via our Partner Pharmacy your prescription will be formulated and packed specifically for you.<br/>
    <br/>In addition to your therapy prescription the BLS Clinics doctor may prescribe a specific administering device. If this is the case BLS Clinics normally offer 2-3 different options at different prices for you to choose from.</>,
  },
  {
    title: "Subsequent Follow Up Consultations:",
    price: "$59",
    text: "This is a short follow up consultation once the doctor believes they have prescribed a safe and optimal prescription for your treatment.",
  },
  /*{
    title: "Request a general consultation",
    text: "Want to speak with a specialist before applying for medicinal cannabis?",
    button: "Click Here",
    link: "https://www.instantscripts.com.au/med/DocConsCov",
    external:true
  },*/
];

const support = [
  /*{
    title: "FAQ's",
    text: "Find the answers to some of the most common questions",
    button: "Click Here",
    link: "/faq"
  },*/
  {
    title: "Raise a support ticket",
    text: "Can't find what you're looking for? Engage us here.",
    button: "Click Here",
    onClick: ()=>app.trigger(app.events.SHOW_POPUP, {
      "tp": "pu",
      "pt": "contact",
      "txt": "Contact Us",
      "u": "contact-us",
      "pn": "contact-us",
      "target":"cbls"
  }),
  },
];

const faq_list = [
	{
		title: 'What are "plant based therapies"?',
		content: (
			<p className="icann-content">
				"Plant based" means products naturally derived from plants such as cannabis including cannabinoids such as Cannabidiol (CBD), Tetrahydrocannabinol (THC), Cannabinol (CBN), Cannabigerol (CBG), their acidic forms, terpenes such as limonene, myrcene, beta caryophyllene, and more.
			</p>
		),
	},
  {
		title: 'Is cannabis legal in Australia?',
		content: (
			<p className="icann-content">
				Cannabis is legal in Australia for medical purposes and only available with a prescription from a doctor. Medicinal cannabis is regulated by the Office of Drug Control and the Therapeutic Goods Administration. Sponsors/suppliers/manufacturers of medicinal cannabis products must be licensed to operate.
			</p>
		),
	},
	{
		title: "Who regulates cannabis and cannabis prescriptions in Australia?",
		content:  (
			<p className="icann-content">
				The Therapeutic Goods Administration (TGA), which is run by the Australian Department of Health. More information can be found <a className="underline decoration-1" href="https://www.tga.gov.au/medicinal-cannabis-guidance-documents" target="_blank">here</a>.
			</p>
		),
	},
	{
		title: "How much does medicinal cannabis cost in Australia?",
		content: (
			<p className="icann-content">
				Medicinal cannabis is not subsidised through the PBS and must be purchased through the approved channels. Depending on the type, strength and quantity of the Medicinal Cannabis prescribed prices can vary from $75 to $900.
			</p>
		),
	},
	{
		title: "What type of plant based therapy products might a doctor prescribe?",
		content: (
			<p className="icann-content">
				There are various types of plant based therapies and dose forms that doctors prescribe to treat chronic health conditions. In Australia, plant based therapies come in various forms such as oral mucosal oils, capsules, vapes, capsules, and flower.
			</p>
		),
	},
	{
		title: "Can anybody request access to medicinal cannabis?",
		content: (
			<p className="icann-content">
				Patients that meet the criteria, such as suffering from a chronic health condition, may be eligible for prescription cannabis or CBD. A BLS Clinics doctor will determine whether you are prescribed cannabis and the type of product for you to use.
			</p>
		),
	},

	{
		title: "Where will I collect my prescription?",
		content: (
			<p className="icann-content">
				Our partner pharmacies will express post the prescribed medication to you within a week.
			</p>
		),
	},
	{
		title: "How soon will a doctor contact me after I have completed the medical questionnaire?",
		content: (
			<p className="icann-content">
				Our doctors will endeavour to contact you on the same day as you formally request a consultation.
			</p>
		),
	},
	{
		title: "What is the difference between CBD and THC?",
		content: (
			<><p className="icann-content mb-0">
				CBD, short for Cannabidiol, is the non-psychotropic compound found in the cannabis plant that has therapeutic potential when it interacts with the body. It plays a role in many of the body’s biological responses, including balance in our body’s immune system, communication between cells, appetite, metabolism, memory, sleep and more.<br/><br/>
        THC, short for Tetrahydrocannabinol, is a psychotropic cannabinoid compound with many potential medicinal benefits. THC has strong analgesic, appetite stimulant, antiemetic, and antispasmodic properties. THC is responsible for the euphoric effect as described by patients.<br/><br/>
        More information can be found here:
			</p>
        <ul className="lg:text-[1.25rem]">
          <li><a className="underline decoration-1" href="https://www.tga.gov.au/products/unapproved-therapeutic-goods/medicinal-cannabis-hub/medicinal-cannabis-information-consumers" target="_blank">Medicinal Cannabis Information</a></li>
          <li><a className="underline decoration-1" href="https://www.tga.gov.au/news/blog/consumer-story-caitlin-and-medicinal-cannabis" target="_blank">TGA Consumer Story</a></li>
        </ul>
      </>
		),
	},
];

export { process, proposition, eligibilities, steps, pricing, support, faq_list, promo }