//	------------------------	------------------------	------------------------
//	Description: Missing patho followup
//
// 	Expected props: 
// 		scr_obj - script object
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { Button, Modal, Icon, Form, Accordion } from 'semantic-ui-react'
import moment from 'moment-timezone'

import API_service from 'xAppLib/providers/API_service'
import debounce from 'xAppLib/libs/debounce'

//import UniForm from '../UniForm/UniForm'
//import UniFormSUI from 'xAppLib/UniForm/UniForm-SUI'

const debounce_time = 250

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const patient_fields = [
    { name: 'first_name', label: 'First name', required: true },
    { name: 'last_name', label: 'Last name', required: true },
    { name: 'dob', label: 'Date of birth', required: true },
    { name: 'email', label: 'Email', required: true },
    { name: 'phone', label: 'Phone', required: true },
    { name: 'medicare', label: 'Medicare number', required: false },
    { name: 'lab_id', label: 'Lab ID (Lab/patient ID)', required: false },
]

const pathology_request_fields = [
    { name: 'doc_name', label: 'Doctor name', required: true },
    { name: 'doc_provider', label: 'Doctor provider number', required: true },
    { name: 'tests', label: 'Tests', required: true },
    { name: 'date', label: 'Date requested', required: true },
    { name: 'snum', label: 'Script number', required: true, locked: true, hidden: true },
    { name: 'sid', label: 'Script ID', required: true, locked: true, hidden: true },
]

const pathology_lab_fields = [
    { name: 'id', label: 'Lab ID', required: true, hidden: true },
    { name: 'name', label: 'Name', required: true },
    { name: 'phone', label: 'Phone', required: true },
    { name: 'email', label: 'Email', required: false },
    { name: 'fax', label: 'Fax', required: false },
]

const labs = [
    { name: 'lab_unknown', label: 'Unknown', phone: 'Unknown', email: '', fax: '' },
    { name: 'lab_other', label: 'Other', phone: '', email: '', fax: '' },
    { name: 'lab_4cyte', label: '4cyte', phone: '13 42 98', email: '', fax: '' },
    { name: 'lab_actpath', label: 'ACT Pathology', phone: '02 5124 2930', email: '', fax: '' },
    { name: 'lab_austin', label: 'Austin Pathology', phone: '03 9496 3100', email: '', fax: '' },
    { name: 'lab_acl', label: 'Australian Clinical Labs (ACL)', phone: '1300 134 111', email: '', fax: '' },
    { name: 'lab_capital', label: 'Capital Pathology', phone: '02 6285 9803', email: '', fax: '' },
    { name: 'lab_clinipath', label: 'Clinipath (not Clinpath)', phone: '08 9371 4340', email: '', fax: '' },
    { name: 'lab_clinpath', label: 'Clinpath (not Clinipath)', phone: '08 8366 2111', email: '', fax: '' },
    { name: 'lab_dorevitch', label: 'Dorevitch (DPS)', phone: '03 9244 0472', email: '', fax: '' },
    { name: 'lab_dhm', label: 'Douglass Hanly Moir (DHM)', phone: '1800 555 100', email: '', fax: '' },
    { name: 'lab_hobpath', label: 'Hobart Pathology', phone: '03 6223 1955', email: 'hobartpath@dspl.com.au', fax: '03 6224 1509' },
    { name: 'lab_launpath', label: 'Launceston Pathology', phone: '03 6334 3636', email: 'launcestonpath@dspl.com.au', fax: '03 6334 2273' },
    { name: 'lab_laverty', label: 'Laverty (LPS)', phone: '13 39 36', email: '', fax: '' }, // fax: 02 9770 1051
    { name: 'lab_mps', label: 'Melbourne Pathology (MPS)', phone: '03 9287 7777', email: '', fax: '' },
    { name: 'lab_medlab', label: 'Medlab Pathology', phone: '1300 633 522', email: '', fax: '' },
    { name: 'lab_pathwest', label: 'PathWest', phone: '13 72 84', email: '', fax: '' },
    { name: 'lab_qml', label: 'QML Pathology', phone: '07 3121 4555', email: '', fax: '' },
    { name: 'lab_sapath', label: 'SA Pathology', phone: '08 8222 3000', email: '', fax: '' },
    { name: 'lab_siml', label: 'Southern IML', phone: '02 4224 7474', email: '', fax: '' },
    { name: 'lab_stvin', label: `St Vincent's Pathology`, phone: '03 9231 2888', email: '', fax: '' },
    { name: 'lab_snp', label: 'Sullivan Nicolaides Pathology (SNP)', phone: '1300 778 555 or 07 3377 8555', email: '', fax: '' },
    { name: 'lab_sydpath', label: 'SydPath', phone: '02 8382 9100', email: '', fax: '' },
    { name: 'lab_wdp', label: 'Western Diagnostic Pathology (WDP)', phone: '13 61 99', email: '', fax: '' },
    { name: 'lab_nsw_path_hunter', label: 'NSW Pathology (Hunter)', phone: '02 4921 4000 or 1800 801 949', email: '', fax: '' },
    { name: 'lab_qld_path', label: 'Queensland Pathology', phone: '07 3646 3636', email: '', fax: '' },
    { name: 'lab_mater', label: 'Mater Pathology', phone: '07 3163 7500', email: '', fax: '' },
    { name: 'lab_north_path', label: 'Northern Pathology', phone: '03 8405 8181', email: '', fax: '' },

]

const labs_options = labs.map((e, i) => { return { key: `lab_opt_${i}`, text: `${e.label}`, value: `${e.name}` } })

const acc_style = { padding: '0 10px' }
const in_style = { margin: '0 0 10px 0' }

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default class MissingResults extends Component {

    constructor(props) {

        super(props)

        this.state = {

        }

        this.init({ constructor: true })

    }

    // 		--------------------------------		--------------------------------		---------

    async init(params = {}) { // Decided to go against using state for these largely static vars

        this.debounce_manual_set_state = debounce(this.manual_set_state.bind(this), debounce_time)

        this.script_sid = this.props.scr_obj?.sid
        this.has_script = !!(this.script_sid)

        this.populate_data_from_scr()

        if (!params?.constructor) this.setState({})

    }

    // 		--------------------------------		--------------------------------		---------

    manual_set_state(newState = {}) { this.setState({ newState, tm: moment() }) }

    // 		--------------------------------		--------------------------------		---------

    populate_data_from_scr() {

        this.clear_data()

        this.patient_data = {
            first_name: this?.props?.scr_obj?.spd_data?.first_name,
            last_name: this?.props?.scr_obj?.spd_data?.last_name,
            dob: this?.props?.scr_obj?.spd_data?.dob,
            email: this?.props?.scr_obj?.spd_data?.email,
            phone: this?.props?.scr_obj?.spd_data?.mobile,
            medicare: this?.props?.scr_obj?.spd_data?.medicare,
        }

        this.path_req_data = {
            doc_name: this?.props?.scr_obj?.doc_db_data?.name,
            doc_provider: this?.props?.scr_obj?.doc_db_data?.provider,
            tests: this.get_path_tests(),
            date: moment(this?.props?.scr_obj?.add_dt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYY-MM-DD'),
            snum: this?.props?.scr_obj?.snum,
            sid: this?.props?.scr_obj?.sid,
        }

    }

    // 		--------------------------------		--------------------------------		---------

    clear_data() {

        this.patient_data = patient_fields.reduce((a, c) => { a[c.name] = ''; return a }, {})
        this.path_req_data = pathology_request_fields.reduce((a, c) => { a[c.name] = ''; return a }, {})
        this.path_lab_data = pathology_lab_fields.reduce((a, c) => { a[c.name] = ''; return a }, {})

    }

    // 		--------------------------------		--------------------------------		---------

    get_path_tests() {

        let med = this?.props?.scr_obj?.med_db_data

        const tests = med?.patho_request?.tests?.filter(test => test.selected).map(test => {
            //const new_def = definitions.find(t => t.key == test.key)
            return !test.m2m && test && test.patho_req_txt || test.req_txt
        }) || []

        const req_tests = [...tests, this?.props?.scr_obj?.med?.req_txt].filter(Boolean)

        const req_text = req_tests.reduce((a, c, i) => { return `${a}(${i + 1}) ${c}; ` }, '') || this?.props?.scr_obj?.script_type == 'pthl-needlestick' && 'HIV screen; Hep B and C serology'

        return req_text

    }

    // 		--------------------------------		--------------------------------		---------
    //      Functions
    // 		--------------------------------		--------------------------------		---------

    showSendPathoFollowupTicket() {

        this.setState({ showSendPathoFollowupTicket: true })

    }

    // 		--------------------------------		--------------------------------		---------

    handle_patho_select(params = {}) {

        const value = params?.d?.value

        const lab_data = value && labs.find(e => e.name == value)

        const is_other = !!(lab_data?.name == 'lab_other')

        const new_state = { accordian_additional: is_other, accordian_path_lab: is_other }

        this.path_lab_data = {
            id: lab_data?.name || '',
            name: is_other ? '' : lab_data?.label || '',
            phone: lab_data?.phone || '',
            email: lab_data?.email || '',
            fax: lab_data?.fax || '',
        }

        console.log('bw at lab_data', lab_data, this.path_lab_data)

        this.setState(new_state)

    }

    //	------------------------	------------------------	------------------------

    async send_support_ticket() {

        if (this.state.sending_ticket) console.log('Already sending, please wait...')
        else {
            this.setState({ sending_ticket: true, send_ticket_res: null }, async _ => {

                // Check valids
                let new_state = {}

                let pt_err = false
                const patient_fields_req = patient_fields.filter(e => e.required)
                for (let i = 0; i < patient_fields_req.length; i++) { if (!this.patient_data[patient_fields_req[i].name]) { pt_err = true; break } }
                if (pt_err) new_state = { ...new_state, accordian_additional: true, accordian_patient: true }

                let pr_err = false
                const pathology_request_fields_req = pathology_request_fields.filter(e => e.required)
                for (let i = 0; i < pathology_request_fields_req.length; i++) { if (!this.path_req_data[pathology_request_fields_req[i].name]) { pr_err = true; break } }
                if (pr_err) new_state = { ...new_state, accordian_additional: true, accordian_path_req: true }

                let pl_err = false
                const pathology_lab_fields_req = pathology_lab_fields.filter(e => e.required)
                for (let i = 0; i < pathology_lab_fields_req.length; i++) { if (!this.path_lab_data[pathology_lab_fields_req[i].name]) { pl_err = true; break } }
                if (pl_err) new_state = { ...new_state, accordian_additional: true, accordian_path_lab: true }

                if (Object.keys(new_state).length > 0 || !this.detail) { // Errors

                    new_state = { ...new_state, sending_ticket: false, send_ticket_res: { res: 'err', err: 'Missing information' } }

                } else {

                    const send_params = {
                        detail: this.detail,
                        patient_data: this.patient_data,
                        path_req_data: this.path_req_data,
                        path_lab_data: this.path_lab_data,
                    }

                    console.log('send_params', send_params)

                    const send_ticket_res = await API_service.load_data('pathsup', send_params)
                    console.log('send_ticket_res', send_ticket_res)
                    //const send_ticket_res = { res: 'err', err: 'testing' }

                    new_state = { sending_ticket: false, send_ticket_res }

                }

                this.setState(new_state)

            })
        }

    }

    //	------------------------	------------------------	------------------------

    async handleLock() {

        if (this.state.sending_lock) console.log('Already sending, please wait...')
        else {
            this.setState({ sending_lock: true }, async _ => {

                const is_locked = this?.props?.scr_obj?.meta?.lock_auto_results

                const lock_auto_results = !is_locked

                const params = { sid: this.props.scr_obj.sid, diff: { part: 'meta', to: { lock_auto_results } } }

                const upd_res = await API_service.load_data(
                    'scripts/upd_script',
                    params,
                )

                console.log('upd_res', upd_res)

                if (upd_res && upd_res.res == 'ok'){

                    if (!this.props.scr_obj.meta) this.props.scr_obj.meta = {}
                    this.props.scr_obj.meta.lock_auto_results = lock_auto_results

                }

                this.setState({ sending_lock: false })

            })
        }

    }

    // 		--------------------------------		--------------------------------		---------
    //      UI
    // 		--------------------------------		--------------------------------		---------

    render() {

        const is_locked = this?.props?.scr_obj?.meta?.lock_auto_results

        return 1 &&

            <React.Fragment>

                <Button
                    content={`Missing`}
                    icon='ticket'
                    size='small'
                    color='teal'
                    onClick={_ => this.showSendPathoFollowupTicket()}
                    disabled={!this.has_script}
                />

                <Button
                    content={`eResults`}
                    icon={is_locked ? 'lock' : 'unlock'}
                    size='small'
                    color={is_locked ? 'red' : 'green'}
                    onClick={_ => this.handleLock()}
                    disabled={!this.has_script || this.state.sending_lock}
                    loading={this.state.sending_lock}
                />

                {this.render_send_patho_followup()}

            </React.Fragment>
    }

    //	------------------------	------------------------	------------------------

    render_send_patho_followup() {

        return 1 &&

            <Modal open={!!this.state.showSendPathoFollowupTicket}>
                <Modal.Header><Icon name='ticket' /> Missing results followup</Modal.Header>
                <Modal.Content>

                    <p>Missing results will be followed up by support by phone, fax or email, depending on the pathology lab and provided information.</p>

                    <p>If the pathology lab is unknown, the patient will be contacted to provide the pathology lab details first.</p>

                    <h4>Please enter the required details below and click send</h4>

                    <Form>

                        <Form.Dropdown
                            fluid
                            clearable
                            search
                            selection
                            required
                            placeholder={'Pathology lab name'}
                            label={'Pathology lab name'}
                            options={labs_options}
                            onChange={(e, d) => this.handle_patho_select({ e, d })}
                            error={!this.path_lab_data?.id}
                        />

                        <Form.TextArea
                            defaultValue={this.detail || ''}
                            onChange={(ev, d) => { this.detail = d.value; this.debounce_manual_set_state() }}
                            placeholder={'Detail'}
                            label={'Detail of what is missing'}
                            required
                            error={!this.detail}
                        />

                        <Accordion fluid>

                            <Accordion.Title
                                active={this.state.accordian_additional}
                                onClick={_ => this.setState({ accordian_additional: !this.state.accordian_additional })}
                                content={'Additional information'}
                            />
                            <Accordion.Content active={this.state.accordian_additional}>

                                <Accordion fluid styled>

                                    <Accordion.Title
                                        active={this.state.accordian_patient}
                                        onClick={_ => this.setState({ accordian_patient: !this.state.accordian_patient })}
                                        content={'Patient'}
                                    />
                                    <Accordion.Content active={this.state.accordian_patient} style={acc_style}>

                                        {patient_fields.map((e, i) => {
                                            return 1 &&
                                                <React.Fragment key={`patient_field_${i}`}>
                                                    {!e.hidden && <Form.Input
                                                        //defaultValue={this.patient_data[e.name] || ''}
                                                        value={this.patient_data[e.name]}
                                                        //onChange={(ev, d) => { this.patient_data[e.name] = d.value; this.debounce_manual_set_state() }}
                                                        onChange={(ev, d) => { this.patient_data[e.name] = d.value; this.setState({}) }}
                                                        type={'text'}
                                                        placeholder={e.label}
                                                        label={e.label}
                                                        disabled={e.locked}
                                                        required={e.required}
                                                        error={e.required && !this.patient_data[e.name]}
                                                        fluid
                                                        style={in_style}
                                                    />}
                                                </React.Fragment>
                                        })}

                                    </Accordion.Content>

                                    <Accordion.Title
                                        active={this.state.accordian_path_req}
                                        onClick={_ => this.setState({ accordian_path_req: !this.state.accordian_path_req })}
                                        content={'Pathology request'}
                                    />
                                    <Accordion.Content active={this.state.accordian_path_req} style={acc_style}>

                                        {pathology_request_fields.map((e, i) => {
                                            return 1 &&
                                                <React.Fragment key={`path_req_field_${i}`}>
                                                    {!e.hidden && <Form.Input
                                                        //defaultValue={this.path_req_data[e.name] || ''}
                                                        value={this.path_req_data[e.name]}
                                                        //onChange={(ev, d) => { this.path_req_data[e.name] = d.value; this.debounce_manual_set_state() }}
                                                        onChange={(ev, d) => { this.path_req_data[e.name] = d.value; this.setState({}) }}
                                                        type={'text'}
                                                        placeholder={e.label}
                                                        label={e.label}
                                                        disabled={e.locked}
                                                        required={e.required}
                                                        error={e.required && !this.path_req_data[e.name]}
                                                        fluid
                                                        style={in_style}
                                                    />}
                                                </React.Fragment>
                                        })}

                                    </Accordion.Content>

                                    <Accordion.Title
                                        active={this.state.accordian_path_lab}
                                        onClick={_ => this.setState({ accordian_path_lab: !this.state.accordian_path_lab })}
                                        content={'Pathology lab'}
                                    />
                                    <Accordion.Content active={this.state.accordian_path_lab} style={acc_style}>

                                        {pathology_lab_fields.map((e, i) => {
                                            return 1 &&
                                                <React.Fragment key={`path_lab_field_${i}`}>
                                                    {!e.hidden && <Form.Input
                                                        //defaultValue={this.path_lab_data[e.name] || ''}
                                                        value={this.path_lab_data[e.name]}
                                                        //onChange={(ev, d) => { this.path_lab_data[e.name] = d.value; this.debounce_manual_set_state() }}
                                                        onChange={(ev, d) => { this.path_lab_data[e.name] = d.value; this.setState({}) }}
                                                        type={'text'}
                                                        placeholder={e.label}
                                                        label={e.label}
                                                        disabled={e.locked}
                                                        required={e.required}
                                                        error={e.required && !this.path_lab_data[e.name]}
                                                        fluid
                                                        style={in_style}
                                                    />}
                                                </React.Fragment>
                                        })}

                                    </Accordion.Content>

                                </Accordion>

                            </Accordion.Content>

                        </Accordion>

                        {/* <p>{JSON.stringify(this.props.scr_obj)}</p> */}

                    </Form>

                    {(this.state.send_ticket_res?.res == 'ok') && <h4 style={{ color: 'green' }}>Send successful!</h4>}
                    {(this.state.send_ticket_res?.res == 'err') && <h4 style={{ color: 'red' }}>Send unsuccessful: {this.state.send_ticket_res?.err || 'unknown error'}</h4>}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={_ => this.send_support_ticket()} disabled={this.state.sending_ticket} loading={this.state.sending_ticket}>
                        <Icon name='checkmark' /> Send
                    </Button>
                    <Button color='red' onClick={_ => this.setState({ showSendPathoFollowupTicket: false })} disabled={this.state.sending_ticket}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>
            </Modal>

    }

}