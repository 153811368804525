import React from 'react';
import { Container, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { cls } from '../../NUI/utils';

const IcosmBanner = ({title, desc, img, link, light, img_cls, size}) => {
    const img_path = "https://storage.googleapis.com/icosm/"

    return    <div className={`${light ? "bg-icosm-quaternary text-icosm-primary" : "bg-icosm-secondary text-icosm-primary"} pt-6 md:py-[117px]`}>
                    <Container className={cls(size==="sm" ? "gap-[45px] md:gap-[104px]" : "gap-[45px]", "!flex flex-col md:flex-row !md:px-0")}>
                        <div className={cls("w-full flex flex-col justify-center", size==="sm" ? "w-1/2" : "md:w-2/5")}>
                            <h2 className="text-[44px] leading-[46px]">{title}</h2>
                            <p className="text-icosm_md md:text-icosm_base mb-8 max-w-[311px]">{desc}</p>
                            <Button className={`cta ${light?'cta-primary':'cta-tertiary'} !ml-0 icon`} as={Link} size="large" type="button" to="/join">Sign me up</Button>
                        </div>
                        <div className={cls("w-full", size==="sm" ? "md:w-1/2": "md:w-3/5 xl:w-[800px] md:h-[450px]", "h-[380px] shrink-0 bg-cover bg-center",(img_cls||""))} style={{backgroundImage:`url(${img_path}${img})`}}></div>
                    </Container>
                </div>
}

export default IcosmBanner;
