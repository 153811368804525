import exprms_model from "models/exprms_model";
import { useContext, useMemo } from "react";
import { AppContext } from "xAppLib/context";

export function useAppHistory() {
    const { hist } = useContext(AppContext)
    return hist
}

export function useAppPresence() {
    const { prs } = useContext(AppContext)
    return prs
}

export function useSiteStatus(key) {
    const { site_status } = useContext(AppContext)
    return key ? site_status[key] : site_status
}

export function useAppNotifications() {
    const { notifications } = useContext(AppContext)
    return notifications
}

export function useExperiment(id) {
    const { exprms = {} } = useContext(AppContext)
    return useMemo(()=>{
        const v = exprms[id]
        return v && {
            ...v,
            f : () => exprms_model.impression(id, v)
        }
    },[exprms,id])
    
}

export function useAppContext(key) {
    const ctx = useContext(AppContext)
    return ctx[key]
}

// this will be good later 
export function useApp() {
    return app 
}
