import "core-js/stable";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { init as SentryInit, reactRouterV6Instrumentation } from '@sentry/react';
import org_model from 'models/org_model'  // for some reason, import this here removes a cycling dependency loading issue in webpack
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from "react-router-dom";

import App from "App";
// import Main from "Main";
// import MobileMain from "MobileMain";

const denyHosts = ['localhost:9011','icosm.test:9011','inprep.test:9011', 'cbls.test:9011', 'icann.test:9011', 'cmg.test:9011']

SentryInit( {
				dsn: "https://a2f96a24ba9c4d63bc6ae5d52c6ef323@sentry.io/1286240",
				maxBreadcrumbs: 150,
				release: __RELEASE__,
				// debug:true,
				// tracesSampleRate: 1.0,
				ignoreErrors: [],
				denyUrls: denyHosts.map(h=>`http://${h}`),
				// integrations: [
				// 	new BrowserTracing({
				// 		//tracingOrigins: ['api.instantscripts.com.au'],  // Note: needs to authorise `sentry-trace` header on API first (in Access-Control-Allow-Headers)
				// 		routingInstrumentation: reactRouterV6Instrumentation(
				// 			useEffect,
				// 			useLocation,
				// 			useNavigationType,
				// 			createRoutesFromChildren,
				// 			matchRoutes,
				// 		)
				// 	})
				// ],
				beforeSend(event,hint) {
					if (denyHosts.includes(window.location.host)) 
						return null;
					const code = hint?.originalException?.code 
					/**
					 * Expanding list of non-errors
					 * 
					 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
					 */
					if (code && ['auth/invalid-email','auth/user-disabled','auth/user-not-found','auth/wrong-password','auth/invalid-verification-code','auth/missing-email','auth/expired-action-code','auth/captcha-check-failed','auth/cancelled-popup-request','auth/popup-blocked','auth/user-token-expired','auth/account-exists-with-different-credential','auth/multi-factor-auth-required','auth/invalid-custom-token','auth/invalid-credential','auth/popup-closed-by-user','auth/missing-password'].includes(code))
						return null
						
					return event
				}
			} );
			
import firebaseApp from 'xAppLib/providers/firebase.js';
import { getPerformance } from "firebase/performance";
// const perf = 
getPerformance(firebaseApp);
// console.log('perf', perf);
// const trace = perf.trace('WebAppRuns');
// console.log('trace', trace);
// trace.start();
// console.log('WebAppRuns');
const menu = document.getElementById('main-menu')
menu?.remove()
const content = document.getElementsByClassName('cms-page').item(0)
window.___ = {loc:location.pathname.replace(/\/$/,''),content:content&&content.innerHTML}

ReactDOM.render(
	<App />,
	// app.settings.is_mobile && <MobileMain /> || <App />,
	document.getElementById('app')
);

// trace.stop();
