import React, {useState} from 'react'
import {Button, Image, Loader, Segment} from "semantic-ui-react";
import TakePhoto from "../Photos/TakePhoto";
import UniForm from "../UniForm/UniForm";
import {FileGallery} from "../../views/icosm/treatment/components/FileGallery";
import user_model from "../../models/user_model";
import {useAsync} from "../Hooks/useAsync";
import API_service from "../providers/API_service";
import {rejectOnResErr} from "../../views/icosm/treatment/utils";

const DEBUG = false

const NURSE_PROFILE_FIELDS = [{
    name: "nurse_photo",
    valid_not_required: (val) => !!val,
}]

const NurseProfileSection = ({
    title = "Nurse Profile",
    description = "You will be required to upload a selfie as part of the scripting process, to verify your identity and validate your request. Please upload a clear image of yourself."
}) => {
    const [submittingProfile, setSubmittingProfile] = useState(false);


    const nurse_uid = app.user.uid

    const {data: nurse_profile, loading, error, fn: reload} = useAsync(() =>
        API_service.api_promise(`retrieve_nurse_profile/${nurse_uid}`)
        .then(rejectOnResErr)
        .then(res => res?.nurse_profile || {})
    , [])

    const nursePhoto = nurse_profile?.nurse_photo

    const hasPhoto = !!nursePhoto?.url
    const mode = hasPhoto ? 'view' : 'edit'

    const handleSubmit = async (values) => {
        setSubmittingProfile(true)
        const payload = {
            uid: app.user.uid,
            xtra: {
                nurse_profile: {
                    nurse_photo: values.nurse_photo
                }
            },
        }
        try {
            await user_model.save_prof(payload, (res) => {
                reload()
            })
        } catch (e) {
            console.error(e)
        }
        setSubmittingProfile(false)

    }

    if(loading){
        return <Segment>
            <div className={'flex flex-col justify-center gap-2'}>
                <Loader inline={true} active={true}/>
                <p>Loading nurse profile...</p>
            </div>

        </Segment>
    }

    if (!loading && error){
        return <Segment>
            <div className={'flex flex-col justify-center gap-2'}>
                <p>There was an error loading your nurse profile</p>
                <Button onClick={() => window.location.reload()}>Reload</Button>
            </div>
        </Segment>
    }

    return (<Segment className={'flex flex-col gap-2'}>
        <h3>{title}</h3>
        <h4 className={'!my-0 underline'}>Nurse Photo</h4>
        <p>{description}</p>
        {mode === 'view' && (<>
                    <Image
                        src={nursePhoto?.url}
                        size={'large'}
                    />
            <p className={'mt-2'}>You have uploaded a photo of yourself before, if this photo is incorrect, or out
               of date, please contact support to remove the existing photo.</p>
        </>)}
        {mode === 'edit' && <UniForm
            fields={NURSE_PROFILE_FIELDS}
            onActForm={async (values) => {
                const ans = await app.confirm('Save your photo?', 'Are you sure you want to save this photo? Once saved, you will not be able to change it.');
                if(ans === 'yes'){
                    console.log("Submitting")
                    await handleSubmit(values)
                }
            }}
            render_children={true}
        >
           
            {(values, valids, uf_this) => {
                const hasUploadedPhoto = !!values.nurse_photo
                return (<div className={'flex flex-col space-y-2'}>
                        {!hasUploadedPhoto && <TakePhoto
                            target={'cosm-nurse'}
                            acceptedTypes={[{
                                value: 'image/png', label: 'PNG'
                            }, {
                                value: 'image/jpg', label: 'JPG'
                            }]}
                            enableImageUpload
                            onPhoto={(photo) => uf_this.handleInputChange({
                                target: {
                                    name: 'nurse_photo', value: photo
                                }
                            })}
                            hideComment={true}
                        />}
                        {hasUploadedPhoto && <FileGallery files={[values.nurse_photo]} basic={true} onChange={() => uf_this.handleInputChange({target: {name: 'nurse_photo', value: null}})}/>}
                        {hasUploadedPhoto &&
                            <Button basic onClick={() => uf_this.actForm()} className={'!mt-4'} loading={submittingProfile}>Save image</Button>}
                    </div>

                )
            }}
        </UniForm>}
    </Segment>)
}
export default NurseProfileSection
