
//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

// import moment from 'moment-timezone'

import debounce from 'xAppLib/libs/debounce'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const START_ON_RESET = true

const DEBUG = false

//	------------------------	------------------------	------------------------

const inactivity_watch = function (ln, ci, cw, cr) {

	let inactivity_timer = null;
	let idle = false
	// let idle_st = null;
	// let idle_en = null;

	//	------------------------	------------------------	------------------------

	function reset_timer() {

		inactivity_timer && clearTimeout(inactivity_timer);

		inactivity_timer = setTimeout(start_idle, ln*1000)

		cr?.();

		if (idle) end_idle()
	}

	//	------------------------	------------------------	------------------------

	function start_idle() {

		// idle_st = moment()
		idle = true

		ci?.();

		DEBUG && console.log('inactivity_watch::start_idle', ci);
	}

	//	------------------------	------------------------	------------------------

	function end_idle() {

		// idle_en = moment()
		idle = false

		cw?.();

		DEBUG && console.log('inactivity_watch::end_idle', cw);
	}

	//	------------------------	------------------------	------------------------

	// const resetTimer = reset_timer
	const resetTimer = debounce(reset_timer, 1250)

	// document.addEventListener('mousemove', resetTimer);
	document.addEventListener('keypress', resetTimer);
	// document.addEventListener('load', resetTimer);
	document.addEventListener('mousedown', resetTimer); // touchscreen presses
	document.addEventListener('touchstart', resetTimer);
	document.addEventListener('click', resetTimer);     // touchpad clicks
	document.addEventListener('scroll', resetTimer);    // scrolling with arrow keys
	START_ON_RESET && resetTimer();

	DEBUG && console.log('inactivity_watch::init', ln, ci, cw);


	//	------------------------	------------------------	------------------------

}

export default inactivity_watch