/***
 * Returns a shallow copy of `obj` with the values mapped per `mapFn`
 * Like `Array.prototype.map` but for objects
 * @param {Object} obj
 * @param {objectMapFunction} mapFn
 * @returns {Object}
 * @template T
 * @example
 * obj_map({ x: 1, y: 2, z: 3}, value => value * value)
 * // { x: 1, y: 4, z: 9 }
 * @example
 * obj_map({ age: '42', name: 'Bob'}, (value, key) => key === 'age' ? Number(value) : value)
 * // { age: 42, name: 'Bob' }
 */
export function obj_map(obj, mapFn) {
    let mapped = {};
    for (const key in obj) {
        mapped[key] = mapFn(obj[key], key, obj);
    }
    return mapped;
}

/**
 * Object mapper function. Same param order as the map function in `Array.prototype.map`
 *
 * @callback objectMapFunction<T>
 * @param {obj[T]} value
 * @param {string} key
 * @param {T} obj
 * @return {any}
 * @template T
 */
