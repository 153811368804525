import React from "react";
import { FormField } from "semantic-ui-react";

export const TITLE_INIT = {
    title: "Info Segment",
};

const Title = ({ title, editMode }) =>
    editMode ? (
        <>
            <FormField>
                <label>Title</label>
                <input
                    name="title"
                    placeholder="Enter title"
                    defaultValue={title}
                />
            </FormField>
        </>
    ) : (
        <h2
            className="section-title font-bold md:font-normal"
            id={title?.replace(/\s/g, "").toLowerCase()}
        >
            {title}
        </h2>
    );

export default Title;
