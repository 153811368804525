import React from 'react';
import { Link } from 'react-router-dom';
import { 
    Button,
    Icon,
    Breadcrumb as BreadcrumbSUI
} from 'semantic-ui-react'
import Breadcrumb from 'xAppLib/MenuNav/Breadcrumb'
import cat_model from 'models/cat_model';
import BackButton from 'xAppLib/UIelems/BackButton';


const BreadCrumbs = ({cat_route,cat_nm, med_code, med_data, short}) => {

    const base_path = `${app.consts.routes.cat_route}/${cat_nm}/`

    const parent = cat_route ? base_path + cat_route : '/'
    return <>
            { cat_route && cat_route!='' && !app.settings.iprep && !app.settings.icann && 
                                <React.Fragment>
                                    <Breadcrumb
                                        className='catalog'
                                        nav_base={base_path}
                                        nav_route = {short ? undefined : cat_route}
                                        curr_code = {med_code}
                                        curr_name = {med_data && med_data.name}
                                        root_name={app.settings.icosm ? 'Catalogue' : 'All Conditions'}
                                        db_get = { (l, ca)=>cat_model.watch_record(l, ca) }
                                        // prefix={<BreadcrumbSUI.Section link as={Link} to='/'>Home</BreadcrumbSUI.Section>}
                                    />

                                    <br />
                                </React.Fragment>
                            }
     </>;
}
 
export default BreadCrumbs;