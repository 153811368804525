import { useState, useCallback, useContext, useEffect } from "react";
import user_model from "models/user_model";
import scripts_list_model from "models/scripts_list_model";
import { UserContext } from "xAppLib/context";

export function useUser() {
    const { user } = useContext(UserContext)
    return user
}

export function useProfile() {
    const { profs } = useContext(UserContext)
    return [profs[0],profs]
}


export function useUserPrefs() {
    const user = useUser()
    const { prefs } = useContext(UserContext)
    const setUserPref = useCallback((name,value,save)=>{
        return user_model.save_pref(name,value,save)
    },[user])

    return [prefs,setUserPref]
    
}

export function useUserContext(key) {
    const ctx = useContext(UserContext)
    return ctx[key]
}

// NOte: This doesn't work, don't use
let _active = 0
export function useContextProfile() {
    const user = useUser()
    const [prof, profiles] = useProfile()
    const [activeIndex, setActiveIndex] = useState(_active)
    const [activeProfile, setActiveProfile] = useState(profiles[_active])
    useEffect(()=>{
        _active = 0
        setActiveIndex(_active)
        setActiveProfile(profiles[_active])
    },[user,profiles])
    const setActive = useCallback((n)=>{
        _active = n
        setActiveIndex(_active)
        setActiveProfile(profiles[_active])
    },[setActiveIndex])
    return [activeIndex,setActive,activeProfile]
}
 

export function useUserVerified() {
    const user = useUser()
    const [verified,setVerified] = useState(user.is_email_verified)
	useEffect(_=>{
		setVerified(user.is_email_verified)
	},[user.user_det?.emailVerified ])
	return verified
}

const cache = {}

export function useHistory(params = {}) {

    const user = useUser()
    const [history,setHistory] = useState({data:[],total:0})
    const {pageSize=50,page=1,sid,uid} = params
    
    useEffect(_=>{
        if (!user.user_in)  {
            setHistory({data:[],total:0})
            return
        }
        const cache_key = [user?.uid,pageSize,page,sid,uid].join('_')

        if (cache[cache_key]) {
            // console.log("from cache",cache[cache_key]);
            cache[cache_key].then(result=> setHistory(result))
            return
        }
            
        
        cache[cache_key] = new Promise((resolve)=>{
            scripts_list_model.load_scripts_list(
                {
                    type: 'pts',
                    total_count: true,
                    get_stats: true,
                    limit: pageSize,
                    offset: (page-1) * pageSize,
                    filters : {
                        sid,
                        uid
                    }
                },
                results=>{
                    if (results.res=='ok') {
                        setHistory({data:results.listScripts,total:results.total})
                        resolve({data:results.listScripts,total:results.total})
                    } else {
                        cache[cache_key] = null
                        resolve({data:[],total:0,error:true})
                    }
                }
            )
        })
    },[user,pageSize,page,sid,uid,user?.prof?.pts_scrs])
   
    return [history.data,history.total,history.error]
    
}
