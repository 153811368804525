import {useEffect, useRef} from "react";

/**
 * @description
 * Useful to get a ref of a possibly unstable value such as an unmemoized callback, that is to be used in
 * a hook without needing to declare it as a dependency, which may in turn have unintended consequences.
 * @param {T} value - possibly unstable value
 * @returns {MutableRefObject<T>}
 * @template T
 */
export function useCurrent(value) {
    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref;
}