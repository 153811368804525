import React, { useEffect, useReducer, useState } from 'react';
import LoadingScreen from './LoadingScreen';
import myhr_model from '../../models/myhr_model';
import ErrorScreen from './ErrorScreen';
import { Button, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import keyDepressedListener from '../../xAppLib/Hooks/keyDepressionListener';
import { invalid } from 'moment';
import Alert from '../NUI/Alert';

const defaultState = {
	loading: true,
	loading_what: '',
	access_code: '',
};

const accessReducer = (state, action) => {
	switch (action.type) {
		case 'setLoading':
			return {
				...state,
				loading: action.loading,
				loading_what: action.loading_what,
			};
		case 'updateConfig':
			return {
				...state,
				...action.payload
				// Populates with the response from checkHasAccess
				// will contain {exists: Bool, need_code: Bool, granted: Bool, ok: Bool, err: String}
			};
		case 'updateAccessCode':
			return {
				...state,
				access_code: action.access_code,
				access_code_is_invalid: false,
			};

		case 'invalidAccessCode':
			return {
				...state,
				access_code_is_invalid: true,
			};
		case 'setError':
			return {
				...state,
				err: action.error,
			};

		default:
			return state;
	}
};

const checkHasAccess = async (uid) => {
	return await myhr_model.has_access(uid);
};

const requestAccess = async ({ uid, access_code, emergency, reason }) => {
	return await myhr_model.gain_access({ uid, code: access_code, emergency, reason });
};

const validateAccessCode = (accessCode) => {
	return /^\d{4,8}$/.test(accessCode);
};


const reasons = [
	'This patient’s life, health or safety (e.g. unconscious patient)',
	'Public health or safety (e.g. to identify a serious infection posing a significant risk to the broader population)',
]
  
const Reason = ({onReason}) => {
	const [checked,setChecked]=useState(false);
	return <div>
	<p>Please provide the reason to gain emergency access to this patient's My Health Record.<br/>
	<small>This request will be recorded accordingly.</small></p>
	<p><Checkbox onChange={(e, data) => {onReason(null);setChecked(data.checked)}} label='I believe it is necessary to use emergency access in this patient' /></p>
	{checked && <p>
		It is necessary to use emergency access in order to lessen or prevent a serious threat to:<br/>
		<Dropdown
		placeholder='Reason to access'
		fluid
		selection
		clearable
		className='!h-auto'
		onChange={(e,o)=>onReason(o.value)}
		options={reasons.map(r=>({key:r,value:r,text:r}))}
	/></p>}
</div>
}

const GainAccess = ({ uid, onceGranted }) => {
	const [state, dispatch] = useReducer(accessReducer, defaultState);

	// Emergency Activation
	const emergencyActivationKeySet = ['Shift'];
	const isEmergency = keyDepressedListener(emergencyActivationKeySet);

	const handleGainAccessRequest = async ({ mode }) => {
		const { access_code } = state;
		let reason = null

		if (mode === 'isEmergency') {
			const res = await app.confirm('Request Emergency Access',<Reason onReason={(r)=>reason = r} />,{yes:'Confirm',no:'Cancel'})
			if (res!=='yes' || !reason) 
				return
		}

		mode !== 'restricted' && dispatch({type: 'setLoading', loading: true, loading_what: ' - Requesting access to patient records'})
		let access = {}
		if (mode === 'nonRestricted') {
			access = await requestAccess({uid});
		}
		if (mode === 'restricted') {
			const validAccessCode = validateAccessCode(access_code);
			if (!validAccessCode) {
				dispatch({ type: 'setError', error: 'Access code invalid' });
				return;
			}
			dispatch({type: 'setLoading', loading: true, loading_what: ' - Requesting access to patient records'})
			access = await requestAccess({ uid, access_code });
		}
		if (mode === 'isEmergency') {
			access = await requestAccess({uid, emergency: true, reason});
		}

		if (access?.ok) {
			onceGranted?.();
		} else {
			dispatch({type: 'setLoading', loading: false})
			dispatch({ type: 'setError', error: access?.err || access?.errorMessage || 'An unknown error has occured' });
		}

	};

	useEffect(() => {
		(async () => {
			dispatch({ type: 'setLoading', loading: true, loading_what: 'Access Status' });
			try {
				const accessConfig = await checkHasAccess(uid);
				dispatch({ type: 'updateConfig', payload: accessConfig });
				const { need_code, granted, err } = accessConfig;
				if(err){
					dispatch({ type: 'setLoading', loading: false})
					return 
				}
				if (granted) {
					onceGranted?.();
				} else if (!granted && !need_code) {
					dispatch({ type: 'setLoading', loading: true, loading_what: ' - Requesting access to patient records' });
					const response = await requestAccess({ uid, mode: 'nonRestricted' });
					if(response.ok){
						onceGranted?.();
					} else {
						dispatch({type: 'setLoading', loading: false})
						dispatch({ type: 'updateConfig', payload: {ok: false, err: response?.err || 'An unknown error has occured' }});
					}
				} else if (!granted && need_code) {
					dispatch({ type: 'setLoading', loading: false, loading_what: '' });
				}
			} catch (error) {
				console.log({error});
			}
		})();
	}, []);

	if (state.loading) {
		return <LoadingScreen loadingWhat={state.loading_what} />;
	}

	if (!state.loading && !state.need_code && (state.exists === false || !state.ok || state.err )) {
		const error = state.err || (state.exists === false ? 'This patient does not have a MyHealthRecord' : 'Error when loading patients MyHealthRecord');
		return (
			<div className='mt-6 h-[60vh] flex items-center justify-center'>
				<ErrorScreen errors={[error]} />
			</div>
		);
	}

	if (!state.loading && state.need_code) {
		return (
			<div className='mt-6 h-[60vh] flex flex-col items-center justify-center'>
				<div className={`flex flex-col items-center gap-4 mx-4`}>
					<h3 className='text-bold mb-0'>Access Code Required</h3>
					<span className='text-center'>
						This patient has restricted access to their account. To view MyHR patient records, please request access code from
						patient.
					</span>
					<div className='flex flex-row gap-2'>
						<div className='flex flex-col'>
							<Input
								label='Access Code'
								onChange={(e, { value }) => dispatch({ type: 'updateAccessCode', access_code: value })}
								disabled={isEmergency}
							/>
						</div>
						<Button
							color={isEmergency ? 'red' : 'blue'}
							onClick={async () =>
								await handleGainAccessRequest({
									mode: isEmergency ? 'isEmergency' : 'restricted',
									access_code: state.access_code,
								})
							}
						>
							{isEmergency ? 'Emergency Access' : 'Request Access'}
						</Button>
					</div>
					
					<span className='text-gray-500 mt-2'>
						Hold{' '}
						{emergencyActivationKeySet.map((k, i) => (
							<>
								<kbd
									key={k}
									className='px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg'
								>
									{k}
								</kbd>
								{i < emergencyActivationKeySet.length - 1 && ' + '}
							</>
						))}{' '}
						for Emergency Access
					</span>
					<span className='text-gray-500'>
						Emergency Access should only be used if the situation permits and use will be notified to the practice manager.
					</span>
					{state.err && (
						<Alert error>
							<ErrorScreen message={[state.err]} />
						</Alert>
					)}
				</div>
			</div>
		);
	}

	return <div></div>;
};

export default GainAccess;
