import React from "react";
import { Link } from "react-router-dom";

const RequestButton = ({ link = "request-demo" }) => {
  return (
    <Link to={link} className="request-button">
      <img
        className="inline-block h-5 pb-1"
        src="/ileg/images/bell.svg"
        alt="bell"
      />
      &nbsp; Request a demo
    </Link>
  );
};

export default RequestButton;
