import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TOOLS } from '../editor/ViewerConfig';

const LandingPage = ({slug, title}) => {
    const [data, setData] = useState();

    useEffect(()=>{
        import(`factsheets/${slug}.json`).then(data=>{
            setData({...data})
        })
    },[slug]);

    return (
        <>
            {title && (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}
            {data?.content?.map(block => {
                const El = TOOLS[block.type];
                return El && <El key={block.id} data={block.data} readOnly viewerOnly /> || null;
            })}
        </>)
}
 
export default LandingPage;