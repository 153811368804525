import React, {Component} from "react";
import {Modal, Button, Icon, Container,Message,Form} from "semantic-ui-react";
import UniForm from "../UniForm/UniForm";
import UniFormSUI from "../UniForm/UniForm-SUI";
import support_model from "models/support_model";

class Support extends Component {
	state = {open: false,sending:false,sent_res:null,values:{}};

	open = () => this.setState({open: true,sent_res:null,sending:false});
	close = () => this.setState({open: false});

	render() {
		const {open,sent_res} = this.state;
		return (
			<Modal
				className="support-modal"
				open={open}
				onOpen={this.open}
				onClose={this.close}
				closeIcon
				trigger={
					<Button className="support-button left" compact size_="large" color="orange" icon>
						Support <Icon name="exclamation circle" />
					</Button>
				}
				style={app.settings.is_ionic && {maxHeight:'85vh',overflow:'scroll'} || {}}
			>
				<Modal.Header>
							If you've encountered an unexpected behaviour, please press this 
							&nbsp;
							<Button
									className="support-button" compact _size="large" color="orange" icon="refresh" content="restart"
									onClick={_=>{location.reload(true)}}
								/>
							&nbsp;
							button to restart the app and try again. 
							<br />
							If the problem persists, contact us immediately by using this form.
				</Modal.Header>
				<Modal.Header>Support Request</Modal.Header>
				<Modal.Content>
					
					{sent_res === 'ok' ? this.render_ok() : this.render_form()}
					
					
				</Modal.Content>
				
			</Modal>
		);
	}
	
	render_ok() {
		return <React.Fragment>
					<Message
						positive
						header='Success'
						content='Your support request has been sent'
					  />
				  	<Button fluid color="green" size='large' onClick={this.close} content="Close" />
			</React.Fragment>
	}
	
	render_form() {
		const {sent_res,sending,values} = this.state;
		return <UniForm
			onActForm={ async d => {
						this.setState({sending:true, sent_res:null, error:null})
						const result = await support_model.send_request(d)
						this.setState({ sent_res: result.res, error:result.err, sending:false })
					} }
			render_children={true}
			debug={true}
			fields={support_model.DET_FIELDS}
			// onUpdate={values=>{this.setState({values})}}
			// defaultValues={values}
			defaultValues={{
							name: app.user.user_det?.displayName || '',
							email: app.user.user_det?.email || '',
							phone: app.user.prof?.mobile || '',
						}}
		>
			{(values, valids, uf_this) => {
				return (
					<React.Fragment>
					
						{sent_res == 'err' && <Message
									warning
									header='Problem'
									content='There was a problem sending your request. Please try again.'
								  />}
						<UniFormSUI
							fields={support_model.DET_FIELDS}
							values={values}
							valids={valids}
							uf_this={uf_this}
							loading={sending}
							show="fields_form"
							size={'large'}
							btns={
								<Button fluid color="green" size='large' icon="envelope outline" onClick={d => uf_this.actForm(d)} content="Submit" />
							}
						/>
					
					</React.Fragment>
				);
			}}
		</UniForm>
	}
}

export default Support;
