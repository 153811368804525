import superagent from 'superagent'

import API_service from 'xAppLib/providers/API_service'
import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'support';
const _DET_FIELDS = [
						{
							name: "form",
							type: "hidden",
							value: "support",
						},
						{
							name: "type",
							label:'What type of problem are you having?', 
							type: "select",
							options: [
										{ key: 'lg', value: 'Can\'t login', text: `I cannot login to ${app.runtime.name}` },
										{ key: 'sr', value: 'Can\'t submit script request', text: 'I cannot submit a script request' },
										{ key: 'pb', value: 'Get errors', text: `I got an error while using ${app.runtime.name}` },
										{ key: 'oth', value: 'Other', text: 'Any other issue' },
									],
							placeholder: "To help us assist you with this enquiry, please choose one category.",
							req_label: "*",
						},
						{
							name: "description",
							label:'Description', 
							type: "textarea",
							placeholder: "Please provide as much information as possible about the problem. If you got an error, please include its text.",
							req_label: "*",
						},
						{
							name: "name",
							label:'Your Name', 
							type: "text",
							placeholder: "Please provide a contact name for this request.",
							view_link_to: true,
						},

						{
							name: "email",
							label:'Email Address', 
							type: "email",
							placeholder: "Please provide an email address to get back to you.",
							req_label: "*",
						},

						{
							name: "phone",
							label:'Phone', 
							type: "text",
							placeholder: "If we need to speak to you, please provide the best phone number to contact you.",
							valid_not_required: true,
						},
					];


const _DET_FIELDS_FEEDBACK = [
						{
							name: "form",
							type: "hidden",
							value: "feedback",
						},
						{
							name: "type",
							label:'What do you think about our new app?', 
							type: "select",
							options: [
										{ key: '5', value: 'used already', text: 'Great, I\'ve used it already' },
										{ key: '4', value: 'will use', text: 'Will use it soon' },
										{ key: '3', value: 'not for me', text: 'Good but not for me' },
										{ key: '2', value: 'needs changes', text: 'It needs some changes before I can use it' },
										{ key: '1', value: 'bad', text: 'I don\'t like it and won\'t use it' },
										{ key: 'oth', value: 'Other', text: 'Will write below' },
									],
							placeholder: "To help us assist you with this enquiry, please choose one category.",
							req_label: "*",
						},
						{
							name: "description",
							label:'Description', 
							type: "textarea",
							placeholder: "Please provide as much information as possible. If you got an error, please include its text.",
							req_label: "*",
						},
						{
							name: "name",
							label:'Your Name', 
							type: "text",
							placeholder: "Please provide a contact name for this request.",
							view_link_to: true,
						},

						{
							name: "email",
							label:'Email Address', 
							type: "email",
							placeholder: "Please provide an email address to get back to you.",
							req_label: "*",
						},

						{
							name: "phone",
							label:'Phone', 
							type: "text",
							placeholder: "If we need to speak to you, please provide the best phone number to contact you.",
							valid_not_required: true,
						},
					];
					
const _DET_FIELDS_COMPLICATION = [
						{
							name: "form",
							type: "hidden",
							value: "med_emerg",
						},
						{
							name: "type",
							type: "hidden",
							value: "med_emerg",
						},

						{
							name: "name",
							label:'Your Name', 
							type: "text",
							placeholder: "Please provide a contact name for this request.",
							view_link_to: true,
						},

						{
							name: "phone",
							label:'Phone', 
							type: "text",
							placeholder: "If we need to speak to you, please provide the best phone number to contact you.",
						},
						{
							name: "description",
							label:'Description (Optional)', 
							type: "textarea",
							placeholder: "Provide any relevant details.",
							valid_not_required: true,
						},
					];

const _DET_FIELDS_ACCOUNT_DELETION = [
						{
							name: "form",
							type: "hidden",
							value: "account_del_req",
						},
						{
							name: "description",
							label:'Why would you like to close your account?', 
							type: "select",
							placeholder: "Please provide as much information as possible.",
							options: [
								{ key: '5', value: 'Cost of the service', text: 'Cost of the service' },
								{ key: '4', value: 'Wait time is too long', text: 'Wait time is too long' },
								{ key: '3', value: 'Unable to find what I need', text: 'Unable to find what I need' },
								{ key: '2', value: 'Service no longer required', text: 'Service no longer required' },
								{ key: '1', value: 'Dissatisfied with my experience', text: 'Dissatisfied with my experience' },
								{ key: 'oth', value: 'Other', text: 'Will write below' },
							],
							req_label: "*",
						},
						{
							name: "details",
							label:'Please provide a detailed description of why you would like to close your account.', 
							type: "textarea",
							placeholder: "Please provide as much information as possible.",
							valid_not_required: true,
							no_empty: true,
							validate_function: (value, values) => (values.description !== 'Other' || (values.description === 'Other' && value.trim().length > 10)) || false,
						},
						{
							name: "name",
							label:'Your Name', 
							type: "text",
							placeholder: "Please provide a contact name for this request.",
							view_link_to: true,
							disabled: true
						},

						{
							name: "email",
							label:'Email Address', 
							type: "email",
							placeholder: "Please provide an email address to get back to you.",
							req_label: "*",
							disabled: true
						},

						{
							name: "phone",
							label:'Phone', 
							type: "text",
							placeholder: "If we need to speak to you, please provide the best phone number to contact you.",
							valid_not_required: true,
						},
					];

const _MODEL_NAME = 'support_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class support_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get DET_FIELDS_FEEDBACK() { return _DET_FIELDS_FEEDBACK; }
	static get DET_FIELDS_COMPLICATION() { return _DET_FIELDS_COMPLICATION; }
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get DET_FIELDS_ACCOUNT_DELETION() { return _DET_FIELDS_ACCOUNT_DELETION; }

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

	static async send_request(data) {

		data.dvc = app.dvc
		data.usr = app.user&&app.user.user_in&&app.user.user_det
		data.pg = document.location.href

		console.log('support_model::send_request', data);

		const token = await app.user?.idtoken()

		const response = await superagent
			.post('https://us-central1-safe-scripts.cloudfunctions.net/supportRequest')
			// .post('http://localhost:5001/safe-scripts/us-central1/supportRequest')
			.set('Authorization','Bearer ' + token)
			.type('json')
			.send({...data} || {})
			//.withCredentials()
			// .end( (err, res) => {

			// 				if (err || !res.body) {
			// 					console.log("Error loading response from ", loc, " :: error : ", err, " :: res : ", res);
			// 					ce && ce(res)
			// 					return
			// 				}

			// 			} );


		return response.body
	}

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

	static async send_cosm_med_emerg(data) {

		data.dvc = app.dvc
		data.usr = app.user&&app.user.user_in&&app.user.user_det
		data.pg = document.location.href

		console.log('support_model::send_cosm_med_emerg', data);

		return await API_service.load_data(
									'msgs/send', 
									{msg:'cosm_med_emerg', data},
									// r => ca && ca(r)
								);
	}

}
