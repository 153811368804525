//	------------------------	------------------------	------------------------
//	Description: Displays medications based on server lookup
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'

import { Input, Checkbox, Table, Pagination, Dropdown, Search, Popup, Label, Button, Icon } from 'semantic-ui-react'

import debounce from 'xAppLib/libs/debounce'

import erx_model from '../../models/erx/erx_model'

import {PRESCRIBED_ENUM} from '../../models/erx/resources/erx_enums'

import * as j_f from '../../helpers/json_functions'
import Badge from 'views/NUI/Badge'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const debounce_time =           250 //ms

const table_size_list = [1, 2, 5, 10]

const table_size_options = table_size_list.map(v => {return {key: `drop_${v}`, text: `${v}`, value: v}}) 

const pbs_cols = [
	{label: 'Trade Name',           field: 'ItemTradeName',         searchable: true, orderable: true},
	{label: 'Generic Name',         field: 'ItemGenericName',       searchable: true, orderable: true},
	{label: 'Strength',             field: 'ItemStrength',          searchable: true, orderable: true},
	{label: 'Form',                 field: 'ItemForm',              searchable: false, orderable: true},
	{label: 'Quantity',             field: 'Quantity',              searchable: true, orderable: true},
	{label: 'PBS',                  field: 'PBSCode',               searchable: true, orderable: true},
    {label: 'Manufacturer',         field: 'PBSManufacturerTitle',  searchable: false, orderable: true},
    {label: 'Authority',            field: 'AuthorityType',         searchable: false, orderable: true},
    {label: 'Program',              field: 'ProgramTitle',         searchable: false, orderable: true},
]

let search_index_req = 0, search_index_res = 0, field_req_index = 0, field_res_index = 0 // Used to stop earlier search requests that return later from overriding later search results

const pbs_drug_link_base = 'https://www.pbs.gov.au/medicine/item/'
const pbs_menu_link_base =  'https://www.pbs.gov.au/browse/manufacturer/'

const presc_props = erx_model.PRESCRIBED_ITEM_PROPS

const input_size = 'mini'

const item_defaults = {
    'ItemGenericIntention':         PRESCRIBED_ENUM.GENERIC_INTENTION['Generic'],
    'BrandSubstitutionNotAllowed':  false,
    'UnusualQtyFlag':               false,
    'UnusualDoseFlag':              false,
    'Regulation24':                 false,
    'PrivatePrescription':          false,
    'EmergencySupply':              false,
}

const table_str_trunc = 25

const DEBUG = false

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

class MedicationSelector extends Component {

    constructor (props) {
        super(props)

        const {initialFilters = {}} = this.props;
        const { header_text, field_text } = pbs_cols.filter(f => f.searchable).reduce((acc, f, i) => {
            const value = initialFilters[f.field];
            if (value != null) {
                acc.header_text[`f_i_${i}`]  = value;
                acc.field_text[f.field] = value;
            }
            return acc;
        }, { header_text: {}, field_text: {} });

        this.state = {
            active_page:        1,
            page:               1,
            page_size:          10,
            total_pages:        1,
            total_count:        0,
            ...header_text,
            ...(Object.keys(field_text).length > 0 && { filters: { field_text } }),
        }

        this.debounce_filter = debounce(this.set_filter.bind(this), debounce_time, false)

	}

	//	------------------------	------------------------	------------------------

	componentDidMount () {
        this.state.filters && this.set_filter({});
        DEBUG && this.update_filtered_meds() // autoload some data for testing
	}

    //	------------------------	------------------------	------------------------

    render() {
        return (
            <React.Fragment>
                <Table celled>{/*striped will break the css*/}
                    <Table.Header>
                        <Table.Row>
                            {pbs_cols.filter(c=>c.searchable).map((c, i) => {

                                const c_field_value = this.state[`f_i_${i}`]

                                const clear_but = <a style={{margin: 'auto 0'}} onClick={_ => {this.setState({[`f_i_${i}`]: ''}, _ => this.handle_field_input(c, ''))}}><Icon name='close' color='red'/></a>
                                

                                return <Table.HeaderCell
                                    key={`thc_${i}`}
                                >
                                        <React.Fragment>
                                            <p style={{whiteSpace: 'nowrap'}}>{c.label}</p>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <Search
                                                    //placeholder={c.label}
                                                    onSearchChange={(e, d) => {this.setState({[`f_i_${i}`]: d.value}, _ => this.handle_field_input(c, d.value))}}
                                                    onResultSelect={(e, d) => {this.setState({[`f_i_${i}`]: d.result.title}, _ => this.handle_field_input(c, d.result.title))}}
                                                    results={this.state.field_data && this.state.field_data[c.field]}
                                                    resultRenderer={this.resultRenderer}
                                                    loading={this.state.field_loading}
                                                    minCharacters={0}
                                                    size='mini'
                                                    input={<Input fluid />}
                                                    value={this.state[`f_i_${i}`]}
                                                    fluid
                                                    style={{minWidth: '8em'}}
                                                />
                                                {c_field_value && clear_but}
                                            </div>
                                        </React.Fragment>
                                 
                                </Table.HeaderCell>

                            })}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {this.state.pbs_data && Array.isArray(this.state.pbs_data) && this.state.pbs_data.map((r, i) => {
                                return <Table.Row
                                    key = {`tbr_${i}`}
                                    onClick={_ => this.select_row(r)}
                                    //style={row_style}
                                    className={'select_row'}
                                >
                                    {pbs_cols.filter(c=>c.searchable).map((c, j) => {

                                        let content = ''

                                        if (typeof r[c.field] == 'undefined' || r[c.field] == null) content = '?'
                                        else if (c.field == 'PBSCode') content = <a href={pbs_drug_link_base+r[c.field]} target="_blank">{r[c.field]}</a>
                                        else if (c.field == 'PBSManufacturerTitle'){
                                            const manu_title = j_f.truncate_text(r['PBSManufacturerTitle'] && (`${r['PBSManufacturerTitle']} (${r['PBSManufacturerCode']})`) || r['PBSManufacturerCode'] || '?', table_str_trunc)
                                            
                                            content =   <Popup position='right center' content={<React.Fragment><h4>Manufacturer Title</h4><p>{r['PBSManufacturerTitle'] || '?'}</p><h4>Manufacturer Code</h4><p>{r['PBSManufacturerCode'] || '?'}</p></React.Fragment>} trigger={
                                                            <a href={pbs_menu_link_base+r['PBSManufacturerCode'].toLowerCase()} target="_blank">{manu_title}</a>} />
                                        } 
                                        else content = r[c.field]

                                        return <Table.Cell key={`tbc_${j}`}>
                                            <span>{content}</span>
                                            {j==0 && <>
                                                {pbs_cols.filter(c=>!c.searchable).map((c,k)=><div key={k}><small><b>{c.label}</b> {r[c.field]}</small></div>)}
                                            </>}    
                                        </Table.Cell>

                                    })}

                                </Table.Row>
                            })}

                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={pbs_cols.length}>
                                {`Total results: ${this.state.total_count}`}
                                {`, results per page: `}
                                <Dropdown
                                    placeholder={'Page size'}
                                    //selection
                                    value={this.state.page_size}
                                    options={table_size_options}
                                    onChange={(e, d) => { this.setState({ active_page: 1, page_size: d.value }, _ => { this.update_filtered_meds() }) }}
                                />
                                
                                <Pagination
                                    floated='right'
                                    activePage={this.state.active_page}
                                    totalPages={this.state.total_pages}
                                    firstItem={null}
                                    lastItem={null}
                                    onPageChange={(e, d) => { this.setState({ active_page: d.activePage }, _ => { this.update_filtered_meds() }) }} 
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>

            </React.Fragment>
        )
    }

    resultRenderer = (data) => <React.Fragment>{data.title}</React.Fragment>

    //	------------------------	------------------------	------------------------

    select_row(data) {

        console.log('select row', data)
    
        // Load the values
        const item_raw = JSON.parse(JSON.stringify(item_defaults))

        Object.keys(data).forEach(k => {
            if ((typeof data[k] != 'undefined') && (data[k] != null))
            item_raw[k] = data[k]
        })

        delete item_raw['PBS-DVAAuthorityNumber'] // don't need that and wrong format
        // // Temp - remove the AMT stuff
        // const delete_list = ['AMT_CODE_SYSTEM', 'AMT_CTPP', 'AMT_MP', 'AMT_MPP', 'AMT_MPUU', 'AMT_TP', 'AMT_TPP', 'AMT_TPUU', 'AMT_VERSION']
        // delete_list.forEach(e => item_raw[e] = null)

        // Delete non-needed data
        if (typeof item_raw['_id'] != 'undefined') delete item_raw['_id']
        if (typeof item_raw['total_count'] != 'undefined') delete item_raw['total_count']

        if (typeof this.props.onChange == 'function') this.props.onChange({item: item_raw})
        
    }

    //	------------------------	------------------------	------------------------
    
    async handle_field_input(i, d) {  
             
        const field = i.field

        const new_filter = { filters: {...this.state.filters, field_text: {...(this.state.filters && this.state.filters.field_text), [field]: d}}}

        this.setState({active_page: 1}, this.debounce_filter(new_filter))

    }
    
    //	------------------------	------------------------	------------------------

    async set_filter(options){

        this.setState(options, _ => {
            this.update_filtered_meds()
            this.get_field_auto()
        })

    }

    //	------------------------	------------------------	------------------------

	async update_filtered_meds() {

        search_index_req += 1

        const c_search_index = search_index_req

        const filters = []

        // Text filters
        if (this.state.filters && this.state.filters.field_text) {
            Object.keys(this.state.filters.field_text).forEach(field => {
                const value = this.state.filters.field_text[field]
                if (value != '') filters.push({col: field, val: value, exact: false, case_sense: false})
            })
        }

        const options = {
			page: this.state.active_page,
			page_size: this.state.page_size,
			order_key: 'ItemGenericName',
			order_des: false,
			filters
        }
        
        this.setState({loading_pbs: true}, async _ => {


            const list_res = await (new erx_model()).get_items(options)
            // console.log('list_res')
            // console.log(list_res)
            
			if (c_search_index > search_index_res){
                search_index_res = c_search_index

                const state_update = { pbs_data: list_res.items, loading_pbs: false }

                if (list_res.items && Array.isArray(list_res.items) && list_res.items.length > 0){
                    state_update.total_count = list_res.items[0].total_count
                    state_update.total_pages = Math.ceil(state_update.total_count / this.state.page_size)
                } else {
                    state_update.total_count = 0
                    state_update.total_pages = 1
                }

                this.setState(state_update)

            }

		})

    }

    //	------------------------	------------------------	------------------------

    async get_field_auto() {

        //console.log('Get field')

        try{

            field_req_index += 1
            const c_field_req_index = field_req_index

            const filters = []

            // Text filters
            if (this.state.filters && this.state.filters.field_text) {
                Object.keys(this.state.filters.field_text).forEach(field => {
                    const value = this.state.filters.field_text[field]
                    if (value != '') filters.push({col: field, val: value, exact: false, case_sense: false})
                })
            }

            const options = {
                filters
            }

            this.setState({field_loading: true}, async _ => {

                const list_res = await (new erx_model()).get_fields(options)
                //console.log(list_res)
                
                if (list_res.res == 'ok' && (c_field_req_index > field_res_index)){
                    field_res_index = c_field_req_index
                    const field_data = this.response_to_sorted_search_list(list_res.items)
                    this.setState( {field_data, field_loading: false} )
                }

            })

        }catch(e){
            console.log(`Failed to run get_field_auto: Error: ${e}`, e)
        }

    }

    //	------------------------	------------------------	------------------------

    response_to_sorted_search_list(data){

        let search_data = {}

        if (data){

            Object.keys(data).forEach(k => {
                search_data[k] = []
                data[k].forEach(v => {
                    search_data[k].push({id: `k_${k}_${v}`, title: `${v}`, description: ``})
                })
            })

        }

        return search_data

    }

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default MedicationSelector