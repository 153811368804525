import React, { useMemo } from "react";
import { Form } from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";
import cart_model from "../../../../models/cart_model";
import { pick } from "../../../../helpers/json_functions";

const FIELDS = [
	{
		name: "exprcons_want",
		type: "checkbox",
		label: "Yes",
		options: ['yes', 'no'],
		valid_not_required: true,
	},
	{
		name: "exprcons_agree",
		type: "checkbox",
		label: "I understand that the doctor can only discuss this specific request and is unable to prescribe any other medications or offer other medical advice in this consultation.",
		options: ['yes', 'no'],
		valid_not_required: values => !values.exprcons_want || values.exprcons_agree
	}
]

const getExpressScriptType = (current_script_type) => {
	switch (current_script_type){
		case 'medbuy':
			return 'exprbuy'
		case 'medclick':
			return 'exprclick'
		default:
			'exprcons'
	}
}

export function ExprConsCost({cart}) {
	const regular_price = cart.content().total.value;

	const cost = useMemo(() => {
		const script_type = getExpressScriptType(cart.script_type);
		const mc_pr_maybe = new cart_model({ ...cart, script_type, want_exprcons: true }).MEDCONS_PRICE;
		const mc_pr = typeof mc_pr_maybe === 'number' && isFinite(mc_pr_maybe)
			? mc_pr_maybe
			: typeof mc_pr_maybe === 'string'
				? mc_pr_maybe*1
				/* FIXME there seem to be cases where MEDCONS_PRICE is undefined? Need to figure out what these are */
				: 24;
		return mc_pr.toFixed(0);
	}, [regular_price, cart]);

	return <span>${cost}</span>;
}

const ExprCons = (props) => {
	const {Section, enable_exprcons, store, cart, req_type} = props;

	const type = req_type==='medcert'?'certificate':'medication';

	return (
		<UniFormMed {...props} section="exprcons" fields={FIELDS} onChange={(name, value) => {
			const storeValues = pick(store, FIELDS.map(f => f.name));
			if (name === 'exprcons_want') {
				if (enable_exprcons) {
					return {
						...storeValues,
						exprcons_want: true,
					}
				}
				if (!value) {
					return {
						...storeValues,
						exprcons_agree: false,
						[name]: value,
					}
				}
			}

			return {
				...storeValues,
				[name]: value
			};
		}}>
			{(values, valids, uf_this) => {
				return <Section>
					<Section.Header id="speak-to-a-doctor">
						<span id="speak-to-a-doctor">Speak to a Doctor</span>
					</Section.Header>

					<Section.Content>
						<p>
							Would you like to speak to a doctor about this {type}?
						</p>
						<p>
							You can have a short telehealth consultation with one of our doctors at a cost of <ExprConsCost cart={cart} />.
						</p>
						<p>
							Our doctors can only discuss this specific request to determine eligibility for this {type}.
						</p>

						<Form.Checkbox
							label={'Yes, I would like to speak with a doctor now.'}
							size='massive'

							name={'exprcons_want'}
							checked={store['exprcons_want']}
							onChange={() => uf_this.handleInputChange({
								target: {
									name: 'exprcons_want',
									type: 'checkbox',
									value: values['exprcons_want']
								}
							})}
							error={valids && !valids['exprcons_want']}
						/>

						{store.exprcons_want && (
						<Form.Checkbox
							label={'I understand that the doctor can only discuss this specific request and is unable to prescribe any other medications or offer other medical advice in this consultation.'}
							size='massive'

							name={'exprcons_agree'}
							checked={store['exprcons_agree']}
							onChange={() => uf_this.handleInputChange({
								target: {
									name: 'exprcons_agree',
									type: 'checkbox',
									value: values['exprcons_agree']
								}
							})}
							error={valids && !valids['exprcons_agree']}
						/>
						)}
					</Section.Content>
				</Section>
			}}
		</UniFormMed>
	);
};

export default ExprCons;

