import { useState, useEffect } from 'react';

export default function useWithTime(func, interval = 1000) {
	const [_func, setFunc] = useState(func?.());

	useEffect(() => {
		const _interval = setInterval(() => {
			const newVal = func?.();
			if (_func !== newVal) {
				setFunc(newVal);
			}
		}, interval);

		return () => clearInterval(_interval);
	}, []);

	return _func;
}
