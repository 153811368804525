import { firebase_conf } from './firebase.js';

const DEBUG = false;

import { Facebook } from '@ionic-native/facebook';

import logger from '../libs/logger'
import gtm from 'xAppLib/providers/gtm'

import AppUser from 'models/app_user';
import waitFor from 'xAppLib/libs/waitFor.js';
import API_service from './API_service.js';
import firebase_auth_web from './firebase_auth_web';

function handle_mfa(err) {
	if (err.secondFactors/* === 'auth/multi-factor-auth-required'*/) {
		// if (app.settings.is_ionic && app.settings.is_ionic_ios) {
		// 	alert("Two-factor authentication is not currently supported on iOS mobile app. Please sign in using our web app.")
		// 	return true
		// }
		// var resolver = getMultiFactorResolver(auth, err);
		DEBUG && console.log("Got MFA err",err);
		const resolver = {
			multiFactorHint: 0,
			hints:err.secondFactors,
			session: null
		}
		return app.mfa_confirm(resolver)
	  } else {
		return false
	  }
	
}

function facebook_logout() {
	Facebook.getLoginStatus()
			.then(res => {
				res.status === 'connected' 
				&& Facebook.api(`/${res.authResponse.userID}/permissions`, [] , 'DELETE')
							.then(res => {
									console.log('Revoking Facebook Login', res)
									Facebook.logout()
											.then(res => console.log('Logged out Facebook!', res))
											.catch(e => console.log('Error logout from Facebook', e))
							}).catch(e => console.log('Error revoking', e))
			}).catch(e => console.log(e))
}

function makeAsync(fn, resolveFirst = false) {
	return (...theArgs) => {
		return new Promise((resolve,reject)=>{
			const error = (error, secondFactors)=>{
				DEBUG && console.log("Got Firebase Plugin error",error, secondFactors)
				const e = new Error(error)
				e.secondFactors = secondFactors
				reject(e)
			}
			fn.apply(null,resolveFirst ? [resolve,error,...theArgs]:[...theArgs,resolve,error])
		})
	}
}

// It's 2022 already
let getCurrentUserAsync, getClaimsAsync, isUserSignedInAsync, 
	signInUserWithEmailAndPasswordAsync,verifySecondAuthFactorAsync,
	listEnrolledSecondAuthFactorsAsync, signOutUserAsync,
	signInUserWithCustomTokenAsync, unenrollSecondAuthFactorAsync,
	enrollSecondAuthFactorAsync,reloadCurrentUserAsync, updateUserPasswordAsync,
	sendUserPasswordResetEmailAsync, authenticateUserWithGoogleAsync,
	signInWithCredentialAsync, authenticateUserWithAppleAsync,
	sendUserEmailVerificationAsync, authenticateUserWithFacebookAsync

function initAsync() {
	getCurrentUserAsync = makeAsync(FirebasePlugin.getCurrentUser.bind(FirebasePlugin))
	getClaimsAsync = makeAsync(FirebasePlugin.getClaims.bind(FirebasePlugin))
	isUserSignedInAsync = makeAsync(FirebasePlugin.isUserSignedIn.bind(FirebasePlugin))
	signInUserWithEmailAndPasswordAsync = makeAsync(FirebasePlugin.signInUserWithEmailAndPassword.bind(FirebasePlugin))
	verifySecondAuthFactorAsync = makeAsync(FirebasePlugin.verifySecondAuthFactor.bind(FirebasePlugin),true)
	listEnrolledSecondAuthFactorsAsync = makeAsync(FirebasePlugin.listEnrolledSecondAuthFactors.bind(FirebasePlugin))
	signOutUserAsync = makeAsync(FirebasePlugin.signOutUser.bind(FirebasePlugin))
	signInUserWithCustomTokenAsync = makeAsync(FirebasePlugin.signInUserWithCustomToken.bind(FirebasePlugin))
	unenrollSecondAuthFactorAsync = makeAsync(FirebasePlugin.unenrollSecondAuthFactor.bind(FirebasePlugin),true)
	enrollSecondAuthFactorAsync = makeAsync(FirebasePlugin.enrollSecondAuthFactor.bind(FirebasePlugin),true)
	reloadCurrentUserAsync = makeAsync(FirebasePlugin.reloadCurrentUser.bind(FirebasePlugin))
	updateUserPasswordAsync = makeAsync(FirebasePlugin.updateUserPassword.bind(FirebasePlugin))
	sendUserPasswordResetEmailAsync = makeAsync(FirebasePlugin.sendUserPasswordResetEmail.bind(FirebasePlugin))
	authenticateUserWithGoogleAsync = makeAsync(FirebasePlugin.authenticateUserWithGoogle.bind(FirebasePlugin))
	signInWithCredentialAsync = makeAsync(FirebasePlugin.signInWithCredential.bind(FirebasePlugin))
	authenticateUserWithAppleAsync = makeAsync(FirebasePlugin.authenticateUserWithApple.bind(FirebasePlugin))
	sendUserEmailVerificationAsync = makeAsync(FirebasePlugin.sendUserEmailVerification.bind(FirebasePlugin))
	authenticateUserWithFacebookAsync = makeAsync(FirebasePlugin.authenticateUserWithFacebook.bind(FirebasePlugin))
}

const extractFbxErrCode = (msg) => {
	const code = msg.substring(msg.indexOf("Code=")+5).split(" ", 1).toString()
	return code
}

const fbxCodesToErrMsg = {
	"17001": "Invalid email or password.", //"There is no user record corresponding to this identifier. The user may have been deleted."
	"17005": "The user account has been disabled.",
	"17008": "Email is invalid.",
	"17009": "Invalid email or password", // "The password is invalid or the user does not have a password."
	"17010": "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
	"17011": "Invalid email or password.", //"There is no user record corresponding to this identifier. The user may have been deleted."
	"17012": "An account already exists with the same email address but different sign-in credentials",
	"17044": "Invalid SMS verifaction code",
	"-5": "User cancelled Google sign in",
	"1001": "Apple sign in error",
	'12501': "User cancelled Google sign in"
}

export default class firebase_auth {

	static init() {
		this.check_user()

	}

	static get native_mfa() {
		return !!FirebasePlugin?.verifySecondAuthFactor
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async authStateHandler(signed_in) {
		DEBUG && console.log('firebase_auth :: check_user :: authStateHandler', signed_in );
		
		if (signed_in) {
			const [user, claims, enrolledFactors] = await Promise.all([getCurrentUserAsync(), getClaimsAsync(), listEnrolledSecondAuthFactorsAsync()])
			DEBUG && console.log('firebase_auth :: check_user :: claims :', claims, ' :: user :', user, user.uid /*idTokenResult.token,*/ /*JSON.stringify(idTokenResult),*/ );

			const idTokenResult = {claims,token:user.idToken}

			Object.assign(user,{
				displayName: user.name || '',
				email: user.email || null,
				emailVerified: user.emailIsVerified,
				phoneNumber:user.phoneNumber || null,
				photoURL:user.photoUrl || null,
				isAnonymous:!!user.isAnonymous
			})

			user.providerData = [{...user}]
			user.getIdToken = () => user.idToken
			user.enrolledFactors = enrolledFactors
			
			if (user.idToken === app.user?.token) {

				app.user.refresh(user,idTokenResult)
				app.trigger(app.events.USER_UPD, 'AuthStateRefresh')
				return user;

			}

			DEBUG && console.log('firebase_auth :: check_user :: claims :', idTokenResult.claims, ' :: user :', user, user.uid /*idTokenResult.token,*/ /*JSON.stringify(idTokenResult),*/ );

			if (app.user.uid != user.uid) {
				app.user.cleanup()
				app.user = new AppUser()
				// TODO no gtm from ionic
				await firebase_auth_web.logout()
				app.trigger( app.events.CLEAR_MESSAGE )	
			}
				
			await app.user.init(user, idTokenResult)
			
			//Hack, for now
			const retToken = await API_service.load_data('User/tok',{})
			retToken?.token && await firebase_auth_web.signInWithCustomToken(retToken?.token)	
			
			DEBUG && console.log('app.user.prof', app.user.prof);
			
			app.trigger(app.events.USER_UPD, 'AuthStateChanged')

			if (document.location.pathname.startsWith('/login') || (document.location.pathname.startsWith('/navig') && !app.state.in_sgup_st2) ) {

				if (app.goto_after_login) {
					app.history.push(app.goto_after_login);
					app.goto_after_login = null;
				} else {
					app.history.push('/');
				}
			}

			return user
				
		} else {
			DEBUG && console.log('firebase_auth :: check_user :: NOT USER :');

			if (app.state.goback_token) {
				signInUserWithCustomTokenAsync(app.state.goback_token)
				app.state.goback_token=null
				return 
			}
			
			app.user.cleanup()
			app.user = new AppUser()
			await app.user.init()

			

			app.trigger(app.events.USER_UPD, 'AuthStateChanged')

			return null
		}
		
	}

	static async refresh_user() {
		return this.authStateHandler(true)
	}
	
	
	static async check_user() {

		// TODO
		// There's a bit of duplication between the 2 implementation, maybe that can be cleaned up a bit

		const FirebasePlugin = await waitFor(window,'FirebasePlugin')
		DEBUG && console.log("firebase_auth :: got FirebasePlugin");
		initAsync()
		const authListener = FirebasePlugin.registerAuthIdTokenChangeListener || FirebasePlugin.registerAuthStateChangeListener;
		authListener(this.authStateHandler.bind(this));
		
		if (await isUserSignedInAsync())
			await this.refresh_user()
	
		return
		
	}
				
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async frb_login (e, p) {

		let ret
		try {
			const user = await signInUserWithEmailAndPasswordAsync(e.trim(), p)
			ret = true
		} catch (err) {
			DEBUG && console.log("err",err);
			if (!handle_mfa(err)) {
				DEBUG && console.log('Error logging in.', err);
				err.code = extractFbxErrCode(err.message)

				if(['There is no user record corresponding to this identifier. The user may have been deleted.', 'Invalid verification code'].includes(err.message)){
					err.code = '17011' // assign error code for cancel - android cancel has no error code.
				}
				if(!["17001", "17005", "17008", "17009", '17011'].includes(err.code)){
					logger.report_except(err, {err, msg:'CATCH :: firebase_auth . user . getIdTokenResult'});
				}
				err.message = fbxCodesToErrMsg[err.code] || err.message
				ret = err
			} else {
				ret = true
			}
		}
		gtm.auth('login',{success:ret===true})
		return ret
	}
	
	static async signInWithCustomToken(token) {
		console.log("Signing In")
		try {
			const user = await signInUserWithCustomTokenAsync(token)
			return true
		} catch (err) {
			if(err.code !== 'auth/invalid-custom-token'){
				logger.report_except(err, {token, msg:`CATCH :: firebase_auth . signInWithCustomToken -- ${token} - ${err}`});
			}
			// Sentry.captureException(`CATCH in frb_login - ${err} -- ${e}:${p}`,{extra:{e,p}});
			DEBUG && console.log('Error logging in.', err)
			return err
		}
	}


	static async frb_reset (e) {
		let ret
		try {
			const res = await sendUserPasswordResetEmailAsync(e.trim())
			ret = true
		} catch (err) {
			err.code = extractFbxErrCode(err.message)
			if(['There is no user record corresponding to this identifier. The user may have been deleted.'].includes(err.message)){
				err.code = '17011' // assign error code for cancel - android cancel has no error code.
			}

			if(!['17011', '17008'].includes(err.code)){
				logger.report_except(err, {e, msg:`CATCH :: firebase_auth . sendPasswordResetEmail - ${err} -- ${e}`});
			}

			// Sentry.captureException(err,{extra:{e}});
			err.message = fbxCodesToErrMsg[err.code] || err.message
			DEBUG && console.log('Error sending reset instructions.', err)
			ret = err
		}

		gtm.auth('reset',{success:ret===true})
		
		return ret

		

	}
	
	static async frb_password (p) {
		const result = await updateUserPasswordAsync(p)
		console.log("User password updated")
	}
	
	static async frb_reauthenticate(p) {
		const user = await getCurrentUserAsync()
		const result = await signInUserWithEmailAndPasswordAsync(user.email, p).catch(err => handle_mfa(err) || Promise.reject(err))
		console.log("Current password verified")
}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async logout () {

		await signOutUserAsync()
		gtm.auth('logout')
		facebook_logout()

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async g_login() {
		let ret 
		try {
			const auth = await authenticateUserWithGoogleAsync(firebase_conf.web_client_id)
			const user = await signInWithCredentialAsync(auth)
		} catch (err) {
			if (!handle_mfa(err)) {
				err.code = extractFbxErrCode(err.message)
				DEBUG && console.log("** google plus – failed %o",err);
			
				if(err.message == 'java.lang.Exception: unknown status code: 12501'){
					err.code = '12501' // assign error code for cancel - android cancel has no error code.
				}

				if(!['-5', '17005', '17001', '17008', '17009', '17010', '17011', '12501'].includes(err.code) ){
					logger.report_except(err, {err, msg:`CATCH :: firebase_auth . g_login . ionic:357 - ${err.code}`});
				}
				err.message = fbxCodesToErrMsg[err.code] || err.message
				ret = err
			} else {
				ret = true
			}
		} 
		gtm.auth('g_login',{success:ret===true})
		return ret
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	static async fb_login() {
	
		let ret 
		try {
			const userData = await Facebook.login(['email'])
			const accessToken = userData.authResponse.accessToken
			const auth = await authenticateUserWithFacebookAsync(accessToken)
			const user = await signInWithCredentialAsync(auth)
			ret = true
		} catch (err) {
			if (!handle_mfa(err)) {
				facebook_logout()
				DEBUG && console.log("** facebook – failed %o",err);
				
				err.code = extractFbxErrCode(err.message)
				if(!['-5', '17005', '17001', '17008', '17009', '17010', '17011', '17012', '12501'].includes(err.code) ){
					logger.report_except(err, {err, msg:`CATCH :: firebase_auth . fb_login . ionic:357 - ${err.code}`});
				}
				err.message = fbxCodesToErrMsg[err.code] || err.message
				ret = err
			} else {
				ret = true
			}
		} 
		gtm.auth('fb_login',{success:ret===true})
		return ret
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	static async apple_login() {
		let ret
		try {
			const auth = await authenticateUserWithAppleAsync()
			const user = await signInWithCredentialAsync(auth)
			ret = true
		} catch (err){
			if (!handle_mfa(err)) {
				err.code = extractFbxErrCode(err.message)
				DEBUG && console.log("** apple login – failed %o",err);
				/**
				 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
				 */

				if(err.message == 'The web operation was canceled by the user.'){
					err.code = '1001' // assign error code for android - android cancel has no error code.
				}

				if(!['1001', '17005'].includes(err.code) ){
					logger.report_except(err, {err, msg:`CATCH :: firebase_auth . apple_login . ionic:357 - ${err.code}`});
				}
				err.message = fbxCodesToErrMsg[err.code] || err.message
				ret = err
			} else {
				ret = true
			}
		}
		gtm.auth('apple_login',{success:ret===true})
		return ret
	}

	static async onepass_login(redirect=false) {
		let ret
		try{
			FirebasePlugin.authenticateUserWithOAuth(async function(credential) {
				const { idToken } = await signInWithCredentialAsync(credential)
				app.user.reload_profile({ onepass: idToken, redirect });
			}, function(error) {
				console.error("Failed to authenticate with oAuth provider: " + error);
			}, 'oidc.onepass', { audience: "https://secure.api.onepass.com.au" }, []);
			getCurrentUserAsync()
		}
		catch (err){
			if (!handle_mfa(err)) {
				err.code = extractFbxErrCode(err.message)
				DEBUG && console.log("** apple login – failed %o",err);
				if(err.message == 'The web operation was canceled by the user.'){
					err.code = '1001' // assign error code for android - android cancel has no error code.
				}
				if(!['1001', '17005'].includes(err.code) ){
					logger.report_except(err, {err, msg:`CATCH :: firebase_auth . apple_login . ionic:357 - ${err.code}`});
				}
				err.message = fbxCodesToErrMsg[err.code] || err.message
				ret = err
			} else {
				ret = true
			}
		}
		gtm.auth('onepass_login',{success:ret===true})
		return ret
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async send_verification_email() {
		let ret
		try {
			await sendUserEmailVerificationAsync({
				handleCodeInApp: false,
				contineUrl: "https://www.app.instantscripts.com.au",
				iosBundleId: "au.com.instant",
				installIfNotAvailable: false
			})
			ret = true
		} catch (err) {
			err.code = extractFbxErrCode(err.message)
			err.message = fbxCodesToErrMsg[err.code] || err.message
			DEBUG && console.log("sending verification email – failed %o",err);

			ret = err
		}
		return ret
	}

	static async reload() {
		const user = await reloadCurrentUserAsync()
		return this.refresh_user()
			
	}


	static async verifyPhone(phoneInfoOptions, recaptcha) {

		const result = await verifySecondAuthFactorAsync({
			selectedIndex: 0,
			
		})
		console.log({result});

		return result

		// return new Promise((resolve,reject)=>{
		// 	FirebasePlugin.verifySecondAuthFactor(function(result) {
		// 		if(typeof result === "object"){
		// 			console.log("Received second factor credential - SMS code sent to device");
		// 			// credential = result;
		// 			// enterVerificationCode();
		// 		}else{
		// 			console.log("Second factor successfully verified", true);
		// 		}
		// 		resolve(result)
		// 	}, function(error) {
		// 		console.log("Failed to verify second factor", error, true);
		// 		reject(error)
		// 	}, {
		// 		selectedIndex: 0,
		// 		// credential, credential
		// 	}, {
		// 		// timeOutDuration: timeoutInSeconds,
		// 		// requireSmsValidation: requireSmsValidation,
		// 		// fakeVerificationCode: fakeVerificationCode,
		// 		// phoneNumber: phoneNumber
		// 	});
		// })
		
								
	}

	static async initiatePhoneEnroll(phoneNumber) {
		const verificationId = await enrollSecondAuthFactorAsync(phoneNumber,{})
		console.log("startPhoneEnroll",verificationId);
		return verificationId
	}

	static async verifyPhoneCode(resolver, verificationId, code) {
		const credential = {...verificationId,code}
		console.log("verifyPhoneCode",credential);
		const result = await verifySecondAuthFactorAsync({
			selectedIndex: 0,
			credential
		})
		console.log({result});

	}

	static async enrollPhoneFactor(phoneNumber,verificationId,verificationCode) {
		const credential = {...verificationId,code:verificationCode}
		const result = await enrollSecondAuthFactorAsync(phoneNumber,{credential,displayName:'Phone Number'})
		console.log({result});
		this.refresh_user()
		return result
	}

	static async unenrollFactor(factor) {
		console.log("unenrollFactor()",factor);
		await unenrollSecondAuthFactorAsync(factor.index)
		this.refresh_user()
	}

	static enrolledFactors() {
		return app.user.user_obj?.enrolledFactors || [] 
	}
	

	static get_captcha(ref,config) {
		return {clear:()=>{}}
	}
	
}
