/* global supportsPdfActiveX */
export function supportsPDFs() {
    
	// borrowed from PDFObject https://github.com/pipwerks/PDFObject/blob/665f150faed3988e0da308ba5915f6a7b2c3f8fb/pdfobject.js#L101
	const nav = window.navigator;
	const ua = window.navigator.userAgent;
	const isIE = 'ActiveXObject' in window;
	const isModernBrowser = window.Promise !== undefined;
	const supportsPdfMimeType = nav.mimeTypes['application/pdf'] !== undefined;
	const isSafariIOSDesktopMode =
		nav.platform !== undefined &&
		nav.platform === 'MacIntel' &&
		nav.maxTouchPoints !== undefined &&
		nav.maxTouchPoints > 1;
	const isMobileDevice =
		isSafariIOSDesktopMode || /Mobi|Tablet|Android|iPad|iPhone/.test(ua);
	const isSafariDesktop =
		!isMobileDevice &&
		nav.vendor !== undefined &&
		/Apple/.test(nav.vendor) &&
		/Safari/.test(ua);
	const isFirefoxWithPDFJS =
		!isMobileDevice && /irefox/.test(ua) && ua.split('rv:').length > 1
			? parseInt(ua.split('rv:')[1].split('.')[0], 10) > 18
			: false;

   return !isMobileDevice &&
		(isModernBrowser ||
			isFirefoxWithPDFJS ||
			supportsPdfMimeType ||
			(isIE && supportsPdfActiveX()));

}