import moment from "moment-timezone";
import React, { useState } from "react";
import { Button, Icon, List, Popup, Segment } from "semantic-ui-react";
import scripts_list_model from "models/scripts_list_model";
import group_by_val_obj from "xAppLib/helpers/group_by_val_obj";
import { RenderNotes } from "../../UIelems/fields/FieldMetaNotes";

/**
 * Quick summary of recent scripts/consults
 * @param {RecentAppScriptsProps} props
 * @returns {JSX.Element}
 */
export function RecentAppScripts(props) {
    const {list, compact = false, loading = false, limit = 5, by_uid} = props;

    const scripts = list ?? [];
    const [showAll, setShowAll] = useState(false);

    const oldest_first = scripts.sort((x, y) => y.tm.localeCompare(x.tm));

    const filtered = showAll
        ? oldest_first
        : oldest_first.slice(0, limit);

    const by_date = group_by_val_obj(filtered, scr => scr.tm.split('T')[0]) ?? {};
    const heading = (
        <div className="flex justify-between items-end">
            <b>Recent Activity {loading || scripts.length === 0 ? null : <>({scripts.length})</>}</b>
            <div className="space-x-2">
            {scripts.length > limit && (
                <Button type="button"
                        basic
                        className="!py-1 !text-xs"
                        color="black"
                        size="mini"
                        onClick={() => setShowAll(x => !x)}
                >
                    {showAll ? 'Less' : 'More'}
                </Button>
            )}
            </div>
        </div>
    );
    const content = (() => {
        if (loading) {
            return <><Icon name='spinner' loading size="small"/> Loading...</>;
        }

        if (Object.keys(by_date).length === 0) {
            return <>No recent activity</>;
        }

        return (
            <List data-testid="segment-recent-scripts" divided>
                {Object.keys(by_date).map(dt => (
                    <List.Item key={dt}>
                        <List.Content>
                            <List.Header>
                                <small className="font-normal">{moment(dt).format('DD/MM/YYYY')}</small>
                            </List.Header>

                            <div className={compact ? "": "space-y-2"}>
                            {by_date[dt].map(scr => (
                                <div key={scr.sid}>
                                    {scr.note && <Popup trigger={<span><Icon name="file alternate outline" /></span>}
                                                        on="click"
                                                        className="px-2"
                                                        wide="very"
                                    >
                                        <div style={{maxHeight: '30vh', paddingRight: '1rem', overflowX: 'hidden', overflowY: 'auto'}}>
                                            <RenderNotes note={scr.note} />
                                        </div>
                                    </Popup>}
                                    {['expr_declined', 'qual_declined'].includes(scr.status) && <span><Icon name="warning sign" color="red"/> Declined: </span>}
                                    <i>{![undefined, 'doccons'].includes(scr.script_type) && ![undefined, 'medcert'].includes(scr.req_type) && (scripts_list_model.TYPE_DICT(scr.script_type, scr.req_type) + ' - ')}</i>
                                    <b>{scr.med}</b> <small>{scr.sz} {scr.qnty}</small>
                                </div>
                            ))}
                            </div>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        );
    })();

    return (
        <>
            {!compact && heading}
            <Segment size="small" style={{color: 'initial'}}>
                {compact && heading}
                {content}
            </Segment>
        </>
    );
}

/**
 * @typedef {Object} RecentAppScriptsProps
 * @property {RecentAppScript[]} list
 * @property {boolean} [compact=false]
 * @property {boolean} [loading=false]
 * @property {number} [limit=5]
 */

/**
 * @typedef {Object} RecentAppScript
 * @property {string} tm - ISO date the script was created
 * @property {string} script_type
 * @property {string} req_type
 * @property {string} sid
 * @property {string} status
 * @property {string} note
 * @property {string} med - med name
 * @property {string} qnty - from med_db_data (if applicable)
 * @property {string} sz - from med_db_data (if applicable)
 */