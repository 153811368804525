import React, { Component } from 'react';

import { Input, Icon, Select } from 'semantic-ui-react'
import  JsonEditor from 'xAppLib/UIelems/JsonEditor'; 

export default class ViewEditField extends Component {

	constructor(props) {
		super(props);
	}

	state = {
		mode: 'view',
	}

	// 		--------------------------------		--------------------------------		---------

	static getDerivedStateFromProps(nextProps, prevState) {
		// console.log('ViewEditField :: getDerivedStateFromProps', nextProps, prevState);
		// if ( props.data!=this.props.data && props.loc!=this.props.loc )
		return nextProps.val!=prevState.init_val && { init_val: nextProps.val, curr_val: nextProps.val }
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	start_edit() {

		this.setState({
						mode: 'edit',
					})

	}

	// 		--------------------------------		--------------------------------		---------

	cancel_edit() {

		this.setState({
						mode: 'view',
						curr_val: this.props.val
					})

		this.state.curr_val!=this.props.val && this.props.onCancel && this.props.onCancel(this.state.curr_val)

	}

	// 		--------------------------------		--------------------------------		---------

	save_edit() {

		this.setState({
						mode: 'view',
					})

		if (this.state.curr_val != this.props.val || this.props.alwaysSave) this.props.onSave?.(this.state.curr_val)

	}


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	view_render = () =>  <div
							style = {{
									flex: 1,
									fontSize: this.props.fontSize || 20,
								}}
								onDoubleClick = { () => !this.props.read_only && this.start_edit() }
						  >
						  
						{ 
							this.props.type == 'json' && 
								<pre style={{flex:3}}>{JSON.stringify(this.state.curr_val, null, 2)}</pre> 
							|| 
							this.state.curr_val.toString() 
							|| ''
						}
							&nbsp;
							{!this.props.read_only &&
								<Icon name='edit' size="small"
									onClick = { () => this.start_edit() }
								 />
							}
						</div>

	// 		--------------------------------		--------------------------------		---------

	edit_render = () => {
		const EditComponent = this.props.editAs || Input;
		return (<span style = {{
											flex: 1,
											display:'flex',
											flexDirection:'row',
											alignItems:'center'
											
							 }}>
							{ 
								this.props.children ||
								this.props.type == 'json' && 
									<JsonEditor
											value={this.state.curr_val}
											search={false}
											onChange={ value => this.setState({curr_val: value}) }
										/>
								||
								this.props.type == 'select' && 
									 <Select 
										value = {this.state.curr_val}
										placeholder = {this.props.placeholder}
										onChange = { (e, d) => this.setState({curr_val: d.value}) }
										options={this.props.options}
									/>
								||
								<EditComponent
										value = {this.state.curr_val}
										placeholder = {this.props.placeholder}
										// onBlur = { () => this.cancel_edit() }
										onChange = { (e, d) => this.setState({curr_val: d.value}) }
										// onChange = { (e, d) => this.setState({curr_val: e.target.value}) }
										autoFocus = {true}
									 />
							}

								 <Icon name='cancel' size='big'
									onClick = { () => this.cancel_edit() }
								 />
								<Icon name='check' size='big'
									color='red'
									onClick = { () => this.save_edit() }
								 />
							</span>)
	}

	// 		--------------------------------		--------------------------------		---------

	render() {

		const { mode } = this.state

		return  <div style={this.props.style}>
					{ mode=='view' && this.view_render() }
					{ mode=='edit' && this.edit_render() }
			</div> 
	}

}
