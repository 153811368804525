import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import script_model from "models/script_model";
import { Form, Modal, Header, Button, Icon } from "semantic-ui-react";
import { stateToTimezone } from "helpers/datetime"
import user_model from "../../../models/user_model";
import evermed_model from "../../../models/evermed_model";
import { getClientBookingTimezone } from "../../booking/shared";

import PaypalButton from "./Forms/PaypalButton";

const Confirmation = (props) => {
	const { med_data, inline , visible, store, script_type, req_type, formData, docs_data_db, cart, qs_sorted,  onConfirm, onClose, paypalOrderDetails, enable_qs, defaultValues } = props

    const escript = formData.delivery == "escript"
    const cart_content = cart.content()

    if (!visible)
        return null
    
    const requestTime = new Date().toLocaleDateString("en-AU", {
        timeZone: stateToTimezone(defaultValues?.full_address?.state),
    });

    const appointmentTZ = getClientBookingTimezone();
    
    const content = <>
                 {/*<Divider/>*/}
                    
                    <div>
                        {['medcert'].includes(req_type) &&
                            <p>Based on your profile address, the medical certificate will be issued for date <strong>{requestTime}</strong></p>
                        }

                        {/*formData:
                        {JSON.stringify(formData, null, 4)}

                        vals:
                        {JSON.stringify(vals, null, 4)}*/}

                        <h3>Patient Details</h3>

                        {
                            Object.values(script_model.COMM_USR_FIELDS)
                                .filter(f=>f.name!='full_address')
                                .map( f => !['hidden', 'separator'].includes(f.type) && formData[f.name] && <div key={f.name}>{f.label||f.name}: 
                                <b> {f.name === 'atsi' && user_model.get_atsi_label(formData[f.name]) || f.name === 'dva' && `${formData[f.name].number} ${formData[f.name].colour}` || formData[f.name]}</b> </div> )
                        }

                        <h3>Consultation request details</h3>

                        {
                            Object.values(script_model.COMM_DCNS_FIELDS)
                                .filter(f => f.name != 'full_address')
                                .map(f => {
                                    let response = formData[f.name];
                                    if (f.type === 'appointment' && response) {
                                        response =
                                            response.start
                                                ? (
                                                    moment(response.start).tz(appointmentTZ).format(`ddd DD MMM YYYY \\a\\t h:mma`)
                                                    + ` (${appointmentTZ} time)`
                                                ) : '';
                                    }
                                    if (f.name === 'call_hour_restriction') {
                                        response = moment(response).format('hA');
                                    }
                                    return (
                                        !['hidden', 'separator'].includes(f.type)
                                        && formData[f.name]
                                        && <div key={f.name}>{f.label || f.name} :
                                            <b> {response}</b> </div>
                                    )
                                })
                        }

                        {script_type=='cosm' && 
                            <React.Fragment>
                                <h3>Treatment Details</h3>
                                <div>Treatment Quantity: <b>{formData.cosm_qua}</b></div>
                                <div>Treatment Location: <b>{formData.cosm_loc}</b></div>
                                <div>Treatment Notes: <b>{formData.cosm_note}</b></div>
                                <div>Total Cost: <b>{formData.cosm_total_cost}</b></div>
                                <div>Payment Details: <b>{formData.cosm_payment}</b></div>
                                <div>Deposit Refunds: <b>{formData.cosm_refund}</b></div>
                                <div>Follow Up Payments: <b>{formData.cosm_follow_up_payment}</b></div>
                                <div>Skype Doctor: <b>{(!formData.cosm_doc || formData.cosm_doc=='No') && 'No' || docs_data_db?.filter(d=>d.m==formData.cosm_doc)?.pop()?.name || formData.cosm_doc}</b></div>
                            </React.Fragment>
                        }

                        {enable_qs && qs_sorted.length && <React.Fragment><h3>Digital Medical Consultation Answers:</h3>
                            {
                            qs_sorted
                                    .filter(({k}) => typeof formData[k] !== 'undefined')
                                    .map( ({k,...q}, i) => <div key={'conf_ans_'+i+k}>{q.txt}  <b>{q?.a[formData[k]]?.txt}</b> 	</div> )
                            }
                        </React.Fragment> || null}

                        {['medbuy', 'exprbuy', 'qualbuy'].includes(script_type) &&
                            <React.Fragment>
                                <h3>Medication purchase and delivery</h3>
                                <div>Purchasing Medication: {formData.medication && 
                                    <><b>{evermed_model.SEL_MED_DETS(formData.medication).drugBrandName}</b> ({evermed_model.SEL_MED_DETS(formData.medication).productVariantType !== 'Generic' ? 'Branded' : 'Generic'})</> || 
                                    <><b>{med_data.name}</b> {formData.want_gen?'generic':'branded'}</>}</div>
                                <div>Delivery Address: <b>{formData.first_name} {formData.last_name}, {formData.shipping_address_components?.formatted}</b></div>
                                <div>Cost for the medical consultation and the medication: <b>{cart_content.total.format()}</b></div>
                            </React.Fragment>
                        }
                        {['medclick', 'exprclick', 'qualclick'].includes(script_type) &&
                            <React.Fragment>
                                <h3>Medication purchase and collection details</h3>
                                <div>Purchasing Medication: {formData.medication && 
                                    <><b>{evermed_model.SEL_MED_DETS(formData.medication).drugBrandName}</b> ({evermed_model.SEL_MED_DETS(formData.medication).productVariantType !== 'Generic' ? 'Branded' : 'Generic'})</> || 
                                    <><b>{med_data.name}</b> {formData.want_gen?'generic':'branded'}</>}</div>
                                <div>Medication Cost: <b>${cart.MEDBUY_PRICE.toFixed(2)}</b></div>
                                <br/>
                                <div>Collection Pharmacy: <b>{formData.col_pharm.name}</b></div>
                                <div>Collection Address: <b>{formData.col_pharm.prettyAddress}</b></div>
                                <div>Collection Time: <b>Ready 2 business hours after approval.</b></div>
                                <div>Collection Price: <b>FREE</b></div>
                            </React.Fragment>
                        }

                        {['medcons', 'exprcons', 'qualcons'].includes(script_type) &&
                            <React.Fragment>
                                <h3>
                                    {
                                        escript && "Your eScript code will be sent directly to you" || 
                                        ['medcert'].includes(req_type) && "Your medical certificate will be sent directly to you" || 
                                        "Your medical prescription will be sent to a pharmacy"
                                    }
                                </h3>
                                <div>
                                    {
                                        ['medcert'].includes(req_type) && "Certificate Type" || 
                                        "Prescription Medication"
                                    }:&nbsp;
                                    <b>{med_data.name}</b>
                                </div>
                                <div>Cost for the medical consultation: <b>${(cart.MEDCONS_PRICE*1).toFixed(2)}</b></div>
                                {escript && <div><br/>In the event that the eScript cannot be generated for you, a paper script will be sent to:</div>}
                                {!['medcert'].includes(req_type) && (
                                    <>
                                        <div>Pharmacy Name: <b>{store.send_to_pha?.nm}</b></div>
                                        <div>Pharmacy Address: <b>{store.send_to_pha?.adr}</b></div>
                                    </>
                                )}
                            </React.Fragment>
                        }


                        {['doccons', 'subcons'].includes(script_type) &&
                            <React.Fragment>
                                <h3>
                                    You will receive a call from
                                    {['docconscov', 'docconsref'].includes(req_type) && ' our doctor' || ' us'}
                                    {(!!formData.appointment?.start) ? ' around your appointment time' : ' soon'}.
                                </h3>
                                {store.sendto_pha && <>
                                    <div>Pharmacy Name: <b>{store.sendto_pha.nm}</b></div>
                                    <div>Pharmacy Address: <b>{store.sendto_pha.adr}</b></div>
                                </>}
                                <div>Cost for the medical consultation: <b>${cart.DOCCONS_PRICE.toFixed(2)}</b></div>
                            </React.Fragment>
                        }


                        {formData['cado_want'] && 
                            <React.Fragment>
                                <h3>Request Instant Doctor Consultation</h3>
                                <div>Receive a call from Doctor to mobile number : <b>{formData['cado_phone']}</b></div>
                                <div>Cost for the Instant Doctor Consultation : <b>${cart.CADO_PRICE.toFixed(2)}</b></div>
                            </React.Fragment>
                        }

                        {(script_type === 'pathoreq' && formData.medicare?.replace(/[^0-9]/g, '') === '') &&
                            <>
                                <h3>Privately Billed</h3>
                                <p>Please be aware that if you do not supply your Medicare details at the time of making your pathology request we are not able to add these later, and your pathology request will not be bulk billed.</p>
                            </>
                        }

                        {cart_content.total.value>0 && 
                            <React.Fragment>
                                <h3>Payment Details</h3>
                                <div>Total Amount: <b>{cart_content.total.format()}</b></div>

                                {formData.paym?.CC_tok && 
                                    <div>Paying with {formData.paym.CC_tok.cardType=='001' && 'Visa'} card: 
                                        <b>{formData.paym.CC_tok?.maskedPan?.replace(/X/g, '.') || JSON.stringify(formData.paym.CC_tok)}</b>
                                    </div>
                                }
                            </React.Fragment>
                        }

                        {formData['yogp_want'] && 
                            <React.Fragment>
                                <h3>Update Your Doctor</h3>
                                <div><b>{formData['yogp'].name}</b></div>
                            </React.Fragment>
                        }

                    </div>
        </>

    if (inline)
        return content
        
    return	<>

            <Modal
                open={true}
                onClose={onClose}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
                className='med-confirm'
                >
                
                <Header as='h2' color="blue">
                    <span>Confirm your request details</span>
                    <Modal.Actions className="hidden md:block">
                        <Button onClick={onClose} size="mini" data-testid="button-modify">
                            <Icon name="undo" /> Modify
                        </Button>
                        
                        {cart_content.total.value > 0 && formData.paym.paymentMethod === 'paypal' ?  
                   
                        <div style={{paddingLeft: "10px"}} data-testid="button-paypal">
                            <PaypalButton
                            purchaseAmount={cart_content.total.value}
                            paypalOrderDetails={paypalOrderDetails}
                            onConfirm={onConfirm}
                            btnHeight={35}
                            />
                        </div>
                        : 
                             <Button positive onClick={onConfirm} size="mini" data-testid="button-confirm">
                             <Icon name="checkmark" /> Confirm
                         </Button> 
                    }          
                    </Modal.Actions>
                </Header>

                <Modal.Content style={{overflowY:'scroll',maxHeight:'calc(70vh - 70px)'}} >
                    <p><strong>Confirm that all the provided details below are correct. Press Confirm to proceed or Modify to update the details.</strong></p>

                    {content}

                </Modal.Content>

                <Modal.Actions className="flex justify-end">
                    <Button onClick={onClose}>
                        <Icon name="undo" /> Modify
                    </Button>
                    {cart_content.total.value > 0 && formData.paym.paymentMethod === 'paypal' ? 
                    <div style={{paddingLeft: "10px"}}>
                        <PaypalButton
                            purchaseAmount={cart_content.total.value}
                            paypalOrderDetails={paypalOrderDetails}
                            onConfirm={onConfirm}
                        />
                    </div>
                        :
                    <Button positive onClick={onConfirm}>
                        <Icon name='checkmark' /> Confirm
                    </Button>
                    }      
                </Modal.Actions>
                
            </Modal>

        </>
};

export default Confirmation;