import React, {Component} from 'react'

const ident = c=>c

export default class AppendIf extends Component {

	render () {

		const {cont, appn, beforeContent,afterContent, render=ident} = this.props

			return cont ?
					<React.Fragment>
						{beforeContent}{render(cont)}{afterContent}
						{appn}
					</React.Fragment>
				:
					null
	}
}
