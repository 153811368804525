import React, { useMemo, useRef } from 'react';

import user_model from 'models/user_model';
import { useProfile, useToggleState } from 'xAppLib/Hooks';

import { Modal, Button, Icon, Form, Grid } from 'semantic-ui-react';
import UniForm from '../UniForm/UniForm';
import UniFieldSUI from '../UniForm/UniField-SUI';

const HEADER_AND_BUTTONS_HEIGHT = '200px';

const MedicalHistoryModal = ({ onClose, navigateToChroCond = false }) => {
	const [profile] = useProfile();
	const formRef = useRef(null);
	const [isLoading, turnIsLoadingOn, turnIsLoadingOff] = useToggleState();

	const handleOnClose = () => {
		navigateToChroCond
			? app.trigger(app.events.SHOW_POPUP, {
					noCloseBtn: true,
					cst_modal: true,
					txt: 'Select Your Chronic Conditions',
					pn: 'chro-cond'
			  })
			: onClose?.();
	};

	const updateProfile = (d) => {
		if (!Object.keys(d).length) {
			handleOnClose();
			return;
		}

		turnIsLoadingOn();
		user_model.save_prof({ ...profile, ...d }, (result) => {
			if (result.profile) {
				Object.keys(d).forEach((k) => {
					// update old profile
					app.user.prof[k] = result.profile[k];
				});
			}
			turnIsLoadingOff();
			handleOnClose();
		});
	};

	const defaultValues = useMemo(() => ({
		...profile,
		cons_has_meds: profile?.cons_meds === 'no' && 'no',
		cons_has_medhist: profile?.cons_medhist === 'no' && 'no',  
	}), [profile]);

	return (
		<>
			<Modal.Content style={{ minHeight: `calc(100vh - ${HEADER_AND_BUTTONS_HEIGHT})` }}>
				<UniForm
					ref={formRef}
					name="MedicalHistory"
					onActForm={updateProfile}
					fields={user_model.PROF_MEDICAL_HISTORY}
					defaultValues={defaultValues}
					onChange={(name, value) => {
						if (name === 'cons_has_allergies' && value === 'no') {
							return { cons_allergy: '' };
						}
						if (name === 'cons_has_meds') {
							return { cons_meds: value || '' };
						} 
						if (name === 'cons_has_medhist') {
							return { cons_medhist: value || '' };
						} 
					}}
				>
					{(values, valids, uf_this, fields) => {
						const args = { values, valids, uf_this };
						return (
							<Form size={!app.acl.app_use ? 'big' : undefined}>
								<Grid verticalAlign="top" stackable className="profile">
									<Grid.Row>
										<Grid.Column>
											<UniFieldSUI fl={fields.cons_has_allergies} {...args} />
											{values.cons_has_allergies !== 'no' && <UniFieldSUI fl={fields.cons_allergy} {...args} />}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<UniFieldSUI fl={fields.cons_has_meds} {...args} />
											{values.cons_has_meds !== 'no' && <UniFieldSUI fl={fields.cons_meds} {...args} />}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<UniFieldSUI fl={fields.cons_has_medhist} {...args} />
											{values.cons_has_medhist !== 'no' && <UniFieldSUI fl={fields.cons_medhist} {...args} />}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Form>
						);
					}}
				</UniForm>
			</Modal.Content>
			<Modal.Actions>
				<Button data-testid='button-cancel' onClick={handleOnClose}>
					<Icon name="undo" /> Cancel
				</Button>
				<Button positive data-testid='button-save' onClick={() => formRef.current.actForm()} loading={isLoading}>
					<Icon name="save" /> Save
				</Button>
			</Modal.Actions>
		</>
	);
};

export default MedicalHistoryModal;
