//	------------------------	------------------------	------------------------
//	Description: different data formats specified by eRx
//	------------------------	------------------------	------------------------

// Based on http://erx.com.au/integration/v1 and eRX payload schema def v26

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const moment =      require('moment-timezone')
const erx_enums =   require('./erx_enums')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const ERX_FORMAT = {

    //	------------------------	------------------------	------------------------
    // Base formats
    //	------------------------	------------------------	------------------------

    array: {
        format: '[]',
        validate: (item) => {
            if (!Array.isArray(item)) return { res: 'err', err: 'Expected type: Array. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    string: {
        format: 'X',
        validate: (item) => {
            if (typeof item != 'string') return { res: 'err', err: 'Expected type: String. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                //case 'string':  return ERX_FORMAT.boolean.fromString(item)
                default: return ERX_FORMAT.string.fromOther(item)
            }
        },
        fromOther: (item) => {
            try {
                let val = ''
                if (typeof item != undefined || item != null) val = item.toString()
                return { res: 'ok', val }
            } catch (e) {
                return { res: 'err', err: 'Could not covert to string. Error: ' + e + '. Value: ' + JSON.stringify(item), e }
            }
        },
        //blank: '',
    },

    //	------------------------	------------------------	------------------------

    boolean: {
        format: 'true|false',
        validate: (item) => {
            if (typeof item != 'boolean') return { res: 'err', err: 'Expected type: Boolean. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                case 'string': return ERX_FORMAT.boolean.fromString(item)
                case 'number': return { res: 'ok', val: item ? true : false }
                default: return { res: 'err', err: 'Unknown type to convert to boolean. Type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            }
        },
        fromString: (item) => {
            if (typeof item != 'string') return { res: 'err', err: 'Item not string. Item type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            try {
                switch (item.toLowerCase()) { //Lower case probs not needed as erx seem lower, but ok. Don't do 1 = true, 0 = false (not in erx spec)
                    case '': return { res: 'ok', val: null }
                    case 'true': return { res: 'ok', val: true }
                    case 'false': return { res: 'ok', val: false }
                    case '1': return { res: 'ok', val: true }
                    case '0': return { res: 'ok', val: false }
                    default: return { res: 'err', err: 'Unrecognised string: ' + item }
                }
            } catch (e) {
                return { res: 'err', err: 'Unknown error parsing string: ' + e, e }
            }
        },
    },

    //	------------------------	------------------------	------------------------

    number: {
        format: '123',
        validate: (item) => {
            if (typeof item != 'number') return { res: 'err', err: 'Expected type: Number. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                case 'string': return ERX_FORMAT.number.fromString(item)
                default: return { res: 'err', err: 'Unknown type to convert to number. Type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            }
        },
        fromString: (item) => {
            if (typeof item != 'string') return { res: 'err', err: 'Item not string. Item type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            try {
                item = item.replace(/\D/g, '')
                let new_number = Number(item)
                if (isNaN(new_number)) return { res: 'err', err: 'Could not convert string to number. Value: ' + JSON.stringify(item) }
                return { res: 'ok', val: new_number }
            } catch (e) {
                return { res: 'err', err: 'Unknown error parsing string: ' + e, e }
            }
        },
        //blank: 0,
    },

    //	------------------------	------------------------	------------------------

    unumber: {
        format: '123',
        validate: (item) => {
            if (typeof item != 'number') return { res: 'err', err: 'Expected type: Number. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            if (item < 0) return { res: 'err', err: 'Number less than 0' }
            //console.log('validating unum')
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                case 'string': 
                return ERX_FORMAT.unumber.fromString(item)
                default: return { res: 'err', err: 'Unknown type to convert to number. Type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            }
        },
        fromString: (item) => {
            if (typeof item != 'string') return { res: 'err', err: 'Item not string. Item type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            try {
                let new_number = Number(item)
                if (isNaN(new_number)) return { res: 'err', err: 'Could not convert string to number. Value: ' + JSON.stringify(item) }
                return { res: 'ok', val: new_number }
            } catch (e) {
                return { res: 'err', err: 'Unknown error parsing string: ' + e, e }
            }
        },
        //blank: 0,
    },

    //	------------------------	------------------------	------------------------
    // Regex formats
    //	------------------------	------------------------	------------------------

    numeric: {
        format: '"123"',
        regx: /^[0-9]*$/,
        validate: (item, format = ERX_FORMAT.numeric.format, regx = ERX_FORMAT.numeric.regx) => { // seems weird to self ref but ok
            if (!regx.test(item)) {
                return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            }
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                case 'string': 
                    return { res: 'ok', val: item.replace(/\D/g, '') }
                case 'number': return { res: 'ok', val: item.toString() }
                default: return { res: 'err', err: 'Unknown type to convert to numeric. Type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            }
        },
    },

    //	------------------------	------------------------	------------------------

    xml_date: {
        format: 'YYYY-MM-DD',
        regx: /^\d{4}-\d{2}-\d{2}$/, //Note that we will still check date parse. This should match the above format
        validate: (item, format = ERX_FORMAT.xml_date.format, regx = ERX_FORMAT.xml_date.regx) => { // seems weird to self ref but ok
            let valid_date = false
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            try { valid_date = moment(item, format).isValid() } catch (e) { valid_date = false } //moment will probably behave, but try catch anyway
            if (!valid_date) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Date is not a valid date. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    xml_datetime: {
        format: 'YYYY-MM-DDTHH:mm:ss',
        regx: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/, //Note that we will still check date parse. This should match the above format
        validate: (item, format = ERX_FORMAT.xml_datetime.format, regx = ERX_FORMAT.xml_datetime.regx) => { // seems weird to self ref but ok
            let valid_date = false
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            try { valid_date = moment(item, format).isValid() } catch (e) { valid_date = false } //moment will probably behave, but try catch anyway
            if (!valid_date) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Date is not a valid date. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    cons_num: {
        format: '000000000X',
        regx: /^\d{9}[A-Za-z]$/, //erx dont say if upper or lower case. This should match the above
        validate: (item, format = ERX_FORMAT.cons_num.format, regx = ERX_FORMAT.cons_num.regx) => {
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    safe_num: {
        format: 'SN000000000 or CN000000000',
        regx: /^(SN\d{9}|CN\d{9})$/, //erx dont say if upper or lower case. This should match the above
        validate: (item, format = ERX_FORMAT.safe_num.format, regx = ERX_FORMAT.safe_num.regx) => {
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    dva_num: {
        format: 'AAXXNNNN[A]', //https://meteor.aihw.gov.au/content/index.phtml/itemId/339127
        regx: /^[NVQWST]([A-Za-z]\d{6}|[A-Za-z ]{2}\d{5}|[A-Za-z]{3}\d{4})[A-Za-z]?$/,
        validate: (item, format = ERX_FORMAT.dva_num.format, regx = ERX_FORMAT.dva_num.regx) => {
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    erx_scid: {
        format: 'XXXXXXXXXXXXXXXXXX',
        regx: /^[A-Z0-9]{18}$/,
        validate: (item, format = ERX_FORMAT.erx_scid.format, regx = ERX_FORMAT.erx_scid.regx) => {
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    erx_guid: {
        format: 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
        regx: /^'[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}'$/,
        validate: (item, format = ERX_FORMAT.erx_guid.format, regx = ERX_FORMAT.erx_guid.regx) => {
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            return { res: 'ok' }
        },
    },

    //	------------------------	------------------------	------------------------

    //	------------------------	------------------------	------------------------
    // Enum formats
    //	------------------------	------------------------	------------------------

    state_enum: {
        enums: erx_enums.ERX_ENUM.STATE,
        validate: (item, enums = ERX_FORMAT.state_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                case 'string':  //return ERX_FORMAT.boolean.fromString(item)
                    // Eg. Vic
                    const state_vals = Object.values(ERX_FORMAT.state_enum.enums)
                    const item_upper = item.toUpperCase()
                    if (state_vals.includes(item_upper)) return { res: 'ok', val: item_upper }

                    // eg. ViCtOrIa - this should be tested further
                    const state_name_vals = Object.keys(ERX_FORMAT.state_enum.enums)
                    const state_name_vals_upper = state_name_vals.map(c=>c.toUpperCase())
                    const match_index = state_name_vals_upper.indexOf(item_upper)
                    if (match_index > -1) return { res: 'ok', val: state_vals[match_index] }

                    return { res: 'err', err: 'Could not convert strain' + item + ' to state.'}
                    break
                default: 
                    return { res: 'err', err: 'Unknown type to convert to state. Type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
                    break
            }
        },
    },

    //	------------------------	------------------------	------------------------

    item_state_enum: {
        enums: erx_enums.ERX_ENUM.ITEM_STATE,
        validate: (item, enums = ERX_FORMAT.item_state_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        }
    },

    //	------------------------	------------------------	------------------------

    sex_enum: {
        enums: erx_enums.PATIENT_ENUM.SEX,
        validate: (item, enums = ERX_FORMAT.sex_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        }
    },

    //	------------------------	------------------------	------------------------

    dva_card_enum: {
        enums: erx_enums.PATIENT_ENUM.DVA_CARD,
        validate: (item, enums = ERX_FORMAT.dva_card_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        }
    },

    //	------------------------	------------------------	------------------------

    pres_enum: {
        enums: erx_enums.CLINICIAN_ENUM.PRESCRIBER_TYPE,
        validate: (item, enums = ERX_FORMAT.pres_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        }
    },

    //	------------------------	------------------------	------------------------

    shd_enum: {
        enums: erx_enums.PRESCRIBED_ENUM.SCHEDULE_NUMBER,
        validate: (item, enums = ERX_FORMAT.shd_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        }
    },

    //	------------------------	------------------------	------------------------

    gen_enum: {
        enums: erx_enums.PRESCRIBED_ENUM.GENERIC_INTENTION,
        validate: (item, enums = ERX_FORMAT.gen_enum.enums) => {
            if (!Object.values(enums).includes(item)) return { res: 'err', err: 'Unknown enum: ' + item + '. Allowed: ' + Object.values(enums) }
            return { res: 'ok' }
        },
        fromValue: (item) => {
            switch (typeof item) {
                case 'number':  // Handle the same as bool
                case 'boolean': return { res: 'ok', val: (item ? erx_enums.PRESCRIBED_ENUM.GENERIC_INTENTION.Generic : erx_enums.PRESCRIBED_ENUM.GENERIC_INTENTION.Brand) }
                default: return { res: 'err', err: 'Unknown type to convert to number. Type: ' + typeof item + '. Value: ' + JSON.stringify(item) }
            }
        },
    },

    //	------------------------	------------------------	------------------------
    // Regex and enum formats
    //	------------------------	------------------------	------------------------

    ctg_enum: { // This one is a little different in that it can be of enum or other type that follows the format
        format: 'CTGnnX',
        regx: /^CTG\d{2}[A-Za-z]?$/,
        validate: (item, format = ERX_FORMAT.ctg_enum.format, regx = ERX_FORMAT.ctg_enum.regx) => {
            if (!regx.test(item)) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + item }
            return { res: 'ok' }
        }
    }
}

//	------------------------	------------------------	------------------------
//  Exports
//	------------------------	------------------------	------------------------

// eslint-disable-next-line no-undef
module.exports = {

    ERX_FORMAT,

    //	------------------------	------------------------	------------------------

    check_erx_format: (item, format) => {

        if (Object.keys(ERX_FORMAT).includes(format)) {
            return ERX_FORMAT[format].validate(item)
        } else { // Probably wont get here anymore
            if (typeof item != format) return { res: 'err', err: 'Expected type: ' + format + '. Actual type: ' + typeof item + '. Value: ' + item }
            return { res: 'ok' }
        }

    }

}