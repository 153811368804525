import React, { useEffect, useReducer } from 'react';
import { Form, Checkbox, Loader, Modal, Button } from 'semantic-ui-react';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';
import currency from 'currency.js';

const deliveryResErrorLabel = (status) => {
  switch (status) {
    case 'bad_address':
      return 'We cannot retrieve shipping rates for the address listed above. Please check the suburb, state & postcode are correct and try again.';
    default:
      return 'There was an issue getting delivery details for the address listed below. Please try another address or try again later.';
  }
};

const deliveryCategoryLabel = (categoryCode, options) => {
  switch (categoryCode) {
    case 'Local': {
      const cantLeaveOutside = options?.some?.((opt) => opt.providerName === 'DoorDash' && !opt.hasAuthorityToLeave);
      const text = cantLeaveOutside
				? 'You must be home and show your ID to accept delivery of the medication'
				: 'We recommend you being home to receive the package, but if not, the package will be left unattended if it is safe to do so';
      return <p className="text-gray-600">{text}</p>;
    }
    default:
      return <p className="text-gray-600">We will send you tracking details once the order has been dispatched</p>;
  }
};

const EvermedDeliverySection = (props) => {
  const { Section, evermed, inline, hide_section=false, formData, discount } = props;
  return (
    <UniFormMed {...props} section="evermed_delivery" fields={script_model.EM_DELIVERY_FIELDS}>
      {(values, valids, uf_this, fields) => {
          if(!hide_section){
            return (
          <Section>
            <Section.Header className={inline && 'm-0 mb-3 text-lg'}>Delivery method</Section.Header>
            <Section.Content className={inline && 'p-0'} start={!inline}>
              <EvermedDeliveryOptions {...{ values, valids, uf_this, fields, evermed, formData, discount}} />
            </Section.Content>
          </Section>
          )
        }
      }
    }
    </UniFormMed>
  );
};

const evermed_delivery_label = (method) => {
    const price = currency(method.price);
    const formattedPrice = price.value === 0 ? 'Free' : price.format();
  if (method.type === 'Post') {
    return (
      <label>
        <div className="flex w-full justify-between">
          <span>{method.displaySubtext}</span>
          <span className="font-semibold">{formattedPrice}</span>
        </div>
      </label>
    );
  } else if (method.type === 'Courier') {
    return (
      <label>
        <div className="flex w-full justify-between">
          <span>
            {method.displayName} {method.displaySubtext}
          </span>
          <span className="font-semibold">{formattedPrice}</span>
        </div>
      </label>
    );
  }
};

const EvermedDeliveryOptions = (props) => {

  const { evermed, values, valids, uf_this, fields, formData, discount } = props;

  const confirmationModalReducer = (state, action) => {
    const initial_state = { isOpen: false, selectedMethod: undefined }
    switch(action.type){
      case 'open':
        return {isOpen: true, selectedMethod: action.payload}
      case 'close':
        return initial_state
      case 'accept':
        uf_this.handleInputChange(null, { name: 'delivery', value: `EM_${state.selectedMethod.code}` });
        return initial_state;
    }
  }

  const [ modalState, modalReducer] = useReducer(confirmationModalReducer, { isOpen: false, selectedMethod: undefined });
  
  // If a evermed delivery method has been selected, check if the method is still available after methods refetch.
  // If previously selected options is not in the new delivery methods list, remove previous selection.
  useEffect(() => {
    if (values.delivery && !evermed.loading) {
      const flat_del_ops = evermed.do?.map((method) => method.options).flat();
      if (!flat_del_ops.includes(values.delivery)) {
        uf_this.handleInputChange(null, { name: 'delivery', value: null });
      }
    }
  }, [evermed?.do]);

  const handleDeliverySelection = ({ value }) => {
    const flat_del_ops = evermed.do?.map((method) => method.options).flat();
    const selectedMethod = flat_del_ops.find((opt) => opt.code === value.replace('EM_', ''));
    if (selectedMethod.providerName === 'DoorDash' && !selectedMethod.hasAuthorityToLeave) {
      modalReducer({type: 'open', payload: selectedMethod})
    } else {
      uf_this.handleInputChange(null, { name: 'delivery', value });
    }
  };

  return (
    <Form.Field error={valids && !valids.delivery}>
      {!evermed.loading &&
        evermed.do?.length > 0 &&
        JSON.parse(JSON.stringify(evermed.do)).map((method) => (
          <React.Fragment key={method.code}>
            <h4 className="mb-1">{method.displayName}</h4>
            {deliveryCategoryLabel(method.code, method.options)}
            {method.options.map((opt) => {
                opt.code === 'StandardPost' && discount?.conf?.free_standard_shipping && (opt.price = 0);

                return <Checkbox
                    key={opt.code}
                    className="w-full my-2"
                    radio
                    label={evermed_delivery_label(opt)}
                    name="delivery"
                    value={`EM_${opt.code}`}
                    data-testid="radio-delivery-method"
                    checked={values.delivery === `EM_${opt.code}`}
                    onChange={(e, { value }) => handleDeliverySelection({ value })}
                />
            })}
            <CourierConfirmationModal {...{modalState, modalReducer}} />
          </React.Fragment>
        ))}
      {evermed.loading && (
        <div className="flex-row space-x-2">
          <Loader inline active size="tiny" />
          <span>Loading delivery options...</span>
        </div>
      )}
      {!evermed.loading && evermed?.delRes !== 'ok' && <p className="text-red-600 font-semibold">{deliveryResErrorLabel(evermed?.delRes)}</p>}
    </Form.Field>
  );
};


const CourierConfirmationModal = ({ modalState, modalReducer }) => {
  const { isOpen } = modalState;
  const closeModal = () => modalReducer({type: 'close'})
  return (
    <Modal onClose={closeModal} open={isOpen} dimmer="blurring">
      <h3 className="text-md p-4 pb-0 mb-1">Important Courier Delivery Information</h3>
      <Modal.Content>
        <Modal.Description>
          <p>Orders delivered by courier cannot be left unattended. An adult over the age of 18 must be available to accept delivery.</p>
          <p>The courier will need to scan the recipient's valid government-issued photo ID into their DoorDash app to verify their age.</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Go Back</Button>
        <Button content="I agree" labelPosition="right" icon="checkmark" onClick={() => modalReducer({type: 'accept'})} positive />
      </Modal.Actions>
    </Modal>
  );
};


export default EvermedDeliverySection;
