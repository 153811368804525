import React, { Component} from 'react'
import { Helmet } from "react-helmet"

import {
		Table,
		Popup,
		Button,
		Divider,
		Icon,
		Container,
		Segment,
		Header,
		Message,
		Modal,
		Confirm,
		Checkbox, 
		Form,
		Input
	} from 'semantic-ui-react'

import { ObjectInspector, chromeLight } from 'react-inspector';

import API_service from 'xAppLib/providers/API_service'

import user_model from 'models/user_model'

import presc_model from 'models/presc_model'
import prescs_model from 'models/prescs_model'

import docs_model from 'models/docs_model'
import doc_model from 'models/doc_model'
import cat_model from 'models/cat_model'

import {DataConnector, DataShow, ListFilters, ListPagination, ListTable} from 'xAppLib/DataTable'
import {ViewEditDets} from 'xAppLib/DataList'

import InnerScriptsList from 'views/scripts/modules/InnerScriptsList'
import ActBtns from 'views/UIelems/fields/ActBtns'
import scripts_list_model from "../../../models/scripts_list_model";
import moment from 'moment-timezone'
import {obj_map} from "../../../xAppLib/helpers/obj_map";

const WrapComponent = ({embedded, children, hdr}) => {
	return embedded ? <div>{children}</div> : 
						<Container className='wide-content' fluid>
							<Helmet>
								<title>{hdr}</title>
							</Helmet>
							<h1 className="text-is-blue text-xl">{hdr}</h1>
							{children}
						</Container>
}

function docOptionText(doc, count) {
	const name = doc.n ?? '?';
	const path = doc.path_name.split('/').slice(0, -1).join(' > ');
	const inactive = doc.active === false && 'inactive';
	const extra = [path, inactive].filter(Boolean).join(', ');

	return `${name} (${extra}) (${count})`;
}

const innerScriptsListAccessLevel = 'cust_supp';

export default class Prescs extends Component {

	constructor (props) {
		super(props)

		// user_model.limit_access('admin');
		user_model.limit_access(props.unactioned_followups_letter ? 'followup_letter' : 'prescs');

		const { show_list } = props.match&&props.match.params||props

		doc_model.get_sel_docs_list()

		this.state = {
			filterOptions: {},
			dc_filters:{
				hide_user_referral:true,
				hide_org_referral:true,
				...this.props.filters
			},
			pageSize:props.pageSize && props.pageSize || 50,
			page:1,
			show_list,
			scripts_toggle:{},
		}
		
	}


	//	------------------------	------------------------	------------------------

	async componentDidMount () {
		this.setState(Prescs.set_list_params(this.props))

		const [docs, _cats] = await Promise.all([
			docs_model.get_flat_cats(),
			cat_model.get_cat_list({cat_nm: 'pharm', all: true})
		]);

		this.setState({docs});
	}

	componentDidUpdate(prevProps) {
		const prev_show_list = prevProps.match?.params?.show_list;
		const curr_show_list = this.props.match?.params?.show_list;

		if (prev_show_list !== curr_show_list) {
			this.setState(Prescs.set_list_params(this.props))
		}
	}

	//	------------------------	------------------------	------------------------

	static set_list_params (props) {

		const { show_list } = props.match&&props.match.params||props
		// let hdr, flds
		let list

		if (app.user?.claims?.doc) {
			if (show_list === 'outstanding') {
				const search = new URLSearchParams(window.location.search);
				const doc = (search.get('doc') ?? '').split(',').filter(Boolean);
				list = {
					hdr: 'Outstanding Prescriptions',
					flds: prescs_model.LIST_FLDS_DOC_OUTSTANDING,
					...(doc.length > 0 && {dc_filters: {doc}})
				}
			} else {
				list = {
					hdr: 'View My Prescribed',
					flds: prescs_model.LIST_FLDS_DOC,
					// edit_flds: presc_model.EDIT_FLDS_DOC,
				}
			}
		}
		else if(app.user?.claims?.admin || app.user?.claims?.supp)
			if(props.unactioned_followups){
				list = { 
					hdr: 'Unactioned Followups',
					flds: prescs_model.LIST_FLDS_UNACTIONED_FOLLOWUP,
					edit_flds: presc_model.EDIT_FLDS_ADMIN,
				}
			} else if (props.unactioned_followups_letter){
				list = {
					hdr: 'Follow up Letters',
					flds: prescs_model.LIST_FLDS_FOLLOWUP_LETTER,
					edit_flds: presc_model.EDIT_FLDS_ADMIN,
				}
			} else if (show_list === 'outstanding') {
				const search = new URLSearchParams(window.location.search);
				const doc = (search.get('doc') ?? '').split(',').filter(Boolean);
				list = {
					hdr: 'Outstanding Prescriptions',
					flds: prescs_model.LIST_FLDS_ADMIN,
					edit_flds: presc_model.EDIT_FLDS_ADMIN,
					...(doc.length > 0 && {dc_filters: {doc}})
				}
			} else {
				list = { 
					hdr: 'View All Prescribed',
					flds: prescs_model.LIST_FLDS_ADMIN,
					edit_flds: presc_model.EDIT_FLDS_ADMIN,
				}
			}
		else if(app.user?.prof?.xtra?.show_followup_letter){
			list = {
				hdr: 'Follow up Letters',
				flds: prescs_model.LIST_FLDS_FOLLOWUP_LETTER,
				edit_flds: presc_model.EDIT_FLDS_ADMIN,
			}
		}

		else
			list = { 
				hdr: 'View your organisation prescs',
				flds: prescs_model.LIST_FLDS_ORG,
				edit_flds: presc_model.EDIT_FLDS_ORG
			}

		return {
			...list,
			show_list,
		};
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	get is_admin() {
		return app.user?.claims?.admin
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	get is_doc() {
		return app.user?.claims?.doc
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	get is_supp() {
		return app.user?.claims?.supp
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	act = async (a, p) => {

		return await API_service.load_data( a, p )
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	onFilterChange = (selection, commit_now = false) => {
		const { dc_filters } = this.state
		const omitEmptySelections = obj_map(selection??{}, value => Array.isArray(value) && !value.length ? undefined : value);
		const state = {
			dc_filters:!selection?{}:{...dc_filters,...omitEmptySelections}
		}
		if (commit_now) {
			state.dc_filters = {...state.dc_filters}
		}
			
		this.setState(state)
	}

	onSearch = () => {
		this.setState({dc_filters:this.state.dc_filters})
	}
	
	// 		--------------------------------		--------------------------------		---------
	
	
	getData = async (filters, page, pageSize)=>{
		const { show_list } = this.state
		const {unactioned_followups, unactioned_followups_letter} = this.props
			let result
			if(unactioned_followups){
				result = await prescs_model.unactioned_followup_presc_list({
					filters, 
					get_stats: true,
					total_count: true,
					unactioned_followups
				})
			} else if(unactioned_followups_letter){
				result = await prescs_model.unactioned_followup_letter_list({
					filters,
					get_stats: true,
					total_count: true,
					unactioned_followups
				})
			}else {
				result = await prescs_model.load_prescs({
					filters, 
					limit: pageSize,
					offset: (page-1) * pageSize,
					get_stats: true,
					total_count: true,
					show_list,
				})

				result?.prescs?.map( p => p.med__mid = p?.med?.mid )
				result?.prescs?.map( p => p.dets__msg = p?.dets?.msg )
				result?.prescs?.map( p => p.dets__cost = p?.dets?.cost )
				result?.prescs?.map( p => p.doc__doc_id = p?.doc?.doc_id )
				result?.prescs?.map( p => p.ref__sid = p?.ref?.sid )

				result?.prescs?.map( p => p.show_row_warning = ['annul', 'used'].includes(p.status) )
				result?.prescs?.map( p => p.show_row_error = ['cancel'].includes(p.status) || p.enabled!=undefined&&!p.enabled )
			}
		
			// result?.prescs?.map( p => p.show_row_disabled = p.enabled!=undefined&&!p.enabled )

			// console.log('loaded result', result);

			if (result){
                if ('filter_options' in result) {
                    this.setState({filter_options: result.filter_options});
                }

                return { data: result.prescs, total: result.total }
			}
			return null

	}
	
	handleRef = node => {
		node && this.setState({ buttonNewDiscount:node.ref })
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async save_presc (prid, d) {

		this.setState({save_presc_status: this.is_admin ? `Sending request for ${d.code || 'new code'}` : "Saving"})
		
		const data = { 
			...d,
			// users:d.users && {email:d.users},
			// meds:d.meds && {include:d.meds},
			// max_use:d.max_use === '' ? null : d.max_use
			dets: {msg:d.dets__msg, cost:d.dets__cost},
			med: {mid:d.med__mid},

			doc: {doc_id:d.doc__doc_id},
			ref: {sid:d.ref__sid},
		}

		console.log('save_presc', data);

		await prescs_model.save_presc( prid, data, r=>{this.setState({save_presc_status: r});this.setState({refresh:new Date().getTime()});} )
		
	}
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	async save_presc_fld (prid, fl, vl) {

		this.setState({save_presc_status: this.is_admin ? `Sending save field request for ${fl || 'fld'} : ${vl || 'val'}` : "Saving field"})
		
		console.log('save_presc_fld', prid, fl, vl);

		return await prescs_model.save_presc_fld( prid, fl, vl, r=>{this.setState({save_presc_status: r});this.setState({refresh:new Date().getTime()});} )
		
	}
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async presc_action (prid, act) {

		this.setState({save_presc_status: this.is_admin ? `Running action ${act}` : "Saving"})

		const result = await prescs_model.get_record( null, null, {act, prid});

		this.setState({save_presc_status: result});
		this.setState({refresh:new Date().getTime()});

		return result;
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	edit_presc_render() {
		
		const {  save_presc_status, openEditDiscount, buttonNewDiscount, editData, editMode = 'popup' } = this.state
		
		const prid = editData&&editData.prid
		
		let data = {}
		
		if (editData) {
			data = {
				...editData,
				// expiry_date : editData.expiry_date && moment(editData.expiry_date).format('YYYY-MM-DD'),
				// users :  editData.users && editData.users.email,
				// meds :  editData.med.mid
			}
		}

		// console.log('edit_presc_render', editData, data);
		
		const editControl = <ViewEditDets 
				newRecord = {true}

				itm_data = {data} 
				
				onChange={(name,value,values) => {
					if (name=='type') {
						return {
							conf : {...presc_model.BASE_CONF, ...(presc_model.TYPES[value].conf)}
						}
					}
					
				}}

				det_fields = {this.state.edit_flds}
				onSave_dets = { (d, ca) => {
					
					this.save_presc(prid,d, ca)
				} }

				btn_submit = {
						<Button primary fluid content='Save'  />
					}

				btn_cancel = {
						<span></span>
					}
			/>

		const summary = save_presc_status && save_presc_status.res=='ok' && `SUCCESS - Prescription saved`
							|| save_presc_status && save_presc_status.res=='err' && `ERROR - ${save_presc_status.err_msg}`
							|| save_presc_status ||
							false
							
		let header = editData && "Edit Prescription" || "New Prescription"
	
		return <div style={{marginBottom:'20px'}}>
				

				{editMode == 'popup' && 
					<Popup
							context={buttonNewDiscount}
							open={openEditDiscount} 
							flowing
							basic
							position='bottom left'
							style={{width: '600px'}}
						>
						<Popup.Header>
							{header}
							<Icon link name='close' style={{float:'right'}} onClick={_=>this.setState({openEditDiscount:false})} />
						</Popup.Header>
						<Popup.Content className='add-user'>
							
							{editControl}

							{summary && <Message
								error = {save_presc_status && save_presc_status.res=='err'}
								success = {save_presc_status && save_presc_status.res=='ok'}
								header = { summary }
							 />			}				
						</Popup.Content>
					</Popup> 
				}
				{editMode == 'modal' && 
					<Modal
							open={openEditDiscount} 
							onClose={_=>this.setState({openEditDiscount:false})}
							closeIcon
						>
						<Header>{header}</Header>
						<Modal.Content>
							
							{editControl}

							{summary && <Message
								error = {save_presc_status && save_presc_status.res=='err'}
								success = {save_presc_status && save_presc_status.res=='ok'}
								header = { summary }
							 />	}				
						</Modal.Content>
					</Modal> 
				}
		

		</div>
	}
		
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	render () {

		const { show_list } = this.state

		const { filters, page,refresh,pageSize,dc_filters } = this.state

		const {embedded = false, unactioned_followups, unactioned_followups_letter } = this.props

		const is_followup_page = unactioned_followups || unactioned_followups_letter
		const is_outstanding_prescs = show_list === 'outstanding';

		return (
			<WrapComponent embedded={embedded} hdr={this.state.hdr}>
					<DataConnector
						pageSize={pageSize}
						page={page}
						filters={dc_filters}
						refresh={refresh}
						data_params={{show_list, unactioned_followups}}
						data={ this.getData }
						debounceFilters={1000}
						>
						{(data, loading, pages, total, pageSize) => {
							return <React.Fragment>
								{
									<>
									{!this.is_doc && !this.is_supp && !is_followup_page && !show_list && <Button
										ref={this.handleRef} 
										icon  
										compact 
										size={"big"} 
										color="green" 
										onClick={_=>this.setState({openEditDiscount:true,save_presc_status:null,editData:null,editMode:'popup'})}>
											<Icon name='plus' /> Add Prescription
								</Button>}
								{this.edit_presc_render()}
								{!embedded && (this.is_admin || this.is_supp || (is_outstanding_prescs && this.is_doc)) && <React.Fragment>
								<ListFilters start_open={is_outstanding_prescs}
											 popup
											 selected={this.state.dc_filters}
											 onReset={_=>this.onFilterChange(null,true)}
											 btn_search={true}
											 onSearch={ this.onSearch }
								>
									<Segment.Group horizontal>

										{!this.is_doc && <Segment  color='green'>
											<Header>{is_followup_page ? 'Prescription Number' : 'Prescription / Patient / Type / Status / UR / Doctor (Ref Sid)'}</Header>
											<ListFilters.Filter 
													as="text"
													placeholder="Filter text"
													onFilterChange={ this.onFilterChange }
													name="filter_text"
													value={this.state.dc_filters['filter_text']}
													fluid
												/>
										</Segment>}

										{is_outstanding_prescs && (
											<Segment  color='green'>
												<Header>Doctor</Header>
												<ListFilters.Filter
													as='dropdown'
													onFilterChange={this.onFilterChange}
													name="doc"
													value={this.state.dc_filters.doc}
													options={this._doc_filter_options()}
												/>
											</Segment>
										)}

									</Segment.Group>
	
								</ListFilters>

								<Button
									content={'Reload List'}
									icon="refresh"
									onClick={_=>this.setState({refresh:new Date().getTime()})}
									color="grey"
									type="button"
									loading={loading}
									style={{marginLeft: 'auto',display:'block'}}
								 />
							 </React.Fragment>}

								{!is_followup_page && <ListPagination
										page={page} 
										pages={pages} 
										pageSize={pageSize}
										loading={loading} 
										data={data} 
										total={total} 
										boundaryRange={3}
										showPageSizeSelector={true}
										onPageSizeChange={pageSize=>this.setState({pageSize})}
										onPageChange={page=>this.setState({page})}/>}
									</>
								}
								

								<ListTable data={data} loading={loading} rowKey={row => row.prid}>
									{(row,index)=>{
										const toggle_key = `${index}_${index}`
										return <React.Fragment>
											{user_model.check_access(innerScriptsListAccessLevel) && <ListTable.Cell row={row} index={index} label=''>
												{(value,row,index)=>
													<Icon name={this.state.scripts_toggle[toggle_key]?'triangle down':'triangle right'} 
														  onClick={_=>{ this.setState({scripts_toggle:{...this.state.scripts_toggle,[toggle_key]:!this.state.scripts_toggle[toggle_key]}}) }} 
														  />
													}
											</ListTable.Cell>}


											{
												this.state.flds && this.state.flds.map((f, fi)=><ListTable.Cell singleLine row={row} index={index} key={fi} f={f} />)
											}

											
											
											<ListTable.Cell 
													// visible={!this.is_supp} 
													row={row} index={index} label='Act btns' visible={!(app.acl.is_doc && is_outstanding_prescs)}
												>
													{(value,row,index)=>(<React.Fragment>

														<ActBtns 
															row={row} 
															f={{
																	name: 'Status',
																	btns_list_func: (row) => {
																		const isScheduled = row.status === 'scheduled';
																		const canBeScheduled = !!row.activate_at;
																		const isTreatmentPlan = !!row.ref?.tpid;
																		const action_btns = isScheduled ? [
																			{t: 'separator'},
																			{n: 'Activate Now', c: 'green', i: 'send', a: 'act:activate'}
																		] : [];

																		const admin_btns = [
																			canBeScheduled && {n:'Scheduled', a:'scheduled', c:'blue', i:''},
																			!is_followup_page && {n:'Active', a:'active', c:'green', i:''},
																			!is_followup_page && {n:'Used', a:'used', c:'yellow', i:''},
																			!is_followup_page && {n:'Annul', a:'annul', c:'orange', i:''},
																			!is_followup_page && {n:'Cancel', a:'cancel', c:'red', i:''},
																			isTreatmentPlan && {n:'Pending', a:'pending', c:'grey', i:''},
																			...(unactioned_followups ? prescs_model.UNACTIONED_FOLLOWUP_ACT_BTNS : []),
																			...(unactioned_followups_letter ? prescs_model.FOLLOWUP_LETTER_ACT_BTNS : []),
																			unactioned_followups_letter &&
																			(app.user?.claims?.admin || app?.user.claims?.supp) &&
																			{
																				n: 'Letter Not Needed',
																				a: 'letter_not_needed',
																				c: 'red',
																				i: 'exclamation',
																			},
																			...action_btns,
																		].filter(x=>x);

																		const doc_btns = [
																			{n:'Cancel', a:'cancel', c:'red', i:''},
																			...action_btns,
																		];

																		return this.is_doc ? doc_btns : admin_btns;
																	},

																	btns_stat_param: 'status',
																	btns_stat_dict: (stat) => {
																			if(is_followup_page) return ''
																			if (['done_doccall'].includes(stat))	return 'Consult finished';

																			return stat==null ? 'not set' : stat
																		},

																	// btns_stat_dd_opts: SCRIPT_STATUS_OPTIONS,
																	act_run: async (b, row, mod) => {
																		const [type, action] = b.a.split(':');
																		if (type === 'act') {
																			return this.presc_action(row.prid, action);
																		}
																		if (['made_contact', 'attempted_contact'].includes(b.a)){
																			const {uid, first_name, last_name, email, claims} = (app?.user?.profs[0]) || {}
																			const now = moment().tz('Australia/Melbourne').format()
																			const new_hist = [...row.dets.reminder.hist, {
																				type: 'admin_phone',
																				user: {
																					uid, first_name, last_name, email, claims
																				},
																				outcome: b.a,
																				time: now,
																			}]
																			return this.save_presc_fld(row.prid, 'dets', {...row.dets, reminder: {
																				...row.dets.reminder,
																				hist: new_hist,
																				admin_action_required: false, 
																				admin_outcome: b.a, 
																				outcome_msg: b.p?.msg,
																				count: new_hist.length,
																				last_reminder: now

																			}})
																		}
																		if(['letter_sent', 'letter_not_needed'].includes(b.a)){
																			const {uid, first_name, last_name, email, claims} = (app?.user?.profs[0]) || {}
																			const now = moment().tz('Australia/Melbourne').format()
																			const new_hist = [...row.dets.reminder.hist, {
																				type: b.a,
																				user: {
																					uid, first_name, last_name, email, claims
																				},
																				...(b.a === 'letter_sent' && {tracking_number: b.p.msg}),
																				time: now,
																			}]
																			return this.save_presc_fld(row.prid, 'dets', {...row.dets, reminder: {
																				...row.dets.reminder,
																				hist: new_hist,
																				admin_action_required: false,
																				letter_actioned: true,
																				count: new_hist.length,
																				last_reminder: now
																			}})
																		}

																		return this.save_presc_fld(row.prid, 'status', b.a)
																	}
,
																}}
															/>
														
													</React.Fragment>)}
											</ListTable.Cell>

											{!is_followup_page && <ListTable.Cell
													visible={!app.acl.is_doc}
													row={row} index={index} label='Actions'
												>
													{(value,row,index)=>(<React.Fragment>

														<Checkbox	
															defaultChecked = {row.enabled}
															onChange = { (e, d) => this.save_presc_fld(row.prid, 'enabled', d.checked) }
															// onChange = { (e, d) => console.log(d.checked) }
															toggle
															style = {{padding:0,alignSelf:'center'}} 
															key = {row.prid}
														/>
														<ActBtns row={row} f={{
															btns_list_func: () => {
																return [
																	{
																		n: 'Edit', i: 'edit', a: _ => {
																			this.setState({openEditDiscount:true,save_presc_status:null,editData:row,editMode:'modal'})
																		}
																	},
																	{
																		n: 'Delete', i: 'x', c: 'red', a: _ => {
																			this.setState({openDeleteDiscount: row.prid})
																		}
																	},
																	['req_icanncons_flup', 'req_doccons_flup'].includes(row.type) && row.status === 'active' && {
																		n: 'Resend Pts Cons Followup Email',
																		i: 'clipboard check',
																		a: {
																			email_type: 'cre_followup_consult',
																			name: 'Patient Followup Email'
																		}
																	},
																	['presc_icann', 'presc_ihair'].includes(row.type) && row.status === 'active' && {
																		n: `Resend Pts Med Presc'd Email`,
																		i: 'pills',
																		a: {
																			email_type: 'scr_pts_presc',
																			name: `Pts Med Presc'd Email`
																		}
																	}
																].filter(Boolean);
															},
															act_run: async (btn, row) => {
																if (btn.a instanceof Function) {
																	return btn.a();
																}
																const {email_type, name} = btn.a;
																const res = await API_service.load_data('prescs', {
																	act: 'resend_email',
																	email_type,
																	prid: row.prid
																})
																app.trigger(app.events.SHOW_MESSAGE,
																	'Prescriptions Admin',
																	res.res === 'ok' && `Re Sent ${name} :: ${res.to}` || `ERROR Re Sending ${name} :: ${res.to} -- ${res.err}`,
																	res.res === 'ok' && 'positive' || 'negative'
																);
																return res;
															}
														}}/>
													</React.Fragment>)}
											</ListTable.Cell>}

											{(app.user?.claims?.admin || app.user?.claims?.supp) && <ListTable.Cell
													visible={user_model.check_access('admin')} 
													row={row} index={index} label='Raw'
												>
													{(value,row,index)=>(<><Popup
															trigger={<Button icon='plus' size="mini" />}
															position='left center'
															on='click'
															style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll'}}
													>
														<ObjectInspector
																data={ row }
																expandLevel={ 1 }
																theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
															 />
													</Popup>
													{DataShow.show_data_field(row, scripts_list_model.DET_FIELDS_HIST[0])}</>)}
											</ListTable.Cell>}


										</React.Fragment>
									}}
									
									{
										(row,index)=>{
										const toggle_key = `${index}_${index}`
										
										if (!user_model.check_access(innerScriptsListAccessLevel) || !this.state.scripts_toggle[toggle_key])
											return null
										
										return <React.Fragment>
											<Table.Cell colSpan={12}><InnerScriptsList filters={is_followup_page ? {sid: row.sid} : {prid:row.prid}} /></Table.Cell>
										</React.Fragment>
									}}
								</ListTable>
								{!is_followup_page && <ListPagination
										page={page} 
										pages={pages} 
										pageSize={pageSize}
										loading={loading} 
										data={data} 
										total={total} 
										boundaryRange={3}
										showPageSizeSelector={true}
										onPageSizeChange={pageSize=>this.setState({pageSize})}
										onPageChange={page=>this.setState({page})}/>}
							</React.Fragment>
						}}
						


					</DataConnector>

					<Confirm
						header='Delete presc code'
						open={!!this.state.openDeleteDiscount}
						onCancel={_=>this.setState({openDeleteDiscount:false})}
						onConfirm={async _=>{
							await prescs_model.delete_presc(this.state.openDeleteDiscount)
							this.setState({openDeleteDiscount:false,refresh:new Date().getTime()})
						}}
					/>
			</WrapComponent>
		)
	}

    _doc_filter_options() {
        const {docs, filter_options} = this.state;

        return (filter_options?.doc ?? [])
			.sort((a, b) => b.tot - a.tot)
            .map(({val: doc_id, tot}) => ({
                key: doc_id,
                value: doc_id,
                text: docs && doc_id in docs
                    ? docOptionText(this.state.docs?.[doc_id], tot)
                    : `${doc_id} (${tot})`,
            }))
    }

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

}
