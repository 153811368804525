import React from 'react';
import UniFormMed from 'views/med/UniFormMed';
import script_model from 'models/script_model';
import { Form, Checkbox, Icon } from 'semantic-ui-react';

const DVASection = (props) => {
  const { store, Section, cart, evermed } = props;
  return (
    <UniFormMed {...props} section="dva_conc_eligible" fields={script_model.DVA_SECTION_FIELDS}>
      {(values, valids, uf_this, fields) => {
        return (
          <Section>
            <Section.Header>DVA Concession</Section.Header>
            <Section.Content>
              <p>Are you eligible to receive concession pricing for this medication under your DVA policy?</p>
              <Form.Field error={valids && !valids.dva_conc_eligible}>
                {['yes', 'no'].map((o) => (
                  <Checkbox
                    key={o}
                    radio
                    label={o}
                    style={{ textTransform: 'capitalize' }}
                    checked={values.dva_conc_eligible === o}
                    onClick={(e, { value }) => {
                      uf_this.handleInputChange({ target: { name: 'dva_conc_eligible', value: o } });
                    }}
                  />
                ))}
              </Form.Field>
            </Section.Content>
          </Section>
        );
      }}
    </UniFormMed>
  );
};

export default DVASection;
