import React, { Component } from 'react';

import { Icon } from 'semantic-ui-react'

import getNestedObject from 'xAppLib/helpers/getNestedObject'

export default class KeyName extends Component {

	constructor(props) {
		super(props);
	}

	state = {
		loaded: false,
	}

	// 		--------------------------------		--------------------------------		---------

	componentDidMount () {
		const { k, loc, db_get, data_db } = this.props

		// console.log('KeyName db_get', k, loc, loc.replace(/\//g, '/i/'), data_db, data_db[loc], data_db[loc.replace(/\//g, '/i/')], getNestedObject(data_db, loc.replace(/\//g, '/i/'), '/'));

		data_db && this.setState({
						loaded: true,
						val: getNestedObject(data_db, loc.replace(/\//g, '/i/'), '/')?.n || data_db[loc.replace(/\//g, '/i/')]?.n || data_db[loc]?.n || `...${k}`,
					})

		db_get && db_get( loc.replace(/\//g, '/i/'), d=>!this.donotshow&&this.data_loaded(d) )

	}
	
	componentDidUpdate(prevProps, prevState) {
		if  (prevProps.loc != this.props.loc) {
			this.componentDidMount()
		}
	}

	// 		--------------------------------		--------------------------------		---------

	componentWillUnmount () {
		
		this.donotshow = true;

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	data_loaded(d) {
		const { k } = this.props

		this.setState({
						loaded: true,
						val: d && d.n || d && d.name || `...${k}`,
					})

	}

	// 		--------------------------------		--------------------------------		---------

	render() {

		const { k, loc } = this.props
		const { loaded, val } = this.state

		// console.log('KeyName', loc, '|||', k, '|||', loc.replace(/\//g, '/i/'), '|||', val);

		return 	loaded && <React.Fragment>{val}</React.Fragment> || <Icon loading size='small' name='spinner' />
	}

}
