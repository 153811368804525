import React from 'react';

const Disclaimer = ({enabled, visible }) => {
	if (!enabled || !visible)
		return null
	return <p className='disclaimer' style={{marginTop: '1rem', fontSize:'14px'}}>
				By clicking continue you accept our <a
				style={{fontSize:'14px',textDecoration:'underline', textAlign: 'right', cursor: 'pointer'}}
				onClick={ _=> app.runtime.mode=='caia' && window.open('https://caia-app-web-content.webflow.io/caia-app/app-privacy-policy', 'tnc') 
								|| app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Privacy Policy',pn:'privacy-policy' }) 
						}
				>Privacy Policy</a>
				{(app.runtime.mode=='ileg' || app.runtime.mode=='cmg' || app.runtime.mode=='cbls')  && <> and <a
				style={{fontSize:'14px',textDecoration:'underline', textAlign: 'right', cursor: 'pointer'}}
				onClick={ _=> app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Terms & Conditions',pn:'terms-and-conditions', target:app.runtime.mode})}
				>Terms & Conditions</a></> || 
				app.runtime.mode!='caia' && <>, <a style={{fontSize:'14px',textDecoration:'underline', textAlign: 'right', cursor: 'pointer'}}
				onClick={ _=> app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Platform Terms & Conditions',pn:'platform-terms-and-conditions' })}
				>Platform Terms & Conditions</a> and <a style={{fontSize:'14px',textDecoration:'underline', textAlign: 'right', cursor: 'pointer'}}
				onClick={ _=> app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Customer Terms & Conditions',pn:'customer-terms-and-conditions' })}
				>Customer Terms & Conditions</a></>}. You will be added to our subscriber list, you can unsubscribe at any time.
			</p>;
}
 
export default Disclaimer;


