const { useReducer, useCallback, useMemo } = require("react");

const DEBUG = false

function reducer(state, action) {
    const { type, key, value } = action
    DEBUG && console.log("~~reduce~~",action.type,key,value);
    switch (type) {
        case 'set':
			return {...state,[key]:value}
        case 'del': {
            const tmp = {...state}
            delete tmp[key]
			return tmp
        }
		case 'add':{
			return {...state,[key]:[...(state[key]||[]),value]}
        }
		case 'addN':{
			return {...state,[key]:[...(state[key]||[]),...value]}
        }
		case 'remove':{
			return {...state,[key]:(state[key]||[]).filter(v=>v!=value)}
        }
		case 'removeAt':{
			return {...state,[key]:(state[key]||[]).filter((v,i)=>i!=action.index)}
        }
		case 'reset':
			return {...value}
		case 'exec':
			return action.callbackFn.apply(null,[state,action])
		default:
			return state
	}
}

export function useDefaultReducer(defaultValues, custom_reducer = null) {
    let _reducer = reducer
    if (custom_reducer) {
        _reducer = function(state, action) {
            const ret = custom_reducer(state,action)
            if (ret !== undefined)
                return ret
            return reducer(state,action)
                
        }
    }
	const [userData, dispatch] = useReducer(_reducer, defaultValues);

    const actions = useMemo(()=>{
        return {
            set(key,value){
                dispatch({type:'set',key,value})
            },
            del(key,value){
                dispatch({type:'del',key,value})
            },
            add(key,value){
                dispatch({type:'add',key,value})
            },
            addN(key,value){
                dispatch({type:'addN',key,value})
            },
            remove(key,value){
                dispatch({type:'remove',key,value})
            },
            reset(){
                dispatch({type:'reset',value:defaultValues})
            },
            exec(callbackFn){
                dispatch({type:'exec',callbackFn})
            }
        }
    },[dispatch])

    return useMemo(()=>[userData, actions,dispatch],[userData,actions, dispatch])
}
