import React, { useState, useEffect } from "react";
import { Form,Message } from "semantic-ui-react";

const DocCallWarning = (props) => {
	const { enabled, visible, store, med_data, script_type, req_type } = props

    if (!enabled || !visible)
        return null

	return (
		<Message
            header = {`Doctor may call you in the next 20 minutes on ${store.mobile || 'the contact number you provide'} to discuss your request.`}
            list = {["If this number is incorrect, go to your details section above and edit it"]}
            content = "Please be available to receive the call from our doctor. Otherwise, your request may be declined."
            color = {'yellow'}
        />
	);
};

export default DocCallWarning;

