import firebaseApp from './firebase.js';
import { getDatabase,
	onValue,
	ref as fireRef,
	serverTimestamp,
	update,
	push,
	set,
	get,
	remove,
	query,
	orderByChild,
	startAt,
	limitToLast,
	equalTo,
	onChildChanged,
	goOffline,
	goOnline,
	off, onDisconnect
} from 'firebase/database';

const DEBUG = false;
const db = getDatabase(firebaseApp);

function describeQuery(loc, extra) {
	return [
		loc,
		extra?.where_key && `?${extra.where_key}=${extra.where_val}`,
		extra?.limit_last && `[${extra.limit_last}]`,
	].filter(Boolean).join('')
}

export const watchers = (() => {
	let id=0;
	let active = [];
	const debug = (...args) => app.settings.is_local && console.debug(...args);
	return {
		count() {
			return active.length;
		},
		add(loc, extra) {
			const desc = [
				loc,
				extra?.where_key && `?${extra.where_key}=${extra.where_val}`,
				extra?.limit_last && `[${extra.limit_last}]`
			].filter(Boolean).join('');
			const record = {id, loc, extra, desc};
			id++;
			active = active.concat(record);
			debug('ON  %c%s %c%o', 'color:forestgreen', desc, 'color:inherit', {active});
			return function remove() {
				active = active.filter(r => r !== record);
				debug('OFF %c%s %c%o', 'color:orangered', desc, 'color:inherit', {active});
			};
		}
	}
})();

export default class firebase_database {

	static _subscribers = [];

	static onData(fn) {
		if (!firebase_database._subscribers.includes(fn)) {
			firebase_database._subscribers.push(fn);
		}

		return () => {
			firebase_database._subscribers = firebase_database._subscribers.filter(f => f !== fn);
		};
	}

	static makeSubscriberNotifier(description, type, fn = snapOrDbRef => snapOrDbRef) {
		const start = Date.now();
		let count = 0;
		return function notifySubscribers(snapOrDbRef) {
			count++;
			const elapsed = Date.now() - start;

			firebase_database._subscribers.forEach(subscriber => {
				try {
					subscriber(snapOrDbRef, type, description, count, elapsed);
				} catch (e) {
					// do nothing
				}
			});

			return fn(snapOrDbRef);
		}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	static ref (loc) {

		// console.log('firebase_database :: ref ', loc)

		return fireRef(db, loc)

		// localStorage.setItem(this.loc_store_name, JSON.stringify(this.data));

		// this.call_after_update(this.data)
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static watch_record (loc, ca, p) {

		DEBUG && console.log('firebase_database :: watch_record ', loc, p)

		const watch = this.makeSubscriberNotifier(describeQuery(loc, p), 'watch', data => {

			DEBUG && console.log('firebase_database :: watch_record GOTVALUE :: ', loc, p, data.val(), data.key)

			ca && ca (data.val(), data.key)
		});

		const error = (errorObject) => {

			console.log('firebase_database :: watch_record read FAILED :: ', loc, p, errorObject.code)

			p && p.ce && p.ce (errorObject)
		}

		let constraints = [];

		if (p && p.where_key && p.where_val && p.where_type == 'fro') {

			constraints = [startAt(p.where_val), orderByChild(p.where_key)];
		}

		else if (p && p.where_key && p.where_val) {

			constraints = [equalTo(p.where_val), orderByChild(p.where_key)];

		}

		if (p?.limit_last) {
			constraints.push(limitToLast(p.limit_last));
		}

		const ref = query(fireRef(db, loc), ...constraints);
		onValue(ref, watch, error)
		const remove = watchers.add(loc, p);

		return () => {
			remove();
			off(ref, 'value', watch)
		}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static watch_changing_records (loc, ca, p) {

		console.log('firebase_database :: watch_changing_records ', loc, p)

		onChildChanged(fireRef(db, loc), (data) => {

						DEBUG && console.log('firebase_database :: watch_record GOTVALUE :: ', loc, p, data.val())

						ca && ca (data.val())
					},
					(errorObject) => {

						console.log('firebase_database :: watch_record read FAILED :: ', loc, p, errorObject.code)

						p && p.ce && p.ce (errorObject)
					}
				);

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async get_record (loc, deb=DEBUG) {

		// console.log('firebase_database :: get_record ', loc)

		let off;
		return new Promise((resolve, reject) => {
			const watch = snap => resolve(snap.val());

			off = onValue(query(fireRef(db, loc)), watch, reject, { onlyOnce: true });
		})
			.then(firebase_database.makeSubscriberNotifier(describeQuery(loc), 'get'))
			.finally(off);


		// const r = await get(fireRef(db, loc));
			// .then(
			// 		data => {

			// 			deb && console.log('firebase_database :: get_record GOTVALUE :: ', loc, data.val())

			// 			// ca && ca (data.val())
			// 			return data.val()
			// 		},
			// 	)

		// return r.val()
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async add_record (loc, d, p, ca, ce) {

		// firebase.database().ref().child('posts').push().key;

		// console.log('firebase_database :: add_record', loc, d)

		if (p && p.timestamp) {
			d = {...d, tm: serverTimestamp(), tm_str: new Date().toString() };
		}

		try	{
			const r = await push(fireRef(db, loc), d).then(firebase_database.makeSubscriberNotifier(describeQuery(loc), 'add'));

			DEBUG && loc!='lg' && console.log('firebase_database :: add_record :: COMPLETED', loc, d, r, r.key)
			ca && ca(r.key)								

			if (p?.add_hist) 
				this.add_record(loc+'/'+r.key+'/hist/', 
									{
										act: 'add',
										to: d,
										by: {
												u: app.user.uid,
												n: app.user.user_det.displayName,
												e: app.user.user_det.email,
												d: app.dvc.dvcid,
												// r: user_model.role_name(req.auth_user),
											}, 
									}, 
									{timestamp: true}
								);

			return r.key
		} catch(error) {
			console.error('firebase_database :: add_record :: FAILED :: ', loc, d, "Error: ", error)
		}

		// return firebase
		// 	.database()
		// 	.ref(loc)
		// 	// .ref('user_exps/' + u)
		// 	// .push(!p || !p.timestamp ? d : {...d, tm: firebase.database.ServerValue.TIMESTAMP, tm_str: new Date().toString() })
		// 	.push(d)
		// 	.then( (r) => {
		// 		DEBUG && loc!='lg' && console.log('firebase_database :: add_record :: COMPLETED', loc, d, r, r.key)
		// 		ca && ca(r.key)
		// 	} )
		// 	.catch( (error) => {
		// 		console.error('firebase_database :: add_record :: FAILED :: ', loc, d, "Error: ", error)
		// 	} );
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static set_record (loc, d, p) {

		// firebase.database().ref().child('posts').push().key;

		// console.log('firebase_database :: set_record', loc, d)

		if (p && p.timestamp) {
			d = {...d, tm: serverTimestamp(), tm_str: new Date().toString() };
		}

		set(fireRef(db, loc), d)
		.then(firebase_database.makeSubscriberNotifier(describeQuery(loc), 'set'))
		.then( () => {
			DEBUG && console.log('firebase_database :: set_record :: COMPLETED', loc, d)

				if (p?.add_hist) 
					this.add_record((p.add_hist_loc || loc)+'/hist/', 
									{
										act: 'set',
										to: {
												...(p.add_hist_fld && { f: p.add_hist_fld }),
												v: d,
											},
										by: {
												u: app.user.uid,
												n: app.user.user_det.displayName,
												e: app.user.user_det.email,
												d: app.dvc.dvcid,
												// r: user_model.role_name(req.auth_user),
											}, 
									}, 
									{timestamp: true}
								);

			} )
			.catch( (error) => {
				console.error('firebase_database :: set_record :: FAILED :: ', loc, d, "Error: ", error)
			} );
	}


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static update_record (loc, d, p, ca, ce) {

		// console.log('firebase_database :: update_record', loc, d)

		// var updates = {};
		// updates[loc] = d;

		return new Promise((resolve,reject)=>{


			if (p && p.timestamp)
				d = {
					...d,
					[p.timestamp_fld||'tm']: serverTimestamp(),
					...(p.timestamp_fld !== false && {[(p.timestamp_fld||'tm')+'_str']: new Date().toString()})
				};

			else if (d.constructor == Array)		d = {...d}


			const dbref = p && p.ref || firebase_database.ref(loc)
			update(dbref, d)
				.then(firebase_database.makeSubscriberNotifier(describeQuery(loc), 'update'))
				.then( (r) => {
					DEBUG && console.log('firebase_database :: update_record :: COMPLETED', loc, d, r)
					resolve(r)
					ca?.(r)
					// console.log('firebase_database :: update_record :: COMPLETED', updates)
				} )
				.catch( (error) => {
					console.error('firebase_database :: update_record :: FAILED :: ', loc, d, "Error: ", error)
					// console.error('firebase_database :: update_record :: FAILED :: ', updates, "Error: ", error)
					reject(error)
					ce?.(error)
				} );

			if (p?.add_hist) 
				this.add_record((p.add_hist_loc || loc)+'/hist/', 
									{
										act: 'upd',
										to: d,
										by: {
												u: app.user.uid,
												n: app.user.user_det.displayName,
												e: app.user.user_det.email,
												d: app.dvc.dvcid,
												// r: user_model.role_name(req.auth_user),
											}, 
									}, 
									{timestamp: true}
								);
		})

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static delete_record (loc) {

		// console.log('firebase_database :: delete_record', loc)

		remove(fireRef(db, loc))
			.then(firebase_database.makeSubscriberNotifier(describeQuery(loc), 'delete'))
			.then( () => {
				DEBUG && console.log('firebase_database :: delete_record :: COMPLETED', loc)
			} )
			.catch( (error) => {
				console.error('firebase_database :: delete_record :: FAILED :: ', loc, "Error: ", error)
			} );

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static on_disconnect (loc) {
		return onDisconnect(fireRef(db, loc))
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static bounce_connection() {
		goOffline(db);
		goOnline(db);
	}
}
