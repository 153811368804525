import {useEffect} from 'react';

export function AssertiveRtdbBounce({force = false}) {
    useEffect(() => {
        app.trigger(app.events.BOUNCE_RTDB);
    }, []);

    useEffect(() => {
        let hidden = false;

        function bounceWhenVisible() {
            if (hidden && !document.hidden) {
                app.trigger(app.events.BOUNCE_RTDB, {force});
            }
            hidden = document.hidden;
        }

        document.addEventListener('visibilitychange', bounceWhenVisible);
        return () => {
            document.removeEventListener('visibilitychange', bounceWhenVisible);
        };
    }, [force]);

    return null;
}