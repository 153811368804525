import React, { useState } from 'react';
import { Form, Accordion, Grid, Search, Button, List } from 'semantic-ui-react';
import debounce from 'xAppLib/libs/debounce';
import API_service from 'xAppLib/providers/API_service';
import { useCallback } from 'react';
import YogpManual from "./YogpManual";

const SECOND = 1000;
const INITIAL_RESULTS = 5;
const LOAD_MORE = 10;


const ResultRenderer = ({ address, specialty, organisation, isFormSelected, handleOnChangeClick, onSelect, email, name, edit }) => {
	
    let content = (
        <div>
            <div className="font-bold">{name}</div>
            <div className="font-bold">{email}</div>
        </div>
    );
    
    if (address) {
        const { street, city, stateOrProvince, postalCode } = address;
        const formatAddress = `${street}, ${city}, ${stateOrProvince}, ${postalCode}`;
        content = (
            <div>
                <div className="font-bold">{organisation}</div>
                <div className="font-bold">
                    {name} - {specialty}
                </div>
                <div className="flex flex-row gap-3 mt-2">
                    <img src="https://storage.googleapis.com/instant-med-public/icons/icon-address.svg" alt="address" />
                    <p>{formatAddress}</p>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`flex justify-between items-center grow ${
                !isFormSelected && "cursor-pointer"
            }`}
            onClick={!isFormSelected ? onSelect : undefined}
        >
            {content}
						{edit && (
							<Button compact primary type="button" onClick={handleOnChangeClick}>
								Change
							</Button>
						)}
        </div>
    );
};

const YogpForm = ({ values, valids, uf_this, req_type, script_type, fields, edit = true, renderSaveForLater = true }) => {
	const selected = values.yogp ?? {};
	const [saveDoc, setSaveDov] = useState(true);
	const [search, setSearch] = useState({ value: '', data: [], results: [], isLoading: false });
	const { value, data, results, isLoading } = search;

	const fetchNames = useCallback(
		debounce(async (query) => {
			const res = await API_service.api_promise(`referralnet/find_practitioner?query=${query}`).catch(() =>
				setSearch((prev) => ({ ...prev, isLoading: false, results: [] }))
			);
			const formatData = Array.isArray(res)
				? res.map(({ recipientId, displayName, address, organisation, specialty, emailAddress }) => ({
						key: recipientId,
						name: displayName,
						email: emailAddress,
						address,
						organisation,
						specialty
				  }))
				: [];

			setSearch((prev) => ({ ...prev, isLoading: false, data: formatData, results: formatData.slice(0, INITIAL_RESULTS) }));
		}, SECOND),
		[]
	);

	const formCleanup = () => {
		setSearch({ value: '', results: [], isLoading: false });
		uf_this.handleInputChange(null, { name: 'yogp', value: {} });
	}

	const handleSearchChange = (e, { value }) => {
		setSearch({ value, isLoading: !!value });
		if (value) {
			fetchNames(value);
		}
	};

	const handleResultSelect = (result) => {
		const { key, ...rest }  = result;
		setSearch({ isLoading: false, results: [], value: '' });
		uf_this.handleInputChange(null, { name: 'yogp', value: { ...rest, recipientId: key } });
	};

	const handleCheckboxSelect = (e, { checked }) => {
		uf_this.handleInputChange({ target: { name: 'yogp_want', type: 'checkbox', value: !checked } });
		if (!checked) {
			formCleanup();
		}
	};

	const handleLoadMore = (e) => {
		e.preventDefault();
		const resultsToLoad = Math.min(LOAD_MORE, data.length - results.length) + results.length;
		setSearch((prev) => ({ ...prev, results: data.slice(0, resultsToLoad) }));
	};

	const handleSaveDoc = () => {
		setSaveDov((prev) => {
			fields.yogp.update_profile = !prev;
			return !prev
		});
	}

	return (
		<Accordion fluid>
			{edit && (
				<Accordion.Title active={values['yogp_want']} index={1}>
					<Form.Checkbox
						label={
							(req_type == 'paas' && "Yes, I would like to send a copy of this request patient's regular doctor.") ||
							`Yes, I would like to send my regular doctor information about any ${script_type === 'docrefr' ? 'specialist referrals' : 'scripts'} issued following my consultation`
						}
						size="massive"
						name="yogp_want"
						checked={values['yogp_want']}
						onChange={handleCheckboxSelect}
						key={'form_radio_q_' + 'yogp_want' + '_a_' + 'no'}
					/>
				</Accordion.Title>
			)}


			<Accordion.Content active={values['yogp_want']}>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							{Object.keys(selected).length ? (
								<ResultRenderer {...selected} handleOnChangeClick={formCleanup} isFormSelected={true} edit={edit} />
							) : (
								<>
									<Form.Field label="Search by doctor's name or clinic name" name="yogp_label" error={valids && !valids?.yogp} />
									<Search
										open={false}
										placeholder="Enter Name"
										input={{ fluid: true, autoComplete: 'false', iconPosition: 'left' }}
										loading={isLoading}
										onSearchChange={handleSearchChange}
										value={value}
										fluid
									/>
									{value && (
										<>
											<div className="border my-4 rounded rounded-lg border-gray-200">
												<List divided relaxed>
													{isLoading 
														? <p className="p-4">Searching...</p> 
														: !results?.length 
															? <p className="p-4">No results found</p> 
															: results.map((result) => (
																<List.Item key={result.key} style={{ padding: 15 }} onClick={() => handleResultSelect(result)}>
																	<ResultRenderer {...result} />
																</List.Item>
													))}
												</List>
											</div>
											{data?.length > results?.length && (
												<Button size="small" fluid onClick={handleLoadMore}>
													Load {Math.min(LOAD_MORE, data.length - results.length)} more {data.length - results.length === 1 ? 'result' : 'results'}
												</Button>
											)}
											<YogpManual
												values={values}
												valids={valids}
												uf_this={uf_this}
												handleResultSelect={
													handleResultSelect
												}
											/>
										</>
									)}
								</>
							)}

							{renderSaveForLater && (
								<Form.Checkbox
									label="Save my doctor details for later use"
									size="massive"
									style={{ marginTop: 16 }}
									checked={saveDoc}
									onChange={handleSaveDoc}
								/>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Accordion.Content>
		</Accordion>
	);
};

export default YogpForm;
