
import React, { Component } from 'react'

import {  Button,  Icon } from 'semantic-ui-react'

import moment from 'moment-timezone'

import API_service from 'xAppLib/providers/API_service'

import hi_model from 'models/hi/hi_model'

const hi = new hi_model()

class StartConsult extends Component {
    constructor(props) {
        super(props);
        this.state = { making_consult:false }
    }
    render() { 
        return <Button loading={this.state[`making_consult`]} disabled={this.state.making_consult} labelPosition='left' icon color='green' size='small' onClick={_ => this.run_make_consult()} compact><Icon name='doctor' /> Start Consult</Button>;
    }

    async run_make_consult() {

		if (!confirm('Are you sure?'))
			return false

		const { row } = this.props

		if (this.state.making_consult) {
			console.log('Already making consult')
		} else {
			this.setState({ making_consult: true }, async _ => {

				// Do the IHI verification first
				//const scr_req = await API_service.load_data('reqScriptForm', req_data)
				const verify_res = await hi.verify_ihi({uid: row.uid})

				const patient_data = {
					uid: row.uid,
					first_name: row.first_name,
					last_name: row.last_name,
					email: row.email,
					dob: moment(row.dob).format('YYYY-MM-DD'),
					sex: row.sex,
					medicare: row.medicare,
					dva: row.dva,
					conc_card: row.conc_card,
					mobile: row.mobile,
					address: row.address,
					full_address: row.full_address,
					cons_phone: row.mobile,
					cons_desc: 'Doctor requested consult',
					cons_allergy: '',
					//sendto_oid:			'',
					dest_pharm_curr: 'yes',
					sendto_srch_adr: row.address,
					paym: { price: 0 },
					//need IHI info here
					ihi_ihinumber: row.ihi_ihinumber,
					ihi_ihirecordstatus: row.ihi_ihirecordstatus,
					ihi_ihistatus: row.ihi_ihistatus,
					ihi_history: row.ihi_history,
					ihi_demo_info: row.ihi_demo_info,
					ihi_demo_conflict: row.ihi_demo_conflict,

				}

				const req_data = {
					script_type: 'doccons',
					req_type: 'docconscov',
					form_data: { ...patient_data },
					med: { id: "DocConsCov" },
					med_det: { days_limit: "", m: "DocConsCov", name: "Doctor Consultation", q: {} },
					dvc: { dvcid: app && app.dvc && app.dvc.dvcid || 'unknown', org: false },
					//usr: app && app.user && app.user.user_det,
					doc: app && app.user && app.user.user_det,
					form_errs: [],
					client_form_valid: true,
				}

				const scr_req = await API_service.load_data('reqScriptForm', req_data)

				// Take the patient req

				if (scr_req && scr_req.res == 'ok' && scr_req.sid) {

					const change_res = await API_service.load_data('chgStat', { sid: scr_req.sid, stat: 'in_doccall', doc: app && app.user && app.user.claims && app.user.claims.doc_id }) // we might want to check this returns ok

					if (change_res.res != 'ok') if (this.message_context && this.message_context.add_message) this.message_context.add_message({ type: 'caution', header: 'Failed to set consult to in call', content: [change_res.err] }); else console.log('Global messages not setup correctly')

					this.setState({ making_consult: false  }, _=> { app.history.push(`/doccons/${scr_req.sid}/enc_not_req`) })

				} else {

					if (this.message_context && this.message_context.add_message) this.message_context.add_message({ type: 'negative', header: 'Failed to start consultation', content: [scr_req.err] }); else console.log('Global messages not setup correctly')

					this.setState({ making_consult: false })
				
				}


			})
		}
	}
}
 
export default StartConsult;