import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";
import firebase_database from "../../xAppLib/providers/firebase_database";

export function VidCallLogSummary({record, status_colour}) {
	const logs = record?.logs;
	const hist = record?.hist;
	const name = record?.auth?.displayName;

	const isSkype = entry => entry.label === 'skype_call';
	const status = entry => entry.to?.status || entry.diff?.to?.status;
	const isTransition = entry => !!status(entry);
	const isVideoPublished = entry => entry.label === 'Session published';
	const isVideoConnected = entry => entry.label === 'Got subscriber';
	const isCallPaused = entry => entry.label === 'Call paused';
	const isCallResumed = entry => entry.label === 'Call resumed';
	const isDeviceReloaded = entry => entry.label === 'Nurse device reload requested';
	const isConsRequested = entry => entry.to?.reqcons === true;
	const isAccessDenied = entry => entry.name === 'OT_USER_MEDIA_ACCESS_DENIED';

	const {interesting, users} = useMemo(
		() => {
			const entries = [
				...Object.values(logs || {}),
				...Object.values(hist || {})
			];

			return {
				interesting: entries
					.filter(entry => (
						isSkype(entry)
						|| isTransition(entry)
						|| isVideoPublished(entry)
						|| isVideoConnected(entry)
						|| isAccessDenied(entry)
						|| isConsRequested(entry)
						|| isCallPaused(entry)
						|| isCallResumed(entry)
						|| isDeviceReloaded(entry)
					))
					.sort((x, y) => x.tm - y.tm),
				users: entries.filter(isVideoPublished).reduce(
					// OT errors have the error type as the name, so map uid-name from published entries for use later
					(lookup, entry) => ({...lookup, [entry.uid]: entry.name}), {}
				)
			};
		},
		[logs, hist]
	);

	return (
		<ul>
			{interesting.length === 0 && <li>No logs yet</li>}
			{interesting.map(entry => (
				<li key={entry.tm}>
					<code>{new Date(entry.tm).toLocaleTimeString()}</code>{' '}
					{isSkype(entry) && <>
						<Icon name="skype" size="small" color="teal"/> {name}:
						Skyped {entry.name || entry.skype || '?'}
					</>}
					{isTransition(entry) && <>
						<Icon name="tag" size="small" color={status_colour?.(status(entry)) || 'grey'}/> {entry.by?.n || '?'}:
						changed to {status(entry)}
					</>}
					{isVideoPublished(entry) && <>
						<Icon name="camera" size="small" color="yellow"/> {entry.name || '?'}: publishing
						video
					</>}
					{isVideoConnected(entry) && <>
						<Icon name="camera" size="small" color="green"/> {entry.name || '?'}: receiving video
						from {entry.stream?.name || '?'}
					</>}
					{isCallPaused(entry) && <><Icon name="stop" size="small" color="red"/> {entry.label}</>}
					{isCallResumed(entry) && <><Icon name="play" size="small" color="green"/> {entry.label}</>}
					{isDeviceReloaded(entry) && <><Icon name="refresh" size="small" color="grey"/> {entry.label}</>}
					{isAccessDenied(entry) && <>
						<Icon name="dont" size="small" color="red"/> {users[entry.uid] || '?'}: denied media access
					</>}
					{isConsRequested(entry) && <>
						<Icon name="user plus" size="small" color="violet"/> {entry.by?.n || '?'}:
						requested video consult
					</>}
				</li>
			))}
		</ul>
	);
}


function WatchRecord({path, children}) {
	const [data, setData] = useState();

	useEffect(() => {
		return firebase_database.watch_record(path, setData);
	}, [path]);

	return children(data);
}

export function LoadVidCallLogSummary({path}) {
	return <WatchRecord path={path}>
		{record => <VidCallLogSummary record={record}/>}
	</WatchRecord>
}