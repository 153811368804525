import React from "react";

const cardContent = [
  {
    link: "#",
    image: "/ileg/images/why-one.svg",
    title: "Streamlined medical care, expediting case management",
    text: "It’s as easy as registering and requesting a consultation, and we’ll do the rest.",
  },
  {
    link: "#",
    image: "/ileg/images/why-two.svg",
    title: "Australian Registered Doctors",
    text: "All our doctors are registered and onshore in Australia.",
  },
  {
    link: "#",
    image: "/ileg/images/why-three.svg",
    title: "Safe, Secure and fully Integrated",
    text: "We are Australian Digital Health Agency (ADHA) certified.",
  },
];

const CardGroup = () => {
  return (
    <div className="grid place-content-center -mx-4 sm:grid-cols-3 grid-cols-1 xl:gap-8 md:gap-1 ">
      {cardContent.map((card) => {
        return (
          <div
            className="flex flex-col bg-white rounded-lg shadow-xl m-5 md:m-2"
            key={card.title}
          >
            <div className="bg-lightgray py-16 rounded-t-lg md:max-h-44 lg:max-h-48">
                <img
                    className="w-1/3 m-auto"
                    src={card.image}
                    alt={card.image}
                  />  
            </div>
            <div className="flex flex-col items-start mt-4 p-5">
              <h4>{card.title}</h4>
              <p className="content text-sm">{card.text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardGroup;
