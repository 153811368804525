import React from 'react';
import { Form } from 'semantic-ui-react';

const CheckboxField = ({ title, value, checked }) => {
	checked ||= value;
	return (
		<Form.Field>
			<label>{title}</label>
			<p className="text grey">{checked === 'no' ? 'None' : value || 'Not specified'}</p>
		</Form.Field>
	);
};

export default CheckboxField;
