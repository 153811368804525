import React from 'react'
import {useCopyToClipboard} from "../Hooks/useCopyToClipboard";
import {Button, Icon} from "semantic-ui-react";

const CopyBtn = ({defaultLabel, copyValue, copyLabel, btnProps}) => {
    const {copyToClipboard, copied} = useCopyToClipboard();
    return (
        <Button onClick={() => copyToClipboard(copyValue)} {...{btnProps}}>
            <Icon name={copied ? 'check' : 'copy'}/>
            {copied && copyLabel || defaultLabel}
        </Button>
    )
}
export default CopyBtn