import logger from 'xAppLib/libs/logger'

const DEBUG = false
const TRACK_DEV = DEBUG
const LOG_DEV = DEBUG

export default class gtm {

	static get dataLayer() {
		if ((app.dev_env && !TRACK_DEV) || !window.dataLayer) {
			DEBUG && console.log("GTM not available")
			return []
		}
		return dataLayer
	}

	static commit(category, action, data, extra_log) {

		DEBUG && console.log("gtm commit %o : %o",action,data);
		try {
			if (data?.ecommerce)
				this.dataLayer.push({ ecommerce: null });
			this.dataLayer.push({
				event:data.event||action,
				...data
			});
			this.log(category,action,data,extra_log)
		} catch (e) {
			DEBUG && console.error(e);
		}
	}

	static mf(res, type) {
		window._mfq = window._mfq || [];
		try {
			let val = res ? 'formSubmitSuccess' : 'formSubmitFailure';
			window._mfq.push(["tag", type]);
			if(type != 'search') window._mfq.push([val, 'form']);
		} catch (e) {
			DEBUG && console.error(e);
		}
	}

	static log(category,action, data, extra_log = null) {
		if (app.dev_env && !LOG_DEV)
			return
		logger.usg_log('ga.'+action, 'ga.'+category, extra_log, data)
	}
		
	

	static impressions(products) {
		this.commit('ecommerce','impressions',{
			'ecommerce': {
				'currencyCode': 'AUD',
				'impressions': products
			}
		},products[0]?.category || 'empty');
	}
	
	static productClick(products,list) {
		this.commit('ecommerce','productClick',{
			'event': 'productClick',
			'ecommerce': {
				'click': {
					'actionField': { list },
					'products': products
				}
			}
		},products[0]?.name);

	}
	
	static details(products,list) {
		this.commit('ecommerce','details',{
			'ecommerce': {
				'detail': {
					'actionField': { list },
					'products': products
				}
			}
		},products[0]?.name || 'empty');
	}

	static add_to_cart(products) {
		this.commit('ecommerce','add_to_cart',{
			'event': 'addToCart',
			'ecommerce': {
				'currencyCode': 'AUD',
				'add': {
				'products': products
				}
			}
		},products[0]?.name);
	}

	static remove_from_cart(products) {
		this.commit('ecommerce','remove_from_cart',{
			'event': 'removeFromCart',
			'ecommerce': {
				'currencyCode': 'AUD',
				'remove': {
				'products': products
				}
			}
		},products[0]?.name);
	}

	

	
	static checkout(actionField, products) {
		this.commit('ecommerce','checkout',{
			'event': 'checkout',
			'ecommerce': {
				'checkout': {
					'actionField': actionField,
					'products': products
				}
			}
		},products[0]?.name);
		
	}

	static purchase(actionField, products) {
		this.commit('ecommerce','purchase',{
			'ecommerce': {
			'purchase': {
				'actionField': actionField,
				'products': products
			}
			}
		},products[0]?.name);
		
	}

	static cat_search(search, products) {
		this.commit('search','query',{
			'event':'catSearch',
			'catSearchInput':search,
			'products':products.length
		},search);
		this.mf(true, 'search');
	}

	static auth(authAction, data = {}) {
		this.commit('auth',authAction,{
			'event':'auth',
			'authAction':authAction,
			...data,
		},data.success);
		this.mf(data.success, authAction);
	}

	static event(cat, action, data = {}) {
		this.commit(cat,action,{
			'event':cat,
			'action':action,
			...data,
		});
		
	}
		
}
