import React, { Component, Fragment } from 'react';
import { Button, Menu, Dropdown, Input, Loader, Icon, Accordion, Checkbox } from 'semantic-ui-react';
import { AsyncButton } from "../../views/UIelems/AsyncButton";
import SelectOrg from '../Orgs/SelectOrg'

class ListFilters extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: !!props.start_open
		}
	}
	
	handleOpen = () => {
		this.setState({ isOpen: true })
	}

	handleClose = () => {
		this.setState({ isOpen: false })
	}

	handleToggle = () => {
		// this.state.isOpen && this.props.onReset&&this.props.onReset()
		this.setState({ isOpen: !this.state.isOpen })
	}


	render() {
		const { children, popup = false, loading, header="Filters", selected, onReset = _=>{}, open, btn_search, btn_reload, onSearch, onReload } = this.props
		const { isOpen  } = this.state
		
		if (popup) {
			const total = selected && Object.values(selected).filter(v=> Array.isArray(v) ? v.length : (typeof v === 'boolean' || !!v)).length
			
			return (
				<React.Fragment>
					
					<Accordion fluid styled className='list-filters'>
						<Accordion.Title active={open || isOpen} index={0} onClick={this.handleToggle}>
							<Icon name='dropdown' />
							Filters
						</Accordion.Title>
						<Accordion.Content active={open || isOpen}>
							<div>
								{ loading && <Menu.Item><Loader active inline/></Menu.Item> || children}
							</div>
							<div className="flex-horizontal">
							{btn_search  && <AsyncButton color="green" disabled={total==0} icon='search' content={'Search'} onClick={onSearch} style={{marginRight:'.5rem'}} />}
							<Button color="grey" icon='delete' disabled={total==0} content={'Clear'} onClick={onReset}/>
							<span className="grow"/>
							{btn_reload && <AsyncButton color="grey" icon='refresh' content={'Reload List'} onClick={onReload ?? onSearch}/>}
							</div>
						</Accordion.Content>
					</Accordion>



					{/*<Popup
							trigger={<Button color="green" icon='add' content={header} label={total && { as: 'span', basic: true, content: total, color:'green' } || null} labelPosition={total && 'right'|| null}/>}
							position='bottom left'
							on='click'
							style={{maxWidth:'500px',width:'500px'}}
							open={this.state.isOpen}
				            //onClose={this.handleClose}
				            onOpen={this.handleOpen}
					>
					<Popup.Header>
						Filters
						<Icon link name='close' style={{float:'right'}} onClick={_=>this.setState({isOpen:false})} />
					</Popup.Header>
					<Menu text vertical style={{flexWrap:'wrap',width:'100%'}}>
							{ loading && <Menu.Item><Loader active inline/></Menu.Item> || children}
					</Menu>
					</Popup>
					{total > 0 && <Button color="grey" icon='delete' content={'Clear'} onClick={onReset}/>}*/}
				
				</React.Fragment>

			)
		} else {
			return (
				<Menu style={{flexWrap:'wrap'}}>
					{ header && <Menu.Item header>{header}</Menu.Item>}
					{ loading && <Menu.Item><Loader active inline/></Menu.Item> || children}
				</Menu>

			)
		}
 
		
	}


}

class ListFiltersFilter extends Component {
	render() {
			const { label, options, buttons, name, value = '', render, as, placeholder, type , onFilterChange = _=>{}, onFilterEnd, defaultValue = false, onDropdownClick, multiple = true } = this.props

		if (render)
			return render()
			
		return (
			<>
				{label && <Fragment><strong style={{display:'block',marginBottom:'5px'}}>{label}</strong>&nbsp;</Fragment>}
				{ (_ => {
					switch (as || type) {
						case 'dropdown':
							return <Dropdown
								placeholder={placeholder || 'Select'}
								fluid
								multiple={multiple}
								search
								selection
								options={options}
								value={value || (multiple ? [] : '')}
								onChange={ (event, data) => onFilterChange({[name]:data.value})}
								onClick={onDropdownClick}
								style={{ height: 'auto' }}
							 />

						 case 'org':
						 	return <SelectOrg 
								multiple
								onSelect={(org) => {
									if (org) {
										const newValue = org && value && [...value] || []
										const index = newValue.indexOf(org.oid)
										if (index != -1) {
											newValue.splice(index,1)
										} else {
											newValue.push(org.oid)
										}
										onFilterChange({[name]:newValue})
									} else {
										onFilterChange({[name]:[	]})
									}
									
								}}
								oid={value}
								leafOnly={false}
								placeholder='Select org'
								setBtn={true}
							 />

						 case 'checkbox':
							return <Checkbox 
									checked={value || undefined}
									// radio 
						
									label={placeholder}

									// value={value}
									onChange={(event, data) => onFilterChange({[name]:defaultValue? value : !value})}
							  />

						case 'boolean-button-group':
						case 'button-group':
							return <Button.Group buttons={
								((as||type) === 'boolean-button-group' ? [
									{ key: false, icon: 'close', color: 'red' },
									{ key: true, icon: 'check', color: 'green' },
								] : (buttons || [])).map(({color = 'blue', ...btn}) => ({
									onClick: () => onFilterChange({[name]: value === btn.key ? undefined : btn.key}),
									...btn,
									color: btn.key === value ? color : undefined
								}))
							}/>

						case 'text':
						 default:
							return <Input
									 placeholder={placeholder || 'Enter'}
									 type={type||'text'}
									 value={value}
									 onChange={ (event, data) => onFilterChange({[name]:data.value}) }
									 fluid={this.props.fluid}
									 size={this.props.size}
									 min={this.props.min}
									 max={this.props.max}
									 // onBlur={ (event, data) => onFilterEnd&&onFilterEnd({[name]:data.value})}
							  />
					}
				})() }
			</>
		);
	}

}

ListFilters.Filter = ListFiltersFilter


export default ListFilters;
