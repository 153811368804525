import {useEffect} from 'react'
import {useUser, useUserData} from 'xAppLib/Hooks'
import unseen_results_model from 'models/unseen_results_model'

const WatchUnseenResultsExpress = ({children}) => {

    const user = useUser()
    const [count, setCount] = useUserData('unseen_results_express', null)

    useEffect(() => {
        if (user.claims.doc_id) {
            return unseen_results_model.watch_unseen_results_express_count(user.claims.doc_id, setCount);
        }
    }, [user.uid])

    return children?.(count) || null;

}


export default WatchUnseenResultsExpress