import React from 'react';
import { Helmet } from "react-helmet";
import FAQ from '../../xAppLib/UIelems/FAQ';
import { Container } from 'semantic-ui-react';
import IcosmBanner from './components/IcosmBanner';
import { Link } from 'react-router-dom';
	
const faqs = [
    {
        title: "How to Join?",
        content: <>You can do this through our website or by emailing us at <a href="mailto:assist@instantcosmetics.com.au">assist@instantcosmetics.com.au.</a> Our friendly team will be delighted to help you get started.</>
    },
    {
        title: "How much does it cost to join?",
        content: "There's a $0 joining fee! We're pleased to say there are no hidden fees or extra costs to join our services. We offer a scripting service and have a wholesale shop where you can purchase products at a competitive price."
    },
    {
        title: "How do I login to the InstantCosmetics platform?",
        content: <>Simply visit <Link to="/">https://instantcosmetics.com.au</Link> and select the log in option in the top right corner to get access your account.</>
    },
    {
        title: "What level of experience do I need to join?",
        content: "Cosmetic nurses must have at least 6 months of hands-on experience to operate in a solo clinic. If you have less than 6 months of experience but will be working in an existing clinic or under the supervision of a senior nurse, please reach out to us — we're happy to assist further."
    },
    {
        title: "How do I join the WhatsApp support groups?",
        content: <>If you would like to join our Whatsapp support groups, please simply email us at <a href="mailto:assist@instantcosmetics.com.au">assist@instantcosmetics.com.au.</a> and we will ensure you are added.</>
    },
    {
        title: "Does InstantCosmetics provide ongoing clinical training and support?",
        content: "We are excited to collaborate with a variety of suppliers and medical professionals, offering ongoing training both in person and through webinars and group training sessions. We highly value clinical support and guidance, and we prioritise this in our work. Our nurses benefit from these resources, and we regularly share updates with them."
    },
    {
        title: "How do I obtain a script?",
        content: "You can easily obtain a script from one of our Australia-based medical practitioners through our platform. Our user-friendly software lets you submit your script and have a virtual consultation, all without any added stress."
    },
    {
        title: "Can I purchase product through InstantCosmetics?",
        content: "Absolutely! We offer a wide range of products at competitive prices and take care of the extra work for you. We provide tracking information and handle communication with suppliers on your behalf."
    }
]

const IcosmFaq = () => {

	return  <div>
                <Helmet>
                    <title>Frequently Asked Questions</title>
                </Helmet>

                <Container>
                   <FAQ data={faqs} hide_warn />
                </Container>

                <IcosmBanner img='2/iStock-1283401197.jpg' light  title="Start today with the original scripting platform" desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." />

            </div>
}

export default IcosmFaq;
