import React, { useEffect, useState } from 'react';

import { Button, Dropdown, Modal, ModalContent, ModalHeader, Input, Loader, Icon, Popup, TextArea, Segment, Radio, Table, Checkbox, ModalActions } from 'semantic-ui-react';
import GetMyHRIndividualDetails from './GetMyHRIndividualDetails';
import { useAsync } from '../../xAppLib/Hooks/useAsync';
import API_service from '../../xAppLib/providers/API_service';

const DEBUG = false

const configs = {
	medications:{
		title:"Medications",
		cols:{
			medication:'Medication',
			ItemStrength:'Dose',
			PatientInstructions:'Instructions'
		},
		add:false
	},
	history:{
		title:"Medical History",
		cols:{
			date:'Date',
			description:'Description',
			comments:'Comments',
			confidential:'Confidential'
		},
		add:true
	},
	allergies:{
		title:"Allergies / Adverse Reactions",
		cols:{
			substance:'Substance/Agent',
			reaction:'Reaction'
		},
		add:true

	},
}


const Section = ({target, config, data={rows:[],exclusion:'none_known'}, disabled = false, onUpdate}) => {
	
	const {rows, exclusion} = data
	const handleExclusion = (e, { value }) => {
		onUpdate({
			rows:[...rows.map(r=>({...r,enable:false}))],
			exclusion:value
		})
	}

	const onToggle = (rs,enable) => {
		rs.map(r=>r.enable = enable)
		onUpdate({rows:[...rows]})
	}

	const render_row = (row)=>Object.entries(config.cols).map(([k,v],i)=><Table.Cell key={k}>{row[k]}</Table.Cell>)
	const n_toggled = rows.reduce((n,r)=>n+r.enable,0)
	const all_toggled = n_toggled==rows.length

	useEffect(()=>{
		if (n_toggled && exclusion) {
			onUpdate({exclusion:undefined})
		} else if (!n_toggled && !exclusion) {
			onUpdate({exclusion:'none_known'})
		}
	},[n_toggled,exclusion])

	return <Segment loading={disabled}>
			<p>
				<b>{config.title}</b>
			</p>
			<p>Exclusion Statement: 
				<Radio
					label='None Known'
					name={`${target}_exclusion`}
					value='none_known'
					checked={exclusion === 'none_known' && n_toggled == 0}
					onChange={handleExclusion}
				/>
				<Radio
					label='None Supplied'
					name={`${target}_exclusion`}
					value='none_supplied'
					checked={exclusion === 'none_supplied' && n_toggled == 0}
					onChange={handleExclusion}
				/>
			</p>
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
						<Checkbox
							checked={!!rows.length && all_toggled}
							disabled={!rows.length}
							onChange={()=>onToggle(rows,!all_toggled)}
						/>
						</Table.HeaderCell>
						{Object.entries(config.cols).map(([k,v],i)=><Table.HeaderCell key={k}>{v}</Table.HeaderCell>)}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{rows.map((r,i)=><Table.Row key={i}>
							<Table.Cell>
								<Checkbox
									checked={r.enable}
									onChange={()=>{
										onToggle([r],!r.enable)
									}}
								/>
							</Table.Cell>
							{render_row(r)}
						</Table.Row>)}
						{config.add && <Table.Row><Table.Cell colSpan="10"><Button size="tiny" basic onClick={()=>{/*One Day*/}}>Add</Button></Table.Cell></Table.Row>}
				</Table.Body>

			</Table>

		</Segment>;
}

function scripts_to_meds(scripts) {
	return scripts.map(s=>s.epresc?.item).filter(Boolean)
		.map(item=>(
			{
				enable:true,
				...item,
				medication:`${[item.ItemTradeName,item.ItemGenericName].filter(n=>n).join(' ')}`

		}))
}

const defaultState = (scripts) => {
	scripts = scripts_to_meds(scripts)
	return	{
		medications:{
			exclusion:scripts.length && undefined || 'none_known',
			rows:scripts
		},
		history:{
			exclusion:'none_known',
			rows:[]
		},
		allergies:{
			exclusion:'none_known',
			rows:[]
		},
	}
}

const SHS = ({isOpen,onClose,src_obj = {}, scripts = []}) => {
	// DEBUG && console.log({scripts})
	const [data,setData] = useState(defaultState(scripts))

	const { data: res, loading, error, fn:submitSHS } = useAsync({
		immediate: false,
		fn: async () => {
			DEBUG && console.log("submitSHS")

			let full_address = src_obj.spd_data.full_address || src_obj.form_data.full_address
			
			const user_dets = {
						...src_obj.spd_data,
						...{
							// Always showing atsi
							atsi: src_obj.spd_data.atsi || '',
							ctg: src_obj.spd_data.atsi && (src_obj.spd_data.atsi === 'A' || src_obj.spd_data.atsi === 'TSI') ? true : false
						},
						full_address,
						pts_uid: src_obj.pts_uid
					}

			const req_data = {
				script_type: 'shs',
				req_type: 'doccons',
	
				form_data: {...user_dets, myhr_grant:true, shs:data},
	
				med :{ },
	
				meta: {ref_sid: src_obj.sid},
	
				doc: src_obj.doc, // TODO get current doc

				epresc : {},

				// myhr: {}
			}

			let ret = { }
				
			try {
	
				ret = await API_service.load_data(
					'reqScriptForm',
					req_data,
				)
	
			} catch (error) {
				ret = { res:'err', err:error.message }
			}

			if (ret.err)
				throw new Error(ret.err)

			return ret
		}
	}, [isOpen]);

	const onUpdate = (target,update) => {
		setData(d=>({...d,[target]:{...d[target],...update}}))
	}

	DEBUG && console.log({res,loading,error})

	const error_message = error?.message 
	const success = res?.res=='ok'

	useEffect(()=>{
		if (error_message)
			 app.confirm("Error",error_message,{basic:true})

	},[error_message])

	return <>

		<Modal size={'fullscreen'} open={isOpen} onClose={onClose} closeIcon dimmer={'inverted'}>
					<ModalHeader className='!pr-0'>
						<div className='flex justify-between'>
							<h4 className='flex-auto'>Shared Health Summary</h4>
							
						</div>

					</ModalHeader>
					<ModalContent >
						
						{success && <>
							<p><Icon name='check'/> Shared Health Summary submitted</p>
							
						</> || <>
								<GetMyHRIndividualDetails uid={src_obj.pts_uid} has_access/>
								{Object.entries(configs).map(([id,config])=><Section key={id} disabled={loading} target={id} config={config} onUpdate={(v)=>onUpdate(id,v)} data={data[id]}></Section>)}
							</>}
					</ModalContent>
					<ModalActions>
					{!success && 
							<Button onClick={submitSHS} primary disabled={loading}>Submit</Button>
							|| <Button onClick={onClose} primary >Close</Button>
					}
					</ModalActions>

				</Modal>
	
	</>
}
 
export default SHS