import { pick } from "../helpers/json_functions";
import API_service from "../xAppLib/providers/API_service";

export default class evermed_model {
    med_dets = []
    del_opts = []

    static SEL_MED_DETS(prodVar) {
        return this.med_dets?.find(p => p.productVariantType === prodVar)
	}

    static SEL_DEL_DETS(code){
        return this.del_opts?.map(method => method.options).flat().find(opt => code?.includes(opt.code))
    }

	static async getDeliveryAndMedOpts(args) {
        const postData = pick(args, [
            'mppAmtCode',
            'pbsCode',
            'postcode',
            'qty',
            'showCourier',
            'state',
            'suburb',
            'referenceTimestamp',
            'address2',
            'address',
            'sharps',
            'temp',
            'isClickAndCollect',
            'showOutOfStockVariants'
        ]);

		const payload = await API_service.load_data('evermed/deliveryAndMedOpts', postData);
		if (payload.medPrices && payload.delOpts) {
            this.med_dets = payload.medPrices
            this.del_opts = payload.delOpts
            return payload
		}
		return Promise.reject(new Error(payload?.err || payload?.msg || 'Unknown error loading treatment plan'))
	}

    static async getMedPrices({mppAmtCode, pbsCode, qty}) {
		const payload = await API_service.load_data('evermed/getMedPrice', {mppAmtCode, pbsCode, qty})
        return payload
	}

    static async getOrderStatus({orderNumber}){
        const res = await API_service.load_data(`evermed/getOrderStatus/${orderNumber}`)
        return res
    }

    static async cancelOrder({sid, orderNumber}){
        const res = await API_service.load_data(`evermed/cancelOrder`, {sid, orderNumber})
        return res
    }

    static async reprocessOrder({sid}){
        const res = await API_service.load_data(`evermed/reprocessOrder`, {sid})
        return res
    }

    static async overwriteWithPreviousRecord({sid, previous_record_tm}){
        const res = await API_service.load_data(`evermed/manualOverwrite`, {sid, previous_record_tm})
        return res
    }
    static async getAndSetEvermedOrder({sid}){
        const res = await API_service.load_data(`evermed/getAndSetOrder`, {sid})
        return res
    }

    static async reconcileCSV(file_data){
        const res = await API_service.load_data(`evermed/reconcileCSV`, file_data)
        return res
    }
}
