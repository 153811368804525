import React, {useState} from 'react'
import { Button, Icon } from "semantic-ui-react"
import file_to_base64 from 'xAppLib/helpers/file_to_base64';


const ShareDownloadButton = ({url,filename='document.pdf',label}) => {

        const [sharing,setSharing] = useState(false)

		// Temp workaround, remove this once app is published in app store
		if (app.settings.is_ionic && !window.plugins?.socialsharing)
			return null
		
		const download = async () => {
			setSharing(true)
			const response = await fetch(url, {  })
            let blob = await response.blob()
            blob = new Blob([blob],{type:'application/pdf'})
			const b64 = await file_to_base64(blob)
			console.log({b64});
			setSharing(false)
			if (app.settings.is_ionic) {
				if (!window.plugins?.socialsharing) {
					alert("Please download the latest version of the InstantScripts app to use this feature.")
					return
				}

				var options = {
					// message: 'InstantScripts Document', // not supported on some apps (Facebook, Instagram)
					subject: 'InstantScripts Document', // fi. for email
					files: [`df:${filename};`+b64], // an array of filenames either locally or remotely
					// chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
					// appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
					// iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
				  };
				  
				  var onSuccess = function(result) {
					console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
					console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
				  };
				  
				  var onError = function(msg) {
					console.log("Sharing failed with message: " + msg);
				  };
				  
				  window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
			} else {
				const download = document.createElement('a');
				download.href = b64.replace('data:application/pdf;','data:application/octet-stream;');
				download.download = filename
				document.body.appendChild(download);
				download.click();
				document.body.removeChild(download);
			}
		}
        
    return <Button loading={sharing} data-testid="button-download" onClick={_=>download(url)}><Icon name={app.settings.is_ionic?"share":"download"}/> {label||(app.settings.is_ionic?"Export":"Download")}</Button>
}
 
export default ShareDownloadButton;