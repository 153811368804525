import firebase_database from '../providers/firebase_database.js';
import API_service from '../providers/API_service'

const _FRDB_LOC = 'TBA';
const _DET_FIELDS = ['TBA'];
const _MODEL_NAME = 'TBA';

export default class db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }


//	------------------------	------------------------	------------------------

	static init() {
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static prep_loc(l, alt_root) {
		return (alt_root || this.FRDB_LOC) + (l&&l.startsWith('/')?'':'/') + (l||'')
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static det_fields() {

		return this.DET_FIELDS

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static watch_all_records (ca, p) {

		return firebase_database.watch_record (this.FRDB_LOC, ca, p) 

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static watch_changing_records (ca, p) {

		firebase_database.watch_changing_records (this.FRDB_LOC, ca, p) 

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static watch_record (l, ca, p) {

		return firebase_database.watch_record (this.prep_loc(l), ca, p) 

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static async get_record (l, ca, p) {

		if (this.USE_DATA == 'API')
			return await API_service.load_data (this.API_NAME, p) 

		else {

			// return await firebase_database.get_record (this.prep_loc(l), ca, p)

			const loc = this.prep_loc(l, p?.alt_root)

			if (!app.app_data.ws_data)				app.app_data.ws_data = {};
			if (!app.app_data.ws_data.db)			app.app_data.ws_data.db = {};
			if (!app.app_data.ws_data.inflight)		app.app_data.ws_data.inflight = {};

			if (!app.app_data.ws_data.db[loc] || p?.refresh) {
				if (!app.app_data.ws_data.inflight[loc] || p?.refresh) {
					app.app_data.ws_data.inflight[loc] = firebase_database.get_record (loc, ca, p);
					app.app_data.ws_data.inflight[loc].finally(() => {
						// Remove inflight cache entry once settled;
						// 	If resolved, future requests get the cached value anyway
						// 	If rejected, this will let future requests try again
						delete app.app_data.ws_data.inflight[loc];
					});
				}

				app.app_data.ws_data.db[loc] = await app.app_data.ws_data.inflight[loc];
			}

			return app.app_data.ws_data.db[loc];
		}

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static add_record (l, d, p=null, ca, ce) {

		// firebase_database.add_record( 'meds_cat'+l+d.c, d );
		return firebase_database.add_record (this.prep_loc(l), d, p, ca, ce)

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static set_record (l, d, p) {

		// firebase_database.add_record( 'meds_cat'+l+d.c, d );
		firebase_database.set_record (this.prep_loc(l), d, p) 

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static update_record (l, d, p) {

		// console.log(this.MODEL_NAME, ' :: update_record', l, d)

		return firebase_database.update_record (this.prep_loc(l), d, p) 

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static delete_record (l) {

		// console.log(this.MODEL_NAME, ' :: delete_record', l)

		firebase_database.delete_record (this.prep_loc(l)) 

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static on_disconnect (l) {
		return firebase_database.on_disconnect(this.prep_loc(l));
	}

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------  

}
