import { useState, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { UserDataContext } from "xAppLib/context";



export function useUserData(id, defaultValue = null) {
	const [ctx, actions, dispatch] = useContext(UserDataContext)
	const value = useMemo(()=> id in ctx ? ctx[id] : defaultValue,[ctx[id]])

	const theseActions = useMemo(()=>{
		return Object.keys(actions).reduce((final,k)=>{
			const action = actions[k]
			if (!['reset','exec'].includes(k))
				final[k] =  (data) => {
					action.apply(null,[id,data])
				}
			return final
		},{...actions})
	},[id,actions])

	return useMemo(()=>[value,theseActions.set,theseActions,actions,dispatch],[value,theseActions,actions,dispatch])
}



