/* eslint-env node */

//	------------------------	------------------------	------------------------
//	Description:
//  eRx script -> ITEM[s] -> content -> clinician, patient, prescribed item
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const erx_content_model =           require('./erx_content_model')
const erx_session_key_model =       require('./erx_session_key_model')

const get_set =                     require('../../helpers/erx/get_set_function')

const erx_enums =   require('../erx/resources/erx_enums')
const erx_formats = require('../erx/resources/erx_formats')
const erx_props =   require('../erx/resources/erx_props')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DEFAULTS = {
    Sequence: 1,
    State: erx_enums.ERX_ENUM.ITEM_STATE.Active,
    Content: new erx_content_model(),
    SessionKey: new erx_session_key_model(),
    //CreatorVendorID: '95' // Let eRx assign this?

}

const raw_prop_id =     '_RAW'

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class erx_item_model {

    constructor(item_data, run_validation = true) {

        this._item_data = {}
        this.setup_props()

        if (item_data){
            if (run_validation) this.item_data = item_data
            else this._item_data = item_data
        } 
        else this.set_default()

    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get item_data() { return this._item_data }
    set item_data(val) {
        this._item_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------

    set_blank() {// You probably don't want to call this

        this.clear()
        
        erx_props.ITEM_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })

        this.Sequence = 1
        this.State = erx_enums.ERX_ENUM.ITEM_STATE.Active
        this.Content = new erx_content_model()
        this.SessionKey = new erx_session_key_model()
        this.Content.set_blank()
        this.SessionKey.set_blank()

    }

    //	------------------------	------------------------	------------------------

    set_default() { 

        this.set_blank()

        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))

        this.Content.set_default()
        this.SessionKey.set_default()
        //this.fix_data()
    
    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {
        
        // Fix self
        let result = {res: 'ok', fixes: [], err: []}

        for (let i = 0; i < erx_props.ITEM_PROPS.length; i++){
            const c_prop = erx_props.ITEM_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format - see if we can convert any strings to expected format
            if ((typeof c_item !== 'undefined') && c_item !== '' && c_item !== null && c_prop.format){ //Note - content props for patient, clinician and item wont have a format and will be ignored
                
                const formatter = erx_formats.ERX_FORMAT[c_prop.format]
                if (!formatter || !formatter.validate) result.err.push('Cannot find validator for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                else {

                    const valid_res = formatter.validate(c_item)

                    if (valid_res.res != 'ok'){

                        if (remove_errors) this[c_prop.name] = null // The value may be fixed below

                        if (!formatter.fromValue) result.err.push('Cannot find formatter (fromValue function) for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                        else {

                            const from_res = formatter.fromValue(c_item)

                            if (from_res.res != 'ok') result.err.push('Could not parse item to type ' + c_prop.format + ' for prop ' + c_prop.name + '. Error: ' + from_res.err)
                            else {

                                result.fixes.push( { name: c_prop.name, from: c_item, to: from_res.val } )
                                this[c_prop.name] = from_res.val

                            }
                        }
                    } 
                }              
            }
        }

        // Fix children
        let content = this.get_content()
        if (content){
            const content_fix_res = content.fix_data(remove_errors)
            if (content_fix_res.res != 'ok') result.err.push('Error fixing content. Error: ' + content_fix_res.err)
            else result = {...result, content_fix_res}          
        }

        let session_key = this.get_session_key()
        if (session_key){
            const session_key_fix_res = session_key.fix_data(remove_errors)
            if (session_key_fix_res.res != 'ok') result.err.push('Error fixing session key. Error: ' + session_key_fix_res.err)
            else result = {...result, session_key_fix_res}
        }
        
        if (result.err.length > 0) result.res = 'err'
        else delete result.err
        return result

    }

    //	------------------------	------------------------	------------------------

    check_valid(params = {}) {

        let result = {res: 'ok'}

        try {
            const content = this.get_content()
            const content_valid_res = content.check_valid(params)

            if (content_valid_res.res != 'ok')
                result = {...result, res: 'err', err: [...(result.err || []), ('Content is invalid: ' + content_valid_res.err)] }

            const session_key = this.get_session_key()
            let session_key_valid_res = {res: 'ok', msg: 'nill session key'}

            if (!session_key.is_nill()) session_key_valid_res = session_key.check_valid(params)
            if (session_key_valid_res.res != 'ok')
                result = {...result, res: 'err', err: [...(result.err || []), ('Session key is invalid: ' + session_key_valid_res.err)] }
                //return {res: 'err', err: 'Session key is invalid: ' + session_key_valid_res.err, session_key_valid_res}
        } catch (e) {
            result = {...result, res: 'err', err: [...(result.err || []), ('Unknown error while checking item validity: ' + e)] }
            //return {res: 'err', err: 'Unknown error while checking item validity: ' + e, e}
        }
        
        return result

    }

    //	------------------------	------------------------	------------------------

    compare(target_item, subset = false) { // Compares this script's items to the target scripts items. Subset means only compare the props on the source

        let diff = []

        //log("Checking item props match")
        // Check props
        erx_props.ITEM_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_item && (typeof target_item[item.name] != undefined) && (target_item[item.name]!= '') && target_item[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Item property ' + item.name + ', source: ' + source + ', target: ' + target, source, target}) 
            }
        })

        //log("Checking script items match")
        let source_content = this.get_content()
        let target_content = target_item && target_item.get_content && target_item.get_content() || null
        let compare_content_res = {}
        if (source_content && source_content.compare) compare_content_res = source_content.compare(target_content, subset)
        if (compare_content_res.res != 'ok') diff.push({name: 'Source content mismatch: ' + compare_content_res.err, compare_content_res, source: source_content, target: target_content})
        
        // Check items on target
        if (!subset) {
            compare_content_res = {}
            if (target_content && target_content.compare) compare_content_res = target_content.compare(source_content, subset)
            if (compare_content_res.res != 'ok') diff.push({ name: 'Target content mismatch: ' + compare_content_res.err, compare_content_res, source: source_content, target: target_content })
        }

        // Return
        if (diff.length == 0) return {res: 'ok'}
        else return {res: 'err', err: 'Items do not match by ' + diff.length + ' field(s)/content. Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff}

    }

    //	------------------------	------------------------	------------------------

    get_content() { // Returns an array of all the items
        //if (!this.Content) return {}
        try {
            return new erx_content_model(this['Content' + raw_prop_id] || null) // Need to double check this - that content is always one item
        } catch (e) {
            throw {err: 'Unable to get content: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------

    get_session_key() {
        try {
            return new erx_session_key_model(this['SessionKey' + raw_prop_id] || null) // Need to double check this - that content is always one item
        } catch (e) {
            throw {err: 'Unable to get session key: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.ITEM_PROPS.forEach(p => {
        if (p.name == 'Content') { 
            let t_prop = JSON.parse(JSON.stringify(p))
            t_prop.name += raw_prop_id
            this.define_prop(t_prop)
            Object.defineProperty(this, p.name, { get() { return this.get_content() }, set(val) { this[t_prop.name] = val && val.inner_data || {} }})
        } else if (p.name == 'SessionKey') { 
            let t_prop = JSON.parse(JSON.stringify(p))
            t_prop.name += raw_prop_id
            this.define_prop(t_prop)
            Object.defineProperty(this, p.name, { get() { return this.get_session_key() }, set(val) { this[t_prop.name] = val && val.inner_data || {} }})
        } else this.define_prop(p)
    })}

    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.item_data }
    set inner_data(val) { this.item_data = val }

    //	------------------------	------------------------	------------------------

    get Sequence() { throw { override_msg: 'get Sequence' }}
    set Sequence(val) { throw { override_msg: 'set Sequence' }}

    get OriginalSequence() { throw { override_msg: 'get OriginalSequence' }}
    set OriginalSequence(val) { throw { override_msg: 'set OriginalSequence' }}

    get State() { throw { override_msg: 'get State' }}
    set State(val) { throw { override_msg: 'set State' }}

    get SessionKey() { return this.get_session_key() }
    set SessionKey(val) { throw { override_msg: 'set SessionKey' }}

    get Content() { return this.get_content() }
    set Content(val) { throw { override_msg: 'set Content' }}

    get Tags() { throw { override_msg: 'get Tags' }}
    set Tags(val) { throw { override_msg: 'set Tags' }}

    get UpdatedBy() { throw { override_msg: 'get UpdatedBy' }}
    set UpdatedBy(val) { throw { override_msg: 'set UpdatedBy' }}

    get UpdatedDate() { throw { override_msg: 'get UpdatedDate' }}
    set UpdatedDate(val) { throw { override_msg: 'set UpdatedDate' }}

    get DispensedDate() { throw { override_msg: 'get DispensedDate' }}
    set DispensedDate(val) { throw { override_msg: 'set DispensedDate' }}

    get CreatorVendorID() { throw { override_msg: 'get CreatorVendorID' }}
    set CreatorVendorID(val) { throw { override_msg: 'set CreatorVendorID' }}

    get DispenserVendorID() { throw { override_msg: 'get DispenserVendorID' }}
    set DispenserVendorID(val) { throw { override_msg: 'set DispenserVendorID' }}

}