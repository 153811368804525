import React, { useCallback, useEffect, useRef, useState } from 'react';
import vidcall_model from 'models/vidcall_model'
import { Button, Icon } from 'semantic-ui-react';
import API_service from 'xAppLib/providers/API_service'
import firebase_database from 'xAppLib/providers/firebase_database';
import { cls } from "views/NUI/utils";
import logger from 'xAppLib/libs/logger'


// TODO clean up, don't really use all those anymore
const STATUS_OFF = 'off'
const STATUS_ON = 'on'
const STATUS_INIT = 'init'
const STATUS_READY = 'ready'
const STATUS_CALLING = 'calling'
const STATUS_ONCALL = 'oncall'

const DEBUG = false
const DEBUG_PEERS = false
const DEBUG_STREAMS = false





const createEmptyAudioTrack = () => {
	const ctx = new AudioContext();
	const oscillator = ctx.createOscillator();
	const dst = oscillator.connect(ctx.createMediaStreamDestination());
	oscillator.start();
	const track = dst.stream.getAudioTracks()[0];
	return Object.assign(track, { enabled: false });
  };
  
const createEmptyVideoTrack = ({ width, height }) => {
	const canvas = Object.assign(document.createElement('canvas'), { width, height });
	canvas.getContext('2d').fillRect(0, 0, width, height);
  
	const stream = canvas.captureStream();
	const track = stream.getVideoTracks()[0];
  
	return Object.assign(track, { enabled: false });
  };

function getEmptyStream() {

	  const audioTrack = createEmptyAudioTrack();
	  const videoTrack = createEmptyVideoTrack({ width:640, height:480 });
	  const mediaStream = new MediaStream([audioTrack, videoTrack]);
	
	  mediaStream.empty=true
	  console.log("getEmptyStream()");
	  return mediaStream
}


export function useVidCall({ uid, name, token }) {
	const [vidcall, setVideoCall] = useState(null);
	const [my_id, setMyId] = useState(null);
	const [status, setStatus] = useState(STATUS_OFF);
	const [stream, setStream] = useState(null);

	const initStream = useCallback((audio = true,video = true) => {
		(async () => {
			setStatus(STATUS_INIT);
			try {
				const userStream = await vidcall.getMediaStream(audio,video);
				if (userStream) {
					logger.usg_log('Conference', "StreamStarted")
					setStream(userStream);
					setStatus(STATUS_READY);
				} 
			} catch (error) {
				const data = {
					error,
					navigator:navigator,
					mediaDevices:navigator?.mediaDevices,
					getUserMedia:navigator?.mediaDevices?.getUserMedia
				}
				logger.report_error('ERROR in useVidCall', 'error', data);
				alert("Could not start your video call. Please check your browser is authorised.")
				
			}
			
		})();
	}, [vidcall]);

	useEffect(() => {
		console.log("CREATING vidcall");
		window.vidcall_model = vidcall_model
		const vidcall = new vidcall_model(uid, name, token);
		const vidcallOff = vidcall.on(vidcall_model.ON_OPEN, (id) => {
			console.log("Got id",id);
			logger.usg_log('Conference', "GotID", null, {id})
			setMyId(id);
		});
		setVideoCall(vidcall);
		setStream(getEmptyStream())
		return () => {
			console.log("DESTROYING vidcall");
			vidcallOff?.();
			vidcall.destroy();
		};
	}, [uid,name, token]);

	useEffect(()=>{
		console.log("CDM Stream",stream);
		return () => {
			console.log("CWU Stream ",stream);
			stream?.getTracks().forEach(t => t.stop());
		}
	},[stream])

	return {
		vidcall,
		my_id,
		status,
		initStream,
		stream,
	};
}


const PeerUser = ({me = false, uid, name, hero, stream_id, ready, peer_id, stream, onRequestConnection, requestNewStream, onSelect, onInitStream}) => {
	const remote = !me
	const videoRef = useRef(null)    
	const [refresh,setRefresh] = useState(0)
	function doRefresh() {
			setRefresh(refresh=>{
				return refresh+1
			})
		}
	

	useEffect(()=>{
		if (videoRef.current)
			videoRef.current.srcObject = stream
	},[stream])


	useEffect(()=>{
		if (remote && stream_id) {
			console.log("Connecting", {me, remote}, stream );
			onRequestConnection(peer_id)
		}
	},[remote,stream_id])

	const toggleAudio = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation();
		if (stream?.getAudioTracks()[0]) {
			stream.getAudioTracks()[0].enabled = !stream.getAudioTracks()[0].enabled;
			doRefresh()
		}
	},[stream])

	const toggleVideo = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation();

		if (stream?.getVideoTracks()[0]) {
			stream.getVideoTracks()[0].enabled = !stream.getVideoTracks()[0].enabled;
			doRefresh()
		}
	},[stream])

	const handleRequestNewStreamClicked = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation();

		requestNewStream();
	},[stream])

	const audioEnabled = stream?.getAudioTracks()[0]?.enabled
	const videoEnabled = stream?.getVideoTracks()[0]?.enabled
   
	return <>
		{DEBUG_PEERS && JSON.stringify({peer_id,stream_id})}
		{stream && <div className={cls('video-container-43 rounded-b-sm ','relative',hero?' w-full':'w-60',(stream && !stream?.empty ? 'bg-green-400': 'bg-gray-400'))} onClick={()=>onSelect(peer_id)}>
			
			<video ref={videoRef} muted={me} autoPlay width="100%"></video>
			<div className={cls('absolute bottom-0 right-0 p-2',(stream && !stream?.empty ? 'bg-green-400': 'bg-gray-400'))}><strong>{name}{DEBUG && <> {me&&"– Me"} {hero&&"– Hero"}</>}</strong>{DEBUG && <><br/><small>{peer_id}</small></>}</div>
			{ DEBUG_STREAMS && <p><small>{stream?.id}</small></p>}
			{me && <>
				{stream.empty && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Button onClick={onInitStream}>Start Video Call</Button></div>}
				{!stream.empty && <div className='absolute bottom-1 left-1 z-10'>
					<Icon style={{ cursor: 'pointer' }} color={videoEnabled?'green':'grey'} name="video" onClick={toggleVideo} />
					<Icon style={{ cursor: 'pointer' }} color={audioEnabled?'green':'grey'} name="sound" onClick={toggleAudio} />
					<Icon style={{ cursor: 'pointer' }} color={'green'} name="refresh" onClick={handleRequestNewStreamClicked} />
				</div>}
			</>}
			
	</div>}
	</>;
}
 
const AUTO_START = false


export const WatchPeers = ({children,token}) => {
	const [peersRecord,setPeers] = useState({})
	useEffect(()=>{
		const loc = '/___CONFS___/'+token
		console.log("Watching",loc);
		
		firebase_database.watch_record ('/___CONFS___/'+token, rec=>{
			console.log("*** GOT RECORD",rec?.peers);
			const peers = rec?.peers || {}

			setPeers(peers)

		}) 

		return () => {
			//TODO unwatch
		}
	 
	},[])

	return children?.({peersRecord})
}

function defaultJoin(token,peer_id,user,stream_id) {
	API_service.load_data(
		'confs/join', 
		{token,peer_id,user, stream_id}
	).then(data=>{
		console.log("joined",data)
	})

	return () =>{ 
		return API_service.load_data(
			'confs/leave', 
			{token,peer_id,user, stream_id}
		).then(data=>{
			console.log("left",data)
		})
	}
}

const Conference = ({name, token, peersRecord, joinHandler = defaultJoin, autoStart = false, onPeerConnected,onPeersConnected}) => {
	const videoRef = useRef(null)
	const _peers = useRef({})
	const [refresh,setRefresh] = useState(0)
	const [hero,setHero] = useState(null)
	const uid = app.user.uid
	

	const peers = _peers.current

	window.peers = peers // TODO remove

	function doRefresh() {
		setRefresh(refresh=>{
			return refresh+1
		})
	}

	const onDisconnect = useCallback((a)=>{
		console.log("*** onDisconnect",a);
	},[])

	const {vidcall, status, initStream, stream, my_id} = useVidCall({uid, name, token/*,onCall:(call)=>onAnswerCall(),onDisconnect*/})
 
	

	const onCall = useCallback((connection)=>{
		console.log("*** onCall",connection,vidcall);
		(async ()=>{
			const {remoteStream} = await vidcall.peer_answer(connection,stream)

			const { peer } = connection
			console.log("connecting peer",peer);

			
			// TODO handle if peer doesn't exist. Need to add it for now.
			_peers.current[peer] = Object.assign({},_peers.current[peer],{stream:remoteStream})

			onPeerConnected?.(peer,_peers.current)
			onPeersConnected?.(Object.keys(_peers.current).filter(k=>_peers.current[k].stream))

			doRefresh()

		})()
	},[my_id,stream])



	useEffect(()=>{

		if (my_id) {
			DEBUG && console.log("Adding my peer",my_id);
			_peers.current[my_id] = Object.assign({uid,name,me:true},_peers.current[my_id],{stream})
			doRefresh()
		}
			  
	},[my_id,stream])

	useEffect(()=>{
		let leaveHandler
		if (my_id) {
		   DEBUG && console.log("joining =>>>>",my_id,stream.id);
		   leaveHandler = joinHandler({token,peer_id:my_id,uid,name,stream_id:stream?.id||null})
		}
		
		return () => {
			leaveHandler?.()

			// TODO can we do on window close? 

		}
			  
	},[uid,my_id,stream])

	useEffect(()=>{
		const onCallOff = vidcall?.on(vidcall_model.ON_CALL,onCall)
		const onDisconnectOff = vidcall?.on(vidcall_model.ON_DISCONNECT,onDisconnect)
		return () => {
			onCallOff?.()
			onDisconnectOff?.()
		}
			  
	},[vidcall,onCall,onDisconnect])

	useEffect(()=>{
		if (vidcall && (AUTO_START || autoStart))
			initStream()
	},[vidcall,autoStart])

	useEffect(()=>{
		console.log("CDM");
		return () => {
			console.log("CWU");
		}
			  
	},[])

	useEffect(()=>{
		
		DEBUG && console.log("checking peersRecord",my_id,peersRecord, _peers.current);
		if (!my_id)
			return

		if (!peersRecord[my_id]) {
			peersRecord[my_id] = _peers.current[my_id]
		}

		for (const id of Object.keys(peersRecord)) {
			console.log("checking",id,!!_peers.current[id]);
			if (_peers.current[id]) {
				const { stream, connection } = _peers.current[id]
				console.log("found",id,stream);
				peersRecord[id] = Object.assign(peersRecord[id],{stream,connection})
			}
		}

		DEBUG && console.log("*** new peers",peersRecord);
		_peers.current=peersRecord
		onPeersConnected?.(Object.keys(_peers.current).filter(k=>_peers.current[k].stream))

		doRefresh()
	   
	},[my_id,peersRecord])


	DEBUG && console.log("My stream",stream?.id,stream);
	DEBUG && console.log("My peer",my_id);
	DEBUG && console.log("peers",peers);
	DEBUG && console.log("vidcall",vidcall);

	const not_me =  Object.keys(peers).filter(id=>peers[id].stream).find(id=>id!==my_id)


	const els = Object.keys(peers).map((id,index)=>{
		const peer = peers[id]
		return <PeerUser key={id} hero={(hero||not_me||my_id)==id } {...peer} me={my_id==id} peer_id={id}  
					onInitStream={initStream}
					onSelect={setHero} 
					requestNewStream={initStream}
					onRequestConnection={async (peer_id)=>{
				
				console.log("onRequestConnection ",peer_id,stream?.id);
				if (!stream)
					return
				const {connection, remoteStream} = await vidcall.peer_call(peer_id, stream );

				if (!remoteStream) {
					logger.usg_log('Conference', "onRequestConnection", 'no stream',{peer_id})

				}

				console.log("*** connection",connection);
				console.log("*** remoteStream",remoteStream);
				//    this.set_remote_stream(remoteStream, connection)
				peers[peer_id].stream = remoteStream
				peers[peer_id].connection = connection
				onPeerConnected?.(peer_id,peers)
				onPeersConnected?.(Object.keys(_peers.current).filter(k=>_peers.current[k].stream))

				connection.on('close',_=>{
					console.log("Received close");
					// TODO handle
					peers[peer_id] = Object.assign({},peers[peer_id],{connection:null,stream:null})
				})
				connection.on('error',error=>{
					console.log("error",error);
					// TODO handle
					peers[peer_id] = Object.assign({},peers[peer_id],{connection:null,stream:null})
				})
				// setPeers([...peers])
				doRefresh()
			}} />
	  
	})

	const main = els.find(el=>el.props.hero) || els[0]

	const others = els.filter(el=>el!=main)


	return <div>
			   
			
			{DEBUG && <pre style={{background:'#ccc',fontSize:'.8em',padding:'.4em'}}> {uid}:{name} / [status:{status}] / [my_id:{my_id}] / [stream:{stream?.id}] / [hero:{hero||"me"}]</pre>}
			
			{!my_id && <p className='mt-16 mb-16 text-center'>Connecting...</p>}

			{my_id && <div className='flex relative'>

				{main}

				<div className='absolute right-0 top-0 flex'>
					{others}
				</div>

			</div>}

			{DEBUG && peers &&  
					Object.keys(peers).map(id=><div key={id} style={{fontSize:'.8em'}}>
						{peers[id].stream && "✅" || "⛔️"} {id} / {peers[id].stream?.id}
					</div>)}

				{/* {DEBUG && <pre>
					{JSON.stringify(peers,(key,value)=>key=='connection'?'[CONNECTION]':value,2)}
					</pre>} */}
	</div>;
}
 
export default Conference;