import React, { useEffect, useState } from 'react';
import SimpleShowMore from '../../../xAppLib/UIelems/SimpleShowMore';
import JsonEditor from '../../../xAppLib/UIelems/JsonEditor';
import med_model from 'models/med_model';
import { useToggleState } from 'xAppLib/Hooks';
import { Button, List } from 'semantic-ui-react';
import { toastMessage } from '../../../xAppLib/helpers/toast_message';
import SearchPBSButton from './SearchPBSButton';

const MAX_ROW_LENGTH = 30;

const excl_fields = ['BenefitType','RestrictionType','TextRefAdmin','TextRefCaution','ARTG_IDs','version']

const EPrescribing = ({ value: { epresc = {}, m: med_code }, row }) => {
	const { ItemTradeName, ItemForm, ItemStrength } = epresc;
	const [isEdit, toggleEditOn, toggleEditOff] = useToggleState();
	const [isLoading, turnIsLoadingOn, turnIsLoadingOff] = useToggleState();
	const [json, setJson] = useState();

	const handleOnSave = async () => {
		turnIsLoadingOn();
		await med_model.update_record(med_code, { epresc: json }, { add_hist: true });
		row.epresc = json;
		turnIsLoadingOff();
		setJson(undefined);
		toastMessage('EPrescribing updated');
	};

	const handleOnPBSSelected = ({ item }) => {
		for (const f of excl_fields) {
			delete item[f]
		}
		setJson(item);
		toggleEditOn();
	};

	useEffect(() => {
		if (!isEdit) {
			setJson(undefined);
		}
	}, [isEdit])

	return (
		<div>
			{isEdit && (
				<>
					<div style={{ width: 600, height: 400 }} className="no-padding mb-4">
						<JsonEditor value={json ?? epresc} onChange={(value) => setJson(value)} />
					</div>
					<Button color="red" content="Save" size='tiny' onClick={handleOnSave} disabled={!json} loading={isLoading} />
					<Button basic content="Cancel" size='tiny' onClick={toggleEditOff} />
					<SearchPBSButton label="PBS" onChange={handleOnPBSSelected} buttonProps={{ size: 'tiny' }} />

				</>
			)}
			{!isEdit && (
				<div>
					<List>
						<List.Item>
							<Button onClick={toggleEditOn} size="tiny" icon="edit" style={{ marginRight: 8 }} />
							<SimpleShowMore cut_length={MAX_ROW_LENGTH} content={ItemTradeName} />
						</List.Item>
						<List.Item>
							<SimpleShowMore cut_length={MAX_ROW_LENGTH} content={ItemForm && ItemStrength ? `${ItemForm} / ${ItemStrength}` : ItemForm || ItemStrength} />
						</List.Item>
						<List.Item>{epresc?.PBSCode}</List.Item>
					</List>
					
					
				</div>
			)}
		</div>
	);
};

export default EPrescribing;
