import React, { useState, useEffect } from "react";
import SectionHeader from 'views/med/SectionHeader';
import script_model from "models/script_model";
import { Form, Accordion, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import UniFormMed from "views/med/UniFormMed";

const Cado = (props) => {
	const { store, med_data, script_type, req_type, Section } = props

    return (
		<UniFormMed
			{...props}
			section="cado"
			fields={script_model.COMM_CADO_FIELDS}
		>
			{(values, valids, uf_this, fields) => {
				return <Section>
                    {
                        store["extra_Qs-understand"] == "understand-no" &&
                                        <Button
                                                size="big"
                                                fluid
                                                color="green"
                                                as={Link}
                                                to='/med/DocConsCov'
                                                data-testid='button-doctors-consult'
                                                content='Click here to request a Telehealth Consultation with one of our doctors.'
                                            />
                        ||
                            <>

                                <Section.Header>Not sure if this is the right medication for you? (Optional)</Section.Header>

                                <Section.Content>
                                    <Form.Field label = {`If you're not sure that is the right medication for you, we recommend you request a telehealth consultation with one of our Australian-registered doctors.`} />

                                    {/*<Form.Field label = {`A${(this.cart.MEDCONS_PRICE || req_type=='premed' || script_type=='medbuy')&&'n additional' || ''} fee of $${this.cart.CADO_PRICE} applies to this service.`} />*/}
                                    {/*<Form.Field label = {`A fee of $${this.cart.CADO_PRICE} applies to this service.`} />*/}


                                            <Form.Checkbox
                                                            label={'Yes, I would like to request a telehealth consultation'}
                                                            size='massive'

                                                            name={'cado_want'}
                                                            // value={values['cado_want']}
                                                            checked={values['cado_want']}
                                                            onChange={()=>uf_this.handleInputChange({target:{name:'cado_want', type:'checkbox', value:values['cado_want']}})}
                                                            error = {valids && !valids['cado_want']}

                                                            key={"form_radio_q_"+'cado_want'+"_a_"+'no'}
                                                        />


                                        {values['cado_want'] &&   <Button
                                                    //primary
                                                    size="big"
                                                    fluid
                                                    color="blue"
                                            
                                                    as={Link}
                                                    to='/med/DocConsCov'
                                                    content='Click here to request a Telehealth Consultation with one of our doctors.'
                                                />} 


                                </Section.Content>

                        </>
                    }
                
                </Section>;
			}}
		</UniFormMed>
	);
};

export default Cado;
