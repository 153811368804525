import React from 'react';
import { Message } from 'semantic-ui-react';

const ValidationErrors = ({showErrors, validsErrorsMsg}) => {
    return <Message
                visible={showErrors && validsErrorsMsg.length>0}
                error
                header = "Some information is missing or invalid. Please check the highlighted fields."
                list={validsErrorsMsg || undefined}
                data-testid="text-validation-error"
            />;
}
 
export default ValidationErrors;