
const prc_ex = { 
	"mcpt":{
		"vb":17,
		"vc":15
	}
}

export function get_price_exp(key) {
	const variant  = app.runtime.get_params_init?.get(key)
	return prc_ex[key]?.[variant]
}