
import API_service from '../providers/API_service'
import creditCardType from 'credit-card-type'
import flex from '@cybersource/flex-sdk-web';
import { parseJwt } from 'xAppLib/helpers/jwt'

const DEBUG = false
const use_test = app.settings.dev_env_local ||  app.settings.dev_env_remote
export default class payment {

	static async tokeniseCard(card) {
		DEBUG && console.log('use_test=%o',use_test);
		DEBUG && console.log("tokeniseCard(%o, %o)",card, use_test)
		const data = await new Promise((resolve,reject)=>{
			API_service.call_api(
									'getCardPublicKey/2',
									null,
									result=>{
										if (result.error) {
											reject(result.error)
										} else {
											resolve(result)
										}
									}
							)
		})
		let jwk
		if (data.keyId) {
			const jwt = parseJwt(data.keyId)
			jwk = jwt.flx.jwk
		} else {
			jwk = data
		}
		
		let type;
		try {
			[{type}] = creditCardType(card.number)
		} catch (error) {
			throw Error('Card Type not found')
		} 
		
		DEBUG && console.log("card type=%p",type)
		switch(type) {
			case 'mastercard':
				type="002";
				break;
			case 'visa':
				type="001";
				break;
			case 'american-express':
				type="003";
				break;
			default:
				type='';
		}
		
		var options = {
                kid: jwk.kid,
                keystore: jwk,
				...jwk,
                cardInfo: {
                    cardNumber: card.number,
                    cardType: type,
                    cardExpirationMonth: card.expiry_month,
                    cardExpirationYear: card.expiry_year
                },
                encryptionType: data.encryptionType || 'RsaOaep256',
                production: !use_test // without specifying this tokens are created in test env
            };
		
		try {
			const token = await new Promise((resolve,reject)=>{
				flex.createToken(options,  (response) => {
						DEBUG && console.log("response=%o",response)
		                if (response.error !== undefined) {
		                    //alert('There has been an error!');
		                    DEBUG && console.log("error:",response);
							reject(response.error || "Unable to tokenise your card")
		                    return;
		                }

		                DEBUG && console.log('Token generated: ', JSON.stringify(response));
						resolve({...response, PAY_ENV:use_test?'TEST':'PROD'})

		            });
			})	   
			return token
		} catch (error) {
			console.log("error",error);
			const message = error.responseStatus?.details?.[0]?.message || error?.message || error || "Invalid card"
			DEBUG && console.log("message=%o",message);
			throw Error(message)
		} 
    
		
		
		

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async authorisePayment(cardToken, amount, authoriseOnly = false) {
		
		return new Promise((resolve, reject)=>{
			API_service.call_api(
									'authorisePayment',
									{ cardToken, amount, authoriseOnly},
									result=>{
										if (result.error) {
											reject(result.error)
										} else {
											resolve(result)
										}
									}
							)
		})
	}

}
