import React, { useState } from 'react';
import ProfileSyncModal from './ProfileSyncModal';
import { Button } from 'semantic-ui-react';

export default function ProfileSyncButton({ syncProfile, itm_data, containerClass }) {
	const [openModal, setOpenModa] = useState(false);

	const toggleModal = () => {
		setOpenModa((prev) => !prev);
	};

	return (
		<div className={containerClass}>
			<Button primary onClick={toggleModal} content="Sync User Profile" />
			{openModal && <ProfileSyncModal open={openModal} onClose={toggleModal} syncProfile={syncProfile} script_profile={itm_data} />}
		</div>
	);
}
