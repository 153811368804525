import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import IcosmHeader from './components/IcosmHeader';
import IcosmTiles from './components/icosmTiles';
import IcosmPartners from './components/IcosmPartners';
import IcosmGridInfo from './components/IcosmGridInfo';
import IcosmBanner from './components/IcosmBanner';
import Tiles from './components/Tiles';
import IcosmHero from './components/IcosmHero';
	
const img_path = "https://storage.googleapis.com/icosm/";
const tiles = [
    {
        title: <>Committed<br/> Doctors</>,
        desc: "Our Doctor team has an impressive median wait time of only 70 seconds. Our Doctors are available 8:30am - 8pm. With scripts as little as $15.",
        bg:'bg-icosm-tertiary'
        
    },
    {
        title: <>Quality<br/> Products</>,
        desc: "Shop our range of premium products, skincare, devices and consumables all at competitive prices.",
        bg:'bg-icosm-primary'
    },
    {
        title: <>Advanced<br/> Training</>,
        desc: "Expand yours and your teams knowledge with our advanced training options. Tailored to give your business a leading edge.",
        bg:'bg-icosm-quaternary'
    }
    
]
const grid_info = ["Rest assured that our advanced software ensures full compliance across all aspects.", "Top-tier fully integrated platform ensuring a seamless patient journey.", "Premium events for nurses of all skill levels, looking to elevate their skills & knowledge.", "Access on demand support when you need it the most." ]

const Grow = () => {

	return <div>

		<Helmet>
			<title>Grow your Business</title>
		</Helmet>

       <Container>
            
            <IcosmHero title="Ready to grow your business with InstantCosmetics?" desc="At InstantCosmetics, we're dedicated to maximising your business growth potential. That's why we've designed our platform to streamline operations and elevate your business." link="" img="2/iStock-1389993799.jpg" />
            
            <Tiles list={tiles} />
            
            <IcosmGridInfo list={grid_info} title="Ways we make building a cosmetic business easier" desc="Starting out in your own cosmetic business shouldn’t be difficult. That’s why we’ve built a solution that ensures best practice for all our nurses." cls="pt-[90px]" />

        </Container>

        <IcosmPartners />

        <IcosmBanner light img="2/iStock-1473474782.jpg" title="Get Started Today!" desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." />
	
    </div>

}

export default Grow;
