import React from 'react';
import OnePassLogo from './OnePassLogo';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const OnePassLinked = ({ onClose }) => (
    <>
        <h5 className='flex items-center'><span>You have successfully linked your</span> <OnePassLogo height={42} style={{ margin: '-8px 8px 0' }} /> <span>Account</span></h5>
        <Link onClick={onClose} to={'/onepass'} className='ui basic button w-full md:max-w-fit'>View your benefits</Link>
    </>
);

export default OnePassLinked;