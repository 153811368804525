import { useState, useEffect } from 'react';

const getWindowDimensions =() => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useViewport = () => {
  const [viewport, setViewport] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
        setViewport(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewport;
}
