import React, { useState } from 'react';
import Gravatar from 'xAppLib/Gravatar/Gravatar';

const UserPic = ({photoURL, email, name, size=24, className}) => {
	const [validPhoto, setValidPhoto] = useState(true)

	if (!email)
		return null
		
	const avatarSize = `${size}px`
	const picStyle = { width:avatarSize, height:avatarSize }

	return photoURL && validPhoto ?
			<img
				src={photoURL}
				style={picStyle}
				className={className}
				onError={() => setValidPhoto(false)}
			/> 
			:
			<Gravatar
				email={email}
				name={name || 'Guest'}
				size={size * 2}
				style={picStyle}
				className={className}
			/>
}
 
export default UserPic;