import currency from "currency.js";

export default class onepass_model {
    static isValidOnepassMember() {
        return app?.user?.prof?.onepass;
    }

    /**
     * 
     * @param {med_db_data|cart_model.content} data 
     * @returns 
     */
    static isValidOnepassItem(data) {
        /**
         * Only for iscr
         * Only for pharm
         * Only for cons & certs
         * Not for treatment plans or copay
         */
        return app?.runtime?.mode === 'iscr'
            && app?.app_data?.ws_data?.cat_data['pharm'] // Only for pharm
            && !data?.f?.cst?.toString().toLowerCase().includes('medicare rebate') // Not for copay
            && !["docconswlo", "docconswli"].includes(data?.req_tp) && !["DocConsWLOral", "DocConsWLInj"].includes(data?.c) // Not for Weight Loss Oral Medications / Self-injections
            && (
                // Only for cons & certs
                ['doccons', 'medcons', 'cert', 'patho', 'specialist-referrals'].some(element => data?.c?.toLowerCase().includes(element) || data?.par_id?.toLowerCase().includes(element) || data?.l?.toLowerCase().includes(element) || data?.m?.toLowerCase().includes(element) || data?.itm_tp?.toLowerCase().includes(element) || data?.items?.map(({ id }) => id?.toLowerCase()).includes(element))
                // For medcons, only for non-treatment plans
                || data?.need_qual
            );
    }

    static discountedPriceString(originalPrice) {
        return currency(originalPrice).multiply(0.95).format();
    }
}
