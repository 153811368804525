import React from 'react';
import { cls, col } from './utils';

const bg_col = {gray:'bg-gray-100',red:'bg-red-100',yellow:'bg-yellow-100',green:'bg-green-100',blue:'bg-is-blue-50',indigo:'bg-indigo-100',purple:'bg-purple-100',pink:'bg-pink-100'}
const fg_col = {gray:'text-gray-800',red:'text-red-800',yellow:'text-yellow-800',green:'text-green-800',blue:'text-is-blue-900',indigo:'text-indigo-800',purple:'text-purple-800',pink:'text-pink-800'}
const Badge = ({ color = 'gray', children, large, small, className, dot, testId, onClick }) => {
    const cl = cls(
        'inline-flex items-center px-2.5 py-0.5 rounded-full font-normal',
        large ? 'text-lg' : small ? 'text-sm' : 'text-base',
        col(color,'gray',fg_col),
        col(color,'gray',bg_col),
        className
        )

    return  <span data-testid={testId} className={cl} onClick={onClick}>
            {dot && <svg className={cls("mr-1.5 h-2 w-2" ,col(color,'gray',fg_col))} fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
            </svg>}{children}</span>;
}
 
export default Badge;