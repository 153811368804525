import React, { Component } from 'react';
import { Dropdown, Button } from 'semantic-ui-react'

class SelectOption extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value : props.value,
			editing : props.editButton ? false : true
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value != this.props.value) {
			this.setState({value:this.props.value})
		}
	}

	render() {
		const { value, editing } = this.state
		const { placeholder = 'Select', options = [], onSelect, onClear, noConfirm=false, multiple=false, } = this.props

		let val = options&&options.filter(k => k.key==value)
		val = val && val[0]

		if (!editing) {
			return <React.Fragment>
					<h5 style={{display:'inline'}}>{val&&val.text} {val&&val.subtext && <React.Fragment><br/><small>{val&&val.subtext}</small></React.Fragment>}</h5>
					<Button 	
						compact
						size='mini'
						onClick={_=>this.setState({editing:true})}>Edit</Button>
				</React.Fragment>
		}

		return (
			<Dropdown
					fluid
					clearable
					selection
					selectOnBlur={false}
					className="select-option"
					closeOnChange
					lazyLoad
					multiple={multiple}
					onChange={async (e,{value}) => {
						let val = null;
						if (multiple) {
							if (value.length === 0) {
								val = 'NONE';
							} else {
								val = '"' + value.map(v => {
									return options?.find(({key}) => key === v)?.text ?? '??'
								}).join(', ') + '"';
							}

						} else {
							val =  options&&options.filter(k => k.key==value)
							val = (val?.[0]?.text) ?? 'NONE';
						}
						
						// const answer = await app.confirm('Confirm Change Access', `Update ${this.props.name} to ${val&&val.text||'NONE'}`)

						// if (answer == 'yes') {
						if (
							noConfirm || (
								await app.confirm(
									'Confirm Change Access',
									`Update ${this.props.name} to ${val}`
								)) == 'yes'
						) {
							this.setState({ value })
							if (value != '') {
								onSelect && onSelect(value)
							} else {
								onClear && onClear(value)
							}
						}
						
					}}
					search={(options, query) => {
						const q = query.toLowerCase()
					  	return options.filter(opt => opt.search.includes(q))
					}}
					options={options.map(o=>{
						return {
							key:o.key,
							value:o.value,
							search:`${o.text} ${o.subtext}`.toLowerCase(),
							text:<h5>{o.text}{o.subtext && <React.Fragment><br/><small>{o.subtext}</small></React.Fragment>}</h5>
						}
					})}
					placeholder={placeholder}
					value={value}
				  />

		);
	}

}

export default SelectOption;
