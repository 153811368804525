import React, { lazy, Suspense } from 'react';
import UniForm from 'xAppLib/UniForm/UniForm';
import script_model from 'models/script_model';
import { Form, Checkbox, Image } from 'semantic-ui-react';
import UniFormMed from 'views/med/UniFormMed';
import SonicLabLocationLink from '../../../UIelems/SonicLabLocationLink';
const SonicAdvertisement = lazy(_=>import('../../../UIelems/SonicAdvertisment'))

const PathologyItems = (props) => {
  const { med_data, store, patho_request, script_type, req_type, onUpdate, Section, formData } = props;

  if (!patho_request) return null;

  const swabs = [
    { label: 'extra anal swab (for male-to-male sex or other non-vaginal sex)', key: 'm2m_anal' },
    { label: 'extra pharyngeal swab (for male-to-male sex or other non-vaginal sex)', key: 'm2m_pharyngeal' },
    { label: 'extra self collected vaginal swab', key: 'm2f_vaginal' },
  ];

  return (
    <UniFormMed {...props} section="pathology" fields={script_model.COMM_PATHO_FIELDS}>
      {(values, valids, uf_this, fields) => {
        const { selected_items = {} } = values;
        return (
          <Section>
            <Section.Header>Pathology Tests Included</Section.Header>
            <Section.Content>
              <Suspense fallback={<div>Loading...</div>}>
                <SonicAdvertisement postcode={formData?.full_address?.postcode} addClassName='mb-6'/>
              </Suspense>
              {patho_request.tests.map(({ name, key, selected }, i) => {
                return (
                  <Form.Field key={key}>
                    <Checkbox
                      disabled={!!med_data.itm_lock}
                      label={name}
                      checked={selected_items[key]}
                      onChange={(e, { name, value, checked }) =>
                        uf_this.handleInputChange(null, { name: 'selected_items', value: { ...selected_items, [key]: !!med_data.itm_lock || checked } })
                      }
                    />
                    {key == '-MPGYxzJ2_x0UGF7sqMn' && (
                      <Form.Field style={{ paddingLeft: '.5em', paddingTop: '.5em', display: 'flex', flexDirection: 'column' }}>
                        {swabs.map(({ label, key }) => (
                          <Checkbox
                            label={label}
                            checked={selected_items[key]}
                            onChange={(e, { name, value, checked }) =>
                              uf_this.handleInputChange(null, { name: 'selected_items', value: { ...selected_items, [key]: checked } })
                            }
                          />
                        ))}
                      </Form.Field>
                    )}
                  </Form.Field>
                );
              })}
              <SonicLabLocationLink suburb={formData.full_address?.suburb} postcode={formData.full_address?.postcode}/>
            </Section.Content>
          </Section>
        );
      }}
    </UniFormMed>
  );
};

export default PathologyItems;
