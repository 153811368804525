
const file_to_base64 = 
	(file, header = true) => new Promise( (resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (header) resolve(reader.result);
			else resolve(reader.result.slice(reader.result.indexOf(',') + 1, reader.result.length))
		}
		reader.onerror = error => reject(error);
	} );

export default file_to_base64