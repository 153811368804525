import React from 'react';
// import { Button } from "semantic-ui-react";
// import { Link } from "react-router-dom";

export const MessageType = {
	MUST_VERIF: 'MUST_VERIF',
	INCOMPLETE_PROFILE: 'INCOMPLETE_PROFILE'
};

const MESSAGE = {
	[MessageType.MUST_VERIF]: {
		header: `We couldn't verify your account details`,
		content: (
			<>
				We require your account to be verified with Medicare in order to request a prescription or consultation.
				<br />
				Please check your profile details. Your full name, date of birth, and Medicare number or full address should match your Medicare record.
				<br />
				Use Edit button above to change your details.
				<br />
				<br />
				{/*
				<Button
						size="small"
						color="green"
						as={Link}
						to='/med/DocConsCov'
						content='Click here to request a Telehealth Consultation with one of our doctors.'
					/>*/}
			</>
		)
	},
	[MessageType.INCOMPLETE_PROFILE]: {
		header: 'Your profile is incomplete',
		content: 'To proceed, please ensure that all required details are completed.'
	}
};

const FormMessage = (props) => {
	const { Section, enabled, visible, type } = props;

	if (!(enabled && visible && type)) return null;

	return (
		<Section>
			<Section.Header small>{MESSAGE[type].header}</Section.Header>

			<Section.Content>{MESSAGE[type].content}</Section.Content>
		</Section>
	);
};

export default FormMessage;
