import React from "react";
import { useHistory, useUser } from 'xAppLib/Hooks/Users';
import { usePrepStatus } from './Hooks';

const GetStarted = ({label}) => {
    const {has_patho,has_results,has_presc,step} = usePrepStatus()

    return (  
        label 
        || step == 5 && "Order Prep"
        || step <= 2 && 'Let’s get started' 
        || 'Ordering Process' ) ;
}
 
export default GetStarted;