import React from 'react';
import { 	Container,
			List,
			Input,
			Button,
			Form,
			Divider,
			Popup,
			Icon,
			Message,
			Grid,
			Accordion,
			Header,
			Checkbox,
			Modal,
			Segment,
		} from 'semantic-ui-react'
import messaging_model from 'models/messaging_model'
import { useAppNotifications } from 'xAppLib/Hooks';
import {ManageTopicSubscription} from "../../views/UIelems/ManageTopicSubscription";
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';

const NotificationsRequest = ({enabled = true, onChange, description, size, color, basic = false, show_if_needed = false}) => {
	const notifications = useAppNotifications()

	if (!enabled || !messaging_model.support_noti() || show_if_needed&&notifications.allowed)
			return null
	

		return <Segment size={size} color={color} basic={basic} style={basic && {paddingLeft:0,paddingRight:0} || {backgroundColor: '#FAFAFA'}}>
		
			<h5 className="flex items-center"><SIcon size="xs" name='alarm-bell' cls="mr-1" bold/> Receive notifications</h5>
			
			{notifications.allowed && (<>
					<p><Icon name='check' color='green' /> You are receiving notifications</p>
					{app.acl.is_doc &&
						<ManageTopicSubscription
							title="Instant Consultation request notifications"
							topic={`docid%${app.user.claims.doc_id}`}
							description="Receive in-app and offline notifications during your rostered times"
						/>
					}
				</>)
				||
				<React.Fragment>
					{ (notifications.blocked) && <React.Fragment>
							<p>You have disabled notifications. Please check your { app.settings.is_ionic ? 'device' : 'browser'} settings to enable them again.</p>
					
						</React.Fragment> || <React.Fragment>
							<p className="text-sm text-is-black-50">{description || `You can choose to receive notifications on this ${ app.settings.is_ionic ? 'device' : 'browser'} to be notified quickly.`}</p>
							<p className="text-sm text-is-black-50">After clicking the button, look for the { app.settings.is_ionic ? 'device' : 'browser'} confirmation and press "Allow".</p>
						
							<Button
								onClick={ async _ => {
									const ret = await messaging_model.req_noti_perms()
									if (!ret) {
										alert("Could not enable notifications. Please check your device settings to enable it.")
									}
								} }
								fluid
								className='sm:max-w-fit'
								basic
								type="button"
								content={`Enable notifications`}
							/>
						</React.Fragment>}
					

				</React.Fragment>
			} 

			

		</Segment>
	
}
	


export default NotificationsRequest;
