import React, { useEffect, useState } from "react";
import {
    Button,Modal
} from 'semantic-ui-react'
import API_service from 'xAppLib/providers/API_service'
import MBSSelector from "../../medicare/MBSSelector";
import currency from "currency.js";
import medicare_model from 'models/medicare_model'

const DEBUG = false

const UpdScript = ({script, onRefreshList}) => {
	const [pending,setPending] = useState(false)
	const [option,setOption] = useState(false)
	// const prefill = script.paym?.mdcr_itm
	// const medicare_item = prefill?.num || prefill?.code || null
	// const prefill_extras = script.paym?.mdcr_extras
	// const medicare_item_extra = prefill_extras && prefill_extras.reduce((o,v)=>Object.assign(o,{[v.code]:true}),{}) || {}
	const [items,setItems] = useState({medicare_item:null, medicare_item_extra:{}})
	

	const options = [
		{
			name:'Confirm MHCP Plan',
			amount:script.med_db_data.xtra?.mhcp,
			item:92116,
			req_type:'mhcp'
		},
		{
			name:'Confirm MHCP Review',
			amount:script.med_db_data.xtra?.mhcprev,
			item:92126,
			req_type:'mhcprev'
		},
	]

	const {name} = option || {}

	useEffect(()=>{
		setItems(v=>({...v,medicare_item:option?.item}))
	},[option])

	let price = currency(script.med_db_data.xtra?.copay)
	if (option)
		price = price.add(option.amount)

	const discount = script.paym.items.find(i=>i.id=='DISCOUNT')

	if (discount) {
		price = price.add(discount.price)
	}

	DEBUG && console.log(option,price,script.med_db_data.xtra, {items})

	return <div>
		<div className="mt-2">
			{script.status !== "flagged_urgent" ? options.map((option,i)=>
				<Button
					key={i}
					size='mini'
					disabled={pending}
					loading={pending}
					onClick={()=>setOption(option)}
					className="!mr-1 w-[198px]"
				>
					{option.name}

				</Button>) : null}
		</div>
		<div className="mt-1">
			{options.map((option,i)=>
				<Button
					key={i}
					color="red"
					size='mini'
					disabled={pending}
					loading={pending}
					onClick={()=>setOption({...option, urgent: true})}
					className="!mr-1 w-[198px]"
				>
					{option?.name?.replace("Confirm", "Urgent")}
				</Button>)}
		</div>
		{option && <Modal open={true}
               onClose={()=>setOption(null)}
        >
            <Modal.Header>{option.urgent ? option?.name?.replace("Confirm", "Urgent") : option?.name}</Modal.Header>
            
            <Modal.Content>
				{option.urgent ? <div className="ui warning message">
					This action will flag the consult for urgent care.
				</div> : null}
			<p>
				Set price to {price.format()} inc. ${script.med_db_data.xtra?.copay} copayment. {discount && <strong>inc discount of {discount.price}.</strong>} <br/>Request will be placed in GP WR.
			</p>
			<MBSSelector 
				medicare_item={items.medicare_item}
				medicare_item_extra={items.medicare_item_extra}
				onSelect={val=>setItems((v)=>({...v,...val}))}
				showExtra={false}
				readOnly={true}
			/>
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={pending} loading={pending} content='Cancel' onClick={()=>setOption(null)}/>
                <Button disabled={pending || !items.medicare_item} loading={pending} color='green' content='Submit'
                        onClick={async () => {

							const {medicare_item,medicare_item_extra} = items

							const extras = Object.keys(medicare_item_extra).map(k=>medicare_model.MEDICARE_ITEMS_EXTRA[k])

							let mdcr = { diff: {to: {itm: medicare_model.MEDICARE_ITEMS[medicare_item],extras }, part: 'paym-mdcr'} }

							const req_type = option.req_type
							
							const upds = [
								{diff:{part:'price',to:{price:price.value}}},
								mdcr,
								{
									diff: {
										part: 'status', to: {
											status: script.status === 'flagged_urgent' || option.urgent
												? 'flagged_urgent'
												: 'await_doccall',
											req_type
										}
									}
								}
							]
							DEBUG && console.log(upds)
							if (await app.confirm(name,`Are you sure?`) !== 'yes')
								return
							setPending(true)
							try {
								const ret = await API_service.load_data(
									'scripts/upd_script', 
									{
										sid:script.sid,
										upds
									}
								)
								app.functions.add_message(name, 'Done', 'positive');
								setOption(null)
								onRefreshList?.()
								
			
							} catch (error) {
								app.functions.add_message(name, 'Error:'+error.message, 'negative');
								console.log(error)
							}
							
							setPending(false)
						}}/>
            </Modal.Actions>
        </Modal>}
	
	</div>;
}


export default UpdScript;