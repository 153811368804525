import React, {useMemo, useRef, useState} from 'react';
import moment from "moment";

import webrtc_logs_model from "../../../../models/webrtc_logs_model";
import UniFormMed from "../../UniFormMed";
import TestMyVideo from "../../../supp/TestMyVideo";
import { Button, Checkbox, Icon, Message, Modal, Portal } from "semantic-ui-react";
import { useAsync } from "../../../../xAppLib/Hooks/useAsync";
import { AsyncButton } from "../../../UIelems/AsyncButton";

const FIELDS = [
	{
		name: 'vid_call_network_test',
		label: 'Confirm your video is working',
		type: 'object',
		valid_not_required: v => {
			return (!!v.vid_call_network_test) || (v.vid_call_network_test === false);
		}
	}
];

function RunNetworkTest({onResult, valid, error, value, req_type}) {
	const [stats, setStats] = useState([]);
	const [open, setOpen] = useState(false);
	const actions = useRef();
	const isComplete = stats[stats.length - 1]?.complete;

	const status = (() => {
		switch (value?.video?.overall_status) {
			case 'good':
				return {
					message: 'Your video is working well',
					icon: 'check circle outline',
					type: 'success'
				};
			case 'fair':
				return {
					message: 'Your video is working, but may be a little slow',
					icon: 'check circle outline',
					type: 'success'
				};
			case 'poor':
				return {
					message: 'Your video is not working well, you may need to restart your device, or speak to support',
					icon: 'exclamation triangle',
					type: 'warning'
				};
			default:
				return {
					message: 'Your video quality could not be determined, you may want to run the test again',
					icon: 'exclamation triangle',
					type: 'warning'
				};
		}
	})();

	const { data: last_session } = useAsync(async () => {
		const log = await webrtc_logs_model.get_last_session_log_for_device();

		if (
			// Consider tests stale for cosmetics if they're more than a week old
			// It's an optional test, but this way they should test more often as we get more accurate stats in UI
			req_type === 'cosm'
			&& log?.stats?.raw?.timestamp
			&& moment().subtract(1, 'week').isAfter(log.stats.raw.timestamp)
		) {
			return null;
		}

		if (['fair', 'good'].includes(log?.stats?.video?.overall_status)) {
			onResult(log.stats);
			return log.stats;
		}

		return null;
	});

	const last_tm = (stats.slice(-1)[0] || last_session)?.raw?.timestamp;
	const is_cosm = req_type === 'cosm';

	return (
		<div>
			{valid ? (<>
				<Message visible {...{[status.type]: true}} icon>
					<Icon name={status.icon} size="large"/>
					<Message.Content>
						<Message.Header>
							{status ? "Video test results" : "Unknown video status"}
						</Message.Header>
						<p>{status.message}</p>
						{is_cosm && last_tm && <p>Last test: {moment(last_tm).format('D MMMM yyyy')}</p>}
					</Message.Content>
				</Message>
				</>
			) : (<>
				<Message visible info error={error} icon="exclamation circle" header={is_cosm ? "Note" : "Important"} content={
					is_cosm && <>
						You can test your video is working correctly now before starting a consult with the doctor.
					</> ||
					['mhcp', 'mhcprev'].includes(req_type) && <>
					Medicare requires this consultation to be done via a video call. Please run the following test to ensure video works on your device.<br/>
					Please run the following test to ensure your device supports video conferencing and is correctly
					configured.
					</> ||
					<>We can only provide this service via a Telehealth video consult.<br/>
					Please run the following test to ensure your device supports video conferencing and is correctly
					configured.
					</>
				} />
			</>)}

			<Button type="button" color={is_cosm ? undefined : "green"} basic={valid || is_cosm} onClick={() => setOpen(true)}>
				{valid ? 'Test again' : 'Test my video now'}
			</Button>
			{false && !valid && (<div className="mt-4">
				<Checkbox
					checked={value === false}
					label="I cannot test my video right now, but will ensure I am able to do a video consult with the doctor"
					onChange={_ => onResult(value === false ? undefined : false)}
				/>
			</div>)}
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			>
				<Modal.Header>Video Quality Test</Modal.Header>

				<Modal.Content scrolling>
					<TestMyVideo
						embedded
						autoStart
						showPermissionsInstruction={!(valid || stats.length)}
						trigger={({stop, isRunning}) => (isRunning ? <Portal open mountNode={actions.current}>
							<AsyncButton color="green" basic={!isComplete} onClick={stop}>
								{isComplete ? 'Test Complete' : 'End Test'}
							</AsyncButton>
						</Portal> : 'Starting test...')}
						onStart={() => setStats([])}
						onEnd={() => {
							if (stats[stats.length - 1]?.video?.overall_status) {
								onResult(stats[stats.length - 1]);
							}
							setOpen(false);
						}}
						onLog={(label, data) => {
							if (label === 'stats' && data?.stats) {
								setStats(prev => [...prev, data.stats])
							}
						}}
					/>
				</Modal.Content>

				<Modal.Actions>
					<span ref={actions} />
					<Button onClick={() => setOpen(false)} color="grey" basic>
						Cancel <Icon name="check circle outline" className="right"/>
					</Button>
				</Modal.Actions>

			</Modal>
		</div>
	);

}

export default function ConfirmVideoSelfTest(props) {
	const {visible, enabled, Section, script_type, req_type, med_data} = props;

	if (!(visible && enabled)) {
		return null;
	}

	const fields = useMemo(() => {
		return FIELDS.map(field => {
			if (req_type === 'cosm' && field.name === 'vid_call_network_test') {
				return {
					...field,
					valid_not_required: true,
				};
			}

			return field;
		});
	}, [req_type]);

	return (
		<Section>
			<Section.Header>Video Call Test</Section.Header>
			<Section.Content>
				<UniFormMed {...props} section="vid_call_network_test" fields={fields}>
					{(values, valids, uf_this) => (<>
						<RunNetworkTest
							script_type={script_type}
							req_type={req_type}
							value={values.vid_call_network_test}
							valid={!!(values.vid_call_network_test)}
							error={valids?.vid_call_network_test === false}
							onResult={value => uf_this.handleInputChange({
								target: {
									name: 'vid_call_network_test',
									value: value && {
										video: value.video,
										audio: value.audio,
										ts: value.raw?.timestamp,
									}
								}
							})}
						/>
					</>)}
				</UniFormMed>

			</Section.Content>
		</Section>
	);
}