//	------------------------	------------------------	------------------------
//	Description: Functions for random generation
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const alpha_upper =         'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const alpha_lower =         'abcdefghijklmnopqrstuvwxyz'
const alpha =               alpha_upper + alpha_lower
const numeric =             '0123456789'
const alpha_numeric =       alpha + numeric

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

// eslint-disable-next-line no-undef
module.exports = {

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    genHexString: (length = 1) => { return genHexString(length) },

    //	------------------------	------------------------	------------------------

    make_str: (length = 1, characters = alpha_numeric) => { return make_str(length, characters) },

    //	------------------------	------------------------	------------------------

    make_alpha_str: (length = 1) => { return make_str(length, alpha) },

    //	------------------------	------------------------	------------------------

    make_num_str: (length = 1) => { return make_str(length, numeric) },

    //	------------------------	------------------------	------------------------

    make_alpha_str_caps: (length = 1) => { return make_str(length, alpha_upper) },

    //	------------------------	------------------------	------------------------

    randomInteger: (min = 0, max = 1) => { return Math.floor(Math.random() * (max - min + 1)) + min },

}

//	------------------------	------------------------	------------------------

function genHexString(length) {
    let output = ''
    for (let i = 0; i < length; ++i) output += (Math.floor(Math.random() * 16)).toString(16)
    return output
}

//	------------------------	------------------------	------------------------

function make_str(length = 1, characters = alpha_numeric) {
    let result = ''
    const charactersLength = characters.length
    for ( let i = 0; i < length; i++ ) result += characters.charAt(Math.floor(Math.random() * charactersLength))
    return result
}