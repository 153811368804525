import React from "react";
import { PreviewDocument } from "../../../UIelems/PreviewDocument";
import API_service from "../../../../xAppLib/providers/API_service";

function consentFileUrl(consentFileName) {
    return API_service.API_BASE_URL+`file/doc/view/cosm-consent/${encodeURIComponent(`consent/${consentFileName}`)}?Authorization=Bearer ${app.user.token}`
}

const Consent = (props) => {
    const { Section, enabled, visible } =
        props;

    if (!(visible && enabled)) {
        return null;
    }

    const consentFileName = props.med_data?.xtra?.consent;

    return (
        <Section>
            <Section.Header small>Patient Consent to Treatment</Section.Header>
            <Section.Content>
                <p className="text-sm text-is-black-50">
                    By signing the below I am consenting to the Treatment as
                    explained by the practitioner and acknowledge that I will
                    receive details of the treatment, consultation, consent and
                    all related conditions and guidance.
                </p>

                <PreviewDocument className="text-blue-600"
                                 viewOnly
                                 doc_name="Treatment Consent Form"
                                 url={consentFileUrl(consentFileName)}
                >
                    Read treatment information & consent
                </PreviewDocument>
            </Section.Content>
        </Section>
    );
};

export default Consent;
