import React, { Component} from 'react'
import {Helmet} from "react-helmet"
import { Link } from 'react-router-dom'
import { Container, Button, Icon, Image, Grid } from 'semantic-ui-react'
import Video from 'xAppLib/UIelems/Video'
import device_model from 'models/device_model'
import HomepagePatient from "./HomepagePatient";


const about = () => {
	
	return <>
		<Helmet>
			<title>About Us</title>
		</Helmet>
		<HomepagePatient publicView about/>
		</>
	

}
export default about
