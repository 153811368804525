
// adapted fron https://github.com/Donaldcwl/browser-image-compression/blob/77682f70e4db0a5313bc6f9adf248ee973de4cae/lib/utils.js#L134
export function getExifOrientation(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = e => {
			const view = new DataView(e.target.result);
			if (view.getUint16(0, false) != 0xffd8) {
				return resolve(-2);
			}
			const length = view.byteLength;
			let offset = 2;
			while (offset < length) {
				if (view.getUint16(offset + 2, false) <= 8) return resolve(-1);
				const marker = view.getUint16(offset, false);
				offset += 2;
				if (marker == 0xffe1) {
					if (view.getUint32((offset += 2), false) != 0x45786966) {
						return resolve(-1);
					}

					var little = view.getUint16((offset += 6), false) == 0x4949;
					offset += view.getUint32(offset + 4, little);
					var tags = view.getUint16(offset, little);
					offset += 2;
					for (var i = 0; i < tags; i++) {
						if (view.getUint16(offset + i * 12, little) == 0x0112) {
							return resolve(
								view.getUint16(offset + i * 12 + 8, little)
							);
						}
					}
				} else if ((marker & 0xff00) != 0xff00) {
					break;
				} else {
					offset += view.getUint16(offset, false);
				}
			}
			return resolve(-1);
		};
		reader.onerror = e => reject(e);
		reader.readAsArrayBuffer(file);
	});
}


// adapted from https://stackoverflow.com/a/40867559/192705
export function resetOrientation(srcBase64, srcOrientation) {
	return new Promise((resolve) => {
		var img = new Image();

		img.onload = function() {
			var width = img.width,
				height = img.height,
				canvas = document.createElement("canvas"),
				ctx = canvas.getContext("2d");

			// set proper canvas dimensions before transform & export
			if (4 < srcOrientation && srcOrientation < 9) {
				canvas.width = height;
				canvas.height = width;
			} else {
				canvas.width = width;
				canvas.height = height;
			}

			// transform context before drawing image
			switch (srcOrientation) {
				case 2:
					ctx.transform(-1, 0, 0, 1, width, 0);
					break;
				case 3:
					ctx.transform(-1, 0, 0, -1, width, height);
					break;
				case 4:
					ctx.transform(1, 0, 0, -1, 0, height);
					break;
				case 5:
					ctx.transform(0, 1, 1, 0, 0, 0);
					break;
				case 6:
					ctx.transform(0, 1, -1, 0, height, 0);
					break;
				case 7:
					ctx.transform(0, -1, -1, 0, height, width);
					break;
				case 8:
					ctx.transform(0, -1, 1, 0, 0, width);
					break;
				default:
					break;
			}

			// draw image
			ctx.drawImage(img, 0, 0);

			// export base64
			resolve(canvas.toDataURL());
		};
		img.src = srcBase64;
	});
}
