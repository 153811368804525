import React, { useState } from "react";
import { Form, Grid, Button, GridRow } from "semantic-ui-react";

const email_regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const YogpManual = ({ values, valids, uf_this, handleResultSelect }) => {

    const [errors, setErrors] = useState({ yogp_email: "", yogp_name: "" });
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [notListed, setNotListed] = useState(false);

    const validate = () => {
        const validationErrors = {};
        if (!email)
            validationErrors.email = "Enter your doctor's email address.";
        else if (!email_regex.test(email))
            validationErrors.email = "Must be a valid email address.";

        if (!name)
            validationErrors.name = "Enter your doctor's name.";

        if (validationErrors.name || validationErrors.email) {
            setErrors(validationErrors);
            return false;
        }

        return true;
    }

    const content = notListed ? (
        <Grid.Column width={24} className="m-4">
            <Grid.Row className="mb-4">
                <Grid.Column>
                    <Form.Field
                        label="Enter your GP's name and email address"
                        name="yogp_label"
                    />
                </Grid.Column>
            </Grid.Row>
            <div className="flex justify-between items-center grow">
                <div className="pr-4 flex-1">
                    <Form.Field
                        placeholder="Enter Name"
                        control="input"
                        type="text"
                        name="yogp_name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={errors.name}
                    />
                </div>
                <div className="pr-4 flex-1">
                    <Form.Field
                        placeholder="Enter Email Address"
                        control="input"
                        type="email"
                        name="yogp_email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors.email}
                    />
                </div>
                <div>
                    <Button
                        compact
                        primary
                        type="button"
                        onClick={() =>
                            validate() &&
                            handleResultSelect({
                                email,
                                name,
                                key: "manualEntry",
                            })
                        }
                    >
                        Change
                    </Button>
                </div>
            </div>
        </Grid.Column>
    ) : (
        <Button size="small" fluid onClick={() => setNotListed(true)}>
            My doctor is not listed
        </Button>
    );

    return (
        <div className="border my-4 rounded rounded-lg border-gray-200">
            {content}
        </div>
    );
};

export default YogpManual;
