import React, { useEffect, useState } from 'react';
import SectionHeader from 'views/med/SectionHeader';
import UniForm from 'xAppLib/UniForm/UniForm';
import { Form, Checkbox, Icon, Popup } from 'semantic-ui-react';
import script_model from 'models/script_model';
import UniFormMed from "views/med/UniFormMed";
import { PARTN } from "../../../../constants";


const CollectionMethod = ( props ) => {
    const { 
		store, med_data, med_mb, script_type, home_delivery_available, 
		has_escript, can_escript, ihi_load, has_ihi, c2u, delivery_methods, 
		Section, aus_post_delivery, evermed, is_spon
	} = props

	const c2u_note = 1 && 
		<Popup position='top center' trigger={<Icon size="small" style={{marginLeft:'5px'}} name="question circle outline" color="blue" />}>
			<Popup.Header>Chemist2U delivery time</Popup.Header>
			<Popup.Content>Delivery times are an estimate only. Greater than 95% of C2U deliveries are within the communicated timeframe.<br/><br/>
				Deliveries that are over 24 hours later than the communicated timeframe and without a communicated reason could be reimbursed for the shipping cost.<br/><br/>C2U does not deliver to PO Boxes.</Popup.Content>
		</Popup>

    return	<UniFormMed
                {...props}
				section="collection"
				fields={script_model.COMM_DELIVERY_FIELDS}
				>
						{ (values, valids, uf_this, fields) => {
						return <Section>
							<Section.Header>{["medsub"].includes(script_type) ? "Delivery" : "Collection"} method</Section.Header>
							<Section.Content start>

								<Form.Field
									label = 'Please select a method of collecting your script.'
								/>
									{delivery_methods.escript && <Form.Field error={valids && !valids.delivery}>
										<Checkbox
											radio
											label={<label>{ihi_load && <Icon loading name='spinner' size='small' />}<span className='font-semibold'>Pick-up from pharmacy</span>
												{!can_escript && 
												" – Not available, please check your profile details. Your full name, date of birth, and Medicare number should match your Medicare record." ||
												"" //Need some text in here
												} 
												<br/>
												<small style={{display:'block',marginTop:'.5em'}}>
													Your electronic prescription (eScript) will be sent to you via email and SMS. 
												</small>
												<small style={{display:'block',marginTop:'.25em'}}>
													eScripts can be <strong>dispensed at any pharmacy</strong>. 
												</small>
												<small style={{display:'block',marginTop:'.25em'}}>
													You will need to select a backup pharmacy in case an eScript cannot be issued.	
												</small>
												{/* <small style={{display:'block',marginTop:'.5em'}}>
													Your Electronic Prescription is sent to you by email and SMS. eScripts can be used at <strong>ANY</strong> pharmacy to purchase your medication.
												</small>
												<small style={{display:'block',marginTop:'.25em'}}>
													Delivery time: <strong>Prescription delivered to your phone in less than 15 minutes</strong><br/>
													{ / * Additional cost: <strong>$0.00</strong> * / }
												</small> */}
											</label>}
											name='delivery'
											value='escript'
											disabled={!can_escript}
											checked={store.delivery === 'escript'}
											data-testid='radio-delivery'
											onChange={(e, { value }) => {
												(has_ihi || is_spon) && uf_this.handleInputChange(null,{name:'delivery',value})}
											}
										/>
									</Form.Field>}

									{delivery_methods.c2u_err && 
										<Form.Field error={valids && !valids.delivery}>
											<Checkbox
												radio
												data-testid='radio-delivery'
												label={<label>{c2u.loading && <Icon loading name='spinner' size='small' />}<span className='font-semibold'>Express delivery within 24 hours</span>
													{!c2u.loading && 
														<><small style={{display:'block',marginTop:'.5em'}}>Express delivery not available to your shipping address {c2u_note}</small></> ||
														<><small style={{display:'block',marginTop:'.5em'}}>Checking your shipping address...</small></>
													 }
												</label>}
												disabled={true}
												checked={false}
											/>
										</Form.Field>
									}

									{delivery_methods.c2u && 
										<Form.Field error={valids && !valids.delivery}>
											<Checkbox
											radio
											data-testid='radio-delivery'
											label={<label>{c2u.loading && <Icon loading name='spinner' size='small' />} <span className='font-semibold'>Express delivery within 24 hours (estimated)</span>
												{!can_escript && 
												" – Not available, please check your profile details. Your full name, date of birth, and Medicare number should match your Medicare record." ||
												"" //Need some text in here
												} 
													{!c2u.loading && <><br/>
														{c2u.ok &&
														<>
															<small style={{display:'block',marginTop:'.5em'}}>
																Chemist2U will deliver your medication to your shipping address. 
																{/*<br/> from a nearby Chemist2U partner pharmacy
																Please be aware you will be required to be be home for delivery and ID maybe requested.*/}
															</small>
															<small style={{display:'block',marginTop:'.25em'}}>
																Expected delivery time: <strong>{c2u.formatted}</strong>
																{c2u_note}
																{/*<br/>
																Delivery cost: <strong>$8.95</strong>*/}
															</small>
														</> || <><small style={{display:'block',marginTop:'.5em'}}>Delivery not available to your shipping address {c2u_note}</small></>}{/* I don't think this is every displayed*/}
													</>}
												</label>}
												name='delivery'
												value='c2u'
												disabled={!c2u.ok || !can_escript}
												checked={store.delivery === 'c2u'}
												onChange={(e, { value }) => {uf_this.handleInputChange(null,{name:'delivery',value})} }
											/>
										</Form.Field>
									}

									{delivery_methods.home && 
										<Form.Field error={valids && !valids.delivery}>
											<Checkbox
												radio
												data-testid='radio-delivery'
												label={<label><span className='font-semibold'>Post delivery within {aus_post_delivery?.time || '2 - 4 business days'} (estimated)</span>
													{!can_escript && 
													" – Not available, please check your profile details. Your full name, date of birth, and Medicare number should match your Medicare record." ||
													"" //Need some text in here
													} 
													<small style={{display:'block',marginTop:'.5em'}}>
														{med_mb?.medbuy_supp_oid==PARTN.DUKASA && 'Dukasa Compounding Pharmacy' ||
															med_mb?.medbuy_supp_oid==PARTN.BLS && 'BLS Clinic' ||
															med_mb?.medbuy_supp_oid==PARTN.C2U && 'Chemist2U' ||
															'Prahran Amcal'} will deliver your medication to your shipping address through Australia Post Express Shipping.
													</small>
													{/*<small style={{display:'block',marginTop:'.25em'}}>
														Delivery time: <strong>Medication delivered to your door in {aus_post_delivery?.time || '2 - 4 business days'}</strong><br/>
														Delivery cost: <strong>$7.00</strong>
													</small>*/}
												</label>}
												name='delivery'
												value='home'
												disabled={!can_escript}
												checked={store.delivery === 'home'}
												onChange={(e, { value }) => {uf_this.handleInputChange(null,{name:'delivery',value})} }
											/>
										</Form.Field>
									}
									
									{delivery_methods.pharm && <Form.Field error={valids && !valids.delivery}>
										<Checkbox
											radio
											label={<label>
												<span className='font-semibold'>Paper script</span> sent to your local pharmacy for pickup<br/>
												<small style={{display:'block',marginTop:'.5em'}}>
													We will send an electronic copy of your paper script to <strong>ONE</strong> nearby partner pharmacy of your choice for you to purchase your medication at.
													</small><small style={{display:'block',marginTop:'.25em'}}>
													Delivery time: <strong>Prescription delivered to the pharmacy in less than 15 minutes</strong><br/>
													Additional handling cost: <strong>$5.00</strong> For processing, printing and sending the script to the pharmacy
												</small>
												</label>}
											name='delivery'
											value='pharm'
											checked={store.delivery === 'pharm'}
											onChange={(e, { value }) => {uf_this.handleInputChange(null,{name:'delivery',value})} }
										/>
									</Form.Field>}
								</Section.Content>
							</Section>
					}}
					

			</UniFormMed>;
}
 
export default CollectionMethod;