import React from 'react';
import { cls as _cls }  from '../../NUI/utils'

const Tiles = ({list, multi, circle, cls, border}) => {
    return  <div className={`pt-[65px] tiles ${multi && 'multi'} grid grid-cols-1 md:grid-cols-3 gap-6 ${cls||""}`}>
                {list.map((el, i) => 
                    <div key={i} className={_cls("grid-col-1 text-icosm-secondary p-[36px] flex flex-col justify-center relative min-h-[249px] h-full min-h-[380px]",el?.img ? "bg-cover bg-no-repeat" : el.bg)} style={{ backgroundImage: el?.img ? `url(https://storage.googleapis.com/icosm/2/${el?.img})`: 'none'}}>
                        <h3 className='!mb-0'>{el?.title}</h3>
                        <p className="w-[90%] grow flex justify-center flex-col text-[1.188rem] leading-[1.438rem]">{el?.desc}</p>
                        {circle && <div style={{ backgroundImage: el?.circle_img ? `url(https://storage.googleapis.com/icosm/2/${el?.circle_img})`: 'none'}} className="bg-icosm-primary absolute -bottom-[23px] -right-4 w-[109px] h-[109px] rounded-full bg-[84px_auto] bg-center bg-no-repeat"></div>}
                    </div>
                )}
            </div>
}

export default Tiles;
