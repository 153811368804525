import React, { PureComponent } from 'react';
import {
		Popup,
		Icon,
	} from 'semantic-ui-react'

import { ObjectInspector, chromeLight } from 'react-inspector';

const ReqTm = ({row, index}) => (
			<Popup
						trigger={<Icon name='hourglass outline' />}
						position='left center'
						on='click'
						// style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll', maxHeight:'90vh'}}
						style={{ minWidth: 400, overflow: 'scroll', }}
				>
					<ul>
						{row?.meta?.req_tms?.as?.map( (a, i) => <li key={i}>
								{a.a} &nbsp; :: &nbsp; <b style={a.l>1000 && { color: 'red', }||{}}>{a.l?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</b>  
								<span style={{float:'right'}}> {a.s?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")} - {a.e?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")} </span>
							</li> )}
					</ul>
				</Popup>
);

export default ReqTm
