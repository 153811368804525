import moment from "moment";

export const UI_DATETIME_FMT = "D/M/YY HH:mm:ss";
export const UI_DATETIME_TZ = "Australia/Melbourne";

export function from_melb_ui_tm(melb_time) {
    return moment.tz(melb_time, UI_DATETIME_FMT, 'Australia/Melbourne').local();
}

export function create_date_interval_array(start, end=moment(), interval='month', format=undefined){
    const interval_arr = []
    while (moment(start) < moment(end)){
        interval_arr.push(moment(start).format(format))
        start = moment(start).add(1, interval)
    }
    return interval_arr
}

export function stateToTimezone(state) {
	switch(state) {
		case 'QLD':
			return 'Australia/Brisbane';
		case 'SA':
			return 'Australia/Adelaide';
		case 'NT':
			return 'Australia/Darwin';
		case 'WA':
			return 'Australia/Perth';
		default:
			return 'Australia/Melbourne';
	}
}