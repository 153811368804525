import React from 'react';

export default function IconMed(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.width}
			height={props.height}
			style={{ marginRight: props.marginRight }}
			viewBox="0 0 22 22"
			className='flex-shrink-0'
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g fill="currentColor" fillRule="nonzero">
					<path d="M10.36 13.581h-2.1v-2.1a.63.63 0 00-1.259 0v2.1h-2.1a.63.63 0 000 1.259h2.1v2.1a.63.63 0 101.259 0v-2.1h2.1a.63.63 0 000-1.259z"></path>
					<path d="M20.915 12.917l-.217-.217a2.781 2.781 0 00-3.851-.074c-.075.07.118-.122-1.875 1.872V8.455a1.891 1.891 0 00-1.9-1.774h-.782V5.25h.415a1.284 1.284 0 001.264-1.239V1.574A1.345 1.345 0 0012.701.255H2.553a1.345 1.345 0 00-1.264 1.319v2.437c.024.684.58 1.229 1.264 1.239h.331v1.427h-.66A1.98 1.98 0 00.24 8.455v11.442a2.053 2.053 0 001.984 1.847h10.843c.277-.002.55-.066.8-.187a2.78 2.78 0 002.965-.627l4.084-4.084a2.782 2.782 0 000-3.929h-.001zm-3.256.673a1.521 1.521 0 012.149 0l.217.217c.569.57.595 1.485.059 2.086-.046.052.118-.115-1.609 1.612l-2.366-2.366 1.55-1.549zm-9.4-9.6V1.514h1.544V3.99H8.259zm-2.907 0V1.514h1.645V3.99H5.352zm7.356-2.4v2.388c-.547.046-1.096.05-1.643.012V1.514h1.593c.022.02.039.045.05.073v.003zM2.548 3.978V1.587a.21.21 0 01.05-.073H4.09V3.99a9.657 9.657 0 01-1.542-.012zM4.143 5.25h6.881v1.427H4.143V5.25zM1.499 19.897V8.455c0-.185.368-.515.729-.515h10.839a.67.67 0 01.646.515v7.3l-1.027 1.027a2.782 2.782 0 00-.2 3.7H2.224a.844.844 0 01-.729-.588l.004.003zm14.442.143a1.519 1.519 0 01-2.149 0l-.217-.217a1.519 1.519 0 010-2.149l1.643-1.643 2.366 2.366-1.643 1.643z"></path>
				</g>
			</g>
		</svg>
	);
}