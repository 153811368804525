import React, { useEffect, useState, useRef } from 'react';
import { cls } from './utils';

const Slider = ({ data, interval = 5000, auto = false, clsn, cont, type, img_path, img_cls}) => {
    const [slides, setSlides] = useState(data);
    const slider = useRef(null);
    const [active, setActive] = useState(0);
    const arrows = type === 'icosm';
    const ic_cont = useRef(null);
    const [contWidth, setWidth] = useState("100%");
    
    useEffect(() => {
        if (ic_cont.current) { setWidth(ic_cont.current.scrollWidth + "px"); }
    }, []);

    useEffect(() => {
        if (auto) {
            const upd = setInterval(() => {
                setSlides(slides => slides.slice(1).concat(slides[0]))
            }, interval);
            return () => clearInterval(upd);
        }
    }, [])

    const handleScroll = (i) => {
        const cont = slider?.current;
        const scroll = slider?.current.scrollWidth / slides.length;
        const curr_ind = (i === "left" && active-1) || (i === "right" && active+1)
        cont.scrollTo(curr_ind * scroll, 0);
        setActive(curr_ind);
    }
    const arrow_left = <img id="left" onClick={(e) => handleScroll(e?.target?.id)} className={cls(active < 1 && "pointer-events-none opacity-50", "w-[24px] md:w-[28px] cursor-pointer absolute left-1 bottom-12 md:bottom-auto top-1/2")} src="https://storage.googleapis.com/icosm/2/slider-icon.png"/>
    const arrow_right = <img id="right" onClick={(e) => handleScroll(e?.target?.id)} className={cls((active === slides?.length -1) && "pointer-events-none opacity-50", "rotate-180 w-[24px] md:w-[28px] cursor-pointer absolute right-1 bottom-12 md:bottom-auto top-1/2")} src="https://storage.googleapis.com/icosm/2/slider-icon.png"/>
                                                            
    return (<>
        {type === "icosm_auto" ? 
                <div ref={ic_cont} className={cls("slider-scroll h-full over-x-scroll gap-4 md:gap-12")}
                    style={{width: `${contWidth}`, animationPlayState: "running"}}
                >
                {slides.map((el, i) => { 
                    return  <a key={i} className={cls(clsn, 'm-auto')} target="_blank" href={el.link}>
                                <img alt={el.name} src={el.img} className={cls(img_cls||"h-auto")}/>
                            </a>})}
                </div>
            : 
            <>                   
                {<div className={cls("slider-container md:flex items-top", !auto && "flex h-full overflow-x-scroll scroll-smooth transition-[scroll] ease-in-out gap-2", cont)} ref={slider}>
                    {slides
                        .map((el, i) =>
                        type === "icosm_app" && <div key={i} className="relative w-full md:w-[700px] md:h-[510px] shrink-0">
                                <img src={`https://storage.googleapis.com/icosm/2/${el.img}`} className="h-auto md:h-[600px] mx-auto"/>
                                <div className="absolute bottom-4 md:bottom-[40px] w-full px-2">
                                    <div className="relative flex justify-between items-center bg-icosm-tertiary text-icosm-secondary-50 px-5 pt-4 pb-5 w-full md:w-[633px] mx-auto h-fit shrink-0 rounded-[36px]">
                                        {arrow_left}
                                        <div className='px-4 w-full text-center'>
                                            <h4 className="!text-icosm-secondary">{el?.title}</h4>
                                            <p>{el?.desc}</p>
                                        </div>
                                        {arrow_right}
                                    </div>
                                </div>
                            </div>
                            ||  type === "icosm" && 
                                <div key={i} className="relative flex flex-col text-[20px] leading-[24px] shrink-0 max-w-full md:max-w-[280px]">
                                    <div className="rounded-[45px] w-full h-[188px] mb-2 bg-[center_30%] bg-no-repeat" style={{backgroundImage: `url(${el?.img})`, backgroundSize: 'cover'}}></div>
                                    <p className="pt-2 !mb-2 !font-semibold text-[20px] leading-[24px]">{el.title}</p>
                                    {el?.sub && <p className="!mb-2 !font-semibold text-[14px] leading-[24px]">{el?.sub}</p>}
                                    <p className="py-4 text-[16px] leading-[24px]">{el?.desc}</p>
                                </div>
                            || type === 'reviews' &&
                                <div key={i} className="relative flex flex-col rounded-md shadow p-6 items-center justify-between w-full md:w-1/3 bg-white shrink-0 h-full grow">
                                    <div className="flex gap-1">{Array(5).fill().map((el, i) => <svg key={i} width="16" height="16" viewBox="0 0 24 24"><use href="#star" fill="#3C96FB"></use></svg>)}</div>
                                    <div className="relative py-2 px-6">
                                        <img src={`${img_path}/quotation.svg`} className="absolute left-0 top-4 h-[0.69rem]" />
                                        <p className="text-center text-is-black-50 mt-4">{el.text}</p>
                                        <img src={`${img_path}/quotation.svg`} className="absolute right-0 bottom-4 rotate-180 h-[0.69rem]" />
                                    </div>
                                    <p className="text-sm text-right my-4 mt-auto self-end text-is-black-50">- {el.name}</p>
                                </div>
                            ||  <a key={i} className={cls(clsn, 'm-auto cursor-pointer')} target="_blank" href={el.link}>
                                    <img alt={el.name} src={el.img} className={cls(img_cls||"h-auto")}/>
                                </a>

                        )}
                </div>}

                {!auto && !arrows && !type==="icosm_app" && <div className="flex justify-center gap-2 w-full mt-4">
                    {Array(data.length).fill().map((el, i) => <span key={i} onClick={() => handleScroll(i)} className={cls("h-4 w-4 cursor-pointer rounded-full", active == i ? "bg-is-blue" : "bg-is-gray-25")}></span>)}
                </div>}

                {arrows && <div className="flex justify-center gap-2 w-full mt-4 md:hidden">
                    {arrow_left}
                    {arrow_right}
                </div>}
            </>
        }
    </>);
}

export default Slider;
