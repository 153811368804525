import React, { useRef } from 'react';

const DEBUG = true

const UploadButton = ({onFile, icon='file', label='Upload File', accept=null, multiple=false, color='green'}) => {
	const fileRef = useRef(null)
	return <label className={`ui ${color} button`} style={{
			whiteSpace:'nowrap',
			display:'flex',
			justifyContent:'center'
		}}>
		<input ref={fileRef} type="file" accept={accept} multiple={multiple} onChange={async e=>{

				try {
					onFile && onFile(Array.from(fileRef.current.files))
					setTimeout(_=>{
						fileRef.current.value=''
					},500)

				} catch (e) {
					DEBUG && console.log("error",e);
					
				}

			}
		}
		style={{
			width:'0.1px',
			height:'0.1px',
			opacity:0,
			overflow:'hidden',
			position:'absolute',
			zIndex:-1
		}}
		/>
		
			<i className={`ui ${icon} icon`}></i>
			{label}
		</label>
};

export default UploadButton
