import React, { PureComponent } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Confirm,
		TextArea,
		Form
	} from 'semantic-ui-react'

import UserFlag from 'xAppLib/Users/UserFlag'

const FieldUserFlag = ({row, index, onUpdate}) => (
		<UserFlag 
					notes={row.notes} 
					uid={row.pts_uid || row.uid}
					ui={row.user_iding || row.iding}
					email={row.spd_data?.email || row.email}
					onUpdate={data=>onUpdate({...row,...data})}
					/>
);

export default FieldUserFlag
