import { toast } from 'react-toastify';

export const ToastType = {
	SUCCESS: 'success',
	ERROR: 'error',
	INFO: 'info',
	WARNING: 'warning'
};

export const toastMessage = (msg, type = ToastType.SUCCESS, params = {}) => {
	toast[type](msg, {
		containerId: 'default',
		position: 'bottom-center',
		hideProgressBar: true,
		draggable: false,
		...params
	});
};
