import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import patho_model from 'models/patho_model'
import med_model from 'models/med_model'
import { Grid,List, Input, Icon, Button, Table, Container, Segment, Header, Form, Checkbox, Card, Image, Label, Loader , Dimmer, Divider, Modal } from 'semantic-ui-react'
import ObjectEditor from 'xAppLib/UIelems/ObjectEditor'
import { LevelsView } from './tests/Levels'
import { BinaryView } from './tests/Binary'
import { CulturesView } from './tests/Cultures'
import { FormulaView } from './tests/Formula'
import { useToggleState } from 'xAppLib/Hooks';

import API_service from 'xAppLib/providers/API_service'

const controls = [BinaryView, LevelsView, CulturesView, FormulaView]

const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EditorItem = ({children}) => (
	<Grid.Row columns={1} >
			<Grid.Column>
				<Segment>
					{children}
				</Segment>
			</Grid.Column>
	</Grid.Row>
)

export const latestExpressPathParamsWithValue = async (value, med_db_data) => {
	try {
		const tmp = { ...med_db_data, items: med_db_data.items?.map(i => i.key) }
		const { tests } = await patho_model.request(tmp)
		const new_defs = tests.map(t => t.definition).flat()
		const newV = value.map(v => {
			const def = new_defs.find(d => d.id == v.id)
			return { ...v, ...def, result: v.result }
		})
		return newV
	} catch (e) {
		console.log('Error in latestExpressPathParamsWithValue', e)
		return value
	}
}

const PathoResultsView = ({value = null, med_db_data, extra, sid }) => {
	
	const [els,setEls] = useState([])
	const [v,setV] = useState(null)
	const patho_ref = useRef(null);

	const tmp = []
	
	const onNotify = (i,el) => {
		
		setEls(els=>{
			const update = [...els]
			update[i] = el
			return update
		})
	}
	
	useEffect(async _=>{
		if (med_db_data) {
			setV(await latestExpressPathParamsWithValue(value, med_db_data))
		}
	},[value,med_db_data])

	const [srcMed, setSrcMed] = useState(null);
	const src_mid = extra?.form_data?.src_mid || med_db_data?.xtra?.fu_mid || (med_db_data?.m === '-N0fD37Bdu8fO90i2jTo' && 'DocConsWL');
	useEffect(() => {
		if (src_mid) {
			med_model.load_med(src_mid).then(setSrcMed);
		}
	}, [src_mid]);

	const takeScreenshot = async () => {
		if (!patho_ref.current) return;

		const ref = patho_ref.current;

		let page_height;

		const html2canvas = (await import('html2canvas')).default;

		const screenshot = (await html2canvas(ref, {
			scale: 2, // removes the grey background
			onclone: async (_, clone) => {
				// The Width of the pdf content
				clone.style.width = '511px';

				// Make sure we only take a screenshot of the pathology form and exclude things like "Doctor consultation"
				Object.values(clone.children).forEach((el) => {
					if (el.nodeName !== 'FORM') {
						clone.removeChild(el);
					}
				});

				clone.querySelectorAll('.label').forEach((label) => {
					label.style.lineHeight = '0px';
					label.style.fontSize = '14px';
					label.style.marginTop = '20px';
					label.style.padding = '.5px 10px';
					label.querySelectorAll('*').forEach((el) => {
						el.style.marginBottom = '10px'
					})
				});

				clone.querySelectorAll('.ui.card').forEach((card) => {
					// Force a border
					card.style.border = '2px solid rgba(34, 36, 38, 0.1)';
				});				

				if (app.settings.is_ionic) {
					// Fix overflow of small headers on mobile devices
					clone.querySelectorAll('.ui.small.header').forEach((header) => {
						header.style.marginBottom = '50px'
					});				
				}
				
				page_height = clone.clientHeight;
			}
		})).toDataURL();

		handleScreenshot?.(screenshot, page_height);
	};

	const [screenshot, setScreenshot] = useState();
	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(true);
	const [isLoading, turnIsLoadingOn, turnIsLoadingOff] = useToggleState();

	const handleScreenshot = (src, page_height) => {
		setScreenshot({ src, page_height });
	};

	const handleCloseModal = () => {
		setScreenshot(undefined);
		setEmail('');
	}

	const handleOnEmailChange = (_, { value }) => {
		setEmail(value);
		const error = !email_regex.test(value);
		if (validEmail !== error) {
			setValidEmail(error)
		}
	}

	const handleSendEmail = async () => {
		turnIsLoadingOn();
		await API_service.load_data('send_pathology_email', { ...screenshot, to: email, sid: sid }).catch(console.error);
		turnIsLoadingOff();
		handleCloseModal();
	}

	
	const show_doc = els.filter(Boolean).filter(el=>el.danger||el.warning).length > 0
	if (!v) 
		return <Segment basic>
		      <Dimmer active inverted>
		        <Loader size='mini'>Loading</Loader>
		      </Dimmer>
	      		<div style={{height:100}}></div>
	    	</Segment>

	return (
		<div ref={patho_ref} className="mt-4">

			<ObjectEditor controls={controls} changeStructure={false} value={v} onNotify={onNotify} forceRefresh 
				shouldRenderItem={v=>!(v.result === null || v.result === undefined)}
				EditorItem={EditorItem}
				divided={'none'}
				extra={extra}
				/>
			
			{(show_doc || srcMed) && <>
				<Divider/>
				<Segment
						basic
						as={Link}
						to={`/med/${srcMed?.m || 'DocConsCov'}`}
						style={{paddingLeft:0,paddingRight:0}}
					>
					<Header>Doctor Consultation</Header>
						<p className='text grey'>
							You can request a consultation with an InstantScripts doctor to discuss your results.
							Our doctors have a broad range of knowledge and skills to treat a wide range of health conditions.
						</p>
					<Button  color="green">
						{/doccons/i.test(srcMed?.l) ? srcMed.name : 'Request Doctor Consultation'}
					</Button>

				</Segment>
			</>}

			<Divider/>
			<Button color="blue" onClick={ _ => takeScreenshot()} style={{float:'right'}} >Share via email</Button>
			<br/>
			<br/>

			{screenshot && (
				<Modal open={true} onClose={handleCloseModal} size="small" closeIcon centered={true} style={{ top: '50%', transform: 'translateY(-50%)' }}>
					<Modal.Header as="h3" content="Email your results" />

					<Modal.Content>
						<Input className="login-email" fluid icon="envelope" iconPosition="left" placeholder="Email" value={email} onChange={handleOnEmailChange}  />
						<p className='text-sm mt-2'>Please make sure the email is correct, we'll send the copy of your results to that email.</p>
					</Modal.Content>

					<Modal.Actions>
					<Button color="red" onClick={handleCloseModal}>
							<Icon name="remove" /> Cancel
						</Button>
						<Button color="green" onClick={handleSendEmail} disabled={validEmail} loading={isLoading}>
							<Icon name="checkmark" /> Send
						</Button>
					</Modal.Actions>
				</Modal>
			)}

		</div>
	)
}
	


export default PathoResultsView;
