import React, { Component } from 'react';

const CmgTermsAndConditions = () => (
	<div className="terms">
		<h1><b>Terms of Use</b></h1>
		<p>We, Medigrowth Trading Australia Pty Ltd (ABN 16 629 860 304) (Producer or Haiku.Health), are responsible for the Haiku.Health platform, which is accessible via www.haiku.health (Platform).</p>

		<p className="font-bold">The use of the Platform by any user (you) is subject to these Terms of Use (which may be varied at any time without notice to you). By using the Platform, you agree to these 
		Terms of Use that govern your access to and use of the Platform. Your access to the Platform and / or the goods and services provided via the Platform may be suspended or terminated at any time, without notice to you.</p>

		<p className="font-bold">Doctors you consult with via the Platform are independent of the Producer and InstantScripts.  If you consult with a doctor via the Platform, the doctor is solely responsible for the 
		healthcare services they provide to you, including compliance with standards of care, record keeping and other professional obligations.  Neither the Producer nor InstantScripts is a party to the health professional-patient 
		relationship between you and a doctor, or is in any way involved in, or liable for, any services provided by a doctor to you.</p>

		<h3>1. WHO WE ARE</h3>
		<p>Producer is an Australian owned fully licenced medicinal cannabis biotech.  Producer's operations include distribution, product development, R&D & clinical trial initiatives. The Haiku.Health Platform has been established 
			to connect patients with doctors and improve quality of life with seamless, professional digital healthcare. </p>
		
		<h3>2. PLATFORM</h3>

		<h5>2.1 OVERVIEW</h5>
		<p>The Platform is a secure online digital healthcare platform that provides affordable and convenient health services to Australians, including by:</p>
		<ul>
			<li>facilitating telehealth and video consultations with independent Australian-registered doctors who are authorised to prescribe medicinal cannabis products (Partner Doctors); and</li>
			<li>where clinically appropriate and after a digital or telehealth consultation with a Partner Doctor, facilitating access to online prescriptions, including the delivery of certain medications.</li>
		</ul>

		<h5>2.2 COMMERCIAL ARRANGEMENT WITH INSTANTSCRIPTS</h5>
		<p>The Platform is hosted and operated by InstantScripts Pty Ltd (ACN 629 120 234) (InstantScripts).</p>
		<p>There is a commercial arrangement between the Producer and InstantScripts in relation to the operation and maintenance of the Platform.  If you consult with a Partner Doctor via the Platform, the 
			Producer may receive a referral or commission payment in relation to that consultation from InstantScripts under this commercial arrangement.</p>

		<h3>3. YOUR USE OF THE PLATFORM</h3>
		
		<h5>3.1 ACCEPTANCE OF THESE TERMS OF USE</h5>
		<p>Your use of the Platform is subject to these Terms of Use. These Terms of Use contain important information, including warranty disclaimers and limitations of liability. Please read these Terms of Use carefully before 
			accessing or using the Platform.</p>

		<p>Your use of the Platform in any way, including, without limitation, browsing the Platform, using any information contained on the Platform, and/or purchasing any good or service on the Platform, constitutes acceptance 
			of these Terms of Use, including those additional terms and conditions and policies referenced herein and/or available by hyperlink.  If you do not agree to all of the terms of these Terms of Use, then you may 
			not access or use the Platform.</p>

		<p>The Producer reserves the right to update, vary or replace any part of these Terms of Use at any time by publishing a revised version of the Terms of Use on the Platform.  
			Any new features or tools which are added to the Platform shall also be subject to the Terms of Use.  You can review the most current version of the Terms of Use at any time on this page. It is your responsibility to check 
			this page periodically for changes. Your continued use of or access to the Platform following the posting of any changes constitutes acceptance of those changes.</p>

		<h5>3.2 APPROPRIATE USE OF THE PLATFORM</h5>
		<p>You must only use the Platform, including any third party platforms contained therein, for purposes described in these Terms of Use. You must not use or attempt to use the Platform:</p>

		<ul>
			<li>for any unlawful purpose or in any way that may breach any law or regulation;</li>
			<li>to solicit others to perform or participate in unlawful acts;</li>
			<li>for any commercial purpose;</li>
			<li>to submit, provide or upload false or misleading information or information that may be false or misleading;</li>
			<li>to corrupt, damage or interfere with the Platform, including any third party platforms contained therein, or any third party data, software, hardware, website or information technology systems;</li>
			<li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
			<li>to send, provide or upload any offensive, inflammatory, defamatory, fraudulent or otherwise unlawful information or information that infringes the rights of the Producer, 
				InstantScripts or any Partner Doctor's right, or third party rights;</li>
			<li>to adversely affect the reputation or public image of the Producer, InstantScripts or any Partner Doctor;</li>
			<li>to cause annoyance or inconvenience to the Producer, InstantScripts, any Partner Doctor or any third party; or </li>
			<li>to obtain any good or service available on the Platform for someone other than you</li>
		</ul>

		<p>You acknowledge that your use of the Platform (including any third party platforms contained therein) and, to the extent that you have a personal account on the Platform (Personal Account), your Personal Account, 
			may be terminated at any time if the Producer or InstantScripts reasonably believes that you have breached these Terms of Use. </p>

		<p>Without limiting the above, the Producer and InstantScripts reserves the right, in its sole discretion, to do any of the following without notice to you:</p>

		<ul>
			<li>suspend or block your access to the Platform or part of the Platform;</li>
			<li>limit or prohibit you from using your Personal Account;</li>
			<li>remove any material you send, provide or upload to the Platform; or</li>
			<li>refuse to provide or supply you with any good or service available on the Platform.</li>
		</ul>

		<h5>3.3 INDEMNITY</h5>

		<p>You agree to indemnify, defend and hold harmless the Producer, InstantScripts and each of their respective related entities, directors, officers, partners, employees, contractors, affiliates, suppliers, service providers 
			and licensors (including the Partner Doctors) from any claim or demand, including reasonable legal fees, made by any third-party due to or arising out of:</p>

		<ul>
			<li>your use of the Platform;</li>
			<li>your breach of these Terms of Use or the documents they incorporate by reference; or</li>
			<li>your violation of any law or the rights of a third-party.</li>
		</ul>

		<h3>4. INFORMATION YOU NEED TO KNOW</h3>

		<h5>4.1 EMERGENCIES</h5>
		<p>The goods and services available on the Platform are not suitable for use in emergency circumstances.  If you are in an emergency situation or need urgent and immediate assistance, please contact 000 or your local hospital emergency department.</p>

		<h5>4.2 DISCLAIMER</h5>
		<p>Unless provided directly to you by a Partner Doctor, the information and content on the Platform is general and is provided for information purposes only.  It:</p>
		<ul>
			<li>is not, and is not intended in any way to be, or a substitute for, advice, such as professional medical advice including professional medical advice provided by Partner Doctors during a telehealth consultation facilitated via the Platform; and</li>
			<li>should not replace a qualified health practitioner's independent judgment about the appropriateness or risks of a treatment or use of a particular product or service.</li>
		</ul>

		<p>You should seek the professional advice of a qualified health practitioner with any questions you have regarding any medical conditions and/or the suitability of any products or services, 
			including any products or services offered through the Platform.  Never disregard professional advice given by a qualified health practitioner or delay in seeking it because of the general information you have read on the Platform.  
			You and your health practitioners (including any Partner Doctor you consult with) are exclusively responsible for any patient management and care decisions made with respect to using any product or service offered on the Platform, and any consequences of such decisions.</p>

		<p>Partner Doctors are independent of the Producer and InstantScripts.  If you consult with a Partner Doctor, the Partner Doctor is solely responsible for the healthcare services they provide to you, including compliance with standards of care, 
			record keeping and other professional obligations.  Neither the Producer nor InstantScripts is a party to the health professional-patient relationship between you and a Partner Doctor, or is in any way involved in, or liable for, any services provided by a Partner Doctor to you.</p>

		<p>In some cases, the content on the Platform may incorporate or summarise views, guidelines or recommendations of third parties.  Such content does not necessarily reflect the views of the Producer or InstantScripts, nor does it necessarily indicate a commitment by the 
			Producer or InstantScripts to a particular course of action.  Your reliance on any information provided on the Platform is solely at your own risk.</p>

		<p>Subject to the consumer guarantees provided for in consumer protection legislation (including the Australian Consumer Law set out in Schedule 2 of the Competition and Consumer Act 2010 (Cth) (Australian Consumer Law)), the 
			Producer and InstantScripts do not give any express or implied warranties, or make any representations in relation to the Platform.  In particular, while reasonable care is taken in its preparation, the Producer and InstantScripts does not guarantee or warrant the accuracy, 
			reliability, completeness or currency of the information on the Platform or its usefulness in achieving any purpose.  You accept that content on the Platform may include technical inaccuracies and typographical errors.  Information on the Platform should not be used without 
			validating that information from appropriate sources and obtaining professional advice where it is prudent to do so.  You should make and rely upon your own assessments and enquires to verify the accuracy of the information provided.
</p>

		<p>In addition, subject to the consumer guarantees provided for in consumer protection legislation (including the Australian Consumer Law), neither the Producer nor InstantScripts warrants that the Platform is free from any computer viruses or other defects or that your access to the 
			Platform will be continuous or uninterrupted.  The Producer and InstantScripts accepts no liability arising from your access to the Platform. </p>

		<h5>4.3 LIMITATION OF LIABILITY</h5>
		<p>The Platform comes with non-excludable warranties and guarantees under consumer protection legislation, such as that the Platform will be provided with due care and skill and be reasonably fit for its usual purpose.
</p>

		<p>Subject to the above and to the extent permissible by law, the Producer and InstantScripts and their respective directors, officers, partners, employees, contractors, affiliates, suppliers, service providers and licensors (including the Partner Doctors): </p>

		<ul>
			<li>accept no liability arising from the content on the Platform, or on websites linked from the Platform, being incorrect, incomplete or misleading; and </li>
			<li>will not be liable for any loss, damage, cost or expense incurred or arising by reason of any person relying on the content that appears on the Platform.  </li>
		</ul>

		<h3>5. YOUR PERSONAL ACCOUNT</h3>

		<h5>5.1 ELIGIBILITY FOR A PERSONAL ACCOUNT</h5>
		<p>To be eligible to create a Personal Account and access that Personal Account, you must be, as a minimum:</p>
		<ul>
			<li>residing in Australia (ie. have a current Australian address); and</li>
			<li>planning to access the goods and services provided on the Platform within Australia.</li>
		</ul>

		<p>InstantScripts has the sole right to determine your eligibility to create and maintain a Personal Account, and to access the Platform or any part(s) of the Platform. </p>
		<p>You must provide true, accurate and complete information when registering for and using your Personal Account and must ensure that such information is current and up to date at all times.  When obtaining product or services through the Platform, you must not omit any information that is 
			relevant to the products or services you are obtaining. </p>

		<h5>5.2 YOUR RESPONSIBILITY FOR YOUR PERSONAL ACCOUNT</h5>

		<p>If you choose to create a Personal Account, you are entirely responsible for your log in details and password and must not share these with any third party.  From time to time, you may be required to re-authenticate yourself or provide information in relation to your Personal Account or your security credentials. </p>

		<p>The Producer and InstantScripts are entitled to assume that you are the user whenever your security credentials are used to access a Personal Account.  You must notify InstantScripts and Haiku.Health’s support immediately of any known or suspected unauthorised use(s) of your Personal Account, or any known 
			or suspected breach of security, including but not limited to unauthorised access to the Platform or loss, theft or unauthorised disclosure of your password.  You are responsible for all usage or activity on the Platform. </p>

		<h5>5.3 CLOSING YOUR PERSONAL ACCOUNT</h5>
		<p>If you would like to close your Personal Account, please access the “My Profile” section of the Platform and under “Account Closure”, select “Request Closure”. If you request that your Personal Account be closed, the details you have provided and all information relating to your Personal Account will be 
			archived and stored securely by InstantScripts for up to 7 years. </p>

		<h3>6. GOODS AND SERVICES ON THE PLATFORM</h3>
		
		<h5>6.1 Consultations</h5>
		<p>Once you have created a Personal Account, you must complete a short digital consultation to determine whether you are eligible for a telehealth consultation with a Partner Doctor.  While eligibility requirements may vary, you must (at the very least) be able to demonstrate that you are suffering from a chronic condition 
			and have attempted various treatment options to be eligible for a telehealth consultation with a Partner Doctor. </p>
		<p>If you are deemed to be eligible for a telehealth consultation with a Partner Doctor, you will able to request a telehealth consultation where a Partner Doctor will call you at their next available time. </p>
		<p>You acknowledge that a Partner Doctor may, in their absolute discretion, refuse to prescribe medication to you, issue you a prescription and / or provide any other services or referrals requested by you.  Any such refusal by a Partner Doctor is not grounds for a refund.
</p>
		<p>The Producer and InstantScripts do not guarantee that a telehealth consultation with a Partner Doctor is the appropriate course of treatment for your particular healthcare condition. The Partner Doctor that you consult with will be solely responsible for determining:</p>

		<ul>
			<li>whether or not a telehealth consultation is appropriate for you;</li>
			<li>whether or not to provide the medical and/or health services you request; and</li>
			<li>what health services (if any) are required.</li>
		</ul>

		<p>You should contact your regular doctor immediately if your medical condition changes or your symptoms worsen. If you require urgent care, you should contact your local emergency services immediately. You agree that, if you suffer from any noticeable side-effects from any medication or treatment you receive, you 
			will either consult your regular doctor or contact us to facilitate a further consultation with a Partner Doctor.</p>

		<p>There is no guarantee that you will be able to consult with a particular Partner Doctor, or that you will not receive a consultation with a particular Partner Doctor.
</p>

		<p>You agree you will not record phone consultations unless you receive explicit written consent from the Partner Doctor prior to commencing any such recording.
</p>

		<h5>6.2 Collection and delivery of medication</h5>
		
		<p>The Producer and InstantScripts are not pharmacies and do not dispense any medication.
</p>
		<p>If you a Partner Doctor considers it is clinically appropriate to prescribe medication to you following a telehealth consultation, you may elect to:</p>

		<ul>
			<li>have an electronic prescription sent directly to you by email and / or SMS; or</li>
			<li>depending on the medication prescribed to you, have the electronic prescription sent to a pharmacy and the medication delivered to your nominated shipping address (Shipping Address).</li>
		</ul>

		<p>If you elect to have the electronic prescription sent directly to you by email and / or SMS, you may attend a pharmacy of your choice to collect the medication that has been prescribed to you.  Neither of the Producer nor InstantScripts will be involved, in any way, with the collection by you of such medication.</p>
		<p>If you elect to have the electronic prescription sent to a pharmacy and the prescribed medication delivered to your Shipping Address, then: </p>
		<ul>
			<li>you agree to:
				<ul>
					<li>the applicable pharmacy contacting you for the purpose of confirming details related to your prescription and obtaining all information it requires to dispense the prescribed medication to you; and</li>
					<li>the applicable party responsible for transporting the medication from the pharmacy to your Shipping Address (which may be the Producer or a third party) (Delivery Partner) contacting you to confirm delivery details, including the method of delivery and your Shipping Address; </li>
				</ul>
			</li>
			<li>you will be provided with an estimated delivery timeframe.  You acknowledge that this is an estimate only and based on the assumption that the pharmacy has all the information it requires to be able to dispense the prescribed medication to you and the Delivery Partner has all the information it requires to deliver 
				the prescribed medication to you.  Without limitation, if the pharmacy or Delivery Partner does not have all the information it requires to dispense or deliver the prescribed medication to you (as applicable), then a different delivery time may apply;</li>

			<li>you are responsible for ensuring that you have appropriate arrangements in place to accept delivery of your medication. InstantScripts is not responsible for actions of a Delivery Partner, including:
				<ul>
					<li>the failure of a Delivery Partner to deliver medicines to the correct address;</li>
					<li>the method of delivery used by any Delivery Partner; or</li>
					<li>failure by a Delivery Partner to deliver medicines within any particular timeframe.</li>
				</ul>
			</li>
			<li>if the Shipping Address you provide is incorrect, you may be asked to provide a correct address.  If you are unable to provide a correct address, your order may be cancelled; </li>
			<li>if your order is cancelled after your medication has been dispensed from the pharmacy, you will be charged for the medication and you will not be able to obtain a refund; and </li>
			<li>in the event your medication appears to be damaged, wrongly dispensed or your delivery is delayed, you should contact Haiku.Health’s support through the Platform to seek advice or replacement medication.</li>
		</ul>

		<p>You acknowledge that a pharmacy may, in its absolute discretion, reject your prescription and refuse to dispense medication to you for any reason.  You acknowledge that neither the Producer nor InstantScripts is, 
			in any way, involved in or responsible for the decision to dispense medication to you.</p>

		<h5>6.3 Usage of Medication</h5>
		<p>If a Partner Doctor prescribes any medication, you must carefully read all information provided to you in relation to that medication (including information provided on the product packaging, patient information 
			supplied with the medication, online material provided through the 
			Platform (including by a Partner Doctor or a pharmacy) and the manufacturer’s consumer medical information leaflet).
</p>
		<p>You must follow any advice provided to you by a Partner Doctor you consult with and/or pharmacy in relation to taking any medication prescribed to you.</p>
		<p>If you experience any serious side effects from any medication, you should seek emergency medical care immediately.</p>

		<h3>7. PRICE AND PAYMENT</h3>
		<h5>7.1 Payment</h5>

		<p>All charges and prices as shown listed are in Australian Dollars and are inclusive of GST (if applicable).  Funds will only be debited against your nominated account as authorised in these Terms.</p>
		<p>The amount payable by you for goods and services available on the Platform will be notified to you following your Telehealth Consultation.</p>
		<p>Payment may be made online by credit card, debit card or through a PayPal account.  You are required to provide payment details at the time you request a telehealth consultation on the Platform. </p>
		<p>Following completion of your Telehealth Consultation with a Partner Doctor, the relevant Partner Doctor will instruct InstantScripts to charge you for the goods and services you received. </p>
		<p>If you cancel a telehealth consultation at any time before it commences, you will not be charged for the telehealth consultation. </p>
		<p>For each successful payment, InstantScripts will send you a receipt confirming payment to the email address you have provided.</p>
		<p>InstantScripts may, in its sole discretion, vary the fees and payment terms applying to the goods and services available on the Platform at any time.  Any change to the fees or payment terms will be immediately 
			effective upon the publication of that change on the Platform and will apply to the goods and services requested by you following that change.  If you have booked or paid for goods or services prior to a 
			change in fees or payment terms being published on the Platform, but have not received the goods or services prior to that change taking effect, you will be charged the rate that applied to the applicable goods 
			or services at the time you booked or paid for them.  </p>
		
		<h5>7.2 Third party payers</h5>
		<p>Services provided by Partner Doctors through the Platform are not covered under Medicare Australia, meaning that no Medicare rebate is available and bulk billing is not offered. </p>
		<p>If you are eligible to claim private health insurance benefits for any goods or services you receive, you are responsible for submitting the paid invoice to your insurer to claim any eligible benefits. </p>
	
		<h5>7.3 Refunds</h5>
		<p>Refunds for telehealth consultations are provided at sole discretion of InstantScripts and Partner Doctors.</p>
		<p>Subject to rights that cannot be waived under Australian Consumer Law, the cost of any medication that you choose to have dispensed (and delivered) to you through the Platform can only be refunded when:</p>
		<ul>
			<li>you let InstantScripts or Haiku.Health know you would like to cancel your delivery and the medication has not yet been dispensed by the applicable pharmacy;
</li>
			<li>the pharmacy is unable to dispense your medication; or</li>
			<li>a product or pricing error is identified (and only to the extent that the error has caused you to be charged more than you ought to have been charged).</li>
		</ul>

		<p>Refunds are not permitted by the pharmacy for medication once it has been dispensed.
</p>
		<p>Refunds can only be made back to the payment method or credit card used for the original charge. Refunds may take up to five working days to be processed and appear in your card statement.  
			You are solely responsible for maintaining the accuracy of your details, including any bank account or credit card details to which you would like to receive payments. The Producer and InstantScripts disclaim any and all liability 
			for claims or payments which cannot be processed due to incorrect information provided by you.
</p>

		<h5>7.4 GST</h5>
		<p>In this clause 8.4:</p>
		<ul>
			<li>terms or expressions which have a defined meaning in the A New Tax System (Goods and Services Tax) Act 1999 (Cth) (GST Act) have the same meaning as in the GST Act; and</li>
			<li>any reference to a party includes the representative member of a GST group of which that party is a member.</li>
		</ul>

		<p>Unless otherwise expressly stated, all consideration to be paid or provided under these Terms of Use is expressed exclusive of GST.  If GST is payable on any supply made under these Terms of Use, the 
			recipient must pay to the supplier an additional amount (GST Amount) equal to the GST payable on that supply at the same time as the consideration for the supply is to be paid or provided, except to the extent that:
</p>

		<ul>
			<li>the consideration for the supply is stated to include GST; or</li>
			<li>GST on the supply is reverse charged and payable by the recipient.</li>
		</ul>
		<p>The recipient need not pay the GST Amount until it has received a tax invoice or adjustment note, as the case may be. If an adjustment event arises in relation to a supply made under these Terms, the GST Amount 
			must be adjusted to reflect that adjustment event.  A corresponding payment must be made by the supplier to the recipient or by the recipient to the supplier, as the case may be.</p>

		<p>If these Terms of Use require an amount to be calculated by reference to another amount (Reference Amount) that will be:</p>
		<ul>
			<li>received for a taxable supply; or</li>
			<li>paid for a creditable acquisition,</li>
		</ul>

		<p>then the Reference Amount must be reduced so as to exclude any part of the Reference Amount paid or received on account of GST, as the case may be.</p>
		<p>If these Terms of Use require a party to reimburse or indemnify another party for a cost or expense, the amount of the cost or expense must be reduced by an amount equal to any input tax credit to which the party 
			being reimbursed or indemnified is entitled for that cost or expense.</p>
		
		<h3>8. CONTENT ON THE PLATFORM</h3>

		<h5>8.1 INTELLECTUAL PROPERTY</h5>
		<p>The content on the Platform, including but not limited to text, graphics, logos, photographs and images is the property of the Producer or its content suppliers and is protected by copyright law.  The compilation of 
			all such content is the exclusive property of the Producer and protected by copyright law. </p>
		<p>Except to the extent expressly stated in these Terms of Use, using the Platform does not give you any rights, title, interest or ownership in any intellectual property rights (including copyright) and proprietary 
			rights to any content on the Platform or any trademarks, logos or other proprietary graphics of the Producer or InstantScripts. </p>

		<h5>8.2 YOUR USE OF CONTENT ON THE PLATFORM</h5>
		<p>Subject to your acceptance of these Terms of Use, the Producer authorises you to view, download and/or print a small number of copies of the content on the Platform for your personal, non-commercial use. 
			Otherwise, no content on the Platform may be reproduced, modified, republished, transmitted, displayed, performed, or distributed in any way without the express prior written consent of the Producer or as permitted by law.  
			You may not modify, sell, assign, or transfer the content of the Platform or reproduce, display, distribute or otherwise use the content of the Platform in any way for any public or commercial purpose or otherwise except as 
			expressly permitted in these Terms of Use.  Content on the Platform is subject to change without notice at the discretion of the Producer. </p>
		<p>You are granted a limited, revocable and non-exclusive right to create a hyperlink to the home page of the part of the Platform which is accessible via a website so long as the link does not portray the Producer, InstantScripts or any 
			Partner Doctor or the functions and activities of the Producer or InstantScripts in a false, misleading, derogatory or otherwise offensive manner.  If requested by the Producer or InstantScripts, you must remove any such hyperlink 
			contained on the forum to which you have provided the hyperlink. </p>

		<h3>9. THIRD PARTY CONTENT</h3>

		<h5>9.1 THIRD PARTY LINKS</h5>
		<p>The Platform may include links to websites owned by other parties. These links may be represented in different ways, including as a standalone hyperlink and as content from the website owned by other parties which, if clicked, 
			may direct you to the website owned by other parties. </p>
		
		<p>Neither the Producer nor InstantScripts:</p>
		<ul>
			<li>is responsible for the material contained on these websites or the availability of these websites;</li>
			<li>endorses or is responsible or liable for any content, including advertising or products offered on these websites; or</li>
			<li>is responsible for any damage, offence or loss caused as the result of visiting these websites;</li>
			<li>is liable for any reliance or weight given to third party links when considering whether to purchase a good or service available on the Platform.</li>
		</ul>

		<p>The Producer and InstantScripts recommends that before you visit any website, you check that your computer is running up-to-date virus checking software. </p>
		<p>By accepting these Terms of Use, you agree that you will not breach any right of a third party, including but not limited to contractual, copyright, trademark and privacy rights.</p>

		<h5>9.2 CONTENT ON THIRD PARTY SITES</h5>

		<p>When you use InstantScripts' social media pages, you are using an external website and are bound by the terms and conditions of use of that website. We encourage you to review the terms and conditions of the relevant social media websites.
</p>
		
		<h5>10. YOUR CONTENT</h5>
		<p>Any material you provide, submit or send to, or via, the Platform will be deemed to be non-confidential and non-proprietary, unless it is indicated otherwise.  This includes any data, questions, comments, suggestions, ideas or other 
			information, but does not include your personal (including sensitive or health) information which you provide in order to create or access a Personal Account or to a Partner Doctor in the direct course of obtaining goods and services 
			available on the Platform. The Producer and InstantScripts may use any material provided, submitted or sent to, or via, the Platform which has not been indicated as being confidential or proprietary for any purpose without compensation to you. </p>
		<p>Such material may also be transferred (in an encrypted form) over various networks and/or changed to confirm and adapt to technical requirements of connecting networks or devices.  If you provide any payment information, this information 
			will be tokenised by a third party so that tokens are only ever received when payments occur.  </p>

		<h5>11. PRIVACY</h5>
		<p>Your personal (including sensitive and health) information will be collected by InstantScripts, including if you choose to create a Personal Account, make an order for any goods or services from the Platform or contact Platform host InstantScripts 
			on or via the Platform. <a href="https://app.instantscripts.com.au/privacy" target="_blank">InstantScripts' Privacy Policy</a>, explains how InstantScripts handles your personal (including sensitive and health) information. 
			The Privacy Policy also includes information about how you can:
		</p>
		<ul>
			<li>seek to access and/or correct your personal information InstantScripts holds about you; and</li>
			<li>submit a privacy query or complaint to InstantScripts and how InstantScripts will deal with your complaint.</li>
		</ul>
		<p>By using the Platform, you agree that InstantScripts can use your personal (including sensitive and health) information in accordance with its Privacy Policy.</p>
		<p>In addition to the above, if you provide your explicit consent, InstantScripts will disclose your name and contact details to Producer to allow Producer to contact you and provide information (including marketing and promotional information) 
			to you in relation to products, services and offers Producer thinks may be of interest to you.  If you do not consent to this, you will not receive information from Producer in relation to the Platform or the products provided by Producer. </p>

		<h5>12. COOKIES</h5>

		<p>If your web browser is set up to accept cookies, a cookie may be stored on your hard drive when you visit the Platform. Cookies allow InstantScripts to collect information about your computer, which may include your IP address 
			(a number assigned to your computer when you register with an Internet Service Provider), type of browser, operating system, domain name, and the details of any website which has referred you to the Platform.  InstantScripts uses 
			cookies to track and collect information about which parts of the Platform (including links to other websites) are being visited by you.  Cookies also allow InstantScripts to recognise your computer while you are accessing the Platform.  
			This information is used to maintain the quality of the Platform and to provide tracking and statistics regarding the use of the Platform. </p>
		<p>If you would rather not have this information stored on your computer, you can configure your browser so it does not accept cookies.  However, if you disable cookies you may not be able to access all parts of the Platform.</p>

		<h5>13. GOVERNING LAW</h5>
		<p>These Terms of Use are governed by and must be construed in accordance with the laws of Victoria, Australia. The parties submit to the non-exclusive jurisdiction of the courts of Victoria, Australia and the Commonwealth of Australia in 
			respect of all matters arising out of or relating to these Terms of Use, its performance or subject matter.</p>

		<h5>14. SEVERABILITY</h5>
		<p>Any provision of these Terms of Use which is invalid in any jurisdiction must, in relation to that jurisdiction, be:
</p>

		<ul>
			<li>read down to the minimum extent necessary to achieve its validity, if applicable; and</li>
			<li>severed from these Terms of Use in any other case,</li>
		</ul>
		<p>without invalidating or affecting the remaining provisions of these Terms of Use or the validity of that provision in any other jurisdiction.
</p>

		<h5>15. CONTACT DETAILS</h5>
		<p>Questions about these Terms of Use should be sent to info@haiku.health marked Attention: Regulatory, Subject: Terms and Conditions.  </p>

		<p>The last update to this document was February 2023.</p>

	</div>
);

export default CmgTermsAndConditions


