import React from 'react';
import { Helmet } from "react-helmet";
import { Button, Container } from 'semantic-ui-react';
import IcosmHero from './components/IcosmHero';
import IcosmBanner from './components/IcosmBanner';
import Tiles from './components/Tiles';
import { Link } from 'react-router-dom';

const info = [
	{
		title: "Elevate your knowledge Online",
		desc: "Take advantage of our frequent online webinars to expand your knowledge in the areas that interest you most.",
		bg:'bg-icosm-tertiary',
		circle_img:"icon-laptop.png"
	},
	{
		title: "Expand your clinical skills with live training",
		desc: "Further develop your skills as an aesthetic nurse through our hands-on training events.",
		bg:'bg-icosm-primary',
		circle_img:"icon-mobile.png"
	},
	{
		title: "Gain practical strategies for boosting business growth",
		desc: "Benefit from insights from industry leaders in business growth through our online seminars designed to help you thrive.",
		bg:'bg-icosm-quaternary',
		circle_img:"icon-stairs.png"
	}
]

const info_section2 = ["team-hugel.jpg", "merz-aesthetics.jpg", "teoxane.jpg", "NSS-training.jpg", "hugel.jpg", "allergan-training.jpg", ]

const Training = () => {

	return <div>

		<Helmet>
			<title>Training & Events</title>
		</Helmet>

       <Container>
            <IcosmHero img='2/ic-training.jpg' title="Training tailored to elevate, grow and inspire you!" desc="Your success is our success, and we're here to support your journey in aesthetics with insightful guidance and tailored events for every stage of your growth." link="" order="2" />

			<Tiles list={info} multi circle border cls="min-h-[445px]"/>

			<div className="flex flex-col text-icosm-primary items-center mt-[113px] mb-[49px]">
				<h2 className="mb-[32px]">Get inspired</h2>
				<p className="text-icosm_md md:text-icosm_base mb-[32px]">See some of our previous in person training and events.</p>
				<Button className="cta cta-tertiary icon" as={Link} size="large" type="button" to="/join">Join today</Button>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:mb-[65px]">
                {info_section2.map((el, i) => {
                    return <div key={i} style={{ background: el.length>0 ? `url(https://storage.googleapis.com/icosm/2/${el}) no-repeat center  / cover` : "#000"}} 
					className="bg-cover bg-no-repeat h-[249px] w-full"></div>}
                )}
            </div>

        </Container>

        <IcosmBanner img="2/iStock-1142821566.jpg" light title="Grow today, excel tomorrow." desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." img_cls="bg-[center_80%]"/>
	</div>
}

export default Training;
