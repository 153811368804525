// 
// From https://github.com/KyleAMathews/react-gravatar
// From http://kyleamathews.github.io/react-gravatar/
// Has a problem because using es2015 babel preset
// Had to include to use
// 

import md5 from '../helpers/md5'
import serialize_params from '../helpers/serialize_params'


const gravatar_url = props => {

	const {
			email,
			name,
			size = 50,
			style,
			// md5,
			rating = 'g',
			// default = 'retro',
			className,
			protocol = 'https://',
			retina = false,
		} = props


	const base = `${protocol}www.gravatar.com/avatar/`

	const query = serialize_params({
			s: retina ? size * 2 : size,
			r: rating,
			d: ui_avatar_uri(name, retina ? size * 2 : size),
		})

	// Gravatar service currently trims and lowercases all registered emails
	const formattedEmail = ('' + email).trim().toLowerCase();

	let hash
	if (props.md5) {
		hash = props.md5
	} else if (typeof email === 'string') {
		hash = md5(formattedEmail, {encoding: "binary"})
	} else {
		// console.log('Gravatar image can not be fetched. Either the "email" or "md5" prop must be specified.', email, props)
		return null
		// return (<script />)
	}

	const src = `${base}${hash}?${query}`


	return src
}

export default gravatar_url





function hashCode(str) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + hash //((hash << 5) - hash);
	}
	return hash;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b, prefix = '#') {
  return prefix + componentToHex(parseInt(r)) + componentToHex(parseInt(g)) + componentToHex(parseInt(b));
}

const avatarColors = [ "rgb(63,198,248)", "rgb(65,21,208)", "rgb(175,48,20)", "rgb(167,232,49)", "rgb(251,172,246)", "rgb(246,187,134)", "rgb(121,157,16)", "rgb(52,245,14)", "rgb(174,120,62)",  "rgb(255,136,137)", "rgb(6,150,104)","rgb(244,212,3)", "rgb(251,120,16)", "rgb(154,211,179)", "rgb(32,80,46)", "rgb(17,227,140)", "rgb(42,83,138)", "rgb(179,181,230)", "rgb(49,94,234)", "rgb(254,92,222)", "rgb(157,13,108)"]
const userInitialsBaseURI = "https://ui-avatars.com/api/"

function ui_avatar_uri(user, size) {
	let initials = ["Instant","Scripts"]
	if (typeof user == 'string') {
		const parts = user.split(/\s+/)
		initials = [parts[0]]
		if (parts.length > 1) {
			initials.push(parts[parts.length-1])
		}
	} 
	
	const name = initials.join("+")
	
	const code = hashCode(name)

	const color = avatarColors[(code) % avatarColors.length]
	
	const [,r,g,b] = /rgb\((\d+),(\d+),(\d+)\)/.exec(color)
	
	
	// from TinyColor library
	const brightness = (r * 299 + g * 587 + b * 114) / 1000
	const isDark = brightness < 160 //128
	
	return userInitialsBaseURI
					.concat(name,"/",size,"/",rgbToHex(r,g,b,''),"/",isDark?'ffffff':'333333',"/",initials.length,"/0.5/false/true/true",)

}
