import React from "react";
import { Header, Icon, Popup } from "semantic-ui-react";
import { MedName } from "./QualificationStatusIndicator";

/**
 * Shows an indicator for use in the script list whether a doctor considered the consult
 * appropriate to allow the patient access to app scripts for the next 12 months.
 * @param {boolean=} enrolled - indicates if the doctor allows the patient access to app scripts
 * @param {string=}  message  - reason for not allowing the patient access to app scripts
 * @returns {JSX.Element}
 */
export function AppScriptEnrolmentIndicator({ enrolled, message }) {
	const color = (() => {
		if (typeof enrolled === 'boolean') {
			return enrolled ? 'green' : 'red';
		}
		if (enrolled && typeof enrolled === 'object') {
			if (Object.values(enrolled).every(v => v)) {
				return 'green';
			}
			if (Object.values(enrolled).some(v => v)) {
				return 'orange';
			}
			return 'red';
		}
	})();

	if (!color) {
		return null;
	}

	return (
		<Popup trigger={<Icon name="certificate" color={color} />}
			   wide
			   hoverable
			   content={
					<>
						<Header as={'h4'}>App Script Enrolment</Header>
						{typeof enrolled === 'object'
							? <>{Object.entries(enrolled)
								// show enrolled meds on top (thanks JS for coercing Booleans to numbers!)
								.sort(([, xEnrolled], [, yEnrolled]) => -(xEnrolled - yEnrolled))
								.map(([med, isEnrolled]) => (
									<div key={med}>
										<Icon name={isEnrolled ? 'check' : 'minus'}
											  color={isEnrolled ? 'green' : 'grey'}
										/>{" "}
										<MedName med_id={med} />
									</div>
								))}
							</>
							: (
								<p>
									Consult {enrolled ? 'allows' : 'does not allow'} patient to access text based consults for the next 12 months.
								</p>
							)
						}
						{message && <>
							<p><strong>Reason</strong>: {message}</p>
						</>}
					</>
			   }
		/>
	);
}