import React from 'react';
import { Icon, Button, Label } from 'semantic-ui-react'
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";

export default function CopyField({val, altval, title, label}) {
    const {copyToClipboard, copied} = useCopyToClipboard();

	return <Button as='div' labelPosition='right' size='tiny' style={{marginTop:'.5em'}}
				onClick={e => copyToClipboard(e.shiftKey && altval || val)}
				title={title}
			>
		<Button icon  size='tiny'>
		<Icon name={copied ? 'check' : 'clipboard'} />
		</Button>
		<Label as='a' basic pointing='left'>
			{label || val}
		</Label>
	</Button>
}