import React, { useState, useEffect } from "react";
import script_model from "models/script_model";
import { Form, Accordion, Header } from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";

const AltPharm = (props) => {
	const { med_data, store, script_type, req_type, Section } = props
	const [fields, setFields] = useState(script_model.COMM_PHRM_FIELDS);

	useEffect(() => {
		const fields = script_model.COMM_PHRM_FIELDS.map(f=>{
			if (['dest_pharm_name','dest_pharm_address','dest_pharm_email'].includes(f.name)) {
				f.valid_not_required = store.dest_pharm_curr != "no"
			}
			return f
		})

		setFields(fields);
	}, [med_data, store.dest_pharm_curr]);

	return (
		<UniFormMed
			{...props}
			section="altpharm"
			fields={fields}
			defaultValues={{
				dest_pharm_curr:'yes'
			}}
		>
			{(values, valids, uf_this, fields) => {
				return <Section>
					<Section.Header small>Are you happy for your prescription to be emailed to this pharmacy?</Section.Header>
					<Section.Content >
					<p><small><b>In the event we cannot issue an eScript, this pharmacy will be used as a fallback option to send a paper script to.</b></small></p>
					<Accordion fluid >
						<Accordion.Title active={values['dest_pharm_curr'] == 'yes'} index={0} >
							<Form.Radio
											label="Send to this pharmacy"
											// size='massive'

											name={'dest_pharm_curr'}
											value={'yes'}
											checked={values['dest_pharm_curr'] == 'yes'}
											onChange={()=>uf_this.handleInputChange({target:{name:'dest_pharm_curr', value:'yes'}})}
											error = {valids && !valids['dest_pharm_curr'].toString()}

											key={"form_radio_q_"+'dest_pharm_curr'+"_a_"+'yes'}
										/>
						</Accordion.Title>

						<Accordion.Content active={values['dest_pharm_curr'] == 'yes'} style={{paddingLeft:'2.2em'}}>
							<p>
								<strong>{app.dvc.org.name || ''}</strong><br/>
								{app.dvc.org.address || ''}
							</p>
						</Accordion.Content>


						<Accordion.Title active={values['dest_pharm_curr'] == 'no'} index={1} >
							<Form.Radio
											label={'Send to another pharmacy'}
											size='massive'

											name={'dest_pharm_curr'}
											value={'no'}
											checked={values['dest_pharm_curr'] == 'no'}
											onChange={()=>uf_this.handleInputChange({target:{name:'dest_pharm_curr', value:'no'}})}
											error = {valids && !valids['dest_pharm_curr']?.toString()}

											key={"form_radio_q_"+'dest_pharm_curr'+"_a_"+'no'}
										/>
						</Accordion.Title>

						<Accordion.Content active={values['dest_pharm_curr'] == 'no'} style={{paddingLeft:'2.2em'}}>
							<Form.Field
								label = 'Pharmacy name'
								placeholder = 'pharmacy name'
								control = 'input'
								type = 'text'
								required={!fields.dest_pharm_name?.valid_not_required}
								name = 'dest_pharm_name'
								value = {values['dest_pharm_name'] || ''}
								onChange = {uf_this.handleInputChange}
								error = {valids && valids && !valids.dest_pharm_name}
								disabled = {!!fields.dest_pharm_name?.valid_not_required}
							 />

							<Form.Field
								label = 'Pharmacy address'
								placeholder = 'pharmacy address'
								control = 'input'
								type = 'text'
								required={!fields.dest_pharm_address?.valid_not_required}
								name = 'dest_pharm_address'
								value = {values['dest_pharm_address'] || ''}
								onChange = {uf_this.handleInputChange}
								error = {valids && !valids.dest_pharm_address}
								disabled = {!!fields.dest_pharm_address?.valid_not_required}
							 />

							<Form.Field
								label = 'Pharmacy email'
								placeholder = 'pharmacy email'
								control = 'input'
								type = 'email'
								required={!fields.dest_pharm_email?.valid_not_required}
								name = 'dest_pharm_email'
								value = {values['dest_pharm_email'] || ''}
								onChange = {uf_this.handleInputChange}
								error = {valids && !valids.dest_pharm_email}
								disabled = {!!fields.dest_pharm_email?.valid_not_required}
							 />
						</Accordion.Content>
					</Accordion>
					<br/>
					</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);
};

export default AltPharm;
