import React, { useCallback, useState, useEffect, Profiler } from "react";
import { Button, Menu, Tab, TabPane } from "semantic-ui-react";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";

const BookingFields = ({ type, control, fallback, resetFields, ufThis, setFields }) => {
	const is_enabled =
		!!app.site_status?.booking?.types?.[type]?.enabled;
	const is_hybrid =
		!!app.site_status?.booking?.types?.[type]?.hybrid;

	useEffect(() => {
		if (!is_enabled) {
			return;
		}
		setFields(fields => {
			fields.forEach((field) => {
				if (field.name !== 'appointment') {
					return;
				}
				field.valid_not_required = false;
			});
			return fields;
		});
	}, []);

	const [activeComponent, setActivecomponent] =
		useState(() => is_enabled ? 'appointment' : 'fallback');

	const [bookTabDisabled, setBookTabDisabled] = useState(false);

	const onTabChange = useCallback((_e, { activeIndex }) => {

		const active_component = activeIndex === 0 ? 'appointment' : 'fallback';
		const appointment_required = active_component === 'appointment';

		ufThis.handleInputChange(
			null,
			{
				name: 'appointment',
				value: undefined, valid_not_required: !appointment_required
			}
		)
		resetFields.forEach(
			({ name }) => {
				ufThis.handleInputChange(
					null,
					{ name, value: undefined }
				)
			}
		);
		setFields(fields => {
			fields.forEach((field) => {
				if (field.name === 'appointment') {
					field.valid_not_required = !appointment_required;
				}
				const reset_field = resetFields.find(({ name, required }) => name == field.name && required);
				if (reset_field) {
					field.valid_not_required = appointment_required;
				}
			});
			return fields;
		});
		setActivecomponent(active_component);
	}, [setActivecomponent, ufThis, control.fields]);

	const onInitialAppointmentsEmpty = useCallback(() => {
		if (!is_enabled || !is_hybrid || activeComponent !== 'appointment') {
			return;
		}
		setFields(fields => {
			fields.forEach((field) => {
				if (field.name === 'appointment') {
					field.valid_not_required = true;
				}
				const reset_field = resetFields.find(({ name, required }) => name == field.name && required);
				if (reset_field) {
					field.valid_not_required = false;
				}
			});
			return fields;
		});
		setActivecomponent('fallback');
		setBookTabDisabled(true);
	}, [
		setFields,
		activeComponent,
		setActivecomponent,
		ufThis,
		setBookTabDisabled,
	]);

	const onTabButtonClick = useCallback((event) => {
		const activeIndex = event.target.dataset.tab === 'appointment' ? 0 : 1;
		onTabChange(event, { activeIndex });
	}, [onTabChange, ufThis, control.fields]);

	let control_component = null;
	let fallback_component = null;

	if (is_enabled) {
		const { fields, args } = control;
		const appointment_required = activeComponent === 'appointment';
		control_component = (
			<UniFieldSUI
				fl={{
					...{ ...fields.appointment, valid_not_required: !appointment_required },
					booking_type: type,
					basic: is_hybrid,
					onInitialAppointmentsEmpty,
				}}
				{...{ ...args, valid_not_required: !appointment_required }}
			/>
		);
	}
	if (!is_enabled || is_hybrid) {
		const fallback_required = activeComponent === 'fallback';
		fallback_component = fallback(fallback_required);
	}
	if (is_enabled && is_hybrid) {
		return (
			<div className="pb-4">
				<h3>Consultation time</h3>
				<Tab
					renderActiveOnly={false}
					menu={{ secondary: true, attached: false, }}
					className="bookingfields"
					panes={[
						{
							menuItem: (
								<Menu.Item
									key="appointment-tab"
									fitted
									title={
										bookTabDisabled
											? ('The "Book a time" option is not available right now.'
												+ ' Use the "Next available" option.'
											) : undefined
									}
									disabled={bookTabDisabled}
							>
									<Button
										type="button"
										primary={activeComponent === 'appointment'}
										basic={activeComponent !== 'appointment'}
										onClick={onTabButtonClick}
										data-tab="appointment"
										disabled={bookTabDisabled}
									>
										Book a time
									</Button>
								</Menu.Item>
							),
							pane: (
								<TabPane key="appointment-pane">{control_component}</TabPane>
							),
						},
						{
							menuItem: (
								<Menu.Item key="fallback-tab" fitted>
									<Button
										type="button"
										primary={activeComponent === 'fallback'}
										basic={activeComponent !== 'fallback'}
										onClick={onTabButtonClick}
										data-tab="fallback"
										data-testid="button-next-available"
									>
										Next available
									</Button>
								</Menu.Item>
							),
							pane: (
								<TabPane key="fallback-pane" className="top">{fallback_component}</TabPane>
							),
						},
					]}
					activeIndex={activeComponent === 'appointment' ? 0 : 1}
					onTabChange={onTabChange}
				/>
			</div>
		);
	}
	return (
		<div className="pb-4">
			{control_component}
			{fallback_component}
		</div>
	);
};

export default BookingFields;
