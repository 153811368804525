//	------------------------	------------------------	------------------------
//	Description: Conditional banner - shown only when condition is met
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Message, } from 'semantic-ui-react'
import { useUserData } from "xAppLib/Hooks"

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const ResultsBanner = ({ hide_zero = false }) => {
    const [value] = useUserData('unseen_results', 0)
    const [valueExpress] = useUserData('unseen_results_express', 0)
    const show_counter = !(value + valueExpress == 0 && hide_zero)
    if (!show_counter) return null
    return (
        <ul className="flex">
            {(value > 0) &&
                <li className="flex-auto ml-1 mr-1">
                    <Link to={'/results_followup_all'}>
                        <Message
                            error
                            color="red"
                            header={`${value} patient referral${value > 1 ? 's' : ''} with unseen results`}
                            content={`Please visit the Results and Followup page to review your patient's pathology results`}
                        />
                    </Link>
                </li>
            }
            {(valueExpress > 0) &&
                <li className="flex-auto ml-1 mr-1">
                    <Link to={'/results_followup_all'}>
                        <Message
                            error
                            color="pink"
                            header={`${valueExpress} express referral${valueExpress > 1 ? 's' : ''} unseen results`}
                            content={`Please visit the Results and Followup page to review your patient's express pathology results`}
                        />
                    </Link>
                </li>
            }
        </ul>
    )
}
 
export default ResultsBanner;
