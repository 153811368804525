import React from 'react';
import admin_devices_model from 'models/admin_devices_model';
import { Form } from 'semantic-ui-react';

const DeviceOrgSelector = () => {

    const canSwapDeviceOrg = app.dvc?.org?.availableOrgs?.length;

	const onDeviceUpdate = async (_, e) => {
        const org = app.dvc.org.availableOrgs.find(o => o.oid === e.value);

        if (org.oid === app.dvc.org.oid) return null;

        const answer = await app.confirm(`Set Organisation (${org.onum}) ${org.name}`, `On the current device` );

        if (answer == 'yes') {
            admin_devices_model.update_record(app.dvc.dvcid+'/dvc/', { oid: org.oid }, { timestamp: true, timestamp_fld:'org_set', add_hist: true });
            location.reload(true);
        }
	}

    if (app.dvc?.org && !canSwapDeviceOrg)  return (
        <b>{app.dvc.org.name}<br/></b>
    )

    if (canSwapDeviceOrg) {
        const orgOptions = app.dvc.org.availableOrgs?.map(org => ({ 
            key: org.oid,
            text: org.name,
            value: org.oid,
        })).filter((org) => org.key !== app.dvc.org.oid);
        
        return (
            <Form>
                <Form.Dropdown
                    options={[
                        {
                            key: app.dvc.org.oid,
                            text: <b>{app.dvc.org.name}</b>,
                            value: app.dvc.org.oid
                        },
                        ...orgOptions
                    ]}
                    text={<b>{app.dvc.org.name}</b>}
                    value={app.dvc.org.name}
                    data-testid="dropdown-clinic"
                    onChange={onDeviceUpdate}
                />
            </Form>
        );
    }

    return null;
}

export default DeviceOrgSelector;