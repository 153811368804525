import React, { Component } from 'react';

import IlegEula from './IlegEula'

const IlegTermsAndConditions = () => (
	<div>
		<h3>Parts of this Agreement</h3>
    <p>This Master Services Agreement is comprised of the following:</p>

    <p>Part A - General information and execution;</p>
    <p>Part B - General Terms, and, </p>
    <p>Part C - End User Licence Agreement (EULA)</p>

    <p>By signing this Agreement, the Customer and InstantScripts agree to the terms and conditions set out in this Agreement, and that such terms and conditions will apply to all Services provided pursuant to each Referral under this Agreement.</p>

    <p>All references to this Agreement include this Agreement comprised of each of Parts A, B and C set out above.</p>

    <h3>Part A – General Information and Execution </h3>

    <table>
      <tr>
        <td><p>Name: </p></td>
        <td><p>InstantScripts Pty Ltd </p></td>
      </tr>
      <tr>
        <td><p>ACN:</p></td>
        <td><p>629 120 234</p></td>
      </tr>
      <tr>
        <td><p>ABN:</p></td>
        <td><p>87 629 120 234</p></td>
      </tr>
      <tr>
        <td><p>Address:</p></td>
        <td><p>Level 8 / 637 Flinders St., Docklands VIC 3008</p></td>
      </tr>
      <tr>
        <td><p>Execution and date:</p></td>
        <td><p>Executed as per account creation date.</p></td>
      </tr>
    </table>

    <h3>Part B – General Terms</h3>

    <p><b>1. Definitions and interpretation</b></p>
  
    <p><b>1.1 Definitions</b></p>
    <p>In this Agreement the following definitions apply:</p>
    <p><b>Agreement</b> means this Master Services Agreement and includes Part, Part B and Part C and any other document incorporated by reference;</p>
    <p><b>Authorisation</b> means any consent, registration, filing, agreement, notice of non-objection, notarisation, certificate, licence, approval, permit, authority or exemption from, by or with a Government Agency;</p>
    <p><b>Authorised User</b> means a Customer User, a Client User and any other person expressly authorised by InstantScripts to use the Services;</p>
    <p><b>Claim</b> means any claim, demand, legal proceedings or cause of action including any claim, demand, legal proceedings or cause of action based in contract, tort (including misrepresentation or negligence), under common law or under statute and includes a claim, demand, legal proceeding or cause of action arising from any breach of warranty or indemnity;</p>
    <p><b>Client</b> means a client or potential client of the Customer who is the subject of a Referral;</p>
    <p><b>Client User</b> means any Client who accesses and uses the Services as a result of a Referral;</p>
    <p><b>Customer User</b> means an employee of the Customer and any other person or Representative of the Customer who is authorised by InstantScripts to use the Services;</p>
    <p><b>Commencement Date</b> means the date set out in under Part B of this Agreement.</p>
    <p><b>Developed Intellectual Property Rights</b> means Intellectual Property Rights that are discovered, developed or otherwise come into existence as a result of, for the purposes of, or in connection with the performance of the Services or this Agreement;</p>
    <p><b>Dispute</b> means any dispute or disagreement concerning this Agreement, the provision of the Services or the rights or obligations of the parties under this Agreement;</p>
    <p><b>Eligible Referral</b> has the meaning given in clause 3.5(b);</p>
    <p><b>Fees</b> means the fees described under Part A of this Agreement, as updated or varied from time to time in accordance with this Agreement;</p>
    <p><b>Government Agency</b> means any government or governmental, administrative, monetary, fiscal or judicial, regulatory body, minister, department, commission, authority, instrumentality, board, organisation, tribunal, agency, trade union or entity in any part of the world (or any office or delegate thereof);</p>
    <p><b>Insolvency Event</b> means, in relation to a person:</p>
    <p class="ml-2">(a) anything which reasonably indicates that there is a significant risk that such person is or will become unable to pay its debts as and when they fall due, including each of the following:</p>
        <p class="ml-4">(i) a meeting of the person’s creditors being called or held;</p>
        <p class="ml-4">(ii) a step being taken to make the person bankrupt;</p>
        <p class="ml-4">(iii) an application being presented or an order made for the sequestration of the person's estate;</p>
        <p class="ml-4">(iv) a step being taken to wind the person up;</p>
        <p class="ml-4">(v) a step being taken to have a receiver, receiver and manager, administrator, controller, liquidator or provisional liquidator appointed to the person or any of its assets;</p>
        <p class="ml-4">(vi) the person entering into any type of agreement, composition or arrangement with, or assignment for, the benefit of all or any of its creditors; or</p>
        <p class="ml-4">(vii) the person ceases or threatens to cease to carry on its main business; or</p>
      <p class="ml-2">(b) the appointment of a receiver, receiver and manager, administrator, controller, liquidator or provisional liquidator to the person or any of its assets;</p>
    <p><b>InstantScripts Practitioner</b> means any medical practitioner who is a Representative of InstantScripts who provides Medical Services to a Client as a result of a Referral;</p>
    <p><b>Intellectual Property Rights</b> means any and all intellectual property rights (anywhere in the world, whether statutory, common law or otherwise) including patents, patent applications, utility models, copyright (including future copyright), trade marks, logos and design marks, service marks, trade names, business names, brand names, designs, know how, trade secrets, domain names, other names and locators associated with the world wide web, internet addresses, and including all applications and rights to apply for registration of such intellectual property rights;</p>
    <p><b>Law</b> means any law or legal requirement, including at common law, in equity, under any statute, regulation, by-law or Authorisation, and any decision, directive or requirements of any Government Agency;</p>
    <p><b>Medical Records</b> includes a Medical Services Report, clinical notes, treatment cards, reports, consents, correspondence, memoranda, file notes, test results, x-ray reports, radiological results, radiological imaging and reports, draft reports and letters of referral.</p>
    <p><b>Medical Services</b> means, in respect of each Referral, the services provided by an InstantScripts Practitioner to a Client, including a medical consultation and the preparation of a Medical Services Report or other Medical Record;</p>
    <p><b>Medical Services Report</b> means a written report as to the state of health of a Client prepared by an InstantScripts Practitioner following the provision of Medical Services;</p>
    <p><b>Personal Information</b> means information or an opinion (including information or an opinion forming part of a database), whether true or not, and whether recorded in writing or spoken, about an individual whose identity is apparent, or can reasonably be ascertained, from the information or opinion;</p>
    <p><b>Platform</b> means the ‘My Legal Doc’ online technology platform made available by InstantScripts via www.mylegaldoc.com.au;</p>
    <p><b>Privacy Law</b> means the Privacy Act 1988 (Cth) and any other law that applies to the collection, use, disclosure or handling of information about individuals or health records (including a law of a State or Territory relating to health records or privacy);</p>
    <p><b>Privacy Approval</b> means any authorisation or approval required from a Client under a Privacy Law for the purposes of InstantScripts providing the Services or the Customer and each Customer User accessing the Services and the Medical Records of a Client;</p>
    <p><b>Referral</b> means a referral made by the Customer of a Client to an InstantScripts Practitioner via the Platform;</p>
    <p><b>Regulated Information</b> means all Personal Information, health information and other information or opinion, whether true or not, and whether recorded in material form or not, about any entity or person which a party receives or has access to under, or for the purpose of, this Agreement, including such information forming part of the User Data and all Medical Records that are transmitted and/or stored via the Platform;</p>
    <p><b>Related Party</b> means:</p>
    
      <p class="ml-2">(a) in respect of a body corporate, anyone who is an associate of that body corporate under sections 11 to 15 (inclusive) of the Corporations Act; and </p>
      <p class="ml-2">(b) in respect of an individual, an 'associate' of that individual as defined in section 318 of the Income Tax Assessment Act 1936 (Cth);</p>
    
    <p><b>Representative</b> means, in respect of a person, any director, officer, employee, agent, contractor, adviser or Related Party of or to that person, or any director, officer, employee, agent, contractor or adviser of or to a Related Party of or to that person;</p>
    <p><b>Services</b> means (a) providing access to the Platform and the services made available by InstantScripts online via the Platform, including the facilitation of Referrals and document storage and management of Medical Records for Customers and their Clients, (b) the online, web-deployed, or other My Legal Doc Services provided (or to be provided) by InstantScripts to the Customer via the Platform, any and all software applications or other applications made available or accessible through (or provided as a feature of) the My Legal Doc Services or the Platform, any InstantScripts content that may appear in any output produced by the Customer using the My Legal Doc Services, and (c) all updates to the foregoing, inclusive of any applicable Third Party Products;</p>
    <p><b>Term</b> means the term of this Agreement as set out in clause 2 of this Agreement;</p>
    <p><b>Third Party Consents</b> means any consents, licences or permission from third parties required to allow InstantScripts to provide the Services under this Agreement, including any Privacy Approval;</p>
    <p><b>Third Party Product</b> means any product or service that is supplied to the Customer by or on behalf of a third party;</p>
    <p><b>Third Party Supplier</b> means any subcontractor or supplier engaged or utilised by InstantScripts in connection with the provision of the Services, other than a Related Party of InstantScripts;</p>
    <p><b>Update(s)</b> means any new releases, versions, enhancements, updates, corrections, patches, bug-fixes, or other modifications to the Services or any part of the Services; and</p>
    <p><b>User Data</b> means any data inputted into the Services by the Customer or an Authorised User, including Medical Records uploaded by an Authorised User.</p>
    
    <p><b>1.2 Interpretation</b></p>
    <p>In the interpretation of this Agreement, unless the context otherwise requires:</p>
    
      <p class="ml-2">(a) all references to dollars or ‘$’ are references to Australian currency and all amounts payable are payable in Australian dollars;</p>
      <p class="ml-2">(b) a reference to any law or legislation or legislative provision includes any statutory modification, amendment or re-enactment, and any subordinate legislation or regulations issued under that legislation or legislative provision;</p>
      <p class="ml-2">(c) a reference to a clause, part, schedule or attachment is a reference to a clause, part, schedule or attachment of or to this Agreement;</p>
      <p class="ml-2">(d) a reference to any agreement or document is to that agreement or document as amended, novated, supplemented or replaced from time to time;</p>
      <p class="ml-2">(e) a reference to a person includes any company, trust, partnership, joint venture, association, corporation, body corporate or Governmental Agency;</p>
      <p class="ml-2">(f) where a word or phrase is given a defined meaning another part of speech or other grammatical form in respect of that word or phrase has a corresponding meaning;</p>
      <p class="ml-2">(g) a word which indicates the singular also indicates the plural, a word which indicates the plural also indicates the singular, and a reference to any gender also indicates the other gender;</p>
      <p class="ml-2">(h) a reference to the word ‘include’ or ‘including’ is to be interpreted without limitation;</p>
      <p class="ml-2">(i) headings are inserted for convenience only and do not affect interpretation;</p>
      <p class="ml-2">(j) any schedules, annexures and attachments form part of this Agreement; and</p>
      <p class="ml-2">(k) no clause will be construed to the disadvantage of a party because that party was responsible for the preparation of this Agreement or that clause or the inclusion of the provision in the agreement.</p>

    <p><b>1.3 Business days and time</b></p>
    <p>In the interpretation of this Agreement, unless the context otherwise requires:</p>
      
      <p class="ml-2">(a) a reference to time is to the time in Melbourne, Australia;</p>
      <p class="ml-2">(b) a reference to a business day means a day on which all banks are open for business generally in Melbourne, Australia, other than a Saturday, Sunday or public holiday; and</p>
      <p class="ml-2">(c) if the day on which any act, matter or thing is to be done is not a business day, that act, matter or thing may be done on the next business day.</p>
      
    

    <p>2 Term</p>
    <p>This agreement commences on the Commencement Date and will remain in force until it is terminated in accordance with clause 13 this Agreement.</p>

    <p>3 Access to and use of Services</p>
    <p><b>3.1 Grant of rights</b></p>
    <p>In consideration for the Customer paying the applicable Fees, InstantScripts grants the Customer a non-exclusive, non-transferable, limited right to access and use the Services during the Term in accordance with the Agreement.</p>

    <p><b>3.2 Use of the Services</b></p>
     <p class="ml-2">(a) The Customer will use the Services, and will procure that Customer Users use the Services:</p>
    
      <p class="ml-4">(i) solely for the Customer’s own internal purposes of providing legal advice to Clients; and</p>
      <p class="ml-4">(ii) otherwise in accordance with this Agreement (including in accordance with the End User Agreement) and any other policies or documentation (including any service level agreement, acceptable or fair use policy or privacy policy) issued or made available by InstantScripts to the Customer from time to time.</p>
    

    <p><b>3.3 Authorised Users</b></p>
    
      <p class="ml-2">(a) The Customer may allow Authorised Users to access and use the Services in accordance with this Agreement. The Customer will not provide access to any portion of the Services to any person or entity other than an Authorised User or as otherwise expressly permitted in this Agreement.</p>
      <p class="ml-2">(b) The Customer will, and must procure that it and each Customer User accepts the End User Agreement attached under Part C of this Agreement prior to being provided access to the Services.</p>
      <p class="ml-2">(c) InstantScripts may communicate with any Authorised Users in relation to the Services, including how to use the Services.</p>
      <p class="ml-2">(d) The Customer is at all times responsible and liable for any acts or omissions of its Authorised Users as if those were the Client's own acts or omissions and the Customer must ensure that its Authorised Users do not use the Services in a way that would result in a breach of this Agreement by the Customer.</p>
      <p class="ml-2">(e) The Customer will promptly and without undue delay notify InstantScripts upon learning of any unauthorised use of the Services or any other breach of security related to the Services.</p>

    

    <p><b>3.4 Prohibited use</b></p>
    <p>In using the Services, without limiting any other term of this Agreement, the Customer must not:</p>
    
      <p class="ml-2">(a) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Services available to any third party, other than Authorised Users or as expressly permitted by this Agreement;</p>
      <p class="ml-2">(b) use the Services to process data on behalf of any third party other than Authorised Users;</p>
      <p class="ml-2">(c) modify, adapt, or attempt to gain unauthorised access to the Services;</p>
      <p class="ml-2">(d) use the Services in any unlawful manner;</p>
      <p class="ml-2">(e) use the Services to send unsolicited or unauthorised mail, spam, other forms of duplicative or unsolicited communications;</p>
      <p class="ml-2">(f) use the Services to store or transmit files, materials, data, text, audio, video, images or other content that infringes on any person’s intellectual property rights;</p>
      <p class="ml-2">(g) use the Services in any manner that interferes with or disrupts the integrity or performance of the Services and its components;</p>
      <p class="ml-2">(h) attempt to decipher, decompile, reverse engineer or otherwise discover the source code of any software which comprises the Services;</p>
      <p class="ml-2">(i) use the Services to knowingly post, transmit, upload, link to, send or store any viruses, malware, or any other similar harmful software; or</p>
      <p class="ml-2">(j) attempt to use, or use the Services in violation of this Agreement,</p>
    
    <p>and must ensure that its Authorised Users do not do anything which would amount to a breach by the Customer of its obligations under clauses 3.4(1) to 3.4(10).</p>

    <p><b>3.5 Referral process</b></p>
    <p>Following each Referral by the Customer, InstantScripts will procure that:</p>
    
      <p class="ml-2">(a) an InstantScripts Practitioner contacts the Client and offers to provide Medical Services to the Client in respect of such Referral; and</p>
      <p class="ml-2">(b) if such offer is accepted by the Client and Medical Services are provided by the InstantScripts Practitioner (‘<b>Eligible Referral</b>’), the InstantScripts Practitioner provides or makes available a Medical Services Report to the Customer and the Client User via the Platform.</p>

    

    <p>For the avoidance of doubt, save for InstantScripts’ limited obligations under clause 3.5 in respect of a Referral, the provision of the Medical Services is a matter directly between the InstantScripts Practitioner and the Client.</p>

    <p><b>3.6 Updates and modifications</b></p>
    <p>InstantScripts may from time to time without notice to the Customer, as part of the Services:</p>
    
      <p class="ml-2">(a) implement Updates to the Services; or</p>
      <p class="ml-2">(b) make modifications to the Services or particular components of the Services including to add or remove features and functionality from time to time.</p>

    

    <p><b>3.7 Limits on requirement to provide Services</b></p>

    <p>Nothing in this Agreement will require InstantScripts to provide (and InstantScripts will not be liable for failing to provide) any of the Services where providing any part of the Services would:</p>

    
      <p class="ml-2">(a) amount to a contravention or infringement of any Law;</p>
      <p class="ml-2">(b) place InstantScripts or a Related Party of InstantScripts in breach of any contract to which it is a party; or</p>
      <p class="ml-2">(c) infringe the Intellectual Property Rights, rights under any Privacy Laws or any contractual or other legal rights of a third party.</p>
    

    <p><b>4 Third Party Products</b></p>

    <p><b>4.1 Third Party Products</b></p>

    
      <p class="ml-2">(a) The Services may contain features designed to be integrated and/or configured with Third Party Products.</p>
      <p class="ml-2">(b) If the Customer or any Authorised User uses the Services in conjunction with any Third Party Products, the Customer acknowledges and agrees that:</p>
      
        <p class="ml-4">(i) any issues relating to access to and use of those Third Party Products, or relating to any exchange of data or settlement of funds between the Customer and the third party or the Third Party Product, are solely between the Customer and the applicable third party and are not matters for which InstantScripts is responsible or liable;</p>
        <p class="ml-4">(ii) InstantScripts does not warrant or support any Third Party Products or any interface between them and the Services; and</p>
        <p class="ml-4">(iii) InstantScripts is not liable for any disclosure, modification or deletion of Medical Services Reports, User Data or Personal Information resulting from access to or use of those Third Party Products or any exchange of data with them, other than to the extent caused by a material breach of this Agreement by InstantScripts or InstantScripts’ wilful default or gross negligence.</p>
      
    

    <p><b>4.2 No breach of obligations</b></p>

    
      <p class="ml-2">(a) InstantScripts will not be liable for any failure or delay to make Services available to the Customer or an Authorised User to the extent that such failure or delay arises out of any failure or delay by a Third Party Supplier to provide such Services, provided that InstantScripts has taken all reasonable steps to minimise or eliminate such failure or delay.</p>
      <p class="ml-2">(b) InstantScripts will not be in breach of its obligation to provide access to the Services under this Agreement to the extent that it cannot provide such Services as a result of any failure to obtain a Third Party Consent despite using reasonable endeavours to obtain such consent.</p>
      <p class="ml-2">(c) The parties must comply with the terms and conditions of any Third Party Consent.</p>
    

    <p><b>5 User Data</b></p>

    
      <p class="ml-2">(a) The Customer is solely responsible for:</p>
      
        <p class="ml-4">(i) the accuracy, completeness and legality of User Data and legality of the means by which the Customer acquired its User Data;</p>
        <p class="ml-4">(ii) securing and maintaining all rights in User Data necessary for InstantScripts to provide the Services without violating the rights of any third party or otherwise obligating InstantScripts to the Customer or to any third party;</p>
        <p class="ml-4">(iii) effecting and maintaining appropriate security measures to secure its, and its Authorised Users', access to and use of the Services, and to prevent the unauthorised access to or use of the Services or to the User Data; and</p>
        <p class="ml-4">(iv) promptly notifying InstantScripts of any breach of security or any unauthorised access to or use of the Services or User Data (regardless of the cause of such breach or InstantScripts access or use).</p>

      
      <p class="ml-2">(b) The Customer authorises InstantScripts to use the User Data for the purpose of enabling InstantScripts to perform the Services</p>
      <p class="ml-2">(c) Despite any other provisions of this Agreement, the Customer acknowledges and agrees that InstantScripts may aggregate anonymous or de-identified data, including regarding the Authorised Users' usage of the Services and potential improvements of or functioning of the Services (collectively, ‘<b>Analytics</b>’).  As between the Client and InstantScripts, InstantScripts will own all right, title and interest in and to such Analytics and the Intellectual Property Rights relating to them, and such Analytics are InstantScripts’ confidential information. </p>

    

    <p><b>6 Payments</b></p>

    <p><b>6.1 Payment for Services</b></p>

    <p>The Customer must pay InstantScripts the Fees for the provision of the Services.  Unless otherwise agreed in writing by the parties, such fees and charges are fixed during the Term (including any extension).</p>

    <p><b>6.2 Invoices</b></p>

    <p>On or around the start of each month, InstantScripts will submit an invoice to the Customer for the Fees incurred by the Customer during the previous month.</p>

    <p><b>6.3 Payment</b></p>

    <p>The Customer must pay each invoice submitted by InstantScripts within 30 days of receipt of the invoice from InstantScripts by way of electronic funds transfer to a bank account nominated in writing by InstantScripts.</p>

    <p><b>6.4 Interest on late payments</b></p>

    <p>If the Customer fails to pay an amount owing to InstantScripts under this Agreement on the due date for payment, the Customer must pay on demand interest on that amount from the due date of payment until the date of payment, at [8%] per annum (calculated daily), with InstantScripts entitled to capitalise interest at monthly intervals.</p>

    <p><b>6.5 Suspension of Services pending payment</b></p>

    <p>In addition to and without in any way limiting clause 6.4, if the Customer fails to pay an amount owing to InstantScripts under this Agreement, and fails to remedy such breach by paying the amount within five (5) business days after receiving a written notice to do so, InstantScripts may suspend the provision of any or all of the Services until such amount (and any accrued interest) is paid.</p>

    <p><b>6.6 GST</b></p>

    
      <p class="ml-2">(a) Unless otherwise stated in the applicable invoice, all prices, rates and charges which are payable under this Agreement are exclusive of GST.</p>
      <p class="ml-2">(b) A party must pay GST on a taxable supply made to it under this Agreement in addition to any consideration (excluding GST) that is payable for that taxable supply.  It must do so at the same time and in the same way as it is required to pay the consideration for the taxable supply.</p>
      <p class="ml-2">(c) For the purposes of this Agreement, “GST”, “taxable supply” and “tax invoice” shall have the meaning attributed to those terms in A New Tax System (Goods and Services Tax) Act 1999.</p>
    

    <p><b>7 Intellectual Property</b></p>

    <p><b>7.1  Acknowledgements</b></p>

    <p>The parties acknowledge and agree that at all times:</p>

    
      <p class="ml-2">(a) all Intellectual Property Rights and other information that InstantScripts provides or makes available to the Customer under or in connection with this Agreement or the provision of the Services remains at all times the property of InstantScripts and its licensors; and</p>
      <p class="ml-2">(b) InstantScripts and its licensors continue to own all of their Intellectual Property Rights existing at the date of this Agreement, or coming into existence during the Term, and, as between InstantScripts and the Customer, InstantScripts will own all Developed Intellectual Property Rights. </p>
    

    <p><b>7.2 Licence</b></p>

    <p>During the Term (including any extension and any holding over period), InstantScripts grants to the Customer a personal, royalty-free, non-exclusive, non-transferable and non-assignable licence to use the Developed Intellectual Property Rights solely for the purpose of enabling the Customer to enjoy the benefit of the Services and operate its business in the ordinary course.</p>

    <p><b>7.3 Customer’s Intellectual Property</b></p>

    <p>InstantScripts acknowledges and agrees that all Intellectual Property Rights and other information of the Customer that the Customer provides or makes available to InstantScripts remains the property of the Customer and its licensors. InstantScripts will not use or disclose such Intellectual Property Rights or information for any purpose other than for the purpose of providing the Services and performing its obligations under this Agreement.</p>

    <p><b>8 Privacy and Regulated Information</b></p>

    
      <p class="ml-2">(a) In respect of any Regulated Information, each party must comply with:</p>
      
        <p class="ml-4">(i) all applicable Privacy Laws; and</p>
        <p class="ml-4">(ii) any privacy policy adopted by the other party.</p>
      

      <p class="ml-2">(b) Without limiting clause 8(a), the Customer must ensure that Regulated Information is protected against loss and against unauthorised access, use, modification, disclosure or other misuse and that only authorised personnel have access to Regulated Information.</p>
      <p class="ml-2">(c) Each party must promptly inform the other in writing of any complaint that it receives concerning the use, disclosure, storage, transfer or handling of Regulated Information.  The parties must cooperate with each other in respect of any such complaint.</p>
      <p class="ml-2">(d) The Customer must ensure that at all relevant times it holds and maintains all necessary Privacy Approvals required to permit InstantScripts to provide the Services under this Agreement and for the Customer and each Customer User to access the Services and the User Data and the Medical Records of each Client User.</p>
      <p class="ml-2">(e) The Customer must inform InstantScripts as soon as it becomes aware that it, its Related Parties or their Representatives do not hold any such Privacy Approval.</p>
      <p class="ml-2">(f) The Customer acknowledges and agrees that InstantScripts will rely on any representation made by the Customer, its Authorised Users and its Related Parties (and their respective Representatives) that any of them has a Privacy Approval in respect of a Client or any other person without undertaking its own enquiries as to whether the Privacy Approval has in fact been given or is adequate for the purposes for which InstantScripts requires that Privacy Approval.  However, if required by InstantScripts, the Customer must procure Privacy Approvals in a form that has been approved by InstantScripts. The Customer must upload copies of a Client User’s Privacy Approvals to the relevant Client User’s profile on the Platform, or provide evidence of such Privacy Approvals to InstantScripts upon request.</p>
      <p class="ml-2">(g) The Customer agrees to indemnify and keep indemnified InstantScripts, its Related Parties and their directors, officers, employees, contractors and agents (‘Indemnified Parties’) at all times against all actions, proceedings, liability, claims, damages, costs and expenses of any nature or description whatsoever suffered or incurred by the Indemnified Parties arising in any way out of reliance by any Indemnified Party on a representation by the Customer, its Authorised Users and its Related Parties (and their respective Representatives) that any Privacy Approval has been obtained.</p>
      <p class="ml-2">(h) The indemnity in clause 8(g) is a continuing indemnity and will be irrevocable and remain in full force and effect notwithstanding the termination of this Agreement.</p>
    

    <p><b>9 Confidentiality</b></p>

    <p><b>9.1 Confidential Information</b></p>

    <p>Subject to clause 9.3, each party acknowledges that the terms of this Agreement, the existence of this Agreement, the fact that this Agreement has been entered into and all information and material disclosed or provided to it or any of its Representatives by any other party to this Agreement or any of its Representatives in the course of providing the Services or communications or negotiations in connection with this Agreement is confidential.</p>

    <p><b>9.2 Parties must maintain confidentiality</b></p>

    <p>Each party agrees to maintain the confidentiality of the matters, information and material referred to in clause 9.1 and not disclose or use such information for any purpose other than that for which it has been provided.</p>

    <p><b>9.3 Permitted disclosures</b></p>

    <p>Notwithstanding clauses 9.1 and 9.2, but subject to clause 9.4, a party may disclose information where such disclosure:</p>

    
      <p class="ml-2">(a) has been expressly consented to by the other party in writing or is contemplated or permitted by this Agreement;</p>
      <p class="ml-2">(b) is made to a Representative of a party or to another person who must know it for the purposes of this Agreement on the basis that the Representative or other person to whom the information is disclosed is bound by obligations of confidentiality no less onerous than those imposed on the parties to this Agreement and keeps the information confidential; or</p>
      <p class="ml-2">(c) is required by Law or a regulatory body (including a relevant stock exchange), court or Government Agency.</p>
    

    <p><b>9.4 Required disclosures</b></p>

    <p>Where a party is required to make any disclosure in accordance with clauses 9.3(a) to 9.3(b), the party required to make that disclosure must, before doing so:</p>

    
      <p class="ml-2">(a) notify the other party of the proposed disclosure or announcement;</p>
      <p class="ml-2">(b) coordinate and consult with the other party in relation to the timing and content of the disclosure;</p>
      <p class="ml-2">(c) comply with any reasonable request by the other party concerning the proposed disclosure; and</p>
      <p class="ml-2">(d) to the extent that the disclosure is required as a consequence of any requirement, action or decision of a regulatory body, give the other party a reasonable opportunity to challenge in a court of law or other appropriate body whether the proposed disclosure is required.</p>

    

    <p><b>9.5 Return or destruction of confidential information</b></p>

    
      <p class="ml-2">(a) If requested in writing by the other party or on termination of this Agreement, a party must promptly return to the other party or destroy (if requested by the other party) all documents or material containing conditional information regarding the matters, information and material referred to in clause 9.1 in the possession, power or control of that party or any of its Representatives and, if requested by the other party, give the other party either or both of the following:</p>
      
        <p class="ml-4">(i) evidence of the return or destruction of such documents or material; or</p>
        <p class="ml-4">(ii) a written statement signed by an authorised officer of the party confirming that all such documents or material have been returned or destroyed.</p>

      
      <p class="ml-2">(b) Clause 9.5(a) does not apply to:</p>
      
        <p class="ml-4">(i) documents required to be retained by the party or any of its Representatives by Law; or</p>
        <p class="ml-4">(ii) any automatically generated backups or archive copies of confidential information which is located on a server of the party or any of its Representatives as a result of the automatic back-up of data in the usual operations of the party or any of its Representatives,</p>

      
      <p>provided that at all times (including after the termination of this Agreement) these documents and materials are kept confidential in accordance with the terms of this Agreement.</p>
      
    

    <p><b>9.6 Notification of breach</b></p>

    <p>If a party becomes aware of a breach of any of the obligations set out in this clause 9, that party must immediately notify the other party.</p>

    <p><b>9.7 Continuing obligations</b></p>

    <p>All obligations of confidence set out in this Agreement continue in full force and effect after this Agreement ends.</p>

    <p><b>10 Warranties</b></p>

    <p><b>10.1 General warranties</b></p>

    <p>Each party represents and warrants to the other on a continuing basis that:</p>

    
      <p class="ml-2">(a) it has taken all necessary action to authorise the execution, delivery and performance of this Agreement in accordance with its terms;</p>
      <p class="ml-2">(b) it has full power to enter into and perform its obligations under this Agreement and can do so without the consent of any other person;</p>
      <p class="ml-2">(c) as at the date of this Agreement, the execution, delivery and performance of this Agreement by it complies with each law, regulation, Authorisation, ruling, judgment, order or decree of any Government Agency, its constitution or other constituent documents and any Security Interest which is binding on it;</p>
      <p class="ml-2">(d) on execution of this Agreement its obligations under this Agreement will be valid, binding and enforceable; and</p>
      <p class="ml-2">(e) it is able to pay its debts as and when they become due and payable and is not the subject of an Insolvency Event.</p>
    

    <p><b>10.2 Acknowledgements</b></p>
    
    <p>Each party acknowledges that each other party has entered into this Agreement in reliance on the warranties given by it under this Agreement.</p>

    <p><b>10.3 Warranties separate</b></p>

    <p>Each of the warranties given by a party under this Agreement is separate and independent and, except as expressly provided to the contrary in this Agreement, is not limited by reference to any other one of the warranties.</p>

    <p><b>11 Limitation of liability</b></p>

    <p><b>11.1 Limitations</b></p>

    <p>Notwithstanding any other term of this Agreement:</p>

    
      <p class="ml-2">(a) other than as expressly set out in this Agreement, InstantScripts makes no warranties or representations in relation to the Services, including in relation to their fitness for purpose whether any purpose is specified or otherwise; and</p>
      <p class="ml-2">(b) subject to clause 11.2 and to the extent permitted by Law:</p>
      
        <p class="ml-4">(i) InstantScripts’s total aggregate liability (including liability for negligence) under or in any way related to this Agreement or the Services will not exceed, in aggregate, the Fees paid by the Customer to InstantScripts during the 12 month period immediately prior to the date the liability arose;</p>
        <p class="ml-4">(ii) InstantScripts will not be liable to the Customer party in relation to, and excludes, any liability (including liability for negligence) under or in any way related to this Agreement for any loss which cannot fairly and reasonably be considered to arise naturally or in the ordinary course of things from the breach of this Agreement or the fact, matter or circumstance giving rise to the Claim;</p>
        <p class="ml-4">(iii) InstantScripts excludes all implied conditions and warranties, except any implied condition or warranty the exclusion of which would contravene any statute or cause any part of this clause to be void ('<b>Non-excludable Condition</b>'); and</p>
        <p class="ml-4">(iv) InstantScripts’s liability for breach of any Non-excludable Condition (other than an implied warranty of title) is limited, at InstantScripts’s option, to refunding the price of the services in respect of which the breach occurred or to providing those services again.</p>
      
    

    <p><b>11.2 Acknowledgements</b></p>

    <p>The Customer acknowledges and agrees that:</p>

    
      <p class="ml-2">(a) InstantScripts relies on hosting services provided by third parties to host the Platform. InstantScripts makes reasonable commercial efforts to ensure that it selects and engages reputable host service providers and to seek commitments from those host service providers to minimise the likelihood and duration of any downtime for the hosting service and hence access to and use of the Platform. However, the parties acknowledge that InstantScripts will not be liable to the Customer, or to any Client or Authorised User for any loss or damage occurring in the event that the third party hosting service on which the Platform relies experiences disruptions or downtime. Further, without limiting any other provision of this Agreement, the Customer agrees and acknowledges that InstantScripts will not be liable to the Customer or to any Client or Authorised User for any loss or damage occurring due to failure malfunction or inadequacy of any system, equipment, hardware or software utilised by the Customer or any Authorised User in connection with the use of the Platform;</p>
      <p class="ml-2">(b) InstantScripts may not be held responsible for, and has no input into or control over, the provision of Medical Services and/or contents of any Medical Records (including Medical Services Reports) stored or transmitted via the Platform;</p>
      <p class="ml-2">(c) InstantScripts may not be held responsible or liable for the Customer’s or an Authorised User’s or a Client’s use of, or reliance on, any Medical Services or the contents of any Medical Records (including Medical Services Reports) stored or transmitted via the Platform; and</p>
      <p class="ml-2">(d) InstantScripts may not be held liable to the Customer, any of its Related Entities, or their Representatives or any Client or Authorised User in respect of, arising out of or in any way connected with the provision of Medical Services, the contents of any Medical Records (including Medical Services Reports) stored or transmitted via the Platform or the Customer’s, the Client’s or an Authorised User’s use of, or reliance on, Medical Services or the contents of any Medical Records (including Medical Services Reports).</p>

    

    <p><b>11.3 Exclusions</b></p>
    
    <p>Nothing in clause 11.1(b) operates to exclude or restrict InstantScripts’s liability for fraud; or for a breach of clause 8 (Privacy and Regulated Information) or clause 9 (Confidentiality).</p>

    <p><b>12 Force majeure</b></p>

    <p>InstantScripts is not liable for any failure to perform or delay in performing its obligations under this Agreement if that failure or delay is due to anything beyond its reasonable control.  If that failure or delay exceeds 90 days, the Customer may terminate this Agreement with immediate effect by giving notice to InstantScripts.</p>

    <p><b>13  Termination</b></p>

    <p><b>13.1 Termination without cause</b></p>

    <p>Either party may terminate this Agreement at any time by not less than 90 days’ prior written notice to the other party.</p>

    <p><b>13.2 Immediate termination</b></p>

    <p>Either party may immediately terminate this Agreement by giving written notice to the other party if:</p>

    
      <p class="ml-2">(a) the other party is in breach of a material provision of this Agreement and such breach is not able to be remedied;</p>
      <p class="ml-2">(b) the other party is in breach of a provision of this Agreement which is capable of being remedied but fails to remedy such breach within 10 business days after receiving a written notice requiring it to do so; or</p>
      <p class="ml-2">(c) the other party is the subject of an Insolvency Event.</p>
    

    <p><b>13.3 Rights not affected</b></p>

    <p>If this Agreement is terminated each party retains its rights under this Agreement and at Law in respect of any breach of this Agreement by the other party and all other accrued rights.</p>

    <p><b>13.4 Continuing obligations</b></p>

    <p>This clause 13 and each of the following clauses continue to apply after this Agreement ends or is terminated: clause 7 (Intellectual Property); clause 8 (Privacy and Regulated Information); clause 9 (Confidentiality); clause 11 (Liability and Indemnity); clause 14 (Dispute resolution); clause 15 (Notices); clause 16 (General); any other clauses that make provision for the continued operation of this Agreement; and those provisions of clause 1 (Definitions and interpretation) which go to the interpretation of the above clauses.</p>

    <p><b>14 Dispute resolution</b></p>

    <p>14.1 Dispute</p>

    <p>If a party considers that a Dispute has arisen, it must give written notice to the other party ('<b>Dispute Notice</b>') setting out the nature of the Dispute, and the parties must then act in good faith and use reasonable efforts to resolve the Dispute quickly and efficiently through negotiation.</p>

    <p><b>14.2 Mediation</b></p>

    
      <p class="ml-2">(a) If the parties are unable to resolve the Dispute within 15 business days of the date of the Dispute Notice or such longer period agreed by the parties ('Negotiation Period'), the parties must refer the Dispute for mediation to be conducted on the following terms:</p>
      
        <p class="ml-4">(i) the mediator will be as agreed by the parties within 10 business days of the end of the Negotiation Period;</p>
        <p class="ml-4">(ii) if the parties are unable to agree on a mediator within 10 business days of the end of the Negotiation Period, either party may ask the President of the Law Institute of Victoria or his or her nominee to appoint a mediator;</p>
        <p class="ml-4">(iii) the parties must mediate the Dispute in accordance with principles agreed between them or, if no agreement can be reached, the principles determined by the mediator in his or her absolute discretion; and</p>
        <p class="ml-4">(iv) unless the parties agree otherwise, the mediator's fee and any other costs of the mediation will be shared equally by the parties, but each party must pay its own costs of preparing for and participating in the mediation.</p>

      
      <p class="ml-2">(b) The parties must do whatever is reasonably necessary to put into effect any negotiated or mediated agreement or other resolution.</p>
    

    <p><b>14.3 Dispute resolution procedure</b></p>

    <p>No party may commence any legal action or proceedings in relation to any Dispute until the dispute resolution procedures set out in clauses 14.1 and 14.2 have been followed and until such time as any mediation in respect of any such Dispute has been terminated, provided that nothing in this clause 14 restricts or limits the rights of a party to seek urgent interlocutory relief or declaratory relief in respect of a Dispute</p>

    <p><b>14.4 Performance of obligations</b></p>

    <p>Notwithstanding the existence of a Dispute, each party must continue to perform its obligations under this Agreement.</p>

    <p><b>15 Notices</b></p>

    <p><b>15.1 Notices must be given by authorised signatories</b></p>

    <p>Any notice given or made by a party under or in connection with this Agreement must be in writing and signed by that party by an authorised signatory of that party.  A person is an authorised signatory if he or she is a director of the relevant party or is authorised in writing by that party. Any notice by email is taken to be signed by the named sender.</p>

    <p><b>15.2 Giving notices</b></p>

    <p>Any notice given under this Agreement is only given if it is in writing and delivered or posted to that party at its address and marked for the attention of the relevant department or person (if any) set out in Part A of this Agreement or sent by email to that party at its email address and marked for the attention of the relevant department or person (if any) set out in Part A of this Agreement.</p>

    <p><b>15.3 Change of address or email address</b></p>

    <p>If a party gives each other party three business days' notice of a change of its address or email address, any notice is only given to that party if delivered or posted to its latest address or email address.</p>

    <p><b>15.4 Time notice is given</b></p>

    <p>Any notice is to be treated as given at the following time:</p>

    
      <p class="ml-2">(a) if it is delivered, when it is left at the relevant address;</p>
      <p class="ml-2">(b) if it is sent by post, three (or in the case of a notice posted to another country, nine) business days after it is posted; or</p>
      <p class="ml-2">(c) if sent by email, on the date of transmission, or if sent on a non-business day to the Customer's address, on the next business day, and provided that the sender's email software from which the email was sent records a successful transaction.</p>
    

    <p>If any notice is given on a day that is not a business day or after 5.00pm on a business day in the place of the party to whom it is sent, it is to be treated as having been given at the beginning of the next business day. If the sender's email software indicates that the notice was received after 5.00pm it shall be deemed to have been received on the following day.</p>

    <p><b>16 General</b></p>

    <p><b>16.1 Approvals and consent</b></p>
    <p>Except as otherwise set out in this Agreement, a party may give or withhold an approval or consent in that party's absolute discretion and subject to any conditions determined by that party.  A party is not obliged to give reasons for giving or withholding a consent or approval or for giving a consent or approval subject to conditions.</p>

    <p><b>16.2 Assignment</b></p>

    <p>The Customer must not assign this Agreement or any rights or obligations under this Agreement without the prior written consent of InstantScripts.  InstantScripts may assign its rights and obligations under this Agreement by written notice to the Customer.</p>

    <p><b>16.3 Costs</b></p>

    <p>Unless otherwise agreed by the parties in writing, each party must pay its own costs in relation to preparing, negotiating and executing this Agreement and any related document.</p>

    <p><b>16.4 Entire agreement</b></p>

    <p>This agreement constitutes the entire agreement between the parties in relation to its subject matter.  No party can rely on an earlier document, or anything said or done by another party, or by a director, officer, agent or employee of that party, before this Agreement was executed.</p>

    <p><b>16.5 Execution of separate documents</b></p>

    <p>This agreement is properly executed if each party executes either this document or an identical document.  In the latter case, this Agreement takes effect when the separately executed documents are exchanged between the parties. Delivery of an executed counterpart of this Agreement by PDF file (portable document file) will be effective as manual delivery of an executed counterpart of this Agreement.</p>

    <p><b>16.6 Exercise of rights</b></p>

    <p>A party may exercise a right, power or remedy separately or concurrently with another right, power or remedy available to it at law or under this Agreement. A single or partial exercise of a right, power or remedy by a party does not prevent a further exercise of that or of any other right, power or remedy and a failure by a party to exercise, or delay by a party in exercising, a right, power or remedy does not prevent its exercise.</p>

    <p><b>16.7 Further acts</b></p>

    <p>Each party must at its own expense promptly execute all documents and do, or use reasonable endeavours to cause a third party to do, all things that another party from time to time may reasonably request in order to give effect to, perfect or complete this Agreement and all transactions incidental to it.</p>

    <p><b>16.8 Governing law and jurisdiction</b></p>

    <p>This Agreement is governed by the law of Victoria, Australia.  The parties submit to the non-exclusive jurisdiction of its courts and courts of appeal from them.  The parties will not object to the exercise of jurisdiction by those courts on any basis.</p>

    <p><b>16.9 Indemnities</b></p>

    <p>Except as otherwise set out in this Agreement, each indemnity in this Agreement is a continuing obligation, separate and independent from the other obligations of the indemnifying party and survives termination of this Agreement.</p>

    <p><b>16.10 No authority to act</b></p>

    <p>Except as otherwise set out in this Agreement, no party has any power or authority to act for or to assume any obligation or responsibility on behalf of another party, to bind another party to any agreement, negotiate or enter into any binding relationship for or on behalf of another party or pledge the credit of another party.</p>

    <p><b>16.11 Relationship</b></p>

    <p>Except as otherwise set out in this Agreement, this Agreement does not create a relationship of employment, trust, agency or partnership between the parties.</p>

    <p><b>16.12 Rights cumulative</b></p>
    
    <p>Except as otherwise set out in this Agreement, the rights of a party under this Agreement are cumulative with and in addition to any other rights available to that party under this Agreement, any other document or by law and nothing in this Agreement excludes any such rights.</p>

    <p><b>16.13 Severability</b></p>

    <p>Each provision of this Agreement is individually severable.  If any provision is, or becomes illegal, unenforceable or invalid in any jurisdiction it is to be treated as being severed from this Agreement in the relevant jurisdiction, but the rest of this Agreement will not be affected and the legality, validity and enforceability of the provision in any other jurisdiction will not be affected.</p>

    <p><b>16.14 Successors and assigns</b></p>

    <p>This agreement continues for the benefit of, and binds, a successor in title of a party, including a person to whom a party's rights and obligations are assigned in accordance with the requirements of this Agreement.</p>

    <p><b>16.15 Variation</b></p>

    <p>No variation of this Agreement will be of any force or effect unless it is in writing and signed by each party to this Agreement.</p>

    <p><b>16.16 Waiver</b></p>

    <p>A waiver of any right, power or remedy under this Agreement must be in writing signed by the party granting it.  A waiver only affects the particular obligation or breach for which it is given.  It is not an implied waiver of any other obligation or breach or an implied waiver of that obligation or breach on any other occasion.</p>

    <h3>Part C – End User Licence Agreement (EULA)</h3>

    {<IlegEula/>}

    

	</div>
);

export default IlegTermsAndConditions
