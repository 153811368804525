import React from 'react';

export default function CosmTermsAndConditions() {
	return (
		<div className="terms">
			<h1>
				<b>Terms of Use</b>
			</h1>

			<p>
				<span>
					<b>Effective Date:</b>
				</span>{' '}
				16th September 2024
			</p>

			<h3>1. Acceptance of Terms</h3>
			<p>
				By accessing or using InstantCosmetics website <a href="https://instantcosmetics.com.au/">https://instantcosmetics.com.au/</a> (the “Platform”) and any related services,
				you agree to comply with and be bound by these Terms of Use (the “Terms”) each time you use the Platform. If you do not agree with these Terms, please do not use our
				Platform.
			</p>

			<h3>2. Individual use</h3>
			<p>
				You acknowledge and agree that your login in details are unique and confidential to you and are not to be shared with another person to enable them to use the Platform. In
				the event you employ or contract other health care practitioners to work in your business and you require them to use the Platform, each individual is required to complete
				the onboarding process (including accepting these Terms), prior to using the Platform.
			</p>

			<h3>3. Eligibility, Accreditation and Training</h3>
			<p>
				As a condition of using the Platform, you represent and warrant that you are a registered nurse who is registered with the Australian Health Practitioner Regulation Agency
				(“AHPRA”) to provide health care services in Australia and agree to provide evidence of this registration on request by InstantCosmetics.
			</p>
			<p>
				You agree to maintain your AHPRA accreditation at all times while using the Platform and accessing the services provided by InstantCosmetics. You agree to notify
				InstantCosmetics in the event you are no longer registered with AHPRA and/or you have a condition imposed on your AHPRA registration, in which case InstantCosmetics
				reserves the right to remove your permission to use the Platform.
			</p>
			<p>
				You warrant to InstantCosmetics that you have completed the required training to perform cosmetic injection treatments as required by AHPRA’s “Guidelines for registered
				medical practitioners who perform cosmetic surgery and procedures”
			</p>

			<h3>4. Compliance with Legislation</h3>
			<p>You agree to comply with all relevant legislation applicable to your practice, including but not limited to:</p>
			<ul>
				<li>the Therapeutic Goods Act 1989 (Cth), Therapeutic Goods Regulations 1990 (Cth) and associated regulations including the Therapeutic Goods Advertising Code;</li>
				<li>
					the Health Practitioner Regulation National Law Act 2009 (Cth) (as implemented from state to state) and AHPRA’s “Guidelines for registered medical practitioners who
					perform cosmetic surgery and procedures”;
				</li>
				<li>
					state based drugs and poisons legislation and regulations (including, where relevant, the requirement to carry out procedures only at appropriately licensed facilities);
				</li>
				<li>all local, state, and federal regulations regarding the storage and handling of medicines; and</li>
				<li>
					privacy legislation, including but not limited to the Privacy Act 1988 (Cth) (Privacy Act), Health Records Act 2001 (Vic) and the Health Records and Information Privacy
					Act 2002 (NSW)
				</li>
			</ul>

			<h3>5. Management of adverse events and clinical standards</h3>
			<p>
				You agree to adhere to the management of adverse events protocol and clinical standards developed by InstantCosmetics and in force from time to time. You acknowledge and
				agree that InstantCosmetics reserves the right to update the clinical standards in line with advancements in best practice and notify you of such changes. If you are unable
				to comply with the updated protocols and standards, you must not continue to use the Platform.
			</p>

			<h3>6. Privacy</h3>
			<p>
				If we collect your personal information, we do so subject to the terms of our Privacy Policy, details of which are{' '}
				<a href="https://instascripts.com.au/privacy">InstantScripts - Fast, Safe & Secure Online Prescriptions in Australia.</a>
			</p>
			<p>
				You agree that the medical record of each patient contains Health Information, as defined in the Privacy Act and must be treated with highest level of care and
				confidentiality. To that end you agree to implement procedures and protocols to ensure the Health Information of patients held by you remains strictly confidential at all
				times (including, but not limited to, the implementation of appropriate IT security systems).
			</p>

			<h3>7. Third party websites</h3>
			<p>
				This Platform may contain links to external websites (Third Party Websites). InstantCosmetics does not sponsor, guarantee or approve of any material or representations in
				or linked by Third Party Websites, nor does InstantCosmetics warrant that any materials on Third Party Websites are free from any computer virus, defects or infringements.
				You acknowledge and agree that your use of Third Party Websites is at your own risk.
			</p>

			<h3>8. Termination</h3>
			<p>
				InstantCosmetics reserves the right to suspend or terminate your access to the Platform if you fail to comply with these Terms, including but not limited to failure to
				maintain your registration with AHPRA or non-compliance with relevant legislation.
			</p>

			<h3>9. Changes to Terms</h3>
			<p>
				InstantCosmetics may update or modify these Terms at any time. We will notify you of any significant changes by posting the new Terms on the Platform. Your continued use of
				the Platform after any such changes constitutes your acceptance of the new Terms. If you are unable to accept the updated new Terms, you must cease your use of the
				Platform.
			</p>

			<h3>10. Governing Law</h3>
			<p>
				These Terms shall be governed by and construed in accordance with the laws of Victoria, Australia. You submit to the non-exclusive jurisdiction of the courts of that State.
			</p>

			<h3>11. Contact Information</h3>
			<p>
				For any questions or concerns regarding these Terms or your use of the Platform, please contact us at{' '}
				<a href="mailto:assist@instantcosmetics.com.au ">assist@instantcosmetics.com.au </a>
			</p>
		</div>
	);
}
