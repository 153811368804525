function obj_new(o1, o2, onw) {

	return Object
				.keys(o1)
				.reduce( (diff, key) => (o1[key] === o2[key] || onw[key] === undefined) ? diff : { fro: { ...diff.fro, [key]: o2[key] }, to: { ...diff.to, [key]: onw[key] },  } , {fro:{}, to:{}} );

}

const obj_diff = function (old_obj, new_obj) {


	if (!old_obj)	return { fro:{}, to:{...new_obj} }
	if (!new_obj)	return { fro:{...old_obj}, to:{} }

	// console.log('obj_diff', old_obj, new_obj, {...obj_new(old_obj, new_obj, new_obj), ...obj_new(new_obj, old_obj, new_obj), });

	return {...obj_new(old_obj, new_obj, new_obj), ...obj_new(new_obj, old_obj, new_obj), }

	// return Object
	// 			.keys(new_obj)
	// 			.reduce( (diff, key) => old_obj[key] === new_obj[key] ? diff : { ...diff, [key]: new_obj[key] } , {} );

}


export default obj_diff
