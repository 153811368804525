import React from 'react';
import org_model from 'models/org_model';

const PrescriptionsUpdate = () => {
  const qual_price = org_model.org_conf_itm(app.runtime.org, 'qc_pr') || 34;
  const script_price = org_model.org_conf_itm(app.runtime.org, 'mc_pr') || 19;

  return (
    <div className="terms">
      <h1>
        <b>Online prescriptions update</b>
      </h1>
      <p>New guidelines released by the Medical Board of Australia now require all patients to speak to a doctor prior to accessing online prescriptions.</p>
      <p>This requirement applies to all Australian medical clinics.</p>
      <p>What does this mean for you?</p>
      <p>
        When requesting an online prescription, you may be asked to speak to an InstantScripts doctor so they can review your medical history and ensure the
        medication requested is suitable for you.
      </p>
      <p>
        If approved, you can request a single prescription for that medication online for 12 months in circumstances where you can’t get to your regular doctor.
      </p>
      <p>If you require a different prescription, you will need to have a new telehealth consultation with one of our doctors.</p>
      <p>We are committed to offering our patients the highest quality service while complying with all Australian medical regulations.</p>
      <p>Everyone should have access to the healthcare they need, when they need it, and we strive to deliver fast, convenient access to medical care.</p>

      <p>
        If you would like to learn more about the guidelines from the Medical Board of Australia you can visit their{' '}
        <a className="text-blue-800" target="_blank" href="https://www.medicalboard.gov.au/Codes-Guidelines-Policies.aspx">
          website.
        </a>
      </p>
    </div>
  );
};

export default PrescriptionsUpdate;
