import React, { useState } from "react";
import { useEffect } from "react";
import {
  Popup,
  Icon,
} from "semantic-ui-react";
import evermed_model from "../../models/evermed_model";
import { pick } from "../../helpers/json_functions";

const labels = {
    lineStatus: "Order Status",
    packageStatus: "Delivery Status",
    holdReason: "Hold Reason",
    rejectReason: "Reject Reason",
    comment: "Comment",
    packageDeliveryProvider: "Delivery Partner",
    packageTrackingCode: "Tracking Code",
    packageTrackingUrl: "Tracking Url",
    estimatedEarliestDeliveryDate: "Earliest delivery date",
    estimatedLatestDeliveryDate: "Latest delivery date",
    packageReadiedAt: "Readied At",
    packageDispatchedAt: "Dispatched At",
    packageDeliveredAt: "Delivered At"
}

const defaultEvermedOrderState = {loading: true, show: false, orderDetails: {}, failed: false}

const EvermedOrderDetails = ({orderNumber}) => {
  const [state, setState] = useState(defaultEvermedOrderState);
    useEffect(async () => {
        if(state.loading && state.show && !state.failed){
            const orderDetails = await evermed_model.getOrderStatus({orderNumber})
            if(orderDetails?.res === 'ok' && orderDetails?.order){
                setState({...state, loading: false, orderDetails: pick(orderDetails?.order?.lines[0], Object.keys(labels))})
            } else {
                setState({...state, loading: false, failed: true})
            }
        }
        if(!state.show){
          setState(defaultEvermedOrderState)
        }
    }, [state])
    
  return (
    <Popup
      trigger={
        <Icon
            name="magnify"
          onClick={(_) => setState({...state, show:!state.show})}
        />
      }
      open={state.show}
      position="left center"
      style={{}}
    >
        {state.loading ? 
        <><Icon loading name='spinner' size='small'/> <span>Loading data from Evermed</span></> : 
        <>
        <Popup.Header>
        <Icon
        link
        name="close"
        style={{ float: "right" }}
        onClick={(_) => setState({...state, show:!state.show})}
        />
        Evermed order details
        </Popup.Header>
        <div className="">
           
            <br />
            <Popup.Content>
                {Object.keys(state.orderDetails).map(key => <p key={key} className="m-1"><strong>{labels[key]}:</strong> {state.orderDetails?.[key]}</p>)}
                {state.failed && <p>Could not load the order from Evermed</p>}
            </Popup.Content>
        </div>
        </>
        }
      
    </Popup>
  );
};

export default EvermedOrderDetails;
