import React, { PureComponent } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Confirm,
		TextArea,
		Form
	} from 'semantic-ui-react'

import API_service from 'xAppLib/providers/API_service'

async function act(a, p) { 
	return await API_service.load_data( a, p ) 
}


class Payments extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			msg: "",
			show_confirm: false
		};
	}

	render() {
		const {row, index} = this.props

		const handleSubmit = async () => {
			if(!this.state.msg.trim()){ alert('Please enter a message first!'); return }
			this.setState({['tref_load']:true, show_confirm: false})
			const result = await act('refundPayment', {sid: row.sid, msg: this.state.msg, chr_id: row.paym.capture && row.paym.capture.id || row.paym.charge.id})
			this.setState({
				['tstat'] : result,
				['tref_load']:false
			})
		}

		const toggleRefundWindow = () => this.setState({
				show_confirm: !this.state.show_confirm
		})

		return (
			<React.Fragment>

				{app.user.claims.root && row.paym?.price && !row.paym?.charge &&
					<React.Fragment>
					<Button
						color='yellow'
						onClick={ async _=> {
									this.setState({['tpay_load']:true})
									const result = await act('makePayment', {sid: row.sid}) 
									this.setState({
											['tstat'] : result,
											['tpay_load']:false
										})
								}
								}
						compact
						size='mini'>
						<Icon loading={this.state['tpay_load']} name={this.state['tpay_load'] ? 'spinner' : 'dollar'} /> 
						Make Paymemt
					</Button>
					<br />
					</React.Fragment>
				}
				
				
				{ row.paym?.charge && <React.Fragment>
					
					<Button
							color='green'
							onClick={ async _=> {
										this.setState({['tcap_load']:true})
										const result = await act('capturePayment', {sid: row.sid, chr_id: row.paym.charge.id}) 
										this.setState({
												['tstat'] : result,
												['tcap_load']:false
											})
									}
									}
							style={{marginBottom: '.2em'}}
							compact
							disabled={row.paym_status=='paid'||row.paym_status=='refunded'||this.state['tcap_load']}
							size='mini'>
							<Icon loading={this.state['tcap_load']} name={this.state['tcap_load'] ? 'spinner' : 'bolt'} /> 
							Charge
						</Button>

					<br />
				
				</React.Fragment>}

				
				{(row.paym?.capture?.id || row.paym?.charge?.id) && <React.Fragment>
					{this.state.show_confirm ?
						<>
						<Form className='mt-3'>
							<Form.TextArea 
								placeholder={'Refund Reason/Ref'}
								value={this.state.msg}
								onChange={(e, d)=> this.setState({
									msg: d.value
								})}
								size='tiny'
							/>
							<Form.Group widths={'equal'}>
								<Form.Field>
									<Button
										color={'green'}
										size='mini'
										content='Refund'
										fluid
										onClick={async () => await handleSubmit()}
										/>
									</Form.Field>
									<Form.Field>
									
									<Button
										color={'red'}
										size='mini'
										content='Cancel'
										fluid
										onClick={() => toggleRefundWindow()}
										/>
									</Form.Field>
						
							</Form.Group>
						</Form>
					</>
					:
					<Button
							color='red'
							onClick={() => toggleRefundWindow()}
							compact
							disabled={row.paym_status=='refunded' || this.state['tref_load']}
							size='mini'>
								<Icon loading={this.state['tref_load']} name={this.state['tref_load'] ? 'spinner' : 'gift'} /> 
								Refund
							</Button>
					}
				</React.Fragment>}
				

				
					
				{this.state['tstat']&&this.state['tstat'].status||this.state['tstat']&&this.state['tstat'].error||this.state['tstat']&&this.state['tstat'].refund?.status||''}
			</React.Fragment>
		);
	}

}





export default Payments
