
import React, { Component, useMemo, useState } from 'react'
import { Confirm, Button, Label, Header, Icon, Modal, Segment, Accordion, Table, Grid, Popup, Input } from 'semantic-ui-react'

import PrescribedItemSelector from 'views/erx/PrescribedItemSelector'
import PatientSelector from 'views/erx/PatientSelector'
import ClinicianSelector from 'views/erx/ClinicianSelector'
import ScriptTypeSelector from 'views/erx/ScriptTypeSelector'

const ScriptSummary = ({row,modal,trigger}) => {

	const [open,setOpen] = useState(false)

	const script = useMemo(()=>{

		const { item, clinician, patient, mysl_consent,escript,send_to_pharmacy } = row?.epresc || {}
		if (!item)
			return null
		return {
			item,
			patient,
			clinician,
			escript,
			mysl_consent,
			send_to_pharmacy,
		}
	
	},[row])


	if ( script == null)
		return null

	let content =  <>

			<PrescribedItemSelector
				script={script}
				itemDetails={script.item}
				viewOnlyMode={true}
			/>

			<PatientSelector
				script={script}
				patientDetails={script.patient}
				viewOnlyMode={true}
			/>

			<ClinicianSelector
				script={script}
				clinicianDetails={script.clinician}
				viewOnlyMode={true}
			/>
	</>


	if (modal) {

		content = <Modal
			closeIcon
			open={open}
			onOpen={()=>setOpen(true)}
			onClose={()=>setOpen(false)}
			trigger={trigger||<Button>View Details</Button>}
		>
			<Modal.Header>eScript details</Modal.Header>
			<Modal.Content>
				{content}
			</Modal.Content>
			<Modal.Actions>
				<Button color='green' onClick={()=>setOpen(false)}>
					Close
				</Button>
			</Modal.Actions>
		</Modal>


	}

	return content


}
 



export default ScriptSummary
