import React from 'react';

const DisplayField = ({label,value,children}) => (
	<div className="field">
		<label>{label}</label>
		{value||children}
	</div>
);


export default DisplayField
