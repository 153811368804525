import localstorage_database from "../xAppLib/providers/localstorage_database";
import debounce from "../xAppLib/libs/debounce";

const DEBUG = false;

export class form_persistence_model {
    _uid = null;
    _storageKey = null;
    _formId = null;
    _expiry = null;

    static none() {
        return {
            save: () => {},
            retrieve: () => null,
            clear: () => {}
        }
    }

    constructor(uid, storageKey, formId, expiry_ms = 10 * 60 * 1000) {
        this._uid = uid;
        this._storageKey = storageKey;
        this._formId = formId;
        this._expiry = expiry_ms;

        this.save = debounce(this.save.bind(this), 1000);
    }

    save(form) {
        DEBUG && console.log('form_persistence_model.save', form);

        localstorage_database.update_record(this._storageKey, {
            form,
            uid: this._uid,
            formId: this._formId,
            tm: Date.now()
        })
    }

    retrieve() {
        const data = localstorage_database.get_record(this._storageKey);

        if (this._is_stale(data?.tm)) {
            // Even if this isn't for the same user/formId, it's stale, so remove it
            DEBUG && console.log('form_persistence_model.retrieve[stale]', data);
            this._remove();
            return null;
        }

        if (data?.form && data.uid === this._uid && data.formId === this._formId) {
            DEBUG && console.log('form_persistence_model.retrieve[data]', data);
            return data.form;
        }

        DEBUG && console.log('form_persistence_model.retrieve[none]');
        return null;
    }

    clear() {
        DEBUG && console.log('form_persistence_model.clear');

        this._remove();
    }

    _is_stale(tm) {
        return tm + this._expiry < Date.now();
    }

    _remove() {
        localstorage_database.delete_record(this._storageKey);
    }
}
