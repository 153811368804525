import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Message } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useProfile } from 'xAppLib/Hooks';
import { getClientBookingTimezone } from './shared';
import { FRIENDLY_DATETIME_DAY_MONTH } from './formatting';
import instcons_global_model from '../../models/instcons_global_model';

const AppointmentItem = ({ appointment, onRecordChange }) => {
    useEffect(() => instcons_global_model.watch_record(appointment.sid, onRecordChange), [appointment.sid]);

    const bookingTimeZone = getClientBookingTimezone();
    const time =
        moment(appointment.start).tz(bookingTimeZone)
            .format(FRIENDLY_DATETIME_DAY_MONTH);
    return (
        <Message
            icon="calendar plus outline"
            header={appointment.med_db_data__name}
            className='!items-start'
            content={
                <div className='pt-2'>
                    Appointment on <strong>{time}</strong> <small>({bookingTimeZone}) </small>
                    <br />
                    <div className='flex flex-wrap gap-4 items-baseline'>
                        <Link
                            to={`/docconsreq/${appointment.sid}/${appointment.spd_hash__mobile}`}
                        >
                            <Button size='tiny' primary>Go to appointment</Button>
                        </Link>
                        <Link to={`/contact-us`}>
                            Need help? Contact us
                        </Link>
                    </div>
                </div>
            }
        />
    );
}

const PatientAppointments = () => {
    const [prof] = useProfile();

    const [appointments, setAppointments] = useState(prof?.pts_appointments ?? []);

    useEffect(() => {
        setAppointments(prof?.pts_appointments ?? []);
    }, [prof]);

    const onRecordChange = useCallback((data) => {
        if (data.status !== 'await_doccall' || !data.appointment?.active) {
            setAppointments(appts => appts.filter(({ sid }) => sid !== data.sid));
        } else {
            setAppointments(appts => appts.map(a => {
                if (a.sid === data.sid) {
                    a.start = data.appointment?.start_time;
                    a.end = data.appointment?.end_time;
                }
                return a
            }));
        }
    }, [setAppointments]);

    const total = appointments.length;

    if (!total) {
        return null;
    }

    let panels = null;
    if (total) {
        const remaining = total - 1;
        panels = ([{
            title: `${remaining} more appointment${remaining > 1 ? 's' : ''}`,
            content: {
                content: appointments.slice(1).map(
                    a => <AppointmentItem key={a.appointment_id} appointment={a} onRecordChange={onRecordChange} />
                ),
            }
        }]);
    }

    return (
        <div>
            <AppointmentItem appointment={appointments[0]} onRecordChange={onRecordChange} />
            {total > 1 && (<Accordion panels={panels} fluid />)}
        </div>
    );
}

export default PatientAppointments;
