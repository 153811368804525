import device_model from 'models/device_model'
import logger from 'xAppLib/libs/logger';
import localstorage_database from 'xAppLib/providers/localstorage_database';


const _MODEL_NAME = 'exprms_model';


const DEBUG = false;

const STORE_KEY = 'exprms'

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class exprms_model {

	static get MODEL_NAME() { return _MODEL_NAME; }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------
	static local() {
		return localstorage_database.get_record(STORE_KEY) || {}
	}

	static _exprm
	static get exprm() {
		if (!this._exprm)
			this._exprm = this.local()
		return this._exprm
	}
	static set exprm(val) {
		this._exprm = val
	}
	
	static calc_exprms (exprms, override = {}) {

		let show_exprms = this.exprm;
		DEBUG && console.log("calc_exprms",exprms, show_exprms)
		let need_upd = false;

		const start_sec = new Date(app.timing.init_start).getSeconds()

		// clean up removed experiments and/or variant 
		for (const exprm_key of Object.keys(show_exprms)) {
			const v = show_exprms[exprm_key].n
			if (!(exprm_key in exprms) || (!exprms[exprm_key][v])) {
				DEBUG && console.log("need to delete experiment",exprm_key)
				delete show_exprms[exprm_key]
				this.need_upd = true
			}
		}

		for (const exprm_key of Object.keys(exprms)) {
			if (!(exprm_key in show_exprms)) {
				const variants = Object.keys(exprms[exprm_key])
				const exprm_len = variants.length
				// const exprm_sec_len = 60/exprm_len
				// const exprm_var = Math.floor(start_sec / exprm_sec_len)
				const exprm_var_num = Math.floor(Math.random() * exprm_len)
				const exprm_var = variants[exprm_var_num]
				DEBUG && console.log('experiment', exprm_key, 'ind', exprm_var, 'exprm_len', exprm_len, 'exprm_sec_len', exprm_len, 'val', exprm_var)
				show_exprms[exprm_key] = {
					n:exprm_var,
					t: Math.floor(Date.now() / 1000)
				};
				this.need_upd = true;
			}

			if (exprm_key in override && override[exprm_key] in exprms[exprm_key] && show_exprms[exprm_key].n !== override[exprm_key]) {
				DEBUG && console.log('overriding experiment', exprm_key, 'ind', override[exprm_key], 'val', exprms[exprm_key][override[exprm_key]]);
				show_exprms[exprm_key] = {
					n: override[exprm_key],
					t: Math.floor(Date.now() / 1000)
				};
				this.need_upd = true;
			}
		}
		
		

		if (this.need_upd) {
			this.exprm = {...show_exprms};
			localstorage_database.update_record(STORE_KEY,this.exprm)
		}

		DEBUG && console.log('exprms_model :: calc_exprms ', 'need_upd',this.need_upd, 'dvc', app.dvc, 'exprms', exprms, 'start', app.timing.init_start, 'secs', start_sec, 'need_upd', need_upd, 'show_exprms', show_exprms);

		app.runtime.exprm = this.exprm

		return this.exprm;
	}

	static save_all() {
		localstorage_database.update_record(STORE_KEY,this.exprm)
		this.save_to_dvc()
	}

	static save_to_dvc() {
		if (this.need_upd) {
			device_model.upd_dvc_fld(STORE_KEY, this.exprm)
			DEBUG && console.log('updating dvc exprms', this.exprm)
			this.need_upd = false
		}
	}
	
	static impression(id,v) {
		if (!(v?.n||v))
			return

		logger.usg_log('experience_impression', null,null, {	exp_variant_string: `ISCR-${id}-${v?.n||v}`	})
	}
		
}
