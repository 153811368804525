import React, { Component } from 'react';
import {
	Icon,
	Button,
	Input,
	Card,
	Segment
} from 'semantic-ui-react'
import { toast } from 'react-toastify';

import API_service from '../providers/API_service'

import { getExifOrientation, resetOrientation } from '../libs/image_orient'
import gtm from '../providers/gtm';
import image_to_base64 from '../helpers/image_to_base64';

const DEBUG = false

class TakePhoto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// 'canvas' || 'upload'
			image_source: 'canvas'
		};
		
		this.videoRef = React.createRef()
		this.canvasRef = React.createRef()
		this.fileRef = React.createRef()
	}
	
	componentWillUnmount() {
		this.shutdown()
	}

	// 		--------------------------------		--------------------------------		---------

	init() {
		if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices
					.getUserMedia({ video: true })
					.then(stream => {
						this.videoRef.current.addEventListener("playing", this.videoReady);
						this.videoRef.current.srcObject = stream;
						this.videoRef.current.play();
						this.stream = stream
					});
		} else {
			alert("The device cannot access the camera.")
			this.setState({show:'button'})
		}
	}
	
	videoReady = () => {
		this.setState({videoReady:true})
	}
	
	shutdown() {
		if (!this.stream)
			return
		this.videoRef.current.removeEventListener("playing", this.videoReady);
		
		this.stream.getTracks().forEach(function(track) {
		  track.stop();
		});
		
		this.stream = null
	}
	// 		--------------------------------		--------------------------------		---------

	async upl_photo(img, comn, file_name, index) {
		comn ||= 'Untitled';
		const { target = 'cosm-trt-pts', img_fn = 'snap', include_file_name } = this.props

		this.setState({uploading:true})
		const res = await API_service.load_data(
			`file/photo/store/${target}/${img_fn}`,
			{ img_data: img, img_src: this.state.src, comn, o:this.state.orientation, file_name: include_file_name && file_name },
		)
							
		this.setState({uploading:false})
		const { onPhoto } = this.props

		if (this.props.tag)
			res.tag = this.props.tag
		if (this.props.tag_label)
			res.tag_label = this.props.tag_label

		res.image_source = this.state.image_source

		onPhoto && onPhoto(res)

		DEBUG && console.log('uploaded photo', res);

		this.handleDeleteImg(index);
		gtm.log('','upl_pic');
	}

	show_toast_error(msg) {
		toast.error(msg, {
			containerId: 'default',
			position: 'bottom-center',
			hideProgressBar: true,
			draggable: false,
		});
	}


	async on_image_upload_select(event) {
    const files = event.target.files;

		let images = await Promise.all(Object.values(files).map((file) => image_to_base64(file, this.props.uploadFullSizeImages))).catch((err) =>
			this.show_toast_error(err?.message || String(err))
		);

		images = images.map((img, index) => ({ img, comn: '', file_name: files[index].name }));

		this.setState({
			images,
			show: 'preview',
			image_source: 'upload'
		});
	}

	render_ios_button() {
		// Todo: support selecting multiple images
		return <label className="ui big green button fluid" style={{width:'100%', minWidth: 200, whiteSpace:'nowrap',display:'flex',justifyContent:'center'}}>
			<input ref={this.fileRef} type="file" accept="image/*,.pdf" onChange={async e=>{
					try {
						const file = this.fileRef.current.files[0]

						const img = await new Promise(resolve=>{
							const reader = new FileReader()
							reader.addEventListener("load",  () => {
								resolve(reader.result)
							}, false);

						  if (file) {
								reader.readAsDataURL(file);

						  }
						})
						this.setState({show:'preview',images: [{ img, comn: '' }], src:img })

						const orientation = await getExifOrientation(file)
						DEBUG && console.log("orientation",orientation);
						this.setState({orientation})
						const copy = await resetOrientation(img,orientation)
						DEBUG && console.log("copied");
						this.setState({ images: [{ img: copy, comn: '' }]})
					} catch (e) {
						DEBUG && console.log("error",e);
						this.setState({ show:'button', images: [] })
					}

				}
			}
			style={{
					width:'0.1px',
					height:'0.1px',
					opacity: 0,
					overflow:'hidden',
					position: 'absolute',
					zIndex: -1
				}} />
			
				<i className="ui camera icon"></i>
				Upload Image
			</label>
	}
	
	render_canvas_button() {
		return (
			<div>
				{this.props.enableCameraUpload && (
					<Button
						size='big'
						style={{whiteSpace:'nowrap',display:'flex',justifyContent:'center'}}
						fluid
						type="button"
						color='green'
						content="Take Photo"
						icon='camera'
						onClick={_=>{this.setState({show:'canvas'}, () => this.init())}}
					/>
				)}

				{ this.props.enableImageUpload && (
					<React.Fragment>
						<Button
							size='big'
							type="button"
							style={{ whiteSpace:'nowrap',display:'flex',justifyContent:'center', marginTop: 10}}
							fluid
							color='green'
							content="Upload Photo"
							icon='upload'
							onClick={_ => { document.getElementById('form-image-uploader-input').click() }}
						/>

						<input
							type="file"
							accept="image/*,.pdf"
							style={{ display: 'none' }}
							id="form-image-uploader-input"
							multiple={this.props.multiple}
							onChange={(e) => this.on_image_upload_select(e)}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}

	render_canvas() {
		return <div style={{position:'relative', width: 290, height: 217, border: '1px solid #e1e1e1', borderRadius: 0 }}>
				<video ref={this.videoRef} width="640" height="480" autoPlay style={{maxWidth:'100%',height:'auto'}}></video>
				{this.state.videoReady && <Button color="green" style={{position:'absolute',left:'50%',bottom:'0%',transform:'translate(-50%, -50%)',zIndex:10}} onClick={_=>{
						this.canvasRef.current.getContext('2d').drawImage(this.videoRef.current, 0, 0, 640, 480);
						const img = this.canvasRef.current.toDataURL("image/jpeg");
						this.shutdown()
						this.setState({show:'preview',img})
					}}><Icon name='camera' /> Take Photo</Button>}
				<canvas ref={this.canvasRef} width="640" height="480"  style={{position:'absolute',opacity:.5,left:0,width:'100%',height:'auto',zIndex:8}} ></canvas>
			</div>
	}

	handleDeleteImg(index) {
		const images = this.state.images.filter((_, i) => i !== index);

		this.setState({ images, show: images.length ? 'preview' : 'button' });
	}

	handleComnChange(text, index) {
		this.state.images[index].comn = text;
		this.setState({ images: this.state.images })
	}
	
	render_preview() {
		return (
			<Card.Group> 
				{this.state.images?.map(({ img, comn, file_name }, index) => (
					<Card style={{ width: 290 }} key={index}>
						<div
							style={{
								width: 290,
								height: 217,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
								backgroundImage: `url('${img}')`
							}}
						></div>
						<Button
							circular
							color='red'
							icon='delete'
							style={{ position:'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 35, height: 35, right:5,top:5}}
							onClick={() => this.handleDeleteImg(index)}
						/>
						<Card.Content style={{ padding: '25px 10px', display: 'flex' }}>
							<Input
								style={{ marginRight: 5, flex: '1 1 auto' }}
								placeholder='Comment'
								value={comn}
								onChange={(_, { value }) => this.handleComnChange(value, index)}
								className="full-width-input"
							/>
						<Button size='small' color="green" onClick={() => { this.upl_photo(img, comn, file_name, index) }} content="Save" />
						</Card.Content>
					</Card>
				))}
		</Card.Group> 
		)
	}

	render_button() {
		return app.settings.is_mob ? this.render_ios_button() : this.render_canvas_button()
	}

	render() {
		const { onPhoto, inline=false } = this.props
		const { show = 'button' } = this.state
		
		return (
			<Segment basic loading={this.state.uploading} style={{padding:0, height: '100%', width:inline?'100%':'auto'}}>
				{ show=='button' && this.render_button() }
				{ show=='preview' && this.render_preview() }
				{ show=='canvas' && this.render_canvas() }
			</Segment>
		)
	}
}

export default TakePhoto;
