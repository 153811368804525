/* eslint-env node */
//	------------------------	------------------------	------------------------
//	Description: properties of different components in the escript
//	------------------------	------------------------	------------------------
//
// Based on http://erx.com.au/integration/v1 and eRX payload schema def v26
// mandatory - custom checks not specified in the xsd
// For mandatory checks, we deliberatly use !(a == b) instead of a != b, as this handles undefined properly
// blank key is similar to default setting, allows for changing non-nullable fields to some inital value, like 0 or '' or false. Default value will be applied after
//
// Updated to Schema 29 - 2020/06/29
// minOccurs:0 - means don't init it (since can be absent)
// nillable: true means not mandatory (Set i:nil="true"), still init to null

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const j_f = require('../../../helpers/json_functions')
const erx_enums = require('./erx_enums')

//	------------------------	------------------------	------------------------
//	Props
//	------------------------	------------------------	------------------------


module.exports = {
    SCRIPT_PROPS: [
        {name: '$',                         label: 'XML root',                  fe_show: false, fe_edit: false, path: ['ePrescription', '$'],                                                                                     use_for_compare: false,},                                  // xml garbage
        {name: 'SCID',                      label: 'Script ID',                 fe_show: false, fe_edit: false, path: ['ePrescription', 'SCID', '[0]'],                    format:'erx_scid',                                     use_for_compare: true, },                        // Tested
        {name: 'GUID',                      label: 'Global ID',                 fe_show: false, fe_edit: false, path: ['ePrescription', 'GUID', '[0]'],                    format:'erx_guid',                     minOccurs: 0,   use_for_compare: false,},                        // Not tested
        {name: 'CreatedDate',               label: 'Created Date',              fe_show: false, fe_edit: false, path: ['ePrescription', 'CreatedDate', '[0]'],             format:'xml_datetime',                 minOccurs: 0,   use_for_compare: false,},                 // Not tested
        {name: 'OriginalSCID',              label: 'Original Script ID',        fe_show: false, fe_edit: false, path: ['ePrescription', 'OriginalSCID', '[0]'],            format:'erx_scid',     nillable: true, minOccurs: 0,   use_for_compare: false,},                // Not tested
        {name: 'OriginalGUID',              label: 'Original Global ID',        fe_show: false, fe_edit: false, path: ['ePrescription', 'OriginalGUID', '[0]'],            format:'erx_guid',     nillable: true, minOccurs: 0,   use_for_compare: false,},                        // Not tested
        {name: 'NotificationConsentFlag',   label: 'Notification Consent',      fe_show: false, fe_edit: false, path: ['ePrescription', 'NotificationConsentFlag', '[0]'], format:'boolean',                      minOccurs: 0,   use_for_compare: false,},     // Not tested
        {name: 'Status',                    label: 'Status',                    fe_show: false, fe_edit: false, path: ['ePrescription', 'Status', '[0]'],                  format:'string',                       minOccurs: 0,   use_for_compare: false,},                      // Not tested
        {name: 'PrescriberToNotify',        label: 'Prescriber To Notify',      fe_show: false, fe_edit: false, path: ['ePrescription', 'PrescriberToNotify', '[0]'],      format:'string',       nillable: true, minOccurs: 0,   use_for_compare: false,},          // Not tested
        {name: 'ETPIdentifiers',            label: 'ETP Identifiers',           fe_show: false, fe_edit: false, path: ['ePrescription', 'ETPIdentifiers', '[0]'],                                                 minOccurs: 0,   use_for_compare: false,},          // Not tested - type not ready
        {name: 'Item',                      label: 'Item',                      fe_show: false, fe_edit: false, path: ['ePrescription', 'Items', '[0]', 'Item'],                                                  minOccurs: 0,   use_for_compare: false,},               // Tested     - You probably want to use get_item, which is a nicer wrapper - updated 20200617 - stupid xml, comparison will occur using get_item instead
        {name: 'SourceType',                label: 'Source Type',               fe_show: false, fe_edit: false, path: ['ePrescription', 'SourceType', '[0]'],              format:'string',       nillable: true, minOccurs: 0,   use_for_compare: false,},                  // Not tested
        {name: 'ConsolidatedSCIDs',         label: 'Consolidated Script IDs',   fe_show: false, fe_edit: false, path: ['ePrescription', 'ConsolidatedSCIDs', '[0]'],       format:'string',       nillable: true, minOccurs: 0,   use_for_compare: false,},           // Not tested
        {name: 'ViewConsent',               label: 'View Consent',              fe_show: false, fe_edit: false, path: ['ePrescription', 'ViewConsent', '[0]'],             format:'string',       nillable: true, minOccurs: 0,   use_for_compare: false,},                 // Not tested
        {name: 'PatientRef',                label: 'Patient Reference',         fe_show: false, fe_edit: false, path: ['ePrescription', 'PatientRef', '[0]'],                                                     minOccurs: 0,   use_for_compare: false,},            // Not tested - seems this shouldn't be here, according to xsd?
        {name: 'MedchartMetadata',          label: 'Medchart Metadata',         fe_show: false, fe_edit: false, path: ['ePrescription', 'MedchartMetadata', '[0]'],                                               minOccurs: 0,   use_for_compare: false,},            // Not tested
        {name: 'Tags',                      label: 'Tags',                      fe_show: false, fe_edit: false, path: ['ePrescription', 'Tags', '[0]'],                                           nillable: true, minOccurs: 0,   use_for_compare: false,},                        // Not tested - nillable = 1?, type not ready
        {name: 'OperationResult',           label: 'Operation Result',          fe_show: false, fe_edit: false, path: ['ePrescription', 'OperationResult', '[0]'],                                                minOccurs: 0,   use_for_compare: false,},             // Not tested, type not ready
    ],

    //	------------------------	------------------------	------------------------

    SCRIPT_PROPS_IGNORE: [  // These are the props that should not be initialised. Otherwise the messages to erx adapter fail. I assume because we shouldn't access them or they are system generated
    //     //'GUID',
    //     'OriginalSCID',
        'MedchartMetadata',
    //     'OperationResult',
         'PatientRef',
    ],

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    ITEM_PROPS: [
        {name: 'Sequence',                  label: 'Sequence',              fe_show: false, fe_edit: false, path: ['Sequence', '[0]'],              format:'unumber',                                        use_for_compare: true ,},                      // Tested
        {name: 'OriginalSequence',          label: 'Original Sequence',     fe_show: false, fe_edit: false, path: ['OriginalSequence', '[0]'],      format:'unumber',          nillable: true, minOccurs: 0, use_for_compare: false,},              // eRx adapter doesn't seem to like us providing this even blank
        {name: 'State',                     label: 'State',                 fe_show: false, fe_edit: false, path: ['State', '[0]'],                 format:'item_state_enum',                               use_for_compare: true ,},                         // Tested - probably update this to the enum
        {name: 'SessionKey',                label: 'Session Key',           fe_show: false, fe_edit: false, path: ['SessionKey', '[0]'],                                      nillable: true, minOccurs: 0, use_for_compare: false,},                    // Not tested - type in class
        {name: 'Content',                   label: 'Content',               fe_show: false, fe_edit: false, path: ['Content', '[0]'],                                                                       use_for_compare: false,},                       // Tested     - You probably want to use the get_content for nicer wrapping - compare will come elsewhere
        {name: 'Tags',                      label: 'Tags',                  fe_show: false, fe_edit: false, path: ['Tags', '[0]'],                                            nillable: true, minOccurs: 0, use_for_compare: false,},                          // Not tested, type not implemented
        {name: 'UpdatedBy',                 label: 'Updated By',            fe_show: false, fe_edit: false, path: ['UpdatedBy', '[0]'],             format:'string',          nillable: true, minOccurs: 0, use_for_compare: false,},                     // Not tested
        {name: 'UpdatedDate',               label: 'Updated Date',          fe_show: false, fe_edit: false, path: ['UpdatedDate', '[0]'],           format:'xml_datetime',    nillable: true, minOccurs: 0, use_for_compare: false,},                   // eRx adapter doesn't seem to like us providing this even blank, dont know type
        {name: 'DispensedDate',             label: 'Dispensed Date',        fe_show: false, fe_edit: false, path: ['DispensedDate', '[0]'],         format:'xml_datetime',    nillable: true, minOccurs: 0, use_for_compare: false,},                 // eRx adapter doesn't seem to like us providing this even blank, dont know type
        {name: 'CreatorVendorID',           label: 'Creator Vendor ID',     fe_show: false, fe_edit: false, path: ['CreatorVendorID', '[0]'],       format:'numeric',         nillable: true, minOccurs: 0, use_for_compare: false,},               // eRx adapter doesn't seem to like us providing this even blank, dont know type
        {name: 'DispenserVendorID',         label: 'Dispenser Vendor ID',   fe_show: false, fe_edit: false, path: ['DispenserVendorID', '[0]'],     format:'numeric',         nillable: true, minOccurs: 0, use_for_compare: false,},             // eRx adapter doesn't seem to like us providing this even blank, dont know type
    ],

    //	------------------------	------------------------	------------------------

    ITEM_PROPS_IGNORE: [ // These are the props that should not be initialised. Otherwise the messages to erx adapter fail. I assume because we shouldn't access them or they are system generated
        //'OriginalSequence',
        //'UpdatedDate',
        //'DispensedDate',
        //'CreatorVendorID',
        //'DispenserVendorID',
    ],

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    CONTENT_PROPS: [
        {name: 'Patient',                   label: 'Patient',               fe_show: false, fe_edit: false, path: ['Patient', '[0]'],                          use_for_compare: false ,},                        // Not tested - You probably want to use the get_patient for nicer wrapping, content compare done interally
        {name: 'Clinician',                 label: 'Clinician',             fe_show: false, fe_edit: false, path: ['Clinician', '[0]'],                        use_for_compare: false ,},                        // Not tested - You probably want to use the get_clinician for nicer wrapping, content compare done interally
        {name: 'PrescribedItem',            label: 'Prescribed Item',       fe_show: false, fe_edit: false, path: ['PrescribedItem', '[0]'],                   use_for_compare: false ,},                        // Not tested - You probably want to use the get_prescribeditem for nicer wrapping, content compare done interally
        {name: 'Pharmacy',                  label: 'Pharmacy',              fe_show: false, fe_edit: false, path: ['Pharmacy', '[0]'],          minOccurs: 0,  use_for_compare: false ,},                        // Not tested - s29, not implemented
        {name: 'DispensedItem',             label: 'Dispensed Item',        fe_show: false, fe_edit: false, path: ['DispensedItem', '[0]'],     minOccurs: 0,  use_for_compare: false ,},                        // Not tested - s29, not implemented
        {name: 'DispensedPatient',          label: 'Dispensed Patient',     fe_show: false, fe_edit: false, path: ['DispensedPatient', '[0]'],  minOccurs: 0,  use_for_compare: false ,},                        // Not tested - s29, not implemented
        {name: 'LastDispensedItem',         label: 'Last Dispensed Item',   fe_show: false, fe_edit: false, path: ['LastDispensedItem', '[0]'], minOccurs: 0,  use_for_compare: false ,},                        // Not tested - s29, not implemented
        {name: 'TokenMetadata',             label: 'Token Metadata',        fe_show: false, fe_edit: false, path: ['TokenMetadata', '[0]'],     minOccurs: 0,  use_for_compare: false ,},                        // Not tested - s29, not implemented
    ],

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    PATIENT_PROPS: [
        {name: 'PatientUniqueID',           label: 'Unique ID',                         path: ['PatientUniqueID', '[0]'],                  fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:20,                       use_for_compare: true ,                                     },                    // Not tested
        {name: 'PatientNumber',             label: 'Patient Number',                    path: ['PatientNumber', '[0]'],                    fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:20,                       use_for_compare: true ,                                     },                    // Not tested
        {name: 'PatientFamilyName',         label: 'Family Name',                       path: ['PatientFamilyName', '[0]'],                fe_extended: false, fe_show: true,  fe_edit: false, format:'string',        length:50,                       use_for_compare: true ,       mandatory: (p) => {return true}},                     // Not tested
        {name: 'PatientFirstName',          label: 'First Name',                        path: ['PatientFirstName', '[0]'],                 fe_extended: false, fe_show: true,  fe_edit: false, format:'string',        length:50,                       use_for_compare: true ,       mandatory: (p) => {return true}},              // Not tested
        {name: 'PatientMedicareFamilyName', label: 'Family Name (Medicare)',            path: ['PatientMedicareFamilyName', '[0]'],        fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,                       use_for_compare: true ,                                     },                    // Not tested
        {name: 'PatientMedicareFirstName',  label: 'First Name (Medicare)',             path: ['PatientMedicareFirstName', '[0]'],         fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,                       use_for_compare: true ,                                     },                    // Not tested - 51 is table, 50 in xsd
        {name: 'PatientTitle',              label: 'Title',                             path: ['PatientTitle', '[0]'],                     fe_extended: false, fe_show: false, fe_edit: true,  format:'string',        length:30,                       use_for_compare: true ,                                     },                     // Not tested
        {name: 'PatientAddress1',           label: 'Address',                           path: ['PatientAddress1', '[0]'],                  fe_extended: false, fe_show: true,  fe_edit: true,  format:'string',        length:50,                       use_for_compare: true ,       mandatory: (p) => {return true}},                     // Not tested
        {name: 'PatientAddress2',           label: 'Address',                           path: ['PatientAddress2', '[0]'],                  fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,                       use_for_compare: true ,                                     },                    // Not tested
        {name: 'PatientSuburb',             label: 'Suburb',                            path: ['PatientSuburb', '[0]'],                    fe_extended: false, fe_show: true,  fe_edit: true,  format:'string',        length:50,                       use_for_compare: true ,       mandatory: (p) => {return true}},                     // Not tested
        {name: 'PatientPostcode',           label: 'Postcode',                          path: ['PatientPostcode', '[0]'],                  fe_extended: false, fe_show: true,  fe_edit: true,  format:'numeric',       length:4,                        use_for_compare: true ,       mandatory: (p) => {return true}},                     // Not tested - length changed to null as per 29
        {name: 'PatientState',              label: 'State',                             path: ['PatientState', '[0]'],                     fe_extended: false, fe_show: true,  fe_edit: true,  format:'state_enum',    length:3,                        use_for_compare: true ,       mandatory: (p) => {return true}},                     // Not tested
        {name: 'PatientSex',                label: 'Sex',                               path: ['PatientSex', '[0]'],                       fe_extended: false, fe_show: true,  fe_edit: false, format:'sex_enum',      length:1,                        use_for_compare: true ,       mandatory: (p) => {return true}},                     // Not tested
        {name: 'PatientPhoneNumber',        label: 'Phone Number',                      path: ['PatientPhoneNumber', '[0]'],               fe_extended: false, fe_show: true,  fe_edit: true,  format:'numeric',       length:20,                       use_for_compare: true ,                                     },                                            // Not tested
        {name: 'PatientMobileNumber',       label: 'Mobile Number',                     path: ['PatientMobileNumber', '[0]'],              fe_extended: false, fe_show: true,  fe_edit: true,  format:'numeric',       length:20,                       use_for_compare: true ,                                     },                                            // Not tested
        {name: 'PatientEmail',              label: 'Email',                             path: ['PatientEmail', '[0]'],                     fe_extended: false, fe_show: true,  fe_edit: true,  format:'string',        length:50,                       use_for_compare: true ,                                     },                                            // Not tested 
        {name: 'PatientMedicareNumber',     label: 'Medicare Number',                   path: ['PatientMedicareNumber', '[0]'],            fe_extended: false, fe_show: true,  fe_edit: false, format:'numeric',       length:20,                       use_for_compare: true ,       mandatory: (p) => {return p.patient && !(p.item && p.item.PrivatePrescription == true) && !(p.patient.DVACardColour == erx_enums.PATIENT_ENUM.DVA_CARD.Gold)}},           // Not tested, s29 not include length but still set
        {name: 'PatientMedicareSubNumerate',label: 'Medicare Sub Numerate',             path: ['PatientMedicareSubNumerate', '[0]'],       fe_extended: false, fe_show: true,  fe_edit: false, format:'numeric',       length:3,                        use_for_compare: true ,       mandatory: (p) => {return p.patient && !(p.item && p.item.PrivatePrescription == true) && !(p.patient.DVACardColour == erx_enums.PATIENT_ENUM.DVA_CARD.Gold)}},           // Not tested, s29 not include length but still set
        {name: 'PatientMedicareValidTo',    label: 'Medicare Valid To',                 path: ['PatientMedicareValidTo', '[0]'],           fe_extended: false, fe_show: true,  fe_edit: false, format:'xml_date',      length:null,     nillable: true, use_for_compare: true ,                                     },//(p) => {return p && !(p.PatientMedicareNumber == '')}},                   // Not tested. Schema indicates nillable, so maybe some cards dont have
        {name: 'Concession_PensionNumber',  label: 'Concession / Pension Number',       path: ['Concession_PensionNumber', '[0]'],         fe_extended: false, fe_show: true,  fe_edit: false, format:'cons_num',      length:20,                       use_for_compare: true ,                                     },                                            // Not tested
        {name: 'EntitlementNumber',         label: 'Entitlement Number',                path: ['EntitlementNumber', '[0]'],                fe_extended: false, fe_show: true,  fe_edit: false, format:'safe_num',      length:20,                       use_for_compare: true ,                                     },                                            // Not tested
        {name: 'DVANumber',                 label: 'DVA Number',                        path: ['DVANumber', '[0]'],                        fe_extended: false, fe_show: true,  fe_edit: false, format:'dva_num',       length:10,                       use_for_compare: true ,       mandatory: dva_mandatory},                         // Not tested // According to https://meteor.aihw.gov.au/content/index.phtml/itemId/339127 it should be 9 long
        {name: 'DVACardColour',             label: 'DVA Card Colour',                   path: ['DVACardColour', '[0]'],                    fe_extended: false, fe_show: true,  fe_edit: false, format:'dva_card_enum', length:10,                       use_for_compare: true ,       mandatory: (p) => {return p.patient && !(p.patient.DVANumber == '' || p.patient.DVANumber == null)}},                             // Not tested
        {name: 'PatientBirthdate',          label: 'Birthdate',                         path: ['PatientBirthdate', '[0]'],                 fe_extended: false, fe_show: true,  fe_edit: false, format:'xml_date',      length:null,     nillable: true, use_for_compare: true ,       mandatory: (p) => {return true}},                                             // Not tested
        {name: 'PatientCTGFlag',            label: 'CTG Flag',                          path: ['PatientCTGFlag', '[0]'],                   fe_extended: false, fe_show: true,  fe_edit: true,  format:'boolean',       length:null,     minOccurs: 0,   use_for_compare: true ,                                     },                                             // Not tested
        {name: 'PatientIHI',                label: 'Individual Healthcare Identifier',  path: ['PatientIHI', '[0]'],                       fe_extended: false, fe_show: true,  fe_edit: false, format:'numeric',       length:19,       minOccurs: 0,   use_for_compare: true ,       mandatory: (p) => {return !!p.escript}                               },                                             // Not tested - table says string, xsd long - go with xsd
        {name: 'PatientHospitalCategory',   label: 'Hospital Category',                 path: ['PatientHospitalCategory', '[0]'],          fe_extended: false, fe_show: false, fe_edit: false, format:'boolean',       length:null,     minOccurs: 0,   use_for_compare: true ,                                     },                                            // Not tested, updated - causes issues with schema validation. Order change fixes issues.
        {name: 'Patient_Reserved_01',       label: 'Reserved 1',                        path: ['Patient_Reserved_01', '[0]'],              fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,       minOccurs: 0,   use_for_compare: false,                                     },                                            // Not tested
        {name: 'Patient_Reserved_02',       label: 'Reserved 2',                        path: ['Patient_Reserved_02', '[0]'],              fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,       minOccurs: 0,   use_for_compare: false,                                     },                                            // Not tested
        {name: 'Patient_Reserved_03',       label: 'Reserved 3',                        path: ['Patient_Reserved_03', '[0]'],              fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,       minOccurs: 0,   use_for_compare: false,                                     },                                            // Not tested
        {name: 'Patient_Reserved_04',       label: 'Reserved 4',                        path: ['Patient_Reserved_04', '[0]'],              fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,       minOccurs: 0,   use_for_compare: false,                                     },                                            // Not tested
        {name: 'Patient_Reserved_05',       label: 'Reserved 5',                        path: ['Patient_Reserved_05', '[0]'],              fe_extended: false, fe_show: false, fe_edit: false, format:'string',        length:50,       minOccurs: 0,   use_for_compare: false,                                     },                                            // Not tested
    ],

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    CLINICIAN_PROPS: [

        {name: 'DoctorPrescriberNumber',    label: 'Prescriber Number',     fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorPrescriberNumber', '[0]'],          format:'numeric',       length:10,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'DoctorFirstName',           label: 'First Name',            fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorFirstName', '[0]'],                 format:'string',        length:30,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'DoctorFamilyName',          label: 'Family Name',           fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorFamilyName', '[0]'],                format:'string',        length:30,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'DoctorProviderNumber',      label: 'Provider Number',       fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorProviderNumber', '[0]'],            format:'string',        length:10,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'DoctorMobileNumber',        label: 'Mobile Number',         fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorMobileNumber', '[0]'],              format:'numeric',       length:10,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      - updated as per example xmls and int in xsd
        {name: 'DoctorEmail',               label: 'Email',                 fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorEmail', '[0]'],                     format:'string',        length:50,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PracticeName',              label: 'Practice Name',         fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticeName', '[0]'],                    format:'string',        length:50,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PracticeAddress1',          label: 'Practice Address',      fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticeAddress1', '[0]'],                format:'string',        length:50,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PracticeAddress2',          label: 'Practice Address',      fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticeAddress2', '[0]'],                format:'string',        length:50,                          use_for_compare: true ,                                       },                   // Not tested      
        {name: 'PracticeSuburb',            label: 'Practice Suburb',       fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticeSuburb', '[0]'],                  format:'string',        length:50,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PracticePostcode',          label: 'Practice Postcode',     fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticePostcode', '[0]'],                format:'string',        length:4,                           use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested - length changed to null as per 29. Then back to 4. Yes the schema is string not integer.
        {name: 'PracticeState',             label: 'Practice State',        fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticeState', '[0]'],                   format:'state_enum',    length:3,                           use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PracticeEmail',             label: 'Practice Email',        fe_extended: false, fe_show: true,  fe_edit: false, path: ['PracticeEmail', '[0]'],                   format:'string',        length:50,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested       - the email length restriction isn't in the xsd
        {name: 'DoctorPhoneNumber',         label: 'Phone Number',          fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorPhoneNumber', '[0]'],               format:'numeric',       length:20,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      - int in xsd, length not specified
        {name: 'DoctorFaxNumber',           label: 'Fax Number',            fe_extended: false, fe_show: true,  fe_edit: false, path: ['DoctorFaxNumber', '[0]'],                 format:'numeric',       length:20,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      - int in xsd, length not specified
        {name: 'PrescribingSystemUsed',     label: 'Prescribing System',    fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescribingSystemUsed', '[0]'],           format:'string',        length:30,                          use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PrescriberType',            label: 'Prescriber Type',       fe_extended: false, fe_show: true,  fe_edit: false, path: ['PrescriberType', '[0]'],                  format:'pres_enum',     length:1,       minOccurs: 0,       use_for_compare: true ,        mandatory: (p) => {return true}},             // Not tested      
        {name: 'PrescriberHPIO',            label: 'HPIO',                  fe_extended: false, fe_show: true,  fe_edit: false, path: ['PrescriberHPIO', '[0]'],    blank:"0",    format:'numeric',       length:19,      minOccurs: 0,       use_for_compare: true ,        mandatory: (p) => {return true}},                    // Not tested      // Mandatory - ! and == true deliberatly used instead of == false
        {name: 'PrescriberHPII',            label: 'HPII',                  fe_extended: false, fe_show: true,  fe_edit: false, path: ['PrescriberHPII', '[0]'],    blank:"0",    format:'numeric',       length:19,      minOccurs: 0,       use_for_compare: true ,        mandatory: (p) => {return true}},                    // Not tested      // Mandatory - ! and == true deliberatly used instead of == false
        {name: 'PrescriberAHPRANumber',     label: 'AHPRA Number',          fe_extended: false, fe_show: true,  fe_edit: false, path: ['PrescriberAHPRANumber', '[0]'],           format:'string',        length:13,      minOccurs: 0,       use_for_compare: true ,        mandatory: (p) => {return p.item && !(p.item.PrivatePrescription == true)}},                    // Not tested      // Mandatory - ! and == true deliberatly used instead of == false
        {name: 'Clinician_Reserved_01',     label: 'Reserved 1',            fe_extended: false, fe_show: true,  fe_edit: false, path: ['Clinician_Reserved_01', '[0]'],           format:'string',        length:50,                          use_for_compare: false,        mandatory: (p) => {return true}},                    // Not tested - s29 does not have this as min occurs
        {name: 'Clinician_Reserved_02',     label: 'Reserved 2',            fe_extended: false, fe_show: false, fe_edit: false, path: ['Clinician_Reserved_02', '[0]'],           format:'string',        length:50,      minOccurs: 0,       use_for_compare: false,                                       },                    // Not tested      
        {name: 'Clinician_Reserved_03',     label: 'Reserved 3',            fe_extended: false, fe_show: false, fe_edit: false, path: ['Clinician_Reserved_03', '[0]'],           format:'string',        length:50,      minOccurs: 0,       use_for_compare: false,                                       },                    // Not tested      
        {name: 'Clinician_Reserved_04',     label: 'Reserved 4',            fe_extended: false, fe_show: false, fe_edit: false, path: ['Clinician_Reserved_04', '[0]'],           format:'string',        length:50,      minOccurs: 0,       use_for_compare: false,                                       },                    // Not tested     
        {name: 'Clinician_Reserved_05',     label: 'Reserved 5',            fe_extended: false, fe_show: false, fe_edit: false, path: ['Clinician_Reserved_05', '[0]'],           format:'string',        length:50,      minOccurs: 0,       use_for_compare: false,                                       },                    // Not tested     
        
    ],

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------



    PRESCRIBED_ITEM_PROPS: [
        // Based on http://erx.com.au/integration/v1
        {name: 'PrescriptionDate',                              label: 'Prescription Date',                                     fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescriptionDate', '[0]'],                                 format:'xml_datetime',  length:null,                                  use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'ItemUniqueID',                                  label: 'Unique ID',                                             fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['ItemUniqueID', '[0]'],                                     format:'string',        length:null,                                  use_for_compare: true ,                                     },                   // Do not use
        {name: 'ItemTradeName',                                 label: 'Trade Name',                                            fe_group:    0, fe_order:    1, fe_extended: false, fe_show: true,  fe_edit: is_extemp,  path: ['ItemTradeName', '[0]'],                                    format:'string',        length:280,                                   use_for_compare: true ,       mandatory: (p) => {return p.item && (p.item.BrandSubstitutionNotAllowed || (p.item.ItemGenericIntention == erx_enums.PRESCRIBED_ENUM.GENERIC_INTENTION.Brand))}, disableForNP: true},                    // Not tested - table says length 50 but xsd 200, s29 280
        {name: 'ItemGenericName',                               label: 'Generic Name',                                          fe_group:    0, fe_order:    2, fe_extended: false, fe_show: true,  fe_edit: is_extemp,  path: ['ItemGenericName', '[0]'],                                  format:'string',        length:280,                                   use_for_compare: true ,       mandatory: (p) => {return true}, disableForNP: true},                    // Not tested - as above
        {name: 'ItemForm',                                      label: 'Form',                                                  fe_group:    0, fe_order:    3, fe_extended: false, fe_show: true,  fe_edit: is_extemp,  path: ['ItemForm', '[0]'],                                         format:'string',        length:50,                                    use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested - 30 -> 50 (s29)
        {name: 'ItemStrength',                                  label: 'Strength',                                              fe_group:    0, fe_order:    4, fe_extended: false, fe_show: true,  fe_edit: is_extemp,  path: ['ItemStrength', '[0]'],                                     format:'string',        length:100,                                   use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested - 20 -> 100 (s29)
        {name: 'ItemGenericIntention',                          label: 'Generic Intention',                                     fe_group:    0, fe_order:  1002, fe_extended: true,  fe_show: true,  fe_edit: (p) => {!is_extemp(p)},  path: ['ItemGenericIntention', '[0]'],           format:'gen_enum',      length:1,                                     use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'BrandSubstitutionNotAllowed',                   label: 'No Brand Substitution',                                 fe_group:    0, fe_order:  1003, fe_extended: true,  fe_show: true,  fe_edit: (p) => {!is_extemp(p)},  path: ['BrandSubstitutionNotAllowed', '[0]'],    format:'boolean',       length:null,                                  use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'PBSCode',                                       label: 'PBS Code',                                              fe_group:    1, fe_order:    1, fe_extended: false, fe_show: true,  fe_edit: false,  path: ['PBSCode', '[0]'],                                          format:'string',        length:6,                                     use_for_compare: true ,       mandatory: (p) => {return p.item && !(p.item.PrivatePrescription == true)}}, // Not tested - unless private
        {name: 'PBSManufacturerCode',                           label: 'PBS Manufacturer Code',                                 fe_group:    1, fe_order:    2, fe_extended: true,  fe_show: true,  fe_edit: false,  path: ['PBSManufacturerCode', '[0]'],                              format:'string',        length:2,                                     use_for_compare: true ,       mandatory: (p) => {return p.item && (!(p.item.PrivatePrescription == true) && !(p.item.ItemGenericIntention == erx_enums.PRESCRIBED_ENUM.GENERIC_INTENTION.Generic) )}}, // Not tested - unless private
        {name: 'Quantity',                                      label: 'Quantity',                                              fe_group:    0, fe_order:    5, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['Quantity', '[0]'],                                         format:'unumber',        length:5,                                     use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested, no length in schema
        {name: 'UnusualQtyFlag',                                label: 'Unusual Quantity',                                      fe_group:    0, fe_order: 2000, fe_extended: true, fe_show: true,  fe_edit: false, path: ['UnusualQtyFlag', '[0]'],                                   format:'boolean',       length:null,    blank: false,                 use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'ItemBarcode',                                   label: 'Barcode',                                               fe_group:    0, fe_order: 1000, fe_extended: true,  fe_show: true,  fe_edit: true,  path: ['ItemBarcode', '[0]'],                                      format:'numeric',       length:24,                                    use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested // schema likes this set to 0 // although length should be 30, 24 seems max for our validator
        {name: 'RouteAdministration',                           label: 'Route Of Administration',                               fe_group:    0, fe_order:    8, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['RouteAdministration', '[0]'],                              format:'string',        length:20,                                    use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'NumberOfRepeatsAuthorised',                     label: 'Number Of Repeats',                                     fe_group:    0, fe_order:    6, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['NumberOfRepeatsAuthorised', '[0]'],                        format:'unumber',        length:3,                                     use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'PBS_DVAAuthorityNumber',                        label: 'Generated Authority code (or "auto")',                          fe_group:    0, fe_order:  5.2, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['PBS-DVAAuthorityNumber', '[0]'],                           format:'string',       length:10,                                    use_for_compare: true ,                                     },
        {name: 'ControlledSubstanceReference',                  label: 'Controlled Substance Reference',                        fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: true,  path: ['ControlledSubstanceReference', '[0]'],                     format:'string',        length:25,                                    use_for_compare: true ,        mandatory: is_controlled      },
        {name: 'PhoneApprovalAuthorityNumber',                  label: 'Streamlined or Phone approval authority',                       fe_group:    0, fe_order:  5.2, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['PhoneApprovalAuthorityNumber', '[0]'],                     format:'string',        length:10,                                    use_for_compare: true ,                                     },                    // Not tested 
        {name: 'ScheduleNumber',                                label: 'Schedule Number',                                       fe_group:    1, fe_order:    0, fe_extended: false, fe_show: true,  fe_edit: false, path: ['ScheduleNumber', '[0]'],                                   format:'shd_enum',      length:2,                                     use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'PatientInstructions',                           label: 'Patient Instructions',                                  fe_group:    0, fe_order:    9, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['PatientInstructions', '[0]'],                              format:'string',        length:250,                                   use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested - length null -> 250 s29
        {name: 'RepeatIntervals',                               label: 'Repeat Intervals (days)',                               fe_group:    0, fe_order:    7, fe_extended: false, fe_show: has_repeats,  fe_edit: true,  path: ['RepeatIntervals', '[0]'],                                  format:'unumber',        length:3,                                    use_for_compare: true ,       mandatory: has_repeats},                    // Not tested - as per erx schema
        {name: 'DoctorNotes',                                   label: 'Doctor Notes',                                          fe_group:    0, fe_order: 1000, fe_extended: true,  fe_show: true,  fe_edit: true,  path: ['DoctorNotes', '[0]'],                                      format:'string',        length:50,                                    use_for_compare: true ,                                     },                    // Not tested - xsd indicates maxlen 50
        {name: 'ReasonForPrescribing',                          label: 'Reason For Prescribing',                                fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['ReasonForPrescribing', '[0]'],                             format:'string',        length:200,                                    use_for_compare: true ,                                     },                    // Not tested - as above
        {name: 'Regulation24',                                  label: 'Regulation 24',                                         fe_group:    0, fe_order:  102, fe_extended: true,  fe_show: true,  fe_edit: true,  path: ['Regulation24', '[0]'],                                     format:'boolean',       length:null,    blank: false,                 use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested - not used
        {name: 'IsExtemp',                                      label: 'Extemporaneous',                                        fe_group:    0, fe_order: 1000, fe_extended: true,  fe_show: true,  fe_edit: false,  path: ['IsExtemp', '[0]'],                                         format:'boolean',       length:null,    blank: false,                 use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'ExtemporaneousDescription',                     label: 'Extemporaneous Description',                            fe_group:    0, fe_order: 1000, fe_extended: true,  fe_show: true,  fe_edit: true,  path: ['ExtemporaneousDescription', '[0]'],                        format:'string',        length:254,                                   use_for_compare: true ,       mandatory: (p) => {return p.item && p.item.IsExtemp}},                    // Not tested 
        {name: 'UnlistedItemRepatAuthority',                    label: 'Unlisted Item Repat Authority',                         fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['UnlistedItemRepatAuthority', '[0]'],                       format:'boolean',       length:null,    blank: false,                 use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'PrivatePrescription',                           label: 'Private Prescription',                                  fe_group:    0, fe_order:  100, fe_extended: true, fe_show: true,  fe_edit: true,  path: ['PrivatePrescription', '[0]'],                              format:'boolean',       length:null,    blank: false,                 use_for_compare: true ,       mandatory: (p) => {return true}},                    // Not tested 
        {name: 'ScriptCTGAnnotation',                           label: 'Script CTG Annotation',                                 fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: true, path: ['ScriptCTGAnnotation', '[0]'],                              format:'ctg_enum',      length:null,                    minOccurs: 0, use_for_compare: true ,       mandatory: (p) => {return p.patient && p.patient.PatientCTGFlag}},   // Not tested
        {name: 'AMT_MP',                                        label: 'Medicinal Product ID (AMT_MP)',                         fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['AMT_MP', '[0]'],                                           format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_MPP',                                       label: 'Medicinal Product Pack (AMT_MPP)',                      fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['AMT_MPP', '[0]'],                                          format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_TPP',                                       label: 'Trade Product Pack (AMT_TPP)',                          fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['AMT_TPP', '[0]'],                                          format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_MPUU',                                      label: 'Medicinal Product Unit of Use (AMT_MPUU)',              fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['AMT_MPUU', '[0]'],                                         format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_TP',                                        label: 'Trade Product (AMT_TP)',                                fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['AMT_TP', '[0]'],                                           format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_TPUU',                                      label: 'Trade Product Unit of Use (AMT_TPUU)',                  fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['AMT_TPUU', '[0]'],                                         format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_CTPP',                                      label: 'Containered Trade Product Pack (AMT_CTPP)',             fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['AMT_CTPP', '[0]'],                                         format:'string',        length:50,      nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'PrescribedItem_Reserved_01',                    label: 'Maximum Quantity Authorised to be Dispensed (in words)',fe_group:    0, fe_order:  5.1, fe_extended: false, fe_show: is_controlled, fe_edit: true,  path: ['PrescribedItem_Reserved_01', '[0]'],               format:'string',        length:50,                      minOccurs: 0, use_for_compare: false,                                     },                    // Not tested // Maximum Quantity Authorised to be Dispensed
        {name: 'PrescribedItem_Reserved_02',                    label: 'Reserved 2',                                            fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescribedItem_Reserved_02', '[0]'],                       format:'string',        length:50,                      minOccurs: 0, use_for_compare: false,                                     },                    // Not tested 
        {name: 'PrescribedItem_Reserved_03',                    label: 'Reserved 3',                                            fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescribedItem_Reserved_03', '[0]'],                       format:'string',        length:50,                      minOccurs: 0, use_for_compare: false,                                     },                    // Not tested 
        {name: 'PrescribedItem_Reserved_04',                    label: 'Reserved 4',                                            fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescribedItem_Reserved_04', '[0]'],                       format:'string',        length:50,                      minOccurs: 0, use_for_compare: false,                                     },                    // Not tested 
        {name: 'PrescribedItem_Reserved_05',                    label: 'Reserved 5',                                            fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescribedItem_Reserved_05', '[0]'],                       format:'string',        length:50,                      minOccurs: 0, use_for_compare: false,                                     },                    // Not tested 
        {name: 'TherapeuticGoodIdentificationCode',             label: 'Therapeutic Good Identification Code',                  fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['TherapeuticGoodIdentificationCode', '[0]'],                format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'TherapeuticGoodIdentificationCodeSystem',       label: 'Therapeutic Good Identification Code System',           fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['TherapeuticGoodIdentificationCodeSystem', '[0]'],          format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'TherapeuticGoodIdentificationCodeSystemName',   label: 'Therapeutic Good Identification Code System Name',      fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['TherapeuticGoodIdentificationCodeSystemName', '[0]'],      format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'TherapeuticGoodIdentificationCodeSystemVersion',label: 'Therapeutic Good Identification Code System Version',   fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['TherapeuticGoodIdentificationCodeSystemVersion', '[0]'],   format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'TherapeuticGoodIdentificationDisplayName',      label: 'Therapeutic Good Identification Display Name',          fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['TherapeuticGoodIdentificationDisplayName', '[0]'],         format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_CODE_SYSTEM',                               label: 'AMT Code System',                                       fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['AMT_CODE_SYSTEM', '[0]'],                                  format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'QuantityExtended',                              label: 'Quantity (Extended)',                                   fe_group:    0, fe_order: 1000, fe_extended: false,  fe_show: true,  fe_edit: true,  path: ['QuantityExtended', '[0]'],                                 format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'AMT_VERSION',                                   label: 'AMT Version',                                           fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true, fe_edit: false, path: ['AMT_VERSION', '[0]'],                                      format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'PRIMARY_MEDICATION_CODE',                       label: 'Primary Medication Code',                               fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PRIMARY_MEDICATION_CODE', '[0]'],                          format:'string',        length:null,    nillable: true, minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'RTACid',                                        label: 'RTAC ID',                                               fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['RTACid', '[0]'],                                           format:'numeric',       length:10,                      minOccurs: 0, use_for_compare: true ,                                     },                    // Not tested 
        {name: 'PrescriberScriptNumber',                        label: 'Prescriber Script Number',                              fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrescriberScriptNumber', '[0]'],                           format:'string',        length:50,                                    use_for_compare: true ,                                     },                     // Not tested - xsd indicates max length 50
        {name: 'PrivacyStatement',                              label: 'Privacy Statement',                                     fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: false, fe_edit: false, path: ['PrivacyStatement', '[0]'],                                 format:'boolean',       length:null,                    minOccurs: 0, use_for_compare: true ,                                     },
        {name: 'EmergencySupply',                               label: 'Emergency Supply',                                      fe_group:    0, fe_order:  101, fe_extended: true, fe_show: true,  fe_edit: true,  path: ['EmergencySupply', '[0]'],                                  format:'boolean',       length:null,                    minOccurs: 0, use_for_compare: true ,                                     },
        {name: 'Annotations',                                   label: 'Annotations',                                           fe_group:    0, fe_order: 1000, fe_extended: false, fe_show: true,  fe_edit: true,  path: ['Annotations', '[0]'],                                      format:'array',         length:null,                    minOccurs: 0,                                                             }, // Not implemented just yet
        {name: 'SNOMED_CTReasonforPrescribe',                   label: 'Prescribing Reason (SNOMED_CTR)',                       fe_group:    0, fe_order: 1000, fe_extended: true, fe_show: true,  fe_edit: false, path: ['SNOMED-CTReasonforPrescribe', '[0]'],                      format:'numeric',       length:null,                       use_for_compare: true ,       mandatory: (p) => {return false}},
        {name: 'UnusualDoseFlag',                               label: 'Unusual Dose',                                          fe_group:    0, fe_order: 2001, fe_extended: true, fe_show: true,  fe_edit: true, path: ['UnusualDoseFlag', '[0]'],                                  format:'boolean',       length:null,    blank:false,                  use_for_compare: true ,       mandatory: (p) => {return true}},
    ],

    //	------------------------	------------------------	------------------------
    //	------------------------	------------------------	------------------------

    SESSION_KEY_PROPS: [
        // Based on http://erx.com.au/integration/v1
        {name: 'Key',                   label: 'Key',                   fe_show: false, fe_edit: false, path: ['Key', '[0]'],                   format:'string',        length:null,        use_for_compare: true ,                                      },                    // Not tested - note - probably wont ever run this compare
        {name: 'IV',                    label: 'IV',                    fe_show: false, fe_edit: false, path: ['IV', '[0]'],                    format:'string',        length:null,        use_for_compare: true ,                                      },                    // Not tested - note - probably wont ever run this compare
        {name: 'CertificateVersion',    label: 'Certificate Version',   fe_show: false, fe_edit: false, path: ['CertificateVersion', '[0]'],    format:'string',        length:null,        use_for_compare: true ,                                      },                    // Not tested - note - probably wont ever run this compare
    ],

    retricted_repeat_intervals: rest_rep_int

}

function rest_rep_int({patient,item}) { //PRES-18
    
    if (patient?.PatientState == 'NSW' && (item?.ScheduleNumber == '4B' || item?.ScheduleNumber == '8')) return true // 4B and 8 in NSW
    if ((patient?.PatientState == 'ACT' || patient?.PatientState == 'WA' || patient?.PatientState == 'NT' || patient?.PatientState == 'QLD') && item?.ScheduleNumber == '8') return true // 8 in ACT, WA, NT
    if (patient?.PatientState == 'TAS' && (item?.ScheduleNumber == '8' || item?.ScheduleNumber == '4D')) return true // 8 and 4D in TAS

    return false

}

function is_controlled(p) { // This will need to be better in future

    return p && p.item && (p.item.ScheduleNumber == '8' || p.item.ScheduleNumber == 8 ) || false

}

function is_auth(p) {

    const AuthorityType = j_f.obj_val(p, ['item_raw', 'AuthorityType'])

    return (AuthorityType == 'authority-required' || AuthorityType == 'streamlined')

}

function dva_mandatory(p) {

    const ProgramCode = j_f.obj_val(p, ['item_raw', 'ProgramCode'])
    const PrivatePrescription = j_f.obj_val(p, ['item', 'PrivatePrescription'])

    if (ProgramCode == 'R1' && !PrivatePrescription) return true

    return p.patient && !(p.patient.DVACardColour == '' || p.patient.DVACardColour == null)

}

function is_extemp(p) {

    const IsExtemp = j_f.obj_val(p, ['item', 'IsExtemp'])

    return IsExtemp

}

function has_repeats(p) {

    const NumberOfRepeatsAuthorised = j_f.obj_val(p, ['item', 'NumberOfRepeatsAuthorised'])

    return typeof NumberOfRepeatsAuthorised != 'undefined' && NumberOfRepeatsAuthorised > 0

}