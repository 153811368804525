import { generatePrettyAuthorLabel } from 'helpers/myhr';
import myhr_model from 'models/myhr_model';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { Loader, Table, TableRow, TableHeaderCell, TableBody, TableCell, TableHeader, Button, Icon } from 'semantic-ui-react';
import MyHRDocumentView from './MyHRDocumentView';
import ActBtns from '../UIelems/fields/ActBtns';
import ErrorScreen from './ErrorScreen';
import Badge from 'views/NUI/Badge'

const DEFAULT_HPIO = '8003624166737939' // TODO make configurable later

const DEBUG = false 

const defaultState = {
	isDisplayingDocumentView: false,
	init:false,
	loading: {
		directory: true,
		document: false,
	},
	documents: [],
	selected_document: false,
};

const MyHRDirectoryView = ({ by_uid, filters, doc, auto_open }) => {
	const [state, setState] = useState(defaultState);
	const currentLoader = useRef(null)

	const isLoadingDirectory = !!state.loading.directory;
	const isLoadingDocument = !!state.loading.document;
	const displayingDocument =!!state.isDisplayingDocumentView ;

	useEffect(async () => {
		setState({
			...state,
			loading: { ...state.loading, directory: true },
		});
		const response = await myhr_model.find_documents({ uid: by_uid, filters });
		const success = response.res === 'ok';
		if (success) {
			setState({
				...state,
				init:true,
				documents: response.registry_object_list,
				loading: { ...state.loading, directory: false },
				success,
			});
		} else {
			setState({
				...state,
				documents: [],
				success: success,
				loading: { ...state.loading, directory: false },
				error_code: response.code,
				error_description: response.description,
			});
		}
	}, [JSON.stringify(filters)]);

	const loadDocument = async(d) => {
		const { DocumentUniqueId, RepositoryUniqueId} = d
		setState({
			...state,
			selected_document: { DocumentUniqueId, RepositoryUniqueId },
			loading: { ...state.loading, document: true },
			isDisplayingDocumentView: true,
		});

		const doc_load = myhr_model.get_document({
			uid: by_uid,
			document: { RepositoryUniqueId, DocumentUniqueId },
		});
		currentLoader.current = doc_load
		const doc_res = await doc_load
		// handle race condition, discard results if not active loader
		if (currentLoader.current != doc_load)
			return 

		setState({
			...state,
			selected_document: { ...doc_res, DocumentUniqueId, RepositoryUniqueId },
			loading: { ...state.loading, document: false },
			isDisplayingDocumentView: true,
		});

	}

	const handleDocumentSelection = async ({ document, name }) => {

		const DocumentUniqueId = document?.ExternalIdentifier?.find((ent) => ent.Name === 'XDSDocumentEntry.uniqueId').value;
		const RepositoryUniqueId = document.Slot?.repositoryUniqueId;

		await loadDocument({DocumentUniqueId,RepositoryUniqueId})

		
	};
	const handleDocumentUnselect = () => {
		setState({
			...state,
			selected_document: false,
			isDisplayingDocumentView: false,
		});
	};

	const handleDocumentRemoval = async (document) => {
		const DocumentUniqueId = document?.ExternalIdentifier?.find((ent) => ent.Name === 'XDSDocumentEntry.uniqueId').value;

		const response = await myhr_model.remove_document({
			uid: by_uid,
			documentID: DocumentUniqueId,
		});

		if (response.res === 'ok') {
			setState({
				...state,
				documents: state.documents.filter(
					(doc) => doc.ExternalIdentifier?.find((ent) => ent.Name === 'XDSDocumentEntry.uniqueId').value !== DocumentUniqueId
				),
			});
		}

		return response;
	};

	useEffect(async ()=>{
		if (auto_open && state.init)
			await loadDocument(auto_open)

	},[auto_open,state.init])

	const fields = displayingDocument ? ['Document'] : ['Type', 'Status', 'Date', 'Author', 'Action'];

	if (isLoadingDirectory) {
		return (
			<div className='min-h-[60vh]'>
				<Loader inverted>Loading My Health Record Documents</Loader>
			</div>
		);
	}

	if (!state.loading?.directory && !state.success) {
		return (
			<div className="flex min-h-[60vh] items-center justify-center">
				<ErrorScreen
					message={
						<p>
							Error encountered when attempting to load patient records: {state.error_description} ({state.error_code})
						</p>
					}
				/>
			</div>
		);
	}

	const { selected_document } = state;
		
	return (
		<>
			<div className={`grid grid-cols-3 gap-4`}>
				<div className={`${displayingDocument ? 'col-span-1' : 'col-span-3'} max-h-[60vh] overflow-auto`}>
					<Table singleLine selectable>
						<TableHeader>
							<TableRow>
								{fields.map((f) => (
									<TableHeaderCell key={f}>{f}</TableHeaderCell>
								))}
							</TableRow>
						</TableHeader>
						<TableBody>
							{state.documents.length==0 && <TableRow><TableCell colSpan="10"><p>The request to retrieve documents was successful, however no documents were returned for this patient.</p></TableCell></TableRow>}
							{state.documents.map((document) => {
								const DocumentUniqueId = document?.ExternalIdentifier?.find(
									(ent) => ent.Name === 'XDSDocumentEntry.uniqueId'
								).value;
								const authorInstitution = document?.Classification?.find(
									(ent) => ent.Slot?.authorInstitution
								).Slot.authorInstitution;
								const documentType = document?.Classification[1]?.Name
								const RepositoryUniqueId = document.Slot?.repositoryUniqueId;
								const args = {
									onClick: () => handleDocumentSelection({ name: document.Name, document }),
								};
								const isHighlighted = DocumentUniqueId == doc?.documentId
								const isCurrentlyViewing =	selected_document.DocumentUniqueId === DocumentUniqueId && RepositoryUniqueId === selected_document.RepositoryUniqueId;
								const ownDocument = authorInstitution.includes(`1.2.36.1.2001.1003.0.${DEFAULT_HPIO}`)

								return (
									<TableRow key={DocumentUniqueId + RepositoryUniqueId} className={`cursor-pointer`} positive={isHighlighted}>
										<TableCell {...args}>
											<div className={`flex justify-between`}>
												<span>{isHighlighted && <Icon name='pin' title='The current script'/>} {documentType || 'Untitled Document'}
												{DEBUG && <>
													<br/>id: {document.id}
													<br/>did: {DocumentUniqueId}
													<br/>rid: {RepositoryUniqueId}
													</>}
												</span>
												{isCurrentlyViewing && <Badge small color='green'>Viewing</Badge>}
											</div>
										</TableCell>
										{!displayingDocument && (
											<TableCell {...args}>
												{document.status.replace('urn:oasis:names:tc:ebxml-regrep:StatusType:', '')}
											</TableCell>
										)}
										{!displayingDocument && (
											<TableCell {...args}>
												{moment(document.Slot.creationTime, 'YYYYMMDDHHmmss').format('DD-MM-YYYY')}
											</TableCell>
										)}
										{!displayingDocument && (
											<TableCell {...args}>{generatePrettyAuthorLabel(document.Classification[0]?.Slot)}</TableCell>
										)}
										{!displayingDocument && (
											<TableCell className='z-auto'>
												{ownDocument && <ActBtns
													row={document}
													f={{
														btns_list_func: () => [
															{
																n: 'Delete',
																a: 'delete',
																c: 'red',
																i: 'trash',
															},
														],
														act_run: async (b, document) => await handleDocumentRemoval(document),
													}}
												/>}
											</TableCell>
										)}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
				<div className={`${displayingDocument ? 'col-span-2' : 'sr-only'}`}>
					<div className=' min-h-[60vh] w-full'>
						{isLoadingDocument && (
							<div className='flex flex-col gap-4 mx-auto items-center justify-center min-h-[60vh] border border-gray-200 rounded'>
								<Loader inverted inline>
									Loading Document
								</Loader>
								<Button size='tiny' onClick={() => handleDocumentUnselect()}>
									Go Back
								</Button>
							</div>
						)}
						{!isLoadingDocument && state.selected_document?.document && (
							<MyHRDocumentView {...{ documentArr: state.selected_document.document, handleDocumentUnselect }} />
						)}
						{!isLoadingDocument && state.selected_document?.error && (
							<div
								className={`flex flex-col gap-4 mx-auto items-center justify-center min-h-[60vh] border border-gray-200 rounded`}
							>
								<ErrorScreen message={state.selected_document.error} />
								<Button size='tiny' onClick={() => handleDocumentUnselect()}>
									Go Back
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MyHRDirectoryView;
