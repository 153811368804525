import React from "react";
import { Link as RouterLink } from "react-router-dom";
import onepass_model from "../../models/onepass_model";
import SIcon from "../../views/NUI/StreamlineIcons/SIcon";
import Badge from "../../views/NUI/Badge";
import { cls } from "../../views/NUI/utils";
import { Button, Container } from "semantic-ui-react";
import { MedEnrollments } from "../../views/UIelems/QualificationStatusIndicator";
import { useProfile, useUser } from "xAppLib/Hooks";

const pricing = cost => cost && ({
    cost,
    op_cost: onepass_model.discountedPriceString(cost),
})

export const tile_data = (pr_btn) => {
    return [
        {
            labelDesk: "Need to reorder a script?",
            label: "Order History & Reorder",
            link: "/history",
            desc: "Your request history is securely stored in our database to make reordering your prescription easy.",
            icon: "pills-single",
            cls: "text-is-blue",
        },
        {
            labelDesk: "Online Prescriptions",
            label: "Request a Script",
            link: "/m/pharm/online/prescription",
            desc: (
                <>
                    <li>For when your script has run out</li>
                    <li>Script sent to your phone or medication delivered</li>
                </>
            ),
            icon: "doc-stamp",
            learnmore: "/online-scripts",
            pr_label: 'From',
            ...pricing(app.site_status?.prs_mc['mc_std'])
        },
        {
            labelDesk: "Medical Certificates",
            label: "Request a Certificate",
            link: "/c/pharm/certs/",
            desc: (
                <>
                    <li>For work, uni, school or carers</li>
                    <li>Medical Certificates sent to your email</li>
                </>
            ),
            icon: "med-cert",
            learnmore: "/online-medical-certificates",
            ...pricing(app.site_status?.prs_mc['mc_std'])
        },
        {
            labelDesk: "Telehealth Consultations",
            label: "Request a Consultation",
            link: "/cat/DocCons",
            desc: (
                <>
                    <li>For when you need to speak to a doctor</li>
                    <li>Fast access to medical advice</li>
                </>
            ),
            icon: "smartphone-cross-hand",
            learnmore: "/telehealth-consultations",
            pr_label: 'From',
            ...(pr_btn ? {cost: app.site_status?.prs_mc['dc_uti_pr']} : pricing(app.site_status?.prs_mc['dc_std']) )
        },
        {
            labelDesk: "Weight Loss Treatment",
            label: "Request a Consultation",
            link: "/m/pharm/DocCons/DocConsWL",
            desc: (
                <>
                    <li>For the medical management of weight loss</li>
                    <li>Doctor consultations and treatment options</li>
                </>
            ),
            icon: "doc-sign-pen",
            learnmore: "/weight-loss",
            ...pricing(app.site_status?.prs_mc['dc_wlinj_pr'])
        },
        {
            labelDesk: "Blood Test Requests",
            label: "Request a Blood Test",
            link: "/c/pharm/patho",
            desc: (
                <>
                    <li>For pregnancy, STIs and more</li>
                    <li>Referrals sent to your email</li>
                </>
            ),
            icon: "patho-microscope",
            learnmore: "/online-pathology-requests",
            ...pricing(app.site_status?.prs_mc['mc_pt_pr'])
        },
        {
            labelDesk: "Specialist Referrals",
            label: "Request a Referral",
            link: "/c/pharm/express-specialist-referrals",
            desc: (
                <>
                    <li>For skin checks, eye tests and more</li>
                    <li>Referrals sent to your email</li>
                </>
            ),
            icon: "doc-steth",
            learnmore: "/online-specialist-referrals",
            ...pricing(app.site_status?.prs_mc['mc_pt_pr'])
        },
    ];
};

const MainTiles = ({
    title,
    colour = "blue",
    show_hist = false,
    excl = [],
    show_pr = false,
    pr_btn = false,
    size = "lg",
    learnmore,
    viewerOnly = true,
    pt = 'sm:pt-[96px]'
}) => {
    const [prof] = useProfile()
    const Link = viewerOnly ? RouterLink : Container;
    const tiles = tile_data(pr_btn)
    const histEl = tiles[0];
    const clr =
        (colour == "blue" && "bg-is-blue text-white hover:text-white") ||
        "bg-white text-black !hover:text-is-blue";
    const lg = size == "lg";
    const qualcons = prof?.qualcons?.filter(q=>q.qualification_status === 'current' && q.enrolled_med !=='*') || [];

    return (
        <div className={cls("py-6 sm:pb-6 main-tiles ui container fluid-fixed", pt)}>
            {title}

            {show_hist && (
                <div className="ui row w-full md:max-w-[1264px] mx-auto my-4 rounded-md bg-is-blue-20 self-center">
                    <div className="history-feature w-full flex flex-col md:flex-row relative p-4 text-is-black rounded-md mb-4 md:mb-0">
                        <SIcon name={histEl.icon} size="xl" cls={histEl.cls} />
                        <div className="md:ml-6 mb-2 md:mb-0">
                            <h4 className="absolute md:relative top-4 !mb-0 md:top-0 left-20 md:left-0 w-2/3 md:w-full leading-6 md:leading-normal">
                                {histEl.labelDesk}
                            </h4>
                            <p className="mt-4 md:mt-0">{histEl.desc}</p>
                            {qualcons.length==0  && <Link className="ui button primary" to={histEl.link}>Medical History</Link>}
                        </div>
                    </div>
                    {qualcons.length>0 && <MedEnrollments display="home" qualcons={qualcons} />}
                </div>
            )}

            <div
                className={cls(
                    "flex items-center justify-center mb-4 md:mb-0 flex-wrap",
                    lg ? "gap-4" : "gap-3"
                )}
            >
                {tiles
                    .filter((el) => ![...excl, "/history"].includes(el.link))
                    .map((el, i) => (
                        <div
                            key={i}
                            className={cls(
                                "relative rounded-md w-full md:w-[410.67px] h-full",
                                !lg ? "p-4" : "p-0 md:p-4",
                                clr
                            )}
                        >
                            <Link
                                to={el.link}
                                data-testid="link-tile"
                                className={cls(
                                    "flex justify-between w-full h-full",
                                    !lg
                                        ? "flex-col items-start "
                                        : "flex-row md:flex-col items-center md:items-start",
                                    colour == "blue" && "hover:text-white"
                                )}
                            >
                                <div
                                    className={cls(
                                        "relative md:absolute",
                                        lg &&
                                        "border-r border-white md:border-none p-2.5 md:p-0"
                                    )}
                                >
                                    <SIcon
                                        name={el.icon}
                                        size={size}
                                        cls={el.cls}
                                    />
                                </div>

                                <div
                                    className={cls(
                                        "flex w-full relative md:mt-[72px] mb-4 md:mb-0",
                                        !lg
                                            ? "flex-col"
                                            : "flex-row md:flex-col p-2.5 md:p-0"
                                    )}
                                >
                                    <h4
                                        className={cls(
                                            lg
                                                ? "text-[1.125rem] md:text-[1.3rem]"
                                                : "text-base font-bold text-left",
                                            "md:order-2 leading-4 md:leading-normal"
                                        )}
                                    >
                                        {el.labelDesk}
                                       
                                        {(pr_btn && el.cost) && <><br/><span className="text-sm mt-1 block md:hidden">{el.pr_label} ${el.cost}</span></>}
                                    </h4>
                                </div>

                                <div
                                    className={cls(
                                        lg ? "my-0 md:my-4" : "my-4"
                                    )}
                                >
                                    <ul
                                        className={cls(
                                            "list-disc list-inside text-sm",
                                            lg && "hidden md:block"
                                        )}
                                    >
                                        {el.desc}
                                    </ul>
                                    {lg && (
                                        <SIcon
                                            name="chevron"
                                            size="xs"
                                            cls="md:hidden -rotate-90 mr-4"
                                            bold
                                        />
                                    )}
                                </div>

                                {show_pr && (
                                    <>
                                        <Badge
                                            small
                                            className="!bg-is-blue-20 text-sm text-is-black-80"
                                        >
                                            {" "}
                                            Cost: ${el.cost}
                                        </Badge>
                                        <Badge
                                            small
                                            className="mt-2 mb-4 !bg-is-blue-20 text-sm text-is-black-80"
                                        >
                                            {" "}
                                            OnePass cost: {el.op_cost}
                                        </Badge>
                                    </>
                                )}

                                <div
                                    className={cls(
                                        lg &&
                                        "!hidden md:!block hover:!bg-is-blue-20 hover:!text-is-blue",
                                        "ui button fluid basic blue"
                                    )}
                                >
                                    {el.label} {(pr_btn && el.cost) && (`${el?.pr_label||''} $${el.cost}`)}
                                </div>
                               
                            </Link>

                            {learnmore && el.learnmore && (
                                <Link
                                    to={el.learnmore}
                                    onClick={(e) => {
                                        if(!app.settings.is_ionic){
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window
                                            .open(el.learnmore, "_blank")
                                            .focus();
                                        }
                                    }}
                                    className={cls(
                                        !lg && "hidden md:block",
                                        "absolute bottom-1 md:bottom-0 md:right-4 md:top-4 left-[80px] md:left-auto h-[24px] text-white cursor-pointer text-md font-semibold hover:text-white md:h-[56px] md:flex items-center"
                                    )}
                                >
                                    <div className="flex items-center text-sm underline hover:no-underline">
                                        Learn more{" "}
                                        <SIcon
                                            name="external"
                                            color="white"
                                            bold
                                            cls="h-[20px] w-[20px] order-2 md:order-none ml-1 md:ml-2"
                                        />
                                    </div>
                                </Link>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default MainTiles;
