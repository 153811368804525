import React, { useEffect, useState } from 'react';
import { Button, Message } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { from_melb_ui_tm } from "../../helpers/datetime";

import { useProfile } from "../../xAppLib/Hooks";
import instcons_global_model from "../../models/instcons_global_model";
import scripts_list_model from "../../models/scripts_list_model";
import moment from "moment";

function WatchForInProgress({rec}) {
	const {pathname} = useLocation();
	const {sid, spd_hash__mobile = '_', spd_data, req_type} = rec;

	const [fbdbRec, setFrdbRec] = useState(null);
	useEffect(() => instcons_global_model.watch_record(sid, setFrdbRec), [sid]);

    if (!fbdbRec) {
        return null;
    }

    const {status, vidcall_notify, sessionId} = fbdbRec;

    if (status !== 'in_doccall') {
        return null;
    }

    const has_recent_invite = vidcall_notify && moment().subtract(1, 'hour').isBefore(fbdbRec.vidcall_notify);
	const is_mhcp = req_type === 'mhcp';
    if (!(has_recent_invite || is_mhcp)) {
		// Don't show if it's been more than an hour since the last invite, and it's not an MHCP
		return null;
	}

	const name = rec.med_db_data?.name || "Your Video Consult";
    const status_label = scripts_list_model.stats_dict(status);
    const prefix = `/doccons_view`;
    const href = `${prefix}/${sid}/${spd_hash__mobile}`;
	const show_pt_name = app.user?.profs?.length > 1 && spd_data?.first_name && spd_data?.last_name;

	if (pathname.startsWith(prefix)) {
		return null;
	}

	return (
		<Message info>
			<Message.Header>
				{name} – {status_label}<br/>
				<small className="dates uppercase">
					{show_pt_name && <>{spd_data?.first_name} {spd_data?.last_name} – </>} {from_melb_ui_tm(rec.add_tm).format('MMM D, YYYY')}
				</small>
			</Message.Header>
			<Message.Content className="m-4">
				{sessionId
					? <p>Your doctor has started the video consultation and ready for you to join.</p>
					: <p>Your doctor will notify you shortly when they're ready to start the video consultation.</p>
				}
				<Button to={href}
						as={Link}
						icon="video"
						primary
						content={sessionId ? 'Join video consult' : 'Wait for doctor'}
				/>
			</Message.Content>
		</Message>
	);
}

export function InProgressVideoConsults() {
    const [prof] = useProfile();

    return (prof?.hist ?? [])
        .filter(rec => (
            ['qualcons', 'qualbuy', 'qualclick', 'exprcons', 'exprbuy', 'exprclick', 'doccons'].includes(rec.script_type)
            && ['await_doccall', 'in_doccall', 'noans_doccall', 'could_not_call', 'wrong_number'].includes(rec.status)
			&& from_melb_ui_tm(rec.add_tm).isAfter(moment().subtract(1, 'month'))
        ))
        .map(rec => <WatchForInProgress key={rec.sid} rec={rec}/>);
}