import React from 'react';
import { Container } from "semantic-ui-react";
import { useCannStatus } from "./Hooks";

export function ICannUnavailable({children}) {
	const {can_proceed} = useCannStatus();
	if (can_proceed) {
		return children;
	}

	return (
		<section className="hero primary flex flex-col">
			<Container className="grow">
				<div className="text-center">
					<h2>Thank you for visiting {app.runtime.name}</h2>
					<div className="text-md md:text-2xl pt-4">
						{app.runtime.name} is temporarily unable to support you via our online telehealth clinic.
					</div>
					<div className="text-md md:text-2xl pt-4">
						We hope to be back online soon.
					</div>
				</div>
			</Container>
		</section>
	)
}