import React, { useState } from 'react';
import { useEffect } from 'react';
import { Popup, Icon, Dropdown, Button, Divider } from 'semantic-ui-react';
import evermed_model from '../../models/evermed_model';
import { pick } from '../../helpers/json_functions';
import { ObjectInspector, chromeLight } from 'react-inspector';
// 15746

const handleAction = async (action_type, payload, state, setState) => {
  setState({ ...state, loading: true });
  var response;
  switch (action_type) {
    case 'cancel_order':
      response = { ...(await evermed_model.cancelOrder(payload)), action_type };
      break;
    case 'reprocess_order':
      response = { ...(await evermed_model.reprocessOrder(payload)), action_type };
      break;
    case 'overwrite_order':
      console.log(payload);
      response = { ...(await evermed_model.overwriteWithPreviousRecord(payload)), action_type };
      break;
    case 'get_and_set_order':
      response = { ...(await evermed_model.getAndSetEvermedOrder(payload)), action_type };
      break;
  }
  setState({ ...state, loading: false, actionsTaken: [...state.actionsTaken, response] });
};

const EvermedActions = ({ row: scr_obj }) => {
  const [state, setState] = useState({
    loading: false,
    show_evermed: false,
    show_selected_snapshot: false,
    orderDetails: {},
    failed: false,
    actionsTaken: [],
    previous_record_tm: '',
  });
  return (
    <div className="p-4 w-100 flex-col">
      <h3>Evermed Control Panel</h3>
      <p>
        View Evermed Record:{' '}
        <Popup
          trigger={
            <b className="cursor-pointer hover:underline" onClick={(_) => setState({ ...state, show_evermed: !state.show_evermed })}>
              {scr_obj.meta?.evermed?.order?.orderNumber}
            </b>
          }
          open={state.show_evermed}
          position="left center"
        >
          <ObjectInspector data={scr_obj.meta?.evermed} expandLevel={1} theme={{ ...chromeLight, ...{ TREENODE_PADDING_LEFT: 20 } }} />
        </Popup>
      </p>

      <Divider />
      <div className="w-100">
        <h4>Quick Actions</h4>
        <div className="flex-row space-x-3">
          <Button
            icon
            labelPosition="left"
            color="red"
            disabled={state.loading}
            loading={state.loading}
            onClick={() => handleAction('cancel_order', { sid: scr_obj.sid, orderNumber: scr_obj.meta.evermed?.order?.orderNumber }, state, setState)}
          >
            <Icon name="truck" />
            Cancel Order
          </Button>
          <Button
            icon
            labelPosition="left"
            color="yellow"
            disabled={state.loading}
            loading={state.loading}
            onClick={() => handleAction('reprocess_order', { sid: scr_obj.sid }, state, setState)}
          >
            <Icon name="sync" />
            Reprocess Order
          </Button>
          <Button
            icon
            labelPosition="left"
            color="green"
            disabled={state.loading}
            loading={state.loading}
            onClick={() => handleAction('get_and_set_order', { sid: scr_obj.sid }, state, setState)}
          >
            <Icon name="plus" />
            Get and Set Order
          </Button>
        </div>
      </div>
      {scr_obj.meta?.evermed?.previous && (
        <>
          <Divider />
          <div className="flex-col space-y-2">
            <h4>Manual overwrite from previous records</h4>
            <div className="flex justify-between gap-4">
              <Dropdown
                selection
                options={scr_obj.meta?.evermed?.previous?.map((rec) => ({
                  key: rec.tm,
                  text: `Overwritten on ${rec.tm} by ${rec.by?.name}`,
                  value: rec.tm,
                }))}
                placeholder="Select Previous Record by time"
                onChange={(e, { value }) => {
                  console.log(value);
                  setState({ ...state, previous_record_tm: value });
                }}
              />
              <Button
                disabled={state.loading || !state.previous_record_tm}
                loading={state.loading}
                color="green"
                onClick={() => handleAction('overwrite_order', { sid: scr_obj.sid, previous_record_tm: state.previous_record_tm }, state, setState)}
              >
                Overwrite Evermed Details
              </Button>
            </div>
            {state.previous_record_tm && (
              <div>
                <Popup
                  trigger={
                    <p className="underline cursor-pointer pt-0" onClick={(_) => setState({ ...state, show_selected_snapshot: !state.show_selected_snapshot })}>
                      View Selected Record
                    </p>
                  }
                  open={state.show_selected_snapshot}
                  position="left center"
                >
                  <ObjectInspector
                    data={scr_obj.meta.evermed.previous.find((rec) => rec.tm === state.previous_record_tm)}
                    expandLevel={1}
                    theme={{ ...chromeLight, ...{ TREENODE_PADDING_LEFT: 20 } }}
                  />
                </Popup>
              </div>
            )}
          </div>
        </>
      )}
      {state.actionsTaken.length > 0 && (
        <>
          <Divider />
          <div>
            {state.actionsTaken.map((action, i) => (
              <div key={i}>
                {action.action_type} - {action.res} - {action?.msg}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default EvermedActions;
