import logger from 'xAppLib/libs/logger'
import API_service from 'xAppLib/providers/API_service'
import moment from 'moment'

const DEBUG = false

export function parseState(state) {
    switch(state?.toUpperCase().trim()) {
        case 'VIC':
        case 'VICTORIA':
            return "VIC"
        case 'NSW':
        case 'NEW SOUTH WALES':
            return 'NSW'
        case 'ACT':
        case 'AUSTRALIAN CAPITAL TERRITORY':
            return 'ACT'
        case 'QLD': 
        case 'QUEENSLAND':
            return 'QLD'
        case 'NT':
        case 'NORTHERN TERRITORY':
            return 'NT'
        case 'WA':
        case 'WESTERN AUSTRALIA':
            return 'WA'
        case 'SA':
        case 'SOUTH AUSTRALIA':
            return 'SA'
        case 'TAS':
        case 'TASMANIA':
            return 'TAS'
        default:
            return  ''
    }

    
}

export const states  = {
	'VIC':'Victoria',
	"NSW":'New South Wales',
	'ACT':'Australian Capital Territory',
	'QLD':'Queensland',
	'NT':'Northern Territory',
	'WA':'Western Australia',
	'SA':'South Australia',
	'TAS':'Tasmania',
}

const stateToTimezone = {
	VIC: 'Australia/Melbourne',
	NSW: 'Australia/Sydney',
	ACT: 'Australia/Canberra',
	QLD: 'Australia/Brisbane',
	NT: 'Australia/Darwin',
	WA: 'Australia/Perth',
	SA: 'Australia/Adelaide',
	TAS: 'Australia/Hobart'
};

export const stateToTime = (state, format = 'h:mm a') => {
    if (!stateToTimezone[state]) {
        return null;
    }
    return moment().tz(stateToTimezone[state]).format(format);
}

export function formatComponents(data, allowLocalArea = false) {
    const {address, address2, suburb, state, postcode} = data
    DEBUG && console.log("formatComponents()",{address, address2, suburb, state, postcode});
    if (suburb=='' || state=='' || postcode == '' || (address == '' && !allowLocalArea))
        return ''
    const first = [address2, address].map(v => v && v.trim()).filter(n => n).join(' / ')
    const sub = [suburb, state, postcode].map(v => v && v.trim()).filter(n => n).join(" ")
    const parts = [first, sub].map(v => v && v.trim()).filter(n => n).join(', ')
    return parts
}

export async function getFinalAddress(address, address2, allowLocalArea = false, result = false) {
    DEBUG && console.log("getFinalAddress:geocoding passed value %o",address);
    logger.usg_log('getFinalAddress_FE', location.pathname, null, {address:address})
    if(!result){
        result = await new Promise((resolve, reject) => {
            API_service.call_api('geocode', {
                address:address
            }, resolve, reject)
        })
    }
    DEBUG && console.log('getFinalAddress:result = %o', result)
    
    if (result) {
        const data = getAddressObject(address, result.address_components)
        DEBUG && console.log("getFinalAddress:data",{data});
        if (address2)
            data.address2 = address2
        let formatted_address = formatComponents(data, allowLocalArea)
        DEBUG && console.log("getFinalAddress:formatted_address1",{formatted_address});
        if (data.floor) {
            const i = formatted_address.indexOf(data.floor)
            if (i !== -1) {
                formatted_address = formatted_address.slice(0, i) + formatted_address.slice(i + data.floor.length)
            }
            formatted_address = formatted_address.replace(/(^,)|(,$)/g, "").trim()
        }
        DEBUG && console.log("getFinalAddress:formatted_address2",{formatted_address});
        
        const final = [
            // address2,
            formatted_address
        ].filter(n=>n&&n!='').join(", ")
        DEBUG && console.log("getFinalAddress:final",{final});
        
        data.formatted = final
        
        data.geo = result
        console.log({final, data});
        return {final, data}

    }
    
    return {}
}

export async function reverseGeoCode(coordinates) {
    DEBUG && console.log("reverse_geocode(%o)",coordinates);
    logger.usg_log('reverse_geocode_FE', location.pathname, null, {coordinates:coordinates})
    const result = await API_service.load_data('reverse-geocode', coordinates)
    DEBUG && console.log("reverse_geocode result ",result);
    
    if (result.error) {
        return null
    } else {
        return result
    }
}

export function getAddressObject(full_address, address_components) {
    DEBUG && console.log("getAddressObject()", {full_address, address_components});
    const ShouldBeComponent = {
        floor:["floor"],
        number: ["street_number"],
        postcode: ["postal_code"],
        street: [
            "street_address", "route"
        ],
        state: [
            "administrative_area_level_1", "administrative_area_level_2", "administrative_area_level_3", "administrative_area_level_4", "administrative_area_level_5"
        ],
        stateLong: [
            "administrative_area_level_1", "administrative_area_level_2", "administrative_area_level_3", "administrative_area_level_4", "administrative_area_level_5"
        ],
        suburb: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
        ],
        countryCode: ["country"],
        country: ["country"]
    };

    const address = {
        
    };

    address_components.forEach(component => {
        for (const shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (['countryCode', 'state', 'suburb'].includes(shouldBe)) {
                    address[shouldBe] = component.short_name;
                } else {
                    address[shouldBe] = component.long_name;
                }
            }
        }
    });
    
    DEBUG && console.log({address});
    
    
    // Calculate better "Address line" from removing known parts from original full address.
    for (const part of['state', 'suburb', 'postcode', 'stateCode', 'country']) {
        const i = full_address.lastIndexOf(address[part])
        if (i !== -1) {
            full_address = full_address.slice(0, i) + full_address.slice(i + address[part].length)
        }
    }

    while ([' ', ','].includes(full_address.charAt(full_address.length - 1))) {
        full_address = full_address.substring(0, full_address.length - 1);
    }
    address.address = full_address
    DEBUG && console.log({address});

    return address;
}