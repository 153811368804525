import React, { Component } from 'react';

const Privacy = () => (
	<div className="privacy">
		<h1><b>Privacy Policy</b></h1>
		<h3>1. INTRODUCTION</h3>
		<ol className="list-decimal">
			<li>We respect your rights to privacy and are very conscious of the provisions of the Privacy Act 1988 (Cth) (<b>Privacy Act</b>). The Privacy Act contains the Australian Privacy Principles (<b>APPs</b>) which cover areas including the collection, use, disclosure, quality and security of personal information. We are also conscious of the relevant health privacy principles under State and Territory legislation.</li>
			<li>We, InstantScripts Pty Ltd (ACN 629 120 234), own and operate the InstantScripts platform (<b>Platform</b>) and websites and app (<b>Websites</b>).</li>
			<li>The Platform facilitates confidential access to digital health services, including:
				<ul>
					<li>telehealth and video consultations with registered doctors (<b>Partner Doctors</b>) and other registered health providers, such as pharmacists, pathology providers and cosmetic nurses (<b>Partner Providers</b>); and</li>
					<li>where clinically appropriate and after a telehealth or video consultation, the ability to request:
						<ul>
							<li>an online prescription, including delivery of the medication;</li>
							<li>a referral for a pathology test;</li>
							<li>a referral to a specialist doctor; and</li>
							<li>a medical certificate.</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>We recognise the importance of your privacy and understand that the security of your personal information is important to you.</li>
			<li>This privacy policy explains what information we collect about you, how we may use and disclose it, and the steps we take in relation to security.</li>
			<li>Personal information is information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not, and whether the information or opinion is recorded in a material form or not.</li>
			<li>Special provisions apply to the collection, use and disclosure of personal information which is sensitive information. This includes health information and information about a person's race, ethnic origin, political opinions, membership of political, professional or trade associations, religious or philosophical beliefs, sexual preferences and criminal history.</li>
			<li>In this privacy policy, all references to personal information include sensitive information unless indicated otherwise.</li>
			<li>You can contact our privacy officer using the following details:
				<ul>
					<li>Contact Person: Compliance Officer</li>
					<li>Phone Number: 1300 391 438</li>
					<li>Email: info@instantscripts.com.au</li>
					<li>Online: www.instantscripts.com.au</li>
					<li>Postal Address: Level 8 / 637 Flinders St., Docklands VIC 3008</li>
				</ul>
			</li>
			<li>The Websites may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. We are only responsible for the privacy practices and security of our Websites. We recommend that you check the privacy and security policies and procedures of other websites that you visit.</li>
		</ol>

		<h3>2. PERSONAL INFORMATION WE COLLECT</h3>
		<ol className="list-decimal">
			<li>We collect personal information about you from our interactions with you including through our Websites, Platform, telephone conversations with us, e-mails, chat and written and verbal communications.</li>
			<li>The types of personal information we may collect and hold vary depending on the nature of our interaction with you and may include:
				<ul>
					<li>identifying and contact information such as your name, address, contact number and email;</li>
					<li>health information such as information about your past, present or future health, the provision of telehealth services to you, and the provision of pharmacy scripts, pharmacy items and referrals. This may be information you provide to us via the Platform, the Websites or information provided by a Partner Doctor or Partner Provider through the Platform in the course of delivering services to you;</li>
					<li>other types of sensitive information such as your gender at birth and whether you are of Aboriginal or Torres Strait Islander descent; and</li>
					<li>government identifiers such as Medicare numbers and individual healthcare identifiers.</li>
					<li>We may also collect de-identified information via cookies on the Websites. This information includes your browser type, operating systems and other websites visited. This information does not include any of your personal information and will not be used to link back to you individually.</li>
				</ul>
			</li>
			<li>We may collect personal information about a range of individuals including:
				<ul>
					<li>patients;</li>
					<li>your next of kin or carer (<b>Representative</b>);</li>
					<li>healthcare providers including Partner Doctors and Partner Providers;</li>
					<li>job applicants, employees and contractors of InstantScripts; and</li>
					<li>third parties providing a service to InstantScripts.</li>
				</ul>
			</li>
			<li>If you are a patient, we primarily collect personal information about you from:
				<ul>
					<li>you, when you use the Platform and/or the Websites;</li>
					<li>you, when you contact us (whether by telephone, email, chat or through the Platform or Websites);</li>
					<li>any person who, on your behalf and with your consent, provides information about you such as your Representative; and</li>
					<li>any Partner Doctor or Partner Provider from whom you obtain services through the Platform.</li>
				</ul>
			</li>
			<li>If you engage with us as a Partner Doctor or Partner Provider, we may also collect personal information about your skills, qualifications and experience. Further details of this will be outlined in the Contractor Agreement or other agreements we have with you upon registering as a Partner Doctor or Partner Provider with InstantScripts.</li>
			<li>We may also collect personal information about you from third parties such as from Google, Facebook and Apple if you use these platforms to login to the Platform.</li>
		</ol>

		<h3>3. PURPOSES FOR WHICH WE COLLECT, HOLD, USE AND DISCLOSE YOUR PERSONAL INFORMATION</h3>
		<ol className="list-decimal">
			<li>We collect, hold, use and disclose personal information for a range of purposes including the following:
				<ul>
					<li>to communicate with you;</li>
					<li>for record keeping purposes;</li>
					<li>to provide to you the functionality of the Websites and the Platform (e.g. if you are a patient, to connect you to a Partner Doctor or Partner Provider through our Platform);</li>
					<li>to enable a Partner Doctor or Partner Provider to provide healthcare services through the Platform;</li>
					<li>as required for delivery of the services provided through our Platform (but not by us) to you, including sharing of your address and phone number with third party providers for the purposes of parcel delivery and tracking;</li>
					<li>as required for the ordinary operation of our Platform and Websites;</li>
					<li>to manage our relationship with you, and to review, develop and improve our services to you and your experience with us;</li>
					<li>to inform you about important matters relating to the Platform, the Websites or your personal information;</li>
					<li>for market research;</li>
					<li>to invoice and process any fees payable in relation to the services rendered through the Platform or Websites;</li>
					<li>for quality assurance and training purposes and as may be required by our insurers;</li>
					<li>to enable us to comply with laws and assist government or law enforcement agencies where we are required and authorised to do so;</li>
					<li>where there is a serious and imminent threat to an individual’s life, health, or safety; or a serious threat to public health or public safety; and</li>
					<li>for any purpose disclosed to you or required or authorised by or under law.</li>
					<li>Our range of products and services and our functions and activities may change from time to time.</li>
					<li>If you provide your email address, telephone and/or mobile phone number, you also consent to us using your email address, telephone and/or mobile phone number to contact you (including by telephone call, SMS or email) for any of the above purposes.</li>
					<li>With your consent, we may also use and disclose your information to enable healthcare assessment and accreditation providers to assess our healthcare standards and certify the services we provide.</li>
				</ul>
			</li>
			<li>If you are a patient, your health information collected by us will only be used or disclosed:
				<ul>
					<li>to enable a Partner Doctor or Partner Provider who is providing services to you through the Platform to provide those services;</li>
					<li>to third party suppliers and service providers, and other providers for the operation of our Platform, Websites and/or our business or in connection with providing our products and services to you (including for the purposes of delivering goods to you);</li>
					<li>to those who work for us (whether as an employee or a contractor), our officers and related bodies corporate;</li>
					<li>to payment systems operators (e.g. merchants receiving card payments, PayPal) and organisations who carry out credit, fraud and other security checks;</li>
					<li>to your Representatives and specific third parties authorised by you to receive health information held by us (such as your nominated doctor who you would like to share your health information with);</li>
					<li>should a Partner Doctor form the professional opinion that a patient is at imminent risk of harm, including self-harm, and where it is consistent with his or her professional and ethical obligations to do so, to contact emergency services (such as an ambulance service) to request a welfare check or similar intervention;</li>
					<li>to any relevant government authority, where we reasonably believe that such disclosure is necessary;</li>
					<li>to any entity with which we merge (or proposed to merge), or by which we are acquired (or proposed to be acquired) or to which we may sell all or part of our business and assets; and</li>
					<li>to enable us to comply with laws and assist government or law enforcement agencies where we are required and authorised to do so.</li>
				</ul>
			</li>
			<li>If you do not provide us with personal information about you that we request, we may not be able to provide you with the products services you request.</li>
			<li>We may de-identify, use and retain your personal information (excluding any eScript data) to conduct analysis on how the Platform, Websites and services are being used and to develop and enhance our Platform, Websites and services.</li>
		</ol>

		<h3>4. INFORMATION SHARING</h3>
		<ol className="list-decimal">
			<li>We share your personal information with our related bodies corporate (as defined in the Corporations Act 2001 (Cth)) to enable us to conduct our business and provide the Platform, Websites and our services.</li>
			<li>We also share your personal information with third parties, including:
				<ul>
					<li>persons or organisations engaged by us to assist us in carrying out the above purposes such as data storage providers, IT support providers, payment systems operators, providers of targeted online advertising and delivery partners; and</li>
					<li>Partner Doctors and Partner Providers who provide services to you through the Platform, to enable them to deliver the products and services that you request from them through the Platform.</li>
					<li>healthcare assessment and accreditation providers with your consent, to enable them to assess and certify the services we provide.</li>
				</ul>
			</li>
			<li>We do not share your personal information with other healthcare providers or pharmacists who are unconnected to the Platform, without your explicit consent.</li>
			<li>For the purpose of complying with our financial audit obligations, we are required to divulge limited personal information about you to our financial auditors. Wherever possible, personal information used for the purpose of our financial audit is anonymised.</li>
			<li>In addition to the above, if you are a patient, please refer to section 3.2 of this privacy policy for further information in relation to whom we may disclose your health information.</li>
			<li>We require Partner Doctors and Partner Providers who provide services through the Platform to take reasonable steps to protect personal information from unauthorised loss or unauthorised access or use. Those Partner Doctors and Partner Providers may be required to take further steps to protect personal information under the professional regulations applicable to them.</li>
			<li>You acknowledge and agree that, should we sell, merge or otherwise change control of our business, our company, the Platform or the Websites to a third party, we shall be permitted to disclose your personal information to the third party without giving notice or seeking prior consent from you. We shall also be entitled to assign the benefit of any agreements we have with you to the third party.</li>
			<li>We may disclose de-identified information to third parties for the purposes of reviewing the quality of services delivered and conducting clinical research.</li>
		</ol>

		<h3>5. STORAGE OF PERSONAL INFORMATION</h3>
		<ol className="list-decimal">
			<li>In order to help prevent unauthorised access, use or disclosure, we have put in place reasonable physical, electronic and managerial procedures to help safeguard and secure the personal information we collect.</li>
			<li>We store the personal information we collect and use Transport Layer Security (TSL) to provide users secure and private access. Presently our intention is to store personal information collected by us on servers located in Australia. By using the Platform, you consent to our storing your personal information in this manner.</li>
			<li>We take reasonable steps to protect your personal information, including the following:
			<ul>
				<li>Password protection: You are required to set up a secure password to use the Platform. The Platform does not permit automatic log-ins without that password. If you need to change your password, we use authentication methods to make sure it is you.</li>
				<li>Secure storage and handling: We use a combination of firewall barriers, encryption techniques, data segregation techniques, backup and authentication procedures to help maintain the security of the Platform and to protect your account and your personal information.</li>
				<li>Interoperability: We comply with robust interoperability requirements that aim to protect the flow and transfer of your data.</li>
			</ul>
			</li>
		</ol>

		<h3>6. COOKIES AND TRACKING TECHNOLOGY</h3>
		<ol className="list-decimal">
			<li>In common with many other website operators, we may use a standard technology called ‘cookies’ on our Websites. Cookies are small pieces of information that are stored by your browser on your computer’s hard drive and they are used to record how you navigate the Websites on each visit.</li>
			<li>Cookies that are used in any part of our Websites will not be utilised for collecting personally identifiable information and will only be used for internal management purposes.</li>
			<li>Most browsers automatically accept cookies, but you can usually change your browser to prevent cookies being stored. Please note, if you do turn cookies off this will limit the service that we are able to provide to you.</li>
		</ol>

		<h3>7. MARKETING AND PROMOTIONS</h3>
		<ol className="list-decimal">
			<li>When you sign up to use the Platform, and unless you subsequently opt out, you consent to us using your personal information for marketing and promotion purposes.</li>
			<li>If you consent to us using your personal information for these purposes, we may, from time to time, send you information in relation to products, services or other offers we think may be of interest to you, including to tell you about the services we offer on the Platform and the products and services offered by third parties. We will contact you via the preferred communication method you nominate through the Platform.</li>
			<li>We use third party vendor remarketing services including such as Google’s ‘Customer Match’ and Facebook’s ‘Custom Audience’ to display ads. This means if you have provided us with your contact details, unless you opt-out of receiving direct marketing from us, we may upload these to third party vendors who perform remarketing services on our behalf.</li>
			<li>We do not sell or disclose your information to third parties to market their products or services to you.</li>
			<li>You can opt out of marketing communications at any time by using the unsubscribe facility in the relevant message or contacting our privacy officer using the contact details set out at the start of this privacy policy.</li>
		</ol>

		<h3>8. YOUR RIGHTS</h3>
		<ol className="list-decimal">
			<li>Your rights in relation to the personal information held by us about you include:
				<ul>
					<li>Access: You can request a copy of your information, and to ask for it in a format that can be easily reused or transferred to another person or trusted healthcare provider.</li>
					<li>Correct: You can ask us to correct or update your information.</li>
					<li>Complain: You can express your concerns or complaints to us about your privacy or the way we are handling your personal information. We take your concerns seriously and will consider or investigate your complaint and endeavour to respond to your complaint within 14 days.</li>
					<li>Closing your account: If you would like to close your Personal Account or Family Account, please access the “My Profile” section of the Platform and under “Account Closure”, select “Request Closure”. You can also email us at support@instantscripts.com.au. If you request that your Personal Account or Family Account be closed, the details you have provided and all information relating to your Personal Account or Family Account will be archived and stored securely by InstantScripts for up to 7 years. If you request deletion of your data, our ability to comply with such requests may be limited by local law, in accordance with Health Records Act 2001 (Victoria), Principle 4 Data Security and Data Retention.</li>
				</ul>
			</li>
			<li>We will generally provide you with access to your personal information if practicable, and will take reasonable steps to amend any personal information about you which is inaccurate or out of date. In some circumstances and in accordance with applicable privacy laws, we may not permit you access to your personal information, or may refuse to correct your personal information, in which case we will inform you about the reason for this decision.</li>
			<li>If you are not satisfied with the way we handle your query or handle your personal information (including our response to your request to access or correct your personal information), you have a right to lodge a complaint with the Office of the Australian Information Commissioner (OAIC) by visiting the OAIC website. If your complaint relates to the handling of your health information and you reside in Victoria, New South Wales or the Australian Capital Territory, you can lodge a complaint with the relevant State/Territory health complaints commissioner.</li>
			<li>To exercise any of your rights in relation to your personal information, please contact our privacy officer using the contact details provided at the start of this privacy policy.</li>
			<li>If you provide personal information to us about a third party (such as your directors, employees or someone you have business dealings with) you must ensure that you are allowed to give that information to us and that we are allowed to collect that information.</li>
		</ol>

		<h3>9. GENERAL</h3>
		<ol className="list-decimal">
			<li>This privacy policy is effective from November 2022. As this privacy policy is updated from time to time, to obtain a copy of the latest version at any time, you should visit our Websites. Your continued use of the Websites and the Platform after changes are published constitutes your acceptance of the modified terms of this privacy policy.</li>
		</ol>
		<p>Last updated: November 2022</p>
	</div>
);

export default Privacy
