import React, { useState, useEffect } from 'react';

function keysDepressedListener(keys) {
    const [depressedKeys, setDepressedKeys] = useState({});

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (keys.includes(event.key)) {
                setDepressedKeys(prevKeys => ({ ...prevKeys, [event.key]: true }));
            }
        };

        const handleKeyUp = (event) => {
            if (keys.includes(event.key)) {
                setDepressedKeys(prevKeys => ({ ...prevKeys, [event.key]: false }));
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        // Cleanup function to remove the event listeners when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    return keys.every(key => depressedKeys[key]);
}

export default keysDepressedListener;