import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Dropdown, Form, Header, Message, TextArea } from "semantic-ui-react";
import med_model from "../../../models/med_model";
import obj_diff from "../../../xAppLib/helpers/obj_diff";
import { useSiteStatus } from "../../../xAppLib/Hooks";
import { useAsync } from "../../../xAppLib/Hooks/useAsync";
import API_service from "../../../xAppLib/providers/API_service";
import Alert from "../../NUI/Alert";
import { AsyncButton } from "../../UIelems/AsyncButton";
import { WithInfoIcon } from "../../med/Sections/HDR";
import { maxMissedCallsText } from "../util";
import { NoAnswerCancelConsTemplate, NoAnswerTemplate, WrongDetailsTemplate } from "./TextTemplates";

const consultCancelReasons = [
	{key: 'wrong_type', value: 'Thank you for choosing InstantScripts. Unfortunately, the incorrect consultation type was requested, so your request has been cancelled and refunded by our team. This could take a few days. Please rebook a <insert correct consultation type> consultation. Please call 000 if this is an emergency.', text: 'Incorrect consultation type'},
	{key: 'rude', value: 'Unfortunately, your request was cancelled because of rude, aggressive, or abusive behaviour. At InstantScripts we have a zero tolerance policy towards those behaviours.', text: 'Rude, aggressive or abusive behaviour'},
	{key: 'inappropriate', value: 'Thank you for choosing InstantScripts. Due to the nature of your request, we recommend booking a face-to-face consult with a doctor to assess this condition. Your request has been cancelled and refunded by our team, this may take a few days. Please call 000 if this is an emergency.', text: 'Inappropriate consultation booked for telehealth'},
	{key: 'wrong_med', value: 'Thank you for choosing InstantScripts. Unfortunately, the medication you have requested is not available via telehealth. Your request has been cancelled and refunded by our team, this may take a few days. Please call 000 if this is an emergency.', text: 'Requesting inappropriate medication'},
	{key: 'no_contact', value: 'Thank you for choosing InstantScripts. Unfortunately, we were unable to reach you after 3 attempts over 3 hours. Your request has been cancelled and refunded by our team, this may take a few days. Please rebook if you still require our assistance or call 000 if this is an emergency.', text: 'Unable to contact patient'},
	{key: 'other', value: '', text: 'Other reason (please specify)'},
];

export function ApproveScriptAction({disabled, save_note_msg, type, onSubmit}) {
	const script_hold = useSiteStatus('script_hold') || false;

	return (
		<>
			{script_hold && (
				<Alert error header="eScript maintenance">
					The eScript platform is currently undergoing maintenance. Please inform patient there may be delays to receive their eScript.
				</Alert>
			)}
			<AsyncButton
				size="big"
				disabled={disabled}
				onClick={onSubmit}
				fluid
				color="green"
				icon="check"
				content={`${save_note_msg}Approve ${type} ${script_hold?'(Placed in queue)':''}`}
			/>
		</>
	)
}

export function DeclineScriptAction({disabled, save_note_msg, type, onSubmit}) {
	const [reason, setReason] = useState('');
	const [show, setShow] = useState(false);

	return (
		<div>
			<Button
				size="big"
				disabled={disabled}
				onClick={() => setShow(v => !v)}
				fluid
				color="red"
				icon="exclamation"
				content={`${save_note_msg}Reject ${type}`}
			/>
			{show &&
				<>
					<br/>
					<Form className="mt-4">
						<TextArea placeholder='Reason to reject (min 30 characters)'
								  style={{minHeight: 30, width: '80%'}}
								  onChange={(t, d) => setReason(d.value)}
								  value={reason}
						/>
					</Form>
					<AsyncButton
						size="small"
						disabled={disabled || reason.trim().length < 30}
						onClick={() => onSubmit(reason)}
						fluid
						color="red"
						content="Send"
					/>
				</>
			}

		</div>
	)
}

export function BackToAwaitAction({disabled, save_note_msg, onSubmit, discard_note_msg, show_discard = true}) {
	return <>
		<p>Don't take a consult - set Awaiting doctor call - and return it back into Waiting Room:</p>
		<div className="flex flex-col space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
			<AsyncButton
				disabled={disabled}
				size="small"
				onClick={() => onSubmit(true)}
				fluid
				color="grey"
				content={`${save_note_msg}Cancel Consultation and return it to waiting room`}
			/>
			{show_discard && <AsyncButton
				disabled={disabled}
				size="small"
				onClick={() => onSubmit(false)}
				fluid
				color="grey"
				content={`${discard_note_msg}Cancel Consultation and return it to waiting room`}
			/>}
		</div>
	</>
}

export function NoAnswerAction({disabled, save_note_msg, onSubmit, discard_note_msg, canCancelCons, scr_obj, show_discard = true}) {
	const [reason, setReason] = useState(NoAnswerTemplate);
	const [show, setShow] = useState(false);
	const [cancelCons, setCancelCons] = useState(canCancelCons);
	const msg_prefix = `Proceed with${!reason && 'out' || ''} message - `;
	const actionText = cancelCons ? 'cancel the consultation' : 'return it back into Waiting Room';

	useEffect(() => {
		cancelCons ? setReason(NoAnswerCancelConsTemplate) : setReason(NoAnswerTemplate);
	}, [cancelCons]);

	return (
		<div>
			<p>Can't reach pts - Set status No Answer and {actionText}:</p>
			<Button
				size="small"
				disabled={disabled}
				onClick={() => setShow(v => !v)}
				fluid
				color="blue"
				content={`${save_note_msg}Set No Answer and ${actionText}`}
			/>
			{show &&
				<>
					{canCancelCons && (
						<>
							<Alert error className="my-4">
								<WithInfoIcon color="red">{maxMissedCallsText(scr_obj.hist)}</WithInfoIcon>
							</Alert>
							<Checkbox
								onChange={() => setCancelCons((prev) => !prev)}
								checked={cancelCons}
								label="Cancel consultation and refund patient"
							/>
						</>
					)}

					<Form className="mt-4">
						<TextArea placeholder='Add message to patient' style={{minHeight: 30, marginBottom: 16}}
								  onChange={(t, d) => setReason(d.value)}
								  value={reason}
						/>
					</Form>
					<div className="flex flex-col space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
					<AsyncButton
						size="small"
						disabled={disabled}
						onClick={() => onSubmit(reason, true, cancelCons)}
						fluid
						color="blue"
						content={`${msg_prefix}${save_note_msg}Set No Answer and ${actionText}`}
					/>
					{show_discard && <AsyncButton
						disabled={disabled}
						size="small"
						onClick={() => onSubmit(reason, false, cancelCons)}
						fluid
						color="blue"
						content={`${msg_prefix}${discard_note_msg}Set No Answer and ${actionText}`}
					/>}
					</div>
				</>
			}
		</div>
	)
}

export function WrongDetailsAction({disabled, save_note_msg, onSubmit}) {
	const [reason, setReason] = useState(WrongDetailsTemplate);
	const [error, setError] = useState('Please enter a reason for cancelling the consultation');
	const [show, setShow] = useState(false);

	useEffect(() => {
		if(reason){
			const matches = reason.match(/<([^>]+)>/g);
			if(matches?.length > 0){
				setError(matches[0] + ' in the above message.');
			}
			else if(/[<>]/.test(reason)){
				setError('Please replace everything between and including < >');
			}
			else{
				setError('');
			}
		}
	}, [reason]);

	return (
		<div>
			<p>Wrong Details or Request - cancel request and remove it from the Waiting Room:</p>
			<Button
				disabled={disabled}
				size="small"
				onClick={() => setShow(v => !v)}
				fluid
				color="red"
				content={`${save_note_msg}Error Consultation and remove it from waiting room`}
			/>
			{show &&
				<>
					<br/>
					<Form className="mt-4">
						<Dropdown
							placeholder={'Select a reason for canceling consultation'}
							fluid
							selection
							value={reason}
							options={[{ key:'-', value: '', text: '-- please select --' }].concat(consultCancelReasons)}
							onChange={(_, d) => setReason(d.value)}
						/>
						<TextArea placeholder='Cancellation reason to patient' style={{minHeight: 30, width: '80%', marginTop: 16, marginBottom: 16}}
								  onChange={(t, d) => setReason(d.value)}
								  value={reason}/>
					</Form>
					{(!reason || error) && <Alert error className='mt-0' content={error || 'Please enter a reason for canceling the consultation'}/>}
					<AsyncButton
						disabled={disabled || !reason || error}
						size="small"
						onClick={() => onSubmit(reason)}
						fluid
						color="red"
						content={`Proceed with${!reason && 'out' || ''} message - ${save_note_msg}Error Consultation and remove it from waiting room`}
					/>
				</>
			}
		</div>
	)
}

export async function loadMedsForAppScriptEnrolment(med_ids, selected) {
	try {
		const meds = await Promise.all(med_ids.map(async med_id => [med_id, await med_model.load_med(med_id)]));

		return Object.fromEntries(meds.map(([med_id, med]) => [med_id, { enrolled: selected?.[med_id] !== false, med }]))
	} catch (e) {
		return {};
	}
}

export function AppScriptEnrolment(props) {
	const {
		meds,
		suggested_meds,
		error,
		minLength,
		required = true,
		onUpdate,
		className,
	} = props;
	const message = props.message ?? '';

	const describe = med => `${med.name} ${med.size || ''} ${med.qnty && ` (x${med.qnty})`}`;

	const suggestedMedOpts = (suggested_meds || [])
		.filter(med => !meds[med.m])
		.map(med => ({
			key: med.m,
			value: med.m,
			text: describe(med)
		}))
		.sort((x, y) => x.text.localeCompare(y.text));

	const has_meds = Object.keys(meds).length > 0;
	const some_enrolled = Object.keys(meds).some(med => meds[med]?.enrolled);
	const doUpdate = ({ meds, message }) => {
		const some_enrolled = Object.keys(meds).some(med => meds[med]?.enrolled);
		onUpdate({
			meds,
			message,
			error: !has_meds || some_enrolled
				? null
				: required && message.trim().length === 0
				? 'Please enter a reason for not enrolling the patient for text based scripting'
				: minLength > 0 && message.trim().length < minLength
				? `Please enter at least ${minLength} character${minLength===1?'':'s'}`
				: null
		});
	}

	const charsRemaining = minLength - message.trim().length;
	const s = Object.entries(meds).length === 1 ? '' : 's';
	return (
		<div className={className}>
			<Header as='h5'>Text Based Script Enrolment</Header>
			{suggestedMedOpts.length > 0 && (
				<Dropdown
					placeholder={'Suggested medications'}
					fluid
					selection
					value={null}
					options={[{ key:'-', value: null, text: '-- please select --' }].concat(suggestedMedOpts)}
					onChange={(_, d) => {
						const mid = d.value;
						if (!mid) {
							return;
						}

						const med = suggested_meds.find(m => m.m === mid);
						doUpdate({
							meds: {...meds, [mid]: {enrolled: true, med}},
							message
						})
					}}
				/>
			)}
			{has_meds ? (<>
				<p>
					The following medication{s} will be available to order via text based scripting for the next 12 months.
				</p>
				<p>
					The patient will still be required to complete a digital questionnaire, and will still need review and
					approval by a doctor.
				</p>
			</>) : (
				<Alert info className="mt-2">
					Patient will <em>not</em> be enrolled for text based scripting for any medication as a result completing this consult.
				</Alert>
			)}
			{Object.entries(meds)
				.map(([key, { enrolled, med }]) => ({ key, med, checked: !!enrolled, label: describe(med) }))
				.sort((x, y) => x.label.localeCompare(y.label))
				.map(({ key, med, checked, label }) => (
					<div key={key}>
						<Checkbox toggle
								  onChange={(e, d) => doUpdate({
									  meds: {...meds, [key]: {enrolled: d.checked, med}},
									  message
								  })}
								  checked={checked}
								  label={<label className="pb-4"><strong>{label}</strong><br />{med.alt_name}</label>}
						/>
					</div>
				))
			}
			{has_meds && !some_enrolled && (
				<Message warning
						 icon="warning sign"
						 header={<Message.Header>App Scripts Eligibility</Message.Header>}
						 content={<Message.Content>
							 The patient may be prevented from accessing text based consultations for this medication
							 and will require a review consultation the next time they use our platform.
						 </Message.Content>}
				/>
			)}
			{has_meds && !some_enrolled && (
				<Form>
					<label>Reason for not enrolling the patient
					<TextArea
						placeholder='Please indicate why the patient should not be able to access text based consultations (this is NOT seen by the patient)'
						fluid
						style={{minHeight: 120}}
						onChange={(_t, d) => doUpdate({ meds, message: d.value })}
						value={message}
					/>
					</label>
					{charsRemaining > 0 && <span>{charsRemaining} more character{charsRemaining===1?'':'s'} to go</span>}
				</Form>
			)}
			{error && <Alert error content={error}/>}
		</div>
	)
}

export function AdminEditAppScriptEnrolment({scr_obj, onUpdate}) {
	const {sid, meta = {}} = scr_obj;
	const initial = {
		enrolled: meta.enrol_app_scripts,
		message: meta.enrol_msg || ''
	};
	const [{ enrolled, message, error }, setState] = useState(initial);
	const has_changes = !error && enrolled !== initial.enrolled || message !== initial.message;
	const can_submit = !error && has_changes;

	const update = useAsync({
		immediate: false,
		fn: async () => {
			const initialValues = {enrol_app_scripts: initial.enrolled, enrol_msg: initial.message};
			const newValues = {enrol_app_scripts: enrolled, enrol_msg: enrolled ? null : message};
			const data_diff = obj_diff(initialValues, newValues);

			if (Object.keys(data_diff.to || {}).length === 0) return

			const params = {sid, diff: {...data_diff, part: 'meta'}};
			await API_service.load_data('scripts/upd_script', params);
			onUpdate?.({meta: {...meta,...newValues}});

		}

	});
	return (
		<div className="space-y-2">
			<AppScriptEnrolment enrolled={enrolled} message={message} showCurrentEligibility={false} onUpdate={setState} required/>
			<AsyncButton onClick={update.fn} disabled={!can_submit} color="blue">
				Update
			</AsyncButton>
		</div>
	)
}

export function PutOnHoldAction({disabled, onSubmit}) {
	const [reason, setReason] = useState('');
	const [show, setShow] = useState(false);
	const valid = useMemo(() => Boolean(reason.trim()), [reason]);
	const [showError, setShowError] = useState(false);

	return (
		<div>
			<p>Put on hold - The consultation will not go back to the waiting room.</p>
			{show ?
				<>
					<Form className="mt-4">
						<TextArea placeholder='Add message for why this consultation is on hold' style={{minHeight: 30, marginBottom: 16}}
								  onChange={(t, d) => setReason(d.value)}
								  value={reason}
						/>
						{showError && <Alert afterHr error className='mt-0' content="Please enter a reason for putting the consultation on hold"/>}
					</Form>
					<div className="flex flex-col space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
					<AsyncButton
						size="small"
						disabled={disabled}
						onClick={() => {
							if(valid){
								setShowError(false);
								onSubmit(reason, true);
							}
							else{
								setShowError(true);
							}
						}}
						fluid
						color="yellow"
						content='Put the consultation on hold'
					/>
					</div>
				</>
			 : <Button
				size="small"
				disabled={disabled}
				onClick={() => setShow(v => !v)}
				fluid
				color="yellow"
				content={`Put the consultation on hold`}
			/>}
		</div>
	)
}

export function WrongNumberAction({disabled, onSubmit}) {
	return <>
		<p>Wrong Number - set Wrong Number status and return it back into Waiting Room</p>
		<div className="flex flex-col space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
			<AsyncButton
				disabled={disabled}
				size="small"
				onClick={() => onSubmit(true)}
				fluid
				color="teal"
				content={`Wrong number - return it to waiting room`}
			/>
		</div>
	</>
}