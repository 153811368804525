/* eslint-env node */
const proof_boxes = [
    {
        title: 'Trusted by over 2 million patients',
        img: 'icon_multi-people.svg'
    },
    {
        title: 'Australian-Registered Doctors',
        img: 'icon_steth-heart.svg'
    },
    {
        title: 'Highly Rated Customer Service',
        img: 'icon_star-heart.svg'
    },
    {
        title: 'Australian Digital Health Agency registered',
        img: 'icon_steth.svg',
    },
]

const manage_boxes = [
    {
        title: 'Doctors available 24/7',
        img: 'icon_calendar-3.svg'
    },
    {
        title: 'Available anywhere in Australia',
        img: 'icon_pill-bottle.svg'
    },
    {
        title: 'eScript in minutes',
        img: 'icon_clock.svg'
    },
    {
        title: 'Medication delivery',
        img: 'icon_delivery-truck.svg'
    },
]

const treatment_boxes = [
    {
        id: 1,
        title: 'Online Prescriptions',
        desc: 'Run out of your medication and can\'t get to your doctor? You can request a script for over 300 common medications.',
        img: 'icon_smartphone-cross.svg',
        cta: 'Request a Script',
        link: '/m/pharm/online/prescription',
        class: 'search',
        order: 0
    },
    {
        id: 2,
        title: 'Telehealth Consultations',
        desc: 'Need medical advice today? Our team of Australian-registered doctors are available 24/7.',
        img: 'icon_document-stamp.svg',
        cta: 'Request a Consultation',
        link: '/cat/DocCons',
        order: 1
    },
    {
        id: 3,
        title: 'Treatment Plans',
        img: 'icon_document-sign-pen.svg',
        cta: 'Request a Treatment Plan',
        order: 0
    },
    {
        id: 4,
        title: 'Mental Health Care Plans',
        desc: 'Want mental health support? We can help you access bulk-billed Mental Health Care Plans from home.',
        img: 'icon_document-sign-pen.svg',
        cta: 'Request a Mental Health Care Plan',
        link: '/m/pharm/DocCons/DocConsMHCPCopay',
        order: 0
    },
    {
        id: 5,
        title: 'Specialist Referrals',
        desc: 'Need to see a specialist? We can help you access referrals from the comfort of home.',
        img: 'icon_document-sign-pen.svg',
        cta: 'Request a Specialist Referral',
        link: '/c/pharm/express-specialist-referrals',
        order: 0
    },
    {
        id: 6,
        title: 'Blood Tests',
        desc: 'Do you need a blood test? We can help you access referrals for a range of blood tests from the comfort of home.',
        img: 'icon_blood-test.svg',
        cta: 'Request a Blood Test',
        order: 0
    },
    {
        id: 7,
        title: 'Antiviral Treatment',
        desc: 'Find out if you are eligible for the COVID-19 antiviral treatment.',
        img: 'icon_document-sign-pen.svg',
        cta: 'Find out more',
        link: '/covid-antivirals-1/',
        order: 0
    },
    {
        id: 8,
        title: 'PCR Test Referral',
        desc: 'Not sure if you have COVID? Request a referral for a COVID-19 PCR Test.',
        img: 'icon_document-sign-pen.svg',
        cta: 'Request a Referral',
        link: '/covid-pcr-test/',
        order: 0
    },
    {
        id: 9,
        title: 'Medical Certificates',
        desc: 'Not feeling well and need to take a day off work? Request a medical certificate from home.',
        img: 'icon_certificate.svg',
        cta: 'Request a Medical Certificate',
        link: '/c/pharm/certs/',
        order: 0
    }
]

module.exports.lp_template = (data) => {
    const clr = {
        black: '#272727',
        blue: '#0065f2',
        light_blue: '#E2F3FF',
        gray: '#FAFAFA'
    }
    let btn = `padding:.75rem 1.5rem;color:#FFF;border-radius:.28rem;`

    const { meta, header_img, hdr_size, cat, title, info_img, info_title, info_desc, more_info, faq_sources, faq, treatment_ctas, treatment_desc, treatment_link, blood_test_link, find_btn=true, speak_btn } = data
    const img_path = 'https://storage.googleapis.com/instant-med-public/landing-pages/';

    const treatment = treatment_boxes.map(box => {
        if (box.id == 3) {
            box.desc = treatment_desc || 'On regular medication? Never run out of your medication again with our easy and convenient home delivery service.'
            box.link = treatment_link || '/c/pharm/subscr'
        }
        if (box.id == 6) { box.link = blood_test_link || '/c/pharm/patho' }
        return box;
    });

    const header = `
                <div class="lp-header flex md:justify-center bg-white border-b border-is-gray-50 w-full mb-4 p-4 md:pb-8 md:pt-6">
                    <div class="ui container" style="margin:0!important;">
                        <div class="relative w-full flex flex-wrap md:flex-nowrap gap-4 md:gap-6">
                            <div class="w-full md:w-[40.31rem] h-full block order-2 md:order-1">
                                <h1 style="color:#272727;" class="w-full mb-4">${title}</h1>
                                <p class="mb-4">Manage your health from the comfort of home. Our Australian-registered doctors are available from anywhere in Australia.</p>
                                <div class="mb-4" style="margin-bottom:2rem;"><span class="bg-white py-2 px-4 rounded-full shadow font-bold mr-2">24/7</span> <span class="bg-white py-2 px-4 rounded-full shadow font-bold">7 days a week</span></div>
                                <a href="${speak_btn || '/cat/DocCons'}" style="${btn}" class="search ui button fluid sm:max-w-fit primary !mb-4 md:!mb-0">Speak to a Doctor</a>
                                ${find_btn ? `<a href="/m/pharm/online/prescription" style="${btn}" class="search ui button fluid sm:max-w-fit primary basic !text-is-blue hover:!text-white">Find your medication</a>` : ''}
                            </div>
                            <div class="order-1 md:order-2 w-full h-[15.625rem] md:w-[34.69rem] lg:h-auto bg-no-repeat rounded-2xl" style="min-height:250px;background-size:${hdr_size || '160%'};background-position:60% 35%;background-image: url(${img_path + header_img}.jpg)"></div>
                        </div>
                    </div>
                </div>`;

    const proof = `
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:pt-8 rounded-lg">
                    ${proof_boxes.map(el =>
                    `<div class="flex items-center bg-white rounded-full shadow py-4 px-6 text-center gap-4">
                        <img class="h-8 p-1 lg:mx-auto shrink-0" src="${img_path + el.img}"/>
                        <p class="text-left font-bold text-base">${el.title}</p>
                    </div>`).join(' ')}
                </div>
                `

    const treatment_options = `
        <div class="py-4 md:py-8">
            <h2 class="mb-4 md:mb-8">${cat} treatment options</h2>
            <div class="flex flex-wrap lg:flex-nowrap gap-4">
            ${treatment.filter(t => treatment_ctas.includes(t.id)).sort((a, b) => a.order - b.order).map(el =>
                `<div class="bg-white rounded-lg w-full lg:w-1/3 shadow-sm hover:shadow-lg transition duration-100 ease-in-out p-4 lg:p-6">
                    <a href="${el.link}" class="hover:text-is-black flex h-fit items-center justify-start gap-4">
                        <div class="flex items-center md:items-start md:flex-col gap-4">
                            <div class="block shrink-0 rounded-full bg-no-repeat bg-center h-14 w-14 md:h-[72px] md:w-[72px] bg-is-blue-20" style="background-image: url(${img_path + el.img});background-size:50%;"></div>
                            <div>
                                <p class="mt-0 mb-2 font-bold">${el.title}</p>
                                <p class="text-sm">${el.desc}</p>
                            </div>
                        </div>
                        <img class="h-5 p-1 block" src="${img_path}icon_chevron.svg" style="top:0;right:.5rem;"/>
                    </a>
                </div>`).join(' ')}
            </div>
        </div>`

    const manage = `
        <div class="py-4 md:py-8">
            <h2 class="mb-4 md:mb-8">Manage your health from the comfort of home</h2>
            <div class="flex flex-wrap lg:flex-nowrap gap-4 justify-center sm:justify-between">
                ${manage_boxes.map(el =>
                    `<div class="flex w-full sm:w-[343px] h-[32px] lg:w-fit text-center gap-4">
                        <img class="h-6 w-6" src="${img_path + el.img}"/>
                        <p class="text-left font-bold text-base">${el.title}</p>
                    </div>`).join(' ')}
            </div>
        </div>`

    const info = info_desc.length>0 ? `
        <div class="py-4 md:py-8 flex flex-wrap md:flex-nowrap justify-between gap-4 md:gap-8">
            <div class="w-full md:w-auto flex-grow rounded-xl h-auto bg-cover" style="min-height:200px;background-image: url(${img_path + info_img}.jpg)"></div>
       
            <div class="w-full md:w-2/3 md:pr-4">
                <h2 class="mb-4 md:mb-8">${info_title}</h2>
                <p class="block" style="margin-bottom:0;">${info_desc}</p>
            </div>
         </div>`:'';

    const faqs = (el) => {
        return `<details class="border-t-2 border-is-gray-30 p-4">
                    <summary class="my-4 font-bold relative" style="list-style:none;position:relative;cursor:pointer;">
                    <h5 class="max-w-[90%]">${el.question}</h5> <img class="absolute h-6 p-1" src="${img_path}icon_chevron.svg" style="top:0.1rem;right:0;"/></summary>
                    <div class="pb-4 max-w-[95%]">${el.answer}</div>
                </details>`
    }

    const faq_list = faq?.length>0 ?`
        <div class='lp-faqs py-4 md:py-8 flex flex-col md:flex-row md:space-x-24'>
            <div class="w-full md:w-1/3 mb-4">
                <h2>Frequently<br/> asked questions</h2>
                <div class="flex md:mb-4 md:mb-0 cursor-pointer text-is-blue" id="chat"><img class="mr-2 h-6" src="${img_path + 'icon_chat.svg'}" style="color:${clr.blue};"/><p class="text-is-blue underline text-sm font-semibold hover:no-underline">Still need help? Chat to us.</p></div>
            </div>

            <div class="hidden md:block w-2/3">${faq.map(el => faqs(el)).join(' ')}</div>
            <div class="md:hidden w-full"> ${faq.slice(0, 3).map(el => faqs(el)).join(' ')}
                ${faq.length > 3 ? `<details class="lp-info">
                <summary class="relative font-bold" style="list-style:none;position:relative;cursor:pointer;">
                    <span class="more text-is-blue underline text-sm font-bold">Show more</span>
                </summary>
                ${faq.slice(3, faq.length).map(el => faqs(el)).join(' ')} ` : ''}
            </div>
        </div>`:'';

    const blogs = more_info?`
        <div class="lp-more-info py-4 md:py-8 md:pb-4 w-full mx-auto">
            <h2 class="mb-4 md:mb-8">Read more about ${["IBS", "COVID-19", "UTI", "COPD"].includes(cat) ? cat : cat.toLowerCase() }</h2>
            <div class="flex flex-nowrap gap-6 overflow-x-scroll md:overflow-x-hidden scroll-hidden md:pb-4">
            ${more_info.map(el =>
                `<a href="http://www.instantscripts.com.au/${el.link}" class="blog rounded-lg shadow-sm hover:shadow-lg transition duration-100 ease-in-out w-full bg-white md:flex-1 hover:text-is-black">
                    <div class="rounded-t-lg bg-cover bg-center w-[230px] md:w-full" style="height:165px; background-image: url(${img_path + el.img}.jpg)"></div>
                    <div class="w-full px-4 pt-4 pb-8 rounded-b-lg"><p class="font-bold">${el.title}</p></div>
                </a>`).join(' ')}
            </div>
        </div>`: '';

    const sources = faq_sources ?`<div class="py-8 text-is-black-50"><p class="text-sm mb-2">Sources:</p><p class="text-sm">${faq_sources}</p></div>`:'';
    
    return `
            <div style="margin:0 auto;">
                ${header}
                <div class="ui container">
                    ${proof}
                    ${treatment_options}
                    ${manage}
                    ${info}
                    ${blogs}
                    ${faq_list}
                    ${sources}
                    <a href="#" class="block text-center text-is-blue underline text-sm font-bold">Back to top</a>
                </div>
            </div>`
}
