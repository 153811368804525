import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, Form, List, Icon, Select, Grid } from "semantic-ui-react";
import user_model from "models/user_model";
import ProfileEdit from "xAppLib/Users/ProfileEdit";
import { useRef } from "react";
import gtm from "../providers/gtm";

const ENABLE_MULTI = true
const DEBUG = false

const ProfileSelect = ({ children, profiles, onRefreshProfiles, autocomplete, active = 0, canSelect = false, onSelect = null, canBrowse = true, canDisplayProfile = true, medForm=false, canEditDisplayProfile= true, requireAddress = false, showErrors = false, need_ihi, disableParentEdit, onNewProfiles, fields, shouldInlineEdit, acc_must_verif }) => {
	const profs = profiles||app.user.profs
	if (!profs)
		return null
	const [_profiles, setProfiles] = useState(profs);
	const [current, setCurrent] = useState({...profs[active]});
	const [showProfiles, setShowProfiles] = useState(false);
	const [edit, setEdit] = useState(false);
	const gone = useRef(false)

	useEffect(() => {
		DEBUG && console.log("setProfiles()",profiles);
		if (!profiles)
			return
		setProfiles(profiles);
	}, [profiles]);

	useEffect(() => {
		DEBUG && console.log("setCurrent()",{..._profiles[active]});
		setCurrent({
			..._profiles[active],
			cons_has_meds: _profiles[active].cons_meds === 'no' && 'no',
			cons_has_medhist: _profiles[active].cons_medhist === 'no' && 'no',
			yogp_want: !!Object.keys(_profiles[active].yogp ?? {}).length,
		});
	}, [active,_profiles]);

	const selectProfile = useCallback((p, i) => {
		DEBUG && console.log("selectProfile",p,i);
		onSelect?.(p, i);
		setShowProfiles(false);
	});

	const refresh = useCallback((updateType) => {
		DEBUG && console.log("refresh()",updateType);
		if (gone.current) {
			console.log("gone");
			return
		}

		if (profiles)
			return
		
		if (updateType == 'ProfileUpdated') {
			setProfiles([...app.user.profs]);
			onRefreshProfiles?.()
		}
	},[profiles])

	useEffect(() => {
		if (profiles)
			return
		app.on(app.events.USER_UPD, refresh);
		return () => {
			gone.current = true
			app.off(app.events.USER_UPD, refresh);
		};
	}, [refresh,profiles]);

	const browseButton = medForm ? <div className="md:ml-2 mt-2 md:mt-0">
		<Button
			fluid
			basic
			onClick={(_) => {setShowProfiles(true);gtm.log('', 'modal_open', {modal: 'family_profiles'})}}>
				Add Profile
		</Button>
		</div> : 
		<Button
			icon
			color="green"
			type="button"
			data-testid="button-family-profiles"
			onClick={(_) => {setShowProfiles(true);gtm.log('', 'modal_open', {modal: 'family_profiles'})}}
		>
			<Icon name="users" /> Family Profiles
		</Button>

	const openCroCondModal = () => {
		app.trigger(app.events.SHOW_POPUP, {
			txt: 'Select Your Chronic Conditions',
			pn: 'chro-cond',
			noCloseBtn: true,
			cst_modal: true
		})
	};

	const saveProfile = async (d) => {
		return new Promise((resolve) => {
			const { uid, parent } = edit || current || {};
			user_model.save_prof(
				{ uid, parent, ...d },
				(r) => {
					resolve(r);
					if (r.res == "ok") {
						setEdit(false);
						onNewProfiles?.(r.profile)
						refresh('ProfileUpdated');
					}
					
				},
				!uid ? parent : edit || current
			);
			gtm.log('', 'profile', {update_type: edit.parent && !edit.uid ? "added" : "updated"})
		})
	}

	const handleOnEditClick = (profile) => {
		setEdit({ 
			...profile, 
			cons_has_meds: profile.cons_meds === 'no' && 'no', 
			cons_has_medhist: profile.cons_medhist === 'no' && 'no',
			yogp_want: !!Object.keys(profile.yogp ?? {}).length,
		});
	}

	return (
		<>
			{canDisplayProfile && <>
					{canSelect && _profiles.length > 1 && <><Form.Field 
						inline
						// class="border-b border-is-gray-50"
					>
						
						<label style={{marginBottom: '.5rem'}}>Select a Profile</label><br/>
						<div className="md:flex justify-between">
							<Select 
								className='ui form'
							onChange={(e, { name, value }) => {
								selectProfile(_profiles[value],value)
							}}
							value={active}
								options={_profiles.map((p,i)=>({key:p.uid,value:i,text:<>{p.first_name} {p.last_name}</>,description:p.desc||'Main Profile'}))}
							/>
							{browseButton}
						</div>
					<hr className="h-px bg-is-gray-50 border-0 mt-4 -mx-4"></hr>
					</Form.Field></>
					}

					<ProfileEdit showErrors={showErrors} requireAddress={requireAddress} edit={false} shouldInlineEdit={shouldInlineEdit} onUpdate={saveProfile} data={current} showBtns={false} need_ihi={need_ihi} fields={fields} />
			
				</>}

			<div className="flex mt-4 gap-2">
				{canEditDisplayProfile && canDisplayProfile && !shouldInlineEdit && <Button type="button" className={`${medForm && 'w-full'}`} primary={acc_must_verif} basic={!acc_must_verif} onClick={(_) => setEdit(current)}>
					Edit
				</Button>}
				{ENABLE_MULTI && canBrowse && !app.settings.iprep &&!app.settings.ileg && !medForm && browseButton}
				{canDisplayProfile && !medForm && <Button icon onClick={openCroCondModal} color='green'><Icon name='medrt' /> My Conditions</Button>}
				{children}
			</div>

			{<BrowseProfiles profiles={_profiles} show={showProfiles} onShow={setShowProfiles} onEdit={handleOnEditClick} onSelect={selectProfile} canSelect={canSelect}  disableParentEdit={disableParentEdit}/> }
			
			<Modal open={!!edit} closeIcon onClose={(_) => {
				setEdit(false);
				gtm.log('', 'modal_close', {modal: 'family_profiles'})
			}}>
				<Modal.Header>
					{edit.parent && !edit.uid ? "New Profile" : "Edit Profile"}
				</Modal.Header>
				<Modal.Content>
					<Form autoComplete={autocomplete}>
						<ProfileEdit
							data={edit}
							fields={fields}
							requireAddress={requireAddress}
							showErrors={showErrors}
							onCancel={(_) => setEdit(false)}
							onUpdate={saveProfile}
						/>
					</Form>
				</Modal.Content>
			</Modal>
		</>
	);
};

export const BrowseProfiles = ({profiles,show,onShow,onEdit,onSelect, active, canSelect, disableParentEdit, medForm}) => {

	const template = () => {
		const profile = profiles?.[0];
		return {
			last_name: profile?.last_name,
			mobile: profile?.mobile,
			full_address: profile?.full_address,
			parent: profile?.uid
		}
	};

	if (!show)
		return null

	return (
		<>
			<Modal
				open
				closeIcon
				onClose={(_) => onShow(false)}
			>
				<Modal.Header>
					{ENABLE_MULTI && (
						<Button
							basic
							floated="right"
							color="blue"
							onClick={(_) => onEdit( template() )}
						>
							Add Profile
						</Button>
					)}
					<div>Profiles</div>
					<small className="ui text grey">
						<small>Manage your family profiles</small>
					</small>
				</Modal.Header>
				<Modal.Content style={{ padding: 0 }}>
					<List divided relaxed>
						{profiles.map((p, i) => (
							<List.Item
								style={{ padding: "1.25rem 1.5rem" }}
								color={i == active ? "green" : undefined}
								key={i}
							>
								<List.Content floated="right">
									<div className="ui two buttons">
										{canSelect && (
											<Button
												//basic
												color="green"
												size="small"
												disabled={i == active}
												onClick={(_) => onSelect(p, i)}
											>
												{i == active
													? "Selected"
													: "Select"}
											</Button>
										)}
										{!(disableParentEdit && !p.parent) && (
											<Button
												//basic
												color="blue"
												size="small"
												onClick={(_) => onEdit(p)}
											>
												Edit
											</Button>
										)}
									</div>
								</List.Content>
								{/* <List.Icon name='user' color='grey' size='large' /> */}
								<List.Content>
									<List.Header as="h5" style={{ marginTop: 0 }}>
										{p.first_name} {p.last_name}{" "}
										{p.ihi_ihirecordstatus == "Verified" &&
											p.ihi_ihistatus == "Active" && (
												<small className="text grey">
													{" "}
													<Icon
														name="star"
														color="yellow"
													/>{" "}
													Verified Account
												</small>
											)}
									</List.Header>
									<List.Description>
										<p className="text-sm">{i == 0 ? "Primary Profile" : p.desc}</p>
									</List.Description>
								</List.Content>
							</List.Item>
						))}
					</List>
				</Modal.Content>
			</Modal>

	</>
	);
};
 


export default ProfileSelect;
