import React, { Component } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Segment,
		Dimmer,
	} from 'semantic-ui-react'

import DataShow from './DataShow';

import getNestedObject from 'xAppLib/helpers/getNestedObject'
import { cls } from "../../views/NUI/utils";

class ListTable extends Component {

	render() {
		const { data, loading = true , children, inverted=false,color=null,fixed=false, style ={}, className } = this.props
		const itm_rows = !Array.isArray(children) ? [children] : children
		return (
			<Segment className="list-table" basic style={{padding:0,...style}}>
			    <Dimmer active={loading} inverted={!inverted}>
			      <Loader />
			    </Dimmer>

			<Table striped selectable color={color} inverted={inverted}  fixed={fixed} style={{margin:0,height:'100%'}} className={className}>
				<Table.Header>
					<Table.Row>
				       {itm_rows[0](null)}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{/*loading && !data && <Table.Row><Table.Cell singleLine><Loader active inline/></Table.Cell></Table.Row>*/}
					{data && this.data_render() }
				</Table.Body>
			</Table>
		</Segment>
			
		);
	}

	data_render() {
		const { children, data, showWarnings = true } = this.props
		const itm_rows = !Array.isArray(children) ? [children] : children

		return data && Array.isArray(data) && data.length && data.reduce((flat, r,i)=>{
			return flat.concat(itm_rows.map((row_render_func,rowIndex)=> row_render_func(r,i) && <Table.Row className={cls('relative', this.props.rowClass?.(r, i))} key={(this.props.rowKey?.(r) || i)+'-'+rowIndex+"-view-col"} warning={showWarnings && (r.is_script!=undefined&&!r.is_script || r.show_row_warning)} error={r.show_row_error} disabled={r.show_row_disabled} >
				 { row_render_func(r,i)}
			</Table.Row>))
		}, []) || <Table.Row><Table.Cell colSpan={100}>No records</Table.Cell></Table.Row>

	}
 
}

class ListTableCell extends Component {
	render() {
		const { row , index, visible = true, singleLine = false, colSpan, collapsing, label, path, type, f, children, onClick, funcs, style, extra_show } = this.props
		
		if (!visible)
			return null
			
		const header = !row
		if (!row)
			return <Table.HeaderCell singleLine={singleLine} colSpan={colSpan}>{f && f.name || label}{f?.tttip && <Popup content={f.tttip} trigger={<Icon name='info circle' size='small' color='grey' />} />}{extra_show}</Table.HeaderCell>

		let value = children || '-'
		if (f) {
			value = DataShow.show_data_field(row, {...f, funcs},null,null,null,false, this.props)
		}
		else if (type) {
			value = DataShow.show_data_field(row, {...this.props, funcs}) 
		}
		else if (row && path) {
			value = getNestedObject( row, path, '.' )
		}

		return (
			<Table.Cell collapsing={collapsing} singleLine={singleLine} colSpan={colSpan} onClick={onClick} style={style}>{children && typeof children === 'function' ? children(value,row,index) : value }</Table.Cell>
		);
	}

}

ListTable.Cell = ListTableCell

const equalWithoutChildren = (prev, next) => {
  return Object.keys(prev).every(key => {
    if (key === 'children') return true;
    return prev[key] === next[key];
  });
};

/**
 * Please note that this list will only re-render if the props change. 
 * If you have children that use out-of-scope dependencies, please add them as props as well.
 */
ListTable.Memoed = React.memo(ListTable, equalWithoutChildren);

export default ListTable;
