import React, { useState, useEffect } from 'react';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';
import YogpForm from './YogpForm';

const YOGP = (props) => {
	const { store, med_data, req_type, script_type, Section } = props;
	const [fields, setFields] = useState([]);

	useEffect(() => {
		const fields = script_model.COMM_YOGP_FIELDS.map((f) => {
			if (f.name == 'yogp') {
				f.valid_not_required = !store.yogp_want;
			}
			return f;
		});

		setFields(fields);
	}, [med_data, store.yogp_want]);

	return (
		<UniFormMed {...props} section="yogp" fields={fields}>
			{(values, valids, uf_this, fields) => {
				return (
					<Section>
						<Section.Header>Update {(req_type == 'paas' && 'Patient') || 'your'} doctor (optional)</Section.Header>
						<Section.Content>
							<YogpForm values={values} valids={valids} uf_this={uf_this} req_type={req_type} script_type={script_type} fields={fields} />
						</Section.Content><br/>
					</Section>
				);
			}}
		</UniFormMed>
	);
};

export default YOGP;
