import React, { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import logger from "../libs/logger";

const DEBUG = false

export default function AppHistory({children = null}) {
	const histRef = useRef([])

	const navigate = useNavigate();
	const location = useLocation();
	const navigationType = useNavigationType();
	const historyListeners = useRef([])

	const navState = useRef(null)

	useMemo(() => {
		app.history = {
			location:'',
			push: ()=>{},
			goBack: ()=>{},
			goForward: ()=>{},
			listen: (callback, now = false) => {
				DEBUG && console.log("added listen handler",callback,now)
				historyListeners.current.push(callback)
				if (now && navState.current) {
					DEBUG && console.log("notify listener now",navState.current);
					const [location, navigationType] = navState.current
					callback(location, navigationType)
				}
				return () => {
					historyListeners.current = historyListeners.current.filter(fn=>fn!==callback)
				}
			}
		};
		DEBUG && console.log("assigning initial history",app.history);
	}, [])

	useMemo(() => {
		
		Object.assign(app.history,  {
			push: navigate,
			location,
			goBack: () => navigate(-1),
			goForward: () => navigate(1),			
		})
		DEBUG && console.log("updating history",app.history);
	}, [navigate,location])

	useEffect(()=>{
		DEBUG && console.log("history change",{navigationType, location});
		navState.current = [location, navigationType]
		DEBUG && console.log("notifying listerners",historyListeners.current);
		historyListeners.current.forEach(fn=>fn(location, navigationType))
	},[navigationType, location])

	const pathname = location.pathname;
	useEffect(() => {
		DEBUG && console.log('pageview', pathname);
		logger.usg_log('pageview', pathname);
	}, [pathname]);

	return children
}
