import React from 'react'
import API_service from 'xAppLib/providers/API_service'
import medicare_meta_model from './medicare_meta_model'

const _MEDICARE_ITEMS = {
	// 91795: {code:91795, desc:'<5 min', amount:20.55, benefit:17.50},
	// 91809: {code:91809, desc:'<20 min', amount:44.90, benefit:38.20},
	// 91810: {code:91810, desc:'>20 min', amount:87.00, benefit:73.95},
	// 91811: {code:91811, desc:'>40 min', amount:128.05, benefit:108.85},
	// 92216: {code:92216, desc:'Emergency and Unsociable hours', amount:155.45},

	// 92128: {code:92128, desc:'Mental Health Care Plan (with Training) 20-40 min', amount:108.80},
	// 92129: {code:92129, desc:'Mental Health Care Plan (with Training) > 40 min', amount:160.25},
	// 92124: {code:92124, desc:'Mental Health Care Plan (without Training) 20-40 min', amount:85.70},
	// 92125: {code:92125, desc:'Mental Health Care Plan (without Training) > 40 min', amount:125.15},
	// 92126: {code:92126, desc:'Mental Health Care Plan - Review', amount:85},

	// 93716: {code:93716, desc:'Bulk billed covid antiviral consult > 20 min', amount:79.70},
	91891: {code:91891, desc:'Telehealth consultation > 6min', amount:42.85},

	92116: {code:92116, desc:'Mental Health Care Plan (with Training) via video 20-39 min', amount:103.70},
	92117: {code:92117, desc:'Mental Health Care Plan (with Training) via video > 40 min', amount:152.80},
	92112: {code:92112, desc:'Mental Health Care Plan (without Training) via video 20-39 min', amount:81.70},
	92113: {code:92113, desc:'Mental Health Care Plan (without Training) via video  > 40 min', amount:120.25},
	92126: {code:92126, desc:'Mental Health Care plan Review (via phone or video)', amount:81.70},
	92127: {code:92127, desc:'Mental health consultation >20 min (via phone or video)', amount:81.70},
}

const _MEDICARE_ITEMS_EXTRA = {
	// 10990: {code:10990, desc:'U16 or Commonwealth concession card holder', amount:6.60},
	// 10991: {code:10991, desc:'U16 or Commonwealth concession card holder, Doctor location in a regional, rural or remote area', amount:10.05},
	// 10981: {code:10981, desc:'Potentially COVID 19 vulnerable patient (Required to self isolate or is over 70, or identifies as Aboriginal or is the parent of a child under 12 or is immuno-compromised or meets triage protocol criteria for suspected COVID-19)', amount:12.95},
	// 10982: {code:10982, desc:'Potentially COVID 19 vulnerable patient (Required to self isolate or is over 70, or identifies as Aboriginal or is the parent of a child under 12 or is immuno-compromised or meets triage protocol criteria for suspected COVID-19) AND Doctor lives in Rural or Regional Australia', amount:18.95},
}


const _STATUS_LIST = ['PENDING','OK','WITH MEDICARE','PROCESSED','COMPLETE','REJECTED','ERROR', 'VERIFICATION FAILED','LOST','INCORRECT']
// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class medicare_model {

	static get MEDICARE_ITEMS() { return _MEDICARE_ITEMS; }
	static get MEDICARE_ITEMS_EXTRA() { return _MEDICARE_ITEMS_EXTRA; }
	static get MEDICARE_ALL_ITEMS() { return {..._MEDICARE_ITEMS,..._MEDICARE_ITEMS_EXTRA}; }
	static get STATUS_LIST() { return _STATUS_LIST; }
	static get REASON_CODES() { return medicare_meta_model.MEDICARE_REASON_CODES; }

	static async medicare_claim (sid, items) {

		console.log('medicare_model :: claim sending ', {sid, items});
		
		const result = await API_service.api_promise('medicare/claim',{sid, items}).catch(e=>({res:'res',error:e.message||e}))
		return result

	}
	
	
	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------
	
	static async medicare_verify(params) { 
		console.log('medicare_model :: verify sending ', params);
		
		const result = await API_service.api_promise('medicare/verify', params).catch(e=>({res:'res',error:e.message||e}))
		return result
	}
	
	
	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------
	
	static async medicare_status(sid) { 
		console.log('medicare_model :: status sending ', {sid});
		
		const result = await API_service.api_promise('medicare/status',{sid}).catch(e=>({res:'res',error:e.message||e}))
		return result
	}


	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------
	
	static async check_provider(provider_number,minorID) { 
		console.log('medicare_model :: checking provider number ', {provider_number,minorID});

		const result = await API_service.api_promise('medicare/getProvider',{provider_number,minorID}).catch(e=>({res:'res',error:e.message||e}))
		return result
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------
	
	static async add_provider(name, provider_number, minorID) { 
		console.log('medicare_model :: adding provider number ', {provider_number,minorID});

		const result = await API_service.api_promise('medicare/addProvider',{name, provider_number,minorID}).catch(e=>({res:'res',error:e.message||e}))
		return result
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------
	
	static async set_incorrect(sid) { 
		console.log('medicare_model :: set_incorrect ', {sid});

		const result = await API_service.api_promise('medicare/setIncorrect',{sid}).catch(e=>({res:'res',error:e.message||e}))
		return result
	}
	
	// ---- --------------------------------------------  --------------------------------------------


}
