import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet"
import { Container, Button, Form, Checkbox, Dropdown } from 'semantic-ui-react'
import IcosmHeader from './components/IcosmHeader';
import UniForm from 'xAppLib/UniForm/UniForm'
import UniFieldSUI from 'xAppLib/UniForm/UniField-SUI';
import icosm_model from '../../models/icosm_model';
import Files from 'xAppLib/Files/Files';
import { cls } from 'views/NUI/utils'
import IcosmHero from './components/IcosmHero';
//import admin_users_model from 'models/admin_users_model';
//onboarding local: Ox9Joao

const GetStarted = () => {
    const [loading, setLoading] = useState(false)
	const [submitted, setSubmitted] = useState(false)
    const [files, setFiles] = useState({})
    const [groups, setGroups] = useState({onboarding: 'uFMV_sU', nurse: '-LQ9ViFhGTsEgP7y6MID'});
    const [nurseList, setList] = useState([]);
    const [org, setOrg] = useState('');
    const [duplicate, setDuplicate] = useState(''); 
    const uniformRef = useRef();
    const [exNurse, setExisting] = useState({ex_nurse: '', ex_group: ''});

    /*useEffect(async () => {
       await loadData();
    }, []);*/

    async function loadData () {
        /*const flat_list = await docs_model.get_flat_cats();
        const nurses_list = await admin_users_model.load_users_list({
            filters: {oids:[groups.onboarding,], extended_ihi: true, patients_only:app.acl.is_doc_admin}, 
            get_stats: true,
        })
        setList(nurses_list)*/
	}

    // Form Input
    const FileInput = ({name, text}) => <Files
            // target = ''
            inline
            data = {files[name] || []}
            onAddFile={(file) =>  setFiles({...files, [name]: [file] })}
            addPIT={false}
            addNotes={false}
            >
            <p className="text-sm tracking-normal text-icosm-gray">{text}</p>
        </Files>

    const handleCheck = (d, e, uf_this, vals=[]) => {
        const { name, label, checked } = d;
        uf_this.handleInputChange(e, {name: name, value: checked ? [...vals, label] : vals?.filter(x =>x != label)})
	};

    /* Update
    const onFormUpdate = (values) => {
        if (values.name && app.acl.mode !== 'admin' && app.acl.can_onboard_doc)
            checkIfNurseExists(values.name)
    }

    // Exisiting Nurse
    const checkIfNurseExists = (name) => {
        const duplicate = Object.keys(nurseList)
            .filter(k => [name.toLowerCase()].includes(nurseList[k].auth.displayName?.toLowerCase()))
            .map(k => ({ name: name, uid: nurseList[k].uid}));
        setDuplicate(duplicate)
	}*/

    /*async function loadExistingNurse (itm_code) {
        setLoading(true)
        // const nurse = '';

        const target_group = groups.nurse
        uniformRef.current.setVals({ ...nurse, target_group })
        setExisting((prev) => ({...prev, ex_nurse: itm_code, ex_group: groups.onboarding}));
        setLoading(false)
	}*/

    // Submit

    async function submitForm(values) {

        // console.log('submit', values)

        setLoading(true)
        let data = values;

        for (const f in files) {
            const file = Object.entries(files[f]).map(i => i[1]);
            data = {...data, [f]: file[0]};
        }
        
        try {
            data = {...data, organisation: org};

            if (!app.acl.can_onboard_nurse && groups.onboarding) {
				data = {...data, group: groups.onboarding};
			}

            const res = await icosm_model.icosm_signup(data, exNurse.ex_nurse, exNurse.ex_group);

			/*if (res?.success && res.doc_id && res.group) {
                // console.log('success')
				app.history.push(`/admin/doc/${res.group.replace(/\/i/g, '')}/${res.doc_id}`);
			}*/
		} catch (e) {
            console.log('error submit')
		}
		// await loadData()

        setLoading(false)
        setSubmitted(true)
    }

    let signup_fields = icosm_model.SIGNUP_FIELDS;

    const img_path = "https://storage.googleapis.com/icosm/"


	return <div className= "get-started">

		<Helmet>
			<title>Join</title>
		</Helmet>

		<Container>

            <IcosmHero no_border img='2/iStock-1994813691.jpg' title="We’re thrilled to see you here!" desc="Please fill out our short questionnaire below to start the onboarding process." order="2" hide_btn={true} />
            
            <div className='flex flex-col lg:flex-row gap-8 justify-between join-form'>

                {!submitted ? <>
                
                <UniForm 
                    ref={uniformRef}
                    onActForm = { submitForm }
                    render_children = {true}
                    fields = { signup_fields }
                    valid_not_required = {false}
                    >
                        {
                            (values, valids, uf_this, fields, display_errors=true) => {
                                
                                const args = {values, valids, uf_this, display_errors}
                                return <Form size="large" className="pb-4 join-form">

                                    <div className="mt-9 md:mt-[79px]">
                                        <h3 className=' mb-[33px]'>How can we contact you?</h3>
                                        <UniFieldSUI fl={fields.firstname} {...args}/>
                                        <UniFieldSUI fl={fields.lastname} {...args}/>
                                        <UniFieldSUI fl={fields.email} {...args} />
                                        <UniFieldSUI fl={fields.phone} {...args} />
                                    </div>

                                    <div className="mt-9 md:mt-[79px]">
                                        <h3 className=' mb-[33px]'>Let’s get to know you</h3>
                                        <UniFieldSUI fl={fields.describes} {...args}/>
                                        <UniFieldSUI fl={fields.position} {...args}/>
                                    </div>

                                    <div className="my-9 md:my-[79px]  flex flex-col">
                                        <h3 className=' mb-[33px]'>What prompted your<br className="lg"/> visit today?</h3>
                                        <label className='mb-[26px] block'>{fields.visit.label}</label>
                                        {fields.visit.options.map((el, i) => (
                                            <Form.Checkbox key={el} name='visit' label={el}  
                                                fl={fields.visit}
                                                error={valids &&!valids['visit']}
                                                onChange={(e, d) => handleCheck(d, e, uf_this, values.visit)}
                                            />
                                        ))}
                                     
                                    </div>
                                   
                                     <div className="mt-9 md:mt-[79px]">
                                        <label className='!mb-[26px]'>{fields.learn.label}</label><br/><br/>

                                        <Dropdown
                                            placeholder='Please select'
                                            selection 
                                            fluid
                                            error={valids&&!valids['learn']}
                                            name='learn'
                                            options={fields.learn.options}
                                            value={values["learn"]}
                                            onChange={(e, { value }) => uf_this.handleInputChange({target:{name: "learn", value}})
                                        }
                                        />
                                        <br/><br/>
                                        <Form.Checkbox
                                            onChange={(e, { checked }) => uf_this.handleInputChange({target: { name: 'privacy', value: checked ? "true" : null}})}
                                            error={valids && !valids['privacy']&&'You must agree to the Privacy and Terms'}
                                            label={<label>{fields.privacy.label}</label>}/>
                                        <br/>
                                        </div>

                                    <Button 
                                        size="large" 
                                        type="button"
                                        className='cta cta-tertiary icon !mt-8'
                                        onClick={_=>uf_this.actForm()}
                                        >Submit</Button>
                                </Form>
                            }
                        }
                        
                </UniForm> </> :
                <div className="flex flex-col w-full items-center">
                    <h3 className="text-center">Thank you</h3>
                    <p className="text-center text-icosm_xl font-bold mb-8">We will be in contact</p>
                </div>}

                <div className="flex flex-col gap-4">
                    <div className="lg:w-[489px] h-[312px] md:h-[508px] gap-[52px] bg-[#D9D9D9] block px-full bg-cover" style={{backgroundImage:`url(${img_path}2/Rectangle408.jpg)`}}></div>
                    {!submitted && <>
                        <div className="hidden lg:block lg:w-[489px] h-[508px] gap-[52px] bg-[#D9D9D9] bg-cover" style={{backgroundImage:`url(${img_path}2/Rectangle425.jpg)`}}></div>
                        <div className="hidden lg:block lg:w-[489px] h-[508px] gap-[52px] bg-[#D9D9D9] bg-cover" style={{backgroundImage:`url(${img_path}2/Rectangle426.jpg)`}}></div>
                    </>}
                </div>
            </div>

		</Container>

    </div>
}

export default GetStarted;
