import React, {Component} from "react";
import {Modal, Button, Icon, Container,Message,Form} from "semantic-ui-react";
import UniForm from "../UniForm/UniForm";
import UniFormSUI from "../UniForm/UniForm-SUI";
import support_model from "models/support_model";
import user_model from 'models/user_model';
import gtm from "../providers/gtm";

class Feedback extends Component {
	state = {open: false,sending:false,sent_res:null,values:{}};

	open = () => {
		this.setState({open: true,sent_res:null,sending:false});
		gtm.log('', 'modal_open', {modal: this.props.request==='account_del_req' ? 'del_acc' : 'feedback' });
	}
	close = () => {
		this.setState({open: false});
		gtm.log('', 'modal_close', {modal: this.props.request==='account_del_req' ? 'del_acc' : 'feedback' });
	}

	render() {
		const {open,sent_res} = this.state;
		const { trigger = <Button className="support-button" compact size="large" color="grey" icon>
			Feedback <Icon name="question circle" />
	</Button>, request } = this.props
		const flds = request==='account_del_req' ? support_model.DET_FIELDS_ACCOUNT_DELETION : support_model.DET_FIELDS_FEEDBACK
		return (
			<Modal
				className="support-modal"
				open={open}
				onOpen={this.open}
				onClose={this.close}
				closeIcon
				trigger={trigger}
				style={app.settings.is_ionic && {maxHeight:'85vh',overflow:'scroll'} || {}}
			>
				<Modal.Header>
					{request==='account_del_req' && 'Account closure request' || 'We welcome your feedback to improve your experience'} 
				</Modal.Header>
				<Modal.Content>
					
					{sent_res === 'ok' ? (()=>{gtm.log('','user_request',{req_type: request==='account_del_req'?'acc_del':'feedback'});return this.render_ok(request)})() : this.render_form(flds)}
					
					
				</Modal.Content>
				
			</Modal>
		);
	}
	
	render_ok(request) {
		return <React.Fragment>
					<Message
						positive
						header='Success'
						content={request==='account_del_req' && 'Your account closure request has been sent.' || 'Your feedback has been sent.'} 
					  />
				  	<Button fluid color="green" size='large' onClick={this.close} content="Close" />
			</React.Fragment>
	}
	
	render_form(flds) {
		const {sent_res,sending,values} = this.state;
		return <UniForm
			onActForm={ async d => {
						this.setState({sending:true, sent_res:null, error:null})
						if (d.form === 'account_del_req') d = {...d, type: 'Account closure request'}
						const result = await support_model.send_request(d)
						if (result.res === 'ok' && d.form === 'account_del_req') 
							await user_model.save_pref(d.form, new Date(Date.now()).toLocaleDateString())
						this.setState({ sent_res: result.res, error:result.err, sending:false })
					} }
			render_children={true}
			debug={false}
			fields={flds}
			// onUpdate={values=>{this.setState({values})}}
			// defaultValues={values}
			defaultValues={{
							name: app.user.user_in && app.user.user_det.displayName || '',
							email: app.user.user_in && app.user.user_det.email || ''
						}}
		>
			{(values, valids, uf_this) => {
				return (
					<React.Fragment>
					
						{sent_res == 'err' && <Message
									warning
									header='Problem'
									content='There was a problem sending your request. Please try again.'
								  />}
						<UniFormSUI
							fields={flds}
							values={values}
							valids={valids}
							uf_this={uf_this}
							loading={sending}
							show="fields_form"
							size={'large'}
							btns={
								<Button fluid color="green" size='large' icon="envelope outline" onClick={d => uf_this.actForm(d)} content="Submit" />
							}
						/>
					
					</React.Fragment>
				);
			}}
		</UniForm>
	}
}

export default Feedback;
