const group_by_val_obj = function (o, k_or_fn) {

	if(!o || !o.length || !k_or_fn)		return null

	let r = o.reduce((t, v) => {
		const k = typeof k_or_fn === 'function' ? k_or_fn(v) : v[k_or_fn];
		t[k] = t[k] ? t[k].concat(v) : [v];
		return t;
	}, {});

	return r
}

export default group_by_val_obj
