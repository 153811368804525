import React, { Component } from 'react';

const ISCustomerTermsAndConditions = () => (
	<div className="terms">
		<h1><b>Customer - Terms of Service</b></h1>
		<p>Please read and accept these Terms of Service (<b>Terms</b>).</p>
		<p className="font-bold uppercase">You must accept these terms each time you receive services through InstantScripts' platform. InstantScripts MAY VARY THESE TERMS AT ANY TIME and may suspend or terminate your access to the platform and / or services provided by InstantScripts at any time, without notice to you.</p>
				
		<h3>1. SCOPE OF TERMS</h3>
		<ol className="list-decimal">
			<li>These Terms set out the basis on which InstantScripts Pty Ltd ABN 87 629 120 234 (<b>InstantScripts</b>) makes available to you, through the Platform:
				<ol className="list-alpha">
					<li>the facilitation of telehealth consultations with InstantScripts Partner Doctors; and</li>
					<li>where clinically appropriate and after a digital or telehealth consultation with an InstantScripts Partner Doctor:
						<ol className="list-i">
							<li>the ability to request a prescription;</li>
							<li>the ability to request a referral for a pathology test;</li>
							<li>the ability to request a specialist referral; and</li>
							<li>the ability to request a medical certificate,</li>
						</ol>
					</li>
					<span>(the Services).</span>
				</ol>
			</li>
			<li>These Terms apply each time you access the Services. If you wish to access the Services, you must agree to be bound by these Terms by clicking the "I accept" button.</li>
			<li>You must accept these Terms each time you receive Services through the Platform, including when you:
				<ol className="list-alpha">
					<li>request a telehealth consultation with an InstantScripts Partner Doctor (<b>Telehealth Consultation</b>); or</li>
					<li>complete a digital consultation to request a prescription, pathology test, specialist referral or medical certificate (<b>Digital Consultation</b>).</li>
				</ol>
			</li>
			<li>Each time you receive Services will be a separate contract between you and InstantScripts. If you do not accept these Terms, you will not be able to access the Services. A failure to comply with these Terms may result in InstantScripts suspending or terminating your access to the Platform and / or the Services.</li>
			<li>From time to time, InstantScripts may review and update these Terms, including to take account of new laws, regulations, products, services or technology. Any variation to these Terms will take effect from the date of publication on the Platform. The Terms which apply at the time you request a Telehealth Consultation or undertake a Digital Consultation are those that govern your access to the Services the subject of the Telehealth Consultation or Digital Consultation.</li>
		</ol>


		<h3>2. EMERGENCIES</h3>
		<ol className="list-decimal">
			<li>The Services through the Platform are not suitable for emergency circumstances. If you are in an emergency situation or need urgent and immediate assistance please contact 000 or your local hospital emergency department.</li>
		</ol>


		<h3>3. YOUR PERSONAL ACCOUNT AND USE OF THE PLATFORM</h3>
		<ol className="list-decimal">
			<li>The Services provided through the Platform are only available to individuals who:
				<ol className="list-alpha">
					<li>reside in Australia (ie. have a current Australian address);</li>
					<li>access the Services within Australia; and</li>
					<li>who:
						<ol className="list-i">
							<li>create a personal account with InstantScripts through the Platform (<b>Personal Account</b>); or</li>
							<li>create an account to use on behalf of another person, such as using the “family account” feature, provided they are the other’s parent, guardian or carer and have their consent to create the account.</li>
						</ol>
					</li>
				</ol>
			</li>
			<li>By creating a Personal Account or Family Account, requesting a Telehealth Consultation, undertaking a Digital Consultation or otherwise accessing the Services through the Platform, you confirm you are located within Australia.</li>
			<li>You must provide true, accurate and complete information when registering for and using your Personal Account or Family Account and must ensure that such information is current and up to date at all times. If any information you provide is inaccurate or incomplete, InstantScripts may not be able to provide the Services to you.</li>
			<li>You are responsible for complying with the <a href='#' onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt",	txt:'Platform Terms and Conditions', pn:'platform-terms-and-conditions'})}>Platform Terms of Use</a> and any other requirements necessary to access the Platform, create a Personal Account or Family Account, request a Telehealth Consultation, undertake a Digital Consultation or otherwise access the Services.</li>
			<li>You acknowledge that InstantScripts can terminate, suspend or block your access to the Platform if InstantScripts believes you have breached these Terms, the Website Terms of Use or any other requirements necessary to access the Platform. Without limiting the foregoing, InstantScripts reserves the right, in its sole discretion, to do any of the following without notice to you:
				<ol className="list-alpha">
					<li>suspend or block your access to the Platform;</li>
					<li>limit or prohibit you from using your Personal Account or Family Account; or</li>
					<li>refuse to provide or supply you with any good or service offered by, or on behalf of, InstantScripts.</li>
				</ol>
			</li>
		</ol>

		<h3>4. SERVICES</h3>
		<ol className="list-decimal">
			<li>When obtaining Services through the Platform, you must provide honest, accurate and complete information and not omit any information that is relevant to the Services you are obtaining. If you do not provide honest and accurate information or omit any information that is relevant to the Services you are obtaining, you may not be able to receive the full benefit of those Services.</li>
			<li>Subject to your compliance with these Terms, you may request a Telehealth Consultation or undertake a Telehealth Consultation or Digital Consultation using the Platform at any time. </li>
			<li>Notwithstanding clause 4.2, InstantScripts Partner Doctors are only available during the hours advertised on the website (as updated from time to time). You may not be able to consult with an InstantScripts Partner Doctor outside of these times.</li>
			<li>From time to time, you may be required to consult with an InstantScripts Partner Doctor instead of or after you complete a Digital Consultation. You may also request to consult with an InstantScripts Partner Doctor when you undertake a Digital Consultation. Where this occurs, you will be required to request a Telehealth Consultation with an InstantScripts Partner Doctor. </li>
			<li>InstantScripts Partner Doctors are independent of InstantScripts and solely responsible for the healthcare services they provide to you, including compliance with standards of care, record keeping and other professional obligations, as well as compliance with privacy law. InstantScripts is not a party to the health professional-patient relationship between you and an InstantScripts Partner Doctor and is in no way involved in, or liable for, any services provided by an InstantScripts Partner Doctor to you.</li>
			<li>InstantScripts does not guarantee that a Telehealth Consultation or Digital Consultation is the appropriate course of treatment for your particular healthcare condition. The InstantScripts Partner Doctor that you consult with will be solely responsible for determining:
				<ol className="list-alpha">
					<li>whether or not a Telehealth Consultation or Digital Consultation is appropriate for you;</li>
					<li>whether or not to provide the medical and/or health services you request; and</li>
					<li>what health services (if any) are required.</li>
				</ol>
			</li>
			<li>You should contact your regular doctor immediately if your medical condition changes or your symptoms worsen. If you require urgent care, you should contact your local emergency services immediately. You agree that, if you suffer from any noticeable side-effects from any medication or treatment you receive, you will either consult your regular doctor or contact us to facilitate a further consultation with an InstantScripts Partner Doctor.</li>
			<li>You agree you will not record phone consultations unless you receive explicit written consent from the InstantScripts Partner Doctor prior to commencing any such recording.</li>
			<li>InstantScripts does not guarantee that you will be able to consult with a particular InstantScripts Partner Doctor, or that you will not receive a consultation with a particular InstantScripts Partner Doctor.</li>
			<li>You acknowledge that an InstantScripts Partner Doctor may, in their absolute discretion, refuse to prescribe medication to you, issue you a repeat prescription and / or provide any other services or referrals requested by you. Any such refusal by an InstantScripts Partner Doctor is not grounds for a refund.</li>
			<li>Without limiting clause 4.10, you acknowledge that InstantScripts Partner Doctors are unable to:
				<ol className="list-alpha">
					<li>prescribe controlled, addictive or Schedule 8 medications which include but are not limited to Diazepam, Endone, Oxycontin, Palexia, Lyrica, Stilnox, Zolpidem, Codeine painkillers (including Panadeine Forte), Benzodiazepines (including Valium / Diazepam, Temazepam, Oxazepam, Alprazolam), Duromine, Tramadol, Gabapentin, Pregabalin, Seroquel, Steroids, Vyvanse, Modafinil, Dexamphetamine, Ritalin, Concerta or Sofradex ear drops. Prescriptions are solely at the discretion of the consulting doctor.</li>
					<li>provide backdated medical certificates; or</li>
				</ol>
			</li>
		</ol>

		<h3>5. COLLECTION AND DELIVERY OF MEDICATION</h3>
		<ol className="list-decimal">
			<li>InstantScripts is not a pharmacy and does not dispense any medication.</li>
			<li>InstantScripts and/or a Pharmacy may contact the InstantScripts Partner Doctor who issued your prescription in relation to that prescription.</li>
			<li>If you are prescribed a medication by an InstantScripts Partner Doctor following a Digital Consultation or a Telehealth Consultation, depending on your circumstances, you may:
				<ol className="list-alpha">
					<li>have an electronic prescription sent directly to you by email and / or SMS; or</li>
					<li>have an electronic copy of your paper prescription sent to a Pharmacy.</li>
				</ol>
			</li>
			<li>If you have an electronic prescription sent directly to you by email and / or SMS, you may attend a pharmacy of your choice to collect the medication that has been prescribed to you. InstantScripts will not be involved, in any way, with the collection by you of such medication.</li>
			<li>If you have an electronic copy of your paper prescription sent to a Pharmacy, depending on availability and your circumstances, you may attend that Pharmacy to collect the prescribed medication. InstantScripts will not be involved, in any way, with the collection by you of such medication.</li>
			<li>If you choose to have the prescribed medication delivered to your Shipping Address, then:
				<ol className="list-alpha">
					<li>you agree to:
						<ol className="list-i">
							<li>the applicable Pharmacy contacting you for the purpose of confirming details related to your prescription and obtaining all information it requires to dispense the prescribed medication to you; and</li>
							<li>a Delivery Partner contacting you to confirm delivery details, including the method of delivery and your Shipping Address;</li>
						</ol>
					</li>
					<li>InstantScripts will arrange for the collection of the dispensed medication from the Pharmacy and the delivery of that medication to your Shipping Address by a Delivery Partner;</li>
					<li>you will be provided with an estimated delivery timeframe. You acknowledge that this is an estimate only and based on the assumption that the Pharmacy has all the information it requires to be able to dispense the prescribed medication to you and the Delivery Partner has all the information it requires to deliver the prescribed medication to you.Without limitation, if the Pharmacy or Delivery Partner does not have all the information it requires to dispense or deliver the prescribed medication to you (as applicable), then a different delivery time may apply;</li>
					<li>you are responsible for ensuring that you have appropriate arrangements in place to accept delivery of your medication. InstantScripts is not responsible for actions of a Delivery Partner, including:
						<ol className="list-i">
							<li>the failure of a Delivery Partner to deliver medicines to the correct address;</li>
							<li>the method of delivery used by any Delivery Partner; or</li>
							<li>failure by a Delivery Partner to deliver medicines within any particular timeframe.</li>
						</ol>
					</li>
					<li>if the Shipping Address you provide is incorrect, you may be asked to provide a correct address. If you are unable to provide a correct address, your order may be cancelled;</li>
					<li>if your order is cancelled after your medication has been dispensed from the Pharmacy, you will be charged for the medication and you will not be able to obtain a refund; and</li>
					<li>in the event your medication appears to be damaged, wrongly dispensed or your delivery is delayed, you should contact InstantScripts through the Platform to seek advice or replacement medication at <a href="mailto:info@instantscripts.com.au">info@instantscripts.com.au</a>.</li>
				</ol>
			</li>
			<li>You acknowledge that a pharmacy may, in its absolute discretion, reject your prescription and refuse to dispense medication to you for any reason. You acknowledge that InstantScripts is not, in any way, involved in or responsible for the decision to dispense medication to you.</li>
			<li>In some circumstances, customers can opt to have eligible prescription medications delivered. Delivery is available for online prescriptions and treatment plans only. </li>
		</ol>

		<h3>6. USAGE OF MEDICATION</h3>
		<ol className="list-decimal">
			<li>If an InstantScripts Partner Doctor prescribes any medication, you must carefully read all information provided to you in relation to that medication (including information provided on the product packaging, patient information supplied with the medication, online material provided through the Platform (including by an InstantScripts Partner Doctor or a Pharmacy) and the manufacturer’s consumer medical information leaflet).</li>
			<li>You must follow any advice provided to you by an InstantScripts Partner Doctor you consult with and/or Pharmacy in relation to taking any medication prescribed to you.</li>
			<li>If you experience any serious side effects from any medication, you should seek emergency medical care immediately.</li>
		</ol>

		<h3>7. PRICE AND PAYMENT</h3>
		<ol className="list-decimal">
			<li>All charges and prices as shown listed are in Australian Dollars and are inclusive of GST (if applicable).</li>
			<li>The amount payable by you for each Service will be notified to you at the time you request a Telehealth Consultation or undertake a Digital Consultation (as is applicable). You should consider all applicable costs before finalising your request for a Telehealth Consultation or Digital Consultation.</li>
			<li>Payment may be made online by credit card, debit card or through a PayPal account. You are required to provide payment details at the time you request a Telehealth Consultation or undertake a Digital Consultation (as is applicable).</li>
			<li>By finalising a request for a Telehealth Consultation or Digital Consultation, you authorise InstantScripts to run an authorisation check to confirm the validity of your payment details and availability of funds to pay for the Services you have requested. This check does not result in any charge to you. If your payment details do not pass the authorisation check, you may be contacted by InstantScripts to confirm your payment details. If no authorised payment details have been provided, you may not be able to finalise your Telehealth Consultation or Digital Consultation.</li>
			<li>InstantScripts will only arrange for funds to be debited against your nominated account as authorised in these Terms.</li>
			<li>Following completion of your Telehealth Consultation with an InstantScripts Partner Doctor or the completion of your Digital Consultation, the relevant InstantScripts Partner Doctor will instruct InstantScripts to charge you for the Services received. You hereby authorise InstantScripts to charge the cost of any Service against your nominated account.</li>
			<li>If you cancel your Telehealth Consultation at any time before it commences, you will not be charged for the Telehealth Consultation. </li>
			<li>For each successful payment, InstantScripts will send you a receipt confirming payment to the email address you have provided.</li>
			<li>InstantScripts may, in its sole discretion, vary the fees and payment terms applying to the Services at any time. Any change to the fees or payment terms applying to the Services will be immediately effective upon the publication of that change on the Platform and will apply to the Services requested by you following that change. If you have booked or paid for Services prior to a change in fees or payment terms being published on the Platform, but have not received the Services prior to that change taking effect, you will not be charged more for the applicable Services at the time you receive such Services.</li>
			<li>Services provided by an InstantScripts Partner Doctor through the Platform are not covered under Medicare Australia, no Medicare rebate is available and bulk billing is not offered.</li>
			<li>If you are eligible to claim private health insurance benefits for any Services you receive, you are responsible for submitting the paid invoice to your insurer to claim any eligible benefits.</li>
			<li>You are solely responsible for maintaining the accuracy of your details, including your private health insurance details and any bank account or credit card details to which you would like to receive payments. InstantScripts disclaims any and all liability for claims or payments which cannot be processed due to incorrect information provided by you.</li>
		</ol>

		<h3>8. REFUNDS</h3>
		<ol className="list-decimal">
			<li>Refunds for Telehealth Consultations or Digital Consultation are provided at sole discretion of InstantScripts and InstantScripts Partner Doctors.</li>
			<li>Subject to rights that cannot be waived under Australian Consumer Law, the cost of any medication that you choose to have dispensed (and delivered) to you through the Platform can only be refunded when:
				<ol className="list-alpha">
					<li>you let InstantScripts know you would like to cancel your delivery and the medication has not yet been dispensed by the applicable Pharmacy;</li>
					<li>the Pharmacy is unable to dispense your medication; or</li>
					<li>a product or pricing error is identified (and only to the extent that the error has caused you to be charged more than you ought to have been charged).</li>
				</ol>
			</li>
			<li>Refunds are not permitted by the Pharmacy for medication once they have been dispensed.</li>
			<li>Refunds can only be made back to the payment method or credit card used for the original charge. Refunds may take up to five working days to be processed and appear in your card statement.</li>
		</ol>

		<h3>9. AVAILABILITY</h3>
		<ol className="list-decimal">
			<li>InstantScripts does not permit you to choose to the InstantScripts Partner Doctor who provides you a Digital Consultation or Telehealth Consultation.</li>
			<li>It is possible that the Services and / or InstantScripts Partner Doctors available through the Platform at any given time may not be suitable for your needs. In that circumstance, InstantScripts will seek to facilitate that the assessing InstantScripts Partner Doctor help you select a suitable practitioner and / or refer you to an appropriate third party.</li>
			<li>If InstantScripts is delayed or hindered in or prevented from performing any of its obligations under these Terms by reason of any event or circumstance beyond its reasonable control, InstantScripts will have no liability to you in respect of such non-performance and the time for performing the same will be extended until the event or circumstance has ceased.</li>
		</ol>

		<h3>10. PRIVACY</h3>
		<ol className="list-decimal">
			<li>Your personal (including sensitive and health) information will be collected by InstantScripts, including if you choose to create a Personal Account, request a Service, undertake a Telehealth Consultation or a Digital Consultation or otherwise access the Services, or contact InstantScripts on or via the Platform. InstantScripts' Privacy Policy, which can be accessed at www.instantscripts.com.au/privacy/, explains how InstantScripts generally handles your personal (including sensitive and health) information. The Privacy Policy also includes information about how you can:
				<ol className="list-alpha">
					<li>seek to access and/or correct your personal information InstantScripts holds about you; and</li>
					<li>submit a privacy query or complaint to InstantScripts and how InstantScripts will deal with your complaint.</li>
				</ol>
			</li>
			<li>By using the Platform, you agree that InstantScripts can use your personal (including sensitive and health) information in accordance with its Privacy Policy.</li>
		</ol>


		<h3>11. LIMITATION OF LIABILITY</h3>
		<ol className="list-decimal">
			<li>Nothing in these Terms excludes, restricts or modifies any consumer guarantee, right or remedy conferred on you by the Australian Consumer Law, or any other applicable law, that cannot be excluded, restricted or modified by agreement.</li>
			<li>To the greatest extent permitted by law, InstantScripts will not be liable to you for any direct or indirect lost profit, loss of opportunity, incidental, consequential or special damages, howsoever the loss is caused and regardless of whether it was foreseeable or not.</li>
			<li>To the greatest extent permitted by law, InstantScripts makes no representations or warranties, express or implied, with respect to:
				<ol className="list-alpha">
					<li>the merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights of the Platform or any medical or health related matter;</li>
					<li>any treatment, information, advice or action relied on or followed by you; or</li>
					<li>the Platform being available without interruption, error-free or free from viruses or other things that may interfere with the normal operations of your system.</li>
				</ol>
			</li>
			<li>To the maximum extent permitted by law, InstantScripts' liability will be limited to providing the Services again (or the cost of having the Services provided).</li>
			<li>You release InstantScripts from all liability with respect to any matter concerning the health and medical services facilitated through the Platform, including information provided to you about those health and medical services.</li>
			<li>InstantScripts will not be liable for any loss or damage resulting from the transmission of data as may occur during your use of the Platform.</li>
			<li>InstantScripts will not be liable for any loss, damage, expense or claim arising from damage, loss, injury or death caused directly or indirectly by an InstantScripts Partner Doctor, Pharmacy and/or Delivery Partner and you release InstantScripts from any liability for any claim arising from any of those circumstances.</li>
			<li>Neither InstantScripts nor any InstantScripts Partner Doctor, Pharmacy nor Delivery Partner are responsible for loss or damage arising due to you failing to provide true or fully complete information or failing to comply with any of your obligations under these Terms.</li>
			<li>InstantScripts will not be liable for any loss or damage which arises from your failure to inform any other healthcare provider about treatment you receive from an InstantScripts Partner Doctor through the Platform.</li>
		</ol>

		<h3>12. OFFERS</h3>
		<ol className="list-decimal">
			<li>Certain offers made by InstantScripts are subject to additional terms (as specified in the offer). You should read these terms carefully as time periods and the availability will often be limited.</li>
			<li>Where InstantScripts offers a promotion, that promotion is provided by InstantScripts Pty Ltd ABN 87 629 120 234 and is subject to the terms and conditions contained in this clause 12.</li>
			<li>If you want the benefit of the promotion, you will need to use the code specified in the promotion. Use of this code constitutes your acceptance of these terms and conditions.</li>
			<li>Your ability to use the benefit of the promotion expires on the date specified in the promotion and if no date is specified, on the second anniversary of the date on which the promotion was first published.</li>
			<li>The promotion can only be redeemed through the Platform.</li>
			<li>The promotion must not be copied or redistributed in any way.</li>
		</ol>

		<h3>13. GST</h3>
		<ol className="list-decimal">
			<li>In this clause 13:
				<ol className="list-alpha">
					<li>terms or expressions which have a defined meaning in the <i>A New Tax System (Goods and Services Tax) Act 1999</i> <i>(Cth) </i>(<b>GST Act</b>) have the same meaning as in the GST Act; and</li>
					<li>any reference to a party includes the representative member of a GST group of which that party is a member.</li>
				</ol>
			</li>
			<li>Unless otherwise expressly stated, all consideration to be paid or provided under these Terms is expressed inclusive of GST.</li>
			<li>If GST is payable on any supply made under these Terms, the recipient must pay to the supplier an additional amount (<b>GST Amount</b>) equal to the GST payable on that supply at the same time as the consideration for the supply is to be paid or provided.</li>
			<li>Clause 13.3 does not apply to the extent that:
				<ol className="list-alpha">
					<li>the consideration for the supply is stated to include GST; or</li>
					<li>GST on the supply is reverse charged and payable by the recipient.</li>
				</ol>
			</li>
			<li>The recipient need not pay the GST Amount until it has received a tax invoice or adjustment note, as the case may be.</li>
			<li>If an adjustment event arises in relation to a supply made under these Terms, the GST Amount must be adjusted to reflect that adjustment event. A corresponding payment must be made by the supplier to the recipient or by the recipient to the supplier, as the case may be.</li>
			<li>If these Terms require an amount to be calculated by reference to another amount (<b>Reference Amount</b>) that will be:
				<ol className="list-alpha">
					<li>received for a taxable supply; or</li>
					<li>paid for a creditable acquisition,</li>
				</ol>
			</li>
			then the Reference Amount must be reduced so as to exclude any part of the Reference Amount paid or received on account of GST, as the case may be.
			<li>If these Terms require a party to reimburse or indemnify another party for a cost or expense, the amount of the cost or expense must be reduced by an amount equal to any input tax credit to which the party being reimbursed or indemnified is entitled for that cost or expense.</li>
		</ol>

		<h3>14. COMPLAINTS</h3>
		<ol className="list-decimal">
			<li>If you believe you have been incorrectly charged for a Service, or you have any other concern or complaint relating to the Services, please contact InstantScripts at <a href="mailto:info@instantscripts.com.au"><b>info@instantscripts.com.au.</b></a></li>
			<li>InstantScripts will seek to respond to your concern or complaint as soon as it is able, and will endeavour to resolve such concern or complaint with you.</li>
		</ol>

		<h3>15. GENERAL</h3>
		<ol className="list-decimal">
			<li>These Terms are governed by and must be construed in accordance with the laws of Victoria, Australia. The parties submit to the non-exclusive jurisdiction of the courts of Victoria, Australia and the Commonwealth of Australia in respect of all matters arising out of or relating to these Terms of Use, its performance or subject matter.</li>
			<li>These Terms, and any provision of these Terms, are not to be construed to the disadvantage of a party because that party was responsible for their preparation.</li>
			<li>Headings in these Terms are intended for convenience of reference only. Forms of words will be construed to include singular or plural, and any gender, masculine, feminine, or neutral, as the context requires, and "including" means "including but not limited to". Reference to a person includes an individual, corporation, trust or statutory body.</li>
			<li>Nothing in these Terms will be construed as creating a relationship of partnership, of principal and agent or of trustee and beneficiary between you and InstantScripts.</li>
			<li>Any provision of these Terms (or any part thereof) which is invalid in any jurisdiction must, in relation to that jurisdiction:
				<ol className="list-alpha">
					<li>be read down to the minimum extent necessary to achieve its validity, if applicable; and</li>
					<li>be severed from these Terms in any other case,</li>
				</ol>
			</li>
			<span>without invalidating or affecting the remaining provisions of these Terms or the validity of that provision in any other jurisdiction.</span>
		</ol>


	<h3>16. DEFINITIONS</h3>
	<p><b>Australian Consumer Law </b>means the Australian Consumer Law as set out in Schedule 2 of the <i>Competition and Consumer Act 2010 (Cth)</i>.</p>
	<p><b>Delivery Partner </b>means any third party responsible for transporting medication from the Pharmacy to the Shipping Address.</p>
	<p><b>Digital Consultation </b>has the meaning given to that term in clause 1.3(b).</p>
	<p><b>InstantScripts </b>means InstantScripts Pty Ltd ABN 87 629 120 234.</p>
	<p><b>InstantScripts Partner Doctors </b>means Australian-registered doctors that provide services through the Platform.</p>
	<p><b>Pharmacy </b>means a pharmacy that has an arrangement with InstantScripts in relation the receipt by the pharmacy of prescriptions.</p>
	<p><b>Platform </b>means the website accessible at <a href="http://www.instantscripts.com.au">www.instantscripts.com.au</a> and the InstantScripts app.</p>
	<p><b>Services </b>has the meaning given to that term in clause 1.1.</p>
	<p><b>Shipping Address </b>means the address you provide to InstantScripts for the delivery of prescribed medication in accordance with these Terms.</p>
	<p><b>Telehealth Consultation </b>has the meaning given to that term in clause 1.3(a).</p>
	<p><b>Terms</b> means these Terms of Service.</p>
	<p><b>You </b>or <b>you</b> means the individual accessing the Services through the Platform.</p>
	<p>Last update: November 2022</p>

	</div>
);

export default ISCustomerTermsAndConditions
