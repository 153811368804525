import React from 'react'
import { getChartRanges }  from 'xAppLib/Results/util'


const ReferenceRangeIndicatorSVG = (props) => {
	const { desc, value, ref_range, emergency_range_low = '', emergency_range_high = '', width } = props
	const widthUse = width - 6 //Add the 3 + 3 so that we see all of the strokeWidth="4" at max and min limits
	if (value === '' || ref_range === '') return null
	const chartRange = getChartRanges(value, ref_range, emergency_range_low, emergency_range_high)
	if (chartRange.upper === 0) return null
	const pixel = widthUse / chartRange.upper
	const emergRangeLow = (emergency_range_low === '') ? 0 : Number(emergency_range_low)
	const emergRangeHigh = (emergency_range_high !== '') ? Number(emergency_range_high) : chartRange.upper
	const data = {
		chartRange: [chartRange.lower, chartRange.upper],
		refRange: [chartRange.ref_lower, chartRange.ref_upper],
		refRangePixels: [chartRange.ref_lower * pixel, chartRange.ref_upper * pixel],
		emergRangeLow: [0, emergRangeLow],
		emergRangeLowPixels: [0, emergRangeLow * pixel],
		emergRangeHigh: [emergRangeHigh, chartRange.upper],
		emergRangeHighPixels: [emergRangeHigh * pixel, chartRange.upper * pixel],
		value: value,
		valuePixel: value * pixel,
		colour: (() => {
			switch (chartRange.inRange) {
				case 'low':
					return 'blue'
				case 'high':
					return 'red'
				default:
					return '#2ecc71'
			}
		})()
	}
	if (isNaN(data.valuePixel)) {
		// console.log('desc', desc)
		// console.log('data', data)
	}

	return (
		<>
			<svg width={width} height="30">
				<rect x="3" y="5" width={widthUse} height="20" fill="#F1F1F4" /> //Chart area
				<rect x="3" y="13" width={widthUse} height="4" fill="#CBCBCB" /> //Grey horizontal axis
				{(emergency_range_low !== '') && <>
					<rect x={data.emergRangeLowPixels[1] - data.emergRangeLowPixels[0] + 3} y="12" width={data.refRangePixels[0] - data.emergRangeLowPixels[1] - data.emergRangeLowPixels[0]} height="6" fill="#B2D2FE" opacity="40%" />//Emergency lowish range
					<rect x={data.emergRangeLowPixels[0] + 3} y="12" width={data.emergRangeLowPixels[1] - data.emergRangeLowPixels[0]} height="6" fill="#1C48E5" opacity="40%" /> //Emergency low range
				</>
				}
				{(emergency_range_high !== '') && <>
					<rect x={data.refRangePixels[1] + 3} y="12" width={(data.emergRangeHighPixels[0] - data.refRangePixels[1])} height="6" fill="#FCE278" opacity="40%" />//Emergency highish range
					<rect x={data.emergRangeHighPixels[0] + 3} y="12" width={data.emergRangeHighPixels[1] - data.emergRangeHighPixels[0]} height="6" fill="#F08C0E" opacity="40%" /> //Emergency high range
				</>
				}
				<rect x={data.refRangePixels[0] + 3} y="12" width={data.refRangePixels[1] - data.refRangePixels[0]} height="6" fill="#2ecc71" opacity="40%" /> //Reference range
				<line x1={data.valuePixel + 3} y1="0" x2={data.valuePixel + 3} y2="30" stroke={data.colour} strokeWidth="4" /> //Value
			</svg>
		</>
	)
}

export default ReferenceRangeIndicatorSVG
