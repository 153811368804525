export default function obj_deep_diff(old_obj, new_obj) {
	if (!old_obj) return { fro: {}, to: { ...new_obj } };
	if (!new_obj) return { fro: { ...old_obj }, to: {} };
  
  return Object.entries(new_obj).reduce((diff, [key, value]) => {
    if(typeof value === 'object') {
      const isEqual = JSON.stringify(value) === JSON.stringify(old_obj[key]);
      return isEqual ? diff : { fro: { ...diff.fro, [key]: old_obj[key] }, to: { ...diff.to, [key]: value } };
    }
    return old_obj[key] === value ? diff : { fro: { ...diff.fro, [key]: old_obj[key] }, to: { ...diff.to, [key]: value } };
  }, { fro: {}, to: {} })
}
