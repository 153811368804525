import React, { useState } from "react";
import { FormField } from "semantic-ui-react";
import ImagePicker from 'xAppLib/UIelems/ImagePicker';

export const IMAGE_INIT = {
	desktopSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/ibs-man-sore-stomach-2-d64561b3.jpeg',
	tabletSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/ibs-man-sore-stomach-2-ccdff877.jpeg',
	mobileSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/ibs-man-sore-stomach-2-f1a35f00.jpeg',
}

const Image = ({ desktopSrc, tabletSrc, mobileSrc, editMode }) => {
	const [open, setOpen ] = useState(false);

    const handleOnImageClick = (target, imgUrl) => {
        document.getElementById(target).value = imgUrl;
		setOpen(false);
	};

    return editMode ? (
        <>
            <FormField>
                <label>Desctop Source</label>
                <input
                    id="imagePicker-desktopSrc"
                    name="desktopSrc"
                    placeholder="Desktop Source"
                    defaultValue={desktopSrc}
                    onClick={() => setOpen("imagePicker-desktopSrc")}
                />
            </FormField>
            <FormField>
                <label>Laptop Source</label>
                <input
                    id="imagePicker-tabletSrc"
                    name="tabletSrc"
                    placeholder="Laptop Source"
                    defaultValue={tabletSrc}
                    onClick={() => setOpen("imagePicker-tabletSrc")}
                />
            </FormField>
            <FormField>
                <label>Mobile Source</label>
                <input
                    id="imagePicker-mobileSrc"
                    name="mobileSrc"
                    placeholder="Mobile Source"
                    defaultValue={mobileSrc}
                    onClick={() => setOpen("imagePicker-mobileSrc")}
                />
            </FormField>
            <ImagePicker open={open} onClose={() => setOpen(false)} onImageClick={(_, imgUrl) => handleOnImageClick(open, imgUrl)} bucket="is-media" />
        </>
    ) : (
        <div>
            <img
                className="object-cover w-full h-full hidden md:block"
                src={desktopSrc}
            />
            <img
                className="object-cover w-full h-full hidden sm:block md:hidden"
                src={tabletSrc}
            />
            <img
                className="object-cover w-full h-full block sm:hidden"
                src={mobileSrc}
            />
        </div>
    );
};

export default Image;
