import React, { useState } from "react";
import moment from "moment/moment";
import { Container, Segment } from "semantic-ui-react";

import obj_diff from "../../xAppLib/helpers/obj_diff";
import { ViewEditDets } from "../../xAppLib/DataList";
import { AsyncButton } from "../UIelems/AsyncButton";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import API_service from "../../xAppLib/providers/API_service";
import { pick } from "../../helpers/json_functions";

export function MentalHealthCarePlan({req_scr_obj, onSubmit}) {
	const newRecord = req_scr_obj.script_type !== 'mentplan';
	const [values, setValues] = useState(() => newRecord ? defaultValues(req_scr_obj) : req_scr_obj.med);

	const can_generate_preview = values?.q1_issues || values?.q2_meds || values?.q3_k10 || values?.q4_crisis || values?.q5_edu || values?.q6_date || values?.q14_review;

	const can_submit = can_generate_preview && (
		newRecord || Object.keys(obj_diff(req_scr_obj.med, values)?.to || {}).length > 0
	);

	return (
		<>
			<ViewEditDets
				newRecord={newRecord}
				itm_data={values}
				valid_not_required
				det_fields={DOC_FIELDS}
				onChange={(d, c, e) => setValues(e)}
				onSave_dets_fld={(key, val) => setValues(prev => ({...prev, [key]: val}))}
				btn_submit={<span />}
				btn_cancel={<span />}
			/>

			{can_generate_preview && (
				<Container text>
					<Segment raised piled>
						<pre>{preview(req_scr_obj, values)}</pre>
					</Segment>
				</Container>
			)}

			<br/>
			<AsyncButton
				disabled={!can_submit}
				size="big"
				onClick={() => onSubmit(values)}
				fluid
				color="green"
				content={`${newRecord ? 'Send' : 'Update'} Mental Health Care Plan`}
			/>
		</>
	)
}

export function AdminEditMentalHealthCarePlan({req_scr_obj, onUpdate}) {
	const [values, setValues] = useState(() => pick(req_scr_obj.med, ADMIN_FIELDS.map(f => f.name)));

	const save = useAsync({
		immediate: false,
		fn: async (newValues) => {
			// only diff the fields admin actually have access to edit, otherwise it looks like they're removing everything
			const data_diff = obj_diff(values, newValues);

			if (Object.keys(data_diff.to || {}).length === 0) return

			const params = {sid: req_scr_obj.sid, diff: {...data_diff, part: 'med'}}

			await API_service.load_data('scripts/upd_script', params);

			setValues(newValues);

			onUpdate?.({med: {...req_scr_obj.med, ...newValues}});
		}
	});

	return (
		<ViewEditDets
			itm_data={values}
			allow_fld_edit={false}
			det_fields={ADMIN_FIELDS}
			loading={save.loading}
			onSave_dets={save.fn}
		/>
	)
}

const FIELD_SEND_TO_PSY = {
	name: 'send_to_psy',
	label: 'Send to Psychologist (name)',
};
const FIELD_SEND_TO = {
	name: 'send_to',
	type: 'email',
	label: 'Send to Psychologist (email)',
};

const DOC_FIELDS = [
	{
		name: 'ref_txt',
		label: "Free text referral (optional)",
		type: 'textarea',
	},
	{
		name: 'q1_issues',
		label: "Current concerns",
		type: 'textarea',
	},
	{
		name: 'q2_meds',
		label: 'Relevant medications',
	},
	{
		name: 'q3_hist',
		label: 'Previous mental health history/treatment',
		type: 'textarea',
	},
	{
		name: 'q4_fam',
		label: 'Family history of mental illness',
		type: 'textarea',
	},
	{
		name: 'q5_soc',
		label: 'Relevant social/personal history',
		type: 'textarea',
	},
	{
		name: 'q6_k10',
		label: 'Results of K10 or other outcome tool',
	},
	{
		name: 'q7_state',
		label: 'Mental State examination',
		type: 'textarea',
		rows: 14,
	},
	{
		name: 'q8_risk',
		label: 'Risk assessment',
		type: 'textarea',
		rows: 2,
	},
	{
		name: 'q9_crisis',
		label: 'Crisis/Relapse Plan',
		type: 'textarea',
		rows: 2,
	},
	{
		name: 'q10_diag',
		label: 'Diagnosis',
		type: 'textarea',
		rows: 2,
	},
	{
		name: 'q11_treat',
		label: 'Recommended treatment',
		type: 'textarea',
		rows: 2,
	},
	{
		name: 'q12_edu',
		label: 'Have you provided appropriate psychological education?',
	},
	{
		name: 'q13_dt',
		label: 'Date plan completed',
		type: 'masked',
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,],
		placeholder: "DD/MM/YYYY",
	},
	{
		name: 'q14_review',
		label: 'Review Date',
		type: 'masked',
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,],
		placeholder: "DD/MM/YYYY",
		// icon: 'id card outline',
		// valid_not_required: true,
		// validate_function: value => validations.validate_medicare(value) ,
	},
	FIELD_SEND_TO_PSY,
	FIELD_SEND_TO,
	{
		name: 'send_mymi',
		type: 'checkbox',
		label: "Send to our telehealth psychology partner My Mirror",
		options: [{c: 'yes', n: 'Yes'}],
	},
];

const ADMIN_FIELDS = [
	{
		...FIELD_SEND_TO_PSY,
		valid_not_required: true,
	},
	{
		...FIELD_SEND_TO,
		valid_not_required: true,
	},
	{
		name: 'update_reason',
		label: 'Update Reason',
		type: 'textarea',
		rows: 2,
		valid_not_required: false,
		value: '', // always reset the field on edit
	},
];

function defaultValues(req_scr_obj) {
	return {
		q6_k10: 'K10 score:' + req_scr_obj.form_data?.k10_score,

		q7_state: `Appearance and General Behaviour: normal 
        Mood: 
        Affect: 
        Thinking: 
        Abnormal/intrusive thoughts: no 
        Cognition: normal
        Orientation (time/place/person): normal 
        Attention/Concentration: 
        Memory: intact
        Insight: normal
        Appetite: normal/disturbed eating pattern (doctor to remove one)
        Motivation/energy: 
        Sleep: 
        Judgement: normal`,

		q8_risk: `Risk of self harm:  low/med/high
        Risk to others: low/med/high`,

		q9_crisis: `if necessary Crisis Assessment Team/Lifeline/send to ED`,

		q11_treat: `Referral to psychologist with goals of treatment: `,

		q12_edu: `Yes`,

		q13_dt: moment().tz("Australia/Melbourne").format('DD/MM/YYYY'),

		send_to_psy: req_scr_obj.form_data?.cons_psy_name,

		send_to: req_scr_obj.form_data?.cons_psy_email,
	}
}

function preview(req_scr_obj, values) {
	return `Dear ${values?.send_to_psy || values?.send_mymi == 'yes' && `My Mirror` || 'Psychologist'} ${values?.send_to && `(${values?.send_to})` || ''},

Re: ${req_scr_obj.spd_data.first_name} ${req_scr_obj.spd_data.last_name}, 
${req_scr_obj.spd_data.address}, ${req_scr_obj.spd_data.dob}, ${req_scr_obj.spd_data.medicare || ''}, ${req_scr_obj.spd_data.mobile}

Thank you for seeing ${req_scr_obj.spd_data.first_name} for 6 sessions under the Better Access Measures with the following referral details in the Mental Health Plan completed today.
${values?.ref_txt || ''}

${values?.q1_issues && `	Current concerns:
        ${values?.q1_issues}
` || ''}${values?.q2_meds && `	Relevant medications:
        ${values?.q2_meds}
` || ''}${values?.q3_hist && `	Previous mental health history/treatment:
        ${values?.q3_hist}
` || ''}${values?.q4_fam && `	Family history of mental illness:
        ${values?.q4_fam}
` || ''}${values?.q5_soc && `	Relevant social/personal history:
        ${values?.q5_soc}
` || ''}${values?.q6_k10 && `	Results of K10 or other outcome tool:
        ${values?.q6_k10}
` || ''}${values?.q7_state && `	Mental State examination:
        ${values?.q7_state}
` || ''}${values?.q8_risk && `	Risk assessment:
        ${values?.q8_risk}
` || ''}${values?.q9_crisis && `	Crisis/Relapse Plan:
        ${values?.q9_crisis}
` || ''}${values?.q10_diag && `	Diagnosis:
        ${values?.q10_diag}
` || ''}${values?.q11_treat && `	Recommended treatment:
        ${values?.q11_treat}
` || ''}${values?.q12_edu && `	Have you provided appropriate psychological education?
        ${values?.q12_edu}
` || ''}${values?.q13_dt && `	Date plan completed: 
        ${values?.q13_dt}
` || ''}${values?.q13_review && `	Review Date: 
        ${values?.q13_review}
` || ''}
Sincerely
`;
}