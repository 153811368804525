import React, {useEffect, useMemo, useRef, useState} from "react";
import moment from "moment-timezone";
import { Icon, Label } from "semantic-ui-react";

const ticker = (() => {
    const subscribers = {};
    return {
        subscribe(fn, ms) {
            const _ms = Math.floor(ms);
            const key = `_${ms}`;
            if (!subscribers[key]) {
                subscribers[key] = {
                    fns: [],
                    id: setInterval(() => {
                        subscribers[key].fns.forEach(fn => fn());
                    }, _ms)
                };
            }

            if (!subscribers[key].fns.includes(fn)) {
                subscribers[key].fns.push(fn);
            }

            return function unsubscribe() {
                const index = subscribers[key].fns.indexOf(fn);
                if (index >= 0) {
                    subscribers[key].fns.splice(index, 1);
                }
                if (subscribers[key].fns.length === 0) {
                    clearInterval(subscribers[key].id);
                    delete subscribers[key];
                }
            }
        }
    }
})();

function pad(num) {
	const s = "0" + num;
	return s.substring(s.length - 2);
}

function defaultFormat(duration, compact) {
	// Don't pad hours, padding is more to stop things jumping around second to second
	const h = Math.floor(duration.asHours());
	const m = compact ? duration.minutes() : pad(duration.minutes());
	const s = compact ? duration.seconds() : pad(duration.seconds());
	return compact ? (h&&`${h}h `||'')+(m&&`${m}m `||'')+(s&&`${s}s`||'') : `${h}h ${m}m ${s}s`
}

export function format_hm(duration, compact) {
	const h = Math.floor(duration.asHours());
	const m = compact ? duration.minutes() : pad(duration.minutes());

	return `${h}h ${m}m`;
}

export function color_levels(levels) {
	return duration => {
		const seconds = duration.asSeconds();
		return seconds <= levels[0] && 'green'
			|| seconds <= levels[1] && 'blue'
			|| seconds <= levels[2] && 'yellow'
			|| seconds <= levels[3] && 'orange'
			|| 'red'
	};
}

function calculate_duration({start, end, dur}) {
	if (typeof dur !== 'undefined') {
		return moment.duration(dur * 1000);
	}
	const from = moment(start).milliseconds(0);
	const to = moment(end || new Date()).milliseconds(0);

	return moment.duration(to.diff(from));
}

/**
 * Renders an icon and duration between two dates in format
 * @param {string | Moment | Date} start - Start time
 * @param {string | Moment | Date} [end=Date.now()] - End time
 * @param {boolean} [inverted=false] - iconColor is the background color
 * @param {string | boolean} [icon=false] - display icon (name, or 'wait' if set to `true`)
 * @param {getColourForDuration | string} [color=black] - of type SemanticCOLORS
 * @param {formatDuration} [format] - alternate formatting of duration (default is like `0h 10m 02s`)
 * @param {boolean} [live=false] - Live update the duration (only useful if end is unset, e.g. now)
 * @returns {JSX.Element}
 * @example
 *  <>Call started <Duration icon live start={add_tm} /> ago</> => Call started 0h 4m 32s ago
 */
export function Duration({start, end, inverted, icon: iconName = false, iconColor, format = defaultFormat, live = false, dur, compact = false}) {
	const [duration, setDuration] = useState(() => calculate_duration({ start, end, dur }));

	// use a ref so we don't force consumers to have to memoize potential non-primitive
	const state = useRef({ start, end, iconColor, iconName, format })
	useEffect(() => {
		state.current = { start, end, iconColor, iconName, format };
	}, [start, end, iconColor, iconName, format])

	const {display, icon, color = 'black'} = useMemo(() => {
		if (duration.isValid()) {
			return {
				display: state.current.format(duration, compact),
				icon: typeof state.current.iconName === 'function'
						? state.current.iconName(duration)
						: state.current.iconName,
				color: typeof state.current.iconColor === 'function'
						? state.current.iconColor(duration)
						: state.current.iconColor
			};
		}
		return {};
	}, [duration]);

	useEffect(() => {
		function update() {
			setDuration(calculate_duration({start: state.current.start, end: state.current.end}));
		}

		if (live) {
			return ticker.subscribe(update, 500);
		}
	}, [live]);

	if (!display) {
		return null;
	}

	const iconEl = icon && <Icon name={typeof icon === 'string' ? icon : 'wait'} size_='large' color={inverted ? undefined : color}/>;

	if (inverted) {
		return (
			<Label color={color}>
				{iconEl}
				{display}
			</Label>
		);
	}

	return <>
		{icon && <Icon name={typeof icon === 'string' ? icon : 'wait'} size_='large' color={color}/>}
		{display}
	</>;
}

/**
 * Derive icon color from a Duration
 *
 * @callback getColourForDuration
 * @param {Duration} duration
 * @return {string} - of type SemanticCOLORS
 */

/**
 * Convert a Duration to a string to display
 *
 * @callback formatDuration
 * @param {Duration} duration
 * @return {string} - display value of the duration
 */
