import React from 'react';
import MedSelect from 'xAppLib/UIelems/MedSelect'
import withData from 'xAppLib/HOC/withData'
import cat_model from 'models/cat_model'

const PharmMedSelector = withData(MedSelect, async _=> {
	const { cat_avail_list } = await cat_model.get_cat_list({cat_nm:'pharm', all:true})
	return cat_avail_list
}, 'cat_filter')


export default PharmMedSelector
