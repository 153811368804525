import React, { useState } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import DocumentRenderer from './DocumentRenderer';

import { ArrowLeftIcon, BookOpenIcon } from '@heroicons/react/outline';

const MyHRDocumentView = ({ documentArr = [], handleDocumentUnselect, changeView }) => {
	const [currentDocument, setCurrentDocument] = useState();
	const rootDocument = documentArr.find(
		(doc) => doc?.shortName?.toLowerCase() === 'cda_root.xml' || doc?.shortName?.toLowerCase() === 'xml_root.xml'
	);

	const attachments = documentArr.filter((doc) => doc.shortName !== rootDocument.shortName && doc.shortName.toLowerCase() !== 'cda_sign.xml');
	const hasAttachments = attachments.length > 0;

	useState(() => {
		setCurrentDocument(rootDocument);
	}, [rootDocument]);


	const fileNameLabel = () => {
		if (currentDocument.shortName.toLowerCase() === 'cda_root.xml') {
			return 'Record Overview';
		}
		if (currentDocument?.shortName) {
			return currentDocument?.shortName;
		}
		return 'Untitled Document';
	};

	if (currentDocument) {
		return (
			<>
				<div className='grid grid-cols-4 gap-4'>
					<div className={`${hasAttachments ? 'col-span-4' : 'col-span-4'} border border-gray-200 rounded min-h-[60vh]`}>
						<div className='p-4 h-full overflow-hidden'>
							<div className='flex justify-between'>
								<div className="flex justify-start items-center gap-4">
									{handleDocumentUnselect && <ArrowLeftIcon
										className='h-6 w-6 cursor-pointer'
										onClick={() => {
											if (currentDocument!=rootDocument) 
												setCurrentDocument(rootDocument);
											else
												handleDocumentUnselect()
										}}
									/>}
									<p className='font-semibold text-md m-0 py-3'>{fileNameLabel()}</p>
								</div>
								<div className='flex justify-end items-center'>
									{currentDocument !== rootDocument && (
										<BookOpenIcon
											className='h-6 w-6 cursor-pointer hover:w-7 hover:h-7 scale-75 duration-300'
											onClick={() => setCurrentDocument(rootDocument)}
										/>
									)}
								</div>
							</div>
							<div className='flex justify-center h-full'>
								<DocumentRenderer fileData={currentDocument} 
								onOpenDocument={(name)=>{
									setCurrentDocument(attachments?.find(a=>a.shortName == name))
								}}
								changeView={changeView}
								/>
							</div>
						</div>
					</div>
					{hasAttachments && (
							<div className='flex flex-row gap-2'>
								{attachments.map((doc) => (
									<Button
										key={doc}
										icon='file'
										color={doc?.name === currentDocument?.name ? 'blue' : 'white'}
										onClick={() => setCurrentDocument(doc)}
									>
										<p className='truncate'>{doc.shortName}</p>
										
									</Button>
								))}
						</div>
					)}
				</div>
			</>
		);
	}

	return <></>;
};

export default MyHRDocumentView;
