import React, { Component } from 'react';
import { Popup, Button, Icon } from 'semantic-ui-react'

// import OrgsTree from './OrgsTree'
import ShowTree from './ShowTree'
// import orgs_model from 'models/orgs_model'

const DEBUG = false;

class SelectTree extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isOpen:false,
		};
	}

	async componentDidMount () {
		this.set_sels(this.props.curr_objs_keys)
	}
	
	componentDidUpdate(prevProps, prevState) {
		const { curr_objs_keys } = this.props

		if (prevProps.curr_objs_keys != curr_objs_keys) {

			this.setState({
				loading: false,
			})

			this.set_sels(curr_objs_keys)
		}
	}

	// 		--------------------------------		--------------------------------		---------

	set_sels(objs_keys) {
		const { obj_key_nm, tree_data } = this.props

		const sel_objs_keys = objs_keys!=null && (Array.isArray(objs_keys) ? objs_keys : [objs_keys]) || null;
		DEBUG && console.log('SelectTree set_sels objs_keys', objs_keys, sel_objs_keys, sel_objs_keys?.map( id => tree_data?.filter( o => o[obj_key_nm]==id ).pop() ) || null);

		this.setState({
			sel_objs_keys: sel_objs_keys,
			sel_objs: sel_objs_keys?.map( id => tree_data?.filter( o => o[obj_key_nm]==id ).pop() ) || null, 
		})
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	handleSelect = async (sel_objs, leaf) => {

		const { onSelect, leafOnly = true } = this.props

		if (!sel_objs)
			return

		if (!leaf && leafOnly)
			return

		this.set_sels(sel_objs.oid)

		onSelect && onSelect(sel_objs)
		
		this.setState({ loading: true })
	}
	
	handleClear = async () => {

		const { onSelect} = this.props

		this.set_sels(null)

		onSelect && onSelect(null)

		this.setState({ loading: true })
	}
	
	handleOpen = () => {
		this.setState({ isOpen: true })
	}

	handleClose = () => {
		this.setState({ isOpen: false })
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	renderName() {
		const { placeholder } = this.props
		const { sel_objs_keys, sel_objs } = this.state

		return sel_objs?.map(o=>o&&o.name).join(", ") 
			|| sel_objs_keys?.join(",")
			|| placeholder
	}
	
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render() {
		
		const { get_tree_data, the_model, sel_btn_col, sel_btn_ico, show_loading, required } = this.props
		const { setBtn } = this.props
		const { isOpen, sel_objs, sel_objs_keys, loading } = this.state
	
		return <Button.Group className="org-selector">
				<Popup
						style={{maxHeight:'50vh',overflow:'scroll',width:'450px'}}
						on='click'
						position='bottom left' 
						open={this.state.isOpen}
						onClose={this.handleClose}
						onOpen={this.handleOpen}

						trigger={ <Button  
										type = "button" 
										color = {sel_btn_col(sel_objs)} 
										icon labelPosition='left' className='org-button'  
										title = {this.renderName()} 
										content = {<><Icon name={loading?'cloud upload':sel_btn_ico(sel_objs)} /> {this.renderName()}</>}
										loading = {show_loading&&loading}
									/> }

						content={<ShowTree
									get_tree_data = { get_tree_data }
									par_key = {the_model.PAR_KEY}
									it_key = {the_model.IT_KEY}
									it_ico = {the_model.IT_ICO}
									it_label = {the_model.IT_LABEL}
									it_id = {the_model.IT_ID}

									onSelect={this.handleSelect} 
									// showSearch={showSearch}
									// refresh={refresh}
									
									selectedKeys={sel_objs_keys}

									setBtnLabel={this.props.multiple?"Toggle":"Set"} 
									setBtn={setBtn} 
								/>}
					/>
				{ !required && sel_objs_keys && sel_objs_keys.length && <Button color="orange" onClick={this.handleClear}  icon='x' />}
			</Button.Group>
	}

}

export default SelectTree;
