import React, { useState, useEffect, useMemo } from 'react';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';
import AccessMyHR from 'views/myhr/AccessMyHR'
import { useProfile } from '../../../../xAppLib/Hooks';
import myhr_model from '../../../../models/myhr_model';

const ENABLE_MY_HR = true

const MyHR = (props) => {
	if (!ENABLE_MY_HR)
		return  null

	const { store, med_data, Section } = props;
	const [fields, setFields] = useState([]);
	const [profile] = useProfile();

	const user_myhr_exists = useMemo(() => {
		return profile.myhr?.exists
	}, [profile?.myhr?.exists]);

	useEffect(() => {
		const fields = script_model.MYHR_FIELDS
		setFields(fields);
	}, [med_data, store.yogp_want, user_myhr_exists]);

	useEffect(() => {
		if (!user_myhr_exists) {
			(async () => {
				await myhr_model.has_access();
				await app.user.reload_profile()
			})();
		}
	}, []);

	if(!user_myhr_exists){
		return <></>
	}

	return (
		<UniFormMed {...props} section="myhr" fields={fields}>
			{(values, valids, uf_this, fields) => {
				return (
					<Section>
						<Section.Header>My Health Record</Section.Header>
						<Section.Content>
							<AccessMyHR mode={AccessMyHR.SCRIPT} onUpdateAccess={(authorised)=>{
								// console.log("authorised",authorised)
								uf_this.handleInputChange(null, { name: 'myhr_grant', value: authorised });
							}}/>
						</Section.Content><br/>
					</Section>
				);
			}}
		</UniFormMed>
	);
};

export default MyHR;
