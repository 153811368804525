//	------------------------	------------------------	------------------------
//	Description: View to select patient details in eRx patient format
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { 	Input,
			Button,
            Icon,
            Accordion,
            Label,
            Checkbox,
            Dropdown,
            Modal,
            Table,
            Popup,
            TextArea,
        } from 'semantic-ui-react'

import MaskedInput from 'react-text-mask'

import erx_model from '../../models/erx/erx_model'
import {ERX_FORMAT} from '../../models/erx/resources/erx_formats'
import {PATIENT_ENUM} from '../../models/erx/resources/erx_enums'

import hi_model from '../../models/hi/hi_model'

import './style/prescribeditemselector.scss'

import moment from 'moment-timezone'

import erx_patient_model from '../../models/erx/erx_patient_model'

import * as j_f from '../../helpers/json_functions'

import debounce from 'xAppLib/libs/debounce'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const patient_props = erx_model.PATIENT_PROPS

const input_size = 'mini'

const patient_defaults = {
    'PatientSex': 'U',
}

// These should eventually do into props in a model somewhere
const ihi_status_options =      ['Active', 'Deceased', 'Retired']//, 'Unknown']
const ihi_rec_status_options =  ['Verified', 'Unverified', 'Provisional']//, 'Unknown']
const ihi_sex_option = {
    "Male":                             "M",
    "Female":                           "F",
    "Intersex":                         "I",
    "Unknown":                          "N",
}
const ihi_demo_conflict_options =  ['Yes', 'No', 'Unknown']//, 'Unknown']

const obj_path_ihi = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'ihiNumber']
const obj_path_ihi_rs = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'ihiRecordStatus']
const obj_path_ihi_s = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'ihiStatus']
const obj_path_ihi_sex = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'sex']
const obj_path_rec_conflict = ['audit_data', 'params', '0', 'demo_conflict']

const debounce_time = 250

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

class PatientSelector extends Component {

    constructor (props) {
        super(props)
        
        // Probably want to limit access here

        this.state = { patient: patient_defaults, patient_raw: patient_defaults}

        this.view_mode = !!props.viewOnlyMode
        
        if (props.patientDetails) this.load_patient_details(props.patientDetails, true)

        this.debounce_manual_ihi_verify = debounce(this.verify_manual_ihi, debounce_time)

	}

	//	------------------------	------------------------	------------------------

	componentDidMount () {

        // Make an initial call to change too
        //this.change_callback(this.state.patient)
        if (!this.view_mode) 
            this.post_patient_change()

	}

	//	------------------------	------------------------	------------------------

	componentDidUpdate(prevProps, prevState) {

        //this.change_callback(this.state.patient)
        
        // Update changes to escript prop
        //if (prevProps.escript != this.props.escript) this.setState({escript: this.props.escript})

    }

    //	------------------------	------------------------	------------------------

    render() {
        // console.log("this.script_context",this.script_context);
        const { script } = this.props
        return (
            <React.Fragment>
                <Accordion  fluid>
                    
                    <Accordion.Title 
                        onClick={ _=> this.setState({open: !this.state.open})}
                        index={0}
                    >
                         <h4>{this.render_check_header()} Patient: {this.patient_summary()}</h4>
                    </Accordion.Title>
                    
                    <Accordion.Content active={this.props.open || this.state.open} className='acc_cont'>
                    
                   

                {/* <Accordion styled fluid>

                    <Accordion.Title
                        onClick={_ => this.setState({ refine_patient_open: !this.state.refine_patient_open })}
                        index={0}
                        content={this.view_mode && 'Details' || '1. Refine patient'}
                    />

                    <Accordion.Content active={this.state.refine_patient_open} className='acc_cont'>
                        {this.render_patient_modifier()}
                    </Accordion.Content>

                    {this.render_check_content()}

                </Accordion> */}
                {this.render_patient_modifier()}
                {this.render_check_content()}

                {/* Patient IHI status*/}
                {(script.escript) && !this.view_mode &&
                    // ['Active', 'Deceased', 'Retired', 'Resolved']
                    ((this.state.patient_ihiStatus === undefined) || (this.state.patient_ihiStatus != 'Active')) &&
                    <React.Fragment>

                        <h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: Patient's IHI Status is: {this.state.patient_ihiStatus || 'Unknown'}</h4>
                        <p>
                            {this.state.patient_ihiStatus == 'Deceased' && 'Deceased patient should not prescribed a script.' ||
                                this.state.patient_ihiStatus == 'Retired' && 'Retired IHI should not prescribed a script.' ||
                                this.state.patient_ihiStatus == 'Resolved' && 'Resolved IHI indicates the patient has a different updated IHI. Search for an updated IHI.' ||
                                `Unrecognised Status. Should be one of ${ihi_status_options.join(', ')}. Search the patient's IHI to rectify.`}
                        </p>
                    </React.Fragment>
                }

                {/* Patient record conflict check*/}
                {(script.escript) && (this.state.patient_ihiDemoConflict == 'Yes') &&
                    <React.Fragment>

                        <h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: There are duplicate records for this patient</h4>
                        <p>Please perform a Patient IHI search \ Verify IHI to see which records are duplicates</p>
                    </React.Fragment> ||
                    (script.escript) && (this.state.patient_ihiDemoConflict == 'Unknown') &&
                    <React.Fragment>

                        <h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: Check for duplicate patient records</h4>
                        <p>This patient has not been checked for duplicate patient records. Please perform a Patient IHI search \ Verify IHI to check.</p>
                    </React.Fragment>
                }

                {this.render_ihi_select_confirm()}
                {this.render_ihi_status_update_confirm()}

                {/* Conformance IHI demo change */}
                {this.render_ihi_demo_check()}

                {this.render_manual_ihi()}
                 
                </Accordion.Content>
                    
                    
             </Accordion>

            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_ihi_demo_check() {

        const c_core_info = this.build_core_demo_info()

        // console.log('c_core_info')
        // console.log(c_core_info)

        // console.log('this.state.patient_ihi_demo')
        // console.log(this.state.patient_ihi_demo)

        // console.log('dif')
        // console.log(j_f.differences_in(c_core_info, this.state.patient_ihi_demo))

        const dif = j_f.differences_in(c_core_info, this.state.patient_ihi_demo)

        return (
            this.state.patient_ihi_demo && !j_f.contained_in(c_core_info, this.state.patient_ihi_demo) && 
            <React.Fragment>
                <h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: The patient's core demographic information is different from when the patient's IHI was assigned</h4>
                <p>It is recommended to check this patient's IHI using the IHI search saving this to the patients record</p>
                <Accordion styled fluid>

                    <Accordion.Title
                        onClick={_ => this.setState({ view_dif: !this.state.view_dif })}
                        index={0}
                        content='View all differences'
                    />

                    <Accordion.Content active={this.state.view_dif} className='acc_cont'>

                    <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Field
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Current value
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Old value
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(dif && dif.length == 0) &&
                            <Table.Row>
                                <Table.Cell colSpan="3">
                                    <p>No differences</p>
                                </Table.Cell>
                            </Table.Row>
                        || (dif && dif.length > 0) &&
                            dif.map((d, i) => {
                                return (
                                    <React.Fragment key={`e_m_${i}`}>
                                        <Table.Row>
                                            <Table.Cell><p>{d.path.join(' - ') || '?'}</p></Table.Cell>
                                            <Table.Cell><p>{JSON.stringify(d.obj1) || '?'}</p></Table.Cell>
                                            <Table.Cell><p>{JSON.stringify(d.obj2) || '?'}</p></Table.Cell>
                                        </Table.Row>
                                    </React.Fragment>
                                )
                            })
                        || 
                            <Table.Row>
                                <Table.Cell colSpan="3">
                                    <p>No data</p>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                    </Table>

                    </Accordion.Content>

                </Accordion>
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_check_content() {
        const { patient_check } = this.props
        // console.log({patient_check});
        return (

            patient_check?.res == 'err' && patient_check?.err?.map &&

            <React.Fragment>

                <Accordion.Title onClick={_ => this.setState({ err_open: !this.state.err_open })}>
                    <h4 className='txt_valid_error'><Icon name={this.state.err_open && 'triangle down' || 'triangle right'} />Patient errors ({patient_check.err.length})</h4>
                </Accordion.Title>

                <Accordion.Content active={this.state.err_open} className='acc_cont'>

                    {patient_check.err.map((err, i) => {
                        return (
                            <React.Fragment key={`e_m_${i}`}>
                                <h4 className='txt_valid_invalid'><Icon name='exclamation' />{typeof err == 'string' && err || JSON.stringify(err)}</h4>
                            </React.Fragment>
                        )
                    })}

                </Accordion.Content>

            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_check_header() {
        const { patient_check } = this.props

        return (
            patient_check?.res == 'ok' && <Icon className='txt_valid_valid' name='checkmark' /> ||
            patient_check?.res == 'err' && <Icon className='txt_valid_invalid' name='exclamation' /> ||
            <Icon className='txt_valid_checking' name='checkmark' />
        )
    }

    //	------------------------	------------------------	------------------------

    render_additional_info() {

        return (
            <React.Fragment>
                <h4>Recorded IHI</h4>
                <div className='field_cls'>
                    <Label className='field_label'>Individual Healthcare Identifier</Label>
                    <Input
                        disabled
                        placeholder={'Individual Healthcare Identifier'}
                        value={this.state.patient['PatientIHI'] || 'Unknown'}
                    />
                    {/* <p>{this.state.patient['PatientIHI'] || 'Unknown'}</p> */}
                </div>
                <div className='field_cls'>
                    <Label className='field_label'>IHI Status</Label>
                    <Dropdown
                        selection
                        //disabled
                        placeholder={'IHI Status'}
                        options={ihi_status_options.map((o, i) => { return { key: `o_${i}`, text: o, value: o, disabled: true} })}
                        value={this.state.patient_ihiStatus || 'Unknown'}
                    />
                </div>
                <div className='field_cls'>
                    <Label className='field_label'>IHI Record status</Label>
                    <Dropdown
                        selection
                        //disabled
                        placeholder={'IHI Record status'}
                        options={ihi_rec_status_options.map((o, i) => { return { key: `o_${i}`, text: o, value: o, disabled: true } })}
                        value={this.state.patient_ihiRecordStatus || 'Unknown'}
                    />
                </div>
                <div className='field_cls'>
                    <Label className='field_label'>Record conflict</Label>
                    <Dropdown
                        selection
                        //disabled
                        placeholder={'Record conflict'}
                        options={ihi_demo_conflict_options.map((o, i) => { return { key: `o_${i}`, text: o, value: o, disabled: true } })}
                        value={this.state.patient_ihiDemoConflict || 'Unknown'}
                    />
                </div>
                <h4>IHI History (refresh page if changed)</h4>
                
                <div className='scroll_table'>
                <Table celled>
                    <Table.Header>
                        <Table.Row className='stick'>
                            <Table.HeaderCell>
                                No
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Date / Time
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                IHI
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Record Status
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Record conflict
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Assigned by
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Demographics
                            </Table.HeaderCell>
                        </Table.Row>
                        
                    </Table.Header>
                    <Table.Body>
                        {this.state.patient_ihi_history && this.state.patient_ihi_history.map((o, i) => {
                            return (
                                <Table.Row key={`t_r_${i}`}>
                                    <Table.Cell>
                                        {i+1}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {o.tm}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {o.ihi}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {o.ihiStatus}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {o.ihiRecordStatus}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {o.ihiDemoConflict || 'Unknown'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Popup
                                            trigger={<p>{o.assignee && o.assignee.name}</p>}
                                            content={o.assignee && o.assignee.uid || '?'}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <TextArea
                                            disabled
                                            value={JSON.stringify(o.demo_info, null, 2)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                </div>

            </React.Fragment>
        )

    }
    
    //	------------------------	------------------------	------------------------

    render_ihi_status_update_confirm() {

        const ihi = this.state.selected_ihi && this.state.selected_ihi.ihi || null
        //const recs = this.state.selected_ihi && this.state.selected_ihi.data || []

        return (

            <Modal
                open={!!this.state.updating_ihi_status}
                closeIcon
                onClose={this.dont_assign_patient_ihi.bind(this)} //Using this since we want to await any assign server responses
                //dimmer='blurring' // This looks cool, but breaks messages by hiding them :()
                >
                <Modal.Header><Icon name='exclamation circle' />IHI record status has changed for {ihi}</Modal.Header>
                <Modal.Content>
                    <h3>The patient's IHI record status has changed from {this.state.patient_ihiRecordStatus || '?'} to {this.state.selected_ihi && this.state.selected_ihi.ihiRecordStatus || '?'}.</h3>
                    <p>You must confirm that this change is legitimate before this is saved to the patient's record.</p>
                    <p>If you are unsure if this is correct, use the manual Patient IHI search to find and Set the appropriate IHI details.</p>
                    <p>For more information please contact InstantScripts support or the HI Service Team through Medicare on <a href='tel:1300 361 457'>1300 361 457</a>.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.dont_assign_patient_ihi.bind(this)} loading={this.state.assigning_ihi}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' onClick={async _ => { this.assign_patient_ihi(this.state.selected_ihi, 'Update') }} loading={this.state.assigning_ihi} disabled={this.state.selected_ihi && this.state.selected_ihi.ihiRecordStatus != 'Verified'}>
                        <Icon name='checkmark' /> Assign updated IHI
                    </Button>
                </Modal.Actions>

            </Modal>

        ) 

    }

    //	------------------------	------------------------	------------------------

    render_ihi_select_confirm() {

        //else this.setState({setting_ihi: true, selected_ihi: {ihi, data}})
        // Get the ihi and status options

        const ihi = this.state.selected_ihi && this.state.selected_ihi.ihi || null
        const recs = this.state.selected_ihi && this.state.selected_ihi.data || []

        // Build the options for the IHI statis and record status (although these will probably always be one value)
        const ihi_stat_opt = ihi_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true }})
        const ihi_rec_stat_opt = ihi_rec_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true }})
        const ihi_sex_opt = Object.keys(ihi_sex_option).map((o, i)=>{ return { key:`o_${i}`, text: o, value: ihi_sex_option[o], disabled: true }})
        const ihi_demo_conflict_opt = ihi_demo_conflict_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true }})

        // console.log('this.state.selected_ihi')
        // console.log(this.state.selected_ihi.ihiDemoConflict)

        // Only enable those options found in the IHI lookup
        recs.forEach(r=>{ 
            const stat_opt = ihi_stat_opt.find(o=>{ return o.value == r.ihiStatus }) 
            if (stat_opt) stat_opt.disabled = false

            const rec_stat_opt = ihi_rec_stat_opt.find(o=>{ return o.value == r.ihiRecordStatus }) 
            if (rec_stat_opt) rec_stat_opt.disabled = false

            const sex_opt = ihi_sex_opt.find(o=>{ return o.value == r.sex }) 
            if (sex_opt) sex_opt.disabled = false

            const demo_conflict_opt = ihi_demo_conflict_opt.find(o=>{ return o.value == (r.params && r.params.demo_conflict && 'Yes' || 'No') }) 
            if (demo_conflict_opt) demo_conflict_opt.disabled = false

            ///console.log(r)

            //selected_ihi
        })

        //const ihi_rec_stat_opt = Object.keys(recs.reduce((a,c)=>{a[c.ihiRecordStatus || 'unknown'] = true; return a}, {})).reduce((a, c, i)=>{if (c != 'unknown') a.push({key:`o_${i}`, text: c, value: c}); return a}, [])
        //const ihi_stat_opt = Object.keys(recs.reduce((a,c)=>{a[c.ihiStatus || 'unknown'] = true; return a}, {})).reduce((a, c, i)=>{if (c != 'unknown') a.push({key:`o_${i}`, text: c, value: c}); return a}, [])

        return (

            <Modal
                open={!!this.state.setting_ihi}
                closeIcon
                onClose={this.dont_assign_patient_ihi.bind(this)} //Using this since we want to await any assign server responses
                //dimmer='blurring' // This looks cool, but breaks messages by hiding them :()
                >
                <Modal.Header><Icon name='exclamation circle' />Confirm setting IHI</Modal.Header>
                <Modal.Content>
                    <h4>IHI details</h4>
                    <div className='field_cls'>
                        <Label className='field_label'>IHI</Label><p>{ihi}</p>
                    </div>
                    <div className='field_cls'>
                        <Label className='field_label'>IHI Status</Label>
                        <Dropdown
                            selection
                            placeholder={'IHI Status'}
                            options={ihi_stat_opt}
                            value={this.state.selected_ihi && this.state.selected_ihi.ihiStatus || ''}
                        />
                    </div>
                    <div className='field_cls'>
                        <Label className='field_label'>IHI Record status</Label>
                        <Dropdown
                            selection
                            placeholder={'IHI Record status'}
                            options={ihi_rec_stat_opt}
                            value={this.state.selected_ihi && this.state.selected_ihi.ihiRecordStatus || ''}
                        />
                    </div>
                    {this.state.selected_ihi && this.state.selected_ihi.ihiRecordStatus != 'Verified' &&
                        <React.Fragment>
                            <h4 className='txt_valid_invalid'><Icon name='exclamation triangle' />Error: The patient's IHI Record Status is {this.state.selected_ihi.ihiRecordStatus || '?'}</h4>
                            <p>Only Verified IHI's are supported. This IHI cannot be saved.</p>
                            <p>Please contact InstantScripts support or the HI Service Team through Medicare on <a href="tel:1300361457">1300 361 457</a></p>
                        </React.Fragment>
                    }
                    <div className='field_cls'>
                        <Label className='field_label'>IHI Record sex</Label>
                        <Dropdown
                            selection
                            placeholder={'IHI Record sex'}
                            options={ihi_sex_opt}
                            value={this.state.selected_ihi && this.state.selected_ihi.ihiSex || ''}
                        />
                    </div>
                    {this.state.patient && this.state.selected_ihi && /*this.state.patient['PatientSex'] && this.state.selected_ihi.ihiSex &&*/ (this.state.patient['PatientSex'] != this.state.selected_ihi.ihiSex) &&
                        <React.Fragment>
                            <h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: Recorded patient sex is different from sex return by the IHI (saved sex: {this.state.patient['PatientSex'] || '?'}, IHI lookup sex: {this.state.selected_ihi.ihiSex || '?'})</h4>
                            <p>Recorded patient sex will not be automatically changed to match IHI records.</p>
                            <p>To save a new patient sex, change the Patient Sex field first, re-run the search confirm this matches the IHI record, then save the IHI (the new sex will be saved to the patient's record).</p>
                        </React.Fragment>
                    }
                    <div className='field_cls'>
                        <Label className='field_label'>Patient record conflict</Label>
                        <Dropdown
                            selection
                            placeholder={'Patient record conflict'}
                            options={ihi_demo_conflict_opt}
                            value={this.state.selected_ihi && this.state.selected_ihi.ihiDemoConflict || ''}
                        />
                    </div>
                    {this.state.selected_ihi && this.state.selected_ihi.ihiDemoConflict == 'Yes' && 
                        <React.Fragment>
                            <h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: There are duplicate records for this patient</h4>
                            <p>There are multiple records that contain the same demographic data as this patient's record. This indicates duplicate records. This warning will be stored with this IHI.</p>
                        </React.Fragment>
                    }
                    <h4><Icon name='exclamation'></Icon> Important note</h4>
                    <p>This will assign the IHI {this.state.selected_ihi && this.state.selected_ihi.ihi} to this script</p>
                    <p><em>and</em></p>
                    <p>Assign the IHI {this.state.selected_ihi && this.state.selected_ihi.ihi} ({this.state.selected_ihi && this.state.selected_ihi.ihiStatus} / {this.state.selected_ihi && this.state.selected_ihi.ihiRecordStatus}) to this patient's record. This means this IHI which will be used for any future scripts too. The patient's IHI record can still be changed in future. A record of a patient's IHI history is kept.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.dont_assign_patient_ihi.bind(this)} loading={this.state.assigning_ihi}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' onClick={async _ => { this.assign_patient_ihi(this.state.selected_ihi, 'Auto') }} loading={this.state.assigning_ihi} disabled={this.state.selected_ihi && this.state.selected_ihi.ihiRecordStatus != 'Verified'}>
                        <Icon name='checkmark' /> Assign IHI
                    </Button>
                </Modal.Actions>

            </Modal>

        )

    }

    //	------------------------	------------------------	------------------------

    render_ihi_selector() {

        return (
            <React.Fragment>
                {/* <Header icon='search' content='Find patient IHI (Individual Healthcare Identifier)' /> */}
                <React.Fragment>

                    {this.render_ihi_results()}

                </React.Fragment>

            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_ihi_results() {

        // Search the results
        const ihi_res = this.build_ihi_list_from_search_result()
        const headers = ['Unique IHI', 'Search type', 'Result data', 'Matched users', 'Select']


        return (
            <React.Fragment>

                {/* {JSON.stringify(ihi_res)} */}
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {headers.map((h, i)=>{
                                return (
                                    <Table.HeaderCell key={`h_i_${i}`}>{h}</Table.HeaderCell>
                                )
                            })}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.running_lookup &&
                        <Table.Row>
                            <Table.Cell colSpan="4">
                                Loading results...
                            </Table.Cell>
                        </Table.Row>
                        ||
                        (!this.state.ihi_lookup) &&
                        <Table.Row>
                            <Table.Cell colSpan="4">
                                <p>Click Search for patient IHI</p>
                            </Table.Cell>
                        </Table.Row>
                        || Object.keys(ihi_res).length == 0 &&
                            <Table.Row>
                                <Table.Cell colSpan="4">
                                    <h4>No results</h4>
                                    <p>Please check the patient details are correct. Consider using historical data (e.g. maiden name).</p>
                                    <p>For other IHI search types please contact the HI Service Team through Medicare on <a href='tel:1300 361 457'>1300 361 457</a>.</p>
                                </Table.Cell>
                            </Table.Row>
                        ||
                         Object.keys(ihi_res).map((o, i) => { 
                            const ihi = this.ihi_from_uri(o)
                            const res_data = ihi_res[o].reduce((a,c)=>{
                                Object.keys(c).forEach(k=>{
                                    if (k != 'params'){
                                        if (typeof a[k] != 'undefined') a[k] = {...a[k], [c[k]]: a[k][c[k]] && a[k][c[k]] + 1 || 1}
                                        else a[k] = {[c[k]]: 1}
                                    }
                                })
                                return a
                            }, {})

                            // See if we have any conflicting demographics                            
                            res_data.user_conflict = ihi_res[o] && ihi_res[o].find && !!ihi_res[o].find(c=>c.params&&c.params.demo_conflict) && {'Yes':true} || {'No':true}

                            const search_type = Object.keys(ihi_res[o].reduce((a, c) => {
                                switch (c.search_type){
                                    case 'uaddress':    { a['Address'] = true; return a}
                                    case 'medicare':    { a['Medicare number'] = true; return a}
                                    case 'dva':         { a['DVA number'] = true; return a}
                                    case 'ihi':         { a['IHI number'] = true; return a}
                                    default:            { a['Unknown'] = true; return a}
                                }
                            }, {})).join(', ')

                            return (
                            <Table.Row key={'t_r_' & i}>
                                <Table.Cell>
                                    {ihi}
                                </Table.Cell>
                                <Table.Cell>
                                    {search_type}
                                </Table.Cell>
                                <Table.Cell>
                                    {this.render_ihi_result(res_data)}
                                </Table.Cell>
                                <Table.Cell>
                                    {this.render_matched_users(ihi_res[o])}
                                </Table.Cell>
                                <Table.Cell>
                                    <Popup
                                        hoverable
                                        trigger={<Button
                                            size='small'
                                            fluid
                                            onClick={_ => this.handle_ihi_select(ihi, ihi_res[o])}
                                            color='green'
                                        >
                                            <Icon name='checkmark' /> Set
                                        </Button>}
                                        content={<React.Fragment><h4>Set patient IHI</h4><p>Saves this Individual Healthcare Identifier (IHI) and associated information to this patient's record.</p></React.Fragment>}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )
                        }) }
                    </Table.Body>
                </Table>
                
                <Button
                    size='medium'
                    icon
                    //basic
                    //label='Search IHI'
                    color='green'
                    className='search_ihi_but'
                    onClick={_=>this.run_ihi_lookup(this.state.patient, false)}
                    loading={this.state.running_lookup}
                    floated='right'
                    content={<React.Fragment><Icon name='search' /> Search for patient IHI</React.Fragment>}/>
                        
                <br/><br/>
                
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    build_ihi_list_from_search_result() {

        let ihi_res = {}

        if (this.state.ihi_lookup &&
            this.state.ihi_lookup.res == 'ok' &&
            this.state.ihi_lookup.search_type &&
            this.state.ihi_lookup.search_result &&
            this.state.ihi_lookup.audit_data &&
            this.state.ihi_lookup.audit_data.params &&
            this.state.ihi_lookup.search_result.searchIHIBatchResult &&
            Array.isArray(this.state.ihi_lookup.search_result.searchIHIBatchResult) &&
            Array.isArray(this.state.ihi_lookup.search_type) &&
            Array.isArray(this.state.ihi_lookup.audit_data.params) &&
            (this.state.ihi_lookup.search_result.searchIHIBatchResult.length == this.state.ihi_lookup.search_type.length) && 
            (this.state.ihi_lookup.search_result.searchIHIBatchResult.length == this.state.ihi_lookup.audit_data.params.length)
            ) {
            // See if there is any IHI
            this.state.ihi_lookup.search_result.searchIHIBatchResult.forEach((res, i) => {
                if (res &&
                    res.searchIHIResult &&
                    res.searchIHIResult.ihiNumber) {
                    if (ihi_res[res.searchIHIResult.ihiNumber]) {
                        ihi_res[res.searchIHIResult.ihiNumber] = [...ihi_res[res.searchIHIResult.ihiNumber], { ...res.searchIHIResult, search_type: this.state.ihi_lookup.search_type[i], params: this.state.ihi_lookup.audit_data.params[i] }]
                    } else ihi_res[res.searchIHIResult.ihiNumber] = [{ ...res.searchIHIResult, search_type: this.state.ihi_lookup.search_type[i], params: this.state.ihi_lookup.audit_data.params[i] }]
                }
            })
        } else {
            //console.log('failed')
            //console.log(this.state.ihi_lookup)
            //if (this.message_context && this.message_context.add_message) app.functions.add_message({type: 'negative', header:'Error interpreting search results', content: 'Failed to work out the search results'})
        }

        return ihi_res
    }

    //	------------------------	------------------------	------------------------

    ihi_from_uri(uri) {
        if (typeof uri != 'string') return ''
        const ihi_split = uri.split('/')
        return ihi_split[ihi_split.length - 1] 
    }

    //	------------------------	------------------------	------------------------

    render_ihi_result(res_data) {

        return (
            Object.keys(res_data).map((o,i)=>{
                if (o != 'search_type')
                return (
                    <React.Fragment key={`r_d_${i}`}>
                        <div className='field_cls'>
                            <Label className='field_label'>{o}</Label>
                            {Object.keys(res_data[o]).map((p, j)=>{
                                return (
                                    <React.Fragment key={`r_dd_${j}`}>
                                        <p>
                                        {o == 'ihiNumber' && this.ihi_from_uri(p) || p} {res_data[o][p] > 1 && `(x${res_data[o][p]} records)` || ''}{j < (Object.keys(res_data[o]).length - 1) && ', ' || '' /* Add comma if multiple */}
                                        </p>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </React.Fragment>
                )
            })
        )
    }

    //	------------------------	------------------------	------------------------

    render_matched_users(user_data) {

        const user_list = {}

        if (user_data && Array.isArray(user_data)){
            user_data.forEach(d=>{
                if (d.params && d.params.demo_users && Array.isArray(d.params.demo_users)){
                    d.params.demo_users.forEach(u=>{
                        if (u.uid){
                            user_list[u.uid] = u
                        }
                    })
                }
            })
        }

        return (
            <React.Fragment>

                {Object.keys(user_list).length > 0 && 
                
                Object.keys(user_list).map((o, i)=>{
                    
                    const user = user_list[o]
                    const name = `${user.first_name || '?'} ${user.last_name || '?'}`
                    let name_short = name
                    if (name_short.length > 15) name_short = `${name_short.slice(0,10)}...`
                    let uid_short = user.uid && user.uid.slice && `${user.uid.slice(0, 4)}...`
                    let dob = `${moment(user.dob).format('YYYY-MM-DD') || 'Unknown DOB'}`

                    return (

                        <React.Fragment key={`u_k_${i}`}>

                            <Popup
                                hoverable
                                trigger={<div>
                                    <React.Fragment><p>
                                            {name_short}<br/>
                                            {dob}<br/>
                                            {this.state.patient_uid == user.uid && 
                                                <span className='txt_valid_valid'>ID: {uid_short} <Icon name='checkmark' /></span> || 
                                                <span className='txt_valid_warning'>ID: {uid_short} <Icon name='exclamation triangle' /></span>}
                                    </p><br/></React.Fragment>
                                </div>} // Trigger doesnt seem to like React.Fragment as the outer element (kills the content popup)
                                content={
                                    <React.Fragment>
                                        <h4>{name}</h4>
                                        <p>{dob}</p>
                                        <p>{user.uid}</p>
                                        {this.state.patient_uid == user.uid && 
                                                <h4 className='txt_valid_valid'><Icon name='checkmark' /> This matches the ID of the patient being searched</h4> || 
                                                <React.Fragment><h4 className='txt_valid_warning'><Icon name='exclamation triangle' /> This matches the ID of another stored patient</h4><p>This indicates these records are duplicates for this patient.</p></React.Fragment>}
                                    </React.Fragment>
                                }
                                />

                        </React.Fragment>

                    )})

                || 'None'}

        
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_patient_modifier() {

        const { script } = this.props

        return (
            <React.Fragment>
                <div className='field_container'>
                    {patient_props.map((prop, i) => {
                        if (prop.fe_show){

                            let inner = ''
                            let patient_key = `f_k_${i}`

                            const clear_button = (
                                <Button
                                        size={input_size}
                                        icon
                                        basic
                                        color='grey'
                                        className='field_button'
                                        onClick={_=>{this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: null}}, this.post_patient_change)}}
                                        content={<Icon name='delete' />}
                                    />
                            )

                            const revert_button = (
                                <Button
                                        size={input_size}
                                        icon
                                        basic
                                        color='green'
                                        className='field_button'
                                        content={<Icon name='undo' />}
                                        onClick={_=>{this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: this.state.patient_raw[prop.name]}}, this.post_patient_change)}}
                                    />
                            )

                            // FE Edit

                            const _this = this
                            let allow_edit = true
                            // if (typeof prop.fe_edit == 'function') allow_edit = prop.fe_edit(script || {})
                            // else allow_edit = !!prop.fe_edit

                            const label = <Label className='field_label'>{prop.label} {!((prop.nillable == true) || (prop.minOccurs === 0)) && '*' /*<Icon name='asterisk' />*/}</Label>

                            // String
                            if (prop.format == 'string' || prop.format == 'cons_num' || prop.format == 'safe_num' || prop.format == 'dva_num') inner = (
                                <div className='field_cls' key={patient_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        value={this.state.patient && this.state.patient[prop.name] || ''}
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        onChange={(e, d)=>{this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: (d.value == '') ? null : d.value}}, this.post_patient_change)}}
                                    />
                                    {allow_edit && (this.state.patient[prop.name] != this.state.patient_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.patient[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Boolean
                            else if (prop.format == 'boolean') inner = (
                                <div className='field_cls' key={patient_key}>
                                    {label}
                                    <div className='field_input'>
                                    <Checkbox
                                        //size={input_size}
                                        className='field_check'
                                        checked={(typeof this.state.patient[prop.name] == 'string' && this.state.patient[prop.name] == 'true') || (typeof this.state.patient[prop.name] == 'boolean' && this.state.patient[prop.name]) || false}
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        label={(typeof this.state.patient[prop.name] == 'undefined' || this.state.patient[prop.name] == null) && 'Unfilled' || this.state.patient[prop.name] && 'Yes' || 'No'}
                                        indeterminate={typeof (this.state.patient && this.state.patient[prop.name]) == 'undefined' || (this.state.patient && this.state.patient[prop.name] == null)}
                                        onChange={_=>{this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: !(this.state.patient && this.state.patient[prop.name])}}, this.post_patient_change)}}
                                    />
                                    </div>
                                    {allow_edit && (this.state.patient[prop.name] != this.state.patient_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.patient[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Number
                            else if (prop.format == 'number' || prop.format == 'unumber') inner = (
                                <div className='field_cls' key={patient_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        value={this.state.patient && this.state.patient[prop.name] || ''}
                                        onChange={(e, d)=>{
                                            let new_num = Number(d.value)
                                            if (isNaN(new_num)) new_num = 0
                                            if (prop.format == 'unumber' && new_num < 0) new_num = 0
                                            this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: new_num}}, this.post_patient_change)}
                                        }
                                    />
                                    {allow_edit && (this.state.patient[prop.name] != this.state.patient_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.patient[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Numeric
                            else if (prop.format == 'numeric') inner = (
                                <div className='field_cls' key={patient_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        value={this.state.patient && this.state.patient[prop.name] || ''}
                                        onChange={(e, d)=>{
                                            let new_num = d.value.replace(/[^0-9]/g, '')
                                            if (new_num == '') new_num = null
                                            this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: new_num}}, this.post_patient_change)}
                                        }
                                    />
                                    {allow_edit && (this.state.patient[prop.name] != this.state.patient_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.patient[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )
                            
                            // Check for enums
                            else if (ERX_FORMAT[prop.format] && ERX_FORMAT[prop.format].enums){
                                
                                // Build the list of enums for selection
                                const enum_list = []

                                Object.keys(ERX_FORMAT[prop.format].enums).forEach((k, j) => {
                                    enum_list.push({key: `drop_${i}_${j}`, text: `${k} (${ERX_FORMAT[prop.format].enums[k]})`, value: ERX_FORMAT[prop.format].enums[k]})
                                })

                                inner = (
                                    <div className='field_cls' key={patient_key}>
                                        {label}
                                        <div className='field_input'>
                                        <Dropdown
                                            selection
                                            fluid
                                            size={input_size}
                                            placeholder={prop.label}
                                            disabled={!allow_edit || this.view_mode}
                                            //icon={!prop.fe_edit || this.view_mode && 'lock' || undefined}
                                            options={enum_list}
                                            value={this.state.patient && this.state.patient[prop.name] || ''}
                                            onChange={(e, d)=>{this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: d.value}}, this.post_patient_change)}}
                                        />
                                        </div>
                                        {allow_edit && (this.state.patient[prop.name] != this.state.patient_raw[prop.name]) && !this.view_mode && revert_button}
                                        {allow_edit && (this.state.patient[prop.name] != undefined) && !this.view_mode && clear_button}
                                    </div>
                                )

                            }

                            // Date
                            else if (prop.format == 'xml_date') inner = (
                                <div className='field_cls' key={patient_key}>
                                    {label}
                                    <Input
                                        size={input_size}
                                        type={'date'}
                                        //maxLength={prop.length || ''}
                                        placeholder={prop.label}
                                        className='field_input'
                                        disabled={!allow_edit || this.view_mode}
                                        icon={(!allow_edit || this.view_mode) && 'lock' || undefined}
                                        value={this.state.patient && this.state.patient[prop.name] || ''}
                                        onChange={(e, d)=>{this.setState({patient: {...(this.state.patient && this.state.patient), [prop.name]: d.value}}, this.post_patient_change)}}
                                    />
                                    {allow_edit && (this.state.patient[prop.name] != this.state.patient_raw[prop.name]) && !this.view_mode && revert_button}
                                    {allow_edit && (this.state.patient[prop.name] != undefined) && !this.view_mode && clear_button}
                                </div>
                            )

                            // Unknown
                            else {
                                inner = (
                                    <div key={patient_key}>
                                        {label}
                                        {`Unknown - ${prop.label} (${prop.format})`}
                                    </div>
                                )
                            }

                            return inner
                            
                        }
                    })}
                </div>
                <br/>

                { !this.view_mode &&
                    <React.Fragment>
                        
                        <Button
                            size={input_size}
                            icon
                            basic
                            label='Clear all'
                            className='field_button'
                            onClick={_ => {
                                const patient = this.state.patient
                                patient_props.forEach(prop => {
                                    let allow_edit = true
                                    if (typeof prop.fe_edit == 'function') allow_edit = prop.fe_edit(this.props.script || {})
                                    else allow_edit = !!prop.fe_edit
                                    if (allow_edit) patient[prop.name] = null 
                                    //if (prop.fe_edit) patient[prop.name] = null 
                                })
                                this.setState({ patient }, this.post_patient_change)
                            }}
                            floated='right'
                            content={<Icon name='delete' />}
                        />
                        <Button
                            size={input_size}
                            icon
                            basic
                            label='Revert all'
                            color='green'
                            className='field_button'
                            floated='right'
                            content={<Icon name='undo' />}
                            onClick={_ => { this.setState({ patient: JSON.parse(JSON.stringify(this.state.patient_raw)) }, this.post_patient_change) }}
                        />
                        <br /><br />
                    </React.Fragment>}
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_manual_ihi() {

        // Build the options for the IHI statis and record status (although these will probably always be one value)
        const ihi_stat_opt = ihi_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true}})
        const ihi_rec_stat_opt = ihi_rec_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true}})
        const ihi_sex_opt = Object.keys(ihi_sex_option).map((o, i)=>{ return { key:`o_${i}`, text: o, value: ihi_sex_option[o], disabled: true}})
        const ihi_demo_conflict_opt = ihi_demo_conflict_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true}})

        const ihi_data = {
            ihi: this.state.manual_ihi_ihi,
            ihiRecordStatus: this.state.manual_ihi_rec_status,
            ihiStatus: this.state.manual_ihi_status,
            //ihiSex: this.state.manual_ihi_sex,
            ihiSex: this.state.patient['PatientSex'],
            ihiDemoConflict: this.state.manual_ihi_democonflict,
            //data: first_res
        }

        return (
            <Modal
                open={!!this.state.manual_ihi}
                closeIcon
                onClose={this.dont_assign_patient_ihi.bind(this)}
                >
                <Modal.Header><Icon name='exclamation circle' />Manually set IHI</Modal.Header>
                <Modal.Content>
                    <h4>Manual IHI details</h4>
                    <div className='field_cls'>
                        <Label className='field_label'>Individual Healthcare Identifier (IHI)</Label>
                        <MaskedInput
                            placeholder={'Individual Healthcare Identifier'}
                            value={this.state.manual_ihi_ihi}
                            // For conformance 005845
                            mask={[ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                            onChange={e=>{
                                const value = e && e.target && e.target.value && e.target.value.toString && e.target.value.toString() || ''
                                const new_num = value.replace(/[^0-9]/g, '')
                                this.setState({manual_ihi_ihi: new_num, manual_ihi_check: this.check_ihi(new_num), manual_ihi_valid: 'unchecked'}, async _=> {
                                    if (this.state.manual_ihi_check.res == 'ok') this.verify_manual_ihi(this.state.patient)
                                })
                            }}
                            disabled={this.state.manual_ihi_verify_running}
                        />
                    </div>
                    <div className='field_cls'>
                        <Label className='field_label'>IHI Status</Label>
                        <Dropdown
                            selection
                            placeholder={'IHI Status'}
                            options={ihi_stat_opt}
                            //onChange={(e,d)=>{this.setState({manual_ihi_status: d.value})}}
                            value={this.state.manual_ihi_status || ''}
                            disabled={true}
                        />
                    </div>
                    <div className='field_cls'>
                        <Label className='field_label'>IHI Record status</Label>
                        <Dropdown
                            selection
                            placeholder={'IHI Record status'}
                            options={ihi_rec_stat_opt}
                            //onChange={(e,d)=>{this.setState({manual_ihi_rec_status: d.value})}}
                            value={this.state.manual_ihi_rec_status || ''}
                            disabled={true}
                        />
                    </div>
                    {this.state.manual_ihi_rec_status && this.state.manual_ihi_rec_status != 'Verified' &&
                        <React.Fragment>
                            <h4 className='txt_valid_invalid'><Icon name='exclamation triangle' />Error: The patient's IHI Record Status is {this.state.manual_ihi_rec_status || '?'}</h4>
                            <p>Only Verified IHI's are supported. This IHI cannot be saved.</p>
                            <p>Please contact InstantScripts support or the HI Service Team through Medicare on <a href="tel:1300361457">1300 361 457</a></p>
                        </React.Fragment>
                    }
                    <div className='field_cls'>
                        <Label className='field_label'>IHI Record sex</Label>
                        <Dropdown
                            selection
                            placeholder={'IHI Record sex'}
                            options={ihi_sex_opt}
                            //onChange={(e,d)=>{this.setState({manual_ihi_sex: d.value})}}
                            value={this.state.manual_ihi_sex || ''}
                            disabled={true}
                        />                        
                    </div>
                    {this.state.manual_ihi_sex && this.state.manual_ihi_sex != this.state.patient['PatientSex'] &&
                        <React.Fragment><p><Icon className='txt_valid_invalid' name='exclamation triangle' /> The patient's sex ({this.state.patient['PatientSex'] || '?'}) is different from the IHI record sex ({this.state.manual_ihi_sex || '?'})</p></React.Fragment>
                    }
                    <div className='field_cls'>
                        <Label className='field_label'>Patient record conflict</Label>
                        <Dropdown
                            selection
                            placeholder={'Patient record conflict'}
                            options={ihi_demo_conflict_opt}
                            //onChange={(e,d)=>{this.setState({manual_ihi_democonflict: d.value})}}
                            value={this.state.manual_ihi_democonflict || ''}
                            disabled={true}
                        />
                    </div>
                    {this.state.manual_ihi_democonflict == 'Yes' &&
                        <React.Fragment><h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: There are duplicate records for this patient</h4><p>There are multiple records that contain the same demographic data as this patient's record. This indicates duplicate records. This warning will be stored with this IHI.</p></React.Fragment>
                    }
                    <h4>IHI verification</h4>
                    {
                        this.state.manual_ihi_verify_running && <p className='txt_valid_warning'>Verifying IHI...</p> ||
                        this.state.manual_ihi_valid == 'valid' && <p className='txt_valid_valid'>Valid IHI</p> ||
                        this.state.manual_ihi_valid == 'unchecked' && <p className='txt_valid_warning'>Unchecked</p> ||
                        <React.Fragment><p className='txt_valid_invalid'>Invalid</p><p>This IHI number is not associated with this patient's demogrpahic information and can not be saved.</p><p>Please check the patient's demographic information or contact the HI Service Team through Medicare on <a href="tel:1300361457">1300 361 457</a></p></React.Fragment>
                    }
                    
                    <h4><Icon name='exclamation'></Icon> Important note</h4>
                    <p>This will assign the IHI {this.state.manual_ihi_ihi || '?'} to this script</p>
                    <p><em>and</em></p>
                    <p>Assign the IHI {this.state.manual_ihi_ihi || '?'} ({this.state.manual_ihi_status || '?'} / {this.state.manual_ihi_rec_status || '?'}) to this patient's record. This means this IHI which will be used for any future scripts too. The patient's IHI record can still be changed in future. A record of a patient's IHI history is kept.</p>

                    { // Show IHI errors
                        this.state.manual_ihi_check && this.state.manual_ihi_check.res == 'err' &&
                        <h4 className='txt_valid_invalid'><Icon name='exclamation' />Invalid IHI: {this.state.manual_ihi_check.err}</h4>
                    }                    

                </Modal.Content>
                <Modal.Actions>
                    {/* <Button color='orange' disabled={this.state.manual_ihi_verify_running || !(this.state.manual_ihi_check && this.state.manual_ihi_check.res == 'ok')} loading={this.state.assigning_ihi || this.state.manual_ihi_verify_running} onClick={async _=>this.verify_manual_ihi(this.state.patient)}>
                        <Icon name='refresh' /> Verify
                    </Button> */} {/* This verification is now done automatically */}
                    <Button color='red' onClick={this.dont_assign_patient_ihi.bind(this)} loading={this.state.assigning_ihi}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' onClick={async _ => { this.assign_patient_ihi(ihi_data, 'Manual') }} loading={this.state.assigning_ihi || this.state.manual_ihi_verify_running} disabled={this.state.manual_ihi_rec_status != 'Verified' || this.state.manual_ihi_valid != 'valid'}>
                        <Icon name='checkmark' /> Assign IHI
                    </Button>
                </Modal.Actions>

            </Modal>
        )

    }

    //	------------------------	------------------------	------------------------

    async verify_manual_ihi(patient) {

        if (this.state.manual_ihi_verify_running){

        } else {
            this.setState({manual_ihi_verify_running: true}, async _ => {
                
                const search_params = this.build_demo_info(patient, true)

                search_params.ihi = this.state.manual_ihi_ihi

                // Only include sex if M or F. This will probably need to be updated in future to handle M, F, U, I, N
                if (this.state.patient.PatientSex == 'M' || this.state.patient.PatientSex == 'F') search_params.sex = this.state.patient.PatientSex

                // Add other info for conformance
                search_params.meta = {}

                search_params.meta.uid = this.state.patient_uid

                const hi = new hi_model()

                let ihi_lookup_res = {}

                try {
                    ihi_lookup_res = await hi.ihi_lookup_manual({search_params, search_params_types: 'ihi'})
                } catch (e) {
                    ihi_lookup_res = { res: 'err', err: `Unexpected error: ${e}`, e }
                }

                // The IHI result has returned. Now we can check things...

                let returned_ihiRecordStatus = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_rs) //|| 'Unverified'
                let returned_ihiStatus = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_s) //|| 'Active'
                let returned_ihi = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi)
                let returned_ihiDemoConflict = j_f.object_value_key_path(ihi_lookup_res, obj_path_rec_conflict) && 'Yes' || 'No'
                let returned_sex = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_sex) //|| 'U'
                returned_ihi = this.ihi_from_uri(returned_ihi)

                console.log('returned_ihiDemoConflict')
                console.log(returned_ihiDemoConflict)

                this.setState({manual_ihi_valid: (returned_ihi && (returned_sex == this.state.patient.PatientSex) && 'valid' || 'invalid'), manual_ihi_sex: returned_sex, manual_ihi_status: returned_ihiStatus, manual_ihi_rec_status: returned_ihiRecordStatus, manual_ihi_democonflict: returned_ihiDemoConflict, manual_ihi_verify_running: false})

            })
        }

    }

    //	------------------------	------------------------	------------------------

    patient_summary() {

        const patient = (this.state.patient['PatientTitle'] && (this.state.patient['PatientTitle'] + ' ') || '') +
                        (this.state.patient['PatientFirstName'] && (this.state.patient['PatientFirstName'] + ' ') || '') +
                        (this.state.patient['PatientFamilyName'] && (this.state.patient['PatientFamilyName'] + ' ') || '') +
                        (this.state.patient['PatientSex'] && (this.state.patient['PatientSex'] == 'F' && '♀ ' || this.state.patient['PatientSex'] == 'M' && '♂ ' || this.state.patient['PatientSex'] == 'U' && '⚥? ' || '⚥ '  + ' ') || '⚥? ') +
                        (this.state.patient['PatientBirthdate'] && ('(' + this.state.patient['PatientBirthdate'] + '),') || '') + 
                        (this.state.patient['PatientAddress1'] && ( ' ' + this.state.patient['PatientAddress1']) || '') + 
                        (this.state.patient['PatientAddress2'] && ( ' ' + this.state.patient['PatientAddress2'] + ', ') || ', ') + 
                        (this.state.patient['PatientSuburb'] && ( this.state.patient['PatientSuburb'] + ' ') || '') + 
                        (this.state.patient['PatientPostcode'] && ( this.state.patient['PatientPostcode'] + ' ') || '') + 
                        (this.state.patient['PatientState'] && ( this.state.patient['PatientState'] + ' ') || '')
                        // + ` (ID: ${this.state.patient_uid})`

        return patient

    }

    //	------------------------	------------------------	------------------------

    post_patient_change() {

        // Any custom functions to check data should go in here
        this.props.onUpdate?.(this.state.patient)

    }

 
    //	------------------------	------------------------	------------------------

    async dont_assign_patient_ihi() {

        if (this.state.assigning_ihi) console.log('Already clicked set. Please wait...')
        else { this.setState({assigning_ihi: false, updating_ihi_status: false, manual_ihi: false, setting_ihi: false}) }

    }

    //	------------------------	------------------------	------------------------

    async manual_ihi() {

        if (this.state.manual_ihi) console.log('Already clicked save. Please wait...')
        else this.setState({manual_ihi: true, manual_ihi_ihi: this.state.patient['PatientIHI'], manual_ihi_check: this.check_ihi(this.state.patient['PatientIHI']), manual_ihi_status: 'Active', manual_ihi_rec_status: 'Verified', manual_ihi_sex: this.state.patient['PatientSex'], manual_ihi_democonflict: 'Unknown', manual_ihi_valid: 'unchecked'}, _=>this.verify_manual_ihi(this.state.patient))

    }

    //	------------------------	------------------------	------------------------

    async reset_patient_ihi() {

        if (this.state.assigning_ihi) console.log('Already clicked set. Please wait...')
        else {

            this.setState({assigning_ihi: true}, async _ => {

                // Call the API to save the IHI

                const hi = new hi_model()

                const demo_info = this.build_demo_info(this.state.patient, false)

                demo_info.ihi = this.state.patient['PatientIHI']

                const ihi_info = {}

                ihi_info.ihi = null
                ihi_info.ihiRecordStatus = null
                ihi_info.ihiStatus = null
                ihi_info.ihiDemoConflict = null

                const save_res = await hi.reset_ihi({ uid: this.state.patient_uid, ihi_info, demo_info }) //ihi_info isn't really need here, as it will be reset on the BE anyway

                if (save_res && save_res.res == 'ok'){
                    
                    const patient = this.state.patient
                    patient['PatientIHI'] = null

                    const patient_raw = this.state.patient_raw
                    patient_raw['PatientIHI'] = null

                    // Do some notification here
                    this.setState({assigning_ihi: false, ihi_lookup: null, setting_ihi: false, manual_ihi: false, updating_ihi_status: false, patient, patient_raw, patient_ihiRecordStatus: ihi_info.ihiRecordStatus, patient_ihiStatus: ihi_info.ihiStatus, patient_ihiDemoConflict: ihi_info.ihiDemoConflict, patient_ihi_demo: demo_info}, _=>{
                        this.post_patient_change()
                        // Skip this notification
                        //if (this.message_context && this.message_context.add_message) { app.functions.add_message({type: 'positive', header:'Assign IHI success', content: `Assigned IHI ${ihi_info.ihi} to user ID ${this.state.patient_uid}`}) } else console.log('Global messages not setup correctly')
                    })

                } else {

                    this.setState({assigning_ihi: false, ihi_lookup: null}, _=>{ 
                        const content = [save_res.err]
                        if (save_res.e_code == 'dup_ihi') content.push('Please determine which IHI is correct for each patient or contact InstantScripts for support.')
                        app.functions.add_message({type: 'negative', header:'Failed to assign IHI', content})
                        
                    })
                }


            })
            
        }

    }

    //	------------------------	------------------------	------------------------

    async assign_patient_ihi(ihi_info, assign_type = 'Unknown') {

        // console.log('ihi_info')
        // console.log(ihi_info)
        // return
        

        if (this.state.assigning_ihi) console.log('Already clicked set. Please wait...')
        else {
            //this.setState({setting_ihi: false, patient: {...this.state.patient, ['PatientIHI']: this.state.selected_ihi && this.state.selected_ihi.ihi || null}})}
            //ihi_lookup_res = await hi.ihi_lookup(search_params)
            //if (assign_res == 'ok') this.setState({}) asd asd asd as asdasd
            //else 

            this.setState({assigning_ihi: true}, async _ => {

                // Call the API to save the IHI

                const hi = new hi_model()

                const demo_info = this.build_demo_info(this.state.patient, false) || {}

                if (!demo_info.meta) demo_info.meta = {}
                if (!demo_info.meta.assign_type) demo_info.meta.assign_type = assign_type

                // console.log('ihi_info')
                // console.log(ihi_info)

                // return

                const save_res = await hi.save_ihi({ uid: this.state.patient_uid, ihi_info, demo_info })

                //console.log(save_res)

                if (save_res && save_res.res == 'ok'){
                    
                    const patient = this.state.patient
                    patient['PatientIHI'] = ihi_info.ihi

                    const patient_raw = this.state.patient_raw
                    patient_raw['PatientIHI'] = ihi_info.ihi

                    if (demo_info.sex){
                        patient['PatientSex'] = demo_info.sex
                        patient_raw['PatientSex'] = demo_info.sex
                    }

                    // Do some notification here

                    app.functions.add_message({type: 'positive', header:'Assign IHI success', content: `Assigned IHI ${ihi_info.ihi} to user ID ${this.state.patient_uid}`})

                    this.setState({assigning_ihi: false, updating_ihi_status: false, manual_ihi:false, setting_ihi: false, patient, patient_raw, patient_ihiRecordStatus: ihi_info.ihiRecordStatus, patient_ihiStatus: ihi_info.ihiStatus, patient_ihi_demo: demo_info, patient_ihiDemoConflict: ihi_info.ihiDemoConflict}, this.post_patient_change)

                } else {

                    this.setState({assigning_ihi: false}, _=>{ 
                        const content = [save_res.err]
                        if (save_res.e_code == 'dup_ihi') content.push('Please determine which IHI is correct for each patient or contact InstantScripts for support.')
                        app.functions.add_message({type: 'negative', header:'Failed to assign IHI', content})
                    })
                }


            })
            
        }

    }

    //	------------------------	------------------------	------------------------

    async handle_find_click() {

        if (this.state.finding_ihi) this.setState({finding_ihi: false, selected_ihi: null})
        else this.setState({finding_ihi: true, selected_ihi: null}, _=>this.run_ihi_lookup(this.state.patient, false))

    }

    //	------------------------	------------------------	------------------------

    handle_ihi_select(ihi, data) {

        if (this.state.setting_ihi) console.log('Already clicked set. Please wait...')
        else this.setState({setting_ihi: true, updating_ihi_status: false, selected_ihi: {ihi, ihiRecordStatus: data[0].ihiRecordStatus, ihiStatus: data[0].ihiStatus, ihiSex: data[0].sex, ihiDemoConflict: data[0].params && data[0].params.demo_conflict && 'Yes' || 'No', data}})

    }

    //	------------------------	------------------------	------------------------

    async run_ihi_lookup(patient, ihi_only = false) {

        if (this.state.running_lookup) console.log('Already running IHI search. Please wait...')
        else {

            this.setState({running_lookup: true, selected_ihi: null, ihi_lookup: null}, async _ => {

                const search_params = this.build_demo_info(patient, ihi_only)

                search_params.ihi = patient['PatientIHI']
        
                // Only include sex if M or F
                if (patient.PatientSex == 'M' || patient.PatientSex == 'F') search_params.sex = patient.PatientSex                          
        
                // Add other info for conformance
                search_params.meta = {}
                
                search_params.meta.uid = this.state.patient_uid                

                const hi = new hi_model()
        
                let ihi_lookup_res = {}

                try {
                    ihi_lookup_res = await hi.ihi_lookup(search_params)
                } catch (e) {
                    ihi_lookup_res = {res: 'err', err: `Unexpected error: ${e}`, e}
                }

                this.setState({ihi_lookup: ihi_lookup_res, running_lookup: false})

            })

        }

    }

    //	------------------------	------------------------	------------------------

    async run_ihi_verify() {

        if (this.state.running_lookup) console.log('Already running IHI search. Please wait...')
        else {

            if (typeof this.state.patient['PatientIHI'] == 'undefined' || this.state.patient['PatientIHI'] == null || this.state.patient['PatientIHI'] == '') {

                app.functions.add_message({ type: 'negative', header: 'No IHI to verify', content: [`The patient has no stored IHI to be verified.`, `Use the Patient IHI search (Services Australia) to find this patient's IHI.`]})

            } else {

                this.setState({ running_lookup: true, selected_ihi: null, ihi_lookup: null }, async _ => {

                    //console.log('running verify')

                    const search_params = this.build_demo_info(this.state.patient, true)

                    search_params.ihi = this.state.patient['PatientIHI']

                    // Only include sex if M or F. This will probably need to be updated in future to handle M, F, U, I, N
                    if (this.state.patient.PatientSex == 'M' || this.state.patient.PatientSex == 'F') search_params.sex = this.state.patient.PatientSex
                    //search_params.sex = this.state.patient.PatientSex

                    // Add other info for conformance
                    search_params.meta = {}

                    search_params.meta.uid = this.state.patient_uid

                    const hi = new hi_model()

                    let ihi_lookup_res = {}

                    try {
                        ihi_lookup_res = await hi.ihi_lookup(search_params)
                    } catch (e) {
                        ihi_lookup_res = { res: 'err', err: `Unexpected error: ${e}`, e }
                    }

                    this.setState({ ihi_lookup: ihi_lookup_res, running_lookup: false }, async _ => {

                        // The IHI result has returned. Now we can check things...

                        let returned_ihiRecordStatus = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_rs)
                        let returned_ihiStatus = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_s)
                        let returned_ihi = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi)
                        let returned_ihiDemoConflict = j_f.object_value_key_path(ihi_lookup_res, obj_path_rec_conflict) && 'Yes' || 'No'
                        let returned_sex = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_sex) || 'U'

                        returned_ihi = this.ihi_from_uri(returned_ihi)

                        // console.log('returned_ihiDemoConflict')
                        // console.log(returned_ihiDemoConflict)

                        // Check to see if there was no IHI associated

                        if ((typeof returned_ihi == 'undefined') || (returned_ihi == null) || (returned_ihi == '')) { // It should be '' if not found

                            // No IHI - conformance doesn't seem to say what to do here, so just throw a caution? Delete the IHI?
                            
                            // Lets delete the stored IHI

                            await this.reset_patient_ihi()

                            app.functions.add_message({ type: 'negative', header: 'IHI not found', content: [`Searching for this patient's IHI (${this.state.patient['PatientIHI']}) returned no results.`, `The stored patient's IHI has been removed.`,`Please use the Patient IHI search (Services Australia) to find this patient's IHI.`]})

                        }

                        // Check for provisional \ Unverified IHI (not supported) - conformance 008218, 008219
                        //else if (returned_ihiRecordStatus == 'Provisional' || returned_ihiRecordStatus == 'Unverified') { // Could just check != 'Verified'
                        else if (returned_ihiRecordStatus != 'Verified') {

                            // Don't support these

                            await this.reset_patient_ihi()

                            app.functions.add_message({ type: 'negative', header: 'IHI not verified', content: [`Searching for this patient's IHI (${this.state.patient['PatientIHI']}) returned a record status of ${returned_ihiRecordStatus}. Only Verified IHI's are supported.`, `The stored patient's IHI has been removed.`,`Please contact InstantScripts support or the HI Service Team through Medicare on 1300 361 457`]}) 

                        }

                        // Check to see if the IHI has be resolved (new IHI number) (016813)

                        else if ((typeof search_params.ihi != 'undefined') && (search_params.ihi != null) && (search_params.ihi != '') && returned_ihi && search_params.ihi != returned_ihi) {

                            if (this.state.running_lookup) console.log('Already running IHI search. Please wait...')
                            else {

                                this.setState({ running_lookup: true, selected_ihi: null, ihi_lookup: null }, async _ => {

                                    // console.log('IHI changed, re-doing verification to check it matches stored core details')

                                    app.functions.add_message({ type: 'warning', header: 'IHI verification returned an updated IHI', content: `Checking this updated IHI matches the patient's record...` })

                                    // As per 016813, this IHI should be valided with the patient's demo data, then stored if valid
                                    search_params.ihi = returned_ihi

                                    let ihi_lookup_res_2 = {}

                                    try {
                                        ihi_lookup_res_2 = await hi.ihi_lookup(search_params)
                                    } catch (e) {
                                        ihi_lookup_res_2 = { res: 'err', err: `Unexpected error: ${e}`, e }
                                    }

                                    this.setState({ ihi_lookup: ihi_lookup_res_2, running_lookup: false }, async _ => {

                                        let returned_ihi_2 = j_f.object_value_key_path(ihi_lookup_res_2, obj_path_ihi)
                                        returned_ihi_2 = this.ihi_from_uri(returned_ihi_2)

                                        // console.log('returned_ihi_2')
                                        // console.log(returned_ihi_2)

                                        // See if these match - if so, save it

                                        if (returned_ihi_2 == returned_ihi) {
                                            // Update to the new IHI as per conformance

                                            const ihi_res = this.build_ihi_list_from_search_result()
                                            const first_res = ihi_res[Object.keys(ihi_res)[0]]
                                            // console.log('first_res')
                                            // console.log(first_res)
                                            this.assign_patient_ihi({ ihi: returned_ihi_2, ihiRecordStatus: first_res[0].ihiRecordStatus, ihiStatus: first_res[0].ihiStatus, ihiSex: first_res[0].sex, ihiDemoConflict: first_res[0].params && first_res[0].params.demo_conflict && 'Yes' || 'No', data: first_res }, 'Verify')

                                            //{ihi, ihiRecordStatus: data[0].ihiRecordStatus, ihiStatus: data[0].ihiStatus, ihiSex: data[0].sex, data}

                                        } else {

                                            //remove the old IHI?
                                            app.functions.add_message({ type: 'negative', header: `Updated IHI does not match the stored patient's information`, content: [`The patient's stored IHI has a newer updaed IHI (${returned_ihi}), however this IHI cannot be saved as it is not associated with the stored patient's demographic information.`, `Please contact InstantScripts support or the HI Service Team through Medicare on 1300 361 457`] }) 

                                        }

                                    })

                                })

                            }

                        }

                        else if ((typeof search_params.ihi != 'undefined') && (search_params.ihi != null) && (search_params.ihi != '') && returned_ihi && (search_params.ihi == returned_ihi)) {                           

                            // Check for different sex
                            if (returned_sex != this.state.patient['PatientSex']){

                                // Sex is different - reset the IHI
                                await this.reset_patient_ihi()

                                app.functions.add_message({ type: 'negative', header: `IHI does not match patient's sex`, content: [`This IHI (${returned_ihi}) has patient sex ${returned_sex} but the patient's record sex is ${this.state.patient['PatientSex']}`, `The stored patient's IHI has been removed.`,`Please use the Patient IHI search (Services Australia) to find this patient's IHI.`]}) 

                            }

                            // Check for status updates
                            else if ((returned_ihiRecordStatus != this.state.patient_ihiRecordStatus) || (returned_ihiStatus != this.state.patient_ihiStatus) || (returned_ihiDemoConflict != this.state.patient_ihiDemoConflict)){

                                console.log('Status change')

                                const ihi_res = this.build_ihi_list_from_search_result()
                                const first_res = ihi_res[Object.keys(ihi_res)[0]]

                                const ihi_data = {
                                    ihi: returned_ihi,
                                    ihiRecordStatus: first_res[0].ihiRecordStatus,
                                    ihiStatus: first_res[0].ihiStatus,
                                    ihiSex: first_res[0].sex,
                                    ihiDemoConflict: first_res[0].params && first_res[0].params.demo_conflict && 'Yes' || 'No',
                                    data: first_res,
                                }

                                const previous_ihi_msg = `Previous: IHI: ${this.state.patient['PatientIHI']}, Status: ${this.state.patient_ihiRecordStatus}, Record status: ${this.state.patient_ihiStatus}, Record conflict: ${this.state.patient_ihiDemoConflict}`
                                const updated_ihi_msg = `Updated: IHI: ${returned_ihi}, Status: ${returned_ihiRecordStatus}, Record status: ${returned_ihiStatus}, Record conflict: ${returned_ihiDemoConflict}`

                                // Save these updates - confromance requires user to confirm if going from record status verified to unverified
                                // Changed - not handling unverified\provisional ihi
                                // if ((returned_ihiRecordStatus == 'Unverified') && (this.state.patient_ihiRecordStatus == 'Verified')) {
                                if (false){
                                    // User must confirm this change - conformance 016814
                                    this.setState({ updating_ihi_status: true, selected_ihi: ihi_data})
                                }
                                // Special 017421 - Deceased -> Active: Alert and manual change required. Note that Retired will be handled in the following else statement (016815)
                                else if (this.state.patient_ihiStatus == 'Deceased' && (returned_ihiStatus == 'Active' || returned_ihiStatus == 'Expired')) { // Expired shouldn't happen according to docs

                                    app.functions.add_message({ type: 'negative', header: `IHI status changed from Deceased to ${returned_ihiStatus} - this change must be saved manually`, content: [`The patient's IHI details have changed`, previous_ihi_msg, updated_ihi_msg, `This change must be confirmed using a manual Patient IHI search`] }) 

                                }

                                // Save - conformance 016815
                                else {

                                    this.assign_patient_ihi(ihi_data, 'Verify')

                                    app.functions.add_message({ type: 'positive', header: 'IHI status updated', content: [`The patient's IHI details have been updated`, previous_ihi_msg, updated_ihi_msg] }) 

                                }

                            }

                            else {

                                app.functions.add_message({ type: 'positive', header: 'IHI verified', content: `The patient's IHI details are up to date.` }) 

                            }

                        }

                        else {
                            // In future this will probably be silent
                            app.functions.add_message({ type: 'negative', header: 'Unknown verify result', content: [`There was an unknown error while verifying the patient's IHI.`, `Please contact InstantScripts`] }) 
                        }

                    })

                })

            }

        }

    }

    //	------------------------	------------------------	------------------------

    load_patient_details(data, during_init = false) {
        console.log("load_patient_details",data);
        if (data){

            const new_patient = {}

            // Load the data for each patient prop
            patient_props.forEach(prop => { if (typeof data[prop.name] != 'undefined') new_patient[prop.name] = data[prop.name] })

            // Note - this function is called during init so no setstate availible
            if (during_init) this.state = {...this.state,
                patient_uid: data['uid'],
                patient_ihiRecordStatus: data['ihiRecordStatus'],
                patient_ihiStatus: data['ihiStatus'],
                patient_ihiDemoConflict: data['ihi_DemoConflict'],
                patient_ihi_history: data['ihi_history'] && data['ihi_history'].reverse && data['ihi_history'].reverse(),
                patient_ihi_demo: data['ihi_demo'],
                patient: JSON.parse(JSON.stringify(new_patient)), patient_raw: JSON.parse(JSON.stringify(new_patient))}
            else this.setState({patient_uid: data['uid'], patient: JSON.parse(JSON.stringify(new_patient)), patient_raw: JSON.parse(JSON.stringify(new_patient))}, this.post_patient_change)

        }
        
    }



    //	------------------------	------------------------	------------------------

    build_demo_info(patient, ihi_based_search_only = false) {

        let info = {}
        let address = {}

        if (patient){
            if (typeof patient.PatientBirthdate == 'string')        info.dob = patient.PatientBirthdate.toUpperCase()
            if (typeof patient.PatientFamilyName == 'string')       info.family_name = patient.PatientFamilyName.toUpperCase()
            if (typeof patient.PatientFirstName == 'string')        info.given_name = patient.PatientFirstName.toUpperCase()
            if (typeof patient.PatientSex == 'string')              info.sex = patient.PatientSex.toUpperCase()

            if (!ihi_based_search_only){ // Needed for verify IHI (016813)

                if (typeof patient.PatientMedicareNumber == 'string')   info.medicare = patient.PatientMedicareNumber.toUpperCase()

                if (typeof patient.PatientAddress1 == 'string')         address.line1 = patient.PatientAddress1.toUpperCase()
                if (typeof patient.PatientAddress2== 'string' )         address.line2 = patient.PatientAddress2.toUpperCase()
                if (typeof patient.PatientSuburb == 'string')           address.suburb = patient.PatientSuburb.toUpperCase()
                if (typeof patient.PatientState == 'string')            address.state = patient.PatientState.toUpperCase()
                if (typeof patient.PatientPostcode == 'string')         address.postcode = patient.PatientPostcode.toUpperCase()

                if (typeof patient.DVANumber == 'string')               info.dva_number = patient.DVANumber.toUpperCase()
                
                if (Object.keys(address).length > 0) info.address = address

            }

        }

        return j_f.clone_object_raw(info)
        
    }

    //	------------------------	------------------------	------------------------

    build_core_demo_info() {

        // As per 006105
        // We want the data even if blank, I think

        let info = {}
        //let address = {}

        let patient = this.state.patient
        if (!patient) patient = {}

        info.dob = typeof patient.PatientBirthdate == 'string' && patient.PatientBirthdate.toUpperCase()

        info.family_name = typeof patient.PatientFamilyName == 'string' && patient.PatientFamilyName.toUpperCase()

        info.sex = typeof patient.PatientSex == 'string' && patient.PatientSex.toUpperCase()

        return j_f.clone_object_raw(info)
        
    }

    //	------------------------	------------------------	------------------------

    // Checks the IHI is properly formatted as per conformance (005819)
    check_ihi(value) {

        const ihi_len = 16

        if (typeof value != 'string' || value == '')    return {res: 'err', err: `No IHI provided`}// Provided: ${typeof value}, value: ${JSON.stringify(value)}`}
        if (value.length != ihi_len)                    return {res: 'err', err: `IHI must be ${ihi_len} digits long. Provided IHI length: ${value.length}`}//, value: ${value}`}
        //Luhn check algorithm
        value = value.replace(/\D/g, "")

        let nCheck = 0, bEven = false
    	for (let n = value.length - 1; n >= 0; n--) {
            let nDigit = parseInt(value[n], 10);
    		if (bEven && ((nDigit *= 2) > 9)) nDigit -= 9
    		nCheck += nDigit
            bEven = !bEven
    	}
        const valid = (nCheck % 10) == 0
        if (!valid)                                     return {res: 'err', err: `IHI fails validation check. Please check each digit.`}
        return {res: 'ok'}

    }

    
}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default PatientSelector