import React, { useState, useEffect, useRef } from "react";

import { List, Header, Button, Message, Input, Icon, Popup } from 'semantic-ui-react'

import moment from "moment-timezone";

import { color_levels, Duration } from "xAppLib/UIelems/Duration";

const COLOR_LEVELS = color_levels([20*60, 40*60, 60*60, 2*60*60]);

const DocCons_stats = props => {

	// const [pcid, setPcid] = useState(props?.match?.params?.pcid || props?.pcid);
	// const [pcsd, setPcsd] = useState();

	const {stats} = props

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	return stats && <>
				<Popup 
						trigger={<Icon name='zoom'  />}
						content={<pre>{JSON.stringify(stats, null, 4)}</pre>}
						wide='very'
						on={['click']}
					/>
				<b>Consult Stats</b>&nbsp;
				<Icon name={stats.valid?'check':'minus'} color={stats.valid?'green':'red'} />
				{stats.doc_reserve && (stats.last_take_wait*1-stats.first_take_wait*1>5) && <Icon name='grab' color='red' />}
				{stats.man_rev_req && <Icon name='warning sign' color='red' />}
				
				<ul>
					{stats.num_of_takes>1  && <li>Takes: {stats.num_of_takes}</li>}

					<li>First wait: &nbsp;
						<Duration 
									icon = 'stopwatch'
									dur = {stats.first_take_wait}
									iconColor = {COLOR_LEVELS}
									compact = {true}
							/>
					</li>

					{(stats.num_of_takes>1 || stats.doc_reserve && (stats.last_take_wait*1-stats.first_take_wait*1>5)) && <li>Last wait: &nbsp;
						<Duration 
									icon 
									dur = {stats.last_take_wait}
									iconColor = {COLOR_LEVELS}
									compact = {true}
							/>
					</li>}

					<li>Cons dur: &nbsp;
						<Duration 
									icon = 'doctor'
									dur = {stats.last_take_dur}
									compact = {true}
							/>
					</li>

					{app.user?.claims?.admin && stats.done_focus_tm && <li>Focus %: &nbsp;
						{Math.round(stats.done_focus_tm / Math.max(stats.done_focus_tm, stats.last_take_dur) * 100)}%
					</li>}

					{stats.take_arr?.[stats.take_arr.length-1]?.cons_ind>1 &&
						<li>Last ind: &nbsp; {stats.take_arr?.[stats.take_arr.length-1]?.cons_ind}
						</li> }
				</ul>
			</>

};

export default DocCons_stats;

