import React, {Component} from 'react'

export default class SimpleShowMore extends Component {

	state = { more_open: false }

	render () {

		const { content, cut_length=130, more_txt='>>', less_txt='<<' } = this.props
		const { more_open } = this.state

		return content && <React.Fragment>

				{ content.toString().length<=cut_length && content }

				{ content.toString().length>cut_length && !more_open && 
					<React.Fragment>
						{content.substring(0, cut_length)} 
						... &nbsp;
						<a onClick={ ()=>this.setState({more_open: true}) }> {more_txt}</a> 
					</React.Fragment>
				}

				{ content.toString().length>cut_length && more_open && 
					<React.Fragment>
						{content}
						&nbsp;
						<a onClick={ ()=>this.setState({more_open: false}) }> {less_txt}</a> 
					</React.Fragment>
				}

			</React.Fragment> 
			|| null

	}
}
