import API_service from 'xAppLib/providers/API_service'

const MODEL_NAME = 'hi_model'

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class hi_model {

    async ihi_lookup(params) { return await API_service.load_data('ihi/lookup', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async ihi_lookup_manual(params) { return await API_service.load_data('ihi/lookup_manual', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async save_ihi(params) { return await API_service.load_data('ihi/save', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async reset_ihi(params) { return await API_service.load_data('ihi/reset', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async load_ihi(params) { return await API_service.load_data('ihi/load', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async ihi_conformance(params) { return await API_service.load_data('ihi/conformance', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async auto_ihi(params) { return await API_service.load_data('ihi/auto_assign', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async verify_ihi(params) { return await API_service.load_data('ihi/verify', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async batch_auto_assign_ihi(params) { return await API_service.load_data('ihi/batch_auto_assign', params) }

    // ----	--------------------------------------------	--------------------------------------------

    async batch_verify_ihi(params) { return await API_service.load_data('ihi/batch_verify', params) }

}
