import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react'

const IcosmHeader = ({title, img, desc, link, link_text, cls, sub}) => {
    return  <div className="mark-hero hero flex flex-col justify-center h-full w-full bg-no-repeat bg-cover bg-center">  
                <div className="md:hidden h-[312px] bg-cover bg-no-repeat bg-[80%_center]" style={{ backgroundImage: `url("https://storage.googleapis.com/icosm/2/IC-hero-home.jpg")`}} /> 
                <Container>
                    <h1 className=" w-full md:w-1/2">{title}</h1>
                    {sub && <p className='sub'>{sub}</p>}
                    <p className="md:w-[540px] text-icosm_md md:text-icosm_base">{desc}</p>
                    {link && <Button 
                                as={Link}
                                size="large" 
                                type='button'
                                className={`cta ${cls} icon`}
                                to={link} 
                                >{link_text}</Button>
                    }
                </Container>
            </div>
}

export default IcosmHeader;


