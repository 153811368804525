import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, Input, Button, Card, Radio } from 'semantic-ui-react';
import useOnSettingsButtonClick from '../../utlis/hooks/useOnSettingsButtonClick';
import ImagePicker from 'xAppLib/UIelems/ImagePicker';
import { useToggleState } from 'xAppLib/Hooks';
import SIcon from '../../../NUI/StreamlineIcons/SIcon';

const DEMO = {
	cards: [
		{
			desktopSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/woman-kitchen-2-05cb37b2.jpeg',
			tabletSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/woman-kitchen-2-c471ef45.jpeg',
			mobileSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/woman-kitchen-2-c471ef45.jpeg',
			header: 'Regulate your appetite',
			desc: 'Learn how to control your eating desires, create healthy eating habits and feel in control of your diet.',
			link: '/',
		},
		{
			desktopSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/consulting-with-psychologist-6a9ec2a1.jpeg',
			tabletSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/consulting-with-psychologist-88e277fd.jpeg',
			mobileSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/consulting-with-psychologist-04f6dd12.jpeg',
			header: 'Overcome hunger pains',
			desc: 'Maintain a healthy diet and avoid unhealthy food and sugar cravings that make weight loss so exhausting.',
			link: '/',
		},
		{
			desktopSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/man-holding-weights-9749b6d3.jpeg',
			tabletSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/man-holding-weights-41b75f71.jpeg',
			mobileSrc: 'https://www.instantscripts.com.au/wp-content/themes/yootheme/cache/man-holding-weights-e7484063.jpeg',
			header: 'Achieve your weight loss goals',
			desc: 'Our team of doctors will help you achieve your weight loss goals and feel healthier throughout our program.',
			link: '/',
		}
	],
	isIconSize: false,
	hasSeparator: true
};

const ICON_SIZE = 48;

const CardImage = ({ desktopSrc, tabletSrc, mobileSrc, isIconSize }) => (
	<>
		<img className="object-cover hidden md:block max-h-[260px]" width={isIconSize ? ICON_SIZE : undefined} style={isIconSize ? { marginLeft: 24, marginTop: 24 } : undefined} src={desktopSrc} />
		<img className="object-cover hidden sm:block md:hidden" width={isIconSize && ICON_SIZE} style={isIconSize ? { marginLeft: 24, marginTop: 24 } : undefined} src={tabletSrc} />
		<img className="object-cover block sm:hidden" width={isIconSize && ICON_SIZE} style={isIconSize ? { marginLeft: 24, marginTop: 24 } : undefined} src={mobileSrc} />
	</>
);

const EditorCards = ({ data, onDataChange, readOnly, settingsWrapper, viewerOnly }) => {
    const Link = viewerOnly ? RouterLink : Container;
	const [toggleSettings, setToggleSettings] = useState(true);
	const [state, setState] = useState(data);
	const [isModelOpen, openModel, closeModel] = useToggleState();
	const imagePickerRef = useRef();

	const { cards = [], isIconSize = false, hasSeparator = true } = state;

	useEffect(() => {
		if (!readOnly) {
			setState((prev) => ({ ...DEMO, ...prev }));
		}
	}, []);

	const handleCardsChange = (name, index) => {
		return (_, { value }) => {
			setState((prev) => {
				const updatedCards = [...prev.cards];
				updatedCards[index][name] = value;
				const updatedState = { ...prev, cards: updatedCards };
				onDataChange(updatedState);
				return updatedState;
			});
		};
	};

	const handleRadioStateChange = (name) => {
		return (_, { checked }) => {
			setState((prev) => {
				const updatedState = { ...prev, [name]: checked };
				onDataChange(updatedState);
				return updatedState;
			});
		};
	};

	!viewerOnly && useOnSettingsButtonClick(settingsWrapper, 'settings', () => setToggleSettings((prev) => !prev));

	const handleClear = () => {
		if (confirm('Are you sure?')) {
			const emptyCard = (card) => Object.keys(card).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
			const emptyCards = DEMO.cards.map(emptyCard);
			setState({ cards: emptyCards });
			onDataChange({});
		}
	};

	const handleOnImageClick = (_, imgUrl) => {
		const { index, image } = imagePickerRef.current;
		cards[index][image] = imgUrl;
		setState((prev) => ({ ...prev, cards }));
		handleCloseModal();
	};

	const handleCloseModal = () => {
		closeModel();
		imagePickerRef.current = undefined;
	};

	const handleOpenModal = (image, index) => {
		return () => {
			openModel();
			imagePickerRef.current = { image, index };
		};
	};

	const cardsLength = state?.cards?.filter(c => c.link).length;

	return (
		<>
			<Container className="landing-page-cards flex justify-center mx-auto py-10">
				<h2 className="text-center mb-10 mt-0">
					Topics you may be interested in
				</h2>
				<Card.Group stackable centered itemsPerRow={cardsLength}>
					{cards?.map((card, index) => card.link && (
						<Card
							key={index}
							className={`!max-w-[550px] ${!hasSeparator && 'no-separetor'}`}
							image={<CardImage {...card} isIconSize={isIconSize} />}
							header={
								<h3>
									<strong>{card.header}</strong>
								</h3>
							}
							description={card.desc}
							extra={card.link && (
								<Link
									href={card.link}
									target="_blank"
									className="flex !text-is-blue font-bold underline"
								>
									Continue reading <SIcon name="external" size="xxs" bold cls="ml-2"/>
								</Link>
							) || undefined}
						/>
					))}
				</Card.Group>
			</Container>

			{!readOnly && toggleSettings && (
				<div className="m-8">
					<Container>
						<Grid stackable>
							<Grid.Row>
								<Grid.Column>
									<Button primary onClick={handleClear} content="Clear" />
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Grid stackable>
							<Grid.Row>
								<Grid.Column width={3}>
									<Radio toggle onChange={handleRadioStateChange('isIconSize')} checked={Boolean(isIconSize)} label="Render icon" />
								</Grid.Column>
								<Grid.Column width={3}>
									<Radio toggle onChange={handleRadioStateChange('hasSeparator')} checked={Boolean(hasSeparator)} label="Use a separator" className="whitespace-nowrap" />
								</Grid.Column>
							</Grid.Row>
						</Grid>
						{cards?.map((card, index) => (
							<Grid key={index} stackable>
								<Grid.Row>
									<Grid.Column width={16}>
										<center className="my-2">
											<h2>Card {index + 1}</h2>
										</center>
									</Grid.Column>
									<Grid.Column width={5}>
										<h4>Desktop URL</h4>
										<Input
											onChange={handleCardsChange('desktopSrc', index)}
											type="text"
											placeholder="Desktop URL"
											value={card['desktopSrc']}
											fluid
											action={{
												color: 'primary',
												icon: 'images',
												onClick: handleOpenModal('desktopSrc', index)
											}}
										/>
									</Grid.Column>
									<Grid.Column width={6}>
										<h4>Tablet URL</h4>
										<Input
											onChange={handleCardsChange('tabletSrc', index)}
											type="text"
											placeholder="Tablet URL"
											value={card['tabletSrc']}
											fluid
											action={{
												color: 'primary',
												icon: 'images',
												onClick: handleOpenModal('tabletSrc', index)
											}}
										/>
									</Grid.Column>
									<Grid.Column width={5}>
										<h4>Mobile URL</h4>
										<Input
											onChange={handleCardsChange('mobileSrc', index)}
											type="text"
											placeholder="Mobile URL"
											value={card['mobileSrc']}
											fluid
											action={{
												color: 'primary',
												icon: 'images',
												onClick: handleOpenModal('mobileSrc', index)
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<h4>Header</h4>
										<Input onChange={handleCardsChange('header', index)} type="text" placeholder="Enter header" value={card['header']} fluid />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<h4>Description</h4>
										<Input onChange={handleCardsChange('desc', index)} type="text" placeholder="Enter description" value={card['desc']} fluid />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<h4>Article Link (leave link blank to hide card)</h4>
										<Input onChange={handleCardsChange('link', index)} type="text" placeholder="Enter article link" value={card['link']} fluid />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						))}
					</Container>
					<ImagePicker open={isModelOpen} onClose={handleCloseModal} onImageClick={handleOnImageClick} bucket="is-media" />
				</div>
			)}
		</>
	);
};

export default EditorCards;
