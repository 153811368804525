
const _MEDICARE_REASON_CODES = {
	101:'More details of service required to assess benefit',
	102:'No amount charged is shown on account/receipt',
	103:'Letter of explanation is being sent separately',
	104:'Balance of benefit due to claimant',
	105:'Benefit paid to provider as requested',
	106:'Servicing provider unable to be identified',
	107:'Benefit paid on item number other than that claimed',
	108:'Benefit is not payable for the service claimed',
	111:'No benefit payable - claims/s over 2 years old',
	113:'Total charge shown on account apportioned over all items',
	115:'Benefit recommended for this item',
	117:'Benefit not recommended for this item',
	120:'Age restriction applies to this item',
	122:'Associated referral/request line not required',
	123:'Benefit paid on radiology item other than service claimed',
	124:'Item is restricted to persons of opposite sex to patient',
	125:'Not payable without associated operation/anaesthetic item',
	126:'Service is not payable without radiology service',
	127:'Maximum number of additional fields already paid  s',
	128:'Benefit paid on associated fracture/amputation item',
	129:'Service is not payable without the base item/s',
	130:'Letter of explanation is being sent separately',
	131:'Date of service not supplied/invalid',
	134:'Single course of treatment paid as subsequent attendance',
	135:'Provider not a consultant physician - specialist rate paid',
	136:'Referral details not supplied- paid at g.p. rate',
	137:'Details of requesting provider not shown on account/receipt',
	138:'Benefit only payable when self-determined/deemed necessary',
	139:'Approved pathologist should not use this item number',
	140:'Non-specialist provider',
	141:'No benefit payable for services performed by this provider',
	142:'Letter of explanation is being sent separately',
	144:'Claim benefit not paid - further assessment required',
	150:'Member has not supplied details to permit claim payment',
	151:'Associated service already paid-adjustment being processed',
	154:'Diagnostic imaging multiple service rule applied to service',
	155:'Letter of explanation is being sent separately',
	157:'Service possibly aftercare - refer to provider',
	158:'Benefit paid on associated abandoned surgery/anae item',
	159:'Item associated with other service on which benefit payable',
	160:'Maximum number of services for this item already paid',
	161:'Adjustment to benefit previously paid',
	162:'Benefit has been previously paid for this service',
	163:'Surgical/anaesthetic item/s already paid for this date',
	164:'Assistant surgeon benefit not payable',
	166:'Letter of explanation is being sent separately',
	168:'Not payable without associated operation/anaesthetic item',
	169:'Operation/anaesthetic item not claimed',
	170:'Assistant anaesthetic benefit not payable',
	171:'Benefit not payable - provider may only act in one capacity',
	173:'Patient episode coning - maximum number of services paid',
	174:'Patient episode coning adjustment',
	175:'Benefit paid on associated foetal intervention item',
	176:'Pay each foetal intervention item as a separate item',
	177:'Foetal intervention item paid using derived fee item',
	179:'Benefit not payable - associated service already paid',
	184:'Benefit paid for additional time item using a derived fee',
	194:'Letter of explanation is being sent separately',
	195:'Letter of explanation is being sent separately',
	206:'Item number does not attract a benefit at date of service',
	208:'Cardnumber used has expired',
	209:'Claimants name stated is different to that on cardnumber',
	211:'Patient not covered by this cardnumber at date of service',
	212:'Date of service used is in the future',
	214:'Claim form not complete',
	215:'Service claimed prior 1 february 1984',
	217:'Patient cannot be identified from information supplied',
	222:'Benefit paid on associated anaesthetic item',
	223:'Service not payable - specified item not claimed or present',
	225:'Patient contribution substantiated-additional benefit paid',
	226:'Date of service is prior to patients date of birth',
	227:'Date of service prior to date eligible for medicare benefit',
	228:'Date of service after benefit period for overseas visitor',
	229:'Benefit paid at 100% of schedule fee',
	230:'Combination of 85% and 100% of schedule fee paid',
	232:'Service claimed not covered by medicare',
	233:'Provider not entitled to medicare benefit at date of service',
	234:'Letter of explanation is being sent separately',
	236:'Letter of explanation is being sent separately',
	237:'Letter of explanation is being sent separately',
	238:'Not paid because all associated services rejected',
	240:'Gap adjustment to benefit previously paid',
	241:'Total charge and benefit for multiple procedure',
	242:'Service is part of a multiple procedure',
	243:'Apportioned charge and total benefit for multiple procedure',
	244:'Benefit not paid - service line in error',
	245:'Benefit paid on service other than that claimed',
	246:'Patient cannot be identified from information supplied',
	250:'Explanation/voucher will be forwarded separately',
	251:'Details of requesting provider not supplied',
	252:'Service possibly aftercare',
	253:'Radiotherapy assessed with other item number on statement',
	254:'Assessment incomplete - further advice will follow',
	255:'Benefit assigned has been increased',
	256:'Benefit not payable on this service for a hospital patient',
	260:'Benefit assessed with associated item on statement',
	261:'Associated surgical items/anaesthetic time not supplied',
	262:'Insufficient prolonged anaesthetic time - service not paid',
	264:'Benefit not payable - compensation/damages service',
	265:'Service not covered by reciprocal health care agreement',
	267:'Service not payable - associated service not present',
	271:'Not payable without associated ophthalmological item',
	272:'Benefit paid on associated ophthalmological item',
	274:'Provisional payment',
	280:'Cannot identify service. resubmit with correct mbs item',
	282:'Date of service outside of referral/request period',
	306:'Card# not valid at date of service-future claims may reject',
	307:'Claim not paid - cardnumber not valid for date of service',
	308:'Ivf service - conditions not met - no benefit payable',
	316:'Benefit not payable - item cannot be self-determined',
	317:'Benefit not payable - additional item to those requested',
	320:'Quoted medicare cardnumber is incorrect',
	322:'Provider not approved for this medicare pathology benefit',
	325:'Laboratory not accredited for benefits for this service',
	326:'Laboratory not accredited for benefits at date of service',
	328:'Benefit paid on associated tomography item',
	329:'Not payable without associated tomography item',
	331:'Benefit not payable - h.i. act sect 20(a)(1)',
	332:'Category 5 lab - benefit not payable for requested service',
	333:'Provider must claim time-based items',
	334:'Benefit not payable-associated pathology must be inpatient',
	335:'Service is not payable without nuclear medicine service',
	336:'Benefit paid on nuclear medicine item other than one claimed',
	337:'Provider must claim content-based items',
	338:'Provider not registered to claim benefit at date of service',
	339:'Benefit paid at the concession rate',
	340:'Refund of co-payment amount',
	341:'No referral details - details required for future claims',
	342:'Referral expired - paid at unreferred (doctor) rate',
	343:'Cardnumber quoted on claim form has been cancelled',
	344:'Concession number invalid - benefit paid at general rate',
	345:'No safty net entitlement - benefit paid at general rate',
	346:'Co-payment not made - $2.50 credited to threshold',
	347:'Safety net threshold reached - benefit increased',
	348:'Overpayment of claim - invalid concession number',
	349:'Replacement for requested eft payment rejected by bank',
	350:'Hospital referral - paid at specialist/consultant rate',
	351:'Benefit not payable - lcc number incorrect or not supplied',
	352:'Service date outside lcc registration dates',
	353:'Pathology items not present - no benefit payable',
	356:'Documentation required to process service',
	358:'Documentation not received - unable to process service',
	359:'Documentation not received - unable to process claim',
	360:'No benefit payable when requested by this provider',
	361:'Di exemption/items not approved',
	364:'Items claimed must be as a combination item',
	367:'Service associated with mbac item in a multiple procedure',
	370:'Benefit paid on item number other than that claimed',
	371:'Future claims quoting old style card no. will be rejected',
	372:'Old style card number quoted - benefit not payable',
	373:'Expired card - benefit not payable',
	374:'Old card issue used - benefit not payable - also refer @',
	375:'Service being processed manually',
	377:'Number of patients seen not indicated',
	378:'Provider cannot refer/request service at date of request',
	390:'Documentation not received',
	391:'Service provider on db1 differs from transmitted data',
	392:'Benefit amount changed',
	393:'No benefit payable - baby not an admitted inpatient',
	395:'Tac medical excess',
	400:'Equipment number missing or invalid',
	401:'Benefit not payable - charge amount missing or invalid',
	402:'Benefit not payable- number of patients attended required',
	403:'Subsequent consultation - referral details required',
	404:'Benefit not payable - referral/request details required',
	405:'Equipment number invalid for servicing provider',
	406:'Supporting text required to assess claim',
	407:'Benefit not payable - overseas student',
	408:'Date of service prior to 29 may 1995',
	409:'Cardnumber for this enrolment needs to be verified',
	410:'Age restriction applies for this item - verify details',
	411:'Mbac determination/precedent number not supplied or invalid',
	412:'Benefit not payable - provider unable to claim this service',
	413:'Benefit not payable - date of serv prior to date of request',
	414:'Provider practice location is closed at date of service',
	415:'Referral details same as rendering provider - self-deemed?',
	416:'Services form a composite item - composite item required',
	417:'Referral needed - if no referral, nr item to be transmitted',
	418:'Item cannot be claimed more than once in one attendance',
	419:'Benefit already paid on item - verify if multiple pregnancy',
	420:'Operation/s schedule fee does not meet item description',
	421:'Wrong assistant item used for the operation/s performed',
	422:'Benefit paid has been reduced (benefit = charge)',
	423:'Optical condition not specified - no benefit payable',
	424:'More information required - which eye was treated',
	425:'Benefit not payable - individual charges required',
	426:'Indicate whether new treatment or continuing management',
	427:'Compensation related services - please forward documents',
	428:'Date of service over 2 years - late lodgement form required',
	429:'Patient cannot be identified from the information supplied',
	430:'Conflicting referral details - please clarify',
	431:'Initial consultation previously paid - query subsequent con',
	432:'Not multi-op - more information required to pay benefit',
	433:'Associated referral/request line not required',
	434:'Expired or invalid card.  benefit not payable',
	435:'Service for nursing home care recipient - benefit not paid',
	436:'Cannot claim out of hospital service through simplified bill',
	437:'Card details invalid. a new medicare number has been issued',
	449:'Held eft payment reprocessed - incorrect claimant selected',
	450:'Eft details invalid - cheque issued for benefit',
	451:'Service provided in an ineligible location',
	452:'Resubmit claim for this service - image not claim related',
	453:'Resubmit claim for service-claim details do not match image',
	454:'Resubmit claim for service - some details not shown on image',
	455:'Resubmit claim for this service-include account and receipt',
	456:'No action required - line adjusted to process claim',
	457:'No action required - line adjusted to process claim',
	458:'No action required - benefit paid on adjusted claim',
	461:'Adjustment to benefit previously paid',
	475:'Patient/service details invalid or missing',
	500:'Rejected in association with another item in this claim',
	501:'Group attendance or item format invalid',
	502:'Patient is not eligible to claim benefit for this item',
	503:'Referral date format is invalid',
	504:'Charge amount missing/invalid - no benefit payable',
	505:'More information required. evidence of condition',
	506:'Consultation not payable on same day as surgical procedure',
	507:'Site not accredited for this service',
	509:'Service paid as item 2712/2719',
	510:'Service paid as item 52-96/or similar item',
	511:'Emsn threshold reached - cap applied to benefit',
	512:'Multiple musculoskeletal mri service rule applied',
	513:'Multiple musculoskeletal mri and di services rules applied',
	514:'Required equipment type code not on lspn register',
	515:'Equipment is older than allowable age for this item',
	516:'Ben paid for base and derived radiotherapy items claimed',
	517:'Mpsn threshold reached - 80% out of pocket paid',
	518:'Benefit paid at 100% schedule fee + emsn',
	519:'Mpsn threshold reached - partial 80% out of pocket paid',
	520:'Benefit paid at 100% schedule fee + part 80% out of pocket',
	521:'Paid part 80% out of pocket + between 85% and 100% increase',
	522:'Benefit paid - emsn + between 85% and 100% schedule fee',
	524:'Safety net benefit adjusted',
	525:'Only attracts benefit when claimed via bulk billing',
	528:'Provider not in eligible area (incorrect rrma,ssd or state)',
	529:'Bulk bill additional item claimed incorrectly',
	530:'Patient not on concession/under 16 years at date of service',
	535:'Missing data',
	536:'Location specific practice number not supplied',
	537:'Location specific practice number invalid',
	538:'Location specific practice number not recognised',
	539:'Location specific practice number not valid at date of serv',
	540:'Enhanced primary care plan item not previously claimed',
	549:'Bulk bill incentive item already paid - adjustment required',
	550:'Associated service not claimed - no benefit payable',
	551:'Specimen collection point is incorrect or not supplied',
	552:'Specimen collection point not valid at date of service',
	553:'Approved collection centre number not supplied',
	554:'Total benefit for anaesthetic service',
	555:'Benefit paid on main rvg anaesthetic item',
	556:'Rvg time item not claimed',
	557:'Associated rvg anaesthetic service not claimed',
	558:'Rvg anaesthetic item not claimed',
	559:'Patient outside age range - please verify age',
	560:'Rvg item restriction',
	561:'Benefit paid on rvg item claimed',
	562:'Benefit paid on associated rvg anaesthetic item',
	563:'Associated rvg service already paid',
	564:'Multiple vascular ultrasound services site rule applied',
	565:'Multiple di and vascular ultrasound service rules applied',
	566:'Total benefit for diagnostic imaging service',
	567:'Benefit paid on main diagnostic imaging item',
	568:'Item cannot be substituted',
	569:'Provider unable to substitute',
	600:'Requesting/referring provider unable to be identified',
	601:'In hospital services cannot be claimed as out of hospital',
	602:'Out of hospital service cannot be claimed as in hospital',
	603:'Newborn not yet enrolled with medicare - no benefit payable',
	604:'Service over 6 months old - late lodgement form required',
	605:'Referral expired - no benefit payable',
	606:'Referring provider number not open at date of referral',
	607:'Referral date has been omitted',
	608:'Referring and servicing provider same - no benefit payable',
	609:'Service cancelled at providers request',
	610:'Provider specialty not consistent with item claimed',
	611:'Referral/request details not supplied - no benefit payable',
	612:'Date of referral after date of service - no benefit payable',
	613:'Card number cannot be identified from information supplied',
	614:'No benefit payable - please notate time of each visit',
	615:'Multiple procedures - notate times and area of treatment',
	616:'Item cannot be claimed as in hospital service',
	617:'Item cannot be claimed as out of hospital service',
	618:'No benefit if requested by this provider at date of request',
	619:'Servicing provider number not open at date of service',
	620:'Duplicate transmission - no further payment made',
	621:'Item not claimable electronically',
	622:'Pet drop-down items not claimable via edi',
	623:'Pet items only claimable via direct bill',
	624:'Pet items - payee provider required',
	625:'Payee provider not eligible to claim pet items',
	627:'Pdt statement not provided by the doctor',
	629:'Initial pdt therapy item not present on patient history',
	633:'Refer back to the specialist (referring provider is closed)',
	634:'Refer back to the specialist (servicing provider is closed)',
	635:'Late lodgement not approved - letter being sent separately',
	636:'Benefit reduced-dental cap broken',
	637:'No benefit payable-dental cap reached',
	638:'Derived fee and other item cannot be claimed in-hospital',
	639:'Provider not in an eligible area to claim this item',
	640:'More than one base and derived item claimed',
	641:'More than one base item claimed',
	642:'Benefit paid for derived and other item claimed',
	643:'Derived item assessed with other item on statement',
	700:'Benefit cannot be determined for this service',
	701:'Benefit cannot be determined due to complex assessing rules',
	702:'Item restrictive with another item',
	703:'Duplicate of item already quoted',
	704:'Provider not permitted to claim this item',
	705:'No associated pathology service',
	706:'Provider not associated with a pathology laboratory',
	707:'Pathology laboratory not registered at date of service',
	708:'Item cannot be claimed from this pathology laboratory',
	709:'Another assistant item should be claimed',
	710:'Associated surgical items not present',
	711:'Unable to determine associated surgery',
	712:'Base item not present or in incorrect order',
	713:'Radiotherapy fields greater than maximum allowable',
	714:'Benefit not determined - number ot time units not present',
	715:'Number of time units exceeded maximum allowable',
	716:'Service forms a composite item - composite item required',
	717:'Benefit not payable on this service for a hospital patient',
	718:'Provider location not open at date of service',
	719:'Benefit cannot be calculated for hyperbaric oxygen therapy',
	720:'Eligibility cannot be determined for this item',
	732:'Referral period not valid for referring provider',
}

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class medicare_meta_model {

	static get MEDICARE_REASON_CODES() { return _MEDICARE_REASON_CODES; }
		
}
