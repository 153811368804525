//	------------------------	------------------------	------------------------
//	Description: Minor base component of an item
//  eRx script -> ITEM[s] -> content, SESSION KEY
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const get_set =                     require('../../helpers/erx/get_set_function')

const erx_formats =                 require('../erx/resources/erx_formats')
const erx_props =                   require('../erx/resources/erx_props')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DEFAULTS = {

}

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

// eslint-disable-next-line no-undef
module.exports = class erx_session_key_model {

    constructor(session_key_data, run_validation = true) {

        this._session_key_data = {}
        this.setup_props()

        if (session_key_data){
            if (run_validation) this.session_key_data = session_key_data
            else this._session_key_data = session_key_data
        }
        else this.set_default()

    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get session_key_data() { return this._session_key_data }
    set session_key_data(val) {
        this._session_key_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------

    set_blank() {// You probably don't want to call this
        this.clear()

        erx_props.SESSION_KEY_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })
    }

    //	------------------------	------------------------	------------------------

    is_nill() {
        let result = true
        erx_props.SESSION_KEY_PROPS.forEach(item => { 
            if (this[item.name] != null && this[item.name] != undefined) result = false
        })
        return result
    }

    //	------------------------	------------------------	------------------------

    set_default() { 
        this.set_blank()
        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {
        
        // Fix self
        let result = {res: 'ok', fixes: [], err: []}

        for (let i = 0; i < erx_props.SESSION_KEY_PROPS.length; i++){
            const c_prop = erx_props.SESSION_KEY_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format - see if we can convert any strings to expected format
            if ((typeof c_item !== 'undefined') && c_item !== '' && c_item !== null && c_prop.format){ //Note - content props for patient, clinician and item wont have a format and will be ignored
                
                const formatter = erx_formats.ERX_FORMAT[c_prop.format]
                if (!formatter || !formatter.validate) result.err.push('Cannot find validator for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                else {

                    const valid_res = formatter.validate(c_item)

                    if (valid_res.res != 'ok'){

                        if (remove_errors) this[c_prop.name] = null // The value may be fixed below

                        if (!formatter.fromValue) result.err.push('Cannot find formatter (fromValue function) for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                        else {

                            const from_res = formatter.fromValue(c_item)

                            if (from_res.res != 'ok') result.err.push('Could not parse item to type ' + c_prop.format + ' for prop ' + c_prop.name + '. Error: ' + from_res.err)
                            else {

                                result.fixes.push( { name: c_prop.name, from: c_item, to: from_res.val } )
                                this[c_prop.name] = from_res.val

                            }
                        }
                    } 
                }              
            }
        }
        
        if (result.err.length > 0) result.res = 'err'
        else delete result.err
        return result

    }

    //	------------------------	------------------------	------------------------

    check_valid(params = {}) {

        for (let i = 0; i < erx_props.SESSION_KEY_PROPS.length; i++){
            const c_prop = erx_props.SESSION_KEY_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format
            if (typeof c_item != 'undefined' && c_item != '' && c_item != null && c_prop.format){
                const check_format_res = erx_formats.check_erx_format(c_item, c_prop.format)
                if (check_format_res.res != 'ok') return {res: 'err', err: c_prop.name + ' is wrong format. ' + check_format_res.err}
            }

            // Check the length
            if (typeof c_item != 'undefined' && c_item != null && c_prop.length){
                if (c_item.toString().length > c_prop.length)
                    return {res: 'err', err: c_prop.name + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString()}
            }

            // Check max occurance
            if (!c_prop.minOccurs === 0){
                if (typeof c_item === 'undefined') return {res: 'err', err: c_prop.name + ' is must have at least one occurance but has none. Value: ' + c_item}
            }

            //Check nillable
            if (!c_prop.nillable === true){
                if (c_item === null && !c_prop.minOccurs === 0) return {res: 'err', err: c_prop.name + ' is not nillable but is null. Value: ' + c_item}
            }

            // Check the mandatory
            if (c_prop.mandatory && c_prop.mandatory(params)){
                if (typeof c_item == 'undefined' || c_item == null)
                    return {res: 'err', err: c_prop.name + ' is mandatory but absent. Value: ' + c_item}
            }
        }
        return {res: 'ok'}
    }

    //	------------------------	------------------------	------------------------

    compare(target_session_key, subset = false) { // This shouldn't really get called

        let diff = []

        //log("Checking session key props match")
        // Check props

        erx_props.SESSION_KEY_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_session_key && (typeof target_session_key[item.name] != undefined) && (target_session_key[item.name]!= '') && target_session_key[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Patient property ' + item.name + ', source: ' + source + ', target: ' + target, source, target}) 
            }
        })

        // Return
        if (diff.length == 0) return {res: 'ok'}
        else return {res: 'err', err: 'Patients do not match by ' + diff.length + ' field(s). Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff}

    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.SESSION_KEY_PROPS.forEach(p => this.define_prop(p)) }
    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.session_key_data }
    set inner_data(val) { this.session_key_data = val }

    //	------------------------	------------------------	------------------------

    // You probably don't want to call any of these

    get Key() { throw { override_msg: 'get Key' }}
    set Key(val) { throw { override_msg: 'set Key' }}

    get IV() { throw { override_msg: 'get IV' }}
    set IV(val) { throw { override_msg: 'set IV' }}

    get CertificateVersion() { throw { override_msg: 'get CertificateVersion' }}
    set CertificateVersion(val) { throw { override_msg: 'set CertificateVersion' }}

}