import React, { useEffect } from 'react';
import { Button, Divider, Icon, List, Message } from "semantic-ui-react";
import { useAsync } from "../../../../xAppLib/Hooks/useAsync";
import treatment_plan_model from "../../../../models/treatment_plan_model";
import UniFormMed from "../../UniFormMed";
import UniFieldSUI from "../../../../xAppLib/UniForm/UniField-SUI";
import moment from "moment";
import { useProfile } from "../../../../xAppLib/Hooks";
import { pick } from "../../../../helpers/json_functions";

function ContactUs({children = 'contact us'}) {
	const openContactModal = e => {
		e.preventDefault();
		app.trigger(app.events.SHOW_POPUP, {pt: "contact", txt: 'Contact Us', u: 'contact-us'})
	};

	return <a href="#" className="underline" onClick={openContactModal}>{children}</a>;
}

function describeIndicativePrice(cart, schedule) {
	if (!schedule) return '';

	const each = cart.content().total;
	const repeats = schedule.length;
	const total = each.multiply(repeats);

	return `${each.format()} x ${repeats} = ${total.format()}`;
}

function TreatmentPlanOverview({tpid, cart, onIndicativePrice, onLoad, children}) {
	const {data, loading, error, fn: load} = useAsync(
		() => tpid && treatment_plan_model.get_treatment_plan(tpid).then(res => {
			onLoad?.(res);
			return res;
		}), [tpid]
	);

	const outstanding = data?.schedule.filter(s => s.outstanding);
	const each = cart.content().total.format();
	const indicative_price = describeIndicativePrice(cart, outstanding);

	useEffect(() => {
		onIndicativePrice(indicative_price);
	}, [indicative_price]);


	if (!tpid) {
		return (
			<Message
				visible
				error
				header="There was an error loading your treatment plan details"
				content={<>
					<p>Treatment Plan ID not found. Please <ContactUs/> to get this resolved.</p>
				</>}
			/>
		);
	}

	if (loading) {
		return <Icon loading name='spinner' size='small'/>;
	}

	if (error || !data) {
		return (
			<Message
				visible
				error
				header="There was an error loading your treatment plan details"
				content={<>
					<p>
						{String(error || "No data returned")}. Please <ContactUs/> if this error keeps happening.
					</p>
					<Button onClick={load}>Try again</Button>
				</>}
			/>
		);
	}

	return (<>
		<p>
			By continuing with this Treatment Plan, you will be charged{" "}
			<strong>{each}</strong> on or near the following dates:
		</p>

		<List bulleted ordered>
			{outstanding.map(s => (
				<List.Item key={s.prid}>
					{s.activate_at && s.status !== 'active' ? moment(s.activate_at).format('MMM D, YYYY') : 'Immediately'}
				</List.Item>
			))}
		</List>

		{/*<Divider/>*/}
		{/*<div style	={{display: 'flex', flexWrap: 'wrap'}}>*/}
		{/*	<h3 style={{flexGrow: 1, margin: '0 0 10px'}}>{inflight && 'Remaining'} Indicative Treatment Plan Cost</h3>*/}
		{/*	<h3 style={{flexGrow: 0, margin: '0 0 10px'}}>{indicative_price}</h3>*/}
		{/*</div>*/}

		{/*<p>*/}
		{/*	We cannot guarantee shipping or medication costs will remain the same for the duration of your*/}
		{/*	treatment plan. If there is an increase in price, we will inform you first to approve the*/}
		{/*	revised price, or you can cancel if you do not wish to continue. If the shipping or*/}
		{/*	medication price decreases, you will be charged the lower rate automatically.*/}
		{/*</p>*/}

		{children}
	</>);
}

export default function TreatmentPlan(props) {
	const {visible, enabled, Section, formData, cart, onUpdate, onUpdateDefaults} = props;
	const [profile, profiles] = useProfile();
	const active = profiles[formData.profile || 0] || profile;

	if (!(visible && enabled)) {
		return null;
	}

	const presc = app.user.prof?.pts_prescs?.find(p => [p.par, p.prid].includes(formData?.ref_prid));
	const tpid = presc?.par || presc?.prid;
	const uid = active?.uid;
	const onLoad = data => {
		const dets = pick(data?.dets || {}, ['shipping_address', 'shipping_address_components', 'yogp', 'yogp_want', 'want_gen']);
		const values = {
			...dets,
			...(dets.shipping_address_components && {
				shipping_address_components: {
					...dets.shipping_address_components,
					formatted: dets.shipping_address_components.formatted || dets.shipping_address
				}
			})
		};

		onUpdate(values);
		onUpdateDefaults(values);
	};

	return (
		<Section>
			<Section.Header>Treatment Plan</Section.Header>
			<Section.Content>
				<UniFormMed {...props} section="treatment_plan" fields={treatment_plan_model.FORM_FIELDS}>
					{(values, valids, uf_this, fields) => (
						<TreatmentPlanOverview tpid={tpid} uid={active?.uid} cart={cart} onLoad={onLoad} onIndicativePrice={price => {
							uf_this.handleInputChange({target: {name: 'indicative_price', value: price}})
						}}>
							{/*<UniFieldSUI fl={fields.subscription} {...{values, valids, uf_this}} show_label={false}/>*/}
							<UniFieldSUI fl={fields.subscription_tos} {...{values, valids, uf_this}} show_label={false}/>
							{/*<UniFieldSUI fl={fields.indicative_price} {...{values, valids, uf_this}}/>*/}
						</TreatmentPlanOverview>
					)}
				</UniFormMed>
			</Section.Content>
		</Section>
	);
}