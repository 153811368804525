import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

const Processes = (props) => {
  const { process, style, panelStyle, textStyle, titlePanelStyle, titleStyle, imgPanelStyle, imgStyle, buttonStyle } = props;
  const img_path = "https://storage.googleapis.com/instant-med-public/Haiku.Health/";
  const circleNumber = (number) => {
    return (
      <svg className="h-32 w-32 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <circle fill="#ffffff" stroke="#1b4932" strokeMiterlimit="10" strokeWidth="15px" cx="250" cy="250" r="225"/>
      <text fontSize="320px" fill="#1b4932" fontFamily="GeneralSansBold, 'Helvetica Neue', Arial, Helvetica, sans-serif" x={number == 1 && "185" || "158"} y="358">{number}</text>   
      </svg>)
  }

  return (
    <div
      className={`${process.length === 4 ? `md:grid-cols-2 lg:grid-cols-4` : `md:grid-cols-${process.length}`} process grid  grid-cols-1 ${style}`}
    >
      {process.map((step, i) => {
        const image = step.number || step.image || app.settings.is_cbls && "https://storage.googleapis.com/instant-med-public/BLSClinics/bls-icon.png" ||
        app.settings.is_cmg && img_path+"Medigrowth_icon.png"
        return (
          <div className={`tile flex flex-col mx-2 ${panelStyle}`} key={i}>
            <div className={`flex flex-col justify-center`}>
              {(step.number || image) && <div className={`my-auto ${imgPanelStyle}`}>
                  {image && <img className={`${imgStyle}`} src={image} alt={step.title}/>}
                  {step.number && circleNumber(step.number)}
               </div>}
              {step.title && (<div className={`my-auto ${titlePanelStyle}`}>
                <h4 className={`w-full text-step icann-font-one mb-0 ${titleStyle}`}> {step.title} </h4>
                </div>)}

               {step.sub_title && (<div className={`my-auto ${titlePanelStyle}`}>
                <h3 className={`w-full text-step`}> {step.sub_title} </h3>
                </div>)}
            </div>
           
             {step.text && <p className={`lg:text-md text-base w-full ${textStyle}`}> {step.text} </p>}
             {step.button && <Button
                className={buttonStyle}
                to={step.link}
                onClick={step.onClick}
              >
                {step.button}
              </Button>}
          </div>
        );
      })}
    </div>
  );
};

Processes.propTypes = {
  process: PropTypes.array.isRequired,
  style: PropTypes.string,
  panelStyle: PropTypes.string,
  titlePanelStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  textStyle: PropTypes.string,
  imgStyle: PropTypes.string,
  imgPanelStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  cannStatus: PropTypes.object
};

export default Processes;