
import firebase_database from 'xAppLib/providers/firebase_database.js';
import { push, onDisconnect, update, onValue, serverTimestamp } from 'firebase/database';

import moment from 'moment';

import throttle from 'xAppLib/libs/throttle'

import cln_json_data from 'xAppLib/helpers/cln_json_data'

const DEBUG = false;

export default class presence_model {

	static connect () {

		const userListRef = firebase_database.ref("/user_presence/online");

		DEBUG && console.log('presence_model::init claims', app.user.prof?.claims && Object.keys(app.user.prof?.claims)?.filter( r => app.user.prof.claims[r] ).map( r => ({['srch_'+r]:app.user.prof.claims[r]}) ) )

		const ini_flds = app.dvc.dvcid && {

									cre_tm: moment().format('X'),
									ua: navigator.userAgent,

									loc_st: document.location.href,

									shid: app.shid,

									dvcid: app.dvc.dvcid || '',
									d_oid: app.dvc.org?.oid || '',
									d_onm: app.dvc.org?.name || '',
								}

		const usr_flds = _ => app.user.user_in && ({
									u_nm: app.user.user_det.displayName,
									u_em: app.user.user_det.email,
									u_ph: app.user.user_det.photoURL,
									uid: app.user.user_det.uid,
									role: app.user.prof.claims,

									// ...( Object.keys(app.user.prof?.claims)?.filter( r => app.user.prof.claims[r] ).map( r => [`srch_${r}`] = app.user.prof.claims[r]})  ),
									srch_doc_gp: app.acl.is_doc && app.acl.is_doc_gp && 'y' || null,
									srch_doc_cosm: app.acl.is_doc && !app.acl.is_doc_gp && 'y' || null,
									srch_doc_nurse: app.acl.is_nurse && 'y' || null,

									u_oid: app.user.org.oid,
									u_onm: app.user.org.name,
								}) || ({
									u_nm: 'nonuser',
								})

		const loc_flds = loc => ({
									loc_lst: document.location.href,
									pth_lst: loc?.pathname,
									loc_lst_tm: moment().format('X'),
									stm: serverTimestamp(),
								})

		const idl_st_flds = _ => ({
									idle: true,
									idle_st: moment().format('X'),
									stm: serverTimestamp(),
								})
		const idl_en_flds = _ => ({
									idle: false,
									idle_en: moment().format('X'),
									stm: serverTimestamp(),
								})
		const idl_rs_flds = _ => ({
									idle: false,
									idle_rs: moment().format('X'),
									stm: serverTimestamp(),
								})

		const start_data = cln_json_data({
								...ini_flds,
								...usr_flds(),
								...idl_rs_flds(),
							})

		DEBUG && console.log('presence_model::init', start_data)

		const myUserRef = push(userListRef, start_data);
		// TODO unwatch?
		let once = false
		onValue(myUserRef, snap => {
			const val = snap.val();
			if (!val)
				return
			const client_tm = Math.max([
				val.idle_st,
				val.idle_en,
				val.idle_rs,
			].map(x => x*1000).filter(x => isFinite(x)));
			const server_tm = val.stm;
			const lag = client_tm && server_tm ? client_tm - server_tm : 0
			val.lag = lag
			if (client_tm && server_tm) {
				DEBUG && console.log(`client lag ${lag}ms`);
			}
			if (!once) {
				app.trigger(app.events.PRS, {...val})
				once = true
			}
		})

		firebase_database
			.watch_record(
							".info/connected",
							c => {

									if (!c) return;

									onDisconnect(myUserRef)
										.remove()
										.then( () => app.user.user_in
														&& firebase_database
																.update_record("/user_presence/last_online/"+app.user.user_det.uid, null, {timestamp:true})
											);

									app.user.online = c
									app.trigger(app.events.USER_UPD, 'UserOnlineChanged')

								}
						)

		app.on(app.events.USER_UPD, _=> update(myUserRef, cln_json_data(usr_flds())) )


		const throttled_update = throttle(update, 2000);
		app.on(app.events.IDLE_START, _=> throttled_update(myUserRef, idl_st_flds()) )
		app.on(app.events.IDLE_END, _=> throttled_update(myUserRef, idl_en_flds()) )
		app.on(app.events.IDLE_RESET, _=> throttled_update(myUserRef, idl_rs_flds()) )

		app.history.listen( loc => update(myUserRef, loc_flds(loc)) , true)
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static get_online_users (ca, tp) {

		return firebase_database
			.watch_record(
							"user_presence/online",
							r => r && ca?.( r ),
							tp!='all' && {
								where_key: 'srch_'+tp,
								where_val: 'y',
								// where_type: 'fro',
							} || {
								where_key: 'u_nm',
								where_val: 1,
								where_type: 'fro',
							}
						)

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

}
