const firebase_conf_prod = {
	apiKey: "AIzaSyCQ4Fub0KXF_DBJirQg3mkgy4v-tGFzyyk",
	// authDomain: "safe-scripts.firebaseapp.com",
	authDomain: "www.instantscripts.com.au",
	databaseURL: "https://safe-scripts.firebaseio.com",
	projectId: "safe-scripts",
	storageBucket: "safe-scripts.appspot.com",
	messagingSenderId: "1077172152896",
	appId: "1:1077172152896:web:abad9f5da68037fa",
	vapid_key:"BIDzAIJ4YKsX_L83QA2nCxq1Z45U78XNV8ivo69eobRd-bSMJkoKZ_1426i6dXPsL17HKGQ7J-MpqEBPSkOg0Ls",
	web_client_id:"1077172152896-fjq028kc6q44vlr2a832gvjv2vk3808u.apps.googleusercontent.com"
};

const firebase_conf_dev = {
	apiKey: "AIzaSyC_QgFI3xsOrjUaVvuFcI0QD15uUWMrIdk",
	authDomain: (typeof app == 'undefined' || location.host == 'localhost:9011') ? "iscr-dev.firebaseapp.com" : location.hostname,
	databaseURL: "https://iscr-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "iscr-dev",
	storageBucket: "iscr-dev.appspot.com",
	messagingSenderId: "167379052692",
	appId: "1:167379052692:web:adb2cb6530ced0e9a8ac81",
	measurementId: "G-JBH7HPSC7T",
	vapid_key:"BEKAyq3jNAFtXprLulpdmSbOcE43MyhFXx9NQV7TsfLf4-G-x16fscCEG5df-t7tn_TOk7RsWPvBxe6GKmctzu4",
	web_client_id:"167379052692-qgejj1tmdmkea6001gegb757hfvnel8e.apps.googleusercontent.com"
};

const firebase_conf = (typeof app !== 'undefined' && app.settings.frb_prod) ? firebase_conf_prod : firebase_conf_dev;
export default firebase_conf;