import db_lib from 'xAppLib/libs/db_lib';
import firebase_database from 'xAppLib/providers/firebase_database.js';
import API_service from "../xAppLib/providers/API_service";

import org_model from 'models/org_model'
import { PARTN } from "../constants";

export const cat_nms = [
	'pharm',
	'cosm',
	'mobIVdr',
	// 'patho',
	'IVwell',
	'doc_presc_cat',
	'VCPS',
	'IMS',
	'CSC',
	'iLeg_cat',
	'cbls',
	'cmg',
];

const _CAT_FIELDS = [

						// {
						// 	type: "hidden",
						// 	name: "l",
						// 	value: loc
						// },

						{
							name: "type",
							type: "radio",
							options: [{c:'c', n:'Category'}, {c:'m', n:'Medication'}, {c:'l', n:'Link'}, {c:'s', n:'Section'}],
							placeholder: "Type?",
							param_name: "t",
							req_label: "*",
						},

						{
							name: 'active',
							label: 'Active',
							type: 'bool',
						},

						// user_model.check_access('root') && 
						{
							name: "order",
							type: "number",
							// placeholder: "",
							// pattern: "a-zA-Z0-9_-",
							param_name: "o",
							// prop_name: "k",
							// req_label: "*",
							valid_not_required: true,

							// view_link_to: true,
						},

						{
							name: "code",
							type: "text",
							placeholder: "Code ID (optional)",
							// pattern: "a-zA-Z0-9_-",
							param_name: "c",
							prop_name: "k",
							req_label: "*",
							valid_not_required: true,

							view_link_to: true,
						},
						{
							name: "name",
							type: "text",
							placeholder: "Name",
							param_name: "n",
							req_label: "*",

							view_link_to: true,
						},
						{
							name: "description",
							type: "textarea",
							placeholder: "Describe the medication",
							param_name: "d",
							req_label: "*",
							valid_not_required: true,
						},
						{
							name: "additional",
							type: "textarea",
							placeholder: "Additional information",
							param_name: "x",
							req_label: "*",
							valid_not_required: true,
						},

						{
							name: "conf",
							type: "json-text",
							placeholder: "",
							param_name: "f",
							// req_label: "",
							valid_not_required: true,
						},

					];

const _FRDB_LOC = 'meds_cat';
const _MODEL_NAME = 'cat_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class cat_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC + (cat_model.cat_nm!=null && cat_model.cat_nm!='pharm' && ('_'+cat_model.cat_nm)||'') }
	// static get FRDB_LOC() { return _FRDB_LOC + (cat_model.alt_cat&&!cat_model.alt_cat.startsWith("_")&&'_'||'') + (cat_model.alt_cat||''); }
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get CAT_FIELDS() { return _CAT_FIELDS; }

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

	static set_cat(c) {
		cat_model.alt_cat = c || false;
		cat_model.cat_nm = c && c?.replace(/^_/, '') || 'pharm';
	}
	
	static watch_record (l, ca, p) {
		super.watch_record (l, r=>{
			ca(r)
		}, p) 
	}

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

	static async get_cat_list (p, refresh = false) {

		const cat_nm = p?.cat_nm || 'pharm'
		const mb_list = p?.mb_list || cat_nm=='pharm' && org_model.org_conf_itm(app.runtime.org, 'is_mb') && (org_model.org_conf_itm(app.runtime.org, 'mb_list') || 'pharm')

		// console.log('cat_model::get_cat_list  cat_nm ', cat_nm, 'mb_list', mb_list);
		const cat_key = `${cat_nm}${p?.all?'_all':''}`
		if (refresh || !app.app_data.ws_data?.cat_data?.[cat_key] || app.app_data.ws_data?.cat_data?.[cat_key]?.mb_list!=mb_list) {

			cat_model.set_cat( cat_nm )
			const cat_tree_list = await cat_model.get_record(null,null,{refresh})

			let cat_show_list = {}
			let cat_avail_list = {}
			let temp_config = {};
			const prefix_par_nm = p.prefix_par_nm ? cat_nm.replace('_', '') : ''
			const recur_list_trav = (cat_data, par='', par_db='', par_nm=prefix_par_nm, par_id = undefined, showing=true) => (
						cat_data &&
							Object.keys(cat_data)
								// .sort( (a, b) => cat_model.sort_cat_view(cat_data[a], cat_data[b]) )
								.sort( (a, b) => cat_data[a].o && cat_data[b].o ? cat_data[a].o - cat_data[b].o : cat_data[a].n.localeCompare(cat_data[b].n) )
								.map( objk => {

									let cat = JSON.parse(JSON.stringify(cat_data[objk]));
									cat.l = par
									cat.l_db = par_db
									cat.par_nm = par_nm
									cat.par_id = par_id

									if (cat.t === "s")
										temp_config = cat;
									else if (cat.t === "m" && cat.l === temp_config.l) 
										cat.f = {
											...(temp_config.f || {}),
											...(cat.f || {})
										}

									if (cat.t=='c' || cat.t=='m'&&(p.all || cat.active!=false)) cat_avail_list[objk] = cat;
									if (showing&&(p.all || cat.active!=false)) cat_show_list[objk] = cat;

									// ['c1', 'c1m1', 'ZZZ - test category', "Healthaexpensevine Forte"].includes(cat.n) && 
									// 	console.log('cat', objk, cat.t=='c' || cat.t=='m'&&cat.active!=false, showing, cat, cat_avail_list[objk], cat_show_list[objk]);

									cat.i && recur_list_trav(cat.i, par+objk+'/', par_db+objk+'/i/', par_nm+(par_nm && ' > ' ||'')+cat.n, `${objk}`, showing&&(p.all || cat.active!=false));
									delete cat.i
									// if (cat_data[objk].active==false && !p.all) return;

									return
								} )
			)
			
			let meds_sub_list
			let meds_mb_list

			// if (cat_nm =='pharm') {
			
				meds_sub_list = await firebase_database.get_record ('meds_sub')

				mb_list && ( meds_mb_list = await firebase_database.get_record ('meds_mb_'+mb_list) )

				// console.log('cat_model::get_cat_list ', mb_list, 'meds_mb_list', meds_mb_list);

				meds_mb_list && Object.keys(meds_mb_list).map( k => {

								if (typeof meds_mb_list[k] != 'object') 	return;

								meds_mb_list[k]['mb_avail'] = cat_model.can_deliver(meds_mb_list[k]);
								meds_mb_list[k]['mb_avail'] && (meds_mb_list[k]['min_price'] = meds_mb_list[k].medbuy_gen_conc_price || meds_mb_list[k].medbuy_conc_price);
								meds_mb_list[k]['mb_avail'] && (meds_mb_list[k]['max_price'] = meds_mb_list[k].medbuy_nomedicare_price || meds_mb_list[k].medbuy_gen_nomedicare_price);
						} )
			// }

			// console.log('meds_mb_list', meds_mb_list);

			recur_list_trav(cat_tree_list)

			// return {cat_tree_list, cat_flat_list}


			if (!app.app_data.ws_data)				app.app_data.ws_data = {};
			if (!app.app_data.ws_data.cat_data)		app.app_data.ws_data.cat_data = [];
			
			app.app_data.ws_data.cat_data[cat_key] = {cat_show_list, cat_avail_list, meds_sub_list, mb_list, meds_mb_list, cat_tree_list};
			
			// console.log(cat_nm, app.app_data.ws_data.cat_data[cat_nm]);

			// console.log('cat data cached');
		} else {
			// console.log('cat data from cache');
		}

		return app.app_data.ws_data.cat_data[cat_key];

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static sort_cat_view(cat_a, cat_b, search) {

		if (search) { // if sorting within search results, prioritize direct matches of the search
			const index_a = cat_a.n.toLowerCase().indexOf(search),
				index_b = cat_b.n.toLowerCase().indexOf(search);
			if (!index_a && index_b) return -1;
			if (index_a && !index_b) return 1;
		}
		
		return (!cat_a.o && !cat_b.o) && cat_a.n.localeCompare(cat_b.n) || ((cat_a.o||1000) - (cat_b.o||1000)) ;

		// if (!cat_a.o && !cat_b.o)
		// 	return cat_a.n.localeCompare(cat_b.n)
		// else
		// 	return ((cat_a.o||1000) - (cat_b.o||1000))
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------  

	static can_deliver(med_mb) {
		if (med_mb?.medbuy_supp_oid === PARTN.EVERMED) {
			return true;
		}

		return !!(med_mb?.medbuy_supp_oid
			&& (med_mb.medbuy_price
				&& med_mb.medbuy_conc_price
				&& med_mb.medbuy_nomedicare_price
				|| med_mb.medbuy_gen_price
				&& med_mb?.medbuy_gen_conc_price
				&& med_mb.medbuy_gen_nomedicare_price)
		);
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------  

	static get_all_cat_tree_list() {
		const allCat = app.app_data?.ws_data?.cat_data ?? {};
		return Object.entries(allCat).reduce((acc, [k, v]) => Object.assign(acc, { [k.replace(/^_|_all/g, '')]: v.cat_tree_list }), {});
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------  

	static med_search(val) {

        return API_service.load_data(`catalogue_med_search?s=${val}`);
		
	}
}

	// 		--------------------------------		--------------------------------		---------
