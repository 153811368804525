import React from 'react';


const icanntc = () => <a className='underline' onClick={_=>app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Terms and Conditions',pn:'terms-and-conditions', target: app.settings.is_cmg && 'cmg' || app.settings.is_cbls && 'cbls' || 'iscr' }) } target='_blank'>Terms and Conditions</a>
const ctc = () => <a className='underline' onClick={_=>app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Customer Terms and Conditions',pn:'customer-terms-and-conditions' }) } target='_blank'>Customer Terms and Conditions</a>
const ptc = () => <a className='underline' onClick={_=>app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Platform Terms and Conditions',pn:'platform-terms-and-conditions' }) } target='_blank'>Platform Terms and Conditions</a>
const privacy = () => <a className='underline' onClick={_=>app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Privacy Policy',pn:'privacy-policy' }) } target='_blank'>Privacy Policy</a>

export function TermsAndPrivacyBlurb() {
  return <>By clicking Sign Up you accept our {ptc()}, {ctc()} and {privacy()}. You will be added to our subscriber list, you can unsubscribe at any time.</>
}

export default function TermsAndPrivacy({ isShowingSubscriber = true }) {
  return <label style={{fontSize:'0.875rem'}}><span className='font-semibold'>I have read and accept the {app.runtime.name} {privacy()}, {(app.settings.is_cmg || app.settings.is_cbls) ? icanntc() : <>{ptc()} and {ctc()}</>}.</span>{isShowingSubscriber && <><br/><br/>You will be added to our subscriber list, you can unsubscribe at any time.</>}</label>;
}