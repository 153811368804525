import React from "react";
import { Button, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

const MedUnsuit = (props) => {
	const { Section, enabled, visible } = props

	if (!(enabled && visible))
		return null;

	return <Section>
			<br />
			<Message
				error
				visible
				header = 'This medication is unavailable to you through our online prescription service.'
				data-testid = 'label-medication-unavailable' 
				content = {<><br/><p>We suggest you have a <Link className="link" to="/m/pharm/DocCons/DocConsCov">Telehealth Consultation</Link> with one of our doctors to discuss your request.</p></>}
				/>
		</Section>;
};

export default MedUnsuit;

