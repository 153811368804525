import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Form,
    FormField,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    TextArea,
} from "semantic-ui-react";
import useOnSettingsButtonClick from "../../utlis/hooks/useOnSettingsButtonClick";
import Title from "../components/Title";
import ImagePicker from 'xAppLib/UIelems/ImagePicker';
import { Link } from "react-router-dom";

export const INIT = {
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, <link>hyper link<link> tempor incididunt ut labore et dolore magna aliqua.",
    desktopSrc: 'https:/www.instantscripts.com.au/images/homepage/2210_home_header_desktop.jpg',
    tabletSrc: 'https:/www.instantscripts.com.au/images/homepage/2210_home_header_640.jpg',
    mobileSrc: 'https:/www.instantscripts.com.au/images/homepage/2210_home_header_desktop.jpg',
    link: ''
}

const CreditBlock = ({
    data,
    onDataChange,
    readOnly,
    settingsWrapper,
    viewerOnly,
}) => {
    const [toggleSettings, setToggleSettings] = useState(true);
    const [state, setState] = useState(data);
    const [confirm, setConfirm] = useState(false);
    const [imagePicker, setImagePicker] = useState();

    useEffect(() => {
        if (!Object.keys(data).length) setState(INIT);
    }, []);

    !viewerOnly &&
        useOnSettingsButtonClick(settingsWrapper, "settings", () =>
            setToggleSettings((prev) => !prev)
        );

    const onConfirm = () => {
        const formElement = document.getElementById("edit-component-form");
        const formData = new FormData(formElement);

        const update = {};
        for (let key in INIT) {
            const value = formData.get(key);
            update[key] = value !== null ? value : state[key];
        }

        onDataChange(update);
        setState(update);
        setConfirm(false);
    };

    const handleOnImageClick = (target, imgUrl) => {
        document.getElementById(target).value = imgUrl;
        setImagePicker(false);
    };

    const modalEl = confirm && (
        <Modal
            style={{ height: "fit-content", margin: "auto" }}
            onClose={() => setConfirm(false)}
            open={confirm}
        >
            <Form id="edit-component-form">
                <ModalHeader className="m-4">
                    <Title title={`Editing Credit Block component`} />
                </ModalHeader>
                <ModalContent className="m-4">
                    <FormField>
                        <label>Text (mark the link text with {"<link>"} tags)</label>
                        <TextArea
                            type="textarea"
                            name="content"
                            placeholder="Enter content"
                            defaultValue={state.content}
                        />
                        <label>Link</label>
                        <TextArea
                            type="textarea"
                            name="link"
                            placeholder="Enter link"
                            defaultValue={state.link}
                        />
                    </FormField>
                    <FormField>
                        <label>Desctop Source</label>
                        <input
                            id="imagePicker-desktopSrc"
                            name="desktopSrc"
                            placeholder="Desktop Source"
                            defaultValue={state.desktopSrc}
                            onClick={() => setImagePicker("imagePicker-desktopSrc")}
                        />
                    </FormField>
                    <FormField>
                        <label>Laptop Source</label>
                        <input
                            id="imagePicker-tabletSrc"
                            name="tabletSrc"
                            placeholder="Laptop Source"
                            defaultValue={state.tabletSrc}
                            onClick={() => setImagePicker("imagePicker-tabletSrc")}
                        />
                    </FormField>
                    <FormField>
                        <label>Mobile Source</label>
                        <input
                            id="imagePicker-mobileSrc"
                            name="mobileSrc"
                            placeholder="Mobile Source"
                            defaultValue={state.mobileSrc}
                            onClick={() => setImagePicker("imagePicker-mobileSrc")}
                        />
                    </FormField>

                </ModalContent>
                <ModalActions className="m-4">
                    <Button
                        content="Cancel"
                        onClick={() => setConfirm(false)}
                    />
                    <Button content="Confirm" onClick={onConfirm} />
                </ModalActions>
            </Form>
        </Modal>
    );

    const ImageWrapper = ({ children }) => viewerOnly ? (
        <Link to={state.link}>{children}</Link>
    ) : (
        <div>
            {children}
        </div>
    );

    const contentArr = state.content?.split("<link>") || [];

    return (
        <>
            <Container className="landing-page-credit-block my-6" >
                <div className="p-12 border-2" >
                    <p
                        onClick={
                            toggleSettings && !readOnly
                                ? (e) => {
                                    e.stopPropagation();
                                    setConfirm(true);
                                }
                                : undefined
                        }
                    >{contentArr.length > 1 ? (
                        <>
                            {contentArr.map((part, index) =>
                                viewerOnly && index === 1 ? (
                                    <Link to={state.link}>{part}</Link>
                                ) : <span>{part}</span>)}
                        </>
                    ) : state.content}</p>
                    <div className="mt-16 max-w-[360px]">
                        <ImageWrapper>
                            <img
                                className="object-cover w-full h-full hidden md:block"
                                src={state.desktopSrc}
                            />
                            <img
                                className="object-cover w-full h-full hidden sm:block md:hidden"
                                src={state.tabletSrc}
                            />
                            <img
                                className="object-cover w-full h-full block sm:hidden"
                                src={state.mobileSrc}
                            />
                        </ImageWrapper>
                    </div>
                </div>
            </Container>
            {modalEl}
            <ImagePicker
                open={Boolean(imagePicker)}
                onClose={() => setImagePicker()}
                onImageClick={(_, imgUrl) => handleOnImageClick(imagePicker, imgUrl)}
                bucket="is-media"
            />
        </>
    );
};

export default CreditBlock;
