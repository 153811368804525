import React, { Component, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Dropdown,Ref } from 'semantic-ui-react'
import AdminMenu from './AdminMenu';
	
const SideAwareDropdown = ({trigger,items,cls="header-menu"}) => {
	const ref = useRef(null)
	const scheduled = useRef(false)
	const [isRight, setIsRight] = useState(true)
	const [isEnter, setIsEnter] = useState(false)
	
	const handler = function() {
		if (!scheduled.current) {
			window.requestAnimationFrame(()=> {
				if (!ref.current)
					return
				const {x,width} = ref.current.getBoundingClientRect()	
				if (x+(width/2) > document.body.offsetWidth/2) {
					if (!ref.current.classList.contains('right'))
						ref.current.classList.add('right')
					setIsRight(true)
				} else {
					if (ref.current.classList.contains('right'))
						ref.current.classList.remove('right')
					setIsRight(false)
				}
				
				scheduled.current = false;
			});
			scheduled.current = true;
		}
	}

	const setEnter = () => setIsEnter(true)

	const setLeave = () => setIsEnter(false)

	useLayoutEffect(_=>{
		const menu = ref.current;
		if (!menu) {
			return;
		}

		const scrollTarget = app.settings.is_ionic ? document.getElementById('app') : window
		setTimeout(_=>{
			if (ref.current === menu) {
				scrollTarget.addEventListener('scroll', handler)
				scrollTarget.addEventListener('resize', handler)
				menu.addEventListener('mouseenter', setEnter)
				menu.addEventListener('mouseout', setLeave)
				handler()
			}
		},0)
		
		return () => {
			scrollTarget.removeEventListener('scroll', handler)
    		scrollTarget.removeEventListener('resize', handler)
			menu.removeEventListener('mouseenter', setEnter)
			menu.removeEventListener('mouseout', setLeave)
	    };
	},[ref,ref.current])
	
	return <Ref innerRef={ref}>
			<Dropdown 
					item
					trigger={trigger} 
					icon='dropdown' 
					simple
					onClose={handler}
					onClick={handler}
					>
					<Dropdown.Menu className={cls} size="large">
						{ typeof items == 'function' ? items(isRight,isEnter) : items }
					</Dropdown.Menu>
				</Dropdown>
		</Ref>
}

export default SideAwareDropdown
