import React from 'react';
import SectionHeader from 'views/med/SectionHeader';

const DocMedicareWarn = ({cart, enabled, visible }) => {
    if (!enabled || !visible)
        return null
    return <React.Fragment>

            <SectionHeader>Cost</SectionHeader>

            {cart.content().total.value>0 &&
                <h3>Unfortunately, you do not meet the Australian government criteria for a bulk billed Telehealth consult (note, eligibility criteria has changed recently).
                    <br /> To be eligible you need a valid Medicare card and be located in lockdown area.
                    <br /> The full fee for a Telehealth service is ${cart.DOCCONS_PRICE}.</h3>
            }

            {!cart.content().total.value &&
                <h3>You are eligible for a Medicare rebate. There will be no out of pocket fee for this consultation.</h3>
            }

            <br />
        </React.Fragment>;
}
 
export default DocMedicareWarn;