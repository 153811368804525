import React, { useEffect, useState, useCallback } from 'react';
import { Button, Input, Form, Modal, Message } from 'semantic-ui-react';
import { useToggleState, useProfile } from 'xAppLib/Hooks';
import API_service from 'xAppLib/providers/API_service';
import { Link } from 'react-router-dom';

const DEBUG = false;

const CodeInput = ({ onSubmit, error: { err_msg } = {} }) => {
	const [verificationCode, setVerificationCode] = useState('');
	return (
		<>
			<div className="flex flex-row items-stretch">
				<div className="flex-grow">
					<Form.Input
						name="verificationCode"
						required={true}
						value={verificationCode}
						onChange={(e) => {
							setVerificationCode(e.target.value);
						}}
						placeholder={'Enter verification code'}
						fluid
						control={Input}
						autoFocus
					/>
				</div>
				<div className="flex items-stretch pl-2">
					<Button onClick={() => onSubmit(verificationCode)}>Verify</Button>
				</div>
			</div>
			{err_msg && <Message negative header={err_msg} />}
		</>
	);
};

const SMSVerification = () => {
	const [IsOpen, setIsOpen] = useState(false);
	const [reason, setReason] = useState(false);
	const [header, setHeader] = useState(false);
	
	const [callback, setCallback] = useState(null);

	const [isLoading, turnIsLoadingOn, turnIsLoadingOff] = useToggleState();
	const [error, setError] = useState();
	const [payload, setPayload] = useState();
	const [profile] = useProfile();

	const phoneNumber = profile?.mobile && '+*******' + profile?.mobile.replace(/ /g, '').substring(6);

	useEffect(() => {
		app.on(app.events.SMS_CONFIRM, showSMSConfirm);
		return () => {
		  app.off(app.events.SMS_CONFIRM, showSMSConfirm);
		};
	  }, []);

	const showSMSConfirm = useCallback(({ reason, header }, callback) => {
	setReason(reason);
	setHeader(header);
	setCallback(callback);
	setIsOpen(true);
	}, []);

	const handleSendVerificationCode = useCallback(async () => {
		const results = await API_service.api_promise('phone/sendCode', { reason });
		if (results.res === 'ok') {
		  setPayload(results.payload);
		} else {
		  setError(results);
		}
	}, [reason]);

	const handleVerifyCode = useCallback(async (code) => {
		turnIsLoadingOn();
		const results = await API_service.api_promise('phone/verify', { payload, code });
		if (results.res === 'ok') {
		  callback.resolve({ ...results.payload, verified: true });
		  setIsOpen(false);
		} else {
		  setError(results);
		  DEBUG && console.log({ error });
		}
		turnIsLoadingOff();
	}, [callback, payload, turnIsLoadingOff]);

	useEffect(() => {
		if (phoneNumber && IsOpen) {
			setError()
			handleSendVerificationCode();
		}
	}, [phoneNumber, IsOpen]);

	const handleClose = useCallback(() => {
		callback.resolve({ verified: false });
		setIsOpen(false);
	}, [callback]);

	return (
			<Modal open={IsOpen} closeIcon onClose={() => handleClose()}>
				<Modal.Header>{header}</Modal.Header>
				<Modal.Content>
					{phoneNumber ? (
						<>
							<p>
								Code has been sent to your nominated mobile {phoneNumber}. <br />
								Didn't get it?
								<span className="underline cursor-pointer" onClick={handleSendVerificationCode}> Click here </span>
								to resend.
							</p>
							<Form error={!!error} loading={isLoading}>
								<CodeInput error={error} onSubmit={handleVerifyCode} />
							</Form>
						</>
					) : (
						<p>
							Please provide a mobile number in your profile to start validation.{' '}
							<Link className="underline" to="/profile" onClick={() => handleClose()}>
								Click here to edit your profile
							</Link>
						</p>
					)}
				</Modal.Content>
			</Modal>
	);
};

export default SMSVerification;
