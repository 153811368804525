import React, { useCallback, useState } from 'react';
import { Modal, Search } from 'semantic-ui-react';
import ImagePickerPhoto from '../Photos/ImagePickerPhoto';
import debounce from 'xAppLib/libs/debounce';
import { DataConnector, ListPagination } from 'xAppLib/DataTable';
import ImagesPreview from '../Photos/ImagesPreview';
import media_model, { MEDIA_TYPE } from '../../models/media_model';
import { useEffect } from 'react';

const SECOND = 1000;
const DEFAULT_SEARCH = { value: '', res: [], isLoading: false };

const ImagePicker = ({ open, onClose, onImageClick, bucket, type = MEDIA_TYPE.PAGE }) => {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [refresh, setRefresh] = useState();
	const [search, setSearch] = useState(DEFAULT_SEARCH);
	const { value, res, isLoading } = search;

	useEffect(() => {
		if (!open) {
			setSearch(DEFAULT_SEARCH);
		}
	}, [open]);

	const fetchNames = useCallback(
		debounce(async (query) => {
			const { media } = await media_model.find_media(query, type).catch(() => setSearch((prev) => ({ ...prev, isLoading: false, res: [] })));
			setSearch((prev) => ({ ...prev, isLoading: false, res: media }));
			setRefresh(Date.now());
		}, SECOND),
		[]
	);

	const onPhoto = () => {
		setRefresh(Date.now());
	};

	const handleSearchChange = (e, { value }) => {
		setSearch({ value, isLoading: !!value });
		if (value) {
			fetchNames(value);
		} else {
			setRefresh(Date.now());
		}
	};

	const fetchMedia = async (filters, page, pageSize) => {
		const { media, total } = await media_model.get_media_list({ filters, limit: pageSize, offset: (page - 1) * pageSize, total_count: true, type });

		return { data: media, total };
	};

	const getData = async (...args) => {
		return res?.length && value ? { data: res, total: res.length } : await fetchMedia(...args);
	};

	return (
		<Modal open={open} onClose={onClose} size="fullscreen">
			<Modal.Header as="h1" content="Select or upload images" />
			<Modal.Content>
				<div className="flex flex-col gap-8 mb-8">
					<div>
						<ImagePickerPhoto target={bucket} onPhoto={onPhoto} enableImageUpload={true} img_fn="media" multiple uploadFullSizeImages include_file_name />
					</div>
					<Search
						open={!!(isLoading || (value && res?.length === 0))}
						placeholder="Search for images"
						input={{ fluid: true, autoComplete: 'false', iconPosition: 'left' }}
						loading={isLoading}
						noResultsMessage={isLoading ? 'Searching...' : 'No results found.'}
						onSearchChange={handleSearchChange}
						value={value}
						fluid
					/>
				</div>
				<DataConnector pageSize={pageSize} page={page} refresh={0} data={getData} refresh={refresh}>
					{(data, loading, pages, total, pageSize) => (
						<>
							<ListPagination
								page={page}
								pages={pages}
								pageSize={pageSize}
								loading={loading}
								data={data}
								total={total}
								boundaryRange={3}
								showPageSizeSelector={true}
								onPageSizeChange={(pageSize) => setPageSize(pageSize)}
								onPageChange={(page) => setPage(page)}
							/>

							<ImagesPreview list={data} isLoading={loading} onImageClick={onImageClick} scrollable />
						</>
					)}
				</DataConnector>
			</Modal.Content>
		</Modal>
	);
};

export default ImagePicker;
