import React, { PureComponent } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Container,
		Segment,
		Header,
		Confirm,
		TextArea,
		Form
	} from 'semantic-ui-react'


const Attach = ({row, index, onTogglePhotos = ()=>{}, onToggleAttachment = ()=>{}}) => (
			<>
				
						{row.phts?.length > 0 && 
							<>
								<Button
									// link 
									icon='camera' 
									content={row.phts?.length}
									// style={{float:'right'}} 
									onClick={_=>onTogglePhotos(index)} 
								/> 
								<br />
							</>
							|| null
						}
						
						{row.attch && 
							<Button
								// link 
								icon='attach' 
								content={row.attch?.length}
								
								// style={{float:'right'}} 
								onClick={_=>onToggleAttachment(index)} 
							/> 
							|| null
						}
				</> 
);



export default Attach
