import React from 'react'

import db_lib from 'xAppLib/libs/db_lib';
import API_service from 'xAppLib/providers/API_service'
import FieldActBtns from 'views/UIelems/fields/ActBtns'


const non_breaking_br = <span style={{display:'block', verticalAlign:'top'}}>{" \n "}</span>
const Indentation = ({ children }) => <div className='ml-3'>{children}</div>

const _ORDER_FIELDS = [
						{
							name: "name",
							type: "text",
						},
						{
							name: "email",
							type: "text",
						},
						{
							name: "address",
							type: "address_new",
							placeholder: "Type address to search...",
							mode: 'components',
							components:"full_address",
							title: 'Location based on your primary address',
						},
						// {
						// 	name: "payment_name",
						// 	type: "text",
						// },
						// {
						// 	name: "payment_ccnum",
						// 	type: "text",
						// },
						// {
						// 	name: "payment_ccexp",
						// 	type: "text",
						// },
						// {
						// 	name: "payment_cccvc",
						// 	type: "text",
						// },
		];

const _LIST_FLDS_ADMIN = [
						{
							name: 'Tnum',
							jpath: 'tnum',
						},
						{
							name: 'Type',
							jpath: 'type',
						},
						{
							name: 'Invoice/Purchase order',
							jpath: 'tid',					
						},
						{
							name: 'Time',
							jpath: 'cre_tm',
							type: 'time',
						},

						{
							name: 'Basket',
							type: 'compound',
							parts: [
								{
									name: 'basket',
									jpath: 'prods.sale_prods'
								},
								{
									name: 'form',
									jpath: 'data.input.form'
								},
							],
							template : ({basket, form}) => <React.Fragment>
											{ basket&&basket.map( (p,i) => <span key={'bsk'+p+i}>{p.prod&&p.prod.name} - {p.amnt} {p.note && <Indentation>Note: {p.note}</Indentation>} {p.prod.lot && <Indentation>Lot: {p.prod.lot}</Indentation>}{non_breaking_br}</span> ) }
											{form.invoice_num && <>Inv # {form.invoice_num}<br/></>}
											{form.note && <>Note: {form.note}<br/></>}
											{form.notify_list && <>Notify: {form.notify_list?.join(',')}<br/></>}

										</React.Fragment>
						},

						{
							name: 'Buyer',
							type: 'compound',
							parts: [
								{
									type: 'or',
									name: 'org',
									jpath: 'orgs.buy_org.name',
									jpath1: 'orgs.buy_org.org.name',
								},
								{
									name: 'usr_name',
									jpath: 'data.usr.name',
									// type: 'show_more'
								},
								{
									name: 'usr_email',
									jpath: 'data.usr.email',
									// type: 'show_more'
								},
								
							],
							template : ({org,usr_name,usr_email})=> <React.Fragment>{org}{non_breaking_br}{usr_name} {usr_email}</React.Fragment>
						},

						{
							name: 'Supplier',
							type: 'compound',
							parts: [
								{
									type: 'or',
									name: 'org',
									jpath: 'orgs.supp_org.name',
									jpath1: 'orgs.supp_org.org.name',
								},
								{
									type: 'or',
									name: 'supp_cont',
									jpath: 'orgs.supp_org.contact',
									jpath1: 'orgs.supp_org.org.contact',
									// type: 'show_more'
								},
								{
									type: 'or',
									name: 'supp_email',
									jpath: 'orgs.supp_org.email',
									jpath1: 'orgs.supp_org.org.email',
									// type: 'show_more'
								},
								
							],
							template : ({org,supp_cont,supp_email})=> <React.Fragment>{org}{non_breaking_br}{supp_cont} {supp_email}</React.Fragment>
						},

						{
							name: 'Payment',
							type: 'compound',
							parts : [
								{
									name: 'paid',
									jpath: 'pay.charge.orderInformation.amountDetails.totalAmount',
									// jpath: 'pay.basket.price',
								},
								// {
								// 	name: 'paid',
								// 	jpath: 'cado.charge.paid',
								// 	type: 'bool',
								// },
								{
									name: 'status',
									jpath: 'pay_status',
								},
								{
									name: 'env',
									jpath: 'pay.charge.PAY_ENV',
								},
							],
							template : ({price, paid, status, env}) => paid && <React.Fragment>
												${price} {paid*1} {non_breaking_br}
												{status!='paid' && status ||''} {non_breaking_br}
												{env!= 'PROD' && env ||''} 
											</React.Fragment>
						},

						{
							name: 'Basket',
							type: 'compound',
							parts : [
								{
									name: 'basket',
									jpath: 'pay.basket',
								},
							],
							template : ({basket}) => basket?.price && <>
												<i>price coeff: {basket.price_coeff} </i><br/>
												<ul>{basket.prods.map((p,i) => <li key={'bsk'+p+i}> - {p.prod.name} {p.amnt}x{p.price}= ${p.tot_price}</li>)}</ul>
												{basket?.subtotal ? "products" : "sub total"}: ${basket.price} <br/>
												delivery: ${basket.delivery} <br/>
												{basket?.subtotal && <>sub total: ${basket.subtotal} <br/></>}
												GST: ${basket.gst} <br/>
												total: ${basket.tot_price} <br/>
											</> || ''
						},

						// {
						// 	name: 'Price',
						// 	jpath: 'pay.basket.price',
						// },

						{
							name: 'Status',
							jpath: 'status',
						},

					];

const _LIST_FLDS_BUYER = [
						{
							name: 'Ord num',
							jpath: 'tnum',
						},
						{
							name: 'Type',
							jpath: 'type',
						},
						{
							name: 'Invoice/Purchase order',
							jpath: 'tid',					
						},
						{
							name: 'Time',
							jpath: 'cre_tm',
							type: 'time',
						},

						{
							name: 'Basket',
							type: 'compound',
							parts: [
								{
									name: 'basket',
									jpath: 'prods__sale_prods'
								},
							],
							template : ({basket}) => <React.Fragment>
											{ basket&&basket.map&&basket.map( (p,i) => <div key={'bsk'+p+i}>{p.prod&&p.prod.name} - {p.amnt} {p.note && <Indentation>Note: {p.note}</Indentation>} {p.prod.lot && <Indentation>Lot: {p.prod.lot}</Indentation>}</div> ) }
										</React.Fragment>
						},

						{
							name: 'Buyer',
							type: 'compound',
							parts: [
								{
									name: 'org',
									jpath: 'orgs__buy_org__org__name'
								},
								{
									name: 'usr_name',
									jpath: 'data__usr__name',
									// type: 'show_more'
								},
								{
									name: 'usr_email',
									jpath: 'data__usr__email',
									// type: 'show_more'
								},
								
							],
							template : ({org,usr_name,usr_email})=> <React.Fragment>{org}<br/>{usr_name} {usr_email}</React.Fragment>
						},

						// {
						// 	name: 'Supplier',
						// 	type: 'compound',
						// 	parts: [
						// 		{
						// 			name: 'org',
						// 			jpath: 'orgs.supp_org.name'
						// 		},
						// 		{
						// 			name: 'supp_cont',
						// 			jpath: 'orgs.supp_org.contact',
						// 			// type: 'show_more'
						// 		},
						// 		{
						// 			name: 'supp_email',
						// 			jpath: 'orgs.supp_org.email',
						// 			// type: 'show_more'
						// 		},
								
						// 	],
						// 	template : ({org,supp_cont,supp_email})=> <React.Fragment>{org}<br/>{supp_cont} {supp_email}</React.Fragment>
						// },

						{
							name: 'Payment',
							type: 'compound',
							parts : [
								{
									name: 'price',
									jpath: 'pay__basket__price',
								},
								// {
								// 	name: 'paid',
								// 	jpath: 'cado.charge.paid',
								// 	type: 'bool',
								// },
								{
									name: 'status',
									jpath: 'pay_status',
								},
								{
									name: 'env',
									jpath: 'pay__charge__PAY_ENV',
								},
							],
							template : ({price, paid, status, env}) => price && <React.Fragment>
												${price} {paid} <br/>
												{status} 
											</React.Fragment>
						},

						// {
						// 	name: 'Price',
						// 	jpath: 'pay.basket.price',
						// },

						{
							name: 'Status',
							jpath: 'status',
						},

					];

const _LIST_FLDS_SUPPLIER = [
						{
							name: 'Ord num',
							jpath: 'tnum',
						},
						{
							name: 'Type',
							jpath: 'type',
						},

						{
							name: 'Time',
							jpath: 'cre_tm',
							type: 'time',
						},

						{
							name: 'Basket',
							type: 'compound',
							parts: [
								{
									name: 'basket',
									jpath: 'prods__sale_prods'
								},
							],
							template : ({basket}) => <React.Fragment>
											{ basket&&basket.map&&basket.map( (p,i) => <div key={'bsk'+p+i}>{p.prod&&p.prod.name} - {p.amnt} {p.note && <Indentation>Note: {p.note}</Indentation>} {p.prod.lot && <Indentation>Lot: {p.prod.lot}</Indentation>}</div> ) }
										</React.Fragment>
						},

						{
							name: 'Buyer',
							type: 'compound',
							parts: [
								{
									name: 'org',
									jpath: 'orgs__buy_org__org__name'
								},
								{
									name: 'usr_name',
									jpath: 'data__usr__name',
									// type: 'show_more'
								},
								{
									name: 'usr_email',
									jpath: 'data__usr__email',
									// type: 'show_more'
								},
								
							],
							template : ({org,usr_name,usr_email})=> <React.Fragment>{org}<br/>{usr_name} {usr_email}</React.Fragment>
						},

						{
							name: 'Supplier',
							type: 'compound',
							parts: [
								{
									name: 'org',
									jpath: 'orgs__supp_org__org__name'
								},
								{
									name: 'supp_cont',
									jpath: 'orgs__supp_org__org__contact',
									// type: 'show_more'
								},
								{
									name: 'supp_email',
									jpath: 'orgs__supp_org__org__email',
									// type: 'show_more'
								},
								
							],
							template : ({org,supp_cont,supp_email})=> <React.Fragment>{org}<br/>{supp_cont} {supp_email}</React.Fragment>
						},

						// {
						// 	name: 'Payment',
						// 	type: 'compound',
						// 	parts : [
						// 		{
						// 			name: 'price',
						// 			jpath: 'pay__basket__price',
						// 		},
						// 		// {
						// 		// 	name: 'paid',
						// 		// 	jpath: 'cado.charge.paid',
						// 		// 	type: 'bool',
						// 		// },
						// 		{
						// 			name: 'status',
						// 			jpath: 'pay_status',
						// 		},
						// 		{
						// 			name: 'env',
						// 			jpath: 'pay__charge__PAY_ENV',
						// 		},
						// 	],
						// 	template : ({price, paid, status, env}) => price && <React.Fragment>
						// 						${price} {paid} <br/>
						// 						{status} 
						// 					</React.Fragment>
						// },

						// {
						// 	name: 'Price',
						// 	jpath: 'pay.basket.price',
						// },

						{
							name: 'Status',
							jpath: 'status',
						},

					];


const TRANS_STATUS_BTNS = {
	'edit_note': [
		{ n: 'edit note', a: 'trans note', cust: 'stat_w_msg', pl_hld: 'Add Note', msg_init: r => r?.note, c: 'olive', i: 'write' },
	]
}	

const _PAYUS_FIELDS = [
	{
		name: 'invoice_num',
		label:'Invoice Number', 
		type: 'text',
	},
	{
		name: 'amount',
		label:'Amount', 
		type: 'text',
		pattern: /^[1-9][0-9]*[\.]?[0-9]?[0-9]?$/,
		validation_message: 'The amount can only be a two-digit decimal or integer (123, 1.23), cannot be 0'
	},
	{
		name: 'note',
		label:'Note', 
		type: 'textarea',
	},
	{
		name: 'notify_list',
		label:'Notify List (separate multi email recipients with comma)', 
		type: 'text',
		valid_not_required: true,
	},
];

const _MODEL_NAME = 'trans_model';

const _USE_DATA = 'API'
const _API_NAME = 'trans'

export const trans_type = {
	supp_ord: 'Supplier Order',
	prod_sale: 'Sale',
	pay_us: 'Pay Us',
}

export const trans_status = {
	prod_sale_form: 'Sale Form Complete',
	ord_recv: 'Order Received',
	ord_sent: 'Order Sent',
	supp_form_sent: 'Request Sent to Supplier',
	pay_us_sent: 'Payment Sent to Us',
	pay_us_form: 'Payment Sent to Us',
}
// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class trans_model extends db_lib {

	static get ORDER_FIELDS() { return _ORDER_FIELDS; }
	static get LIST_FLDS_ADMIN() { return _LIST_FLDS_ADMIN; }
	static get LIST_FLDS_BUYER() { return _LIST_FLDS_BUYER; }
	static get LIST_FLDS_SUPPLIER() { return _LIST_FLDS_SUPPLIER; }
	
	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }

	static get PAYUS_FIELDS() { return _PAYUS_FIELDS; }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async prod_sale_form (data) {

		// console.log('trans_model :: prod_sale_form sending ', data);

		return await trans_model.get_record(null, null, {act:'prod_sale_form', data});

	}
	

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------
	
	static async load_transactions (data) {

		// console.log('trans_model :: load_transactions sending ', data);

		return await trans_model.get_record(null, null, {act:'trans_list', ...data});
		// return new Promise((resolve,reject)=>{
		// 	setTimeout(_=>{resolve({listTrans:[1,2,3,4],total:4})},2000)
		// })

	}
	


	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static trans_btns (row) {
		let btns_arr = []
		btns_arr.push( ...TRANS_STATUS_BTNS['edit_note'] );
		return btns_arr
	}

	static trans_dict(stat) {
		return stat !== null && <pre><span className="text-sm leading-3 font-normal">{stat}</span></pre>
	}

	static cust_flds = () => {
		return [
			{
				name: 'Notes',
				btns_list_func: trans_model.trans_btns,
				btns_stat_param: 'note',
				btns_stat_dict: trans_model.trans_dict,
				act_run: async (b, row, mod) => {
					const result = await API_service.load_data(
						b.au || 'trans/upd_note',
						{ act: 'upd_note', tid: row.tid, val: b.p.msg, mod }
					)
					return result
				},
				template: FieldActBtns
			}
		].filter(Boolean)
	}

	static async pay_us_form (data) {
		return await trans_model.get_record(null, null, {act:'pay_us_form', data})
	}
}
