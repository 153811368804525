//	------------------------	------------------------	------------------------
//	Description: Counter to show a number
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const default_style = {

    display: 'inline-flex',
    backgroundColor: 'red',
    color: 'white',
    fontSize: '90%',
    fontWeight: 'bold',

    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '100%',
    marginLeft: '5px',

    width: '25px',
    height: '25px',

}


const Counter = ({value = 0, hide_zero, custom_style}) => {
    const show_counter = !((value == 0 || value == '0') && hide_zero)
    if (!show_counter) return null
    return <div style={custom_style || default_style}>
            {value}
        </div> 
}
 
export default Counter;
