import React from "react";
import PropTypes from "prop-types";

const Processes = (props) => {
  const { process, imgTextSpace, titleStyle } = props;

  return (
    <div
      className={`grid md:grid-cols-${process.length} grid-cols-1 md:text-left text-center`}
    >
      {" "}
      {process.map((step) => {
        return (
          <div className={`flex flex-col m-2`} key={step.title}>
            <img
              className="h-20 w-20 mx-auto"
              src={step.image}
              alt={step.title}
            />
            <div className={`flex flex-col items-start ${imgTextSpace} p-5`}>
              <h3 className={`process w-full text-center ${titleStyle}`}>{step.title}</h3>
              <p className="text-base w-full">{step.text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Processes.propTypes = {
  process: PropTypes.array.isRequired,
  imgTextSpace: PropTypes.string,
  titleStyle: PropTypes.string,
};

export default Processes;
