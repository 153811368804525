import React from 'react';
import moment from 'moment';
import API_service from '../../xAppLib/providers/API_service';
import { Label } from 'semantic-ui-react';

const AUDIT_PRACTITIONER_FIELDS = [
	{
		name: 'Number',
		jpath: 'audit_num',
	},
	{
		name: 'Name',
		jpath: 'name',
	},
	{
		name: 'Status',
		jpath: 'status',
	},
	{
		name: 'Expiry Date',
		template : ({ value: { expiry_date } }) => expiry_date && moment(expiry_date).format("DD/MM/YYYY") || "N/A",
    },
	{
		name: 'Tags',
		template : ({ value: { expired, expiring_soon } }) => 
            expired && <Label color='red'>Expired</Label> || expiring_soon && <Label>Expiring Soon</Label> || null,
	},
];

const AUDIT_FIELDS = [...AUDIT_PRACTITIONER_FIELDS]
AUDIT_FIELDS.splice(2, 0, {
    name: 'Owner',
    template : ({ value: { org, user } }) => (org || user)?.name,
});

const get_audit_list = async (data) =>
    await API_service.load_data(`cosmetics/audit/get_audit_list`, data);

const get_audit = async ({ audit_num }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}`);

const create_audit = async ({ type }) =>
    await API_service.load_data(`cosmetics/audit/create`, { type });

const update_audit_item = async ({ audit_section_id, audit_item_id, audit_num, answer  }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/section/${audit_section_id}/item/${audit_item_id}`, { answer });

const toggle_audit_item_not_applicable = async ({ audit_section_id, audit_item_id, audit_num }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/section/${audit_section_id}/item/${audit_item_id}/not_applicable`, {});

const add_audit_evidence = async ({ audit_section_id, audit_item_id, audit_num, evidence  }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/section/${audit_section_id}/item/${audit_item_id}/evidence/add`, { evidence });

const remove_audit_evidence = async ({ audit_section_id, audit_item_id, audit_num, evidence  }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/section/${audit_section_id}/item/${audit_item_id}/evidence/remove`, { evidence });

const submit_audit = async ({ audit_num, }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/submit`);

const update_audit_status = async ({ audit_num, status, feedback, }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/status`, { status, feedback, });

const update_audit_item_status = async ({ audit_section_id, audit_item_id, audit_num, status, feedback }) =>
    await API_service.load_data(`cosmetics/audit/${audit_num}/section/${audit_section_id}/item/${audit_item_id}/status`, { status, feedback });

const get_my_current_audits = async () =>
    await API_service.load_data(`cosmetics/audit/current`);

export default {
    AUDIT_PRACTITIONER_FIELDS,
    AUDIT_FIELDS,
	get_audit,
    get_audit_list,
	create_audit,
	submit_audit,
	update_audit_item,
	add_audit_evidence,
	remove_audit_evidence,
	update_audit_item_status,
	toggle_audit_item_not_applicable,
	update_audit_status,
    get_my_current_audits,
}