import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import UserArea from '../Users/UserArea'


const LoginPage = (props) => {
      const {show='login', sid, enc} = useParams()
     return <>
          <Helmet>
          	<title>Login</title>
          </Helmet>
        <UserArea size='large'  show={show} {...props} sid={sid} enc={enc} />
        </>
}


export default LoginPage
