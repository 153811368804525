import localstorage_database from 'xAppLib/providers/localstorage_database.js';

export default class utm_model {

	static init(){
		/**
		 * Using localstorage to enable multi-touch marketing* for the following user journey
		 * 1.	User visits via facebook/google/3rd party ad
		 * 2.	User browses the app
		 * 3.	User drops off (closes browser window, navigates away from app)
		 * 4.	User revists the app
		 * 5.	User registers
		 * 6.	User makes a purchase
		 * 
		 * 
		 * If runtime storages are used, attribution is lost after step 3 because the runtime object won't have the utm tags from step 2
		 * 
		 * * In Wicked Reports, every action is a click and every click is an atomic event.
		 * * Wicked Reports doesn't have capability to attribute conversions across space & time. UTMs need to be maintained and manually passed to Wicked Reports API for every click
		 * ** For more reference on the inner workings of Wicked Reports, refer https://docs.wickedreports.com/#clicks-insert-clicks-post
		 */
		window.location.search.includes('utm_campaign') && localstorage_database.update_record('utm', Object.fromEntries(new URLSearchParams(window.location.search)));
	}

	static get utms(){
		return localstorage_database.get_record('utm');
	}
	
}