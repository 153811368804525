import React, { useState, useEffect, useRef } from 'react'
import {Popup, Menu } from 'semantic-ui-react'
import UserArea from './UserArea'
import { Link, useLocation } from 'react-router-dom'
import MessageBar from '../UIelems/MessageBar'
import app from '../../amp-app'
import { cls as cls } from 'views/NUI/utils'
import { useExperiment } from '../Hooks'

const LoginPopup = ({signup}) => {

	const [menuType, setMenuType] = useState(signup && 'signup' || 'login')
	const [open, setOpen] = useState(false)
	const location = useLocation();
	const msgRef = useRef();
	const vSignup = useExperiment('ex3666');
	const signupExp = vSignup?.n === 'popups_hidden';

	useEffect(()=>{
		vSignup?.f()
	},[vSignup])

	useEffect(() => {
		app.on(app.events.REQUEST_LOGIN, () => menuOpen('login'))
		app.on(app.events.REQUEST_JOIN, () => menuOpen('signup'))
		app.on(app.events.REQUEST_PASS_RESET_VERI, () => menuOpen('reset'))
		app.on( app.events.MFA_CONFIRM, () =>  setOpen(false))
		app.on( app.events.SHOW_POPUP, () =>  setOpen(false))
		app.on( app.events.SHOW_MESSAGE, () =>  viewMsg())

	},[]) 

	useEffect(() => {
		location.pathname.includes('/login') && app.trigger(app.events.REQUEST_LOGIN)
		location.pathname.includes('/signup') && app.trigger(app.events.REQUEST_JOIN)

	    if(app.settings.icosm && location.pathname.includes('/join')) { setOpen(false)}
		//open ? document.body.classList.add('fixed-bg') : document.body.classList.remove('fixed-bg');	},[open])
	},[location.pathname]) 

	const menuOpen = (type) => {
		setMenuType(type)
		setOpen(true)
	}

	const handleOnOpen = (_, { open }) => {
		setOpen(open);
	}

	const handleOnCLose = (e, { open }) => {
		// Prevent the popup from closing when a modal within is closed
		const closePopup = (elm) => {
			const modalClick = (elm.className?.baseVal ?? elm.className).includes('dimmable');
			if (modalClick || !elm.parentElement) return !modalClick;
			return closePopup(elm.parentElement);
		};

		if (closePopup(e.target.parentElement)) {
			if (app.state.in_sgup_st2) {
				app.state.in_sgup_st2 = false;
				!signupExp &&
					app.trigger(app.events.SHOW_POPUP, {
						txt: 'Medical History',
						pn: 'medical-history-and-chro-cond',
						noCloseBtn: true,
						cst_modal: true
					});
			}
			setOpen(open);
		}
	};
	
	const viewMsg = () => {
		msgRef.current?.scrollIntoView({ behavior: 'smooth', block:'nearest' })
	}

	return (
		<Popup  
			trigger={<Menu.Item
						className={cls(open && 'active', app.state.in_sgup_st2 && open && 'pointer-events-none', app.runtime.mode=='iscr' && "ui primary button rounded-md", signup ? "signup" : "sign-in")}
						content={app.settings.is_cmg && 'Sign In' || app.state.in_sgup_st2 && open && 'Profile' || signup && 'Join' || 
						app.settings.icosm && <><span className="hidden md:block">Log in</span><span className="block md:hidden"><img src="https://storage.googleapis.com/icosm/2/ic-login-mobile-button.svg"/></span></>||
						'Log in'}
						as={Link}
						to={'#'}
						key={'header_menu_mob_login'}
						data-testid={"link-login"} />
						}
			on='click'
			open={open}
			onOpen={handleOnOpen}
			onClose={handleOnCLose}
			className="popup-login"
			popper={{id: 'popup-login-cont'}}
			position='bottom right'
			content={<><div ref={msgRef}><MessageBar  login={true} /></div><UserArea show={menuType} signupExp={signupExp}/></>}
		/>
	)
}

export default LoginPopup


		