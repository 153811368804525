import React, {Component} from 'react'
import { Link } from 'react-router-dom'

export default class UniButton extends Component {

	render () {

		const {type, btn_text, click_act, int_url, ext_url, add_class} = this.props

		if (type=='big_act')
			return <button onClick={click_act} className='big-button transition button'>
							<div className="button-padding w-preserve-3d">
								<div className="button-back w-preserve-3d">
									<div className="btn-type">{btn_text}</div>											
								</div>
							</div>					
					</button>
	}
}
