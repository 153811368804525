import React from "react";
import moment from "moment";
import { Button, Container, Header, Icon, List, Popup } from "semantic-ui-react";
import med_model from "../../models/med_model";
import group_by_val_obj from "../../xAppLib/helpers/group_by_val_obj";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import CopyField from "../../xAppLib/UIelems/CopyField";
import { cls } from "../NUI/utils";
import { Link } from "react-router-dom";
import Badge from "../NUI/Badge";
import { useProfile } from "xAppLib/Hooks";

/** @type {Record<Status, string>} */
const COLOURS = {
	current: "green",
	med_only: "orange",
	expired: "red",
	cons_only: "red",
}

/** @type {Record<Status, string>} */
const STATUS = {
	current: "Current",
	med_only: "Med Only",
	expired: "Expired",
	cons_only: "Async Declined",
}

function useMed(med_id) {
	const { data } = useAsync({
		immediate: med_id && med_id !== '*',
		fn: () => med_model.load_med(med_id)
	}, [med_id]);

	return data
}

export function MedName({ med_id }) {
	const data = useMed(med_id)

	if (med_id === '*') {
		return <><b>All Meds</b></>;
	}
	if (data) {
		return <><b>{data.name}</b> <small>{data.size} {data.qnty}</small></>;
	}

	return med_id || 'Unknown Med'
}

const EnrolledMedTile = ({med, basic}) => {
	const med_id = med.enrolled_med
	const [prof] = useProfile()
	const data = useMed(med_id)
	if (!data)
		return null
	const history = prof?.hist || []
	const script = history?.find(h=>(h.med_db_data.m || h.med.id)==med_id)
	let can_reorder = true
	const add_tm = moment(script?.add_tm,"D/M/YY HH:mm:ss")
	if (script) {
		can_reorder = (!script.med_db_data__days_limit*1 || moment().diff(add_tm.startOf('day'), 'days')>(script.med_db_data__days_limit*1-2)) 
	}

	const onClick = (e) => {
		if (!can_reorder) {
			let days = Math.abs(moment().diff(add_tm.startOf('day'), 'days')-(script.med_db_data__days_limit*1-2))+1
			e.preventDefault()
			const msg = `Script for ${script.med_db_data.name} was issued to you on ${add_tm.format('DD/MM/YY')}. We can't prescribe the same medication again for ${days} day${days!=1?'s':''}. Please try again in ${days} day${days!=1?'s':''}.`
			app.confirm("Reorder limit",msg,{basic:true})
		}
	}

	if (basic) {
		return <div className="flex items-center justify-between p-3" key={med?.enrolled_med}>
			<p className="mb-0 font-medium text-is-black text-left">{data.name} {data.size} x{data.qnty}
				{/* <br />
					Generic name: {data?.alt_name} */}
					</p>
			<Link className={cls("ui button basic",{primary:can_reorder})} onClick={onClick} to={`/m/pharm/online/prescription/${med.enrolled_med}`}>Reorder</Link>
		</div>
	}

	return <Link to={`/m/pharm/online/prescription/${med.enrolled_med}`} onClick={onClick} className="grid-col-1">
		<div className="rounded-lg shadow bg-white flex flex-col sm:flex-row justify-between items-center p-3">
			<h4 className="font-bold my-2 md:my-0 md:mr-2 text-nowrap text-ellipsis overflow-hidden">
				{data.name} 
				<small className="text-sm font-normal ml-2">{data.size} x{data.qnty}</small>
			</h4>
			<div className="flex flex-shrink-0 flex-col sm:flex-row  flex-wrap md:flex-nowrap justify-end items-center">
				<Badge small className='mb-2 md:mb-0 text-is-gray-100 bg-is-blue-20 mr-2 h-fit text-center'>Cost: ${app.site_status?.prs_mc['mc_std']}</Badge>
				<Button className="w-fit" primary={can_reorder}>Request a script</Button>
			</div>
	</div>
	</Link>
}
 


export function MedEnrollments({ qualcons, display  }) {
	// We're only interested in showing meds the pt is currently enrolled to, and only the enrolment
	// for the most recent consult. But we want to display the enrolled meds in order and grouped by
	// the consults themselves. So first group by med and take the first consult (which are assumed
	// to be sorted newest to oldest), then flatten back and group by consult.
	const enrolments = qualcons.filter(x => x.qualification_status === 'current');
	const byMed = group_by_val_obj(enrolments, 'enrolled_med') || {};
	const mostRecentConsults = Object.values(byMed).map(consult => consult[0]);
	const byConsult = group_by_val_obj(mostRecentConsults, 'snum') || {};
	const flat = Object.values(byConsult).flat()

	if (Object.keys(byConsult).length === 0) {
		return <p>No enrolled meds</p>;
	}

	return (
		display === 'home' &&
		<div className={cls("gap-[9px] grid grid-cold-1 pb-4 m-3.5",`lg:grid-cols-${flat?.length > 1? '2':'1'}`)}>
			{flat?.map((el, i) => <EnrolledMedTile med={el} key={i} />)}
		</div>
		|| display === 'search' &&
		<Container className="md:!w-[600px] md:!mx-auto">
			<div className='flex flex-wrap justify-center md:justify-start items-center md:w-[600px] mx-auto gap-3 mb-6'>
				<h4 className="mb-0 w-fit">Your medications:</h4>
				<Badge small className='!px-4 text-is-gray-100 bg-is-blue-20'>Cost: ${app.site_status?.prs_mc['mc_std']}</Badge>
			</div>
			<div className="rounded-lg bg-white divide divide-y divide-[#D7DBE0] border border-[#D7DBE0]">
				{flat?.map((el,i) => <EnrolledMedTile basic med={el} key={i} />)}
			</div>
		</Container>
		||
		<List divided relaxed style={{ maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
			{Object.entries(byConsult).sort(([x_snum], [y_snum]) => x_snum - y_snum).map(([snum, consults]) => (
				<List.Item key={snum}>
					<List.Header>
						<small className="font-normal">{moment(consults[0].tm).format('D/M/YY')}</small>
					</List.Header>

					{consults.sort((x, y) => x.enrolled_med.localeCompare(y.enrolled_med)).map(consult => (
						<List.Content key={consult.enrolled_med}>
							<List.Description>
								<MedName med_id={consult.enrolled_med} />
								{consult.legacy && <> <Icon name="pills" color="grey" /></>}
							</List.Description>
						</List.Content>
					))}

					<List.Description><CopyField val={consults[0].snum || consults[0].sid} /></List.Description>
				</List.Item>
			))}
		</List>
	);
}
/**
 *
 * @param {QualificationStatusIndicatorProps} props
 * @returns {JSX.Element}
 */
export function QualificationStatusIndicator({ qualcons }) {
	const byStatus = group_by_val_obj(qualcons, 'qualification_status') || {};
	const colour = byStatus.current ? COLOURS.current
		: byStatus.cons_only ? COLOURS.cons_only
			: byStatus.med_only ? COLOURS.med_only
				: COLOURS.expired;

	return (
		<Popup trigger={<Icon name="certificate" color={colour} />}
			hoverable
			content={
				<>
					<Header as={'h4'}>App Script Enrolment</Header>
					<MedEnrollments qualcons={qualcons} />
				</>
			}
		/>
	);
}

/**
 * @typedef {Object} QualificationStatusIndicatorProps
 * @property {QualificationStatus} qualcons
 *
 * @typedef {Object} QualificationStatus
 * @property {string} sid - sid of the qualifying consult
 * @property {number=} snum - snum of the qualifying consult (optional for legacy reasons)
 * @property {Status} qualification_status - qualification status of the consult (only current means the pt can access asynchronous scripts)
 * @property {string} tm - ISO formatted date string of the consult
 */

/**
 * @typedef {"current" | "med_only" | "expired" | "cons_only"} Status
 */
