import React, { useState, useEffect } from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { ObjectInspector, chromeLight } from 'react-inspector'
import firebase_database from 'xAppLib/providers/firebase_database'


const LoadShowRTDBJson = props => {

	const { path } = props
	const [display, setDisplay] = useState(false)
	const [data, setData] = useState()

	useEffect(() => {
		if (display)
			return firebase_database.watch_record(path, d => setData(d))
	}, [display])


	return <Popup
		trigger={<Button icon='fire' onClick={_ => setDisplay(true)} />}
		position='left center'
		on='click'
		style={{ height: '80%', width: '80%', minWidth: 700, overflow: 'scroll', maxHeight: '90vh' }}
	>
		{(data) &&
			<ObjectInspector
				data={data}
				expandLevel={1}
				theme={{ ...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 }) }}
			/>}
	</Popup>
}

export default LoadShowRTDBJson
