import React, { Component } from 'react';

import { 
			List, 
			Header,
			Checkbox,
			Button,
			Popup,
			Icon
		} from 'semantic-ui-react'

import { ObjectInspector, chromeLight } from 'react-inspector';

import UniForm from '../UniForm/UniForm'
import UniFormSUI from '../UniForm/UniForm-SUI'

import ShowUserName from '../UIelems/ShowUserName'
// import AddressSearch from 'views/UIelems/AddressSearch';
// import ShowTextObj from 'views/UIelems/ShowTextObj';
import SelectOrg from '../Orgs/SelectOrg'

import ViewEditField from './ViewEditField'

import {DataShow} from 'xAppLib/DataTable'
import moment from 'moment';
import Age from '../../views/patients/Age';

const DEBUG = false

export default class ViewEditDets extends Component {

	constructor(props) {
		super(props);
		DEBUG && console.log('ViewEditDets starts with', props.itm_data, props.det_fields);
	}

	state = {
		mode: this.props.mode || 'view',
		det_fields: this.props.det_fields=="autogen" && this.props.itm_data && this.auto_field_fr_obj(this.props.itm_data) || this.props.det_fields
		// init_val: this.props.val,
		// curr_val: this.props.val,
	}


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	auto_field_fr_obj(o) {
		let f = []
		Object.keys(o).map( k => f.push({ name: k, label: k, type: typeof o[k]=="boolean" && "bool" || typeof o[k]=="number" && "number"  || typeof o[k]=="object" && "json" || "text"  }) )
		DEBUG && console.log('ViewEditDets autogen fields', f);
		return f
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	start_edit() {

		this.setState({
						mode: 'edit',
					})
	}

	// 		--------------------------------		--------------------------------		---------

	cancel_edit() {

		this.setState({
						mode: 'view',
						// curr_val: this.props.val
					})
		
		this.props.onCancel && this.props.onCancel()
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	itm_det_view_render () {

		const { view_data, onSave_dets_fld, read_only, vertical=false, allow_fld_edit=true, allow_full_edit=true, btn_txt_fld_edit, note, extraButtons = null, comparison_fields = {}} = this.props
		const itm_data = view_data || this.props.itm_data;
		const { det_fields } = this.state
		
		const wrapStyle = { display:'flex',alignItems:'baseline',flexDirection:(vertical?'column':'row'), marginBottom:(vertical?'10px':'0px')}
		const headerStyle = {marginRight:(vertical?'0px':'30px'), marginBottom:(vertical?'5px':'0px'), flex:1, textAlign:'right'}
		
		return	<List >
					{itm_data && det_fields && 
						det_fields
							.map( (f, i) => {

								const val = (f.view_path && itm_data[f.name] ? itm_data[f.name][f.view_path] : itm_data[f.name]) // || {}
								return (f.type != 'hidden' && (['bool', 'popup_json_obj'].includes(f.type) || val!=undefined)) && 
									<List.Item className="field" key={'itm_data'+i} style={wrapStyle}>

										<Header as='h5' style={headerStyle}>{f.label}</Header>

										{ 
											//f.type == 'json' && 
											//	<pre style={{flex:3}}>{JSON.stringify(val, null, 2)}</pre> 
											//||
											f.type == 'address_new' && 
												<ViewEditField
													val={(typeof val == 'string' && val || typeof val == 'object' && val.formatted || '-')}
													read_only={true}
													type={f.type}
													style={{flex:3}}
												 /> 
											|| 
											f.type == 'link' && 
												<a href={(f.url_pre||'')+(val||'')+(f.url_post||'')} target='map' style={{flex:3}}>{ (f.url_pre||'')+(val||'')+(f.url_post||'') }</a> 
											|| 
											f.type == 'uid' && 
												<ShowUserName	 style={{flex:3}}	uid = {val}	/>
											||
											f.type == 'onum' &&
												<div style={{flex:3}}>
													{ DEBUG && `${val} - `}
													<SelectOrg
														leafOnly={false}
														onum={val}
														onSelect = { org => onSave_dets_fld(f.name, org.onum) }
														// onSelect={ org => uf_this.handleInputChange({target:{name:f.name, value:org.onum}}) }
														placeholder='Set parent org'
														setBtn={true}
													/>
												</div>
											|| 
											f.type == 'bool' && 
												<>
													<div style = {{flex:3,alignSelf:'center',display:'flex',alignItems:'center'}} >
														<Checkbox	
															checked = {!!val}
															onChange = { (e, d) => onSave_dets_fld(f.name, d.checked) }
															// onChange = { (e, d) => console.log(d.checked) }
															toggle
															readOnly={!onSave_dets_fld}
															style = {{padding:0,alignSelf:'center'}} 
														/>
														<span style={{display:'inline-block',marginLeft:'10px'}}>{val!=undefined&&val.toString()}</span>
													</div>
												</>
											|| 
											['popup_hist', 'show_hist'].includes(f.type) && 
												DataShow.show_data_field(val, f)
											|| 
											f.type == 'popup_json_obj' && 
												<div style = {{flex:3,alignSelf:'center',display:'flex',alignItems:'center'}} >
													<Popup 
														trigger={<Button icon={f.ico||'eye'} compact={f.compact} />}
														content={<ObjectInspector
																	data={ val || itm_data }
																	expandLevel={ f.expLev || 2 }
																	theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
																 />} 
														wide='very'
														on={['click']}
													 />
												</div>
											|| 
											f.format && <ViewEditField
													val={f.format(val)}
													read_only={true}
													type={'text'}
													style={{flex:3}}
												 /> 
										
											||

											f.name === 'sex' && <div style={{flex: "3 1 0%"}}>
												<ViewEditField
													val={val || ''}
													onSave={ (v) => onSave_dets_fld(f.name, v) }
													read_only={f.read_only || read_only || !allow_fld_edit}
													type={f.type}
													style={{flex:3}}
												/>
												{	this.state.mode === 'view'
													&& comparison_fields.ihi_sex
													&& val !== comparison_fields.ihi_sex
													&& (
														<div className="pt-2">
															<span
																className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-s font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
															>
																Our IHI records contain a different patient sex: "{comparison_fields.ihi_sex}"
															</span>
														</div>
													)
												}
											</div>
											||
											f.name === 'dob' && <div style={{flex: 3, fontSize: 20}}><Age dob={val} /></div> ||
											<ViewEditField
													val={val || ''}
													onSave={ (v) => onSave_dets_fld(f.name, v) }
													read_only={f.read_only || read_only || !allow_fld_edit}
													type={f.type}
													style={{flex:3}}
													options={f.options}
												 /> 
										}
									</List.Item>
							}
								)
					}


						{note && <List.Item>
								{note}
							</List.Item>}

				{allow_full_edit && (
					<>
						<List.Item className='mt-10'>
							<Button
								primary
								// onClick={ ()=>this.setState({det_mode: 'edit'}) }
								onClick={(_) => this.start_edit()}
								content={btn_txt_fld_edit || 'Edit'}
							/>
							{extraButtons}
						</List.Item>
					</>
				)}
				</List>

	}

	// 		--------------------------------		--------------------------------		---------

	itm_det_edit_render () {

		const { itm_data, newRecord, onSave_dets, onSave_dets_fld, showCancel=true, cust_fields, onChange, btn_txt_save_edit, btn_txt_canc_edit  } = this.props
		const { size, render_type='fields_list', btn_submit, btn_cancel, vertical, display_errors = false } = this.props
		const { loading } = this.props
		const { valid_not_required=false } = this.props
		const { det_fields } = this.state
		
		return	<UniForm 
										
						// onActForm_ = { (p)=>console.log('UniForm returned', p) }

						onActForm = {async d=> {
							this.setState({loading:true})
							await onSave_dets( d, _=> this.setState({ mode: 'view', loading:false }) )
							this.setState({ mode: 'view', loading:false })
						} }
						
						onChange = { onChange }

						btn_text = "Save"

						className = "itm_det_edit"

						valid_not_required = {valid_not_required}
	
						render_children = {true}

						fields = { det_fields
										.map( (f, i) => ( {
															// param_name: f.name,
															// req_label: "*",
															value: itm_data&&itm_data[f.param_name || f.name],
															...f,
														} )									
											)
								}

						>

				{
					(values, valids, uf_this) => {
						return <React.Fragment>

							<UniFormSUI
								vertical={vertical}
						 		fields={[
							 				...det_fields
							 			]}
								values={values}
								valids={valids}
								uf_this={uf_this}
								show={render_type}
								size = {size}
								cust_fields = {cust_fields}
								valid_not_required = {valid_not_required}
								display_errors={display_errors}
								autocomplete={itm_data?.email && itm_data.email !== app.user?.prof?.email ? 'off' : 'on'}
								btns={
									<div style={{display:'flex',margin:'10px 0'}}>
										{!vertical && render_type!='fields_form' && <div style={{flex:1,marginRight:'30px'}}></div>}
										<div style={!vertical && render_type!='fields_form' && {flex:3,display:'flex'}||{display:'flex'}}>
											<div onClick={ _=> !loading && uf_this.actForm()} >
												{btn_submit ||
													<Button
														loading={typeof loading === 'boolean' ? loading : loading?.toString()}
														// __primary
														type="button"
														size={size} 
														// fluid 
														color='green'
														content={btn_txt_save_edit || (newRecord ? 'Create' : 'Save')}
													 />
												}
											</div>

											{showCancel && <div onClick = { _=> this.cancel_edit() } >
												{btn_cancel ||
													<Button 
														loading={typeof loading === 'boolean' ? loading : loading?.toString()}
														type="button"
														size={size} 
														// fluid 
														secondary 
														content={btn_txt_canc_edit || 'Cancel'}
													 />
												}
											</div>}
										</div>
									</div>
									}
							 />

						</React.Fragment>
					}
				}


				</UniForm>

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render() {

		const { mode } = this.state
		const { newRecord } = this.props

		return  <span style={this.props.style}>
					{ !newRecord && mode=='view' && this.itm_det_view_render() }
					{ (newRecord || mode=='edit') && this.itm_det_edit_render() }
			</span> 
	}

}
