import { useState, useEffect } from "react";

/**
 * Debounce value changes to prevent excessive re-renders
 * @template T
 * @param {T} value - value to debounce
 * @param {number} ms - debounce time in milliseconds
 * @return {T}
 */
export function useDebouncedValue(value, ms) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const id = setTimeout(() => setDebouncedValue(value), ms);
		return () => clearTimeout(id);
	}, [value, ms]);

	return debouncedValue;
}
