import React, { useState, useEffect, useRef } from "react";
import UniForm from "xAppLib/UniForm/UniForm";
import script_model from "models/script_model";
import { Form } from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";
import PaymentForm from "xAppLib/UIelems/PaymentForm";
import gtm from 'xAppLib/providers/gtm';

const Payment = (props) => {
	const { med_data, script_type, req_type, enabled, onMountSection, visible, Section } = props
	const paymentRef = useRef()
	const completeRef = useRef()


	useEffect(()=>{
		if (!enabled || !visible)
			return 
		onMountSection("payment",paymentRef)
		return () => {
			onMountSection("payment",null)
		}
	},[paymentRef.current, enabled, visible])
	


	return (
		<Section>
					{enabled && visible && <><Section.Content end>
					<Section.Header small>Payment details</Section.Header>
						<PaymentForm
							visible={enabled && visible}
							ref={paymentRef}
							acceptPaypal={script_type!=='subcons' && !props.enable_treatment_plan}
							onCompleteInput={(new_card)=>{
								if (completeRef.current !== new_card){
									const event = 'form_step_complete'
									completeRef.current = new_card
									gtm.event(event,`${event}_${new_card?'payment_new':'payment_existing'}`,{})
								} 
							
							}}
						/>
					</Section.Content></>}
		</Section>
	);
};

export default Payment;
