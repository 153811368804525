import React, {Component} from 'react'
import superagent from 'superagent'

// import validator from 'validator'
// import objectAssign from 'object-assign'

import FormFieldHolder from './FormFieldHolder'
import UniButton from '../UIelems/UniButton'
import js_to_json from '../helpers/js_to_json'
import moment from 'moment'

const DEBUG = false;

export default class UniForm extends Component {

	constructor (props) {
		super(props)

		this.state = {
			unsent: true,
			sending: false,
			sent: false,
			sentErr: false,
			sentAttempt: false,
			ready:false,
			values: {},
		}

		this.handleInputChange = this.handleInputChange.bind(this);

	}

	//	------------------------	------------------------	------------------------
	get fieldsIndex() {
		return this.props.fields.reduce((a,fl)=>{ if (fl.name) a[fl.name] = fl; 
			return a
		  },{})
	} 
	
	//	------------------------	------------------------	------------------------

	componentDidMount () {

		const { fields, defaultValues = {}, alwaysCheck, updateOnDefaults } = this.props

		let values =  {}

		fields.forEach( (fl) => {
						if (fl.name) {
							const def = defaultValues[fl.name]
							if (def!==undefined) 
								values[fl.name] = def
							else if (fl.type == 'json-text')  
								values[fl.name] = JSON.stringify(fl.value, null, 4)
							else if (fl.value!=undefined)  
								values[fl.name] = fl.value
							else if (fl.type == 'bool') 
								values[fl.name] = false
							else if (fl.type == 'privacy' && fl.auto)
								values[fl.name] = true
						}
					
					});

		(DEBUG || this.props.debug) && console.log('UniForm init with values', values, 'fields', fields);
		// console.log('checkForm', valids, Object.values(valids), Object.values(valids).reduce( (t, e) => t = t && e, true ));
		
		this.setState({
			values,
			ready:true
		}, _=> {
			if (updateOnDefaults) 
					this.props.onUpdate?.(values)
			if(alwaysCheck)
				this.notifyValids()
		})

		
	}
	
	componentDidUpdate(prevProps, prevState) {
		
		const { fields, defaultValues, alwaysCheck, updateOnDefaults } = this.props
		const { values } = this.state

		if (prevProps.defaultValues!==undefined || defaultValues!==undefined){

			const defUpd = fields.reduce((d,fl)=>{
				if (prevProps.defaultValues?.[fl.name] !== defaultValues?.[fl.name]) {
					d[fl.name] = defaultValues[fl.name]
				}
				return d
			},{})
			
			if (Object.keys(defUpd).length) {
				(DEBUG || this.props.debug) && console.log(this.props.section,'UniForm CDU def new', defUpd);
			
				this.setState(({values})=>({values:{...values,...defUpd}}),_=>{
								(DEBUG || this.props.debug) && console.log(this.props.section,'UniForm CDU def done', this.state.values);
			
								if (updateOnDefaults) 
									this.props.onUpdate?.(this.state.values)
								if(alwaysCheck)
									this.notifyValids()
							})
			} 
		}

		

		if (alwaysCheck && fields !== prevProps.fields) {
			(DEBUG || this.props.debug) &&console.log(this.props.section,'UniForm CDU fields', fields);
			this.notifyValids()
		}
	}
	

	//	------------------------	------------------------	------------------------

	setVals (v) {

		const { fields } = this.props
		let { values } = this.state

		fields.map(  fl => { if (fl.name && v[fl.name]!=null && values[fl.name]!=v[fl.name])  values[fl.name] = v[fl.name] } )

		// console.log('setVals form values', fields, v, this.state.values, values, JSON.stringify(this.state.values) != JSON.stringify(values));
		// console.log('checkForm', valids, Object.values(valids), Object.values(valids).reduce( (t, e) => t = t && e, true ));

		if ( JSON.stringify({...this.state.values}) != JSON.stringify({...values}) )
			this.setState({
				values
			})

	}

	//	------------------------	------------------------	------------------------

	getVal (v) {

		const { values } = this.state

		return values?.[v]
	}

	//	------------------------	------------------------	------------------------

	reset (defaults) {
		this.setState({ values: { ...defaults } })
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	handleInputChange(event, data) {

		const target = data || event.target;
		
		let newValue = target.type == 'checkbox' ? !target.value
								 : 
								target.pattern ? target.value.replace(new RegExp("([^"+target.pattern+"])", "gi") , '-')
									 : 
									target.value

		const fl = Object.values(this.props.fields).filter( fl => fl.name==target.name )?.[0]
		if (newValue && fl?.val_proc) 	newValue = fl.val_proc(newValue)
		
		if (this.props.onChangeActions !== undefined)
		  this.props.onChangeActions(event.target, data, newValue)

		this.setState(state=>{
			
			const values = Object.assign({}, state.values, { [target.name]:newValue })

			const updates = this.props.onChange?.(target.name, newValue,values);
			
			Object.assign(values,updates)

			this.props.onUpdate && this.props.onUpdate(values);
		
			(DEBUG || this.props.debug) && console.log('UniForm :: handleInputChange', data, target, values);
	
			return {...state, values}
		}, _=>{
			if (this.state.sentAttempt !== false)	this.checkForm()
			else if (this.props.alwaysCheck) this.notifyValids()
		})

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	getValids() {

		const { fields, valid_not_required } = this.props
		const { values } = this.state

		
		// (DEBUG || this.props.debug) && console.log('UniForm :: checkForm', fields, fields.map, values);

		let valids = {}
		
		if (valid_not_required)	return {};


		try {
			fields&&fields.map( (fl) => {
				if (fl.name) 
					valids[fl.name] = 
							( _=> { 

								let val = values[fl.name]
								if (typeof val === 'string')
									val = val.trim()

								if (val && fl?.val_proc) 	val = fl.val_proc(val)

								if (fl.pattern && val && !( new RegExp(fl.pattern) ).test(val)) 
									return false

								if (fl.validate_function && (fl.no_empty || val) && !fl.validate_function(val,values))
									return false
								
								if (fl.valid_not_required 
										&& (typeof fl.valid_not_required != 'function'
											 || fl.valid_not_required(values)))
									return true
								
								if (fl.read_only)
									return true
								
								if (typeof fl.type == 'function')
									return !!val
									
								switch (fl.type || 'text') {
									case "hidden":
										return true

									case "object":
										return typeof val == 'object' && val !== null && Object.keys(val).length>0

									case "text":
									case "password":
									case "org":
									case "checkbox":
									case "textarea":
									case "date":
									case "masked":
									case "hidden_validated":
										return !!val && val.length>0
										
									case "address":
									case "address_new":
										if (fl.mode=='components' && !fl.components)
											return !!val?.formatted
										return !!val && val.length>0

									case "dob_date":
										try {
											return val 
													&& /\d{4}\-\d{2}\-\d{2}/.test(val) 
													&& moment(val).isValid()
													&& moment().diff(val, 'years') >= 0
													&& moment().diff(val, 'years') <= 120
										} catch (e) {
											return false
										} 
										
									case "onum":
									case "number":
										return val == val*1

									case "bool":
										return true

									case "json":
										return typeof val == 'object'

									case "jsonarray":
										return Array.isArray(val)


									case "select":
									case "radio":
										return (fl.name in values && (!fl.disallow_null || values[fl.name]!==null))

									case "email":
										return /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(val)
									
									case "privacy":
										return val // Only valid option is to accept the privacy

									case "appointment": {
										return !!val?.start && !!val?.end;
									}

									default:
										console.log("Uniform can't validate ", fl.type);
										return false

								}
							} )() 
				// console.log('validating field ', fl.name, values[fl.name], valids[fl.name], fl);
			} )
		} catch (e) {
			console.log("CATCH :: UniForm.getValids -  Validation Error",e);
			valids.general = false
		}

		// console.log('checkForm', Object.values(valids).reduce( (t, e) => t = t && e, true ), values, valids);
		// console.log('checkForm', valids, Object.values(valids), Object.values(valids).reduce( (t, e) => t = t && e, true ));
		(DEBUG || this.props.debug) && console.log('[debug] UniForm :: getValids', Object.values(valids).reduce( (t, e) => t = t && e, true ), 'valids', valids, 'values', values);

		return valids

		
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	checkForm () {
		
		const { fields, valid_not_required } = this.props
		const { values } = this.state

		const valids = this.getValids();

		// console.log('checkForm', Object.values(valids).reduce( (t, e) => t = t && e, true ), values, valids);
		// console.log('checkForm', valids, Object.values(valids), Object.values(valids).reduce( (t, e) => t = t && e, true ));
		(DEBUG || this.props.debug) && console.log('[debug] UniForm :: checkForm', Object.values(valids).reduce( (t, e) => t = t && e, true ), 'valids', valids, 'values', values);

		this.setState({
			valids
		})

		const ret = Object.values(valids).reduce( (t, e) => t = t && e, true )

		this.props.onFormChecked?.(ret,valids)

		return ret
	}

	notifyValids() {
		const valids = this.getValids();
		const fields = this.fieldsIndex

		const validsErrors = Object.keys(valids).reduce((errors,k)=>(
				Object.assign(errors,!valids[k]&& {[k]:
					fields[k]?.errors?.(this.state?.values?.[k], this.state?.values) ||
					fields[k]?.label || 
					fields[k]?.name || 
					k
				})
			),{})

		
		const ret = Object.values(valids).reduce( (t, e) => t = t && e, true )
		this.props.onFormChecked?.(ret, valids, validsErrors)
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	actForm (e) {
		e && e.preventDefault()


		this.setState({ sentAttempt: true })

		const { values } = this.state

		// this.props.debug && console.log('[debug] UniForm :: actForm - values', values);

		if (this.checkForm()) {

			const { fields, action, actionURL, onActForm } = this.props

			let params = {}

			fields.forEach( (fl) => { 
					if (fl.client_only) return;

					if (fl.name && values[fl.name]!=null) 
						params[fl.param_name || fl.name] = typeof values[fl.name] === 'string' ? values[fl.name].trim() : values[fl.name];

					if (fl.type == 'json-text' )
						params[fl.param_name || fl.name] = JSON.parse(js_to_json(params[fl.param_name || fl.name]));
				} )

			// console.log('actForm', params);
			// return;

			if (!action && onActForm) {
				onActForm(params, values)
				return
			}

			if (action && !onActForm) {
				superagent
					.post(actionURL || (app.app_data.ws_conf.loc.SCRIPT_ROOT.u + app.app_data.ws_conf.loc[action].u))
					.type('form')
					.send( params )

					.end( (err, res) => {

								if (err || !res.ok || !res.body || res.body.res!='ok') {
									console.log('something went wrong', err, res)
									this.setState({
										unsent: false,
										sending: false,
										sent: true,
										sentErr: true
									})
								} else {
									console.log('sent ok', res)
									this.setState({
										unsent: false,
										sending: false,
										sent: true, 
										res: res.body
									})
								}
							} );
			}


			this.setState({
				unsent: false,
				sending: true
			})

		}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	form_fields_render () {
		
		const { fields, hide_btn, btn_text="Submit" } = this.props

		const { values, valids } = this.state

		return	<form className="UniForm">				

				{ fields && 
					fields.map( (fl, i) => fl.type=='hidden' ? null : <FormFieldHolder
								className = {'input_holder ' + fl.holder_class} 
								badClass = 'error' 
								isValid = {!valids || valids[fl.name]} 
								key = {"UniForm-FieldHolder-"+i}
							>

						{fl.name && <label>{fl.label} <span className='required'>{fl.req_label || "is a required field"}</span></label>}

						{ (() => {
								switch (fl.type) {
									case "text":
									case "email":
									case "number":
										return <input 
													name={fl.name}
													value={values[fl.name] || ''}
													onChange={this.handleInputChange}
													type={fl.type} 
													className={'standard-input input '+fl.name} 
													placeholder={fl.placeholder} 
													key={"UniForm-FieldHolder-field-"+i}
												/>

									case "textarea":
										return <textarea 
													name={fl.name}
													value={values[fl.name] || ''}
													onChange={this.handleInputChange}
													className='input textarea' 
													key={"UniForm-FieldHolder-field-"+i}
												></textarea>


									case "select":
										return <select 
													name={fl.name}
													value={values[fl.name]}
													onChange={this.handleInputChange}
													key={"UniForm-FieldHolder-field-"+i}
												>
													<option value=''>Choose:</option>
													{
														fl.options.map( (op, oi) => <option key={"UniForm-FieldHolder-field-"+fl.name+oi}
																			>{op}</option> )
													}														
											</select>


									case "radio":
									case "checkbox":
										return 	fl.options && fl.options.map( (op, oi) => <label key={"UniForm-FieldHolder-field-"+fl.name+oi}>
													&nbsp; &nbsp; 
													<input 
														name={fl.name}
														value={op && typeof op === 'object' ? op.c : op}
														onChange={this.handleInputChange}
														type={fl.type} 
													 />
													&nbsp; &nbsp; 
													{op && typeof op === 'object' ? op.n : op}
													<br/>
												</label>
											)

									case "json-text":
										return <textarea 
													name={fl.name}
													value={values[fl.name] || ''}
													onChange={this.handleInputChange}
													className='input textarea' 
													key={"UniForm-FieldHolder-field-"+i}
												></textarea>

									case "separator":
										return <hr />
								}		
						 } )() }
					</FormFieldHolder>
				) }

				{
					!hide_btn &&
						<UniButton
							type="big_act"
							click_act={this.actForm.bind(this)}
							btn_text={btn_text}
						 />
				}

			</form>
	}

	render () {

		const { className, children, visible=true, fields } = this.props
		const { render_children = !!children } = this.props

		let { values, valids, ready } = this.state

		const { unsent, sending, sent, sentErr, res } = this.state
		
		
		if (!ready)
			return null
		
		valids = (this.state.sentAttempt || this.props.showErrors ) && this.getValids() || valids
		return visible && <React.Fragment>

				{/*<p className='clearfix'></p>*/}

				{ unsent && !render_children && this.form_fields_render()}
				{ unsent && render_children && children?.(values, valids, this, this.fieldsIndex)}



				{ sending && <div> <br /><br /><br /> Sending your request... please wait.</div> }

				{ sent && !sentErr && <div> <br /><br /><br />
						<div dangerouslySetInnerHTML={{__html: res.html}} />
					</div>
				 }

				{ sent && sentErr && <div> <br /><br /><br /> There was an error sending your request. Please try again</div> }

			</React.Fragment>
	}

}
