import PropTypes from "prop-types";
import React, { Component, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
	Button,
	Icon,
	Image,
	Container,
	Grid,
	Segment,
	Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import user_model from "models/user_model";
import UserReferral from "views/UIelems/UserReferral";
import org_model from "models/org_model";
import HomepagePatient from "./HomepagePatient";

const goto_start = () => {
	app.history.push("/m/pharm/online/prescription");
};

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------

const home_pharm_render = () => (
	<Container className="homepage">
		<BasicIntro />
		<h3>
			InstantScripts is a new technology enabling people to get routine
			prescriptions immediately and straight from the pharmacy.
		</h3>
		<h3>
			There's no need anymore to wait around the doctor's office with a
			group of sick patients just for a repeat script.
		</h3>

		<br />
		<br />
		<div className="action-btns" align="center">
			{/*<Button color="green" size="huge" onClick={ _=>goto_start() }>
				Get Started
				<Icon name="right arrow" />
			</Button>*/}
			<Button color="green" size="huge" as={Link} to="/m/pharm/online/prescription">
				Get Started
				<Icon name="right arrow" />
			</Button>
			<Button primary size="huge" as={Link} to={"/about"}>
				About
			</Button>
		</div>
	</Container>
);

// 		--------------------------------		--------------------------------		---------


const home_nurse_render = () => (
	<Container className="homepage">
		<BasicIntro />
		<br />
		<br />
		<div className={'grid grid-cols-1 md:grid-cols-12 gap-6'}>
			<Link as={Link} to={org_model.new_script_route()} className={'col-span-1 md:col-span-4'}>
				<button
					role={'button'}
					className={'ui small icon button cta cta-tertiary justify-center !w-full !h-full !min-h-[70px]'}

				>
					New Treatment Request
				</button>
			</Link>
			<Link  as={Link} to="/patients" className={'col-span-1 md:col-span-4'}>
				<button
					role={'button'}
					className={'ui small icon button cta cta-primary justify-center !w-full !h-full !min-h-[70px]'}

				>
					Returning Patient
				</button>
			</Link>
			<Link as={Link} to={`/admin/org-booking/new/${app.user.org?.oid}`} className={'col-span-1 md:col-span-4'}>
				<button
					role={'button'}
					className={'ui small icon button cta justify-center !w-full !h-full !min-h-[70px]'}
				>
					New Booking
				</button>
			</Link>
			{user_model.check_access('inventory') && <Link
				as={Link}
				to="/inventory"
				className={'md:!col-start-3 col-span-1 md:col-span-4'}
			>
				<button
					role={'button'}
					className={'ui small icon button cta justify-center !bg-icosm-quaternary !text-white !w-full !h-full !min-h-[70px]'}
				>
					Inventory
				</button>
			</Link>}
			<Link as={Link} to="/scripts/list" className={user_model.check_access('inventory') ? 'col-span-1 md:col-span-4' :'col-span-1 md:col-span-4 md:col-start-5'}>
				<button
					role={'button'}
					className={'ui small icon button cta justify-center !w-full !h-full !min-h-[70px]'}
				>
					View My Scripts
				</button>
			</Link>
			</div>
	</Container>
);

// 		--------------------------------		--------------------------------		---------

const home_mang_render = () => (
	<Container className="homepage">
		<BasicIntro />
		<br />
		<br />
		<div
			className="action-btns"
			style={{ display: "flex", justifyContent: "space-evenly" }}
			align="center"
		>
			<Button color="green" size="huge" as={Link} to="/scripts/list">
				View My Scripts
				<Icon name="right arrow" />
			</Button>
		</div>
	</Container>
);

// 		--------------------------------		--------------------------------		---------

const BasicIntro = () => {
	const show_monthly =
		false && app.dvc.org.type == "pharm" && app.dvc.org.monthly;

	return (
		<React.Fragment>
			{!app.settings.is_ionic && (
				<center>
					<Image
						bordered={false}
						src={app.runtime.logo}
						className="home-logo"
					/>
				</center>
			)}

			<Grid>
				<Grid.Row>
					<Grid.Column width={show_monthly ? 10 : 16}>
							<h2 className={'!my-6'}>Welcome to {app.runtime.name}, {app.user.prof.first_name}</h2>
							<p>
								Prescriptions offered quickly, safely and
								securely
							</p>

							<p
								style={{
									display: "flex",
									marginBottom: ".5em"
								}}
							>
								<Icon
									name="check"
									size="small"
									style={{
										marginTop: "6px",
										marginRight: "5px"
									}}
								/>{" "}
								<span>
									Complete the short digital medical
									consultation
								</span>
							</p>
							<p
								style={{
									display: "flex",
									marginBottom: ".5em"
								}}
							>
								<Icon
									name="check"
									size="small"
									style={{
										marginTop: "6px",
										marginRight: "5px"
									}}
								/>{" "}
								<span>Submit your answers to our doctors</span>
							</p>
							<p
								style={{
									display: "flex",
									marginBottom: ".5em"
								}}
							>
								<Icon
									name="check"
									size="small"
									style={{
										marginTop: "6px",
										marginRight: "5px"
									}}
								/>
								<span>
									{app.dvc &&
									app.dvc.org &&
									app.dvc.org.type == "cosm"
										? "Receive your cosmetic treatment"
										: "Pick up your medication from your chosen pharmacy or get fast home delivery (for some medications)"}
								</span>
							</p>

							<p>{app.runtime.name}: Health, Safety, Convenience</p>
					</Grid.Column>
					{show_monthly && (
						<Grid.Column width={6}>
							{app.dvc.org.monthly[0] && (
								<Segment color="green">
									<Header as={"h3"} color="green">
										{app.dvc.org.monthly[0].tot}
										<Header.Subheader>
											Total Scripts This Month
										</Header.Subheader>
									</Header>
								</Segment>
							)}
							{app.dvc.org.monthly[1] && (
								<Segment color="green">
									<Header as={"h3"} color="green">
										{app.dvc.org.monthly[1].tot}
										<Header.Subheader>
											Total Scripts In{" "}
											{app.dvc.org.monthly[1].mnth}
										</Header.Subheader>
									</Header>
								</Segment>
							)}
						</Grid.Column>
					)}
				</Grid.Row>
			</Grid>
		</React.Fragment>
	);
};
// 		--------------------------------		--------------------------------		---------


const Homepage = ({popup}) => {
	const host = location.host
	const is_pharm = app.state.loaded && app.dvc.org.type=='pharm'
	const is_cosm = app.dvc.org.type=='cosm' && user_model.check_access('nurse')
	const is_mang = user_model.check_any(['admin','doc','mang','nurse','pharm'])

	useEffect(_=>{
		if (popup) {
			app.trigger(app.events.SHOW_POPUP, popup)
		}
		
		app.settings.iscr && location.pathname.includes('/contact-us') && app.trigger(
		  app.events.SHOW_POPUP, {
			"tp": "pu",
			"pt": "contact",
			"txt": "Contact Us",
			"u": "contact-us",
			"pn": "contact-us",
			"target":"iscr"
		})
		
	},[popup])

	return (
		<React.Fragment>
			<Helmet titleTemplate={app.settings.iscr ? null : `%s – ${app.runtime.name}`}>
				<title>{app.settings.iscr ? `InstantScripts - Fast, Safe & Secure Online Prescriptions in Australia` : 'Home'}</title>
			</Helmet>
			{ 
				is_pharm &&  home_pharm_render()
				|| is_cosm && home_nurse_render()
				|| app.acl.is_doc_presc && home_nurse_render()
				// || is_mang && home_mang_render()
				|| <HomepagePatient />
			}

		</React.Fragment>
		
	);
};

export default Homepage;
