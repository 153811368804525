import React from 'react';
import med_model from 'models/med_model'
import API_service from '../xAppLib/providers/API_service';

const MEASURE_TYPES = {
	levels : {
		description:'Levels',
		defaultDefinition: { measure:'',unit:'',em_low:'',low:'',high:'',em_high:'',rec_low:null,rec_high:null,feed_good:'',feed_em_low:'', feed_low:'',feed_em_high:'', feed_high:''}
	},
	
	binary : {
		description:'Binary',
		defaultDefinition: { measure:'',negative_pass:true,rec_treat:null,feed_good:'', feed_bad:''}
	},
	
	cultures : {
		description:'Cultures',
		defaultDefinition: { measure:'',feed_good:'', feed_bad:'', organisms:[],treatments:[]}
	},

	formula : {
		description:'Formula',
		defaultDefinition: { measure:'', unit: '', formula: ''}
	}
}

export default class patho_model {
	static get MEASURE_TYPES() { return MEASURE_TYPES; }
	
	static async definitions(r) {
		const med = await med_model.load_med( r.key || r.m, {refresh:true} )
		if (r.items) {
			// Note:
			// if r (original med) has items, there may be some in there that aren't in the current med definitions
			// we merge the two items list together
			let items = (med.items||[])
			med.items = items.concat(r.items.filter(k=>!items.includes(k.key || k)))
		}
		let items = await med_model.items(med)
		items = items?.filter(m=>m.definition) || []
		if (med.definition) {
			items.unshift(med)
		}
		return items
	}
	
	static async request(med) {
		let items = await this.definitions(med)
		const tests = items?.map(m=>{
			return {name:m.name,key:m.key||m.m,definition:m.definition}
		}) || []
		
		return {
			tests
		}
	}
	
	static results_complete(tests){ 
		return tests && Object.values(tests).map(v=>v&&v.result!=undefined && v.result!='null').reduce((r,v)=>r&&v,true) || false
	}

	static async send_results_to_bucket(sid){
		const response = await API_service.load_data('patho/update_results_to_dev_bucket', {sid})
		return response
	}
}
