import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'devices';
const _LIST_DET_FIELDS = [

						{
							name: 'Dvc DB key',
							jpath: 'key',
							type: 'sublast',
						},

						{
							name: 'First connected',
							jpath: 'f.tm',
							type: 'time',
						},

						{
							name: 'Last connected',
							jpath: 'l.tm',
							type: 'time',
						},

						{
							name: '# connected',
							jpath: 'cnt',
							type: 'count_values',
						},

						{
							name: 'Dvc',
							jpath: 'l.ua',
							type: 'useragent',
							jpath1: 'ua',
						},

						{
							name: 'Last user',
							type: 'multiline',
							lines: [
										{jpath: 'l.u.displayName'},
										{type: 'br'},
										{jpath: 'l.u.email'},
									]
						},

						{
							name: 'All users',
							jpath: 'cnt',
							type: 'json',
						},

						// {
						// 	name: 'Pharm',
						// 	jpath: 'dvc.pharm_id',
						// 	type: 'bool',
						// },

						// {
						// 	name: 'Pharm ID',
						// 	jpath: 'dvc.pharm_id',
						// 	type: 'sublast',
						// },

						// {
						// 	name: 'Org',
						// 	jpath: 'dvc.org_id',
						// 	type: 'bool',
						// },

						// {
						// 	name: 'Org ID',
						// 	jpath: 'dvc.org_id',
						// 	type: 'sublast',
						// },

						{
							name: 'last URL',
							jpath: 'l.pg',
							type: 'show_more',
							cut_length: 60,
						},

						// {
						// 	name: 'created',
						// 	jpath: 'tm_str',
						// },

						// {
						// 	name: 'name',
						// 	jpath: 'displayName'
						// },


						// {
						// 	name: 'role',
						// 	type: 'role',
						// },

						// {
						// 	name: 'admin',
						// 	jpath: 'customClaims.admin',
						// 	type: 'bool',
						// },
						// {
						// 	name: 'doc',
						// 	jpath: 'customClaims.doc',
						// 	type: 'bool',
						// },
						// {
						// 	name: 'mang',
						// 	jpath: 'customClaims.mang',
						// 	type: 'bool',
						// },
						// {
						// 	name: 'nurse',
						// 	jpath: 'customClaims.nurse',
						// 	type: 'bool',
						// },
						// {
						// 	name: 'pharm',
						// 	jpath: 'customClaims.pharm',
						// 	type: 'bool',
						// },
						// {
						// 	name: 'user',
						// 	jpath: 'customClaims.user',
						// 	type: 'bool',
						// },

						// {
						// 	name: 'creation time',
						// 	jpath: 'metadata.creationTime',
						// 	type: 'time'
						// },
						// {
						// 	name: 'last SignIn',
						// 	jpath: 'metadata.lastSignInTime',
						// 	type: 'time'
						// },
					];

// const _NEW_USER_FORM_FIELDS = [
// 						{
// 							name: "name",
// 							label:'Name',
// 							type: "text",
// 							placeholder: "Name",
// 							// param_name: "n",
// 							req_label: "*",
// 							valid_not_required: true,
// 						},

// 						{
// 							name: "email",
// 							label:'Email',
// 							type: "email",
// 							placeholder: "Email",
// 							// param_name: "e",
// 							req_label: "*",
// 						},

// 						{
// 							name: "password",
// 							label:'Password',
// 							type: "text",
// 							placeholder: "Password",
// 							// param_name: "p",
// 							req_label: "*",
// 						},

// 						{
// 							name: "type",
// 							label:'Type',
// 							type: "radio",
// 							options: [ {c:'ph', n:'Pharmacist'}, {c:'cn', n:'Cosmetic - nurse'}, {c:'cc', n:'Cosmetic - clinic admin'}, {c:'ch', n:'Cosmetic - head'} ],
// 							placeholder: "Type?",
// 							// param_name: "t",
// 							req_label: "*",
// 						},

// 						// {
// 						// 	name: "pharm",
// 						// 	label:'Pharmacy',
// 						// 	type: "select",
// 						// 	options: [
// 						// 				// { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
// 						// 				{ key: 'gn', value: 'General', text: 'General' },
// 						// 				{ key: 'er', value: 'Report an error', text: 'Report an error' },
// 						// 				{ key: 'us', value: 'Help with usage', text: 'Help with usage' },
// 						// 				{ key: 'ps', value: 'Partnership', text: 'Partnership' },
// 						// 			],
// 						// 	placeholder: "Select",
// 						// 	// param_name: "t",
// 						// 	req_label: "*",
// 						// },

// 					];

// const _MODEL_NAME = 'admin_devices_model';

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class admin_devices_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get LIST_DET_FIELDS() { return _LIST_DET_FIELDS; }
	// static get NEW_USER_FORM_FIELDS() { return _NEW_USER_FORM_FIELDS; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

	static pharms_dd_field( phs ) {

		return {
							name: "pharm",
							label:'Pharmacy',
							type: "select",
							options: (() => {
									let pa = [];
									Object.keys(phs)
										.map( k =>
												pa.push({
														key: k,
														value: k,
														text:
															phs[k].name + "  ::  " +
															(phs[k].email||"NO EMAIL") + "  ::  " +
															phs[k].address
													})
											)
									return pa
								})()
								,
									// [
									// 	// { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
									// 	{ key: 'gn', value: 'General', text: 'General' },
									// 	{ key: 'er', value: 'Report an error', text: 'Report an error' },
									// 	{ key: 'us', value: 'Help with usage', text: 'Help with usage' },
									// 	{ key: 'ps', value: 'Partnership', text: 'Partnership' },
									// ],
							placeholder: "Select",
							// param_name: "t",
							req_label: "*",
							// valid_not_required: true,
						}

	}

	static async reload_device(dvcid) {
		return await admin_devices_model.add_record(`${dvcid}/rld/`,
			{
				u: app.user.uid,
				n: app.user.user_det.displayName,
				e: app.user.user_det.email,
			},
			{timestamp: true}
		);
	}
}
