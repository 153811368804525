import React, { useState, useEffect, useRef } from "react";

import { Helmet } from "react-helmet"

import { List, Header, Button, Message, Input, Icon } from 'semantic-ui-react'

import phonecall_model from 'models/phonecall_model'

import PhoneCall from './PhoneCall'

const PhoneCalls = props => {

	const [pcid, setPcid] = useState(props?.match?.params?.pcid || props?.pcid);
	const [pcsd, setPcsd] = useState();

	const {sid} = props?.match?.params || props

	//	------------------------	------------------------	------------------------

	useEffect(() => {
		if (sid) {
			return phonecall_model.watch_record('', d => {
				d && Object.values(d)?.some(({ by }) => by?.u === app?.user?.prof?.uid) && props?.unmaskCallback?.();
				setPcsd(d);
			}, {where_key: 'sid', where_val: sid});
		}
	}, [sid]);

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const render_calls = _ => {

		return <>
					<ul>
					{ pcsd && Object.keys(pcsd)?.map( pcid => <li key={pcid}>
								<br />
								<PhoneCall
									pcid = {pcid}
									view = 'compact'
								/>
							</li>) }
					</ul>
				</>
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	return <>

					 {pcsd && render_calls()}

				</>

};

export default PhoneCalls;

