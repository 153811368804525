import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Form,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from "semantic-ui-react";
import useOnSettingsButtonClick from "../../utlis/hooks/useOnSettingsButtonClick";
import Title from "../components/Title";
import HelpTile, { HELP_INIT } from "../components/HelpTile";

const FullHelpTile = ({
    data,
    onDataChange,
    readOnly,
    settingsWrapper,
    viewerOnly,
}) => {
    const [toggleSettings, setToggleSettings] = useState(true);
    const [state, setState] = useState(data);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        if (!Object.keys(data).length) setState(HELP_INIT);
    }, []);

    !viewerOnly &&
        useOnSettingsButtonClick(settingsWrapper, "settings", () =>
            setToggleSettings((prev) => !prev)
        );

    const onConfirm = () => {
        const formElement = document.getElementById("edit-component-form");
        const formData = new FormData(formElement);

        const update = {};
        for (let key in HELP_INIT) update[key] = formData.get(key);

        onDataChange(update);
        setState(update);
        setConfirm(false);
    };

    const modalEl = confirm && (
        <Modal
            style={{ height: "fit-content", margin: "auto" }}
            onClose={() => setConfirm(false)}
            open={confirm}
        >
            <Form id="edit-component-form">
                <ModalHeader className="m-4">
                    <Title title={`Editing Full Help Tile component`} />
                </ModalHeader>
                <ModalContent className="m-4">
                    <HelpTile {...state} editMode />
                </ModalContent>
                <ModalActions className="m-4">
                    <Button
                        content="Cancel"
                        onClick={() => setConfirm(false)}
                    />
                    <Button content="Confirm" onClick={onConfirm} />
                </ModalActions>
            </Form>
        </Modal>
    );

    return (
        <>
            <Container
                className="landing-page-help-tile my-6"
                onClick={
                    toggleSettings && !readOnly
                        ? (e) => {
                              e.stopPropagation();
                              setConfirm(true);
                          }
                        : undefined
                }
            >
                <HelpTile {...state} />
            </Container>
            {modalEl}
        </>
    );
};

export default FullHelpTile;
