import React, {Component} from 'react'
import { 
		Segment, 
		Menu, 
		Image,
		Header,
		Form,
		Button,
		Input,
		Divider,
		Icon,
		Container
	} from 'semantic-ui-react'
import discounts_model from 'models/discounts_model'
import gtm from '../../xAppLib/providers/gtm';

class UserReferral extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading_referral_code:false,
			referral_code:app?.user?.prof?.referral_code || null
		};
	}
	
	componentDidMount() {
		if (!this.state.referral_code)
			this.load_referral_code(false)
	}
	
	
	async load_referral_code(create = false) {
		this.setState({loading_referral_code:true})
		const {res,discount} = await discounts_model.get_referral_code(create)
		this.setState({referral_code:res=='ok'&&discount,loading_referral_code:false})
	}

	render() {
		if (this.props.fancy) {
			return <div id="home-secion-cta" className="home-section home-section-referral">
			  <div className="section-inner">
				<div className="section-image" />
				<div className="section-container ui container">
				  <div className="ui grid">
					<div className="ui row">
						<div className="placeholder-col column sixteen wide mobile eight wide tablet eight wide computer" />

							<div className="content-col column sixteen wide mobile eight wide tablet eight wide computer">
						<h2 className="section-title referral">Refer a friend and you both get a discount</h2>
						<div className="section-desc">
						  <p>When your friends use this discount code to get their first express script, they will get 50% off. After that, you'll get 50% off your next express script. You can give it to as many friends as you like and accumulate multiple 50% off scripts!</p>
						</div>
						{!this.state.referral_code && <><div className="section-actions">
						  <a onClick={async _=>{
								 this.load_referral_code(true);
								 gtm.log('', 'gen_ref_code');
							 }} className="section-action ui button inverted active">Create your Referral Code</a>
						</div></> || <>
						
						 <p className="section-desc code">
							 <br/>Your discount code is <strong>{this.state.referral_code.code}</strong>.</p> 	
							 
						</>}
					  </div>
					  
					</div>
				  </div>
				</div>
			  </div>
			</div>
		}
		return (
			<Segment style={{}} loading={this.state.loading_referral_code}>
					<h3>Referral Code</h3>
					{!this.state.referral_code && <>
							<p>When you refer a friend to InstantScripts, if they’re a new customer, you’ll get 50% off your next prescription, and they’ll get 50% off their first prescription!</p>
							<p><Button onClick={async _=>{
								   this.load_referral_code(true);
								   gtm.log('', 'gen_ref_code');
							   }}>Click here to create your referral code</Button></p> 
						</> || <>
						 <p>
							 Your discount code is <strong>{this.state.referral_code.code}</strong>. Share it now with your friends and you will enjoy a discount when they use it. </p> 	
							 
						</>}
		</Segment>
		);
	}

}

export default UserReferral;
