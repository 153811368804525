import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { cls } from '../../NUI/utils';

const IcosmHero = ({title, desc, link, link_text, img, order, hide_btn, anchor, no_border}) => {
    const img_path = "https://storage.googleapis.com/icosm/"

    return  <div className={cls(`mark-hero flex flex-col md:flex-row gap-6 md:gap-8 lg:gap-[71px] lg:mt-[65px] pb-9 md:pb-[60px] items-center !-mt-4 md:!mt-8`, !no_border && 'border-b border-[#A39383]')}>
                <div className={order === "2" ? "order-2":"order-2 md:order-1"}>
                    <h1 className="mb-4 md:mb-[36px]">{title}</h1>
                    <p className="text-icosm_md md:text-icosm_base">{desc}</p>
                   {!hide_btn && <Button className="cta cta-tertiary icon !ml-0" as={anchor ? "a" : Link} size="large" type="button" href={anchor||""} to={!anchor?"/join":""}>{link_text || "Sign me up"}</Button>}
                </div>
                <div className="h-[250px] md:h-[404px] w-full md:w-[381px] shrink-0 bg-cover" style={{backgroundImage:`url(${img_path}${img})`}}></div>
            </div>
}

export default IcosmHero;
