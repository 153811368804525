function defer() {
	let resolve, reject;

	const promise = new Promise((res, rej) => {
		resolve = res;
		reject = rej;
	});

	return {
		resolve,
		reject,
		promise,
	};
}

/**
 * Takes a callback and returns a new function that will batch calls to the callback.
 * The callback is expected to take an array of at least one event, and resolve to an
 * array of results, where the i-th result corresponds to the i-th event (if there are
 * duplicate events, it is up to the underlying callback to determine the action taken).
 * This *could* just be a simple dedupe function, however we actually want to batch
 * events together, the dedupe is an optimisation that can be taken advantage of.
 * @example
 * // not taking into account duplicates
 * 	const double = async (x, i) => ({ x, y: x * 2, i });
 * 	const handleDoubling = async xs => {
 * 		console.log('doubling', xs);
 * 		return Promise.all(xs.map(double));
 * 	}
 * 	const batchedDouble = batched(handleDoubling, 100);
 * 	batchedDouble(1).then(console.log); // { x: 1, y: 2, i: 0 }
 * 	batchedDouble(2).then(console.log); // { x: 2, y: 4, i: 1 }
 * 	batchedDouble(2).then(console.log); // { x: 2, y: 4, i: 2 }
 * 	// logs: "doubling [1, 2, 2]", three distinct calls to `double`
 * @example
 * // de-duping events to only call the "double" function once per unique event
 * 	const double = async (x, i) => ({ x, y: x * 2, i });
 * 	const handleDoubling = async xs => {
 * 		const unique = [...new Set(xs)];
 * 		console.log('doubling', unique);
 * 		const dict = await Promise.all(unique.map(async (x, i) => [x, await double(x, i)])).then(Object.fromEntries);
 * 		return xs.map(x => dict[x]);
 * 	}
 * 	const batchedDouble = batched(handleDoubling, 100);
 * 	batchedDouble(1).then(console.log); // { x: 1, y: 2, i: 0 }
 * 	batchedDouble(2).then(console.log); // { x: 2, y: 4, i: 1 }
 * 	batchedDouble(2).then(console.log); // { x: 2, y: 4, i: 1 }
 * // logs: "doubling [1, 2]", two distinct calls to `double`, but all three invocations resolve with a value
 * @param callback
 * @param ms
 * @return {function(*): Promise<unknown>}
 */
export function batched(callback, ms) {
	let queue = [];
	let timer = null;

	async function abort() {
		clearTimeout(timer);
		timer = null;
		return queue.splice(0).map((x) => x.data);
	}

	async function flush() {
		const current = queue.splice(0);
		if (!current.length) return [];

		await callback(current.map((x) => x.data))
			.then((results) => {
				for (let i = 0; i < current.length; i++) {
					current[i].resolve(results[i]);
				}
			})
			.catch((error) => {
				for (let i = 0; i < current.length; i++) {
					current[i].reject(error);
				}
			});

		return Promise.all(current.map((x) => x.promise));
	}

	function enqueue(data) {
		const deferred = { ...defer(), data };
		queue.push(deferred);

		if (!timer) {
			timer = setTimeout(() => {
				clearTimeout(timer);
				timer = null;
				flush();
			}, ms);
		}

		return deferred.promise;
	}

	enqueue.flush = flush;
	enqueue.abort = abort;

	return enqueue;
}
