import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import gtm from '../providers/gtm';
import { cls } from 'views/NUI/utils';
import SIcon from '../../views/NUI/StreamlineIcons/SIcon';

export default function Buttons({ info_link, link_text, el, cta, cta_link }) {
    return  <div className={cls("flex flex-wrap justify-between xl:flex-nowrap items-center w-full gap-2 md:gap-4 px-6 pb-5 md:pb-8" )}>
                {cta && <Button as={cta_link.href ? 'a' : Link} {...cta_link} color="blue" className={cls("w-full xl:w-fit")}>
                    {cta}
                </Button>}
                {info_link && <a
                    onClick={e => { e.preventDefault(); e.stopPropagation(); gtm.log('', 'outbound', { link: info_link, loc: 'card', med: el ? el.n : '' }); window.open(info_link, '_blank').focus() }}
                    href={info_link} target="_blank" className={cls("!flex w-full justify-start lg:justify-center items-center text-is-blue font-bold underline py-3 md:py-0 xl:w-fit")}>
                    {link_text || 'Learn more'} <SIcon name="external" size="xxs" bold cls="ml-2"/>
                </a>}
            </div>
}
