import React from "react";
import UserFlag from "../../../xAppLib/Users/UserFlag";

export default function FieldAnswerFlags({row}) {
	return (<div className="flex flex-col space-y-2 items-start">{
		Object
			.values(row?.answ_flgs || {})
			.map(qa => (
				<UserFlag key={qa.nid}
						  notes={qa}
						  uid={row.pts_uid}
						  ui={row.user_iding}
						  email={row.spd_data?.email}
						  icon="warning"
						  title="Flagged Answer"
				/>
			))
	}</div>)
}