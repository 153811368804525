import React, {Component, useState, useLayoutEffect, useCallback, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Menu } from 'semantic-ui-react'

import user_model from 'models/user_model'
import { useUser } from 'xAppLib/Hooks'

const AdminMenu = ({ level = 0, inSideMenu = false, isRight, isEnter }) => {
	const user = useUser()
	const location = useLocation()
	const curr_page = location.pathname
	const [activeSubMenu, setActiveSubMenu] = useState()
	const [previousActive, setPreviousActive] = useState()
	const [displays, setDisplays] = useState({})
	const [activePositon, setActivePosition] = useState()
	const [adminPosition, setAdminPosition] = useState()

	const notActive = {
		display: 'none',
	}

	const isActive = {
		display: 'block',
		position: 'absolute',
		backgroundColor: 'white',
		zIndex: '50',
		boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 8%)',
		border: '1px solid rgba(34, 36, 38, 0.15)',
		minWidth: adminPosition?.width,
		top: activePositon?.bottom - adminPosition?.top - activePositon?.height - 1,
		left: isRight ? 'auto' : activePositon?.right - adminPosition?.left,
		right: isRight ? adminPosition?.width - 1 : 'auto',
		maxHeight: '600px',
		overflowY: 'auto'
	}

	useEffect(() => {
		window.addEventListener('resize', deactiveSub)
		return (() => window.removeEventListener('resize', deactiveSub))
	}, [])

	useLayoutEffect(() => {
		setDisplays({...displays, [previousActive]: notActive, [activeSubMenu]: isActive })
	}, [activeSubMenu, previousActive])

	useLayoutEffect(() => {
		deactiveSub()
	}, [isEnter])

	const deactiveSub = useCallback(() => {
		setDisplays({...displays, [activeSubMenu]: notActive })
	}, [])

	const handleMouseOn = (e) => {
		setPreviousActive(activeSubMenu)
		setActivePosition(e.target.getBoundingClientRect())
		setAdminPosition(document.getElementsByClassName('admin-menu')[level].getBoundingClientRect())
		setActiveSubMenu(e.target.textContent)
	}

	if (!user.user_in)
		return null
	
	if (inSideMenu) 
		 return <Menu inverted fluid vertical>
					{
						Object.keys(user_model.ROLE_LIST)
							.sort( (a,b)=>b-a )
							.map( (r,i) =>
										app.user.claims[user_model.ROLE_LIST[r].c] &&
										// r<=user_lev &&
										app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c] &&
								<Menu.Item key={'user_menu sub '+r}>
									<Menu.Header >{user_model.ROLE_LIST[r].n} menu</Menu.Header>
									<Menu.Menu style={{marginLeft:0}}>
									{
										app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c] &&
											app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c]
												.filter((item) => {
													if (item.flag?.startsWith('booking')) {
														return !!app.site_status?.booking?.show_admin_menu;
													}
													return true;
												})
												.map( (p, i) => p.type=='separator' && <hr color='grey' key={'user_menu sub '+r+'-'+i} style={{margin:'.5em 0'}}/>
											|| p.type=='spacer' && <div key={'user_menu sub '+r+'-'+i} style={{lineHeight:.5}}>&nbsp;</div>
											|| (p.type=='header' || p.type=='subheader') && <Menu.Header icon={p.ico} content={p.name} key={'user_menu sub '+r+'-'+i}  />
											|| p.u && p.name && <Menu.Item
															content={p.name}
															icon={p.ico}
															active={curr_page == p.u
																	|| curr_page
																		&& curr_page.startsWith(p.u + (p.u && p.u.endsWith('/') ? '' : '/'))
																}
															// active={curr_page === p.u}
															// onClick={this.handleItemClick}
															as={Link}
															to={p.u}
															color={'red'}
															key={'user_menu sub '+r+'-'+i}
														 />
													 || null
													)
									}
									</Menu.Menu>
								</Menu.Item>
								|| null
							)
					}

				</Menu>
			
	return <React.Fragment>
				{
					Object.keys(user_model.ROLE_LIST)
						.sort( (a,b)=>b-a )
						.map( (r,i) => {
							let nestedMenu = []
							return  app.user.claims[user_model.ROLE_LIST[r].c] &&
							// r<=user_lev &&
							app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c] &&
							<React.Fragment key={'user_menu sub '+r}>
								{/* {i && <Dropdown.Divider /> || null} */}
								{/* <Menu.Header>{user_model.ROLE_LIST[r].n} menu</Menu.Header> */}
								{
									app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c] &&
										app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c]
											.filter((item) => {
												if (item.flag?.startsWith('booking')) {
													return !!app.site_status?.booking?.show_admin_menu;
												}
												return true;
											})
											.filter(p=>p.name||p.type).map( (p, i) => {
												const menuLength = app.app_data.ws_conf.user_menus['menu_'+user_model.ROLE_LIST[r].c]
																	.filter(p=>p.name||p.type).length
												if (p.type === 'header' || i === menuLength - 1 ) {
													const renderMenu = nestedMenu;
													i === menuLength - 1 ? renderMenu.push(p) : nestedMenu = [p];
													if (renderMenu.length > 0) {
														renderMenu[renderMenu.length - 1].type === 'separator' && renderMenu.pop()
														const name = renderMenu[0].name
														!displays[name] && setDisplays({...displays, [name]: notActive})
														return <React.Fragment key={'user_menu '+r+'-'+i}>
															<Menu.Item 
																onMouseEnter={handleMouseOn} 
																style={{justifyContent: isRight ? 'flex-end' : 'space-between', 
																		flexDirection: isRight ? 'row-reverse': 'row'}}>
																<div>{name}</div>
																<i aria-hidden='true' className={isRight?'caret left icon':'caret right icon'}/>
															</Menu.Item>
															<div style={renderMenu[0].submenutop ? {...displays[name], top: renderMenu[0]['submenutop']} : displays[name]}>
																{renderMenu.shift() && renderMenu.map((sm, si) => 
																	sm.type === 'separator' && <hr color='grey' key={'user_menu sub '+r+'-'+ si} style={{margin:'.5em 0'}}/> ||
																	sm.type === 'spacer' && <div key={'user_menu sub '+r+'-'+si} style={{lineHeight:.5}}>&nbsp;</div> ||
																	sm.type === 'subheader' && <Menu.Item icon={sm.ico} content={sm.name} key={'user_menu sub '+r+'-'+si} className='sub-sub-header'/> ||
																	<Menu.Item
																	content={sm.name}
																	icon={sm.ico}
																	active={curr_page == sm.u
																		|| curr_page
																			&& curr_page.startsWith(sm.u + (sm.u && sm.u.endsWith('/') ? '' : '/'))
																	}
																	as={Link}
																	to={sm.up ? { pathname: sm.u, state: sm.up } : sm.u}
																	key={'user_menu sub '+r+'-'+si}
																/>)}
															</div>
														</React.Fragment>
													}
												} else {
													if (nestedMenu.length > 0)
														nestedMenu.push(p)
													else if (!p.type)
														return <Menu.Item
														content={p.name}
														icon={p.ico}
														active={curr_page == p.u
																|| curr_page
																	&& curr_page.startsWith(p.u + (p.u && p.u.endsWith('/') ? '' : '/'))
															}
														as={Link}
														to={p.up ? { pathname: p.u, state: p.up } : p.u}
														key={'user_menu sub '+r+'-'+i}
														/>
												}			
											})	
								}

							</React.Fragment>
							|| null
						})
				}

			</React.Fragment>
		
}

AdminMenu.componentName = 'AdminMenu'
 
export default AdminMenu;
