import React from "react";
import LearnMore from "./LearnMore";
import Alert from "../../views/NUI/Alert";
import { QUAL_STATUS } from "views/med/utils";
import onepass_model from "../../models/onepass_model";
import currency from "currency.js";

export default function PriceUpdate({
    qual_status,
    cart: { MEDCONS_PRICE, MEDCONS_STD = app.site_status?.prs_mc['mc_std'] } = {},
    className = "mt-8",
    enable = true,
    onepassEligible,
    med_data,
}) {
    if (!enable || qual_status === QUAL_STATUS.CURRENT) return null;

    const rejected = [QUAL_STATUS.CONS_ONLY].includes(qual_status);

    return (
        <Alert info className={className} header="Update">

                {rejected ? (
                    <>
                        <p>We can see you have not been approved for online prescriptions for this medication through InstantScripts.</p>
                        <p>
                            To access scripts for this medication in the future, you will need to
                            speak to one of our doctors via a Telehealth
                            Consultation.
                        </p>
                        <p>
                            The cost of your request today {
                                onepassEligible && onepass_model.isValidOnepassItem(med_data)
                                    ? `for being a OnePass member will be ${onepass_model.discountedPriceString(MEDCONS_PRICE)}`
                                    : `will be $${MEDCONS_PRICE}`
                            } and includes a call with a doctor to
                            discuss your condition and determine the best
                            treatment.
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            The cost of your request today {
                                onepassEligible && onepass_model.isValidOnepassItem(med_data)
                                    ? `for being a OnePass member is ${onepass_model.discountedPriceString(MEDCONS_PRICE)}`
                                    : `is $${MEDCONS_PRICE}`
                            } and includes a call with a doctor as well as any prescription issued.
                        </p>
                        <p>
                            If you’re approved for ongoing single script renewals for this medication, the cost will be reduced
                            {onepassEligible && onepass_model.isValidOnepassItem(med_data)
                                ? ` as a OnePass member to ${onepass_model.discountedPriceString(MEDCONS_STD)}`
                                : ` to $${MEDCONS_STD}`} for the next 12 months.
                        </p>
                    </>
                )}
                <LearnMore />
        </Alert>
    );
}
