import React from 'react';
import { Container } from "semantic-ui-react";

export function PrepUnavailable() {
	const img_path = "https://storage.googleapis.com/instant-med-public/iprep/";

	return (
		<section className="hero primary flex flex-col">
			<Container className="grow">
				<h2>Thank you for visiting HIVPrevent</h2>
				<div className="text-center">
					<div className="text-md md:text-xl text-white pt-4">
						HIVPrevent is temporarily unable to support you via our online telehealth clinic.
					</div>
					<div className="text-md md:text-xl text-white pt-4">
						To find a PrEP specialist doctor closest to you visit{" "}
						<a href="https://www.prepdforchange.com" className="text-blue-500 underline" target="_blank">
							www.prepdforchange.com
						</a>
					</div>
					<div className="text-md md:text-xl text-white pt-4">
						We hope to be back online soon.
					</div>
				</div>
			</Container>
			<div className="flex w-full flex-row justify-around items-end">
				<img
					src={img_path+"heart-vector.png"}
					alt="HIV Prevent"
					className="max-h-20 md:max-h-44"
				/>
				<img
					src={img_path+"pill-on-the-run.png"}
					alt="HIV Prevent"
					className="max-h-20 md:max-h-44"
				/>
			</div>
		</section>
	)
}