import React, { Component } from 'react';
import { 	Container,
			List,
			Input,
			Button,
			Table,
			Form,
			TextArea,
			Divider,
			Popup,
			Icon,
			Radio,
			Message,
			Segment,
			Grid,
			Accordion,
			Header,
			Dropdown,
			Checkbox,
			Modal
		} from 'semantic-ui-react'
import medicare_model from 'models/medicare_model'
import currency from 'currency.js'

class MBSSelector extends Component {

	render() {
		const { medicare_item, medicare_item_extra, onSelect, showExtra = true, readOnly = false } = this.props
		return (
			<div>
				<h4>Consult </h4>
					
				{Object.values(medicare_model.MEDICARE_ITEMS).map(({code,desc,amount,benefit},i)=><Form.Field key={i}>
				<Radio
				  
				  label={`${desc} – ${currency(benefit||amount).format()} (#${code})`}
				  name='medicare_item'
				  value={code}
				  checked={medicare_item === code}
				  onChange={(e, { value }) => onSelect({ medicare_item:value })}
				  readOnly={readOnly}
				/>
			  </Form.Field>)}

			  {showExtra && <>
				<h4>Extras</h4>
					<Form.Field >
					<Radio
						label={`None`}
						name='medicare_item_extra'
						value={null}
						checked={Object.keys(medicare_item_extra).length===0}
						onChange={_ => onSelect({ medicare_item_extra:{} })}
						
					/>
					</Form.Field>
					{Object.values(medicare_model.MEDICARE_ITEMS_EXTRA).map(({code,desc,amount,benefit},i)=><Form.Field key={i}>
					<Radio
						
						label={`${desc} – ${currency(benefit||amount).format()} (#${code})`}
						name='medicare_item_extra'
						value={code}
						checked={medicare_item_extra[code] == true}
						onChange={(e, { value }) => onSelect({ medicare_item_extra:{[value]:true} })}
						readOnly={readOnly}
					/>
					</Form.Field>)}
				
					</>}
			</div>
		);
	}

}

export default MBSSelector;
