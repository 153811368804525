import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { 	Container,
			Input,
			Button,
			Table,
			Icon,
			Message,
			Segment,
			Header,
			Loader,
			Popup,
			Menu,
			Dimmer
		} from 'semantic-ui-react'

import { ObjectInspector, chromeLight } from 'react-inspector';
import { LineChart, Line, BarChart, Brush, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';

import moment from 'moment'

// import ListTable from 'views/datalist/ListTable'
// import ListFilters from 'views/datalist/ListFilters'
// import ListPagination from 'views/datalist/ListPagination'
// import DataConnector from 'views/datalist/DataConnector'
import {DataConnector, ListTable, ListPagination} from 'xAppLib/DataTable'

import user_model from 'models/user_model'
import reports_stats_model from 'models/reports_stats_model'
import scripts_list_model from 'models/scripts_list_model'
import API_service from 'xAppLib/providers/API_service'

const scripts_page_size = 10

class InnerScriptsList extends Component {
	
	constructor(props) {
		super(props);
		const list_params = scripts_list_model.set_list_params(props)

		this.state = {
			...list_params,
		};
		
	}

	render() {
		
		const { from, to, oid, otype, mid, inverted=true, pts_ui, filters:prop_filters,show_list } = this.props
		const page = this.state.page || 1
		const pageSize = this.state.pageSize || this.props.pageSize || scripts_page_size
		
		const filters = {
				...prop_filters,
				date_from:from, 
				date_to:to,
				orgs:oid && [oid],
				// [otype=='cosm' && 'orgs' || 'pharms']:[oid],
				meds:mid && [mid],
				pts_ui,
		}
		
		
		return <DataConnector
			pageSize={pageSize}
			page={page}
			filters={filters}
			data={ this.props.data || ( async (filters, page, pageSize)=>{
				const result = await scripts_list_model.load_scripts_list({
																				filters, 
																				// page, 
																				// pageSize, 
																				show_list,
																				limit: pageSize,
																				offset: (page-1) * pageSize,
																				total_count: true,
																				// get_stats: true
																			})
				if (result){
					return { data :result.listScripts, total : result.total }
				}
				return null
				
			} ) }
			>
			{ (data, loading, pages, total, pageSize) => {
				return <React.Fragment>
					<ListTable data={data} loading={loading} inverted={inverted} color="grey">
						{(row,index)=>{
							return <React.Fragment>
								<ListTable.Cell row={row} index={index} label='No' singleLine>
									{(value,r,index)=><React.Fragment>
											
										{user_model.check_access('scripts_list') &&
											<Button 
													as = {Link} 
													to = {{
														pathname: '/script/'+row.sid,
														state: { row:r, flds: JSON.stringify(this.state.flds) }
													}}
													content = {index+1+(page-1)*scripts_page_size}
													size = 'mini'
													compact
													icon="search"
												/>
										 || (index+1+(page-1)*scripts_page_size)
										 }
										</React.Fragment>}
								</ListTable.Cell>
								{
									this.state.flds && this.state.flds.map((f, fi)=><ListTable.Cell row={row} index={index} key={fi} f={f} />)
								}
								{/* <ListTable.Cell row={row} index={index} label='View Script' path='sid'>
									{(value,row,index)=>(
										<Button content="Script"
													icon='eye'
													as='a'
													href={API_service.API_BASE_URL+'viewScript?s='+value}
													target={'script'+index}
													compact
													size='mini'
												 />
										)}
								</ListTable.Cell>

								{ user_model.check_access('cust_supp') &&
									<ListTable.Cell row={row} index={index} label='Request'>
										{(value,row,index)=>(<Popup
												trigger={<Button icon='add' />}
												position='left center'
												on='click'
												style={{height: '80vh', width: '80vw', minWidth: 700, overflow: 'scroll'}}
										>
												<ObjectInspector
														data={ row }
														expandLevel={ 2 }
														theme={{...chromeLight, ...({ TREENODE_PADDING_LEFT: 20 })}}
													 />

										</Popup>)}
									</ListTable.Cell>
								} */}
							</React.Fragment>
						}}
						

					</ListTable>

					<ListPagination 
							page={page} 
							pages={pages} 
							pageSize={pageSize}
							loading={loading} 
							data={data} 
							total={total} 
							onPageChange={page=>{this.setState({page})}}
							showPageSizeSelector={true}
							onPageSizeChange={pageSize=>{this.setState({pageSize})}}
						/>
					
				</React.Fragment>
			}}
			
		</DataConnector>
	}

}

export default InnerScriptsList;
