import React, {useMemo} from 'react';
import {Button, Icon, Message} from 'semantic-ui-react';
import moment from 'moment';

import localstorage_database from 'xAppLib/providers/localstorage_database';
import {useRoster} from "../Hooks";

function SkypeButtonsInternal({
								  roster,
								  onClick,
								  preferred_doc_id,
								  heading = 'Doctors are listed in order of Skype Priority. If you require Skype, please Skype the doctor at the top of the list first.',
								  loadingMessage
}) {
	if (!(roster)) {
		// show a message indicating no doctors on roster?
		return loadingMessage || null;
	}

	return (
		<div className='pt-4'>
			<div>{heading && <strong>{heading}</strong>}</div>
			{roster
				.map( d => <React.Fragment key={d.m}>
					<Button
						basic={preferred_doc_id !== d.k}
						color='teal'
						onClick={e => {
							onClick?.(d);
							if (app.settings.is_local && !window.confirm('Really call in local mode?')) {
								e.preventDefault();
							}
						}}
						content={<><b>{d.name}</b> - Skype call now</>}
						as="a"
						href={d.href}
					/>
					<div style={{height:'1em'}}></div>
				</React.Fragment>
			) }
		</div>
	)
}

export function SkypeButtonsStandalone({script_type, onDoctorSelected, show = true, preferred_doc_id, only_preferred_doc, heading}) {
	const {roster, error} = useRoster(script_type, preferred_doc_id, only_preferred_doc);

	// Setting show=false allows the roster to be pre-loaded and skype buttons to be ready to go,
	// so that when this does become visible, it is usable right away. If this is not important,
	// just render the component on demand.
	return (
		<div className={show ? null : 'hidden'}>
			{error
				? (<Message negative>
					Error loading roster, try <a href="#" rel='noopener noreferrer'
												 className="underline text-blue-600"
												 onClick={e => {
													 e.preventDefault();
													 window.location.reload()
												 }}>
						reloading the app
					</a>.
				</Message>)
				: (<SkypeButtonsInternal {...{roster, preferred_doc_id, heading}}
										 onClick={onDoctorSelected}
										 loadingMessage='Loading roster...'/>
				)}
		</div>
	);
}

const SkypeButtons = (props) => {
	const { visible, script_type, docs_data_db, docs_rost, onUpdateDefaults, enabled } = props
	const roster = useRoster(docs_data_db, docs_rost);

	if (script_type != 'cosm' || !visible || !enabled)
		return null

	return (
		<SkypeButtonsInternal roster={roster} onClick={d => {
			onUpdateDefaults({cosm_doc: d.k})
			localstorage_database.update_record('last_skype_doc', d.k)
		}}/>
	);
}

export default SkypeButtons;

