import React from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Button = (props) => {

    const { className, to, onClick } = props
    if (to?.startsWith('https://')) return <a
      href={to}
      className={`${className}`}
      target="_blank"
    >
      {props.children}
    </a>


    return (
        <Link
    className={`${className}`}
        
        to={(to && to) || '#'}
        onClick={onClick}
      >
        {props.children}
      </Link>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func
};

export default Button