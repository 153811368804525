import React, { useEffect, useState } from 'react';
import { useCopyToClipboard } from "../../xAppLib/Hooks/useCopyToClipboard";
import { Button, Dimmer, Loader, Segment } from 'semantic-ui-react';
import API_service from '../../xAppLib/providers/API_service';

const OnePassDetails = ({ token }) => {
	const [details, setDetails] = useState(null);
	useEffect(() => {
		token && (async () => {
			setDetails(await API_service.load_data( 'User/getOnepassDetails', { token: token } ));
		})();

		return () => setDetails(null);
	}, [token]);

	const {copyToClipboard, copied} = useCopyToClipboard();

    return <>
		{details === null ? <Segment style={{boxShadow: 'none', border: 0}}>
			<Dimmer active inverted style={{position: 'relative'}}>
				<Loader inverted>Loading</Loader>
			</Dimmer>
		</Segment> : <>
			<div className='flex items-center'>
				<div className='flex flex-col'>
					<div className='flex items-center'>
						<span className='mr-2'>Linked Email:</span>
						<span className='mr-2'>{details.email}</span>
						<Button size='mini' onClick={() => copyToClipboard(details.email)}>{copied ? 'Copied!' : 'Copy'}</Button>
					</div>
					<div className='flex items-center'>
						<span className='mr-2'>Tier:</span>
						<span className='mr-2'>{details.tier}</span>
					</div>
				</div>
			</div>
		</>}
    </>
};

export default OnePassDetails;