import React, {Component} from 'react'

const ident = c=>c

export const isObject = obj => obj != null && obj.constructor.name === "Object" || Array.isArray(obj)

export default class ShowTextObj extends Component {

	render () {

		const {lbl, cont} = this.props

			return isObject(cont) ?
					<pre>
						{lbl && <b>{lbl}: </b> || null}
						{JSON.stringify(cont, null, 2)}
					</pre>
				:
					<React.Fragment>
						{lbl && <b>{lbl}: </b> || null}
						{typeof cont == 'boolean' && cont.toString() || cont}
					</React.Fragment>
	}
}
