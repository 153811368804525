import React from "react";
import { Container } from "semantic-ui-react";

const Hero = ({ title, img }) => {
    const img_path = "https://storage.googleapis.com/instant-med-public/";

    return (
        <div className="hero h-auto relative flex items-center flex-wrap bg-is-blue md:min-h-[328px] lg:h-[328px]">
            <img src={`${img_path + img}`} className="w-full block md:hidden" />
            <div
                className="absolute top-0 bottom-0 left-1/2 right-0 bg-cover bg-no-repeat md:block hidden rounded-bl-[270px] md:left-[50%] xl:left-[60%]"
                style={{ backgroundImage: `url(${img_path + img})` }}
            ></div>
            <Container>
                <div className="pt-6 pb-11 sm:py-8 lg:py-0 flex flex-wrap w-full md:w-1/2 items-center justify-center md:justify-start gap-2">
                    <h1 className="text-2xl-sm sm:text-4xl !text-white mt-0 text-center md:text-left md:pb-0">
                        {title}
                    </h1>
                </div>
            </Container>
        </div>
    );
};

export default Hero;
