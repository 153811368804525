import React from 'react';
import LearnMore from './LearnMore';
import Alert from '../../views/NUI/Alert';

export const content = (
	<>
		New guidelines by the Medical Board of Australia may affect your services and pricing.
		<LearnMore />
	</>
);

export default function BoardGuidelines({ className = 'mt-8', enable = true }) {
	if (!enable) return null;

	return (
		<Alert info className={className} header="Important notice">
				<p>{content}</p>
		</Alert>
	);
}
