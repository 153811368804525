import React, {Component} from 'react'


export default class FormFieldHolder extends Component {

	constructor (props) {
		super(props)
	}

	render () {
		const { isValid, className, badClass } = this.props
		// const props = Object.assign({}, this.props, { className: currentClasses })
		// const props = objectAssign({}, this.props, { className: currentClasses })
			// <div {...props} >
		return (
			<span className={className + (isValid ? '' : ' ' + badClass)}>
				{ this.props.children }
			</span>
		)
	}
}

