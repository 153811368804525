import React, { useEffect, useState } from 'react';
import { List, Input, Icon, Button, Table, Container, Segment, Header, Form, Checkbox, Card, Image, Label, Loader , Dimmer, Divider } from 'semantic-ui-react'
import { v4 as generate_id } from 'uuid';
import patho_model from 'models/patho_model'
import PharmMedSelector from '../components/PharmMedSelector'
import DisplayField from '../components/DisplayField'
import Meds from '../components/Meds'
import FeedbackText from '../components/FeedbackText'

/**
----
Definition
---
*/
const BinaryDefinition = ({onUpdate,value=BinaryDefinition.defaultDefinition, children}) => {
	const sendUpdate = (name,new_value) => onUpdate({...value,[name]:new_value,id:value.id||generate_id()})
	const handleChange = (e, { name, value }) => sendUpdate(name, value)
	const handleMedChange = ({ target:{name , value }}) => sendUpdate(name, value)
	return <Segment>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.Input fluid label='Measurement' name='measure' value={value.measure} onChange={handleChange} />
			{children}
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
	   		<Checkbox	
  			  checked = {!!value.negative_pass}
  			  onChange={ (e, d) => handleChange(null,{name:'negative_pass', value:d.checked}) }
  			  toggle 
			  label="Pass if not found"
  		  />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea label='Feedback Good Result' name='feed_good' value={value.feed_good} onChange={handleChange} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea label='Feedback Bad Result' name='feed_bad' value={value.feed_bad} onChange={handleChange} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.Field>
				<label>Recommended Meds for Treatment</label>
				<PharmMedSelector name='rec_treat' value={value.rec_treat} onChange={handleMedChange} />
			</Form.Field>
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Form.TextArea  label='Pathology certificate disclaimer' name='disclaimer' value={value.disclaimer} onChange={handleChange} />
		</Form.Group>
	</Segment>
}

Object.assign(BinaryDefinition,patho_model.MEASURE_TYPES.binary)

/**
----
Input
---
*/

const BinaryInput = ({onUpdate,value=BinaryInput.defaultDefinition, children}) => {
	const handleChange = (e, { name, value : new_value }) => onUpdate({...value, [name]: new_value })
	return <>
		<Form.Group  widths='equal' style={{alignItems:'flex-end'}}>
			<DisplayField fluid label={value.measure} />
		</Form.Group>
		<Form.Group widths='equal' style={{alignItems:'flex-end'}}>
			<Button.Group>
			    <Button color={(value.result===true)&&(value.negative_pass&&'red'||'green')} onClick={_=>onUpdate({...value,result:true})}>{value.negative_pass && "Detected" || "Not Detected"}</Button>
			    <Button color={(value.result==-false)&&(value.negative_pass&&'green'||'red')} onClick={_=>onUpdate({...value,result:false})}>{value.negative_pass && "Not Detected" || "Detected"}</Button>
			    <Button color={(value.result==undefined||value.result==null)&&'grey'} onClick={_=>onUpdate({...value,result:null})}>Not Received</Button>
			  </Button.Group>
	   		{/*
				<Checkbox	
	  			  checked = {!!value.result}
	  			  onChange={ (e, d) => handleChange(null,{name:'result', value:d.checked}) }
	  			  defaultIndeterminate={value.result==undefined||value.result==null}
				  label={(value.negative_pass && "Detected" || "Not Detected") + (value.result==undefined && " ?" ||'')}
	  		  />
				*/}
	  	
	 
		</Form.Group>

	</>
}

Object.assign(BinaryInput,patho_model.MEASURE_TYPES.binary)



/**
----
View
---
*/

const BinaryView = ({onUpdate,value=BinaryView.defaultDefinition, children, index, onNotify}) => {
	
	const { measure, result, negative_pass, rec_treat, feed_good, feed_bad } = value
	// console.log(measure,value);
	const danger = result && negative_pass || !result && !negative_pass
	
	useEffect(_=>{
		
		onNotify?.(index,{
			danger,
			measure
		})
		
	},[value])
	return <>
			<Header>
				{measure}
				<Label size='small' color={danger?'red':'green'} horizontal>
					<Icon name={danger?'warning':'check'}  />
					{danger ? "Detected" : "Not Detected" }
				</Label>
			</Header> 
				
				{danger && <>
						<FeedbackText danger>{feed_bad}</FeedbackText>
						<Meds callDoctor={true} heading={`Recommended Medicines For ${measure}`} medsList={rec_treat} />
					
					</> || <>
						<FeedbackText >{feed_good}</FeedbackText>
					</>}

	</>
}

Object.assign(BinaryView,patho_model.MEASURE_TYPES.binary)



export {
	BinaryDefinition,
	BinaryInput,
	BinaryView
}
