import {Button} from "semantic-ui-react";
import messaging_model from "../../models/messaging_model";
import React from "react";
import {cls} from "../NUI/utils";

export function ManageTopicSubscription({title, topic, description}) {
    return (
        <>
            <h3 className={cls({ "mb-0": !!description })}>{title}</h3>
            {description && <p>{description}</p>}

            <Button
                onClick={async _ => app.trigger(app.events.SHOW_MESSAGE, 'Success', await messaging_model.send_mess_sbsc_tpc({mess_tpc: topic}), 'positive')}
                color="green"
                content={`Subscribe`}
            />

            <Button
                onClick={async _ => app.trigger(app.events.SHOW_MESSAGE, 'Success', await messaging_model.send_mess_unsbsc_tpc({mess_tpc: topic}))}
                color="red"
                content={`Unsubscribe`}
            />
        </>
    );
}