import React from 'react'
import { Icon } from 'semantic-ui-react';

const ErrorScreen = ({ message = 'An unexpected error occured', size="large", errors}) => {
	return (
		<div className={`flex items-center ${size === 'large' ? ' space-x-2 ' : ''}`}>
			<Icon name='exclamation circle' color='red' size={size} className='' />
			<p className={`text-${size} ${size == 'small' ? 'font-light' : ''}`}>{message}
				{errors && <ul>{errors.map(error=><li className={`text-${size} ${size == 'small' ? 'font-light' : ''}`}><small>{error}</small></li>)}</ul>}
			</p>
			
		</div>
	);
};

export default ErrorScreen