export const SchedulerLicenseKey = "CC-Attribution-NonCommercial-NoDerivatives";

export const BOOKING_TYPES = [
    'docconswl',
    'icann',
    'mhcp',
    'test',
];

export const MELBOURNE_TIME_ZONE = "Australia/Melbourne";

export const getClientBookingTimezone = () => {
    try {
        const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
        if (timezone?.startsWith('Australia/')) {
            return timezone;
        }
        return MELBOURNE_TIME_ZONE;
    } catch(_error) {
        return MELBOURNE_TIME_ZONE;
    }
}
