import React, { Component } from 'react';

const ISPlatformTermsAndConditions = () => (
	<div className="terms">
		<h1><b>Platform - Terms of Use</b></h1>
		<p>We, InstantScripts Pty Ltd (ACN 629 120 234), own and operate the "InstantScripts" platform, which is accessible via instantscripts.com.au and the InstantScripts App (together, the <b>Platform</b>).</p>
		<p>Throughout these Terms of Use and the Platform, the terms "we", "us" and "our" refer to InstantScripts.</p>
		<p>The use of the Platform by any user (you) is subject to these Terms of Use. By using the Platform, you agree to these Terms of Use which, together with the Privacy Policy, govern your access to and use of the Platform.<b></b></p>
				
		<h3>1. WHO WE ARE</h3>
		<p>InstantScripts is a secure online digital healthcare platform that provides affordable and convenient health services to Australians, including by:</p>
		<ul>
			<li>facilitating telehealth and video consultations with Australian-registered doctors (<b>InstantScripts Partner Doctors</b>); and </li>
			<li>where clinically appropriate and after a digital or telehealth consultation with an InstantScripts Partner Doctor, facilitating:
				<ul>
					<li>access to online prescriptions, including delivery of medications;</li>
					<li>the ability to request a referral for a pathology test;</li>
					<li>the ability to request a specialist referral; and</li>
					<li>the ability to request a medical certificate.</li>
				</ul>
			</li>
		</ul>

		<h3>2. YOUR USE OF THE PLATFORM</h3>
		<h4>2.1. ACCEPTANCE OF THESE TERMS OF USE</h4>
		<p>Your use of the Platform is subject to these Terms of Use. These Terms of Use contain important information, including warranty disclaimers and limitations of liability. Please read these Terms of Use carefully before accessing or using the Platform.</p>
		<p>Your use of the Platform in any way, including, without limitation, browsing the Platform, using any information contained on the Platform, and/or purchasing any good or service provided by InstantScripts or any person or third party engaged by InstantScripts, constitutes acceptance of these Terms of Use, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. If you do not agree to all of the terms of these Terms of Use, then you may not access or use the Platform.</p>
		<p>InstantScripts reserves the right to update, vary or replace any part of these Terms of Use at any time by publishing a revised version of the Terms of Use on the Platform. Any new features or tools which are added to the Platform shall also be subject to the Terms of Use. You can review the most current version of the Terms of Service at any time on this page. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Platform following the posting of any changes constitutes acceptance of those changes.</p>
		<h3>2.2. APPROPRIATE USE OF THE PLATFORM</h3>
		<p>You must only use the Platform, including any third party platforms contained therein, for purposes described in these Terms of Use. You must not use or attempt to use the Platform:</p>
		<ul>
			<li>for any unlawful purpose or in any way that may breach any law or regulation;</li>
			<li>to solicit others to perform or participate in unlawful acts;</li>
			<li>for any commercial purpose;</li>
			<li>to submit, provide or upload false or misleading information or information that may be false or misleading;</li>
			<li>to corrupt, damage or interfere with the Platform, including any third party platforms contained therein, or any third party data, software, hardware, website or information technology systems;</li>
			<li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
			<li>to send, provide or upload any offensive, inflammatory, defamatory, fraudulent or otherwise unlawful information or information that infringes InstantScripts' right, any InstantScripts Partner Doctor's right or third party rights;</li>
			<li>to adversely affect the reputation or public image of InstantScripts or any InstantScripts Partner Doctor;</li>
			<li>to cause annoyance or inconvenience to InstantScripts, any InstantScripts Partner Doctor or any third party; or</li>
			<li>to obtain any good or service offered by, or on behalf, of us, for someone other than you, unless you are their parent, guardian or carer and have their consent to obtain the good or service on their behalf.</li>
		</ul>
		<p>You acknowledge that InstantScripts can terminate your use of the Platform, including any third party platforms contained therein, and to the extent that you have a personal account on the Platform (<b>Personal Account</b>) or a Family Account (as that term is defined in clause 4.1), your Personal Account or Family Account, if InstantScripts reasonably believes that you have breached these Terms of Use.</p>
		<p>Without limiting the above, InstantScripts reserves the right, in our sole discretion, to do any of the following without notice to you:</p>
		<ul>
			<li>suspend or block your access to the Platform or part of the Platform;</li>
			<li >limit or prohibit you from using your Personal Account or Family Account;</li>
			<li>remove any material you send, provide or upload to the Platform; or </li>
			<li>refuse to provide or supply you with any good or service offered by, or on behalf, of us.</li>
		</ul>
		<h4>2.3. INDEMNITY</h4>
		<p>You agree to indemnify, defend and hold harmless InstantScripts and its related entities, its directors, officers, partners, employees, contractors, affiliates, suppliers, service providers and licensors (including the InstantScripts Partner Doctors) from any claim or demand, including reasonable legal fees, made by any third-party due to or arising out of: </p>
		<ul>
			<li>your use of the Platform;</li>
			<li>your breach of these Terms of Service or the documents they incorporate by reference; or</li>
			<li>your violation of any law or the rights of a third-party.</li>
		</ul>

	<h3>3. INFORMATION YOU NEED TO KNOW</h3>
	<h4>3.1. DISCLAIMER</h4>
	<p>Unless provided directly to you by an InstantScripts Partner Doctor, the information and content on the Platform is general and is provided for information purposes only. It:</p>
	<ul>
		<li>is not, and is not intended in any way to be, or a substitute for, advice, such as professional medical advice including professional medical advice provided by InstantScripts Partner Doctors during a telehealth consultation facilitated by InstantScripts; and </li>
		<li>should not replace a qualified health practitioner's independent judgment about the appropriateness or risks of a treatment or use of a particular product or service. </li>
	</ul>
	<p>You should seek the professional advice of a qualified health practitioner with any questions you have regarding any medical conditions and/or the suitability of any products or services, including any products or services offered by InstantScripts through the Platform. Never disregard professional advice given by a qualified health practitioner or delay in seeking it because of the general information you have read on the Platform. You and your health practitioners (including any InstantScripts Partner Doctor you consult with) are exclusively responsible for any patient management and care decisions made with respect to using any product or service offered by InstantScripts, and any consequences of such decisions. </p>
	<p>InstantScripts Partner Doctors are independent of InstantScripts. If you consult with an InstantScripts Partner Doctor, the InstantScripts Partner Doctor is solely responsible for the healthcare services they provide to you, including compliance with standards of care, record keeping and other professional obligations, as well as compliance with privacy law. InstantScripts is not a party to the health professional-patient relationship between you and an InstantScripts Partner Doctor and is in no way involved in, or liable for, any services provided by an InstantScripts Partner Doctor to you.</p>
	<p>In some cases, the content on the Platform may incorporate or summarise views, guidelines or recommendations of third parties. Such content does not necessarily reflect the views of InstantScripts, nor does it necessarily indicate a commitment by InstantScripts to a particular course of action. Your reliance on any information provided by InstantScripts is solely at your own risk.</p>
	<p>Subject to the consumer guarantees provided for in consumer protection legislation (including the Australian Consumer Law set out in Schedule 2 of the <i>Competition and Consumer Act 2010 (Cth) </i>(<b>Australian Consumer Law</b>)), InstantScripts does not give any express or implied warranties and makes no representations in relation to the Platform. In particular, while reasonable care is taken in its preparation, InstantScripts does not guarantee or warrant the accuracy, reliability, completeness or currency of the information on the Platform or its usefulness in achieving any purpose. You accept that content on the Platform may include technical inaccuracies and typographical errors. Information on the Platform should not be used without validating that information from appropriate sources and obtaining professional advice where it is prudent to do so. You should make and rely upon your own assessments and enquires to verify the accuracy of the information provided.</p>
	<p>In addition, subject to the consumer guarantees provided for in consumer protection legislation (including the Australian Consumer Law), InstantScripts does not warrant that the Platform is free from any computer viruses or other defects or that your access to the Platform will be continuous or uninterrupted. InstantScripts accepts no liability arising from your access to the Platform.</p>
	<h4>3.2. LIMITATION OF LIABILITY</h4>
	<p>The Platform comes with non-excludable warranties and guarantees under consumer protection legislation, such as that the Platform will be provided with due care and skill and be reasonably fit for its usual purpose.</p>
	<p>Subject to the above and to the extent permissible by law, InstantScripts and its directors, officers, partners, employees, contractors, affiliates, suppliers, service providers and licensors (including the InstantScripts Partner Doctors): </p>
	<ul>
		<li>accept no liability arising from the content on the Platform, or on websites linked from the Platform, being incorrect, incomplete or misleading; and </li>
		<li>will not be liable for any loss, damage, cost or expense incurred or arising by reason of any person relying on the content that appears on the Platform.</li>
	</ul>

	<h3>4. YOUR PERSONAL ACCOUNT OR FAMILY ACCOUNT</h3>
	<h4>4.1. ELIGIBILITY FOR A PERSONAL ACCOUNT OR FAMILY ACCOUNT</h4>
	<p>To be eligible to create a Personal Account and access that Personal Account, you must be, as a minimum: </p>
	<ul>
		<li>residing in Australia (ie. have a current Australian address); and</li>
		<li>planning to access the goods and services provided by InstantScripts within Australia.</li>
	</ul>
		<p>You may also create an account to use on behalf of another person, such as using the “family account” feature, provided they are the other’s parent, guardian or carer and have their consent to create the account.</p>
	<p>InstantScripts has the sole right to determine your eligibility to create and maintain a Personal Account or a Family Account, and to access the Platform or any part(s) of the Platform.</p>
	<p>You must provide true, accurate and complete information when registering for and using your Personal Account or Family Account and must ensure that such information is current and up to date at all times. When obtaining product or services through the Platform, you must not omit any information that is relevant to the products or services you are obtaining. </p>
	<h4>4.2. YOUR RESPONSIBILITY FOR YOUR PERSONAL ACCOUNT OR FAMILY ACCOUNT</h4>
	<p>If you choose to create a Personal Account or Family Account, you are entirely responsible for your log in details and password and must not share these with any third party. From time to time, we may require you to re-authenticate yourself or issue any direction regarding your Personal Account, your Family Account or your use of your security credentials. </p>
	<p>We are entitled to assume that you are the user whenever your security credentials are used to access a Personal Account or Family Account. You must notify InstantScripts immediately<b> </b>of any known or suspected unauthorised use(s) of your Personal Account or Family Account, or any known or suspected breach of security, including but not limited to unauthorised access to the Platform or loss, theft or unauthorised disclosure of your password. You are responsible for all usage or activity on the Platform. </p>
	<h4>4.3. CLOSING YOUR PERSONAL ACCOUNT OR FAMILY ACCOUNT</h4>
	<p>If you would like to close your Personal Account or Family Account, please access the “My Profile” section of the Platform and under “Account Closure”, select “Request Closure”. If you request that your Personal Account or Family Account be closed, the details you have provided and all information relating to your Personal Account or Family Account will be archived and stored securely by InstantScripts for up to 7 years. If you request deletion of your data, our ability to comply with such requests may be limited by local law, in accordance with Health Records Act 2001 (Victoria), Principle 4 Data Security and Data Retention.</p>

	<h3>5. OUR CONTENT</h3>
	<h4>5.1. OUR INTELLECTUAL PROPERTY</h4>
	<p>The content on the Platform, including but not limited to text, graphics, logos, photographs and images is the property of InstantScripts or its content suppliers and is protected by copyright law. The compilation of all such content is the exclusive property of InstantScripts and protected by copyright law.</p>
	<p>"InstantScripts" and all related graphics, photographs, logos, and product and service names are trademarks (including unregistered trademarks) of InstantScripts. These trademarks, logos and other proprietary graphics may not be used in connection with any other product or service without InstantScripts' express prior written consent.</p>
	<p>Except to the extent expressly stated in these Terms of Use, using the Platform does not give you any rights, title, interest or ownership in any intellectual property rights (including copyright) and proprietary rights to any content on the Platform or any trademarks, logos or other proprietary graphics of InstantScripts. </p>
	<h4>5.2. YOUR USE OF OUR CONTENT</h4>
	<p>Subject to your acceptance of these Terms of Use, InstantScripts authorises you to view, download and/or print a small number of copies of the content on the Platform for your personal, non-commercial use. Otherwise, no content on the Platform may be reproduced, modified, republished, transmitted, displayed, performed, or distributed in any way without the express prior written consent of InstantScripts or as permitted by law. You may not modify, sell, assign, or transfer the content of the Platform or reproduce, display, distribute or otherwise use the content of the Platform in any way for any public or commercial purpose or otherwise except as expressly permitted in these Terms of Use. Content on the Platform is subject to change without notice at the discretion of InstantScripts.</p>
	<p>You are granted a limited, revocable and non-exclusive right to create a hyperlink to the home page of the part of the Platform which is accessible via a website so long as the link does not portray InstantScripts or any InstantScripts Partner Doctor or the functions and activities of InstantScripts in a false, misleading, derogatory or otherwise offensive manner. If requested by InstantScripts, you must remove any such hyperlink contained on the forum to which you have provided the hyperlink.</p>


	<h3>6. THIRD PARTY CONTENT</h3>
	<h4>6.1. CONTENT HOSTED BY THIRD PARTIES ON THE PLATFORM</h4>
	<p>The chat function on the Platform enables you to chat with our support team in real-time. The chat function is facilitated by Intercom, Inc.. These Terms of Use, as well as any additional terms issued by Intercom, Inc. from time to time, govern your access to and use of the chat function on the Platform. Your use of the chat function constitutes acceptance of these Terms of Use.</p>
	<h4>6.2. THIRD PARTY LINKS</h4>
	<p>InstantScripts may include links on the Platform to websites owned by other parties. These links may be represented in different ways, including as a standalone hyperlink and as content from the website owned by other parties which, if clicked, may direct you to the website owned by other parties. InstantScripts is not responsible for the material contained on these websites or the availability of these websites. InstantScripts does not endorse and is not responsible or liable for any content, including advertising or products offered on these websites, nor is InstantScripts responsible for any damage, offence or loss caused as the result of visiting these websites. InstantScripts is not liable for any reliance or weight given to third party links when considering whether to purchase a good or service offered by, or on behalf, of us. InstantScripts recommends that before you visit any website you check that your computer is running up-to-date virus checking software.</p>
	<p>By accepting these Terms of Use, you agree that you will not breach any right of a third party, including but not limited to contractual, copyright, trademark and privacy rights.</p>
	<h4>6.3. CONTENT ON THIRD PARTY SITES</h4>
	<p>When you use InstantScripts' social media pages, you are using an external website and are bound by the terms and conditions of use of that website. We encourage you to review the terms and conditions of the relevant social media websites. </p>

	<h3>7. YOUR CONTENT</h3>
	<p>Any material you provide or send to InstantScripts on, or via, the Platform will be deemed to be non-confidential and non-proprietary, unless it is indicated otherwise. This includes any data, questions, comments, suggestions, ideas or other information, but does not include your personal (including sensitive or health) information which you provide to us or an InstantScripts Partner Doctor in the direct course of obtaining goods and services offered by, or on behalf, of us. InstantScripts may use any material provided by you to InstantScripts on, or via, the Platform which has not been indicated as being confidential or proprietary for any purpose without compensation to you. </p>
	<p>Such material may also be transferred (in an encrypted form) over various networks and/or changed to confirm and adapt to technical requirements of connecting networks or devices. If you provide any payment information, this information will be tokenised by a third party so that we only ever receive a token when payments occur. </p>

	<h3>8. PRIVACY</h3>
	<p>Your personal (including sensitive and health) information will be collected by InstantScripts, including if you choose to create a Personal Account or Family Account, make an order for any goods or services from InstantScripts or contact InstantScripts on or via the Platform. <a href='#' onClick={() => app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt", txt:'Privacy Policy', pn:'privacy-policy'})}>InstantScripts' Privacy Policy</a>, explains how InstantScripts generally handles your personal (including sensitive and health) information. The Privacy Policy also includes information about how you can:</p>
	<ul>
	<li>seek to access and/or correct your personal information InstantScripts holds about you; and</li>
	<li>submit a privacy query or complaint to InstantScripts and how InstantScripts will deal with your complaint.</li>
	</ul>
	<p>By using the Platform, you agree that InstantScripts can use your personal (including sensitive and health) information in accordance with its Privacy Policy.</p>

	<h3>9. COOKIES</h3>
	<p>If your web browser is set up to accept cookies, a cookie may be stored on your hard drive when you visit the Platform. Cookies allow InstantScripts to collect information about your computer, which may include your IP address (a number assigned to your computer when you register with an Internet Service Provider), type of browser, operating system, domain name, and the details of any website which has referred you to the Platform. InstantScripts uses cookies to track and collect information about which parts of the Platform (including links to other websites) are being visited by you. Cookies also allow InstantScripts to recognise your computer while you are accessing the Platform. This information is used to maintain the quality of our service and to provide tracking and statistics regarding the use of the Platform.</p>
	<p>If you would rather not have this information stored on your computer, you can configure your browser so it does not accept cookies. However, if you disable cookies you may not be able to access all parts of the Platform.</p>

	<h3>10. GOVERNING LAW</h3>
	<p>These Terms of Use are governed by and must be construed in accordance with the laws of Victoria, Australia. The parties submit to the non-exclusive jurisdiction of the courts of Victoria, Australia and the Commonwealth of Australia in respect of all matters arising out of or relating to these Terms of Use, its performance or subject matter.</p>
	
	<h3>11. SEVERABILITY</h3>
	<p>Any provision of these Terms of Use which is invalid in any jurisdiction must, in relation to that jurisdiction, be:</p>
	<ul>
		<li>read down to the minimum extent necessary to achieve its validity, if applicable; and</li>
		<li>severed from these Terms of Use in any other case,</li>
	</ul>
	<p>without invalidating or affecting the remaining provisions of these Terms of Use or the validity of that provision in any other jurisdiction.</p>

	<h3>12. HOW TO CONTACT US</h3>
	<p>Questions about these Terms of Service should be sent to us at <a href="mailto:info@instantscripts.com.au"><b>info@instantscripts.com.au</b></a></p>
	<p>Last update: November 2022</p>

</div>
);

export default ISPlatformTermsAndConditions
