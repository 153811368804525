import React, {Component} from 'react'
import { Link } from 'react-router-dom'

import { Breadcrumb } from 'semantic-ui-react'

import KeyName from './KeyName';

export default class _Breadcrumb extends Component {

	render () {

		const {nav_base, nav_route, curr_remove, curr_code, curr_name, prefix, root_name='Catalogue', db_get, path_arr, data_key='oid', size, data_db, className} = this.props

		let up_arr = [{n:root_name, u:''}]

		nav_route &&
			nav_route
				.split('/')
				.map( (i) => i &&
						up_arr.push({
							k: i,
							u: up_arr[up_arr.length-1].u + i + '/',
						})
					)
		curr_remove && up_arr.pop()

		// console.log('Breadcrumb', up_arr, 'data', this.props, nav_route);
		if (path_arr)
			return 		<Breadcrumb size={size}>
							{
								path_arr
									.map( (l,i)=> <React.Fragment key={ 'Breadcrumb sui nav ' + nav_base + l[data_key]+i }>
												<Breadcrumb.Section 
															link 
															as={Link} 
															to={ nav_base + l[data_key] } 
															active={i<path_arr.length - (curr_name || curr_code ? 0 : 1)} 
														>
													{l.name || ''}
												</Breadcrumb.Section>
												{ (i < path_arr.length - (curr_name || curr_code ? 0 : 1))
													&& <Breadcrumb.Divider icon='right chevron' /> }
											</React.Fragment>
										)
							}

							{ (curr_name || curr_code) &&
								<Breadcrumb.Section active>
									{curr_name || <KeyName
																k={curr_code}
																loc={nav_route + '/' +  curr_code}
																db_get={db_get}
																data_db={data_db}
															/>}
								</Breadcrumb.Section>
							}
						</Breadcrumb>

		if (nav_route && nav_route!='/' || curr_name)
			return 		<Breadcrumb size={size} className={className}>
							{prefix}
							{prefix && <Breadcrumb.Divider icon='right chevron' />}
							{
								up_arr
									.map( (l,i)=> <React.Fragment key={ 'Breadcrumb sui nav ' + nav_base + l.u }>
												<Breadcrumb.Section link as={Link} to={ nav_base + l.u } >
													{l.n || ''}
													{!l.n && l.k && <KeyName
																		k={l.k}
																		loc={l.u.slice(0, -1)}
																		db_get={db_get}
																		data_db={data_db}
																	/>}
												</Breadcrumb.Section>
												{ (i < up_arr.length - (curr_name || curr_code ? 0 : 1))
													&& <Breadcrumb.Divider icon='right chevron' /> }
											</React.Fragment>
										)
							}

							{ (curr_name || curr_code) &&
								<Breadcrumb.Section active>
									{curr_name || <KeyName
																k={curr_code}
																loc={nav_route + '/' +  curr_code}
																db_get={db_get}
																data_db={data_db}
															/>}
								</Breadcrumb.Section>
							}
						</Breadcrumb>

		return null

	}
}
