import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'prods_navig';

const _MODEL_NAME = 'navig_model';

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class navig_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

}
