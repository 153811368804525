import React from "react";
import { Helmet } from "react-helmet";
import Process from "./components/Process";
import { Container } from "semantic-ui-react";
import Button from './components/Button';
import CompletePanel from "./components/CompletePanel"
import { ICannUnavailable } from "./ICannUnavailable";

const img_path = "https://storage.googleapis.com/instant-med-public/icann/"
const process = [
  {
    image: img_path+"green-pill.svg",
    title: "Step One",
    text: "Create an account and request a telehealth consultation",
  },
  {
    image: img_path+"green-pill.svg",
    title: "Step Two",
    text: "A doctor will contact you in the next 3-6 hours (typically within an hour)",
  },
  {
    image: img_path+"green-pill.svg",
    title: "Step Three",
    text: "The doctor will assess the relevant information regarding your condition in order to decide appropriate treatment",
  },
];

const proposition = [
  {
    image: img_path+"Iconography-3.svg",
    text: "Easily connect with one of our specialist doctors.",
  },
  {
    image: img_path+"Iconography-1.svg",
    text: "All our doctors are registered and onshore in Australia.",
  },
  {
    image: img_path+"Iconography-2.svg",
    text: "We are Australian Digital Health Agency (ADHA) registered.",
  },
  {
    image: img_path+"delivery-green.svg",
    text: "Our network of pharmacies will have your medication express delivered straight to your door.",
  },
]

const Homepage = () => {
  return (
    <div className="icann home bg-white">
      <Helmet titleTemplate={null}>
        <title>Benicura - Modern medicine</title>
        <meta name="description" content="Benicura is an online medical practice for patients suffering from chronic health conditions."/>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Benicura - Modern medicine" />
        <meta property="og:description" content="Benicura is an online medical practice for patients suffering from chronic health conditions." />
        <meta property="og:site_name" content="Benicura" />
        <meta property="og:image" content="https://benicura.com.au/icann/images/greendoc-hero.jpg" />
        <meta property="og:image:width" content="1600" />
        <meta property="og:image:height" content="900" />
      </Helmet>

      {/* full width header */}
      <ICannUnavailable>
      
      <section className="hero">
        <Container>
          <div className="grid md:grid-cols-5">
            <div className="md:col-span-3 flex flex-col justify-center lg:py-5 text-center md:text-left lg:pl-24">
              <h1 className="lg:pt-6">
                Modern medicine
              </h1>
              <p className="text-center md:text-left icann-content">
                We're an Australian online healthcare practice
              </p>
              <Button
                className="click-button md:mb-8 self-center md:self-start md:text-md rounded-full"
                to="/getstarted"
              >
                Get Started
              </Button>
            </div>

            <div className="hidden md:block mask relative md:col-span-2 md:py-20">
              <img
                className="absolute inset-0 w-full h-full object-cover object-top rounded"
                src="/icann/images/hero.jpg"
                alt="hero.jpg"
              />
            </div>
          </div>
        </Container>
      </section>

      {/* our process */}
      <section className="bg-white md:py-4">
        <Container>
          <div className="md:pb-8 flex flex-col justify-center">
            <Process
              process={process}
              style="text-center pt-8 pb-8 md:pb-0"
              panelStyle="py-10 bg-white shadow-xl my-4 md:my-0"
              imgPanelStyle="md:mb-1"
              imgStyle="h-10 w-10 mx-auto"
              titleStyle="md:text-xl font-bold mt-2 md:mt-0 text-step"
              textStyle="pt-2 pb-5 px-5"
              buttonStyle="cta-light mx-auto mt-auto rounded-full"
            />
          </div>
        </Container>
      </section>

      {/* value proposition */}
      <section className="bg-white md:py-4">
        <Container>
          <div className="md:pb-8 flex flex-col justify-center">
            <h1 className="lg:px-5 pt-8 pb-12 text-center mb-0 icann-font-four">
              Our doctors are here to support your healthcare journey
            </h1>
            <Process
              process={proposition}
              style="md:text-left text-center"
              panelStyle="pt-5"
              imgStyle="h-28 w-28 mx-auto"
              textStyle="px-5 pt-5"
            />
            <Button className="click-button self-center mt-4 mb-4 md:mt-14 md:mb-0 md:text-md rounded-full" 
                    to="/getstarted">
              Get Started
            </Button>
          </div>
        </Container>
      </section>
      </ICannUnavailable>
     
    </div>
  );
};

export default Homepage;
