import React from "react";
import SIcon from "../../views/NUI/StreamlineIcons/SIcon";
import { Container } from "semantic-ui-react";

const FeatureTiles = ({size="s", margin, steps}) => {

	const features_tiles = [
		{
			title: 'Real Australian doctors',
			desc: 'Australian-registered and fully insured doctors',
			icon: <SIcon name="steth" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
		{
			title: 'Wherever you are',
			desc: 'Pick up from any Australian pharmacy or choose home delivery',
			icon: <SIcon name="location-circle" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
		{
			title: 'Range of medications',
			desc: 'Scripts for most common medications',
			icon: <SIcon name="pills-single" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
		{
			title: 'Secure',
			desc: 'Your data is secure in our cloud storage database',
			icon: <SIcon name="shield-tick" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		}
	]

	const step_tiles = [
		{
			title: '',
			desc: 'Log in or create your  account.',
			icon: <SIcon name="profile" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
		{
			title: '',
			desc: 'Answer the digital consultation questions.',
			icon: <SIcon name="doc-sign-pen" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
		{
			title: '',
			desc: 'A doctor will review your request and call you if required.',
			icon: <SIcon name="doc-steth" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
		{
			title: '',
			desc: 'If eligible an eScript will be sent to your phone or medication delivered.',
			icon: <SIcon name="pill-bottle" clr="#0065F2" bold size={size} cls="text-is-blue mr-3 lg:mr-0"/>
		},
	]

	return 	<Container>
				<div className={`flex flex-col lg:flex-row justify-between my-8 rounded-md mx-auto ${size!="lg" && 'divide-y lg:divide-y-0 divide-x-0 lg:divide-x divide-[#E3E3E3] border border-[#E3E3E3] rounded-md w-full lg:w-[700px]'} ${margin || 'my-8'}`}>
					{steps ? step_tiles.map((el, i) =>
							<div key={i} className={`flex flex-row lg:flex-col items-center lg:items-start w-full lg:w-1/4 p-3`}>
								{el.icon}
								<p className="!text-sm mt-3">{el.desc}</p>
							</div>
						)
					:
						features_tiles.map((el, i) =>
							<div key={i} className={`w-full flex flex-row lg:flex-col items-center lg:items-start ${size!="lg" ? 'p-3 lg:w-1/4' : 'lg:w-[calc(25%_-_.5rem)] rounded-md shadow my-2 p-6 bg-white'}`}>
								{el.icon}
								<div>
									<h4 className={`!my-3 ${size!= "lg" && '!text-sm'}`}>{el.title}</h4>
									<div className="inner flex flex-col justify-between">
									<p className={`${size!= "lg" && "!text-sm"}`}>{el.desc}</p>
									</div>
								</div>
							</div>
						)
					}
				</div>
			</Container>
}
export default FeatureTiles;


