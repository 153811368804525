import React, { Component, Fragment, useState} from 'react'
import { Link } from 'react-router-dom'
import { 	Container,
			List,
			Input,
			Button,
			Table,
			Form,
			TextArea,
			Divider,
			Popup,
			Icon,
			Radio,
			Message,
			Segment,
			Grid,
			Accordion,
			Header,
			Dropdown,
			Loader,
			Modal,
		} from 'semantic-ui-react'
import SignaturePad from 'react-signature-pad-wrapper'
import moment from 'moment'
import { Helmet } from "react-helmet"	
import MaskedInput from 'react-text-mask'

import firebase_functions from 'xAppLib/providers/firebase_functions'
import API_service from 'xAppLib/providers/API_service'

import med_model from 'models/med_model'
import script_model from 'models/script_model'
import scripts_list_model from 'models/scripts_list_model'
import device_model from 'models/device_model'
import user_model from 'models/user_model'
import doc_model from 'models/doc_model'
import docs_model from 'models/docs_model'
import medicare_model from 'models/medicare_model'
import cat_model from 'models/cat_model'

import vidcall_model from 'models/vidcall_model'

import SelectOrg from 'xAppLib/Orgs/SelectOrg'
import PaymentForm from 'xAppLib/UIelems/PaymentForm'
import MedicareClaim from 'views/medicare/MedicareClaim'

import UniForm from 'xAppLib/UniForm/UniForm'
import {ViewEditDets} from 'xAppLib/DataList'
import Photos from 'xAppLib/Photos/Photos';
import Files from 'xAppLib/Files/Files';
import SearchPharm from 'views/UIelems/SearchPharm'

import Patient from 'views/patients/Patient'
import MBSSelector from 'views/medicare/MBSSelector'

import obj_diff from 'xAppLib/helpers/obj_diff'

import debounce from 'xAppLib/libs/debounce'


const STATUS_OFF = 'off'
const STATUS_ON = 'on'
const STATUS_INIT = 'init'
const STATUS_READY = 'ready'
const STATUS_AWAIT = 'await'
const STATUS_CALLING = 'calling'
const STATUS_ONCALL = 'oncall'

const DEBUG = true

let med_prods_cat = {}

export default class DocCons extends Component {

	constructor (props) {
		super(props)

		this.state = {
			sid: props.sid,
			msgs: [],
			status: props.ini_stat || STATUS_OFF,
		}

		this.yourVideoRef = React.createRef()
		this.remoteVideoRef = React.createRef()
		
		this.init_vidcall()

	}

	// 		--------------------------------		--------------------------------		---------

	async componentDidMount() {
		this.init_stream()
	}
	componentWillUnmount() {
		this.destroy_vidcall()
	}
	
	// 		--------------------------------		--------------------------------		---------
	
	init_vidcall() {
		this.vidcall = this.props.vidcall
		this.onCallOff = this.vidcall.on(vidcall_model.ON_CALL, this.onCall)
		this.onDisconnectOff = this.vidcall.on(vidcall_model.ON_DISCONNECT, this.onDisconnect)
	}
	
	destroy_vidcall() {
		try {
			this.cleanup_streams()
			this.onCallOff && this.onCallOff()
			this.onDisconnectOff && this.onDisconnectOff()
			this.vidcall?.destroy()
		} catch (error) {
			console.log(error);
		}
	}
	
	cleanup_streams() {
		this.state.userStream?.getTracks().map(track=>track.stop())
		this.state.remoteStream?.getTracks().map(track=>track.stop())
	}
	
	reset_vidcall() {
		this.destroy_vidcall()
		this.init_vidcall
	}
	
	async init_stream() {
		this.setState({status:STATUS_INIT})
		const userStream = await this.vidcall.getMediaStream()
		this.setState({userStream})
		if (userStream) {
			this.yourVideoRef.current.srcObject = userStream
			this.setState({status:STATUS_READY})
		}
	}
	
	set_remote_stream(remoteStream,connection) {
		this.setState({remoteStream, connection})
		window.peer = {remoteStream, connection, peer:this.vidcall.peer}

		if (remoteStream) {
			this.setState({status:STATUS_ONCALL})
			this.remoteVideoRef.current && (this.remoteVideoRef.current.srcObject = remoteStream)
			console.log("Setting connection handlers");
			connection.on('close',_=>{
				console.log("Received close");
				this.onDisconnect()
			})
			connection.on('error',error=>{
				console.log("error",error);
				this.onDisconnect()
			})
		}
	}
	
	onCall = async (call) => {
		
		console.log("onCall()",{call});
		
		const {remoteStream} = await this.vidcall.peer_answer(call,this.state.userStream)
		
		console.log({remoteStream});
		
		this.set_remote_stream(remoteStream,call)

	}
	
	onDisconnect = () => {
		console.log("onDisconnect()");
		this.remoteVideoRef.current && (this.remoteVideoRef.current.srcObject = null)
		this.setState({remoteStream:null,status:STATUS_READY})
	}
	
	hangUp() {
		console.log("hangUp",this.state.connection);
		this.state.connection?.close()
		this.onDisconnect()
		// this.resetConnection()
	}


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	vidcall_render () {

		const { req_scr_obj, user_dets, msgs_mess, status, call_w_name, show_noti_btns } = this.state

		console.log('vidcall_render', this.remoteVideoRef, this.state.userStream);

		return <Segment>
					<h2>Call / message patient</h2>

					{this.msgs_render(msgs_mess)}

					<Button
						//primary 
						//loading={!!scr_loading}
						//disabled={!med_dets}
						size="big"
						onClick={ async _=> {		

							const scr_req = await API_service.load_data(
								'mess/send_msg',
								{
									to_type: 'tpc',
									tpc: 'uid%'+req_scr_obj.pts_uid,

									data: {
											mess_type: 'doccons_doc_call',

											data: req_scr_obj.sid,

											url: `/doccons_view/${this.props.match?.params?.sid}/${this.props.match?.params?.enc}`,
										},

									title: `InstantScripts Doctor Consultation`,
									body: `Doctor is about to call you now, please be ready to answer the call`,

									img: 'https://storage.googleapis.com/instant-med-public/logo-ID.png',
									url: `https://www.instantscripts.com.au/doccons_view/${this.props.match?.params?.sid}/${this.props.match?.params?.enc}`,
								} 
							)

							this.setState({msgs_mess: [ ...(this.state.msgs_mess||[]), {
													h: 'Message sent',
													m: scr_req,
													// l: scr_req.res=='ok' && 'positive' || 'negative'
												} ],
										} )

							} }
						//fluid
						color="blue"
						content={`Notify Pts about a phone call`}
					/>
				
				
					<Button
						//primary 
						//loading={!!scr_loading}
						//disabled={!med_dets}
						size="big"
						onClick={ async _=> {		

								this.setState({status:STATUS_ON})
								this.init_stream()

							} }
						//fluid
						icon="video"
						color="green"
						content="Start Video Call"
					/>	

					{status != STATUS_OFF &&
						<React.Fragment>
							<h3>Video Call with {call_w_name}</h3>
							<div className='vcall_wrap'> 
								<div className='bg abs_center'>
								{ status == STATUS_CALLING && <React.Fragment><Icon name='user'/> Waiting for patient video.</React.Fragment>}
								</div>
								<video id="friendsVideo" ref={this.remoteVideoRef} autoPlay width="100%"></video>
									
								<div className='vcall_your '>
									{!this.state.userStream && <div className='bg abs_center'>
										<Icon name='user doctor'/> {status == STATUS_INIT && "Connecting your video" }</div>
									}
									<video id="yourVideo" ref={this.yourVideoRef} autoPlay muted width="100%"></video>
								</div>
								{status == STATUS_READY && <Button
									//primary 
									//loading={!!scr_loading}
									//disabled={!med_dets}
									size="big"
									onClick={ async _=> {
										
										
										
										this.setState({status:STATUS_CALLING})


										const scr_req = await API_service.load_data(
											'mess/send_msg',
											{
												to_type: 'tpc',
												tpc: 'uid%'+req_scr_obj.pts_uid,

												data: {
														mess_type: 'doccons_doc_vidcall',

														data: req_scr_obj.sid,
														peer_id: app.user?.vidcall_peer_id,

														url: `/doccons_view/${this.props.match?.params?.sid}/${this.props.match?.params?.enc}`,
													},

												title: `InstantScripts Doctor Consultation`,
												body: `Doctor is calling you, please answer the call`,

												img: 'https://storage.googleapis.com/instant-med-public/logo-ID.png',
												url: `https://www.instantscripts.com.au/doccons_view/${this.props.match?.params?.sid}/${this.props.match?.params?.enc}`,
											} 
										)

										this.setState({msgs_mess: [ ...(this.state.msgs_mess||[]), {
																h: 'Message sent',
																m: scr_req,
																// l: scr_req.res=='ok' && 'positive' || 'negative'
															} ],
													} )

										} }
									//fluid
									color="green"
									className='abs_center'
									content={`Invite Patient to Video Call`}
								/>}
								
								{status == STATUS_ONCALL && <Button
									//primary 
									//loading={!!scr_loading}
									//disabled={!med_dets}
									size="big"
									onClick={ async _=> {
										
											this.hangUp()

										} }
									color="red"
									className='hangup'
									content={`Finish Video Call`}
								/>}

								{this.vidcall?.peer?._id} : - : {status}
								
							</div>
							{/*<Grid    columns='equal'>
								<Grid.Row>

									<Grid.Column  width={10}>
										<b>Them</b>
			 							<video id="friendsVideo" ref={this.remoteVideoRef} autoPlay width="100%"></video>
									</Grid.Column>

									<Grid.Column>
										You ({app.user?.vidcall_peer_id})
										<video id="yourVideo" ref={this.yourVideoRef} autoPlay muted width="100%"></video>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							*/}
							

						</React.Fragment>
					}
					{/*!req_scr_obj.paym__price && <h3>Not Paid, need bulk billing</h3> || ''*/}
			</Segment>
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	msgs_render (msgs) {

		return <>
					{
						msgs?.length &&
							msgs.map( (m, i) => <Message
																header={m.h}
																content={m.m}
																// onDismiss={this.closeWindow.bind(this)}
																key={'msg'+i}
																className={m.l}
															/>
							)
						||''
					}
				</>
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render () {

		return <Container className="meds">
					<Helmet>
						<title>Video Call</title>
					</Helmet>

					{this.vidcall_render()}

			</Container>
	}

}
