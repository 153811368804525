import React, { Component } from 'react';
import {
		Icon,
		Button,
		Popup,
		Modal,
		Table,
		Accordion,
		Dropdown,
		TextArea,
		Segment,
		Label
	} from 'semantic-ui-react'
import moment from 'moment';
import MaskedInput from 'react-text-mask'
import user_model from 'models/user_model'
import API_service from 'xAppLib/providers/API_service';


import * as j_f from '../../helpers/json_functions'

import hi_model from '../../models/hi/hi_model'
import debounce from '../../xAppLib/libs/debounce'

const DEBUG = false;

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const ihi_status_options =      ['Active', 'Deceased', 'Retired']
const ihi_rec_status_options =  ['Verified', 'Unverified', 'Provisional']
const ihi_sex_option = {
    "Male":                             "M",
    "Female":                           "F",
    "Intersex":                         "I",
    "Unknown":                          "N",
}
const ihi_demo_conflict_options =  ['Yes', 'No', 'Unknown']

const obj_path_ihi = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'ihiNumber']
const obj_path_ihi_rs = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'ihiRecordStatus']
const obj_path_ihi_s = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'ihiStatus']
const obj_path_ihi_sex = ['search_result', 'searchIHIBatchResult', '0', 'searchIHIResult', 'sex']
const obj_path_rec_conflict = ['audit_data', 'params', '0', 'demo_conflict']

const debounce_time = 250

const hi = new hi_model()

const max_time_verify_ihi_days = 7

class UserIHI extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			isOpen:false
		};

		this.debounce_manual_ihi_verify = debounce(this.verify_manual_ihi.bind(this), debounce_time)

	
	}
	
	componentDidUpdate(prevProps, prevState) {
		
	}
	
	
	render() {
		// console.log("IHI",this.props);
		const { row } = this.props
		
		return <>
					{this.render_ihi_info(row)}
					{this.render_manual_ihi(row)}
			</>
	}

	
	// 		--------------------------------		--------------------------------		---------

	render_ihi_info(r, parent_loading = false) {

		const ihi_number = format_ihi(r['ihi_ihinumber'])
		const ihi_rec_stat = r['ihi_ihirecordstatus']
		//const ihi_rec_stat = 'Provisional' // For testing
		const ihi_stat = r['ihi_ihistatus']
		//const ihi_stat = 'Deceased' // For testing
		const demo_conf = r['ihi_demo_conflict']
		//const demo_conf = 'Yes' // For testing
		const demo_sex = r['sex']
		const verify_time_raw = r['ihi_verify_time']
		const verify_time = verify_time_raw && moment.utc(verify_time_raw).tz("Australia/Melbourne").format('YYYY-MM-DD HH:mm:ss') || null
		const verify_time_diff = moment.utc().diff(moment.utc(verify_time_raw), 'days')
		const verify_outdated = verify_time_diff > max_time_verify_ihi_days

		const assign_time_raw = r['ihi_auto_ihi_time']
		const assign_time = assign_time_raw && moment.utc(assign_time_raw).tz("Australia/Melbourne").format('YYYY-MM-DD HH:mm:ss') || null

		// Check for differences in demo info since IHI assigned
		const current_core_info = core_demo_info(r)
		const ihi_demo_info = r['ihi_demo_info']
		const demo_dif = j_f.differences_in(current_core_info, ihi_demo_info)

		return (

			<React.Fragment>

				<p>
				{ihi_number && 
				<React.Fragment>
					{ihi_number} ({ihi_rec_stat} / {ihi_stat})
					{(demo_conf != 'No' || ihi_stat != 'Active' || ihi_rec_stat != 'Verified' || !verify_time_raw || verify_outdated || (demo_dif && demo_dif.length > 0)) && <Icon className='txt_valid_warning' name='exclamation triangle' /> || <Icon className='txt_valid_valid' name='checkmark' />}
				</React.Fragment>
				|| 'No stored IHI data'}
				</p>
				<Button size='mini' content="Details" onClick={_ => this.setState({ [`acc`]: !this.state[`acc`] })}  />
				
					<Modal
					open={this.state[`acc`]} 
					onClose={_=>{
						console.log("Here");
						this.setState({['acc']:false})
					}}
					closeIcon
					>
					<Modal.Content>
					<Segment basic loading={parent_loading}>

							<Label className='field_label'>IHI</Label>{ihi_number || '?'} <br />
							<Label className='field_label'>Record status</Label> {ihi_rec_stat || '?'} <br />
							<Label className='field_label'>Status</Label> {ihi_stat || '?'} <br />
							<Label className='field_label'>Record conflict</Label> {demo_conf || '?'} <br />
							<Label className='field_label'>Sex</Label> {demo_sex || '?'} <br />
							<Label className='field_label'>Last verify time</Label> {verify_time || '?'} <br />
							<Label className='field_label'>Last auto-assign time</Label> {assign_time || '?'} <br /><br />

							{demo_conf == 'Yes' && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: There are duplicate records for this patient</p></React.Fragment> ||
							demo_conf != 'No' && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: Patient demographic data conflict not checked. Please verify patient's IHI</p></React.Fragment>}
							{ihi_stat != 'Active' && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: IHI status is {ihi_stat || 'Unknown'}</p></React.Fragment>}
							{ihi_rec_stat != 'Verified' && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: IHI record status is {ihi_rec_stat || 'Unknown'}</p></React.Fragment>}
							{(ihi_demo_info && demo_dif && demo_dif.length > 0) && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: Patient's core demographic details have changed since this IHI was assigned.</p><p>It is recommended to do a Manual IHI search to ensure this IHI is correct</p><p>Verify IHI will remove this IHI if the HI service does not have this updated demographic information.</p>{this.render_demo_dif(demo_dif)}</React.Fragment>}
							{!verify_time_raw && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: This IHI has not been verified. Please verify this IHI.</p></React.Fragment>}
							{verify_time_raw && verify_outdated && <React.Fragment><p className='txt_valid_warning'><Icon name='exclamation triangle' />Caution: This IHI has not been verified in {verify_time_diff} days. Please verify this IHI.</p></React.Fragment>}

							{/* Action buttons */}

							<Button.Group size='small'>

								<Popup hoverable position='top left' content={<React.Fragment><h4>Automatically assign patient IHI</h4><p>Searches for the patient's Individual Healthcare Identifier (IHI) and saves this to the patient's record.</p></React.Fragment>} trigger={
									// <Button labelPosition='left' icon color='green' disabled={this.state.loading_ihi || !!ihi_number || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_ => this.run_auto_ihi(r, i)} ><Icon name='checkmark' />Automatic IHI</Button>} />
									<Button labelPosition='left' icon color='green' disabled={this.state.loading_ihi || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_ => this.run_auto_ihi(r)} ><Icon name='checkmark' />Automatic IHI</Button>} />
								
								<Popup hoverable position='top left' content={<React.Fragment><h4>Manually assign patient IHI</h4><p>Manually enter a patient's Individual Healthcare Identifier (IHI) and save this to the patient's record.</p></React.Fragment>} trigger={
									<Button labelPosition='left' icon color='blue' disabled={this.state.loading_ihi || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_=>this.run_manual_ihi(r)} ><Icon name='save' />Manual IHI</Button>} />

								<Popup hoverable position='top left' content={<React.Fragment><h4>Verify patient's stored IHI</h4><p>Verifies the stored IHI is up-to-date and saves any updates.</p></React.Fragment>} trigger={
									<Button labelPosition='left' icon color='orange' disabled={this.state.loading_ihi || !ihi_number || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_=>this.run_verify_ihi(r)} ><Icon name='refresh' />Verify IHI</Button>} />

								<Popup hoverable position='top left' content={<React.Fragment><h4>Delete patient's stored IHI</h4><p>Removes the stored IHI. The patient's IHI record is maintained</p></React.Fragment>} trigger={
									<Button labelPosition='left' icon color='red' disabled={this.state.loading_ihi || !ihi_number || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_=>this.run_delete_ihi(r)} ><Icon name='close' />Delete IHI</Button>} />

								{/* <Popup trigger={<Button label="Auto IHI" icon='checkmark' color='green' size='mini' disabled={this.state.loading_ihi || !!ihi_number || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_ => this.run_auto_ihi(r)} />}
									content={<React.Fragment><h4>Automatically assign patient IHI</h4><p>Searches for the patient's Individual Healthcare Identifier (IHI) and saves this to the patient's record.</p></React.Fragment>} hoverable />

								<Popup trigger={<Button label="Manual IHI" icon='save' color='green' size='mini' disabled={this.state.loading_ihi || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_=>this.run_manual_ihi(r)} />}
									content={<React.Fragment><h4>Manually assign patient IHI</h4><p>Manually enter a patient's Individual Healthcare Identifier (IHI) and save this to the patient's record.</p></React.Fragment>} hoverable/>

								<Popup trigger={<Button label="Verify IHI" icon='refresh' color='orange' size='mini' disabled={this.state.loading_ihi || !ihi_number || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_=>this.run_verify_ihi(r)} />}
									content={<React.Fragment><h4>Verify patient's stored IHI</h4><p>Verifies the stored IHI is up-to-date and saves any updates.</p></React.Fragment>} hoverable/>
								
								<Popup trigger={<Button label="Delete IHI" icon='close' color='red' size='mini' disabled={this.state.loading_ihi || !ihi_number || !!parent_loading} loading={this.state.loading_ihi || !!parent_loading} onClick={_=>this.run_delete_ihi(r)} />}
									content={<React.Fragment><h4>Delete patient's stored IHI</h4><p>Removes the stored IHI. The patient's IHI record is maintained</p></React.Fragment>} hoverable/> */}

							</Button.Group>

							<Accordion fluid styled>

								<Accordion.Title onClick={_ => this.setState({ [`acc_his`]: !this.state[`acc_his`] })} content='IHI History' />

								<Accordion.Content active={this.state[`acc_his`]}>
									{this.render_ihi_history(r)}
								</Accordion.Content>

							</Accordion>

							{this.advanced_ihi_options(r)}

						</Segment>

					</Modal.Content>
					</Modal>


			</React.Fragment>

		)

	}

	//	------------------------	------------------------	------------------------

	render_demo_dif(dif) {

		return (
			dif && dif.length &&
			<React.Fragment>
				<Accordion styled fluid>

                    <Accordion.Title
                        onClick={_ => this.setState({ [`view_dif`]: !this.state[`view_dif`] })}
                        index={0}
                        content='View all differences'
                    />
	
                    <Accordion.Content active={this.state[`view_dif`]} className='acc_cont'>

                    <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Field
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Current value
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Old value
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(dif && dif.length == 0) &&
                            <Table.Row>
                                <Table.Cell colSpan="3">
                                    <p>No differences</p>
                                </Table.Cell>
                            </Table.Row>
                        || (dif && dif.length > 0) &&
                            dif.map((d, i) => {
                                return (
                                    <React.Fragment key={`e_m_${i}`}>
                                        <Table.Row>
                                            <Table.Cell><p>{d.path.join(' - ') || '?'}</p></Table.Cell>
                                            <Table.Cell><p>{JSON.stringify(d.obj1) || '?'}</p></Table.Cell>
                                            <Table.Cell><p>{JSON.stringify(d.obj2) || '?'}</p></Table.Cell>
                                        </Table.Row>
                                    </React.Fragment>
                                )
                            })
                        || 
                            <Table.Row>
                                <Table.Cell colSpan="3">
                                    <p>No data</p>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                    </Table>

                    </Accordion.Content>

                </Accordion><br/>
			</React.Fragment>
		)

	}

	//	------------------------	------------------------	------------------------

	render_manual_ihi(row) {

		// Build the options for the IHI statis and record status (although these will probably always be one value)
        const ihi_stat_opt = ihi_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true}})
        const ihi_rec_stat_opt = ihi_rec_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true}})
        const ihi_sex_opt = Object.keys(ihi_sex_option).map((o, i)=>{ return { key:`o_${i}`, text: o, value: ihi_sex_option[o], disabled: true}})
        const ihi_demo_conflict_opt = ihi_demo_conflict_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o, disabled: true}})

		const ihi_data = {
            ihi: this.state[`manual_ihi_ihi`],
			ihiRecordStatus: this.state[`manual_ihi_ihiRecordStatus`],
			//ihiRecordStatus: 'Broken', // For testing
            ihiStatus: this.state[`manual_ihi_ihiStatus`],
			ihiSex: this.state[`manual_ihi_sex`],
			//ihiSex: 'F', // For testing
			ihiDemoConflict: this.state[`manual_ihi_ihiDemoConflict`],
			//ihiDemoConflict: 'Yes' // For testing
		}

		const valid_to_save = this.state.manual_ihi_valid == 'valid' && ihi_data.ihiRecordStatus == 'Verified'

		return (this.state[`manual_ihi`] &&
			<Modal
                open={this.state[`manual_ihi`]}
                closeIcon
                onClose={_=>this.dont_assign_manual_ihi(row)}
                >
                <Modal.Header><Icon name='exclamation circle' />Manually set IHI</Modal.Header>
                <Modal.Content>
                    <h4>Manual IHI details</h4>
					<p>Please enter the patient's IHI below. This IHI will be verified using the
						<Popup hoverable
							trigger={<span>{` patient's record`}</span>}
							content={ _ => { const demo_data = build_demo_info(row, true); return ( <Label.Group>{Object.keys(demo_data).map((k, i)=>{return ( <div key={`ud_i_${i}`}><Label>{k}</Label>{` ${demo_data[k]}`}</div>)})}</Label.Group>)}}
						/>.</p>
					
					<Segment loading={this.state.manual_ihi_verify_running}>
						<div className='field_cls'>
							<Label className='field_label'>Individual Healthcare Identifier (IHI)</Label>
							<MaskedInput
								placeholder={'Individual Healthcare Identifier'}
								value={ihi_data.ihi}
								// // For conformance 005845
								mask={[ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
								onChange={e=>{
									const value = e && e.target && e.target.value && e.target.value.toString && e.target.value.toString() || ''
									const new_num = value.replace(/[^0-9]/g, '')
									this.setState({
										[`manual_ihi_ihi`]: new_num,
										[`manual_ihi_resolved`]: null,
										manual_ihi_check: check_ihi(new_num),
										manual_ihi_valid: 'unchecked'
									}, async _=> { if (this.state.manual_ihi_check.res == 'ok') this.debounce_manual_ihi_verify(new_num, row) }) //ihi_data doesn't have the new ihi in it yet
								}}
								disabled={this.state.manual_ihi_verify_running}
							/>
						</div>
						{ // Show IHI errors
							this.state.manual_ihi_check && this.state.manual_ihi_check.res == 'err' &&
							<h4 className='txt_valid_invalid'><Icon name='exclamation' />Invalid IHI: {this.state.manual_ihi_check.err}</h4>
						}
						{ // Resolved IHI as per conformance testing
							this.state[`manual_ihi_resolved`] &&
							<React.Fragment>
								<h4 className='txt_valid_valid'><Icon name='checkmark' />This IHI has been resolved to {this.state[`manual_ihi_resolved`]}</h4>
								<p>This updated IHI will be saved as the patient's IHI and the resolved IHI will be saved to the patient's history</p>
							</React.Fragment>
						}
						<div className='field_cls'>
							<Label className='field_label'>IHI Status</Label>
							<Dropdown
								selection
								placeholder={'IHI Status'}
								options={ihi_stat_opt}
								//onChange={(e,d)=>{this.setState({manual_ihi_status: d.value})}}
								value={ihi_data.ihiStatus}
								//disabled={true}
							/>
						</div>
						<div className='field_cls'>
							<Label className='field_label'>IHI Record status</Label>
							<Dropdown
								selection
								placeholder={'IHI Record status'}
								options={ihi_rec_stat_opt}
								//onChange={(e,d)=>{this.setState({manual_ihi_rec_status: d.value})}}
								value={ihi_data.ihiRecordStatus}
								//disabled={true}
							/>
						</div>
						{(this.state.manual_ihi_valid == 'valid') && ihi_data.ihiRecordStatus != 'Verified' &&
							<React.Fragment>
								<h4 className='txt_valid_invalid'><Icon name='exclamation triangle' />Error: The patient's IHI Record Status is {ihi_data.ihiRecordStatus || 'Unknown'}</h4>
								<p>Only Verified IHI's are supported. This IHI cannot be saved.</p>
								<p>Please contact InstantScripts support or the HI Service Team through Medicare on <a href="tel:1300361457">1300 361 457</a></p>
							</React.Fragment>
						}
						<div className='field_cls'>
							<Label className='field_label'>IHI Record sex</Label>
							<Dropdown
								selection
								placeholder={'IHI Record sex'}
								options={ihi_sex_opt}
								//onChange={(e,d)=>{this.setState({manual_ihi_sex: d.value})}}
								value={ihi_data.ihiSex}
								//disabled={true}
							/>                        
						</div>
						{(this.state.manual_ihi_valid == 'valid') && ihi_data.ihiSex && ihi_data.ihiSex != j_f.obj_val(row, ['sex']) &&
							<React.Fragment>
								<h4 className='txt_valid_invalid'><Icon name='exclamation triangle' />Error: The patient's sex ({j_f.obj_val(row, ['sex']) || 'Unknown'}) is different from the IHI record sex ({ihi_data.ihiSex || 'Unknown'})</h4>
							</React.Fragment>
						}
						<div className='field_cls'>
							<Label className='field_label'>Patient record conflict</Label>
							<Dropdown
								selection
								placeholder={'Patient record conflict'}
								options={ihi_demo_conflict_opt}
								//onChange={(e,d)=>{this.setState({manual_ihi_democonflict: d.value})}}
								value={ihi_data.ihiDemoConflict}
								//disabled={true}
							/>
						</div>
												
						{(this.state.manual_ihi_valid == 'valid') && ihi_data.ihiDemoConflict == 'Yes' &&
							<React.Fragment><h4 className='txt_valid_checking'><Icon name='exclamation triangle' />Caution: There are duplicate records for this patient</h4><p>There are multiple records that contain the same demographic data as this patient's record. This indicates duplicate records. This warning will be stored with this IHI.</p></React.Fragment>
						}
					</Segment>
					
                    <h4>IHI verification</h4>
                    {
                        this.state.manual_ihi_verify_running && <p className='txt_valid_warning'>Verifying IHI...</p> ||
                        this.state.manual_ihi_valid == 'valid' && <p className='txt_valid_valid'>Valid IHI</p> ||
                        this.state.manual_ihi_valid == 'unchecked' && <p className='txt_valid_warning'>Unchecked. Please enter the patient's IHI.</p> ||
                        <React.Fragment><p className='txt_valid_invalid'>Invalid</p><p>This IHI number is not associated with this patient's demogrpahic information and can not be saved.</p><p>Please check the patient's demographic information or contact the HI Service Team through Medicare on <a href="tel:1300361457">1300 361 457</a></p></React.Fragment>
                    }                    

                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={_ => this.dont_assign_manual_ihi(row)} loading={this.state.assigning_ihi}>
                        Cancel
                    </Button>
                    <Button color='green' onClick={async _ => { this.assign_manual_ihi(row, ihi_data) }} loading={this.state.assigning_ihi || this.state.manual_ihi_verify_running} disabled={!valid_to_save}>
                        <Icon name='checkmark' /> Assign IHI
                    </Button>
                </Modal.Actions>

            </Modal>
		)
	}

	//	------------------------	------------------------	------------------------

	dont_assign_manual_ihi(row) {

		if (this.state.assigning_ihi) console.log('Already clicked set. Please wait...')
		else { this.setState({loading_ihi: false, [`manual_ihi`]: false}) }

	}

	//	------------------------	------------------------	------------------------

	assign_manual_ihi(row, ihi_data) {

		if (this.state.assigning_ihi) console.log('Already clicked set. Please wait...')
		else {
			this.setState({assigning_ihi: true}, async _=>{

				const demo_info = build_demo_info(row, false)
				const uid = row.uid

				demo_info.meta = {uid, assign_type: 'Manual'}

				const ihi_save_res = await hi.save_ihi({uid, ihi_info: ihi_data, demo_info})

				if (ihi_save_res.res == 'ok') {

					const verify_ihi_res = await hi.verify_ihi({ uid })

					this.handle_verify_ihi_res(row, verify_ihi_res)

					this.setState({loading_ihi: false, assigning_ihi: false, [`manual_ihi`]: false, refresh: new Date().getTime()})
				}
				else {

					let message = {type: 'negative', header:'Failed to save patient IHI', content: [ihi_save_res.err]}
			
					app.functions.add_message(message)

					this.setState({assigning_ihi: false})

				}

			})
		}

		//this.assign_manual_ihi(row, index, ihi_data)

	}

	//	------------------------	------------------------	------------------------

	render_ihi_history(r, i) {

		let rec = []

		

		if (r && r.ihi_history) rec = j_f.reverse_array(r.ihi_history)
		
		const table_headers = ['No', 'Date / Time', 'IHI', 'Status', 'Record Status', 'Record conflict', 'Assigned by', 'Assign type', 'Demographics']

		return (
			<React.Fragment>
				{r && r.ihi_history &&
					<div className='scroll_table'>
						<Table celled>
							<Table.Header>
								<Table.Row className='stick'>
									{table_headers.map((h, j) => {
										return <Table.HeaderCell key={`t_j_${j}`}>{h}</Table.HeaderCell>
									})}
								</Table.Row>

							</Table.Header>
							<Table.Body>
								{rec.map((o, j) => {
									return (
										<Table.Row key={`t_r_${j}`}>
											<Table.Cell>
												{j + 1}
											</Table.Cell>
											<Table.Cell>
												{o.tm}
											</Table.Cell>
											<Table.Cell>
												{o.ihinumber}
											</Table.Cell>
											<Table.Cell>
												{o.ihistatus}
											</Table.Cell>
											<Table.Cell>
												{o.ihirecordstatus}
											</Table.Cell>
											<Table.Cell>
												{o.demo_conflict || 'Unknown'}
											</Table.Cell>
											<Table.Cell>
												<Popup
													trigger={<p>{o.assignee && o.assignee.name}</p>}
													content={o.assignee && o.assignee.uid || '?'}
												/>
											</Table.Cell>
											<Table.Cell>
												{j_f.obj_val(o, ['demo_info', 'meta', 'assign_type']) || 'Unknown'}
											</Table.Cell>
											<Table.Cell>
												<TextArea
													disabled
													value={JSON.stringify(o.demo_info, null, 2)}
												/>
											</Table.Cell>
										</Table.Row>
									)
								})}
							</Table.Body>
						</Table>
					</div>
					|| <p>No IHI history</p>
				}

			</React.Fragment>
		)

	}

	// 		--------------------------------		--------------------------------		---------

	advanced_ihi_options (r, i) {

		const show_section = (!!j_f.obj_val(app, ['user', 'claims', 'admin'])) || (app && app.user && app.user.user_det && app.user.user_det.email == "demo+gp@instantscripts.com.au")

		return (
			show_section &&
			<React.Fragment>
				<Accordion fluid styled>

					<Accordion.Title onClick={_ => this.setState({ [`acc_e_${i}`]: !this.state[`acc_e_${i}`] })} content='Advanced options' />

					<Accordion.Content active={this.state[`acc_e_${i}`]}>

						<h4>IHI error logging</h4>

						<Button color='red' icon='exclamation' content='Make error 1' loading={this.state.error_running} disabled={this.state.error_running} onClick={_ => this.run_conform({type: 'ihi_error_1'})} label={{ basic: true, color: 'red', pointing: 'left', content: 'No medicare number' }} /><br /><br />

						<Button color='red' icon='exclamation' content='Make error 2' loading={this.state.error_running} disabled={this.state.error_running} onClick={_ => this.run_conform({type: 'ihi_error_2'})} label={{ basic: true, color: 'red', pointing: 'left', content: 'Broken message signature' }} /><br /><br />

						<Button color='red' icon='exclamation' content='Make error 3' loading={this.state.error_running} disabled={this.state.error_running} onClick={_ => this.run_conform({type: 'ihi_error_3'})} label={{ basic: true, color: 'red', pointing: 'left', content: 'Broken message credentials' }} />

						<h4>Force IHI</h4>

						{this.render_force_ihi(r, i)}

						<h4>Manual IHI lookup</h4>

						{this.render_manual_ihi_lookup(r, i)}

					</Accordion.Content>

				</Accordion>
			</React.Fragment>
		)

	}

	// 		--------------------------------		--------------------------------		---------

	async run_conform(params) {
		if (this.state.error_running) console.log('Already running, please wait...')
		else this.setState({ error_running: true }, async _ => {
			const hi = new hi_model()
			const save_res = await hi.ihi_conformance(params)
			console.log('Error result')
			console.log(save_res)
			this.setState({ error_running: false })
		})
	}

	//	------------------------	------------------------	------------------------

	render_manual_ihi_lookup(r, i) {
		
		return (
			<React.Fragment>
				<p>Click add current user. Send. Look in console.</p>
				<p>Change the user details in the request data in params.</p>
				<p>Change the search type in param_type ('ihi', 'uaddress', 'medicare', 'dva')</p>
				<Button color='yellow' icon='plus' content='Add current user details to search' disabled={this.state[`manual_ihi_loading_${i}`]} onClick={_ => this.add_user_manual_ihi_lookup(r, i)}/>
				<Button color='red' icon='close' content='Reset' disabled={this.state[`manual_ihi_loading_${i}`]} onClick={_=>{this.setState({[`manual_ihi_json_${i}`]: JSON.stringify({params: [], param_type: []}, null, 2)})}}/><br /><br />
				<p>Request data:</p>
				<TextArea
					value={this.state[`manual_ihi_json_${i}`] || JSON.stringify({params: [], param_type: []}, null, 2)}
					onChange={(e,d) => this.setState({[`manual_ihi_json_${i}`]: d.value})}
				/>
				<br />
				<Button color='green' icon='checkmark' content='Send manual IHI lookup' loading={this.state[`manual_ihi_loading_${i}`]} disabled={this.state[`manual_ihi_loading_${i}`]} onClick={_ => this.run_manual_ihi_lookup(i)} label={{ basic: true, color: 'green', pointing: 'left', content: 'For NOC' }} /><br />
			</React.Fragment>
		)

	}

	//	------------------------	------------------------	------------------------

	async add_user_manual_ihi_lookup(row) {
		try {
			let new_params = this.state[`manual_ihi_json`] && JSON.parse(this.state[`manual_ihi_json`]) || {params: [], param_type: []}
			if (typeof new_params != 'undefined' && new_params != null && typeof new_params.params != 'undefined' && typeof new_params.param_type != 'undefined'){
				const demo_data = build_demo_info(row, false, true)
				new_params.params.push(demo_data)
				new_params.param_type.push('auto')
				console.log(`Added ${JSON.stringify(demo_data)} - 'auto'`)
				this.setState({[`manual_ihi_json`]: JSON.stringify(new_params, null, 2)})
			} else console.log('Bad JSON structure. Could not add patient details.')
		} catch (e) { console.log(`Could not parse JSON: ${e}`) }
	}

	//	------------------------	------------------------	------------------------

	async run_manual_ihi_lookup() {
		console.log('Manual IHI')
		if (this.state[`manual_ihi_loading`]) console.log('Already running, please wait...')
		else this.setState({ [`manual_ihi_loading`]: true }, async _ => {
			try {
				let params = JSON.parse(this.state[`manual_ihi_json`])
				const ihi_manual_res = await hi.ihi_lookup_manual({params, param_type: 'ihi'})
				console.log(ihi_manual_res)
				console.log(moment().format())
			} catch (e) { console.log(`Error running manual: ${e}`) }
			this.setState({ [`manual_ihi_loading`]: false})
		})
	}

	//	------------------------	------------------------	------------------------

	render_force_ihi(row) {

		// Build the options for the IHI statis and record status (although these will probably always be one value)
        const ihi_stat_opt = ihi_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o}})
        const ihi_rec_stat_opt = ihi_rec_status_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o}})
        const ihi_sex_opt = Object.keys(ihi_sex_option).map((o, i)=>{ return { key:`o_${i}`, text: o, value: ihi_sex_option[o]}})
        const ihi_demo_conflict_opt = ihi_demo_conflict_options.map((o, i)=>{ return { key:`o_${i}`, text: o, value: o}})

		const ihi_data = {
            ihi: this.state[`manual_ihi_ihi`],
			ihiRecordStatus: this.state[`manual_ihi_ihiRecordStatus`],
            ihiStatus: this.state[`manual_ihi_ihiStatus`],
			ihiSex: this.state[`manual_ihi_sex`],
			ihiDemoConflict: this.state[`manual_ihi_ihiDemoConflict`],
		}

		return (
			<React.Fragment>

				<div className='field_cls'>
					<Label className='field_label'>Individual Healthcare Identifier (IHI)</Label>
					<MaskedInput
						placeholder={'Individual Healthcare Identifier'}
						value={ihi_data.ihi}
						mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
						onChange={e => {
							const value = e && e.target && e.target.value && e.target.value.toString && e.target.value.toString() || ''
							const new_num = value.replace(/[^0-9]/g, '')
							this.setState({ [`manual_ihi_ihi`]: new_num})
						}}
					/>
				</div>

				<div className='field_cls'>
					<Label className='field_label'>IHI Status</Label>
					<Dropdown
						selection
						placeholder={'IHI Status'}
						options={ihi_stat_opt}
						onChange={(e,d)=>{this.setState({[`manual_ihi_ihiStatus`]: d.value})}}
						value={ihi_data.ihiStatus}
					/>
				</div>

				<div className='field_cls'>
					<Label className='field_label'>IHI Record status</Label>
					<Dropdown
						selection
						placeholder={'IHI Record status'}
						options={ihi_rec_stat_opt}
						onChange={(e,d)=>{this.setState({[`manual_ihi_ihiRecordStatus`]: d.value})}}
						value={ihi_data.ihiRecordStatus}
					/>
				</div>

				<div className='field_cls'>
					<Label className='field_label'>IHI Record sex</Label>
					<Dropdown
						selection
						placeholder={'IHI Record sex'}
						options={ihi_sex_opt}
						onChange={(e,d)=>{this.setState({[`manual_ihi_sex`]: d.value})}}
						value={ihi_data.ihiSex}
					/>
				</div>

				<div className='field_cls'>
					<Label className='field_label'>Patient record conflict</Label>
					<Dropdown
						selection
						placeholder={'Patient record conflict'}
						options={ihi_demo_conflict_opt}
						onChange={(e,d)=>{this.setState({[`manual_ihi_ihiDemoConflict`]: d.value})}}
						value={ihi_data.ihiDemoConflict}
					/>
				</div><br />

				<Button color='green' icon='checkmark' content='Force IHI save' loading={this.state.loading_ihi} disabled={this.state.loading_ihi} 
				onClick={_=> this.run_force_ihi(row, ihi_data)}
				label={{ basic: true, color: 'green', pointing: 'left', content: 'For status change tests' }} /><br />

			</React.Fragment>
		)

	}

	//	------------------------	------------------------	------------------------

	async run_force_ihi(row, ihi_data) {
		if (this.state.loading_ihi) console.log('Already running, please wait...')
		else this.setState({ loading_ihi: true }, async _ => {
			const demo_info = build_demo_info(row, false)
			const uid = row.uid
			demo_info.meta = { uid, assign_type: 'Manual (CONFORMANCE)' }
			const ihi_save_res = await hi.save_ihi({ uid, ihi_info: ihi_data, demo_info })
			console.log(ihi_save_res)
			this.setState({ loading_ihi: false, refresh: new Date().getTime() })
		})
	}

	//	------------------------	------------------------	------------------------

	async run_auto_ihi(row) {

		if (this.state.loading_ihi) console.log('Already loading...')
		else this.setState({loading_ihi: true}, async _=> {
			const auto_ihi_res = await hi.auto_ihi({ uid: row.uid })
			this.handle_auto_ihi_res(row, auto_ihi_res)
			this.setState({ loading_ihi: false, refresh: new Date().getTime() })
		})

	}

	//	------------------------	------------------------	------------------------

	async run_verify_ihi(row) {

		if (this.state.loading_ihi) console.log('Already loading...')
		else this.setState({loading_ihi: true}, async _=> {
			const verify_ihi_res = await hi.verify_ihi({ uid: row.uid })
			this.handle_verify_ihi_res(row, verify_ihi_res)
			this.setState({ loading_ihi: false, refresh: new Date().getTime() })
		})

	}

	//	------------------------	------------------------	------------------------

	async run_delete_ihi(row) {

		if (this.state.loading_ihi) console.log('Already loading...')
		else this.setState({loading_ihi: true}, async _=> {
			const delete_ihi_res = await hi.reset_ihi({ uid: row.uid })
			this.handle_delete_ihi_res(row, delete_ihi_res)
			this.setState({ loading_ihi: false, refresh: new Date().getTime() })
		})

	}

	//	------------------------	------------------------	------------------------

	async run_manual_ihi(row) {

		if (this.state.loading_ihi) console.log('Already loading...')
		else this.setState({
			loading_ihi: true,
			manual_ihi_valid: 'unchecked',
			[`manual_ihi`]: true,
			[`manual_ihi_ihi`]: '',
			[`manual_ihi_ihiRecordStatus`]: '',
			[`manual_ihi_ihiStatus`]: '',
			[`manual_ihi_sex`]: '',
			[`manual_ihi_ihiDemoConflict`]: '',
			[`manual_ihi_resolved`]: null,
		})

	}

	//	------------------------	------------------------	------------------------

	handle_add_user_res(res) {

		console.log('handle_add_user_res - res')
		console.log(res)

		if (res && res.res == 'ok'){

			// Check the auto assign
			let message = {type: 'positive', header:'Successfully saved new patient', content: [`Now automatically assigning the patient's IHI`]}			

			app.functions.add_message(message)

			this.handle_auto_ihi_res({}, res.assign_auto_ihi)

		} else {

			let message = {type: 'negative', header:'Failed to save patient', content: [res.err || res.err_msg || '?']}			
			app.functions.add_message(message)

		}

		// TODO - handle all the things here

	}

	//	------------------------	------------------------	------------------------

	handle_delete_ihi_res(row, res) {

		let message
		
		if (res.res == 'ok') message = {type: 'positive', header:'Patient IHI reset', content: [`Removed IHI for ${row.email}`]}
		else message = {type: 'negative', header:'Failed to reset IHI', content: [res.err]}

		app.functions.add_message(message)

	}

	//	------------------------	------------------------	------------------------

	handle_verify_ihi_res(row, res) {

		let message
		
		if (res.res == 'ok'){
			const msg_content = [`Verified IHI for account ${row.email}`]

			if (res.change) msg_content.push(res.change)

			message = {type: 'positive', header:'Patient IHI is up to date', content: msg_content}
		}
		else message = {type: 'negative', header:'Failed to verify patient IHI', content: [res.err]}

		app.functions.add_message(message)

	}

	//	------------------------	------------------------	------------------------

	handle_auto_ihi_res(row, res) {

		let message
		
		if (res.res == 'ok'){
			let msg_type = 'positive'
			let ihi_info = j_f.obj_val(res, ['ihi_verify_res', 'ihi_info']) || {}

			let msg_content = [`Assigned IHI ${format_ihi(ihi_info.ihi) || 'Unknown IHI'} (${ihi_info.ihiRecordStatus || 'Unknown Record status'} / ${ihi_info.ihiStatus || 'Unknown IHI status'})`]

			// Demo conflict
			if (ihi_info.ihiDemoConflict == 'Yes'){
				// Get the conflicting UIDs
				let conflicts = {}
				let demos = j_f.object_values_headless_key_path(res, ['demo_users'])
				demos.forEach(d=>{ if (d.value) d.value.forEach(c=>{ conflicts[c.uid] = c }) })
				msg_type = 'warning'
				msg_content.push(`Caution: there are duplicate records for this patient.`)
				msg_content.push(`Please contact InstantScripts support to rectify these records.`)
			}

			if (res.sex_note) msg_content.push(res.sex_note)
			message = {type: msg_type, header:'Patient IHI assigned', content: msg_content}
		}
		else message = {type: 'negative', header:'Failed to assign patient IHI', content: [res.err]}

		app.functions.add_message(message)

	}

	//	------------------------	------------------------	------------------------

	async verify_manual_ihi(ihi, row) {

		if (this.state.manual_ihi_verify_running) {

			console.log('Already running IHI check')

		} else {
			this.setState({ manual_ihi_verify_running: true }, async _ => {

				const search_params = build_demo_info(row, true)

				search_params.ihi = ihi

				// Add other info for conformance
				search_params.meta = { uid: j_f.obj_val(row, ['uid']) || '' }

				const ihi_lookup_res = await API_service.load_data('ihi/lookup_manual', {params: search_params, param_type: 'ihi'})

				// The IHI result has returned. Now we can check things...

				let returned_ihiRecordStatus = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_rs) || '' //|| 'Unverified'
				let returned_ihiStatus = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_s) || '' //|| 'Active'
				let returned_ihi = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi) || ''
				let returned_ihiDemoConflict = j_f.object_value_key_path(ihi_lookup_res, obj_path_rec_conflict) && 'Yes' || 'No'
				let returned_sex = j_f.object_value_key_path(ihi_lookup_res, obj_path_ihi_sex) || '' //|| 'U'

				returned_ihi = ihi_from_uri(returned_ihi)

				//this.setState({ manual_ihi_valid: 'invalid', manual_ihi_verify_running: false })
				this.setState({
					manual_ihi_valid: (returned_ihi && returned_ihi.length == 16) && (returned_sex == search_params.sex) && 'valid' || 'invalid',
					[`manual_ihi_sex`]: returned_sex,
					[`manual_ihi_ihiStatus`]: returned_ihiStatus,
					[`manual_ihi_ihiRecordStatus`]: returned_ihiRecordStatus,
					[`manual_ihi_ihiDemoConflict`]: returned_ihiDemoConflict,
					[`manual_ihi_resolved`]: returned_ihi != search_params.ihi && returned_ihi || null,
					manual_ihi_verify_running: false })
			})
		}

	}
	

}


function format_ihi(number) {
	if (typeof number == 'string' && number.length == 16) return `${number.slice(0, 4)} ${number.slice(4, 8)} ${number.slice(8, 12)} ${number.slice(12, 16)}`
	return undefined
}

//	------------------------	------------------------	------------------------

// Checks the IHI is properly formatted as per conformance (005819)
function check_ihi(value) {

	const ihi_len = 16

	if (typeof value != 'string' || value == '')    return {res: 'err', err: `No IHI provided`}// Provided: ${typeof value}, value: ${JSON.stringify(value)}`}
	if (value.length != ihi_len)                    return {res: 'err', err: `IHI must be ${ihi_len} digits long. Provided IHI length: ${value.length}`}//, value: ${value}`}
	//Luhn check algorithm
	value = value.replace(/\D/g, "")

	let nCheck = 0, bEven = false
	for (let n = value.length - 1; n >= 0; n--) {
		let nDigit = parseInt(value[n], 10);
		if (bEven && ((nDigit *= 2) > 9)) nDigit -= 9
		nCheck += nDigit
		bEven = !bEven
	}
	const valid = (nCheck % 10) == 0
	if (!valid)                                     return {res: 'err', err: `IHI fails validation check. Please check each digit.`}
	return {res: 'ok'}

}

//	------------------------	------------------------	------------------------

function build_demo_info(row, ihi_based_search_only = false, include_ihi = false) {

	// As per 006105

	let info = {}
	let address = {}

	const dob = j_f.obj_val(row, ['dob'])
	const family_name = j_f.obj_val(row, ['last_name'])
	const given_name = j_f.obj_val(row, ['first_name'])
	const sex = j_f.obj_val(row, ['sex'])

	if (typeof dob == 'string') info.dob = moment(dob).format('YYYY-MM-DD')
	if (typeof family_name == 'string') info.family_name = family_name
	if (typeof given_name == 'string') info.given_name = given_name
	if (typeof sex == 'string') info.sex = sex

	if (!ihi_based_search_only){

		const medicare = j_f.obj_val(row, ['medicare'])
		if (typeof medicare == 'string') info.medicare = medicare.replace(/\D/g, '').slice(0, 10)

		const line1 = j_f.obj_val(row, ['full_address', 'address'])
		const line2 = j_f.obj_val(row, ['full_address', 'address2'])
		const suburb = j_f.obj_val(row, ['full_address', 'suburb'])
		const state = j_f.obj_val(row, ['full_address', 'state'])
		const postcode = j_f.obj_val(row, ['full_address', 'postcode'])

		if (typeof line1 == 'string' && line1 != '') address.line1 = line1
		if (typeof line2 == 'string' && line2 != '') address.line2 = line2
		if (typeof suburb == 'string' && suburb != '') address.suburb = suburb
		if (typeof state == 'string' && state != '') address.state = state
		if (typeof postcode == 'string' && postcode != '') address.postcode = postcode

		if (Object.keys(address).length > 0) info.address = address

		const dva = j_f.obj_val(row, ['dva'])
		if (dva && dva.number) info.dva_number = dva.number

	}

	if (include_ihi){
		const ihi = j_f.obj_val(row, ['extras', 'ihi', 'ihi'])
		if (ihi) info.ihi = ihi
	}

	return j_f.clone(info)
	
}

//	------------------------	------------------------	------------------------

function core_demo_info(row) {

	// As per 006105

	let info = build_demo_info(row, true)

	if (info.given_name) delete info.given_name

	return j_f.clone(info)
	
}

//	------------------------	------------------------	------------------------

function ihi_from_uri(uri) {
	if (typeof uri != 'string') return ''
	const ihi_split = uri.split('/')
	return ihi_split[ihi_split.length - 1] 
}

export default UserIHI;
