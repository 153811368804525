import React from 'react';
import { Container } from 'semantic-ui-react';
import Slider from '../../NUI/Slider';

const IcosmAppBanner = ({title, desc, slides}) => {

    return  <div className={`bg-icosm-primary text-icosm-secondary pt-10 text-center`} id="operate">
                <h2 className="text-center w-2/3 mx-auto">{title}</h2>
                <p className="text-icosm_md md:text-icosm_base mb-0">{desc}</p>

                <Slider data={slides} type="icosm_app" clsn="rounded" wrap_text cont="!max-w-[700px] mx-auto" img_cls=""/>

            </div>
}

export default IcosmAppBanner;


