/*eslint-env es6*/
import React, { useCallback, useEffect, useState } from "react";
import { Table, Popup, Button, Icon, Modal, Input } from "semantic-ui-react";
import API_service from "xAppLib/providers/API_service";
import { useAsync } from 'xAppLib/Hooks/useAsync';


export const Print = ({script,open = false,onClose, ev = false}) => {
	
	const [pdf_link,setPdfLink] = useState(null)
	useEffect(()=>{
		
		(async()=>{
			if (open) {
				const hash_res = await API_service.load_data('escripts/get_script_hash', {sid: script.sid})
				setPdfLink( API_service.API_BASE_URL+`viewScript?s=${script.sid}&view=print&hash=${hash_res&&hash_res.secret||'?'}${ev?'&format=ev':''}`/*&Authorization=Bearer ${app.user.token}`*/)
			}
			
		})()
		// const pdf_link_min = API_service.API_BASE_URL+`viewScript?s=${script.sid}&view=print`
		// this.setState({[`print_${index}`]: false, view_print: true, pdf_link})//, pdf_link_min})
	},[script.sid, open])
	return <Modal
		closeIcon
		open={open}
		onClose={onClose}
	>
		<Modal.Header>Print Prescription</Modal.Header>
		<Modal.Content>
			{pdf_link && <iframe src={pdf_link} style={{width:'100%',height:'70vh'}} frameBorder="0"/> || "Loading..."}
			{/* <p><a href={this.state.pdf_link} target='_blank'>{this.state.pdf_link_min}</a></p> */}
			<h4 className='txt_valid_fail'><Icon name='exclamation triangle' />Caution: DO NOT distribute copies of this electronic PDF to ANYONE (patients, doctors, pharmacies, etc.)</h4>
			<p>Please use the email / SMS button to send electronic scripts which contains anonymised information to protect patient privacy</p>
		</Modal.Content>
		<Modal.Actions>
			<Button color='green' onClick={onClose}>
				<Icon name='checkmark' /> Done
			</Button>
		</Modal.Actions>
	</Modal>;
}

const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const Email = ({script,open = false,onClose}) => {

	const [email, setEmail] = useState(!script.epresc?.send_to_pharmacy ? script.epresc?.patient?.PatientEmail||script.spd_data.email||'' : '')
	const [sending,setSending] = useState(false)
	const [error,setError] = useState(false)

	const authorised = !script.epresc?.send_to_pharmacy


	const onChange = useCallback(async (value)=>{
		const error = !email_regex.test(value)
		setEmail(value)
        setError(error)
	},[])

	const sendEmail = useCallback(async ()=>{
		setSending(true)
		const email_res = await API_service.load_data('escripts/email_script', {sid: script.sid, email: email}) 
		setSending(false)
        console.log('email_res',email_res)
		app.functions.add_message('Send Email', email_res?.res=='ok'?'Success':'Failed to send email', email_res?.res=='ok'?'positive':'negative')
	},[script,email])
	return <Modal
		closeIcon
		open={open}
		onClose={onClose}
		>
		<Modal.Header>Send Electronic Prescription via Email</Modal.Header>
		<Modal.Content>
		{!authorised && <>
						{script.epresc?.send_to_pharmacy && <p><strong><Icon name='warning circle' color='red' /> Warning: Script should not be sent to patient (Send to pharmacy: {script.epresc.send_to_pharmacy}).</strong></p>}
					</>||<>
					<p>A copy of the electronic prescription will be sent to this email address:</p>
						<Input
							fluid
							defaultValue={email}
							icon='envelope'
							iconPosition='left'
							placeholder='Email'
							name='email'
							onChange={(e,d) => {onChange(d?.value)}}
							error={error}
						/>
						<br/><p style={{fontSize: '0.8em'}}>Note: An email and SMS are automatically sent to the patient on script creation emergency supply and send to pharmacy scripts.</p>

					</>}
			
		</Modal.Content>
		<Modal.Actions>
			<Button color='red' onClick={onClose} disabled={sending} loading={sending}>
				<Icon name='remove' /> Cancel
			</Button>
			{authorised && <Button color='green' onClick={sendEmail} disabled={error || sending} loading={sending}>
				<Icon name='checkmark' /> Send email
			</Button>}
		</Modal.Actions>
		</Modal>;
}


const sms_regex = /^(\+\d{1,3}[- ]?)?\d{10}$/

export const SMS = ({script,open = false,onClose}) => {

	const [sms, setSMS] = useState(!script.epresc?.send_to_pharmacy ? script.epresc?.patient?.PatientMobileNumber||script.spd_data?.mobile||'' : '')
	const [sending,setSending] = useState(false)
	const [error,setError] = useState(false)

	const authorised = !script.epresc?.send_to_pharmacy

	const onChange = useCallback(async (value)=>{
		const error = !sms_regex.test(value)
		setSMS(value)
        setError(error)
	},[])

	const sendSms= useCallback(async ()=>{
		setSending(true)
        const sms_res = await API_service.load_data('escripts/sms_script', {sid: script.sid, mobile: sms}) 
		setSending(false)
        console.log('sms_res',sms_res)
		app.functions.add_message('Send Email', sms_res?.res=='ok'?'Success':'Failed to send sms', sms_res?.res=='ok'?'positive':'negative')

	},[script,sms])
	return <Modal
		closeIcon
		open={open}
		onClose={onClose}
		>
        <Modal.Header>Send Electronic Prescription via SMS</Modal.Header>
                <Modal.Content>
					{!authorised && <>
						{script.epresc?.send_to_pharmacy && <p><strong><Icon name='warning circle' color='red' /> Warning: Script should not be sent to patient (Send to pharmacy: {script.epresc.send_to_pharmacy}).</strong></p>}
					</>||<>
						<p>A copy of the electronic prescription will be sent to this mobile number:</p>
						<Input
							fluid
							defaultValue={sms}
							icon='envelope'
							iconPosition='left'
							placeholder='Email'
							name='email'
							onChange={(e,d) => {onChange(d?.value)}}
							error={error}
						/>
						<br/><p style={{fontSize: '0.8em'}}>Note: An email and SMS are automatically sent to the patient on script creation except emergency supply and send to pharmacy scripts. </p>
					</>}
                   
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={onClose} disabled={sending} loading={sending}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    {authorised && <Button color='green' onClick={sendSms} disabled={error || sending} loading={sending}>
                        <Icon name='checkmark' /> Send SMS
                    </Button>}
                </Modal.Actions>
      
		</Modal>;
}

export const Status = ({script,open = false,onClose}) => {

	const action = useAsync({
		immediate: open,
		fn: async () =>  API_service.load_data('escripts/status', {sid: script.sid})
	}, [script]);

	const { data, loading, error } = action

	const epresc = data?.epresc || script.epresc || {}
	const ePrescription = epresc?.epresc_data?.ePrescription

	
	return <Modal
		closeIcon
		open={open}
		onClose={onClose}
		>
        <Modal.Header>eScript Status</Modal.Header>
                <Modal.Content>


					{loading && <p><Icon loading name='spinner' /> Fetching latest</p>}
					
					{error && <p>Something went wrong – {JSON.stringify(error)}</p>}

					{data && !loading && <>
						<p><strong>Status</strong>: {epresc.state || 'Submitted'}</p>
						<p><strong>Dispensing Pharmacy</strong>: {(ePrescription?.Items[0].Item.Content.Pharmacy) &&
												((pharmacy,dispenseditem)=><>
															<br/>
															{pharmacy.PharmacyName}<br/>
															{pharmacy.PharmacyAddress1}<br/>
															{pharmacy.PharmacySuburb} {pharmacy.PharmacyState} {pharmacy.PharmacyPostcode}<br/>
															Pharmacist: {pharmacy.PharmacistFirstName} {pharmacy.PharmacistFamilyName}<br/>
															Phone: {pharmacy.PharmacyPhoneNumber}<br/>
															Date: {dispenseditem?.DispenseDate}<br/>
														</>
												)(ePrescription?.Items[0].Item.Content.Pharmacy,ePrescription?.Items[0].Item.Content.DispensedItem) || "Not dispensed"}
						</p>
					</>}
                   
                </Modal.Content>
                <Modal.Actions>
                    <Button  onClick={onClose} >
                         Close
                    </Button>
                   
                </Modal.Actions>
      
		</Modal>;
}