/* eslint-env node */

//	------------------------	------------------------	------------------------
//	Description: base component within an erx script
//  eRx script -> item[s] -> content -> clinician, patient, PRESCRIBED ITEM
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const get_set =     require('../../helpers/erx/get_set_function')
const erx_formats = require('../erx/resources/erx_formats')
const erx_props =   require('../erx/resources/erx_props')
const moment =      require('moment-timezone')

const j_f =         require('../../helpers/json_functions')

const num_convert = require('number-to-words')
const { SCRIPT_PROPS } = require('../erx/resources/erx_props')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DEFAULTS = {

}

const qty_ids = ['Qty ', 'Qty: ', 'Quantity ', 'Quantity: ']

const doc_note_presets = { // Conformance PRES-18B
    'D': ['for dental treatment only'],
    'F': ['for midwifery use only'],
    'E': ['for optometry use only', 'for ocular treatment only'],
    'T': ['for podiatric treatment only', 'for treatment of foot conditions only'],
    'V': ['for veterinary use only'],
}

const doc_note_presets_all = [].concat(...Object.values(doc_note_presets))

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class erx_prescribeditem_model {

    constructor(prescribeditem_data, run_validation = true) {

        this._prescribeditem_data = {}
        this.setup_props()

        if (prescribeditem_data){
            if (run_validation) this.prescribeditem_data = prescribeditem_data
            else this._prescribeditem_data = prescribeditem_data
        } 
        else this.set_default()

    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get prescribeditem_data() { return this._prescribeditem_data }
    set prescribeditem_data(val) {
        this._prescribeditem_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------

    set_blank() {// You probably don't want to call this
        this.clear()

        erx_props.PRESCRIBED_ITEM_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })

        //Some more special magic
        //this.ItemBarcode = 0 // erx xsd doesn't like this as null
        //this.PBS_DVAAuthorityNumber = 0
    }

    //	------------------------	------------------------	------------------------

    set_default() { 
        this.set_blank()
        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))
        this.PrescriptionDate = moment().format('YYYY-MM-DDTHH:mm:ss')
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {
        
        // Fix self
        let result = {res: 'ok', fixes: [], err: []}

        for (let i = 0; i < erx_props.PRESCRIBED_ITEM_PROPS.length; i++){
            const c_prop = erx_props.PRESCRIBED_ITEM_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format - see if we can convert any strings to expected format
            if ((typeof c_item !== 'undefined') && c_item !== '' && c_item !== null && c_prop.format){ //Note - content props for patient, clinician and item wont have a format and will be ignored
                
                const formatter = erx_formats.ERX_FORMAT[c_prop.format]
                if (!formatter || !formatter.validate) result.err.push('Cannot find validator for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                else {

                    const valid_res = formatter.validate(c_item)

                    if (valid_res.res != 'ok'){


                        if (remove_errors) this[c_prop.name] = null // The value may be fixed below

                        if (!formatter.fromValue) result.err.push('Cannot find formatter (fromValue function) for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                        else {

                            const from_res = formatter.fromValue(c_item)

                            if (from_res.res != 'ok') result.err.push('Could not parse item to type ' + c_prop.format + ' for prop ' + c_prop.name + '. Error: ' + from_res.err)
                            else {

                                result.fixes.push( { name: c_prop.name, from: c_item, to: from_res.val } )
                                this[c_prop.name] = from_res.val

                            }
                        }
                    } 
                }              
            }
        }
        
        if (result.err.length > 0) result.res = 'err'
        else delete result.err
        return result

    }

    //	------------------------	------------------------	------------------------

    check_valid(params = {}) {

        let result = {res: 'ok'}

        for (let i = 0; i < erx_props.PRESCRIBED_ITEM_PROPS.length; i++){
            const c_prop = erx_props.PRESCRIBED_ITEM_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format
            if (typeof c_item != 'undefined' && c_item != '' && c_item != null && c_prop.format){
                const check_format_res = erx_formats.check_erx_format(c_item, c_prop.format)
                if (check_format_res.res != 'ok')
                    result = {
                        ...result,
                        res: 'err',
                        err: [...(result.err || []), (c_prop.label + ' is wrong format. ' + check_format_res.err)],
                        err_fields: [...(result.err_fields || []), c_prop.name]
                    }
                //return {res: 'err', err: c_prop.name + ' is wrong format. ' + check_format_res.err}
            }

            // Check the length
            if (typeof c_item != 'undefined' && c_item != null && c_prop.length){
                if (c_item.toString().length > c_prop.length)
                    result = {
                        ...result,
                        res: 'err',
                        err: [...(result.err || []), (c_prop.label + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString())],
                        err_fields: [...(result.err_fields || []), c_prop.name]
                    }
                    //return {res: 'err', err: c_prop.name + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString()}
            }

            // Check max occurance
            if (!c_prop.minOccurs === 0){
                if (typeof c_item === 'undefined')
                    result = {
                        ...result,
                        res: 'err',
                        err: [...(result.err || []), (c_prop.label + ' is must have at least one occurance but has none. ')],
                        err_fields: [...(result.err_fields || []), c_prop.name]
                    }
                    //return {res: 'err', err: c_prop.name + ' is must have at least one occurance but has none. Value: ' + c_item}
            }

            //Check nillable
            if (!c_prop.nillable === true){
                if (c_item === null && !c_prop.minOccurs === 0)
                    result = {
                        ...result,
                        res: 'err',
                        err: [...(result.err || []), (c_prop.label + ' is not nillable but is null. ')],
                        err_fields: [...(result.err_fields || []), c_prop.name]
                    }
                    //return {res: 'err', err: c_prop.name + ' is not nillable but is null. Value: ' + c_item}
            }

            // Check the mandatory
            if (c_prop.mandatory && c_prop.mandatory({...params, item: this})){
                if (typeof c_item == 'undefined' || c_item == null)
                    result = {
                        ...result,
                        res: 'err',
                        err: [...(result.err || []), (c_prop.label + ' is mandatory but absent. ')],
                        err_fields: [...(result.err_fields || []), c_prop.name]
                    }
                    //return {res: 'err', err: c_prop.name + ' is mandatory but absent. Value: ' + c_item}
            }           

        }

        // Additional checks
        // Check QTY and repeats
        // const qty_check_res = this.check_qty(params)
        // if (qty_check_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), qty_check_res.err]}

        // const repeat_check_res = this.check_repeat(params)
        // if (repeat_check_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), repeat_check_res.err]}

        // Controlled drug quantity words
        const qty_words_res = this.check_controlled_qty_words(params) // Check S8 having annotation of quantity
        if (qty_words_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), qty_words_res.err]}

        // for xyz use
        const xyz_use_check_res = this.check_xyz_use_annotation(params) // Check has annotation for prescriber type
        if (xyz_use_check_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), xyz_use_check_res.err]}

        // Minimum interval
        const min_int_res = this.check_minimum_interval(params) // Check minimum interval and as appropriate schedule drugs PRES-18
        if (min_int_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), min_int_res.err]}

        // // Instructions
        const pt_inst_res = this.check_pt_instructions(params) // Check has instructions
        if (pt_inst_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), pt_inst_res.err]}

        return result

    }

    // //	------------------------	------------------------	------------------------

    // check_qty(params) {

    //     // If PBS, make sure less than max

    //     let result = {res: 'ok'}

    //     const PrivatePrescription = j_f.obj_val(params, ['item', 'PrivatePrescription'])

    //     if (!PrivatePrescription){ // Only check if PBS?

    //         const max_qty = j_f.obj_val(params, ['item_raw', 'Quantity'])

    //         if (typeof max_qty != 'undefined'){

    //             const qty = j_f.obj_val(params, ['item_raw', 'Quantity'])

    //             if (qty && qty > max_qty) result = {...result, res: 'err', err: `Quantity (${qty}) greater than maximum allowed (${max_qty})`}

    //         }

    //     }


    //     return result

    // }

    // //	------------------------	------------------------	------------------------

    // check_repeat(params) {

    //     // If PBS, make sure less than max

    //     let result = {res: 'ok'}

    //     console.log('check max repeats')

    //     return result

    // }

    //	------------------------	------------------------	------------------------

    check_xyz_use_annotation(params) {

        let result = {res: 'ok'}

        const prescriber_type = j_f.obj_val(params, ['clinician', 'PrescriberType'])
       
        result = {...result, prescriber_type}

        if (prescriber_type) {

            const expected_annotation = doc_note_presets[prescriber_type]

            if (expected_annotation && Array.isArray(expected_annotation)){

                let has_correct_annotation = false, xyz_use_annotations = 0

                const supply_numbers = j_f.obj_val(params, ['item', 'Annotations', '0', 'Annotation', 'SupplyNumber'])
                const annotations = j_f.obj_val(params, ['item', 'Annotations', '0', 'Annotation', 'Value'])

                result = {...result, expected_annotation: expected_annotation[0]}
                
                if (annotations && Array.isArray(annotations)){

                    if (supply_numbers && Array.isArray(supply_numbers)){

                        const annotation_index = supply_numbers.findIndex(v => {return v === '0' || v === 0})

                        if (annotation_index > -1 && annotation_index < annotations.length){

                            const annotation = annotations[annotation_index]

                            if (typeof annotation == 'string'){

                                const annotation_split = annotation.split(',')

                                for (let i = 0; i < annotation_split.length; i++){

                                    const c_text = annotation_split[i].toLowerCase()

                                    if (doc_note_presets_all.find(o => {return o.toLowerCase() == c_text}) ){

                                        xyz_use_annotations += 1

                                        for (let j = 0; j < expected_annotation.length; j++){
    
                                            const c_expected_annotation = expected_annotation[j]
    
                                            if (c_text == c_expected_annotation.toLowerCase()) has_correct_annotation = true
            
                                            if (xyz_use_annotations > 1) {
                                                result = {...result, res: 'err', err: `More than one xyz use only supplied. Only have the one xyz use only annotation: ${expected_annotation[0]}`}
                                                
                                                j = expected_annotation.length //break - now need to break 2
                                                i = annotation_split.length
                                            }
    
                                        }

                                    }

                                }

                                if (!has_correct_annotation) result = {...result, res: 'err', err: `Missing annotation for xyz use only, but annotations do not contain this. Add annotation: ${expected_annotation[0]}`}

                            } else result = {...result, res: 'err', err: `Missing annotation for xyz use only, but annotation is of unknown type. Add annotation: ${expected_annotation[0]}`}

                        } else result = {...result, res: 'err', err: `Missing annotation for xyz use only, but annotation has no supply number 0. Add annotation: ${expected_annotation[0]}`}

                    } else result = {...result, res: 'err', err: `Missing annotation for xyz use only, but annotation supply number is absent. Add annotation: ${expected_annotation[0]}`}

                } else result = {...result, res: 'err', err: `Missing annotation for xyz use only. Add annotation: ${expected_annotation[0]}`}

            }

        }

        return result

    }

    //	------------------------	------------------------	------------------------

    check_pt_instructions(params) {

        let result = {res: 'ok'}

        const inst = j_f.obj_val(params, ['item', 'PatientInstructions'])

        if (!inst || inst == '') result = {...result, res: 'err', err: 'Patient instructions are required'}

        return result

    }

    //	------------------------	------------------------	------------------------

    check_minimum_interval(params) {

        // Note - this is done already by making RepeatIntervals mandatory in the schema for ALL meds
        //console.log('bw check min int')

        let result = {res: 'ok'}

        const repeat_interval = j_f.obj_val(params, ['item', 'RepeatIntervals'])
        const schedule_number = j_f.obj_val(params, ['item', 'ScheduleNumber'])
        const repeats = j_f.obj_val(params, ['item', 'NumberOfRepeatsAuthorised'])
        const state = j_f.obj_val(params, ['patient', 'PatientState'])

        // Check any interval

        //console.log('Placeholder for repeat interval schedule 8 drugs')

        const rest_res = erx_props.retricted_repeat_intervals(params)        

        if (rest_res && repeat_interval < 1 && repeats > 0) result = {res: 'err', err: `Too short repeat interval for Schedule ${schedule_number} prescription in ${state}`}

        return result

    }

    //	------------------------	------------------------	------------------------

    check_controlled_qty_words(params) { // For s8 meds, need to include annotation of text stating the quantity

        let result = {res: 'ok'}

        const schedule_number = j_f.obj_val(params, ['item', 'ScheduleNumber'])
        const is_controlled = this.check_controlled_drug(schedule_number)

        result = {...result, is_controlled}

        if (is_controlled) {

            const quantity = j_f.obj_val(params, ['item', 'Quantity']) || 0
            const presc_res_1 = j_f.obj_val(params, ['item', 'PrescribedItem_Reserved_01']) || 0
            const quantity_as_words = num_convert.toWords(quantity).replace(/-/g, ' ').toLowerCase()

            let has_correct_qty = false

            result = { ...result, quantity_as_words }

            if (presc_res_1) {

                const c_text = presc_res_1.replace(/-/g, ' ').toLowerCase()

                if (c_text == quantity_as_words) has_correct_qty = true

                if (!has_correct_qty) result = { ...result, res: 'err', err: `Controlled drug written quantity does not match quantity. Should be: ${quantity_as_words}` }

            } else result = { ...result, res: 'err', err: `Controlled drug requires written quantity but this is not provided. Set Maximum Quantity Authorised to be Dispensed (in words) to ${quantity_as_words}` }

        }

        return result

    }

    //	------------------------	------------------------	------------------------

    check_controlled_drug(schedule_number, state) {

        if (schedule_number == '8' || schedule_number == 8) return true

        return false

    }

    //	------------------------	------------------------	------------------------

    compare(target_prescribed_item, subset = false) { // Compares this script's items to the target scripts items. Subset means only compare the props on the source

        let diff = []

        //log("Checking clinician props match")
        // Check props

        erx_props.PRESCRIBED_ITEM_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_prescribed_item && (typeof target_prescribed_item[item.name] != undefined) && (target_prescribed_item[item.name]!= '') && target_prescribed_item[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Prescribed item property ' + item.name + ', source: ' + source + ', target: ' + target, source, target}) 
            }
        })

        // Patient
        // Any future wrapped sub fields will need to go here - equivilant to get_item() on script or get_patient() on content
        // Annotations will probably go in here

        // Return
        if (diff.length == 0) return {res: 'ok'}
        else return {res: 'err', err: 'Prescribed items do not match by ' + diff.length + ' field(s). Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff}

    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.PRESCRIBED_ITEM_PROPS.forEach(p => this.define_prop(p)) }
    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.prescribeditem_data }
    set inner_data(val) { this.prescribeditem_data = val }

    //	------------------------	------------------------	------------------------

    get PrescriptionDate() { throw { override_msg: 'get PrescriptionDate' }}
    set PrescriptionDate(val) { throw { override_msg: 'set PrescriptionDate' }}

    get ItemUniqueID() { throw { override_msg: 'get ItemUniqueID' }}
    set ItemUniqueID(val) { throw { override_msg: 'set ItemUniqueID' }}

    get ItemTradeName() { throw { override_msg: 'get ItemTradeName' }}
    set ItemTradeName(val) { throw { override_msg: 'set ItemTradeName' }}

    get ItemGenericName() { throw { override_msg: 'get ItemGenericName' }}
    set ItemGenericName(val) { throw { override_msg: 'set ItemGenericName' }}

    get ItemForm() { throw { override_msg: 'get ItemForm' }}
    set ItemForm(val) { throw { override_msg: 'set ItemForm' }}

    get ItemStrength() { throw { override_msg: 'get ItemStrength' }}
    set ItemStrength(val) { throw { override_msg: 'set ItemStrength' }}

    get ItemGenericIntention() { throw { override_msg: 'get ItemGenericIntention' }}
    set ItemGenericIntention(val) { throw { override_msg: 'set ItemGenericIntention' }}

    get BrandSubstitutionNotAllowed() { throw { override_msg: 'get BrandSubstitutionNotAllowed' }}
    set BrandSubstitutionNotAllowed(val) { throw { override_msg: 'set BrandSubstitutionNotAllowed' }}

    get PBSCode() { throw { override_msg: 'get PBSCode' }}
    set PBSCode(val) { throw { override_msg: 'set PBSCode' }}

    get PBSManufacturerCode() { throw { override_msg: 'get PBSManufacturerCode' }}
    set PBSManufacturerCode(val) { throw { override_msg: 'set PBSManufacturerCode' }}

    get Quantity() { throw { override_msg: 'get Quantity' }}
    set Quantity(val) { throw { override_msg: 'set Quantity' }}

    get UnusualQtyFlag() { throw { override_msg: 'get UnusualQtyFlag' }}
    set UnusualQtyFlag(val) { throw { override_msg: 'set UnusualQtyFlag' }}

    get ItemBarcode() { throw { override_msg: 'get ItemBarcode' }}
    set ItemBarcode(val) { throw { override_msg: 'set ItemBarcode' }}

    get RouteAdministration() { throw { override_msg: 'get RouteAdministration' }}
    set RouteAdministration(val) { throw { override_msg: 'set RouteAdministration' }}

    get NumberOfRepeatsAuthorised() { throw { override_msg: 'get NumberOfRepeatsAuthorised' }}
    set NumberOfRepeatsAuthorised(val) { throw { override_msg: 'set NumberOfRepeatsAuthorised' }}

    get PBS_DVAAuthorityNumber() { throw { override_msg: 'get PBS_DVAAuthorityNumber' }}
    set PBS_DVAAuthorityNumber(val) { throw { override_msg: 'set PBS_DVAAuthorityNumber' }}

    get PhoneApprovalAuthorityNumber() { throw { override_msg: 'get PhoneApprovalAuthorityNumber' }}
    set PhoneApprovalAuthorityNumber(val) { throw { override_msg: 'set PhoneApprovalAuthorityNumber' }}

    get ScheduleNumber() { throw { override_msg: 'get ScheduleNumber' }}
    set ScheduleNumber(val) { throw { override_msg: 'set ScheduleNumber' }}

    get PatientInstructions() { throw { override_msg: 'get PatientInstructions' }}
    set PatientInstructions(val) { throw { override_msg: 'set PatientInstructions' }}

    get RepeatIntervals() { throw { override_msg: 'get RepeatIntervals' }}
    set RepeatIntervals(val) { throw { override_msg: 'set RepeatIntervals' }}

    get DoctorNotes() { throw { override_msg: 'get DoctorNotes' }}
    set DoctorNotes(val) { throw { override_msg: 'set DoctorNotes' }}

    get ReasonForPrescribing() { throw { override_msg: 'get ReasonForPrescribing' }}
    set ReasonForPrescribing(val) { throw { override_msg: 'set ReasonForPrescribing' }}

    get Regulation24() { throw { override_msg: 'get Regulation24' }}
    set Regulation24(val) { throw { override_msg: 'set Regulation24' }}

    get IsExtemp() { throw { override_msg: 'get IsExtemp' }}
    set IsExtemp(val) { throw { override_msg: 'set IsExtemp' }}

    get ExtemporaneousDescription() { throw { override_msg: 'get ExtemporaneousDescription' }}
    set ExtemporaneousDescription(val) { throw { override_msg: 'set ExtemporaneousDescription' }}

    get UnlistedItemRepatAuthority() { throw { override_msg: 'get UnlistedItemRepatAuthority' }}
    set UnlistedItemRepatAuthority(val) { throw { override_msg: 'set UnlistedItemRepatAuthority' }}

    get PrivatePrescription() { throw { override_msg: 'get PrivatePrescription' }}
    set PrivatePrescription(val) { throw { override_msg: 'set PrivatePrescription' }}

    get ScriptCTGAnnotation() { throw { override_msg: 'get ScriptCTGAnnotation' }}
    set ScriptCTGAnnotation(val) { throw { override_msg: 'set ScriptCTGAnnotation' }}

    get AMT_MP() { throw { override_msg: 'get AMT_MP' }}
    set AMT_MP(val) { throw { override_msg: 'set AMT_MP' }}

    get AMT_MPP() { throw { override_msg: 'get AMT_MPP' }}
    set AMT_MPP(val) { throw { override_msg: 'set AMT_MPP' }}

    get AMT_TPP() { throw { override_msg: 'get AMT_TPP' }}
    set AMT_TPP(val) { throw { override_msg: 'set AMT_TPP' }}

    get AMT_MPUU() { throw { override_msg: 'get AMT_MPUU' }}
    set AMT_MPUU(val) { throw { override_msg: 'set AMT_MPUU' }}

    get AMT_TP() { throw { override_msg: 'get AMT_TP' }}
    set AMT_TP(val) { throw { override_msg: 'set AMT_TP' }}

    get AMT_TPUU() { throw { override_msg: 'get AMT_TPUU' }}
    set AMT_TPUU(val) { throw { override_msg: 'set AMT_TPUU' }}

    get AMT_CTPP() { throw { override_msg: 'get AMT_CTPP' }}
    set AMT_CTPP(val) { throw { override_msg: 'set AMTAMT_CTPP_TPUU' }}

    get PrescribedItem_Reserved_01() { throw { override_msg: 'get PrescribedItem_Reserved_01' }}
    set PrescribedItem_Reserved_01(val) { throw { override_msg: 'set PrescribedItem_Reserved_01' }}

    get PrescribedItem_Reserved_02() { throw { override_msg: 'get PrescribedItem_Reserved_02' }}
    set PrescribedItem_Reserved_02(val) { throw { override_msg: 'set PrescribedItem_Reserved_02' }}

    get PrescribedItem_Reserved_03() { throw { override_msg: 'get PrescribedItem_Reserved_03' }}
    set PrescribedItem_Reserved_03(val) { throw { override_msg: 'set PrescribedItem_Reserved_03' }}

    get PrescribedItem_Reserved_04() { throw { override_msg: 'get PrescribedItem_Reserved_04' }}
    set PrescribedItem_Reserved_04(val) { throw { override_msg: 'set PrescribedItem_Reserved_04' }}

    get PrescribedItem_Reserved_05() { throw { override_msg: 'get PrescribedItem_Reserved_05' }}
    set PrescribedItem_Reserved_05(val) { throw { override_msg: 'set PrescribedItem_Reserved_05' }}

    get TherapeuticGoodIdentificationCode() { throw { override_msg: 'get TherapeuticGoodIdentificationCode' }}
    set TherapeuticGoodIdentificationCode(val) { throw { override_msg: 'set TherapeuticGoodIdentificationCode' }}

    get TherapeuticGoodIdentificationCodeSystem() { throw { override_msg: 'get TherapeuticGoodIdentificationCodeSystem' }}
    set TherapeuticGoodIdentificationCodeSystem(val) { throw { override_msg: 'set TherapeuticGoodIdentificationCodeSystem' }}

    get TherapeuticGoodIdentificationCodeSystemName() { throw { override_msg: 'get TherapeuticGoodIdentificationCodeSystemName' }}
    set TherapeuticGoodIdentificationCodeSystemName(val) { throw { override_msg: 'set TherapeuticGoodIdentificationCodeSystemName' }}

    get TherapeuticGoodIdentificationCodeSystemVersion() { throw { override_msg: 'get TherapeuticGoodIdentificationCodeSystemVersion' }}
    set TherapeuticGoodIdentificationCodeSystemVersion(val) { throw { override_msg: 'set TherapeuticGoodIdentificationCodeSystemVersion' }}

    get TherapeuticGoodIdentificationDisplayName() { throw { override_msg: 'get TherapeuticGoodIdentificationDisplayName' }}
    set TherapeuticGoodIdentificationDisplayName(val) { throw { override_msg: 'set TherapeuticGoodIdentificationDisplayName' }}

    get AMT_CODE_SYSTEM() { throw { override_msg: 'get AMT_CODE_SYSTEM' }}
    set AMT_CODE_SYSTEM(val) { throw { override_msg: 'set AMT_CODE_SYSTEM' }}

    get QuantityExtended() { throw { override_msg: 'get QuantityExtended' }}
    set QuantityExtended(val) { throw { override_msg: 'set QuantityExtended' }}

    get PRIMARY_MEDICATION_CODE() { throw { override_msg: 'get PRIMARY_MEDICATION_CODE' }}
    set PRIMARY_MEDICATION_CODE(val) { throw { override_msg: 'set PRIMARY_MEDICATION_CODE' }}

    get RTACid() { throw { override_msg: 'get RTACid' }}
    set RTACid(val) { throw { override_msg: 'set RTACid' }}

    get PrescriberScriptNumber() { throw { override_msg: 'get PrescriberScriptNumber' }}
    set PrescriberScriptNumber(val) { throw { override_msg: 'set PrescriberScriptNumber' }}

    get PrivacyStatement() { throw { override_msg: 'get PrivacyStatement' }}
    set PrivacyStatement(val) { throw { override_msg: 'set PrivacyStatement' }}

    get EmergencySupply() { throw { override_msg: 'get EmergencySupply' }}
    set EmergencySupply(val) { throw { override_msg: 'set EmergencySupply' }}

    // get Annotations() { throw { override_msg: 'get Annotations' }} // not implemented yet
    // set Annotations(val) { throw { override_msg: 'set Annotations' }} // not implemented yet

}