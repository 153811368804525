import React, { useEffect, useState } from 'react';
import patho_model from 'models/patho_model'
import { List, Input, Button, Table, Container, Segment, Header, Form, Checkbox, Dimmer, Loader } from 'semantic-ui-react'
import ObjectEditor from 'xAppLib/UIelems/ObjectEditor'
import { LevelsInput } from './tests/Levels'
import { BinaryInput } from './tests/Binary'
import { CulturesInput } from './tests/Cultures'
import { FormulaInput } from './tests/Formula'


const controls = [BinaryInput, LevelsInput, CulturesInput, FormulaInput]


const PathoResultsInput = ({med_db_data, value = null, onChange}) => {
	const [edit, setEdit] = useState(null)
	useEffect(async _=>{
		const tmp = {...med_db_data,items:(med_db_data.items||[]).map(i=>i.key)}
		const {tests} = await patho_model.request(tmp)
		const new_defs = tests.map(t=>t.definition).flat()
		const definitions = med_db_data.patho_request.tests.filter(t=>t.selected).map(t=>t.definition).flat()
		const newV = (value||definitions).map(v=>{
			const def =  new_defs.find(d=>d.id==v.id)
			return {...v,...def,result:value && v.result}
		})
		setEdit(newV)
	},[])
	
	if (!edit)
		return <Segment basic>
		      <Dimmer active inverted>
		        <Loader size='mini'>Loading</Loader>
		      </Dimmer>
	      		<div style={{height:100}}></div>
	    	</Segment>
	return <div>
		<ObjectEditor controls={controls} changeStructure={false} 
				value={edit} 
				onChange={({target:{value}})=>{
					setEdit(value)
					const data = value.map(({id,result,measure})=>({id,result,measure}))
					onChange(data)
				}}
			 />
		 
		 
		
	</div>
}
	


export default PathoResultsInput;
