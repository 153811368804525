import { useEffect, useRef } from 'react';

/**
 * A fix for "Warning: Can't perform a React state update on an unmounted component."
 * https://stackoverflow.com/a/65152534
 */

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted.current;
}
