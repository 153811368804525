import React from "react";
import HelpTile, { HELP_INIT } from "../../components/HelpTile";
import Image, { IMAGE_INIT } from "./Image";
import Paragraph, { PAR_INIT } from "./Paragraph";
import Title, { TITLE_INIT } from "../../components/Title";
import List, { LIST_INIT } from "./List";
import Subheader, { SUBHEADER_INIT } from "./Subheader";
import MultiList, { MULTI_LIST_INIT } from "./MultiList";

export const COMPONENTS_MAP = {
    text: Paragraph,
    header: Title,
    help: HelpTile,
    image: Image,
    list: List,
    subheader: Subheader,
    multilist: MultiList,
};

export const INIT_MAP = {
    text: PAR_INIT,
    header: TITLE_INIT,
    help: HELP_INIT,
    image: IMAGE_INIT,
    list: LIST_INIT,
    subheader: SUBHEADER_INIT,
    multilist: MULTI_LIST_INIT,
};

export const MAIN_COMPONENTS = [
    {
        label: "Add Header",
        name: "header",
    },
    {
        label: "Add Subheader",
        name: "subheader",
    },
    {
        label: "Add Paragraph",
        name: "text",
    },
    {
        label: "Add Image",
        name: "image",
    },
    {
        label: "Add List",
        name: "list",
    },
    {
        label: "Add Multi List",
        name: "multilist",
    },
]