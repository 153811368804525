import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from 'semantic-ui-react';
import IcosmHero from './components/IcosmHero';
import IcosmBanner from './components/IcosmBanner';
import IcosmAppBanner from './components/IcosmAppBanner';
import IcosmInfo from './components/IcosmInfo';
	
const Software = () => {

    const slides = [
        {
            img: "iphone-booking.png",
            title: "Book patients with ease",
            sub: "",
            desc: "Ever wanted a booking system that has all your clients notes, scripts and forms in the one place? We can help."
        },
        {
            img: "iphone-inventory.png",
            title: "Manage stock levels seamlessly",
            sub: "",
            desc: "Our inventory system makes it easier for you to keep track of all your stock levels and make purchases."
        },
        {
            img: "iphone-shop.png",
            title: "Shop with purpose",
            sub: "",
            desc: "Know you’re purchasing products that are tried and tested by our team, for the safety of you and your patient."
        },
    ]

	return <div>

		<Helmet>
			<title>Effective Software</title>
		</Helmet>


       <Container>
            <IcosmHero img='2/ipad-Scripting.jpg' title="Fast & Effective Software" desc="Looking for a system that flows, is optimised for your clinics functions and ticks all the boxes when it comes to efficiency? InstantCosmetics have crafted the platform for you." anchor="#operate" link_text="learn more" order="2" />
            
            <IcosmInfo size="lg" title={<>Your clinic, <br/>your way</>} desc={<>Seeking a solution that seamlessly integrates client booking, treatment, and management? Our system effortlessly connects all the<br className="lg"/> dots for you.</>} img="2/Website-Platform-Images-your-clinic-your-way.jpg"/>
            <IcosmInfo size="lg" reverse title={<> Manage your<br/> clinic with ease</>} desc="Our inventory system simplifies the management of your clinic's stock levels." img="2/Website-Platform-Images-inventory.jpg"/>
           
        </Container>

        <IcosmAppBanner title="Operate your business better!" desc="Effective solutions that help you grow." slides={slides}/>
     
        <IcosmBanner img='2/iStock-1315529747.jpg' title="Grow today, excel tomorrow." desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started." />
        
	</div>

}

export default Software;
