import React from 'react'

export default function withAccess(WrappedComponent, access) {

	class ComponentWithAccess extends React.Component {
		constructor(props) {
			super(props)
		}
		render() {
			
			if (!access(this.props))
				return null
			
			return <WrappedComponent { ...this.props } />
		}
	}
	
	return ComponentWithAccess
}
