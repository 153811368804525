import React, { useState } from 'react';

function event_files(ev) {
		
		console.log("event_files()",ev);

		let files = [];

		if (ev.dataTransfer.items) {
			// Use DataTransferItemList interface to access the file(s)
			for (let i = 0; i < ev.dataTransfer.items.length; i++) {
				// If dropped items aren't files, reject them
				if (ev.dataTransfer.items[i].kind === 'file') {
					var file = ev.dataTransfer.items[i].getAsFile();
					// console.log('... file[' + i + '].name = ' + file.name);
					// console.log('... file[' + i + '] = ' + file);
					files.push(file)
				}
			}
		} else {
			// Use DataTransfer interface to access the file(s)
			for (let i = 0; i < ev.dataTransfer.files.length; i++) {
				// console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
				// console.log('... file[' + i + '] = ' + ev.dataTransfer.files[i]);
				files.push(ev.dataTransfer.files[i])
			}
		}

		return files

}

const FileDropArea = ({children,onDropFiles, enabled=true}) => {
	const [active,setActive] = useState(false)
	let enterTarget = null
	
	return <div className={`drop-area ${active&&'drop'||''}`}
					onDragEnter = { e => {
								e.preventDefault();
								e.stopPropagation();
								enterTarget = e.target;
								enabled && setActive(true)
							} }
					onDragLeave = { e => {
								if (enterTarget == e.target) {
									e.preventDefault();
									e.stopPropagation();
									setActive(false)
								}
							} }
					onDragOver = { e => {
								e.preventDefault();
								e.stopPropagation();
							} }
					onDrop = { e => {
								e.preventDefault();
								e.stopPropagation();
								enabled && onDropFiles?.(event_files(e))
								setActive(false)
							} }
		
		>{children}</div>
}

export default FileDropArea;
