import React from 'react'

export default function withData(WrappedComponent, selectData, asProp = 'data') {

	let cache = null
	
	class ComponentWithData extends React.Component {
		constructor(props) {
			super(props)
			this.state = { data: cache}
		}

		async componentDidMount() {
			if (!cache) {
				cache = await selectData(this.props)
				this.setState({
					data:cache
				})
			}
		}

		render() {
			const { data } = this.state
			
			if (!data)
				return null
				
			const props = {[asProp]:data}
			return <WrappedComponent {...props} { ...this.props } />
		}
	}
	
	return ComponentWithData
}
