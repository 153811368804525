import React from 'react';
import { cls } from './utils';
/**
 * * Re-implementation of SUI grid 
 * ? Seems almost too simple
 * TODO implement TW start & end
 * TODO implement responsive
 */

const Grid = ({columns = 16, gap = 4,stackable, children}) => {
    const cl = cls(`gap-${gap}`,stackable?`md:grid md:grid-cols-${columns}`:`grid grid-cols-${columns}`)
    return <div  className={cl}>{children}</div>;
}

// Note: don't really need it, for compatibility with SUI
const Row = ({children}) => children

const Column = ({children, width=1}) => {
    return <div className={`col-span-${width}`}>{children}</div>;
}

Grid.Row = Row
Grid.Column = Column

export default Grid;