// Helper to display medicare nicely. 
export const prettifyMC = (mc) => {
    if (!/^.{10,11}$/.test(mc)) {
        console.log(`Input string (${mc}) must be between 10 and 11 characters long to format nicely`);
        return mc
    }

    let formatted = mc.slice(0, 4) + " " + mc.slice(4, 9) + " " + mc[9];

    if (mc[10]) {
        formatted += " " + mc[10];
    }

    return formatted;
}

// Helper for capitalizing strings
export const capitalize = (str) => {
    if (typeof str === "string") {
        const str_arr = str.split(" ");
        return str_arr.map((word) =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(" ");
    } else {
        return str
    }
}