import firebase_auth_web from "./firebase_auth_web";
import firebase_auth_ionic from "./firebase_auth_ionic";

class firebase_auth {

	static async init(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.init(...args) : firebase_auth_web.init(...args)
	}
	static async refresh_user(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.refresh_user(...args) : firebase_auth_web.refresh_user(...args)
	}
	static async check_user(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.check_user(...args) : firebase_auth_web.check_user(...args)
	}
	static async frb_login(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.frb_login(...args) : firebase_auth_web.frb_login(...args)
	}
	static async signInWithCustomToken(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.signInWithCustomToken(...args) : firebase_auth_web.signInWithCustomToken(...args)
	}
	static async frb_reset(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.frb_reset(...args) : firebase_auth_web.frb_reset(...args)
	}
	static async frb_password(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.frb_password(...args) : firebase_auth_web.frb_password(...args)
	}
	static async frb_reauthenticate(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.frb_reauthenticate(...args) : firebase_auth_web.frb_reauthenticate(...args)
	}
	static async logout(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.logout(...args) : firebase_auth_web.logout(...args)
	}
	static async g_login(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.g_login(...args) : firebase_auth_web.g_login(...args)
	}
	static async fb_login(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.fb_login(...args) : firebase_auth_web.fb_login(...args)
	}
	static async apple_login(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.apple_login(...args) : firebase_auth_web.apple_login(...args)
	}
	static async onepass_login(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.onepass_login(...args) : firebase_auth_web.onepass_login(...args)
	}
	static async send_verification_email(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.send_verification_email(...args) : firebase_auth_web.send_verification_email(...args)
	}
	static async reload(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.reload(...args) : firebase_auth_web.reload(...args)
	}
	static async verifyPhone(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.verifyPhone(...args) : firebase_auth_web.verifyPhone(...args)
	}
	static async initiatePhoneEnroll(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.initiatePhoneEnroll(...args) : firebase_auth_web.initiatePhoneEnroll(...args)
	}
	static async verifyPhoneCode(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.verifyPhoneCode(...args) : firebase_auth_web.verifyPhoneCode(...args)
	}
	static async enrollPhoneFactor(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.enrollPhoneFactor(...args) : firebase_auth_web.enrollPhoneFactor(...args)
	}
	static async unenrollFactor(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.unenrollFactor(...args) : firebase_auth_web.unenrollFactor(...args)
	}
	static enrolledFactors(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.enrolledFactors(...args) : firebase_auth_web.enrolledFactors(...args)
	}
	static get_captcha(...args) {
		return window.__NATIVE_AUTH__ ? firebase_auth_ionic.get_captcha(...args) : firebase_auth_web.get_captcha(...args)
	}

	static get native_mfa() {
		return !!window.__NATIVE_AUTH__
	}



}

export default firebase_auth
