import localstorage_database from 'xAppLib/providers/localstorage_database.js';

import firebase_database from 'xAppLib/providers/firebase_database.js';

import orgs_model from 'models/orgs_model'
import user_model from 'models/user_model'
import logger from 'xAppLib/libs/logger'

const _LCDB_LOC = 'devices';
const _LCDB_LG_LOC = 'devices_lgs';
const _LCDB_SUMMARY_LOC = 'device_summary';

const _DET_FIELDS = {
			'name': 'Pharmacy Name',
			'email': 'Contact email',
		}

const _MODEL_NAME = 'device_model';

export default class device_model {

	static get LCDB_LOC() { return _LCDB_LOC; }
	static get LCDB_LG_LOC() { return _LCDB_LG_LOC; }
	static get LCDB_SUMMARY_LOC() { return _LCDB_SUMMARY_LOC; }
	// static get DET_FIELDS() { return _DET_FIELDS; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	constructor() {

		this.cdb_loc = 'device';

		this.det_fields = {
					'name': 'Pharmacy Name',
					'email': 'Contact email',
					'contact': 'Contact Name',
					'phone': 'Phone number',
					'address': 'Address',
				};

		this.model_name = 'device_model';

		
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	async init(ca,userP) {
		// ca && ca()
		// localstorage_database.delete_record('device_id')
		// return;


		



		app.dvc.dvcid = localstorage_database.get_record('device_id') || null


		if (!app.dvc.dvcid || typeof app.dvc.dvcid == 'undefined') {


			await userP
			const usr = app.user&&app.user.user_in&&app.user.user_det
			const lg_obj = {
							// a, pg, p,
							u: usr,
							ua: window.navigator.userAgent,
							pg: location.toString(),
							// uc: app.user&&app.user.user_in&&app.user.claims,
							// // mob: window.navigator.userAgent && window.navigator.userAgent.match(/mobi/i),
							// // mob: window.navigator.userAgent.match(/mobi/i) || false,
							// mob: window.navigator.userAgent.toLowerCase().includes("mobi"),

							tm:new Date().getTime(), 
							tm_str:new Date().toString(),

							ab: __BUILD__,
						}

			const curr_device = localstorage_database.get_records()

			app.dvc.dvcid = await firebase_database
										.add_record(
												device_model.LCDB_LOC,
												{
													f: lg_obj,
													...(curr_device && curr_device.id && { dvc: {pharm_id:curr_device.id||0, pharm_fr_dvc:curr_device} } || {})
												}
												,
												{ timestamp: true }
											);

			console.log('got new dvcid', app.dvc.dvcid);

			localstorage_database.update_record('device_id', app.dvc.dvcid)

		}

		// let device;
		// // -LQ9ORvuYdd2Fp6kNVRJ
		// try {
		// 	// device = await firebase_database.get_record( device_model.LCDB_LOC + '/-LQ9ORvuYdd2Fp6kNVRJ' )
		// 	// debugger;
		// 	app.dev_env && console.log('will load device data from ', device_model.LCDB_LOC + '/' + app.dvc.dvcid )
		// 	device = await firebase_database.get_record( device_model.LCDB_LOC + '/' + app.dvc.dvcid )
		// } catch (e) {
		// 	console.error('ERROR trying to load device from ', device_model.LCDB_LOC + '/' + app.dvc.dvcid)
		// }

		// console.log('device get', device);

		// this.set_dvc_data(device, ca, !app.dvc.init)

		// await (new Promise(resolve => setTimeout(resolve, 10*1000)));

		firebase_database
			.watch_record(
							device_model.LCDB_LOC + '/' + app.dvc.dvcid,
							async r => {
								if (this.rldd && r?.rld && (Object.keys(r.rld).length>this.rldd))
									location.reload(true);

								this.rldd = r?.rld && Object.keys(r.rld).length || -1;

								// console.log('device watch', app.dvc?.dvc_data, r.dvc, r?.rld && Object.keys(r.rld).length);
								
								if (!app.dvc.dvc_data || app.dvc?.dvc_data?.oid!=r.dvc?.oid) {
									await userP
									this.set_dvc_data(r, ca)
								}
							}
						)
		return
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	async set_dvc_data(device, ca) {

		const usr = app.user&&app.user.user_in&&app.user.user_det
		const lg_obj = {
						// a, pg, p,
						u: usr,
						ua: window.navigator.userAgent,
						pg: location.toString(),
						// uc: app.user&&app.user.user_in&&app.user.claims,
						// // mob: window.navigator.userAgent && window.navigator.userAgent.match(/mobi/i),
						// // mob: window.navigator.userAgent.match(/mobi/i) || false,
						// mob: window.navigator.userAgent.toLowerCase().includes("mobi"),

						tm:new Date().getTime(), 
						tm_str:new Date().toString(),

						ab: __BUILD__,
					}

		app.dvc.exprms = device?.exprms || {}

		app.dvc.dvc_data = device?.dvc || {}

		app.trigger( app.events.DVC_LDD )

		// if ( !dvc_data.oid && !dvc_data.oid_migr_set && (dvc_data.org_id || dvc_data.pharm_id)  ) {

		// 	await firebase_database
		// 			.update_record( 
		// 								device_model.LCDB_LOC + '/' + app.dvc.dvcid + '/dvc/', 
		// 								{oid: dvc_data.org_id || dvc_data.pharm_id}, 
		// 								{timestamp: true, timestamp_fld:'oid_migr_set'} 
		// 							)

		// 	console.log('migrating this device from ', 
		// 				dvc_data.org_id && `org_id ${dvc_data.org_id}` || dvc_data.pharm_id && `pharm_id ${dvc_data.pharm_id}`,
		// 				'to oid'
		// 			);

		// }

		const oid = app.dvc.dvc_data.oid || false
		const org_data = oid && await orgs_model.get_record(null, null, {act:'getdvcorg', oid:oid});
		// app.dvc.org = oid && org_data && {oid, ...org_data.org, ...org_data.conf, path_name:org_data.path_name, type:org_data.type, conf:org_data.conf, monthly:org_data.monthly||''} || false
		app.dvc.org = oid && org_data || false


		// firebase_database.watch_record(
		// 		device_model.LCDB_LOC + '/' + app.dvc.dvcid, 
		// 		async d => {
		// 				if ( app.dvc.org.oid != (d.dvc && d.dvc.oid) ) {
		// 					const u_oid = d.dvc.oid || false
		// 					const u_org_data = u_oid && await orgs_model.get_record(null, null, {act:'getdvcorg', oid:u_oid});
		// 					app.dvc.org = u_oid && u_org_data && {oid:u_oid, ...u_org_data.org, ...u_org_data.org_conf} || false
		// 				}
		// 			}
		// 	);


		// console.log('device_model.init w: ', 'app.dvc.org', app.dvc.org);
		// console.log('device_model.init w: ', 'app.dvc.org', app.dvc.org.type, app.dvc.org, org_data);
		// console.log('loaded device ', 'dvcid', app.dvc.dvcid, 'device', device, 'app.dvc.org', app.dvc.org);

		if (app.dvc.org.type=='pharm'
			 && (document.location.hostname=="pharmapp.instantscripts.com.au"||document.location.hostname=="safe-scripts.firebaseapp.com")
			 && usr
			) {

			console.log('PHARM TERMINAL LOGGED IN', usr);

			logger.usg_log('appstart', 'userin', 'pharmterm', {usr, dvc:app.dvc})

			user_model.logout()
		}


		// 	app.dvc.data = device && device.dvc || {}
		// 	app.dvc.org_db = app.dvc.data&&app.dvc.data.org_id &&
		// 						await firebase_database.get_record( 'orgs/' + app.dvc.data.org_id ) 
		// 					|| {}

		// 	app.dvc.pharm_db = app.dvc.data&&app.dvc.data.pharm_id &&
		// 						await firebase_database.get_record( 'pharms/' + app.dvc.data.pharm_id )
		// 					|| {}


		// console.log('device_model.init w: ', 'app.dvc.dvcid', app.dvc.dvcid, 'device', device, 'app.dvc', app.dvc);
		// console.log('device_model.init w: ', 'app.dvc.dvcid', app.dvc.dvcid, 'app.dvc.data', app.dvc.data, 'app.dvc.org_db', app.dvc.org_db, 'app.dvc.pharm_db', app.dvc.pharm_db);

		ca && ca()
		// console.log(app.user);


		// calculate counters
		const cnt = device&&device.cnt || {}
		app.dvc.new = Object.keys(cnt).length < 2
		const u = `${usr.displayName||'-'} ${usr.email || (usr.providerData && usr.providerData[0].email) || ''}`.replace(/[\.\#\$\/\[\]]/g, '_');
		cnt[u] = (cnt[u] || 0) + 1
		// cnt[usr.displayName||'-'] = (cnt[usr.displayName||'-'] || 0) + 1


		firebase_database
			.update_record(
					device_model.LCDB_LOC + '/' + app.dvc.dvcid,
					{
						cnt,
						l: lg_obj,
					},
					{
						timestamp: true,
						timestamp_fld: 'last_tm',
					},
				);

		firebase_database.update_record(
			`${device_model.LCDB_SUMMARY_LOC}/${app.dvc.dvcid}`,
			{
				oid: app.dvc?.org?.oid ?? null,
				type: (() => {
					if (app.dvc?.org?.type) return app.dvc?.org?.type;
					if (document.location.hostname === 'pharmapp.instantscripts.com.au') return 'pharm';
					if (['cosmapp.instantscripts.com.au', 'instantcosmetics.com.au'].includes(document.location.hostname)) return 'cosm';
					if (app.user?.doc_det) return 'doc';
					if (app.settings.is_local) return 'dev';
					return 'app';
				})(),
			},
			{
				timestamp: true,
				timestamp_fld: false,
			},
		);


		// Log
		firebase_database
			.add_record(
					device_model.LCDB_LG_LOC + '/' + app.dvc.dvcid + '/lg/',
					{...lg_obj, dvc:device&&device.dvc||{}},
					{
						timestamp: true
					},
				);

		// Copy device data for reference
		device.dvc && firebase_database
			.update_record(device_model.LCDB_LG_LOC + '/' + app.dvc.dvcid,
				{dvc:device.dvc},
				{
					timestamp: true,
					timestamp_fld: 'last_tm',
				})

		return
	}


//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static upd_dvc_fld(fld, data) {

		firebase_database
			.update_record(
					device_model.LCDB_LOC + '/' + app.dvc.dvcid,
					{
						[fld]: data,
					},
				);
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static get_curr_device() {
		console.log('get_curr_device', localstorage_database.get_record('curr_device'))
		return localstorage_database.get_record('curr_device')
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static save_curr_device(d) {
		return localstorage_database.update_record('curr_device', d)
	}
	
	
	static check_access(a) {
		let r = false;

		switch (a) {
			case 'pharm':
				r = app.dvc.org && app.dvc.org.type=='pharm'
				break;

			case 'cosm':
				r = app.dvc.org && app.dvc.org.type=='cosm'
				break;
				
			case 'org`':
				r = !!app.dvc.org
				break;
			
			case 'noterm':
				r = !(app.dvc.org && (app.dvc.org.type=='pharm' || app.dvc.org.type=='cosm'))
				break;
		}


		return !!r
	}


// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------


	static watch_recent_devices(after_ts, onData) {
		return firebase_database.watch_record(
			device_model.LCDB_SUMMARY_LOC,
			onData,
			{where_key: 'tm', where_val: after_ts, where_type: 'fro'}
		);
	}

	static watch_org_terminals(oid, onData) {
		return firebase_database.watch_record(
			device_model.LCDB_SUMMARY_LOC,
			onData,
			{where_key: 'oid', where_val: oid}
		);
	}

}

// device_model.constructor()
