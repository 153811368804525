import db_lib from 'xAppLib/libs/db_lib';
import moment from "moment";
import {from_melb_ui_tm} from "../helpers/datetime";

const _FRDB_LOC = 'unseen_results';

export default class unseen_results_model extends db_lib {

    static get FRDB_LOC() {
        return _FRDB_LOC;
    }

    static _watch_unseen_count(type, doc_id, onData, onError) {
        return this.watch_all_records(
            (records) => {
                const cutoff = moment().subtract(6, 'months');
                const fresh = Object.values(records ?? {}).filter(rec => cutoff.isBefore(from_melb_ui_tm(rec.add_tm)))

                onData(fresh.reduce((sum, rec) => sum + (rec[type]?.count || 0), 0))
            },
            {where_key: `${type}/doc_id`, where_val: doc_id, ce: onError}
        );
    }

    static watch_unseen_results_count(doc_id, onData, onError) {
        return this._watch_unseen_count('unseen_results', doc_id, onData, onError);
    }

    static watch_unseen_results_express_count(doc_id, onData, onError) {
        return this._watch_unseen_count('unseen_results_express', doc_id, onData, onError);
    }
}