import { resizeImage } from 'xAppLib/Photos/resizeImage';

const MAX_FILE_SIZE_MB = 5;

export default async function image_to_base64(file, restrictSize = true) {
	return await new Promise((resolve, reject) => {
		const sizeInBytes = MAX_FILE_SIZE_MB * (1024 * 1024);
		const fileReader = new FileReader();

		fileReader.readAsDataURL(file);

		fileReader.onload = () => {
			if (file.size <= sizeInBytes || !restrictSize) return resolve(fileReader.result);

			const too_large = () => reject(`File size is too large. Max size is ${MAX_FILE_SIZE_MB}MB`);
			const temp = new Image();
			temp.onload = function (event) {
				try {
					const { width, height } = event.target;
					// Naive scaling. This will reduce the number of pixels to the desired about, but
					// depending on compression etc the actual file size ends up quite a bit smaller.
					// Ref https://math.stackexchange.com/a/3983239
					const ratio = Math.sqrt(sizeInBytes / file.size);
					const new_w = Math.floor(width * ratio);
					const new_h = Math.floor(height * ratio);
					resolve(resizeImage(event.target, new_w, new_h) || fileReader.result);
				} catch (err) {
					too_large();
				}
			};
			temp.onerror = too_large;
			temp.src = fileReader.result.toString();
		};

		fileReader.onerror = () => reject(`Unable to read the photo. Please try again`);
	});
}
