export function resizeImage(img, width, height) {
	// adapted from: https://stackoverflow.com/a/53986239/1309909

	// Check if the image require resize at all
	if (img.height <= height && img.width <= width) {
		return null;
	}

	// Make sure the width and height preserve the original aspect ratio and adjust if needed
	if (img.height > img.width) {
		width = Math.floor(height * (img.width / img.height));
	} else {
		height = Math.floor(width * (img.height / img.width));
	}

	const resizingCanvas = document.createElement('canvas');
	const resizingCanvasContext = resizingCanvas.getContext('2d');

	// Start with original image size
	resizingCanvas.width = img.width;
	resizingCanvas.height = img.height;

	// Draw the original image on the (temp) resizing canvas
	resizingCanvasContext.drawImage(
		img,
		0,
		0,
		resizingCanvas.width,
		resizingCanvas.height
	);

	const curImageDimensions = {
		width: Math.floor(img.width),
		height: Math.floor(img.height),
	};

	const halfImageDimensions = {
		width: null,
		height: null,
	};

	// Quickly reduce the size by 50% each time in few iterations until the size is less than
	// 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
	// created with direct reduction of very big image to small image
	while (curImageDimensions.width * 0.5 > width) {
		// Reduce the resizing canvas by half and refresh the image
		halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
		halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

		resizingCanvasContext.drawImage(
			resizingCanvas,
			0,
			0,
			curImageDimensions.width,
			curImageDimensions.height,
			0,
			0,
			halfImageDimensions.width,
			halfImageDimensions.height
		);

		curImageDimensions.width = halfImageDimensions.width;
		curImageDimensions.height = halfImageDimensions.height;
	}

	// Now do final resize for the resizingCanvas to meet the dimension requirements
	// directly to the output canvas, that will output the final image
	const outputCanvas = document.createElement('canvas');
	const outputCanvasContext = outputCanvas.getContext('2d');

	outputCanvas.width = width;
	outputCanvas.height = height;

	outputCanvasContext.drawImage(
		resizingCanvas,
		0,
		0,
		curImageDimensions.width,
		curImageDimensions.height,
		0,
		0,
		width,
		height
	);

	return outputCanvas.toDataURL('image/jpeg');
}
