// from https://github.com/flexdinesh/typy/blob/master/src/util.js

const getNestedObj = (obj, dotSeparatedKeys, keySep = '/') => {

	// if (arguments.length > 1 && typeof dotSeparatedKeys !== 'string') return undefined;

	if (dotSeparatedKeys && (typeof dotSeparatedKeys === 'object') && dotSeparatedKeys.val)
		return dotSeparatedKeys.val


	if (typeof obj !== 'undefined' && typeof dotSeparatedKeys === 'string') {

		const pathArr = dotSeparatedKeys.split(keySep);

		// console.log('getNestedObject', obj, dotSeparatedKeys, keySep, pathArr);

		pathArr.forEach((key, idx, arr) => {

			if (typeof key === 'string' && key.includes('[')) {

				try {
					// extract the array index as string
					const pos = /\[([^)]+)\]/.exec(key)[1];
					// get the index string length (i.e. '21'.length === 2)
					const posLen = pos.length;
					arr.splice(idx + 1, 0, Number(pos));

					// keep the key (array name) without the index comprehension:
					// (i.e. key without [] (string of length 2)
					// and the length of the index (posLen))
					arr[idx] = key.slice(0, (-2 - posLen)); // eslint-disable-line no-param-reassign
				} catch (e) {
					// do nothing
				}
			}
		});

		// eslint-disable-next-line no-param-reassign, no-confusing-arrow
		obj = pathArr.reduce( (o, key) => (o && key && o[key] !== 'undefined' ? o[key] : o), obj );
	}

	return obj!=null ? obj : null;
};

const getNestedObject = (obj, dotSeparatedKeys, keySep = '/') => {

	if ( !Array.isArray(dotSeparatedKeys) )
		return getNestedObj(obj, dotSeparatedKeys, keySep)

	let res_arr = []
	dotSeparatedKeys.forEach( p => res_arr.push(getNestedObj(obj, p, keySep)) )

	return res_arr.join('')

}

export default getNestedObject
