/* eslint-env node */

//	------------------------	------------------------	------------------------
//	Description:
//  eRx script -> item[s] -> CONTENT -> clinician, patient, prescribed item
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const erx_patient_model =           require('./erx_patient_model')
const erx_clinician_model =         require('./erx_clinician_model')
const erx_prescribeditem_model =    require('./erx_prescribeditem_model')

const get_set =                     require('../../helpers/erx/get_set_function')
const erx_formats =                 require('../erx/resources/erx_formats')
const erx_props =                   require('../erx/resources/erx_props')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const raw_prop_id =     '_RAW'

//	------------------------	------------------------	------------------------

const DEFAULTS = {
    Patient: new erx_patient_model(),
    Clinician: new erx_clinician_model(),
    PrescribedItem: new erx_prescribeditem_model(),
}

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class erx_content_model {

    constructor(content_data, run_validation = true) {

        this._content_data = {}
        this.setup_props()

        if (content_data){
            if (run_validation) this.content_data = content_data
            else this._content_data = content_data
        } 
        else this.set_default()

    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get content_data() { return this._content_data }
    set content_data(val) {
        this._content_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------
    set_blank() { // You probably don't want to call this
        
        this.clear()

        erx_props.CONTENT_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })

        // This is similar to defaults, but the defaults may have some other options not blank
        this.Patient = new erx_patient_model()
        this.Clinician = new erx_clinician_model()
        this.PrescribedItem = new erx_prescribeditem_model()

     }

    //	------------------------	------------------------	------------------------

    set_default() { 
        
        this.set_blank()

        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))

        this.get_patient().set_default()
        this.get_clinician().set_default()
        this.get_prescribeditem().set_default()
        //this.fix_data()
    
    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {
        
         // Fix self
        let result = {res: 'ok', fixes: [], err: []}

        for (let i = 0; i < erx_props.CONTENT_PROPS.length; i++){
            const c_prop = erx_props.CONTENT_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format - see if we can convert any strings to expected format
            if ((typeof c_item !== 'undefined') && c_item !== '' && c_item !== null && c_prop.format){ //Note - content props for patient, clinician and item wont have a format and will be ignored
                
                const formatter = erx_formats.ERX_FORMAT[c_prop.format]
                if (!formatter || !formatter.validate) result.err.push('Cannot find validator for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                else {

                    const valid_res = formatter.validate(c_item)

                    if (valid_res.res != 'ok'){

                        if (remove_errors) this[c_prop.name] = null // The value may be fixed below

                        if (!formatter.fromValue) result.err.push('Cannot find formatter (fromValue function) for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                        else {

                            const from_res = formatter.fromValue(c_item)

                            if (from_res.res != 'ok') result.err.push('Could not parse item to type ' + c_prop.format + ' for prop ' + c_prop.name + '. Error: ' + from_res.err)
                            else {

                                result.fixes.push( { name: c_prop.name, from: c_item, to: from_res.val } )
                                this[c_prop.name] = from_res.val

                            }
                        }
                    } 
                }              
            }
        }


        // Fix children        
        let patient = this.get_patient()
        if (patient){
            const patient_fix_res = patient.fix_data(remove_errors)
            if (patient_fix_res.res != 'ok') result.err.push('Error fixing patient. Error: ' + patient_fix_res.err)
            result = {...result, patient_fix_res}
        }

        let clinician = this.get_clinician()
        if (clinician){
            const clinician_fix_res = clinician.fix_data(remove_errors)
            if (clinician_fix_res.res != 'ok') result.err.push('Error fixing clinician. Error: ' + clinician_fix_res.err)
            result = {...result, clinician_fix_res}
        }

        let pres_item = this.get_prescribeditem()
        if (pres_item){
            const pres_item_fix_res = pres_item.fix_data(remove_errors)
            if (pres_item_fix_res.res != 'ok') result.err.push('Error fixing prescribed item. Error: ' + pres_item_fix_res.err)
            result = {...result, pres_item_fix_res}
        }
        
        if (result.err.length > 0) result.res = 'err'
        else delete result.err
        return result

    }

    //	------------------------	------------------------	------------------------

    check_valid(params = {}) {

        let result = {res: 'ok'}

        try {

            const patient = this.get_patient()
            const clinician = this.get_clinician()
            const item = this.get_prescribeditem()

            const patient_valid_res = patient.check_valid({...params, patient, clinician, item})
            if (patient_valid_res.res != 'ok')
                result = {...result, res: 'err', err: [...(result.err || []), ('Patient is invalid: ' + patient_valid_res.err)] }
                //return {res: 'err', err: 'Patient is invalid: ' + patient_valid_res.err, patient_valid_res}

            const clinician_valid_res = clinician.check_valid({...params, patient, clinician, item})
            if (clinician_valid_res.res != 'ok')
                result = {...result, res: 'err', err: [...(result.err || []), ('Clinician is invalid: ' + clinician_valid_res.err)] }
                //return {res: 'err', err: 'Clinician is invalid: ' + clinician_valid_res.err, clinician_valid_res}

            const item_valid_res = item.check_valid({...params, patient, clinician, item})
            if (item_valid_res.res != 'ok')
                result = {...result, res: 'err', err: [...(result.err || []), ('Prescribed item is invalid: ' + item_valid_res.err)] }
                //return {res: 'err', err: 'Prescribed item is invalid: ' + item_valid_res.err, item_valid_res}

        } catch (e) {
            result = {...result, res: 'err', err: [...(result.err || []), ('Unknown error while checking content validity: ' + e)] }
            //return {res: 'err', err: 'Unknown error while checking content validity: ' + e, e}

        }

        return result

    }

    //	------------------------	------------------------	------------------------

    compare(target_content, subset = false) { // Compares this script's items to the target scripts items. Subset means only compare the props on the source

        let diff = []

        //log("Checking item props match")
        // Check props
        erx_props.CONTENT_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_content && (typeof target_content[item.name] != undefined) && (target_content[item.name]!= '') && target_content[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Content property ' + item.name + ', source: ' + source + ', target: ' + target, source, target}) 
            }
        })

        // Patient
        // Patient source
        let source_patient = this.get_patient()
        let target_patient = target_content && target_content.get_patient && target_content.get_patient() || null
        let compare_patient_res = {}
        if (source_patient && source_patient.compare) compare_patient_res = source_patient.compare(target_patient, subset)
        if (compare_patient_res.res != 'ok') diff.push({name: 'Source patient mismatch: ' + compare_patient_res.err, compare_patient_res, source: source_patient, target: target_patient})
        
        // Patient target
        if (!subset) {
            compare_patient_res = {}
            if (target_patient && target_patient.compare) compare_patient_res = target_patient.compare(source_patient, subset)
            if (compare_patient_res.res != 'ok') diff.push({ name: 'Target patient mismatch: ' + compare_patient_res.err, compare_patient_res, source: source_patient, target: target_patient })
        }

        // Clinician
        // Clinician source
        let source_clinician = this.get_clinician()
        let target_clinician = target_content && target_content.get_clinician && target_content.get_clinician() || null
        let compare_clinician_res = {}
        if (source_clinician && source_clinician.compare) compare_clinician_res = source_clinician.compare(target_clinician, subset)
        if (compare_clinician_res.res != 'ok') diff.push({name: 'Source clinician mismatch: ' + compare_clinician_res.err, compare_clinician_res, source: source_clinician, target: target_clinician})
        
        // Clinician target
        if (!subset) {
            compare_clinician_res = {}
            if (target_clinician && target_clinician.compare) compare_clinician_res = target_clinician.compare(source_clinician, subset)
            if (compare_clinician_res.res != 'ok') diff.push({ name: 'Target clinician mismatch: ' + compare_clinician_res.err, compare_clinician_res, source: source_clinician, target: target_clinician })
        }

        // Prescribed itme
        // Prescribed itme source
        let source_prescribed_item = this.get_prescribeditem()
        let target_prescribed_item = target_content && target_content.get_prescribeditem && target_content.get_prescribeditem() || null
        let compare_prescribed_item_res = {}
        if (source_prescribed_item && source_prescribed_item.compare) compare_prescribed_item_res = source_prescribed_item.compare(target_prescribed_item, subset)
        if (compare_prescribed_item_res.res != 'ok') diff.push({name: 'Source prescribed item mismatch: ' + compare_prescribed_item_res.err, compare_prescribed_item_res, source: source_prescribed_item, target: target_prescribed_item})
        
        // Prescribed itme target
        if (!subset) {
            compare_prescribed_item_res = {}
            if (target_prescribed_item && target_prescribed_item.compare) compare_prescribed_item_res = target_prescribed_item.compare(source_prescribed_item, subset)
            if (compare_prescribed_item_res.res != 'ok') diff.push({ name: 'Target clinician mismatch: ' + compare_prescribed_item_res.err, compare_clinician_res, source: source_prescribed_item, target: target_prescribed_item })
        }

        // Return
        if (diff.length == 0) return {res: 'ok'}
        else return {res: 'err', err: 'Contents do not match by ' + diff.length + ' field(s)/content. Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff}

    }

    //	------------------------	------------------------	------------------------

    get_patient() { // Returns an array of all the items
        if (!this['Patient' + raw_prop_id]) return null
        try {
            return new erx_patient_model(this['Patient' + raw_prop_id]) // Need to double check this - that content is always one item
        } catch (e) {
            throw {err: 'Unable to get content: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------

    get_clinician() { // Returns an array of all the items
        if (!this['Clinician' + raw_prop_id]) return null
        try {
            return new erx_clinician_model(this['Clinician' + raw_prop_id]) // Need to double check this - that content is always one item
        } catch (e) {
            throw {err: 'Unable to get content: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------

    get_prescribeditem() { // Returns an array of all the items
        //if (!this.PrescribedItem) return {}
        try {
            return new erx_prescribeditem_model(this['PrescribedItem' + raw_prop_id] || null) // Need to double check this - that content is always one item
        } catch (e) {
            throw {err: 'Unable to get content: ' + e, e}
        }
    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.CONTENT_PROPS.forEach(p => {
        if (p.name == 'Patient') { 
            let t_prop = JSON.parse(JSON.stringify(p))
            t_prop.name += raw_prop_id
            this.define_prop(t_prop)
            Object.defineProperty(this, p.name, { get() { return this.get_patient() }, set(val) { this[t_prop.name] = val && val.inner_data || {} }})
        } else if (p.name == 'Clinician') { 
            let t_prop = JSON.parse(JSON.stringify(p))
            t_prop.name += raw_prop_id
            this.define_prop(t_prop)
            Object.defineProperty(this, p.name, { get() { return this.get_clinician() }, set(val) { this[t_prop.name] = val && val.inner_data || {} }})
        } else if (p.name == 'PrescribedItem') { 
            let t_prop = JSON.parse(JSON.stringify(p))
            t_prop.name += raw_prop_id
            this.define_prop(t_prop)
            Object.defineProperty(this, p.name, { get() { return this.get_prescribeditem() }, set(val) { this[t_prop.name] = val && val.inner_data || {} }})
        } else this.define_prop(p)
    })}

    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.content_data }
    set inner_data(val) { this.content_data = val }

    //	------------------------	------------------------	------------------------

    get Patient() { return this.get_patient() } // For intelisense
    set Patient(val) { throw { override_msg: 'set Patient' }}

    get Clinician() { return this.get_clinician() }
    set Clinician(val) { throw { override_msg: 'set Clinician' }}

    get PrescribedItem() { return this.get_prescribeditem() }
    set PrescribedItem(val) { throw { override_msg: 'set PrescribedItem' }}

    get Pharmacy() { throw { override_msg: 'get Pharmacy' }}
    set Pharmacy(val) { throw { override_msg: 'set Pharmacy' }}

    get DispensedItem() { throw { override_msg: 'get DispensedItem' }}
    set DispensedItem(val) { throw { override_msg: 'set DispensedItem' }}

    get DispensedPatient() { throw { override_msg: 'get DispensedPatient' }}
    set DispensedPatient(val) { throw { override_msg: 'set DispensedPatient' }}

    get LastDispensedItem() { throw { override_msg: 'get LastDispensedItem' }}
    set LastDispensedItem(val) { throw { override_msg: 'set LastDispensedItem' }}

    get TokenMetadata() { throw { override_msg: 'get TokenMetadata' }}
    set TokenMetadata(val) { throw { override_msg: 'set TokenMetadata' }}

}