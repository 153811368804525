import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Form, Input, Icon } from "semantic-ui-react";
import Hero from "../NUI/Hero";
import SIcon from "../NUI/StreamlineIcons/SIcon";
import Accordion from "../NUI/Accordion";
import MainTiles from "../../xAppLib/UIelems/MainTiles";
import { cls } from "../NUI/utils";

const RenderPriceOptions = ({ row }) => {

    if (row.pricing?.weeknd && row.pricing?.base && row.pricing?.weeknd !== row.pricing?.base) return (
        <>
            Weekday ${row.pricing.base}
            <br />
            Weekend ${row.pricing.weeknd}
        </>
    )

    return <>${row.cost}</>
}

const Pricing = () => {
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const prs_lst = app.site_status?.prs_lst;
        if (prs_lst) {
            const data = Object.keys(prs_lst).map((d) => ({
                title: prs_lst[d].n,
                s: Object.entries(prs_lst[d].s)
                    .sort((a, b) => a[1].priority - b[1].priority)
                    .map(([k, v]) => ({
                        ...v,
                        cost: app.site_status?.prs_mc[v?.price_ref],
                        pricing: app.site_status?.prs_mc_var[v?.price_ref],
                        lt: `Request a ${v?.l || prs_lst[d]?.l}`,
                    })),
            }));
            setData(data);
            setSearchData(data);
            setLoading(false);
        }
    }, [app.site_status?.prs_lst]);

    const handleSearch = (e) => {
        let srch = e.target.value;
        setSearch(srch);

        if (srch.length > 0) {
            let filtered = data.map((sec) => ({
                ...sec,
                s: ["sti", "uti", "pcos"].includes(srch.toLowerCase())
                    ? sec?.s.filter((item) =>
                          item?.n?.includes(srch.toUpperCase())
                      )
                    : sec?.s.filter((item) =>
                          item?.n?.toLowerCase().includes(srch.toLowerCase())
                      ),
            }));
            setSearchData(filtered);
        } else {
            setSearchData(data);
        }
    };

    const resetSearch = () => {
        setSearch("");
        setSearchData(data);
    };

    return (
        <div className="bg-white pricing pb-12 md:pb-[96px]">
            <Hero
                img="pricing-banner.jpg"
                title={
                    <>
                        <span className="font-bold">Affordable healthcare</span>
                        <br />
                        for all Australians.
                    </>
                }
            />

            <div className="bg-is-gray-20 info-search py-12 md:py-[96px]">
                <Container>
                    <h1 className="!text-is-black mb-0">Our Pricing</h1>

                    <Form>
                        <div className="relative py-10 md:py-12">
                            <Input
                                value={search}
                                icon={
                                    <div className="search-icon">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.59842 2.80002C4.94746 2.80002 2.79843 4.94906 2.79843 7.60002C2.79843 10.251 4.94746 12.4 7.59842 12.4C10.2494 12.4 12.3984 10.251 12.3984 7.60002C12.3984 4.94906 10.2494 2.80002 7.59842 2.80002ZM0.398438 7.60002C0.398438 3.62357 3.62198 0.400024 7.59842 0.400024C11.5749 0.400024 14.7984 3.62357 14.7984 7.60002C14.7984 9.15502 14.3054 10.5949 13.4673 11.7719L19.2469 17.5515C19.7155 18.0201 19.7155 18.7799 19.2469 19.2486C18.7783 19.7172 18.0185 19.7172 17.5499 19.2486L11.7702 13.4689C10.5933 14.3071 9.15341 14.8 7.59842 14.8C3.62198 14.8 0.398438 11.5765 0.398438 7.60002Z"
                                                fill="#111827"
                                            />
                                        </svg>
                                    </div>
                                }
                                iconPosition="left"
                                data-testid="input-search"
                                className="text-is-black-50 input-search"
                                style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    borderRadius: "32px",
                                }}
                                placeholder="Search for a service"
                                onChange={handleSearch}
                                disabled={loading}
                            />
                            {search && (
                                <Button
                                    onClick={resetSearch}
                                    basic
                                    className="!rounded-[32px] absolute right-2 top-[45.5px] md:top-[54px]"
                                    content={
                                        <>
                                            <Icon name="delete" /> Clear
                                        </>
                                    }
                                />
                            )}
                        </div>
                    </Form>

                    {loading && (
                        <div className="px-4">
                            <Icon loading name="spinner" />
                        </div>
                    )}

                    {!loading &&
                        searchData
                            ?.filter((f) => f?.s.length > 0)
                            .map((d, i) => (
                                <Accordion
                                    key={i + d.title}
                                    title={d.title}
                                    active={search.length > 0}
                                    content={
                                        <>
                                            <div className="hidden md:grid grid-cols-4 bg-is-blue-20 font-bold border-y border-is-gray-50">
                                                <div className="col-span-2 p-3 border-r border-is-gray-50">
                                                    Service
                                                </div>
                                                <div className="col-span-1 p-3 border-r border-is-gray-50">
                                                    Cost
                                                </div>
                                                <div className="col-span-1 p-3">
                                                    Request
                                                </div>
                                            </div>

                                            {d?.s?.map((s, i) => {
                                                return (
                                                    <div
                                                        className="grid grid-cols-1 md:grid-cols-4"
                                                        key={i}
                                                    >
                                                        <div className="w-full md:w-auto flex justify-between col-span-1 md:col-span-2 p-3 font-bold md:font-normal border-y border-is-gray-50 md:border-r md:border-b md:border-t-0 md:border-is-gray-10 bg-is-blue-20 md:bg-white">
                                                            {(s?.lm && (
                                                                <>
                                                                    <Link
                                                                        className="decoration-none md:underline underline-offset-2 decoration-1"
                                                                        to={
                                                                            s?.lm
                                                                        }
                                                                    >
                                                                        {s.n}
                                                                    </Link>
                                                                    <Link
                                                                        className="md:hidden h-5"
                                                                        to={
                                                                            s.lm
                                                                        }
                                                                    >
                                                                        <SIcon
                                                                            name="external"
                                                                            size="xxs"
                                                                            bold
                                                                        />
                                                                    </Link>
                                                                </>
                                                            )) ||
                                                                s.n}
                                                        </div>
                                                        <div className="flex items-center md:block col-span-1 border-r border-b border-is-gray-10">
                                                            <div className="border-r border-is-gray-10 font-bold md:hidden p-3 w-[90px]">
                                                                Cost
                                                            </div>
                                                            <div className="p-3 flex flex-wrap">
                                                                <span
                                                                    className={cls(
                                                                        s?.price_disp ==
                                                                            "From"
                                                                            ? "mr-1"
                                                                            : "order-last ml-1"
                                                                    )}
                                                                >
                                                                    {
                                                                        s?.price_disp
                                                                    }
                                                                </span>
                                                                <RenderPriceOptions row={s} />
                                                            </div>
                                                        </div>
                                                        <div className="flex md:block col-span-1 border-b border-is-gray-10">
                                                            <div className="border-r border-is-gray-10 font-bold md:hidden p-3 w-[90px]">
                                                                Request
                                                            </div>
                                                            <div className="p-3">
                                                                <Link
                                                                    as={
                                                                        s?.link?.startsWith(
                                                                            "https://"
                                                                        )
                                                                            ? "a"
                                                                            : Link
                                                                    }
                                                                    className="underline underline-offset-2 decoration-1"
                                                                    to={s?.ln}
                                                                >
                                                                    {s?.lt}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    }
                                />
                            ))}
                </Container>
            </div>

            <MainTiles
                show_hist={false}
                learnmore
                title={
                    <h2 className="text-center mb-10 mt-0">
                        Begin a consultation
                    </h2>
                }
            />
        </div>
    );
};
export default Pricing;
