import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const IcosmGridInfo = ({list, title, desc, link, link_text, dark, cls}) => {
    const img_path = "https://storage.googleapis.com/icosm/"

    return  <div className={`flex flex-col md:flex-row gap-[43px] ${cls&& cls}`}>
                <div className={`flex flex-wrap gap-[22px] w-full md:w-2/3 shrink-0 order-2 md:order-1`}>
                    {list.map((item, i) => {
                        return <div key={i} className={`${dark ? " bg-icosm-tertiary" : " bg-icosm-primary"} w-full flex items-center md:w-[calc(50%-22px)] shrink-0 py-[50px] px-[40px] min-h-[100px] md:min-h-[197px]`}>
                                    <h4 className="!text-icosm-secondary">{item}</h4>
                                </div>
                    })}
                </div>

                <div className="flex flex-col justify-center text-icosm-primary order-1 md:order-2">
                    <h2 className="!text-[44px] leading-[46px]">{title}</h2>
                    <p className="md:mt-[31px] text-icosm_md md:text-icosm_base">{desc}</p>
                    <Button className="cta cta-tertiary icon" as={Link} size="large" type="button" to={link||'/join'}>{link_text||'Sign me up'}</Button>
                </div>
            </div>
}

export default IcosmGridInfo;
