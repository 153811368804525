import MedicareCard from 'xAppLib/UIelems/MedicareCard'
import validations from 'xAppLib/libs/validations';
import { field_dva } from './user_model';
import { findBestMatch } from 'string-similarity';
import moment from 'moment-timezone';

const restrictedMeds = ['diazepam', 'endone', 'lyrica', 'stilnox', 'zolpidem', 'codeine', 'panadeine', 'benzodiazepine', 'valium', 'diazepam', 'temazepam', 'oxazepam', 'alprazolam', 'duromine', 'tramadol', 'gabapentin', 'pregabalin', 'seroquel', 'steroids', 'vyvanse', 'modafinil', 'dexamphetamine', 'ritalin', 'concerta', 'sofradex', 'roaccutane'];

export const getTimeRestriction = (hour) => {
    const now = moment();
    const earlyMorning = now.hours() < 6;
    let limit = moment().startOf('day').hours(hour);
    if (!earlyMorning && hour < 12) limit.add(1, 'day');
    return limit;
}

export const getLateCallLimitOptions = () => [
    { key: 'Anytime', value: 'Anytime', text: 'Anytime' },
    ...[ 23, 24, 1, 2, 3, 4, 5 ].map((hour) => {
        const m = getTimeRestriction(hour);
        return { key: hour, value: m.format(), text: m.format('hA') }
    }),
]

const _COMM_USR_FIELDS = [
									{
										type: "hidden",
										name: "type",
										val: "med_presc"
									},

									{
										name: "first_name",
										type: "text",
										label: "First Name",
										placeholder: "First Name",
										param_name: "first_name",
									},
									{
										name: "last_name",
										type: "text",
										label: "Last Name",
										placeholder: "Last Name",
										param_name: "last_name",
									},
									{
										name: "email",
										type: "email",
										label: "Email",
										placeholder: "Patient Email",
										// valid_not_required: true,
									},
									{
										name: "dob",
										type: "dob_date",
										label: "Date of Birth",
										placeholder: "Patient DOB",
									},
									
									{
										name: 'medicare',
										label: 'Medicare Number',
										type: MedicareCard,
										valid_not_required: true,
										validate_function:validations.validate_medicare
									},
									{
										name: 'dva',
										label: 'DVA',
										valid_not_required: true,
										validate_function:validations.validate_dva
									},
									{
										name: "conc_card",
										label: "If you have a valid concession card, please enter the card number",
										type: "masked",
										mask: [ /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /[A-Za-z]/ ],
										valid_not_required: true,
										pattern: /^\d{9}[A-Za-z]$/,
										placeholder: '000-000-000A',
										val_proc: value => value.replace(/-/g, ''),
									},
									{
										name: 'mobile',
										label: 'Mobile number',
										type: 'masked',
										mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ',/\d/, /\d/, /\d/ ],
										placeholder: "04XX XXX XXX",
										pattern: /^0[0-9]{1}\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/,
										icon: 'mobile',
										// valid_not_required: true,
									},
									{
										name: 'sex',
										label: 'Sex assigned at birth',
										type: 'select',
										// valid_not_required:true,
										clearable:true,
										disallow_null:true,
										options:[
											{ key: 'M', value: 'M', text: 'Male' },
											{ key: 'F', value: 'F', text: 'Female' },
										]
									},
									{
										name: 'address',
										label: 'Address',
										placeholder: 'Patient Address',
										type: 'address_new',
										mode: 'components',
										components:"full_address",
										// valid_not_required: true,
									},
									{
										name: "full_address",
										label:'Address Details',
										mode: 'components',
										type: 'hidden',
										validate_function: (value) => /^\d{4}$/.test(value.postcode),
									},
									{
										name: 'atsi',
										label: 'Are you of Aboriginal or Torres Strait Islander origin?',
										type: 'select',
										valid_not_required: true,
										options: [
											{text:"No", key: "N", value:"N" },
											{text:"Yes, Aboriginal", key: "A", value:"A" },
											{text:"Yes, Torres Strait Islander", key: "TSI", value:"TSI" },
											{text:"Prefer not to say", key: "", value:"" },
										]
									},
									{
										name: "em_con_name",
										type: "text",
										label: "Emergency Contact Name",
										placeholder: "Emergency Contact Name",
										param_name: "em_con_name",
										valid_not_required: true,
									},
									{
										name: 'em_con_mobile',
										label: 'Emergency Contact Mobile number',
										type: 'masked',
										mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ',/\d/, /\d/, /\d/ ],
										placeholder: "04XX XXX XXX",
										pattern: /^0[0-9]{1}\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/,
										icon: 'mobile',
										valid_not_required: true,
									}, 
									{
										name: "em_con_rel",
										type: "text",
										label: "Relationship of emergency contact to patient",
										placeholder: "Relationship of emergency contact to patient",
										param_name: "em_con_rel",
										valid_not_required: true,
									},
									{
										name: 'cons_allergy',
										label: 'Allergies',
										valid_not_required: true//(v) => v.cons_has_allergies === 'no'
									},
									{
										name: 'cons_has_allergies',
										type: 'hidden'
									},
									{
										name: 'chro_cond',
										label: 'Chronic Conditions',
										valid_not_required: true
									},
									{
										name: 'cons_medhist',
										label: 'Medical History',
										valid_not_required: true
									},
									{
										name: 'cons_meds',
										label: 'Medications',
										valid_not_required: true
									}
									// {
									// 	type: "separator",
									// },

									// {
									// 	name: "symptoms",
									// 	type: "textarea",
									// 	label: "Please describe your symptoms",
									// 	valid_not_required: true,
									// },

									// {
									// 	type: "separator",
									// },
		];

const _COMM_USR_FIELDS_EXT = [
	..._COMM_USR_FIELDS.filter(x => x.name !== 'dva'),
	{
		name: 'ctg',
		label: 'CTG',
		type: 'bool',
		valid_not_required: true,
	},
	
	field_dva

]
// 		--------------------------------		--------------------------------		---------

const _COMM_PHOTO_FIELDS = [
	{
		label:'Photos',
		name: "phts",
		type: "text",
		valid_not_required: true,
		// DIFF_scredit: {
		// 	name: "doc",
		// 	type: 'custom',
		// 	cust_fld: 'doc_set',
		// }
	},

]


const _COMM_PHRM_FIELDS = [
									{
										name: "dest_pharm_curr",
										type: "radio",
										options: ['yes', 'no'],
										value: 'yes',
									},
									
									
									
									{
										name: "dest_pharm_name",
										label:"Preferred Pharmacy name",
										type: "text",
										valid_not_required: true,
									},

									{
										name: "dest_pharm_address",
										label:"Preferred Pharmacy address",
										type: "text",
										valid_not_required: true,
									},

									{
										name: "dest_pharm_email",
										label:"Preferred Pharmacy email",
										type: "email",
										valid_not_required: true,
									},
									
									..._COMM_PHOTO_FIELDS

		];

// 		--------------------------------		--------------------------------		---------

const _COMM_TRTM_FIELDS = [
									{
										label: 'Quantity',
										placeholder: 'Quantity',
										name: "cosm_qua",
										type: "text",
										DIFF_scredit: {
											name: "qua",
										}
									},

									{
										label:'Location (e.g. Lips, Glabella, etc)',
										placeholder:'Location',
										name: "cosm_loc",
										type: "text",
										DIFF_scredit: {
											name: "loc",
										}
									},

									{
										label:'Extra notes',
										placeholder:'Notes',
										name: "cosm_note",
										type: "text",
										valid_not_required: true,
										DIFF_scredit: {
											name: "note",
										}
									},
									
									{
										label:'Total cost of treatment',
										placeholder:'Total cost',
										name: "cosm_total_cost",
										type: "number",
										DIFF_scredit: {
											name: "total_cost",
										}
									},
									
									{
										label:'Details of deposits, payments required and payment dates',
										placeholder:'Payment details',
										name: "cosm_payment",
										type: "number",
										valid_not_required: true,
										DIFF_scredit: {
											name: "payment",
										}
									},
									
									{
										label:'Refund of deposits',
										placeholder:'Deposit refunds',
										name: "cosm_refund",
										type: "number",
										valid_not_required: true,
										DIFF_scredit: {
											name: "refund",
										}
									},
									
									{
										label:'Payments for follow up care',
										placeholder:'Follow up payments',
										name: "cosm_follow_up_payment",
										type: "number",
										valid_not_required: true,
										DIFF_scredit: {
											name: "follow_up_payment",
										}
									},
									
									{
										label:'Possible further costs for revision or additional treatment',
										placeholder:'Possible expenses',
										name: "cosm_further_costs",
										type: "text",
										valid_not_required: true,
										DIFF_scredit: {
											name: "further_costs",
										}
									},
									
									..._COMM_PHOTO_FIELDS
		];

// 		--------------------------------		--------------------------------		---------

const _COMM_COSM_DOC_FIELDS = [

									{
										label:'Did you choose to skype a doctor? If so, who did you speak with?',
										name: "cosm_doc",
										type: "radio",
										DIFF_scredit: {
											name: "doc",
											type: 'custom',
											cust_fld: 'doc_set',
										}
									},
		];

const _COMM_COSM_INSTCONS_FIELDS = [

									{
										label:'Do you need to talk to the doctor?',
										name: "cosm_doc",
										type: "radio",
										options: [{c:'InstCons', n:'Yes'}, {c:'No', n:'No'}],
										// options: (_ => ([{c:'InstCons', n:'Yes'}, org_model.org_conf_itm(app.user.org, 'cosm_allw_nodoc') && {c:'No', n:'No'}].filter(Boolean)) )() ,
									},
		];

const _COMM_COSM_INSTCONS_NONODOC_FIELDS = [

									{
										label:'Do you need to talk to the doctor?',
										name: "cosm_doc",
										type: "radio",
										options: [{c:'InstCons', n:'Yes'}],
									},
		];

// 		--------------------------------		--------------------------------		---------

const _COMM_DCNS_FIELDS = [
									{
										label: 'What phone number would you like the doctor to call?',
										name: 'cons_phone',
										type: 'masked',
										mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ',/\d/, /\d/, /\d/ ],
										placeholder: "04XX XXX XXX",
										pattern: /^0[0-9]{1}\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/,
										icon: 'mobile',
										extra: "If you or the doctor require video, this can be activated by the doctor during the telehealth consultation."
									},

									{
										label: 'What is the reason for your request?',
										placeholder: 'Additional information to help our doctors better understand your situation.',
										name: "cons_desc",
										type: "textarea",
										rows: 2,
									},

									{
										label: 'What is the name of the medication you are requesting?',
										placeholder: 'Please enter a single medication.',
										name: 'dcs_med_desc',
										type: 'textarea',
										valid_not_required: true,
										validate_function: (value) => !value.split(" ").find(v => findBestMatch(v, restrictedMeds)?.bestMatch?.rating > 0.80),
										errors: v => 
											v && 'Our doctors cannot prescribe this medication.'
											|| 'What is the name of the medication you are requesting?',
										rows: 1,
									},

									{
										label: 'What is the specialist referral for?',
										placeholder: 'Please enter a specialist.',
										name: 'dcs_refr_spec',
										type: 'textarea',
										valid_not_required: true,
										rows: 1,
									},
									{
										label: 'Do you know the details of your specialist? Please enter the details below.',
										placeholder: 'Please enter your specialist details.',
										name: 'dcs_refr_dets',
										type: 'textarea',
										valid_not_required: true,
										rows: 2,
									},

									{
										label: 'What pathology test are you looking for?',
										placeholder: 'Please specify the tests you want.',
										name: 'dcs_patho_dets',
										type: 'textarea',
										valid_not_required: true,
										rows: 2,
									},

									{
										label: 'What dates do you require the medical certificate for? (Note: We cannot backdate certificates. The duration of the medical certificate is at the discretion of the consulting doctor.)',
										placeholder: 'Please provide the dates for the certificate.',
										name: 'dcs_cert_dates',
										type: 'textarea',
										valid_not_required: true,
										rows: 2,
									},

									{
										label: 'Referral to specialist doctor.',
										placeholder: 'Specialist',
										name: "cons_ref_spec",
										// type: "textarea",
										valid_not_required: true,
									},
									{
										label: 'Specialist doctor name.',
										placeholder: 'Doctor name',
										name: "cons_ref_name",
										// type: "textarea",
										valid_not_required: true,
									},
									{
										label: 'Specialist doctor email.',
										placeholder: 'Doctor email',
										name: "cons_ref_email",
										// type: "textarea",
										valid_not_required: true,
									},

									{
										label: 'Please select a preferred time block (Australian Eastern Standard Time) for our doctors to call you for your consultation. Please note we are not able to guarantee a call back in these time blocks but our doctors will use their best efforts.',
										name: "call_time",
										type: "checkbox",
										options: [{c: 'As soon as possible', n: 'As soon as possible'}, {c: '8am-12pm', n: '8am-12pm'}, {c: '12pm-3pm', n: '12pm-3pm'}, {c: '3pm-6pm', n: '3pm-6pm'}, {c: '6pm-10pm', n: '6pm-10pm'}],
										valid_not_required: true,
									},
									
									{

										label: 'Are there any times which are not convenient for our doctors to call you? Please select from the below time blocks (Australian Eastern Standard Time) and our doctors will make their best effort to avoid these times.',
										name: "dontcall_time",
										type: "checkbox-multiple",
										options: [{c: '6am-8am', n: '6am-8am'}, {c: '8am-12pm', n: '8am-12pm'}, {c: '12pm-3pm', n: '12pm-3pm'}, {c: '3pm-6pm', n: '3pm-6pm'}, {c: '6pm-10pm', n: '6pm-10pm'}, {c: '10pm-12am', n: '10pm-12am'}],
										valid_not_required: true,
									},

									{
										label: "Select an appointment time",
										name: "appointment",
										type: "appointment",
										cust_fld: "appointment",
										valid_not_required: true,
									},

									{
										label: 'Do you know the name and email address of your preferred psychologist?',
										name: "cons_psy_known",
										type: "checkbox",
										options: [{c: 'yes', n: 'Yes'}],
										valid_not_required: true,
									},

									{
										label: 'Psychologist name',
										placeholder: 'Psychologist name',
										name: "cons_psy_name",
										valid_not_required: true,
									},
									{
										label: 'Psychologist email',
										type: 'email',
										placeholder: 'Psychologist email',
										name: "cons_psy_email",
										valid_not_required: (v) => v?.cons_psy_known !== 'yes',
									},


									{
										label: 'Please enter your height (in cm).',
										placeholder: 'Your height in cm',
										name: "cons_bmi_height",
										type: "number",
										valid_not_required: true,
									},
									{
										label: 'Please enter your weight (in kg).',
										placeholder: 'Your weight in kg',
										name: "cons_bmi_weight",
										type: "number",
										valid_not_required: true,
									},
									{
										_loose: 'Accept BMI conditions',
										_strict: 'Please request a telehealth consultation.',
										name: "cons_bmi_out_of_range",
										type: "checkbox",
										options: [
											{
												c: 'Yes',
												n: 'I understand the doctor may determine treatment may not be appropriate for me'
											}
										],
										valid_not_required: true,
									},

									{
										label: 'How many cigarettes do you smoke a day?',
										placeholder: 'Number of cigarettes you smoke a day',
										name: "cons_sc_day",
										valid_not_required: true,
									},
									{
										label: 'How long have you smoked?',
										placeholder: 'Length of time you have smoked (years)',
										name: "cons_sc_length",
										valid_not_required: true,
									},
									{
										label: `We're now open 24 hours. We aim to call you as soon as possible but we may be busy. What is the latest time you’re happy for us to call you during the night?`,
                                        options: getLateCallLimitOptions(),
                                        type: 'select',
										name: "call_hour_restriction",
										valid_not_required: true,
									},
		];

// 		--------------------------------		--------------------------------		---------

const _COMM_CADO_FIELDS = [
									{
										name: "cado_want",
										type: "checkbox",
										label: "call",
										options: ['yes', 'no', 'maybe'],
										valid_not_required: true,
									},

									{
										label:'Your mobile phone number for doctor call',
										name: "cado_phone",
										type: "text",
										valid_not_required: (values)=>!values['cado_want'],
									},
									// {
									// 	name: "cado_name",
									// 	type: "text",
									// 	valid_not_required: true,
									// },
									// {
									// 	name: "cado_ccnum",
									// 	type: "text",
									// 	valid_not_required: true,
									// },
									// {
									// 	name: "cado_ccexpm",
									// 	type: "text",
									// 	valid_not_required: true,
									// },
									// {
									// 	name: "cado_ccexpy",
									// 	type: "text",
									// 	valid_not_required: true,
									// },
									// {
									// 	name: "cado_cccvc",
									// 	type: "text",
									// 	valid_not_required: true,
									// },
		];

// 		--------------------------------		--------------------------------		---------

const _COMM_YOGP_FIELDS = [
									{
										name: "yogp_want",
										type: "checkbox",
										label: "call",
										options: ['yes', 'no', 'maybe'],
										valid_not_required: true,
									},
									{
										label:"Your Doctor's name or clinic name",
										name: "yogp_label",
										type: "text",
										valid_not_required: true,
									},
									{
										label: "Update your doctor details",
										name: "yogp",
										type: "object",
										valid_not_required: true,
										update_profile: true,
									},
		];

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------

const _COMM_MMED_FIELDS = [

									{
										name: "med__name",
										type: "text",
										label: 'name',
									},
									{
										name: "med__size",
										type: "text",
										label: 'size',
										valid_not_required: true,
									},
									{
										name: "med__qnty",
										type: "text",
										label: 'quantity',
										valid_not_required: true,
									},
									{
										name: "med__dose",
										type: "text",
										label: 'dosage',
										valid_not_required: true,
									},
									{
										name: "med__days_limit",
										type: "text",
										label: 'script limit (in days)',
										valid_not_required: true,
									},
									{
										name: "med__authority_code",
										type: "text",
										label: 'Authority code number (or "auto")',
										valid_not_required: true,
									},
									{
										name: "med__phone_appr",
										type: "text",
										label: 'Phone approval number',
										valid_not_required: true,
									},
									{
										name: "med__extra",
										type: "text",
										label: 'Extra text on script',
										valid_not_required: true,
									},
		];

// 		--------------------------------		--------------------------------		---------

const _COMM_MPAY_FIELDS = [
									{
										name: "paid_want",
										type: "checkbox",
										label: "call",
										options: ['yes', 'no', 'maybe'],
										valid_not_required: true,
									},

									{
										name: "paid_phone",
										type: "text",
										valid_not_required: true,
									},
									{
										name: "paid_name",
										type: "text",
										valid_not_required: true,
									},
									{
										name: "paid_ccnum",
										type: "text",
										valid_not_required: true,
									},
									{
										name: "paid_ccexpm",
										type: "text",
										valid_not_required: true,
									},
									{
										name: "paid_ccexpy",
										type: "text",
										valid_not_required: true,
									},
									{
										name: "paid_cccvc",
										type: "text",
										valid_not_required: true,
									},
		];

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------

const _COMM_DEMO_FIELDS = [
									{
										name: "other-ocm",
										type: "radio",
										options: ['Yes', 'No'],
										label: "Can normal over the counter medications like panadol or nurofen remove your pain?",
									},

									{
										name: "allergic",
										type: "radio",
										options: ['Yes', 'No'],
										label: "Are you allergic to codeine or paracetamol?",
									},

									{
										name: "asthma",
										type: "radio",
										options: ['Yes', 'No'],
										label: "Do you suffer from severe asthma?",
									},

									{
										name: "addict",
										type: "radio",
										options: ['Yes', 'No'],
										label: "Are you or have you ever been addicted or dependent on codeine?",
									},

/*									{
										name: "sel",
										type: "select",
										label: "Team",
										options: ['yes', 'no', 'maybe'],
									},*/

		];

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------
const _COMM_EXTRAINFO_FIELDS = [
	{
		label: 'Extra request details.',
		placeholder: 'Enter here',
		name: "cons_desc",
		type: "textarea",
	}
];

const _COMM_SIGN_FIELDS = [
									{
										name: "sign",
										label: "Signature",
										type: "hidden_validated",
									},
		];

const _COMM_DELIVERY_FIELDS = [
					{
						name: "delivery",
						label:'Prefered Collection Method',
						type: "hidden_validated",
					},
		];

const _COMM_SHIPPING_FIELDS = [
					{
						name: "shipping_address_components",
						label:'Shipping Address',
						placeholder: 'Shipping Address',
						type: 'address_new',
						mode:'components',
					},
		];

const _COMM_HD_FIELDS = [
						..._COMM_DELIVERY_FIELDS,
						..._COMM_SHIPPING_FIELDS
		];

const _COMM_EXTRA_FIELDS = [
									{
										name: "want_gen",
										label:'Allow Generic',
										type: "hidden",
										value: false
									},
		];

const _COMM_DISCOUNT_FIELDS = [
									{
										name: "discount_code",
										label:'Discount Code',
										type:'text',
										valid_not_required: true,
									},
		];
		

const _COMM_PATHO_FIELDS = [
				{
					name: "selected_items",
					label:'Pathology Tests Included',
					type: "hidden",
					validate_function:(value,values)=>{
						return value && Object.keys(value).reduce((v,k)=>value[k] || v,false) || false
					}
				}
		];

const _COMM_DOC_FIELDS = [
	{
		name: 'doc',
		label: 'Doctor',
		type: 'custom',
		cust_fld: 'doc_set',
		view_path: 'doc_name',
		valid_not_required: true,
	}
]

const _EM_MEDICATION_FIELDS = [
	{
		name: 'medication',
		label: 'Medication Selection',
		type: 'text',
	}
]

const _EM_DELIVERY_FIELDS = [
	{
		name: 'delivery',
		label: 'Delivery Method',
		type: 'text',
	}
]

const _EM_CLICK_COLLECT_FIELDS = [
	{
		name: 'delivery',
		label: 'Selected Click & Collect Pharmacy',
		type: 'text',
	},
]

const _DVA_SECTION_FIELDS = [
	{
		name: 'dva_conc_eligible',
		label: 'DVA Eligibility',
		type: 'text',
	}
]

// 		--------------------------------		--------------------------------		---------

const _MYHR_FIELDS = [
									{
										name: "myhr_grant",
										type: "hidden",
										valid_not_required: true,
									}
		];

// 		--------------------------------		--------------------------------		---------
// 		--------------------------------		--------------------------------		---------

export default class script_model {

	static get COMM_USR_FIELDS() { return _COMM_USR_FIELDS; }
	static get COMM_USR_FIELDS_EXT() { return _COMM_USR_FIELDS_EXT; }
	static get COMM_PHRM_FIELDS() { return _COMM_PHRM_FIELDS; }
	static get COMM_TRTM_FIELDS() { return _COMM_TRTM_FIELDS; }
	static get COMM_COSM_DOC_FIELDS() { return _COMM_COSM_DOC_FIELDS; }
	static get COMM_COSM_INSTCONS_FIELDS() { return _COMM_COSM_INSTCONS_FIELDS; }
	static get COMM_COSM_INSTCONS_NONODOC_FIELDS() { return _COMM_COSM_INSTCONS_NONODOC_FIELDS; }
	static get COMM_DCNS_FIELDS() { return _COMM_DCNS_FIELDS; }
	static get COMM_CADO_FIELDS() { return _COMM_CADO_FIELDS; }
	static get COMM_YOGP_FIELDS() { return _COMM_YOGP_FIELDS; }

	static get COMM_MMED_FIELDS() { return _COMM_MMED_FIELDS; }
	static get COMM_MPAY_FIELDS() { return _COMM_MPAY_FIELDS; }

	static get COMM_EXTRAINFO_FIELDS() { return _COMM_EXTRAINFO_FIELDS; }
	static get COMM_SIGN_FIELDS() { return _COMM_SIGN_FIELDS; }
	static get COMM_DEMO_FIELDS() { return _COMM_DEMO_FIELDS; }
	static get COMM_HD_FIELDS() { return _COMM_HD_FIELDS; }
	static get COMM_DELIVERY_FIELDS() { return _COMM_DELIVERY_FIELDS; }
	static get COMM_SHIPPING_FIELDS() { return _COMM_SHIPPING_FIELDS; }
	static get COMM_EXTRA_FIELDS() { return _COMM_EXTRA_FIELDS; }
	static get COMM_DISCOUNT_FIELDS() { return _COMM_DISCOUNT_FIELDS; }
	static get COMM_PATHO_FIELDS() { return _COMM_PATHO_FIELDS; }

	static get COMM_DOC_FIELDS() { return _COMM_DOC_FIELDS; }
	static get EM_MEDICATION_FIELDS() { return _EM_MEDICATION_FIELDS; }
	static get EM_DELIVERY_FIELDS() { return _EM_DELIVERY_FIELDS; }
	static get EM_CLICK_COLLECT_FIELDS() { return _EM_CLICK_COLLECT_FIELDS; }
	static get DVA_SECTION_FIELDS() { return _DVA_SECTION_FIELDS; }
	static get MYHR_FIELDS() { return _MYHR_FIELDS; }

	// 		--------------------------------		--------------------------------		---------

	static init() {
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static prep_radio_form_fields (Qs) {

		// console.log('script_model :: prep_radio_form_fields', Qs)

		return Qs && Qs
				.map( (q, qnum) => { 
						const k = q.k
						const validate_function = (value,values)=>{
							const config = q.config || {}

							if (config.file) {
								if (!config.file_answer || (values[k] == config.file_answer))	{
									if (!values.phts || values.phts.filter(p=>p.tag==k).length === 0) {
										return false;
									}
								}
							}

							if (config.text) {
								if (!config.text_answer || (values[k] == config.text_answer))	{
									const inputName = `${k}_text`;
									if ((values[inputName]??'').trim().length === 0) {
										return false;
									}
								}
							}

							return !!value && value.length > 0;
						}
			
						return {
										name: k,
										type: "radio",
										validate_function,
										disallow_null: true,
										options: q.a && Object.keys(q.a).map( (ak) => ( {
															c:ak, 
															n:`${q.a[ak].num || ''} ${q.a[ak].txt} ${q.a[ak].desc || ''}`
														} ) ),
										label: `${qnum+1}. ${q.txt} ${q.desc || ''}`,
										// label: `${q.num || ''} ${q.txt} ${q.desc || ''}`,
									}
								}
							)
				|| []

	}
	
	

	static is_bulk_billed(script_type, req_type) {
		return script_type === 'doccons' && ['mhcp'].includes(req_type);
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static is_async_consult(row) {
		return (
			(['medcons', 'medbuy', 'medclick', 'pathoreq', 'docrefr'].includes(row.script_type)  && ['premed', 'remed'].includes(row.req_type))
			|| (row.script_type === 'medcons' && row.req_type === 'medcert')
		);
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static is_express_consult(row) {
		return ['exprcons', 'exprbuy', 'exprclick'].includes(row.script_type);
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static is_review_consult(row) {
		return ['qualcons', 'qualbuy', 'qualclick'].includes(row.script_type);
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static can_do_enrolment(script) {
		return (script_model.is_express_consult(script) || script_model.is_review_consult(script));
	}
}
