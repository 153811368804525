import React from 'react'
import { Button } from "semantic-ui-react"

const ShareToSocialsButton = ({subj, msg, cls}) => {

	var options = {
		subject: subj,
        message: msg,
     };

    var onSuccess = function(result) {
        console.log("Share completed? " + result.completed);
    };
    
    var onError = function(msg) {
        console.log("Sharing failed with message: " + msg);
    };

    const shareMsg = async () => {
        if (app.settings.is_ionic) {
            try {  
                window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
            } catch( err ) {
                console.log( "Sharing plugin is not available - you're probably in the browser" );
            }
        }
        else {
            navigator.share ? 
                navigator.share({
                  title: subj,
                  text: msg
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error))
            :
            app.trigger(app.events.SHOW_POPUP, {pt:"cx_txt", txt:'Share to Socials', pn:'socials', noCloseBtn: true, msg:msg, subj:subj})
        }
    }
    return  <div className="flex gap-6 w-full">
                <a className={`${cls} ui button basic !hidden md:!block`} href={`mailto:?subject=${subj}&body=${encodeURIComponent(msg)}`}>Share via email</a>
                <Button basic className={`${cls} !w-full lg:!w-fit !block md:!hidden`} onClick={_=>shareMsg()}>Share with friends</Button>
            </div>
}
 
export default ShareToSocialsButton;


export const Socials = ({subj, msg}) => {
    const socials = [
        { 
            name:"WhatsApp",
            link:`https://wa.me/?text=${encodeURIComponent(msg)}`,
            img:'whatsapp_logo.svg'
        },
        { 
            name:"Email",
            link:`mailto:?subject=${subj}&body=${encodeURIComponent(msg)}`,
            img:'mail_logo.png'
        },
        { 
            name:"X",
            link:`https://twitter.com/intent/tweet?text=${encodeURIComponent(msg)}`,
            img:'x_logo.svg'
        },
        { 
            name:"Facebook",
            link:`https://www.facebook.com/sharer/sharer.php?u=instantscripts.com.au/refer-a-friend&quote=${encodeURIComponent(msg)}`,
            img:'facebook_logo.svg'
        }
    ]
    return <div className="flex flex-wrap justify-center">
           {socials.map((s, i) => {
             return <a className="flex flex-col w-1/2 items-center text-[12px] !text-is-black text-center mb-6" href={s?.link} target="_blank" key={i}>
                        <img className="w-[50px] mb-2" src={`https://storage.googleapis.com/instant-med-public/${s.img}`} />
                        {s.name}
                    </a>
            })}
        </div>
}