import React from 'react';
import { Header } from 'semantic-ui-react';

const SectionHeader = ({children, small = false}) => {
	const Wrap = small && 'small' || 'span'
	const color = small && 'grey' || undefined
	const style = small && {borderBottom:'none'} || {}

	return <Header block as='h3' color={color} style={style}>
		<Wrap>{children}</Wrap>
	</Header>
};
 
export default SectionHeader;