/* global AndroidPermissions  */

import Peer from 'peerjs';
import EventEmitter from 'xAppLib/libs/eventemitter'
import { AndroidPermissions } from '@ionic-native/android-permissions';

const DEBUG = true;

export default class vidcall_model extends EventEmitter {
	
	static ON_CALL = 'ON_CALL'
	static ON_DISCONNECT = 'ON_DISCONNECT'
	static ON_OPEN = 'ON_OPEN'
	
	constructor(user,token) {
		super()
		this.peer_id = null
		this.user = user
		this.token = token
		this.init_connect()
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async init_connect () {

		this.peer = new Peer({
					// key: 'quick-scripts-safe-scripts', 
					secure: true, 
					host: 'pcn-dot-instant-med.ts.r.appspot.com', 
					port: 443,
					// port: 80,
					// port: 9000,
					// debug: true,
					debug: 2, // 1: Errors, 2: Warnings, 3: All logs
					//   path: "/",
					path: "/",
					// token: util.randomToken(),
					// config: util.defaultConfig,
				});

		// DEBUG && console.log('vidcall_model :: init_connect :: on open :: peer', this.peer);

		this.peer.on('open', (id) => {

					this.peer_id = id
					console.log("peer_id 2", id);

	
					DEBUG && console.log('vidcall_model :: init_connect :: on open :: peer_id', this.peer_id);

					app.user.vidcall_peer_id = this.peer_id
					app.trigger(app.events.USER_UPD, 'UserPeerChanged')

					this.emit(vidcall_model.ON_OPEN, id)

		} )

		// console.log("peer_id 1", peer_id);

		// console.log('vidcall_model :: init_connect :: peer_id', app.user.vidcall_peer_id, peer_id);

		this.peer.on('connection', (conn) => { 

					DEBUG && console.log('vidcall_model :: init_connect :: on connection :: conn', conn);

				});

		this.peer.on('disconnected', () => { 

					DEBUG && console.log('vidcall_model :: init_connect :: on disconnected ');
					this.emit(vidcall_model.ON_DISCONNECT)
				});


    	// this.set_peer_answer()

		//this.peer.on('call', this.peer_answer.bind(this))
		this.peer.on('call', (call) => { 

			DEBUG && console.log('vidcall_model :: set_peer_answer :: on call ', call);
			
			this.emit(vidcall_model.ON_CALL, call)

		});
		
		// this.peer_id = peer_id
		
		// return peer_id;
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	destroy() {
		this.peer.destroy()
		
	}
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	peer_connect (p) {

		this.conn = this.peer.connect(p);


		console.log('conn', this.conn);

		this.conn.on('open', () => {

					console.log('conn open');

					// Receive messages
					this.conn.on('data', (data) => {
									console.log('Received data', data);
								});

					// Send messages
					this.conn.send('Hello!');

				});

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async getMediaStream(audio = true,video = true) 
	{
		const constraints = {
			audio, 
			video: video ? { 
						// width: { min: 1024, ideal: 1280, max: 1920 },
						// height: { min: 776, ideal: 720, max: 1080 }, 
						width: { min: 320, ideal: 640, max: 940 },
						height: { min: 230, ideal: 360, max: 690 }, 
						facingMode: "user" 
					} : false
		}

		try {
			await vidcall_model.getMediaStreamPermission();
			console.log("Authorised AV");
			return await navigator.mediaDevices.getUserMedia(constraints);
		} catch(err) {
			console.error('cant get stream', err);
			throw err
		}
	}

	static async getMediaStreamPermission() {
		if (app.settings.is_ionic_android) {
			return AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.CAMERA)
					.then(({hasPermission}) => hasPermission || Promise.reject(new Error("Camera unauthorised")))
					.then(() => AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.RECORD_AUDIO))
					.then(({hasPermission}) => hasPermission || Promise.reject(new Error("Audio unauthorised")))
					.catch(() => false);
		} else if (app.settings.is_ionic_ios && !navigator.mediaDevices && cordova.plugins.iosrtc) {
			cordova.plugins.iosrtc.registerGlobals()
		}
		return true;
	}
	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async peer_call (p, userStream) {
		
		return new Promise((resolve, reject)=>{ 
		
			DEBUG && console.log('vidcall_model :: peer_call :: oth peer_id', p);

			DEBUG && console.log('vidcall_model :: peer_call :: on stream :: oth peer_id', p);
			DEBUG && console.log('vidcall_model :: peer_call :: my stream :: ', userStream);

			const connection = this.peer.call(p, userStream);
			console.log({connection});
			connection.on('stream', 
					remoteStream => {

						DEBUG && console.log('vidcall_model :: peer_call :: on call stream :: remoteStream', remoteStream);
						
						resolve({connection,remoteStream})
						
						// document
						// 	.getElementById("friendsVideo")
						// 	.srcObject = remoteStream;

					} );
			connection.on('error', 
					remoteStream => {

						DEBUG && console.log('vidcall_model :: peer_call :: on ERROR :: remoteStream', remoteStream);
						
					
						// document
						// 	.getElementById("friendsVideo")
						// 	.srcObject = remoteStream;

					} );
			
		})


	
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	async peer_answer (call, userStream) {
		
		return new Promise((resolve,reject)=> {
			
			DEBUG && console.log('vidcall_model :: set_peer_answer :: on call ', call);

			// const stream = await this.getMediaStream()

			// this.navigator_getUserMedia(
			// 		{video: true, audio: true}, 

			// 		(stream) => {

						DEBUG && console.log('vidcall_model :: set_peer_answer :: on call :: stream', userStream);

						// document
						// 	.getElementById("yourVideo")
						// 	.srcObject = stream;

						call.answer(userStream); // Answer the call with an A/V stream.
						
						call.on('stream', remoteStream => {
							
							resolve({remoteStream})

							// document
							// 	.getElementById("friendsVideo")
							// 	.srcObject = remoteStream;

						});
		// 			}, 
		
		// 			(err) => {
		// 				console.log('Failed to get local stream' ,err);
		// 			}
		// 		);
		})

			

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	set_peer_answer () {

		DEBUG && console.log('vidcall_model :: set_peer_answer ');

		this.peer.on('call', async call => {

			DEBUG && console.log('vidcall_model :: set_peer_answer :: on call ', call);

			const stream = await this.getMediaStream()

			// this.navigator_getUserMedia(
			// 		{video: true, audio: true}, 

			// 		(stream) => {

						DEBUG && console.log('vidcall_model :: set_peer_answer :: on call :: stream', stream);

						document
							.getElementById("yourVideo")
							.srcObject = stream;

						call.answer(stream); // Answer the call with an A/V stream.

						call.on('stream', (remoteStream) => {

							document
								.getElementById("friendsVideo")
								.srcObject = remoteStream;

						});
		// 			}, 
		
		// 			(err) => {
		// 				console.log('Failed to get local stream' ,err);
		// 			}
		// 		);
		});

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	

}
