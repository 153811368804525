import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCurrent } from "../../xAppLib/Hooks/useCurrent";
import { obj_map } from "../../xAppLib/helpers/obj_map";
import docs_model from "../../models/docs_model";
import user_model from "../../models/user_model";
import obj_filter_by_key from "../../xAppLib/helpers/obj_filter_by_key";

/**
 * Enable toggling based on some key (or index), e.g. for showing/hiding additional rows
 * @return {[Record<string, boolean>, Function]} - a tuple with the first item being an object of state by key and a function to toggle by key
 * @example
 *  const [rows, toggleRow] = useToggleByKey();
 *  rows[row.id] // undefined (falsey)
 *
 *  toggleRow(row.id); // in some click handler
 *  rows[row.id] // true
 *
 *  toggleRow(row.id);
 *  rows[row.id] // false, could use false vs undefined to know if it has ever been enabled
 */
export function useToggleByKey() {
	const [indexes, setIndexes] = useState({});

	const toggle = useCallback((key, value) => {
		setIndexes(prev => ({
			...prev,
			[key]: typeof value === 'undefined' ? !prev[key] : value === prev[key] ? null : value,
		}));
	}, []);

	return [indexes, toggle];
}

/**
 * Poor man's react-query. Allows loading some data in response to user action and track the progress.
 * Provide a fetch function that takes some key and loads some data.
 * Loading the same key twice will return the previous value (inflight or otherwise) instead of hitting the network again
 * @param {fetchHandler} fetchFn - function that takes a key and returns a promise that resolved to data
 * @param {preloadFn?} preloadFn - optional function that that returns an object of key/value pairs to pre-populate the cache
 * @return {[Record<string, LoadingState>, LazyLoadFunctions]} - an object describing loading state by key, and the function to fetch data
 * @example
 * const [movies, load] = useLazyLoad(id => API_service.load('movie/${id}'));
 * const [id, setId] = useState(null);
 * return (<>
 *     <select onChange={({target:{value}}) => {setId(value); value && load(value)}}>
 *         <option>--select--</option>
 *         {someListOfOptions().map(
 *         	opt => <option key={opt.id} value={opt.id}>{opt.label}</option>
 *         )}
 *     </select>
 *     {id && movies[id] && <>
 *         {movies[id].loading && <>loading...</>}
 *         {movies[id].value && <pre>{JSON.stringify(movies[id].value)}</pre>}
 *         {movies[id].error && <>That didn't work, <button onClick={() => load(id, true)}>try again?</button></>}
 *     </>}
 * <>)
 */
export function useLazyLoad(fetchFn, preloadFn) {
	const [cache, setCache] = useState({});
	const [ready, setReady] = useState(!preloadFn);
	const _inflight = useRef({});
	const _fetch = useCurrent(fetchFn);

	const _mounted = useRef(true);
	useEffect(() => {
		_mounted.current = true;
		return () => {
			_mounted.current = false;
		}
	}, []);

	const load = useCallback((key, force = false) => {
		if (!_mounted.current) return;

		if (!_inflight.current[key] || force) {
			const promise = Promise.resolve(_fetch.current(key));
			_inflight.current[key] = promise;

			setCache(prev => ({
				...prev,
				[key]: {
					loading: true,
					error: null,
					// keep any existing value while loading new one
					value: key in prev ? prev[key].value : null,
				}
			}));

			promise
				.then(value => ({value}))
				.catch(error => ({error}))
				.then(result => {
					if (!_mounted.current) return;

					// new request forced while one already in flight?
					if (_inflight.current[key] !== promise) return;

					setCache(prev => ({
						...prev,
						[key]: {
							...result,
							loading: false,
						}
					}));
				});
		}

		return _inflight.current[key];
	}, []);

	const remove = useCallback((key) => {
		if (!_mounted.current) return;

		delete _inflight.current[key];
		setCache(({[key]: removed, ...prev}) => prev);
	}, []);

	const _preload = useCurrent(preloadFn);
	const preload = useCallback(async () => {
		if (!_preload.current) return;
		const data = await _preload.current();
		if (!_mounted.current || !data || typeof data !== 'object') return;

		_inflight.current = {
			..._inflight.current,
			...obj_map(data, (value) => Promise.resolve(value))
		};
		setCache(prev => ({
			...prev,
			...obj_map(data, (value) => ({loading: false, value}))
		}));
		setReady(true);
	}, []);

	useEffect(() => {
		preload().then(() => false /* ignore */);
	}, [preload]);

	const actions = useMemo(()=>({load, remove, preload, ready}),[load, remove, preload, ready])

	return [cache, actions];
}

/**
 * @deprecated
 * This is just here for usage in ScriptsList.jsx.
 * Going forward, use the hook with function components
 */
export async function getDocsNames() {
	return _get_docs_names();
}

async function _get_docs_names(with_doc_type = true) {
	const [docs_gps, docs_cosm] = await Promise.all([
		docs_model.get_GPs(),
		docs_model.get_docs(),
	]);

	const map_doc_name = doc_type => doc => with_doc_type ? `${doc.n} (${doc_type})` : doc.n;

	return {
		...obj_map(docs_gps, map_doc_name('Doctor')),
		...obj_map(docs_cosm, map_doc_name('cosm'))
	};
}

async function _get_active_docs_names(with_doc_type = true) {
	const [docs_gps, docs_cosm] = await Promise.all([
		docs_model.get_GPs(),
		docs_model.get_docs(),
	]);

	const only_active_docs = (_key, doc) => doc?.active !== false;
	const active_gps = obj_filter_by_key(docs_gps, only_active_docs);
	const active_cosm = obj_filter_by_key(docs_cosm, only_active_docs);

	const map_doc_name = doc_type => doc => with_doc_type ? `${doc.n} (${doc_type})` : doc.n;

	return {
		...obj_map(active_gps, map_doc_name('Doctor')),
		...obj_map(active_cosm, map_doc_name('cosm')),
	};
}

/**
 * Return record of doc ids to names (for GPs and Cosmetic Doctors)
 * @return {Record<string, string>}
 */
export function useDocsNames() {
	const [names, setNames] = useState({});

	useEffect(() => {
		let mounted = true;

		const with_doc_type = user_model.check_access('cust_supp');
		_get_docs_names(with_doc_type).then(n => {
			if (mounted) {
				setNames(n);
			}
		});

		return () => {
			mounted = false;
		}
	}, []);

	return names;
}

/**
 * Return record of doc ids to names (for GPs and Cosmetic Doctors)
 * @return {Record<string, string>}
 */
export function useActiveDocsNames() {
	const [names, setNames] = useState({});

	useEffect(() => {
		let mounted = true;

		const with_doc_type = user_model.check_access('cust_supp');
		_get_active_docs_names(with_doc_type).then(n => {
			if (mounted) {
				setNames(n);
			}
		});

		return () => {
			mounted = false;
		}
	}, []);

	return names;
}

// --- JS DOC HELPERS

/**
 * @typedef {Object} LoadingState
 * @property {boolean} loading - if the request is inflight or not
 * @property {*} value - whatever the loading function resolves to (assuming it resolved)
 * @property {*} error - whatever the loading function rejected with (assuming it rejected)
 */

/**
 * @callback FetchFunction
 * @param {string | number | *} key - some key (like a sid) to be used to fetch data
 * @param {boolean = false} force - retry instead of returning cached value
 * @return {Promise<T>} - promise per fetchHandler
 * @template T
 */

/**
 * @callback RemoveEntryFunction
 * @param {string | number | *} key - removes the key (like a sid) from the cache
 */

/**
 * @typedef {Object} LazyLoadFunctions
 * @property {FetchFunction} load - load a record
 * @property {RemoveEntryFunction} remove - remove an entry from the cache
 * @property {preloadFn} preload - bulk update the cache from preloading (assuming a preload function was provide). This will _not_ clear existing entries.
 */

/**
 * @callback fetchHandler
 * @param {string | number | *} key - some key (like a sid) to be used to fetch data
 * @return {Promise<T>} - promise per fetchHandler
 * @template T
 */


/**
 * @callback preloadFn
 * @return {Promise<Record<string, T>>} - returns key/value pairs to preload into the cache
 * @template T
 */