export const generatePrettyAuthorLabel = (slot) => {
	const { authorInstitution = '', authorPerson = '', authorRole = '' } = slot;
	const cleanAuthorPerson = authorPerson
		.split('&')[0]
		.split('^')
		.filter((el) => el !== '')
		.reverse()
		.join(' ');
	const cleanAuthorInstitution = authorInstitution.split('^')[0];
	return `${cleanAuthorPerson} | ${authorRole} | ${cleanAuthorInstitution}`;
};

export const generatePrettyIndividualsDetails = (individual) => {
	const { name: name_obj, sex, dateOfBirth: dob, ihiNumber, indigenousStatus } = individual || {};
	const { familyName, givenName } = name_obj || {};

	const name = [givenName, familyName].flat().join(' ');

	const patientData = {
		name,
		sex,
		dob,
		ihiNumber,
		indigenousStatus: interprateIndigenousStatusCode(indigenousStatus),
	};

	return patientData;
};

export const interprateIndigenousStatusCode = (code) => {
	const codeMapping = {
		1: 'Aboriginal but not Torres Strait Islander origin',
		2: 'Torres Strait Islander but not Aboriginal origin',
		3: 'Both Aboriginal and Torres Strait Islander origin',
		4: 'Neither Aboriginal nor Torres Strait Islander origin',
		9: 'Not stated/inadequately described',
	};

	return codeMapping[code] || 'Unknown';
};
