// 
// From https://github.com/KyleAMathews/react-gravatar
// From http://kyleamathews.github.io/react-gravatar/
// Has a problem because using es2015 babel preset
// Had to include to use
// 
// 

import React from 'react'
import PropTypes from 'prop-types'

import gravatar_url from './gravatar_url'

export default class Gravatar extends React.Component {

	static propTypes = {
		email: PropTypes.string,
		md5: PropTypes.string,
		size: PropTypes.number,
		rating: PropTypes.string,
		default: PropTypes.string,
		className: PropTypes.string,
		protocol: PropTypes.string,
		name: PropTypes.string,
		style: PropTypes.object,
	}
	static defaultProps = {
		size: 50,
		rating: 'g',
		default: 'retro',
		protocol: 'https://',
	}

	render() {

		let className = 'react-gravatar'
		if (this.props.className) {
			className = `${className} ${this.props.className}`
		}

		// Clone this.props and then delete Component specific props so we can
		// spread the rest into the img.
		let { ...rest } = this.props
		delete rest.md5
		delete rest.email
		delete rest.protocol
		delete rest.rating
		delete rest.size
		delete rest.style
		delete rest.className
		delete rest.default

		return <img
				// alt={`Gravatar for ${formattedEmail}`}
				style={this.props.style}
				src={gravatar_url({
						email: this.props.email,
						md5: this.props.md5,
						size: this.props.size,
						rating: this.props.rating,
						default: this.props.default,
						className: this.props.className,
						protocol: this.props.protocol,
						name: this.props.name,
						style: this.props.style,
					 })}
				height={this.props.size}
				width={this.props.size}
				{...rest}
				className={className}
			/>
	}

}

