import db_lib from 'xAppLib/libs/db_lib';

import API_service from 'xAppLib/providers/API_service'
/*
const _FRDB_LOC = 'MedQsForm';

const _DET_FIELDS_PHARM = [
						{
							name: 'Time',
							jpath: 'add_tm'
						},

						{
							name: 'Pharmacy',
							jpath: 'pharm_db_data.name'
						},

						{
							name: 'First',
							jpath: 'spd_data.first_name'
						},
						{
							name: 'Last',
							jpath: 'spd_data.last_name'
						},
						{
							name: 'DOB',
							jpath: 'spd_data.dob'
						},

						{
							name: 'Medicare',
							jpath: 'spd_data.medicare'
						},

						{
							name: 'Medicine',
							jpath: 'med_db_data.name'
						},
						{
							name: 'Size',
							jpath: 'med_db_data.size'
						},

						// {
						// 	name: 'View Script',
						// 	jpath: 'prescPDF'
						// },
					];

const _DET_FIELDS_NURSE = [
						{
							name: 'Time',
							jpath: 'add_tm'
						},


						{
							name: 'First',
							jpath: 'spd_data.first_name'
						},
						{
							name: 'Last',
							jpath: 'spd_data.last_name'
						},
						{
							name: 'DOB',
							jpath: 'spd_data.dob'
						},

						{
							name: 'Medicare',
							jpath: 'spd_data.medicare'
						},

						{
							name: 'Medicine',
							jpath: 'med_db_data.name'
						},
						{
							name: 'Size',
							jpath: 'med_db_data.size'
						},
						{
							name: 'Quantity',
							jpath: 'cosm_det.qua'
						},
						{
							name: 'Location',
							jpath: 'cosm_det.loc'
						},
						{
							name: 'Notes',
							jpath: 'cosm_det.note'
						},


						{
							name: 'By',
							jpath: 'usr.displayName'
						},
						{
							name: 'User',
							jpath: 'usr.email'
						},

						{
							name: 'Clinic',
							jpath: 'dvc.org_db.name'
						},

						// {
						// 	name: 'View Script',
						// 	jpath: 'prescPDF'
						// },
					];

const _DET_FIELDS_MANG = [
						{
							name: 'Time',
							jpath: 'add_tm'
						},

						// {
						// 	name: 'Nurse',
						// 	jpath: 'usr.email'
						// },

						{
							name: 'First',
							jpath: 'spd_data.first_name'
						},
						{
							name: 'Last',
							jpath: 'spd_data.last_name'
						},
						{
							name: 'DOB',
							jpath: 'spd_data.dob'
						},

						{
							name: 'Medicare',
							jpath: 'spd_data.medicare'
						},

						{
							name: 'Medicine',
							jpath: 'med_db_data.name'
						},
						{
							name: 'Size',
							jpath: 'med_db_data.size'
						},
						{
							name: 'Quantity',
							jpath: 'cosm_det.qua'
						},
						{
							name: 'Location',
							jpath: 'cosm_det.loc'
						},
						{
							name: 'Notes',
							jpath: 'cosm_det.note'
						},


						{
							name: 'By',
							jpath: 'usr.displayName'
						},
						{
							name: 'User',
							jpath: 'usr.email'
						},

						{
							name: 'Clinic',
							jpath: 'dvc.org_db.name'
						},

						// {
						// 	name: 'View Script',
						// 	jpath: 'prescPDF'
						// },
					];

const _DET_FIELDS_ADMIN = [
						{
							name: 'Num',
							jpath: 'snum'
						},
						{
							name: 'Type',
							jpath: 'script_type'
						},

						{
							name: 'Time',
							jpath: 'add_tm'
						},

						{
							name: 'First',
							jpath: 'spd_data.first_name'
						},
						{
							name: 'Last',
							jpath: 'spd_data.last_name'
						},
						{
							name: 'DOB',
							jpath: 'spd_data.dob'
						},

						// {
						// 	name: 'Medicare',
						// 	jpath: 'spd_data.medicare'
						// },

						{
							name: 'Medicine',
							jpath: 'med_db_data.name'
						},
						{
							name: 'Size',
							jpath: 'med_db_data.size'
						},


						{
							name: 'Quantity',
							jpath: 'cosm_det.qua'
						},
						{
							name: 'Location',
							jpath: 'cosm_det.loc'
						},
						{
							name: 'Notes',
							jpath: 'cosm_det.note',
							type: 'show_more'
						},


						{
							name: 'By',
							jpath: 'usr.displayName'
						},
						{
							name: 'User',
							jpath: 'usr.email'
						},


						{
							name: 'Pharmacy',
							type: 'or',
							jpath: 'dvc.pharm_db.name',
							jpath1: 'pharm_db_data.name'
						},

						{
							name: 'Clinic',
							jpath: 'dvc.org_db.name'
						},

						// {
						// 	name: 'View Script',
						// 	jpath: 'prescPDF'
						// },
					];
*/

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class reports_stats_model extends db_lib {

	// static get FRDB_LOC() { return _FRDB_LOC + (reports_stats_model.alt_db||''); }
	// static get DET_FIELDS_PHARM() { return _DET_FIELDS_PHARM; }
	// static get DET_FIELDS_NURSE() { return _DET_FIELDS_NURSE; }
	// static get DET_FIELDS_MANG() { return _DET_FIELDS_MANG; }
	// static get DET_FIELDS_ADMIN() { return _DET_FIELDS_ADMIN; }
	// static get DET_FIELDS() { return _DET_FIELDS_ADMIN; }
	// static get DET_FIELDS_COSM() { return _DET_FIELDS_COSM; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

	// static get SCRIPT_PATIENT_FIELDS() { return _SCRIPT_PATIENT_FIELDS; }

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static set_db(c) {
		reports_stats_model.alt_db = c || false;
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static load_data( params, ca ) {

		API_service.call_api(
									'getStats',
									params,
									// 'post_form_cred', null,
									r => ca && ca(r)
							)

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------


}
