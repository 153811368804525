import React, { useEffect,useState } from 'react';
import { Loader, Modal,Header, Button, Icon } from 'semantic-ui-react'

const Troubleshoot = (_) => {

	const [showTroubleshoot,setShowTroubleshoot] = useState(false)
	const [showTroubleshootModal,setShowTroubleshootModal] = useState(false)

	useEffect(_=>{
		if (app.settings.has_troubleshoot)
			setTimeout(_=>{
				setShowTroubleshoot(true)},10*1000)
			},[])

	return <React.Fragment>
	{ !app.state.loaded && showTroubleshoot && <div className='troubleshoot'>
		<div> If loading for more than 15 seconds, <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={_=>setShowTroubleshootModal(true)}>click here</span> to troubleshoot.</div>
		<Icon name='x' onClick={_=>setShowTroubleshoot(false)} color="grey" size="small"/>
	</div>}
		
	<Modal
		open={showTroubleshootModal}
		onClose={_ => {showTroubleshootModal(false)}}
		size='small'
	  >
		<Header color='blue' icon='question circle outline' content='Troubleshooting App Start' />
		<Modal.Content>
		  <h3>Step 1. If the app is not progressing past the start screen it can likely be fixed with a soft reset / force restart.</h3>
		  
		  <h3>How to force restart an iPad</h3>

		  <p>Press and hold the top button and the Home button at the same time for about 10 seconds. <strong>When the Apple logo appears</strong>, release both buttons.</p>
		  
			<img src='https://storage.googleapis.com/instant-med-public/ipad-support.png' style={{maxWidth:'100%',margin:'20px auto',display:'block'}}/>
		
			<h3 style={{marginTop:'0'}}>Step 2. The soft reset did not help.</h3>
			<p>If the app is still not progressing past the start screen after the restart please contact us.</p>
			  
		</Modal.Content>
		<Modal.Actions>
		  <Button color='blue' onClick={() => setShowTroubleshootModal(false)} >
			<Icon name='checkmark' /> Close
		  </Button>
		</Modal.Actions>
	  </Modal>
  </React.Fragment>
};

export default Troubleshoot
