import React, { Component } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
	} from 'semantic-ui-react'

import debounce from '../libs/debounce'

const _default_page_size = 200

const DEBUG = false

class DataConnector extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pagedData : null,
			loading : true,
		}
		if (props.debounceFilters)
			this.debouncedGetData = debounce(this.getData,props.debounceFilters)

	}
	
	async componentDidUpdate(prevProps, prevState) {
		
		if (this.props.data != prevProps.data && typeof this.props.data !== 'function') {
			this.getData()
		}
		
		if (prevProps.page != this.props.page ) {
			DEBUG && console.log("page changed %o => %o",prevProps.page,this.props.page)
			this.getData()
		}
		
		if (prevProps.pageSize != this.props.pageSize ) {
			DEBUG && console.log("page size changed %o => %o",prevProps.pageSize,this.props.pageSize)
			this.getData()
		}
		
		if (prevProps.refresh != this.props.refresh ) {
			DEBUG && console.log("force refresh %o => %o",prevProps.refresh, this.props.refresh)
			this.getData()
		}
		
		if (prevProps.filters != this.props.filters && JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
			DEBUG && console.log("filters changed %o => %o",prevProps.filters,this.props.filters)

			if (this.props.debounceFilters)
			 	this.debouncedGetData()
			else
				this.getData()
		}
		
		if (prevProps.data_params != this.props.data_params && JSON.stringify(prevProps.data_params) != JSON.stringify(this.props.data_params)) {
			DEBUG && console.log("data_params changed %o => %o",prevProps.data_params,this.props.data_params)
			this.getData()
		}
		
	}

	componentDidMount() {
		this.getData()
	}
	
	
	componentWillUnmount() {
		this.componentIsGone = true
	}
	
	onUpdate = (index,data) => {
		const { pagedData } = this.state
		pagedData[index] = {...pagedData[index],...data}
		this.setState({pagedData:[...pagedData]})
		
	}

	

	async getData() {
		const { data, filters = [], page = 1, pageSize = _default_page_size } = this.props
		
		
		if (typeof data == 'function') {
			this.setState({loading:true})
			const v = this.v = new Date().getTime()
			const res = await data(filters, page, pageSize)
			if (this.componentIsGone || (v !== this.v))
				return
			this.setState({loading:false})
			if (!res)
				return
			const { data : pagedData, total } = res
			DEBUG && console.log("received %o",{ pagedData, total })
			const pages = total ? Math.ceil(total/pageSize) : 1
			this.setState({pagedData,loading:false,pages,total})
		} else {
			// direct data provided, just paginate if needed
			if (data) {
				let pages, pagedData
				if (pageSize>0) {
					pages = Math.ceil(data.length / pageSize)
					pagedData = data.slice((page-1) * pageSize, page * pageSize)
				} else {
					pages = 1
					pagedData = data.slice()
				}
				DEBUG && console.log("pages=%o",pages)
				DEBUG && console.log("pagedData=%o",pagedData.length)
				
				this.setState({pagedData, pages, total:data.length, loading : false})
				
			} else {
				this.setState({pagedData:[], pages:0, total:0, loading : false})
			}
			
		}

	}


	render() {
		const { children, pageSize = _default_page_size, visible = true } = this.props
		const { loading, pagedData, pages, total} = this.state
		
		if (!visible)
			return null

		return (
			<div>
		 		{children && children(pagedData, loading, pages, total, pageSize > 0 ? pageSize: total, this.onUpdate)}
	       </div>
		);
	}



}

export default DataConnector;
