import React from "react";
import { FormField, TextArea } from "semantic-ui-react";

export const PAR_INIT = {
    content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dis parturient montes nascetur ridiculus mus mauris vitae. Lectus mauris ultrices eros in cursus turpis massa tincidunt dui. Mi proin sed libero enim sed. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Nullam ac tortor vitae purus faucibus ornare suspendisse. Aliquet lectus proin nibh nisl condimentum id. Tellus cras adipiscing enim eu turpis. Euismod quis viverra nibh cras. Risus viverra adipiscing at in. Elit sed vulputate mi sit. Pulvinar proin gravida hendrerit lectus a. Vitae et leo duis ut diam quam nulla porttitor. Augue eget arcu dictum varius duis. Sit amet massa vitae tortor condimentum lacinia quis vel. Odio tempor orci dapibus ultrices in iaculis nunc sed augue. Sit amet dictum sit amet justo donec enim diam vulputate.",
};

const Paragraph = ({ content, editMode }) =>
    editMode ? (
        <>
            <FormField>
                <label>Content</label>
                <TextArea
                    type="textarea"
                    name="content"
                    placeholder="Enter text"
                    defaultValue={content}
                />
            </FormField>
        </>
    ) : (
        <p>{content}</p>
    );

export default Paragraph;
