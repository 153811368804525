import db_lib from 'xAppLib/libs/db_lib';
import firebase_database from 'xAppLib/providers/firebase_database.js';


const _FRDB_LOC = 'phonecalls';
const _MODEL_NAME = 'phonecall_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class phonecall_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get MODEL_NAME() { return _MODEL_NAME; }

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

	// static watch_record (l, ca, p) {
	// 	super.watch_record (l, r=>{
	// 		if (r)
	// 			r.n = r.n?.toLowerCase().replace(/(?:^|\s|["([{])+\S/g, match => match.toUpperCase())
	// 		ca(r)
	// 	}, p) 
	// }

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

}

	// 		--------------------------------		--------------------------------		---------
