import React, { Component } from 'react';
import {
		Table,
		Popup,
		Button,
		Menu,
		Dropdown,
		Input,
		Loader,
		Icon,
		Segment,
		Dimmer,
	} from 'semantic-ui-react'

import {DataShow} from '.';
import getNestedObject from '../helpers/getNestedObject'

class ListBasic extends Component {

	render() {
		const { data, loading = true , children, inverted=false,color=null,fixed=false} = this.props
		return data && this.renderData() || null
	}

	renderData() {
		const { children, data } = this.props
		const columns = !Array.isArray(children) ? [children] : children

		return data && data.map((r,i)=>{
			return columns.map((render,renderIndex)=><React.Fragment key={renderIndex+"-view-rec-"+i}>
				 { render(r,i)}
			</React.Fragment>)
		})

	}
 
}

class ListBasicCell extends Component {
	render() {
		const { row , index, visible = true, singleLine = false, label, path, type, f, children, onClick, as='div', hideIfEmpty = false, val, ...props } = this.props
		
		const Element = as
		
		if (!visible)
			return null
			
		const header = !row
		
		let value = val || '-'
		if (f) {
			value = DataShow.show_data_field(row, f, null, null, null, null, this.props)
		}
		else if (type) {
			value = DataShow.show_data_field(row, this.props) 
		}
		else if (row && path) {
			value = getNestedObject( row, path, '.' )
		}
		
		if (hideIfEmpty && (value=='' || !value))
			return null

		return (
			<Element {...props} onClick={onClick}>{children ? children(value,row,index) : value }</Element>
		);
	}

}

ListBasic.Cell = ListBasicCell


export default ListBasic;
