//	------------------------	------------------------	------------------------
//	Description: eRx functions - mostly communicating with IS BE
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

// import db_model from '../db_model'
import API_service from 'xAppLib/providers/API_service'

import { PRESCRIBED_ITEM_PROPS, PATIENT_PROPS, CLINICIAN_PROPS } from './resources/erx_props'

const stopwatch = require('../../helpers/stopwatch')
const moment = require('moment-timezone')
const erx_script_model = require('../erx/erx_script_model')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const MODEL_NAME = 'erx_model'
const TIMEOUT = 30000

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default class erx_model  {

  static get MODEL_NAME() { return MODEL_NAME }
  static get PRESCRIBED_ITEM_PROPS() { return PRESCRIBED_ITEM_PROPS }
  static get PATIENT_PROPS() { return PATIENT_PROPS }
  static get CLINICIAN_PROPS() { return CLINICIAN_PROPS }

  // ----	--------------------------------------------	--------------------------------------------	

  static erx_to_is_med_name(med) {

    return `${med.ItemTradeName} – ${med.ItemForm} ${med.ItemStrength}`

  }

  // ----	--------------------------------------------	--------------------------------------------

  async get_items(params) {

    return await API_service.load_data('escripts/erx_item', params)

  }

  // ----	--------------------------------------------	--------------------------------------------

  async get_reasons(params) {

    return await API_service.load_data('escripts/presc_reason', params)

  }

  // ----	--------------------------------------------	--------------------------------------------

  async get_fields(params) {

    return await API_service.load_data('escripts/erx_field', params)

  }

  // ----	--------------------------------------------	--------------------------------------------

  static async check_connection() {

    // TC_PRES_US_003
    const CONFORMANCE_TEST = false
    if (CONFORMANCE_TEST) {
      if (Math.random()>0.5) return {res: 'err', err: 'Conformance test'}
      else return {res: 'ok', err: 'Conformance test'}
      
    }

    const task = _ => new Promise(r => r(API_service.load_data('escripts/erx_check_connection')))

    const timeout = (cb, interval) => _ => new Promise(resolve => setTimeout(() => cb(resolve), interval))

    const onTimeout = timeout(r => r({ res: 'err', err: `Timeout ${TIMEOUT} ms` }), TIMEOUT)

    const run_res = await Promise.race([task, onTimeout].map(f => f())).then(res => { return res })

    return run_res

  }

  // ----	--------------------------------------------	--------------------------------------------

  async check_script_server(params) {

    return await API_service.load_data('escripts/erx_check_script', params)

  }

  // ----	--------------------------------------------	--------------------------------------------

  async check_script(params, fix_data = true) { //local. The server one will be similar. In future, server side will probably look at the PBS list, clinet, docs etc.

    const s_w = new stopwatch()

    let result = { res: 'ok' }

    try {

      const patient_dets = params.patient
      const clinician_dets = params.clinician
      const prescribeditem_dets = params.item

      let script = new erx_script_model()

      let item = script.get_item()
      let content = item.get_content()
      let patient = content.get_patient()
      let clinician = content.get_clinician()
      let prescribeditem = content.get_prescribeditem()

      // Slightly modded. On FE undefined seems to be handled a little different vs BE node.js (no error)
      if (typeof patient_dets == 'object') Object.keys(patient_dets).forEach(k => { if (typeof patient_dets[k] != 'undefined') patient[k] = patient_dets[k] })
      if (typeof clinician_dets == 'object') Object.keys(clinician_dets).forEach(k => { if (typeof clinician_dets[k] != 'undefined') clinician[k] = clinician_dets[k] })
      if (typeof prescribeditem_dets == 'object') Object.keys(prescribeditem_dets).forEach(k => {if ( typeof prescribeditem_dets[k] != 'undefined') prescribeditem[k] = prescribeditem_dets[k] })

      // Come back to fixing this later
      if (fix_data) result.script_fix_res = script.fix_data(false)
      else result.script_fix_res = {res: 'ok', msg: 'Fix data skipped'}

      script.SCID = 'THISWILLBESETLATER' // Just for validation, this will be assigned later to a newly generated scid
      result.script_valid_res = script.check_valid({escript: params.escript})

      if (result.script_valid_res.res != 'ok') result = {...result, res: 'err', err: `Script invalid: ${result.script_valid_res.err}`, err_detail: result.script_valid_res.err}

    } catch (e) { result = { ...result, res: 'err', err: `Unknown error running check_script: ${e}`, e } }

    result = { ...result, ms: s_w.duration() }
    return result

  }

}
