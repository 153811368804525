/* global SafariViewController */
import { AppRate } from '@ionic-native/app-rate';
import { Deeplinks } from '@ionic-native/deeplinks'
import waitFor from 'xAppLib/libs/waitFor'
import logger from 'xAppLib/libs/logger'
import { app_runtime } from './runts';
import { update_runtime } from './prep_env';
import firebase_auth from './xAppLib/providers/firebase_auth_ionic';

export default function prep_ionic() {
	if (!app.settings.is_ionic) 
		return 
	
	
	const cleanup = []
	
	waitFor(window,'StatusBar').then(StatusBar=>StatusBar.show())
	
	waitFor(window,'IonicDeeplink').then(IonicDeeplink=>{
		const deeplinkSubscription = Deeplinks.route({}).subscribe( (result) => { /* match */ 
																		// do nothing, won't happen, got no routes, why bother, the end
																	},
																	async (result) => { /* no match */ 
																		if (result.$link) {
																			const location = {
																				search: result.$link.queryString,
																				pathname: result.$link.path, 
																				href: result.$link.url, 
																				host: result.$link.host, 
																			}
																			app.runtime = app_runtime(window.__.config, location) 
																			await update_runtime()
																			
																			const { path, host } = result.$link
																			console.log({ path, host });
																			if (path) {
																				app.history.push(path)
																			}
																			result.$link.queryString.includes('oidc=onepass') && firebase_auth.onepass_login(true)

																		}
																		logger.log_api('Deeplink', "nomatch", "result", {result})

																})
																
		cleanup.push(deeplinkSubscription)
	})
	
	
	waitFor(window,'AppRate').then(AppRate=>{
		try {
			AppRate.setPreferences({
				displayAppName: 'InstantScripts',
				usesUntilPrompt: 3,
				promptAgainForEachNewVersion: false,
				reviewType: {
					ios: 'InAppReview',
					android: 'InAppReview'
				},
				showPromptForInAppReview:false,
				openStoreInApp: true,
				storeAppURL: {
					ios: '1456598384',
					android: 'market://details?id=au.com.instant',
				},
				customLocale: {
					title: "Would you like to help others by rating our app?",
					message: "It won’t take more than a minute and your opinion will help thousands of Australians make the right decisions!",
					cancelButtonLabel: "No, Thanks",
					laterButtonLabel: "Remind Me Later",
					rateButtonLabel: "Rate It Now",
					yesButtonLabel: "Yes",
					noButtonLabel: "Not really",
					appRatePromptTitle: 'Would you like to help others by reviewing our app?',
					feedbackPromptTitle: 'Mind giving us some feedback?',
				},
				callbacks: {
					handleNegativeFeedback: function(){
						window.open('mailto:contact+rating@instantscripts.com.au', '_system');
					},
					onRateDialogShow: function(callback){
						logger.usg_log('AppRate', "action", "RateDialogShow")
					},
					onButtonClicked: function(buttonIndex){
						logger.usg_log('AppRate', "action", "ButtonClicked", {val:buttonIndex})
					}
				},
				openUrl: function(url) {
					console.log("openUrl",url);
					let safariAvailable = false;
					if (window.SafariViewController) {
						SafariViewController.isAvailable(function(available) {
							safariAvailable = available;
						});
					}
					
					logger.usg_log('AppRate', "action", "openUrl", {val:url, safariAvailable})

			
					if (!safariAvailable) {
						window.open(url, '_blank', 'location=yes');
					} else {
						SafariViewController.show(
							{
								url: url,
								// barColor: "#0000ff", // on iOS 10+ you can change the background color as well
								// controlTintColor: "#00ffff", // on iOS 10+ you can override the default tintColor
								// tintColor: "#00ffff", // should be set to same value as controlTintColor and will be a fallback on older ios
							},
				
							function(result) {
								logger.usg_log('AppRate', "action", "handler", {val:result})

							},
				
							function(msg) {
								logger.usg_log('AppRate', "error", msg)
							}
						);
					}
				}
			});
			
			AppRate.promptForRating(false)
		} catch (e) {
			logger.report_error('ERROR Initialising AppRate', e.toString(), {...e});
		} 
	
	})
	
	return _=> {
		cleanup.map(fn=>fn.call(null))
	}
	
		
}
