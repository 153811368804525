
import React, { Component } from 'react';

import IlegTermsAndConditions from './IlegTermsAndConditions';
import CmgTermsAndConditions from './CmgTermsAndConditions';
import CblsTermsAndConditions from './CblsTermsAndConditions';
import ISPlatformTermsAndConditions from './ISPlatformTermsAndConditions';
import ISCustomerTermsAndConditions from './ISCustomerTermsAndConditions';
import CosmTermsAndConditions from './CosmTermsAndConditions';

const TermsAndConditions = ({type}) => {
  let t = <ISPlatformTermsAndConditions />;

  switch (type) {
    case 'cmg':
      t = <CmgTermsAndConditions />
      break;
    case 'cbls':
      t = <CblsTermsAndConditions />
      break;
    case 'ileg':
      t = <IlegTermsAndConditions />
      break;
    case 'customer':
      t = <ISCustomerTermsAndConditions />
      break;
    case 'platform':
      t = <ISPlatformTermsAndConditions />
      break;
    case 'cosm':
      t = <CosmTermsAndConditions />
      break;
  }
  return t;
};

export default TermsAndConditions
