import React, {Component} from 'react'
import { 
			Modal, 
			Icon, 
			Header,
			Button
		} from 'semantic-ui-react'
import { Player, ControlBar, BigPlayButton } from 'video-react';

export default class IdleScreen extends Component {
	
	constructor(props) {
		super(props);
		this.state = { modalOpen: false,muted:true,player:{} };
	}

	handleOpen = () => {
		this.setState({ modalOpen: true },_=>{
			document.body.classList.add('idle')
			this.unsubscribe && this.unsubscribe()
			this.unsubscribe = this.refs.player.subscribeToStateChange(this.playerStateChange)
		})
	}

	handleClose = () => {
		document.body.classList.remove('idle')
		this.setState({ modalOpen: false })
	}

	// 		--------------------------------		--------------------------------		---------

	componentDidMount() {
		app.on(app.events.IDLE_START, this.handleOpen)
		
	}

	// 		--------------------------------		--------------------------------		---------
	playerStateChange = (state) => {
		//console.log("state=%o",state)
		if (state.ended) {
			this.refs.player.seek(0)
			this.refs.player.play()
		}
		this.setState({player:state})
	}
	
	toggleMuted = () => {
		this.setState({muted:!this.state.muted})
		
	}
	  
	render() {
		return (
			<Modal
					open={this.state.modalOpen}
					onClose={this.handleClose}
					basic
					>
					<div 
						onClick={this.handleClose}
						>
						
						
							<Modal.Content>
								<img src="images/logos/instant-scripts-logo-white2.svg"
								style={{
										width:'200px',
										display:'block',
										margin:'auto',
										marginBottom:'20px',
									}}  />
								<h3 style={{fontSize:38,lineHeight:'1.5em'}}>
									DO YOU NEED A PRESCRIPTION RIGHT NOW? </h3>
								
								
								<div className='player-wrapper'
										onClick={e=>{
											e.stopPropagation();
									  }}>
									<Player
									 	ref="player"
										autoPlay
										muted={this.state.muted}
									 	poster="https://storage.googleapis.com/instant-med-public/video-placeholder.jpg">
										<source src='https://storage.googleapis.com/instant-med-public/InstantScripts%20Pharm%20Consumer%20Video%20V02%20-%20540p30%20.mp4'/>
										<ControlBar disableCompletely />
										<BigPlayButton className="hidden" position="center" />
								 	</Player>
								 	{this.state.player.paused && 
										<Button color="green" 
											size="huge" className="play-button" 
											onClick={e=>{
												e.stopPropagation();
												this.refs.player.play()
								  			}}>Watch Introduction</Button>
									}
									<Button basic icon={this.state.muted ? 'volume down' : 'volume up'} className="sound-control" size="huge" onClick={this.toggleMuted} />
						  		</div>
								<Button className='start-button' fluid  basic onClick={this.handleClose}  size='massive'>
										PRESS HERE TO START YOUR CONSULTATION
									</Button>
								
								<h3 style={{fontSize:38,lineHeight:'1.5em'}}>
									IT WILL ONLY TAKE 60-90 SECONDS. 
								</h3>
							</Modal.Content>
					</div>
					
			</Modal>
			
		)
	}

}
