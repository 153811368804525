import React, { Component } from 'react'
import { 
		Segment, 
		Menu, 
		Image,
		Header,
		Form,
		Button,
		Input,
		Divider,
		Icon,
		Message,
		Container,
	} from 'semantic-ui-react'
import { Helmet } from "react-helmet"
	
import user_model from 'models/user_model'
import PasswordWithValidator from '../UniForm/PasswordWithValidator'

class Password extends Component {
	constructor (props) {
		super(props)
		user_model.limit_access('user');
	}
	
	state = {
		error : false,
		success : false,
		loading : false,
		password : '',
		current_password:''
	}
	
	async onSubmit () {
		try {
			this.setState({error:false,success:false,loading:true})
			const { password, current_password } = this.state

			if(password.length < 8 || !/[\w]/g.test(password) || !/[\d]/g.test(password)){
				this.setState({error:"The new password is not strong enough!.", loading: false})
				return
			}
			
			await user_model.frb_reauthenticate(current_password)
			
			await user_model.frb_password(password)
			await user_model.save_prof({ password: '.' });
			this.setState({success:true,password:'',current_password:'',loading:false})
			
		} catch (e) {
			console.log("password change error %o",e)
			this.setState({loading:false})
			if (e.code == 'auth/wrong-password' || ["Code=17009", 'Invalid verification code'].includes(e.message)) {
				this.setState({error:"The current password provided is incorrect."})
			} else if (['Code=17026', 'The given password is invalid. [ Password should be at least 6 characters ]'].includes(e.message)){
				this.setState({error:"The new password is too short."})
			}else {
				this.setState({error:e.message})
			}
		} 
	}
	
	onChange = (e, { name, value }) => this.setState({ [name]: value, error:false,success:false })
	
	render() {
		return (
			<Container>
				<Helmet>
					<title>Reset Password</title>
				</Helmet>
				<Segment>
					<Header>Change Your Password</Header>
					<Form 
						loading={!!this.state.loading} 
						error={!!this.state.error} 
						success={!!this.state.success} 
						onSubmit={_=>{this.onSubmit()}}>
						<Message
					      error
					      header='Error'
					      content={this.state.error}
					    />
						<Message
					      success='true'
					      header='Success'
					      content={"Your password has been updated"}
					    />
						<Form.Input 
							type="password" name='current_password' 
							placeholder="Enter your current password" 
							label="Current Password" 
							value={this.state.current_password} 
							onChange={this.onChange}
						/>
						<PasswordWithValidator
							style={{marginBottom: 20}}
							type="password" name='password' 
							placeholder="Enter your new password" 
							label="New Password" 
							value={this.state.password} 
							onChange={s => this.onChange(null, { name: 'password', value: s.target.value })}
						/>
						<Button color="green">Update Password</Button>
					</Form>
				</Segment>
			</Container>
		);
	}

}

export default Password;
