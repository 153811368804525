
const DEBUG = false;

export default class localstorage_database {

// ----	--------------------------------------------	--------------------------------------------	

	static init(sn, ca) {

		this.loc_store_name = sn || 'ws_data_store'
		this.call_after_update = ca || false

		let d = null
		try {
			d = window?.localStorage?.getItem(this.loc_store_name)
		} catch (e) { console.warn('CATCH: no localStorage',e); }

		this.data = JSON.parse(d) || {};
	}

//	------------------------	------------------------	------------------------

	static save_data () {
		DEBUG && console.log('localstorage_database :: save_data', this.loc_store_name, 
				this.data, 
				{...this.data}, 
				JSON.stringify({...this.data}), 
			);
		try {
			window?.localStorage?.setItem(this.loc_store_name, JSON.stringify({...this.data}));
		} catch (e) { console.warn('CATCH: no localStorage - save',e); }
		

		this.call_after_update && this.call_after_update(this.data)
	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static get_records (ca) {

		DEBUG && console.log('localstorage_database :: get_records', this.data);

		return this.data || null
	}

//	------------------------	------------------------	------------------------

	static set_records (d) {

		DEBUG && console.log('localstorage_database :: set_records', d);

		this.data = d

		this.save_data()

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------

	static get_record (i) {

		return this.data[i] || null;

	}

//	------------------------	------------------------	------------------------

	static add_record (d) {

		this.data.push(d)

		this.save_data()

	}

//	------------------------	------------------------	------------------------

	static update_record (i, d) {

		this.data[i] = d

		this.save_data()

	}

//	------------------------	------------------------	------------------------

	static delete_record (i) {

		// this.data.splice(i, 1);
		delete this.data[i];

		this.save_data()

	}

//	------------------------	------------------------	------------------------
//	------------------------	------------------------	------------------------


}
