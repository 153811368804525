/* eslint-env node */
/* eslint-disable no-undef */
//	------------------------	------------------------	------------------------
//	Description: base component within an erx script
//  eRx script -> item[s] -> content -> clinician, PATIENT, prescribed item
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const get_set =     require('../../helpers/erx/get_set_function')

const random_f =    require('../../helpers/erx/random_function')

const erx_enums =   require('../erx/resources/erx_enums')
const erx_formats = require('../erx/resources/erx_formats')
const erx_props =   require('../erx/resources/erx_props')

const moment =      require('moment-timezone')

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const DEFAULTS = {

}

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

module.exports = class erx_patient_model {

    constructor(patient_data, run_validation = true) {

        this._patient_data = {}
        this.setup_props()

        if (patient_data){
            if (run_validation) this.patient_data = patient_data
            else this._patient_data = patient_data
        } 
        else this.set_default()

    }

    //	------------------------	------------------------	------------------------
    //  Get and Set
    //	------------------------	------------------------	------------------------

    get patient_data() { return this._patient_data }
    set patient_data(val) {
        this._patient_data = val
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------
    //	Functions
    //	------------------------	------------------------	------------------------

    clear() {
        //this.inner_data = {} // We will lose reference to the inner data. Don't do this
        if (this.inner_data) Object.keys(this.inner_data).forEach((key) => { delete this.inner_data[key] })
        else this.inner_data = {}
    }

    //	------------------------	------------------------	------------------------

    set_blank() { // You probably don't want to call this
        this.clear()
        erx_props.PATIENT_PROPS.forEach(item => { 
            //if (item.minOccurs !== 0){ // If min occurance =0, don't initialise, so it won't appear in xml
                let value = null
                if (typeof item.blank !== 'undefined') value = item.blank // Need type of since '' evaluates to false can't use simple && statments,
                else if (item.format && erx_formats.ERX_FORMAT[item.format] && typeof erx_formats.ERX_FORMAT[item.format].blank !== 'undefined') value = erx_formats.ERX_FORMAT[item.format].blank
                this[item.name] = JSON.parse(JSON.stringify(value))
            //}
        })
    }

    //	------------------------	------------------------	------------------------

    set_default() { 
        this.set_blank()
        Object.keys(DEFAULTS).forEach(key => this[key] = JSON.parse(JSON.stringify(DEFAULTS[key])))
        //this.fix_data()
    }

    //	------------------------	------------------------	------------------------

    toJSON() { // Reduce didn't work
        let ret = {}
        erx_props.PATIENT_PROPS.forEach(i => ret[i.name] = this[i.name])
        return ret
    }

    //	------------------------	------------------------	------------------------

    fix_data(remove_errors = true) {

        // Fix self
        let result = {res: 'ok', fixes: [], err: []}

        for (let i = 0; i < erx_props.PATIENT_PROPS.length; i++){
            const c_prop = erx_props.PATIENT_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format - see if we can convert any strings to expected format
            if ((typeof c_item !== 'undefined') && c_item !== '' && c_item !== null && c_prop.format){ //Note - content props for patient, clinician and item wont have a format and will be ignored
                
                const formatter = erx_formats.ERX_FORMAT[c_prop.format]
                if (!formatter || !formatter.validate) result.err.push('Cannot find validator for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                else {

                    const valid_res = formatter.validate(c_item)
                    if (valid_res.res != 'ok'){

                        if (remove_errors) this[c_prop.name] = null // The value may be fixed below

                        if (!formatter.fromValue) result.err.push('Cannot find formatter (fromValue function) for type: ' + c_prop.format + ' with formatter ' + formatter + ' for prop ' + c_prop.name)
                        else {

                            const from_res = formatter.fromValue(c_item)

                            if (from_res.res != 'ok') result.err.push('Could not parse item to type ' + c_prop.format + ' for prop ' + c_prop.name + '. Error: ' + from_res.err)
                            else {

                                result.fixes.push( { name: c_prop.name, from: c_item, to: from_res.val } )
                                this[c_prop.name] = from_res.val

                            }
                        }
                    } 
                }              
            }
        }
        
        if (result.err.length > 0) result.res = 'err'
        else delete result.err
        return result

    }

    //	------------------------	------------------------	------------------------

    check_valid(params) {
        
        let result = {res: 'ok'}

        for (let i = 0; i < erx_props.PATIENT_PROPS.length; i++){
            const c_prop = erx_props.PATIENT_PROPS[i]
            const c_item = this[c_prop.name]
            
            // Check format
            if (typeof c_item != 'undefined' && c_item != '' && c_item != null && c_prop.format){
                const check_format_res = erx_formats.check_erx_format(c_item, c_prop.format)
                if (check_format_res.res != 'ok') result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is wrong format. ' + check_format_res.err)] }
                // return {res: 'err', err: c_prop.name + ' is wrong format. ' + check_format_res.err}
            }

            // Check the length
            if (typeof c_item != 'undefined' && c_item != null && c_prop.length){
                if (c_item.toString().length > c_prop.length)
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString())] }
                    //return {res: 'err', err: c_prop.name + ' is greater than allowed length ' + c_prop.length + '. Length: ' + c_item.toString().length + ', value: ' + c_item.toString()}
            }

            // Check max occurance
            if (!c_prop.minOccurs === 0){
                if (typeof c_item === 'undefined')
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is must have at least one occurance but has none. Value: ' + c_item)] }
                ///return {res: 'err', err: c_prop.name + ' is must have at least one occurance but has none. Value: ' + c_item}
            }

            //Check nillable
            if (!c_prop.nillable === true){
                if (c_item === null && !c_prop.minOccurs === 0)
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is not nillable but is null. Value: ' + c_item)] }
                //return {res: 'err', err: c_prop.name + ' is not nillable but is null. Value: ' + c_item}
            }

            // Check the mandatory
            if (c_prop.mandatory && c_prop.mandatory({...params, patient: this})){
                if (typeof c_item == 'undefined' || c_item == null)
                    result = {...result, res: 'err', err: [...(result.err || []), (c_prop.label + ' is mandatory but absent. Value: ' + c_item)] }
                    //return {res: 'err', err: c_prop.name + ' is mandatory but absent. Value: ' + c_item}
            }
        }

        return result
    }

    //	------------------------	------------------------	------------------------

    compare(target_patient, subset = false) { // Compares this script's items to the target scripts items. Subset means only compare the props on the source

        let diff = []

        //log("Checking patient props match")
        // Check props

        erx_props.PATIENT_PROPS.forEach(item => {
            if (item.use_for_compare && (!subset || (typeof this[item.name] != undefined && this[item.name] != null))){
                let source = (typeof this[item.name] != undefined) && (this[item.name] != '') && this[item.name] || null
                let target = target_patient && (typeof target_patient[item.name] != undefined) && (target_patient[item.name]!= '') && target_patient[item.name] || null
                if (!(source == null && target == 0) && !(target == null && source == 0) && source != target) //erx seems to replace 0 with null for some :/
                    diff.push({name: 'Patient property ' + item.name + ', source: ' + source + ', target: ' + target, source, target}) 
            }
        })

        // Patient
        // Any future wrapped sub fields will need to go here - equivilant to get_item() on script or get_patient() on content

        // Return
        if (diff.length == 0) return {res: 'ok'}
        else return {res: 'err', err: 'Patients do not match by ' + diff.length + ' field(s). Difference(s):' + diff.reduce((a, c, i) =>  a += ('\n' + (i + 1) + ': ' + c.name), ''), diff}

    }

    //	------------------------	------------------------	------------------------
    //	Get\Set placeholders - these are overridden with setup_props() to work correcly
    //	------------------------	------------------------	------------------------

    setup_props() { erx_props.PATIENT_PROPS.forEach(p => this.define_prop(p)) }
    define_prop(prop){ Object.defineProperty(this, prop.name, { get: get_set.make_get_function(this, prop), set: get_set.make_set_function(this, prop)}) }
    get_object_value(obj, path) { return get_set.get_object_value(obj, path) } // Needed for pointing
    set_object_value(obj, path, val) { return get_set.set_object_value(obj, path, val) } // Needed for pointing

    //	------------------------	------------------------	------------------------

    get inner_data() { return this.patient_data }
    set inner_data(val) { this.patient_data = val }

    //	------------------------	------------------------	------------------------

    get PatientUniqueID() { throw { override_msg, prop: 'get PatientUniqueID' }}
    set PatientUniqueID(val) { throw { override_msg, prop: 'set PatientUniqueID' }}

    get PatientNumber() { throw { override_msg, prop: 'get PatientNumber' }}
    set PatientNumber(val) { throw { override_msg, prop: 'set PatientNumber' }}

    get PatientFamilyName() { throw { override_msg, prop: 'get PatientFamilyName' }}
    set PatientFamilyName(val) { throw { override_msg, prop: 'set PatientFamilyName' }}

    get PatientFirstName() { throw { override_msg, prop: 'get PatientFirstName' }}
    set PatientFirstName(val) { throw { override_msg, prop: 'set PatientFirstName' }}

    get PatientMedicareFamilyName() { throw { override_msg, prop: 'get PatientMedicareFamilyName' }}
    set PatientMedicareFamilyName(val) { throw { override_msg, prop: 'set PatientMedicareFamilyName' }}

    get PatientMedicareFirstName() { throw { override_msg, prop: 'get PatientMedicareFirstName' }}
    set PatientMedicareFirstName(val) { throw { override_msg, prop: 'set PatientMedicareFirstName' }}

    get PatientTitle() { throw { override_msg, prop: 'get PatientTitle' }}
    set PatientTitle(val) { throw { override_msg, prop: 'set PatientTitle' }}

    get PatientAddress1() { throw { override_msg, prop: 'get PatientAddress1' }}
    set PatientAddress1(val) { throw { override_msg, prop: 'set PatientAddress1' }}

    get PatientAddress2() { throw { override_msg, prop: 'get PatientAddress2' }}
    set PatientAddress2(val) { throw { override_msg, prop: 'set PatientAddress2' }}

    get PatientSuburb() { throw { override_msg, prop: 'get PatientSuburb' }}
    set PatientSuburb(val) { throw { override_msg, prop: 'set PatientSuburb' }}

    get PatientPostcode() { throw { override_msg, prop: 'get PatientPostcode' }}
    set PatientPostcode(val) { throw { override_msg, prop: 'set PatientPostcode' }}

    get PatientState() { throw { override_msg, prop: 'get PatientState' }}
    set PatientState(val) { throw { override_msg, prop: 'set PatientState' }}

    get PatientSex() { throw { override_msg, prop: 'get PatientSex' }}
    set PatientSex(val) { throw { override_msg, prop: 'set PatientSex' }}

    get PatientPhoneNumber() { throw { override_msg, prop: 'get PatientPhoneNumber' }}
    set PatientPhoneNumber(val) { throw { override_msg, prop: 'set PatientPhoneNumber' }}

    get PatientMobileNumber() { throw { override_msg, prop: 'get PatientMobileNumber' }}
    set PatientMobileNumber(val) { throw { override_msg, prop: 'set PatientMobileNumber' }}

    get PatientEmail() { throw { override_msg, prop: 'get PatientEmail' }}
    set PatientEmail(val) { throw { override_msg, prop: 'set PatientEmail' }}

    get PatientMedicareNumber() { throw { override_msg, prop: 'get PatientMedicareNumber' }}
    set PatientMedicareNumber(val) { throw { override_msg, prop: 'set PatientMedicareNumber' }}

    get PatientMedicareSubNumerate() { throw { override_msg, prop: 'get PatientMedicareSubNumerate' }}
    set PatientMedicareSubNumerate(val) { throw { override_msg, prop: 'set PatientMedicareSubNumerate' }}

    get PatientMedicareValidTo() { throw { override_msg, prop: 'get PatientMedicareValidTo' }}
    set PatientMedicareValidTo(val) { throw { override_msg, prop: 'set PatientMedicareValidTo' }}

    get Concession_PensionNumber() { throw { override_msg, prop: 'get Concession_PensionNumber' }}
    set Concession_PensionNumber(val) { throw { override_msg, prop: 'set Concession_PensionNumber' }}

    get EntitlementNumber() { throw { override_msg, prop: 'get EntitlementNumber' }}
    set EntitlementNumber(val) { throw { override_msg, prop: 'set EntitlementNumber' }}

    get DVANumber() { throw { override_msg, prop: 'get DVANumber' }}
    set DVANumber(val) { throw { override_msg, prop: 'set DVANumber' }}

    get DVACardColour() { throw { override_msg, prop: 'get DVACardColour' }}
    set DVACardColour(val) { throw { override_msg, prop: 'set DVACardColour' }}

    get PatientBirthdate() { throw { override_msg, prop: 'get PatientBirthdate' }}
    set PatientBirthdate(val) { throw { override_msg, prop: 'set PatientBirthdate' }}

    get PatientCTGFlag() { throw { override_msg, prop: 'get PatientCTGFlag' }}
    set PatientCTGFlag(val) { throw { override_msg, prop: 'set PatientCTGFlag' }}

    get PatientIHI() { throw { override_msg, prop: 'get PatientIHI' }}
    set PatientIHI(val) { throw { override_msg, prop: 'set PatientIHI' }}

    get Patient_Reserved_01() { throw { override_msg, prop: 'get Patient_Reserved_01' }}
    set Patient_Reserved_01(val) { throw { override_msg, prop: 'set Patient_Reserved_01' }}

    get Patient_Reserved_02() { throw { override_msg, prop: 'get Patient_Reserved_02' }}
    set Patient_Reserved_02(val) { throw { override_msg, prop: 'set Patient_Reserved_02' }}
    
    get Patient_Reserved_03() { throw { override_msg, prop: 'get Patient_Reserved_03' }}
    set Patient_Reserved_03(val) { throw { override_msg, prop: 'set Patient_Reserved_03' }}

    get Patient_Reserved_04() { throw { override_msg, prop: 'get Patient_Reserved_04' }}
    set Patient_Reserved_04(val) { throw { override_msg, prop: 'set Patient_Reserved_04' }}

    get Patient_Reserved_05() { throw { override_msg, prop: 'get Patient_Reserved_05' }}
    set Patient_Reserved_05(val) { throw { override_msg, prop: 'set Patient_Reserved_05' }}

    // get PatientHospitalCategory() { throw { override_msg, prop: 'get PatientHospitalCategory' }} // Problems with schema validation - ignore for now
    // set PatientHospitalCategory(val) { throw { override_msg, prop: 'set PatientHospitalCategory' }}

}