import React from 'react';
import { 	Container,
			Input,
			Button,
			Table,
			Icon,
			Message,
			Segment,
			Header,
			Loader,
			Popup,
			Menu,
			Dimmer
			
		} from 'semantic-ui-react'

const StatsPanel = ({header = "Stats", summaries = [], children, head_as = 'h3', sum_as = 'h3' }) => (
		<React.Fragment>
				
				<Segment.Group horizontal>
					<Segment  inverted color='green'>
						<Header as={head_as}>{header}</Header>
					</Segment>
				</Segment.Group>

				{summaries && summaries.length > 0 && <Segment.Group horizontal>
					
					{summaries.map(({val, title, display = true, onClick, selected},i)=>{
						return display && 
							(selected && 
								<Segment key={i} onClick={onClick} color={"green"}>
									{ val !==null && val !==undefined && <Header as={sum_as} color="green">
										{val}
										<Header.Subheader>{title}</Header.Subheader>
									</Header>}
								</Segment> ||
								<Segment key={i} onClick={onClick}>
									{ val !==null && val !==undefined && <Header as={sum_as} color="green">
										{val}
										<Header.Subheader>{title}</Header.Subheader>
									</Header>}
								</Segment>) || null
					})}

				</Segment.Group>}


					{children}
					
		</React.Fragment>
);

export default StatsPanel;
