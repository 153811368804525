//	------------------------	------------------------	------------------------
//	Description: Notify patho
//
// 	Expected props: 
// 		scr_obj - script object
//
//  Notes: Could do with a varible name clean-up for clarity
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { Button, Modal, Icon, Form, Table } from 'semantic-ui-react'

import API_service from 'xAppLib/providers/API_service'
import debounce from 'xAppLib/libs/debounce'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const debounce_time = 200

const states = [
    { name: 'state_nsw',    label: 'New South Wales',               regx: /.*(nsw|wales).*/gi},
    { name: 'state_vic',    label: 'Victoria',                      regx: /.*(vic).*/gi},
    { name: 'state_qld',    label: 'Queensland',                    regx: /.*(qld|queen).*/gi},
    { name: 'state_wa',     label: 'Western Australia',             regx: /(^\s*wa$|^wa\s*$|.*west.*)/gi},
    { name: 'state_sa',     label: 'South Australia',               regx: /(^\s*sa$|^sa\s*$|.*south\s*aus.*)/gi},
    { name: 'state_act',    label: 'Australian Capital Territory',  regx: /.*(act|capital).*/gi},
    { name: 'state_tas',    label: 'Tasmania',                      regx: /.*tas.*/gi},
    { name: 'state_nt',     label: 'Northern Territory',            regx: /.*(nt|north).*/gi},
    { name: 'state_other',  label: 'Other',                         regx: /NOTHINGSHOULDGOINHERE/gi,    table_hide: true},
]

const states_options = states.map((e, i) => { return { key: `state_opt_${i}`, text: `${e.label}`, value: `${e.name}` } })
const states_table_filter = states.filter(o => {return !o.table_hide})

//	------------------------	------------------------	------------------------

const conditions = [
    { name: 'condition_chlamydia',  label: 'Chlamydia',     id: '84ced053-be0a-4142-8051-67b49b09e8bd'},
    { name: 'condition_gonorrhoea', label: 'Gonorrhoea',    id: '7a26e85d-ff25-4b4a-b9ae-ab36945f3e58'},
    { name: 'condition_syphilis',   label: 'Syphilis',      id: 'a4b1e0dd-16ed-4a38-be7a-2461fcda472e'},
    { name: 'condition_hep_b',      label: 'Hepatitis B',   id: '685cd1a9-0b89-473c-92ee-348fdc46f2f3'},
    { name: 'condition_hep_c',      label: 'Hepatitis C',   id: '32a0402a-5190-49c6-93d5-d6eafa764f05'},
    { name: 'condition_hiv',        label: 'HIV',           id: 'd7980932-75c8-4aa2-9620-e79cc9f536d9'},
    { name: 'condition_other',      label: 'Other (write in note)',     table_hide: true},
]

const conditions_options = conditions.map((e, i) => { return { key: `cond_opt_${i}`, text: `${e.label}`, value: `${e.name}` } })
const conditions_table_filter = conditions.filter(o => {return !o.table_hide})

//	------------------------	------------------------	------------------------

const contact_methods = [
    { name: 'method_fax',   label: 'Fax' },
    { name: 'method_email', label: 'Email' },
    { name: 'method_mail',  label: 'Mail' },
    { name: 'method_phone', label: 'Phone' },
    { name: 'method_other', label: 'Other (write in note)' },
]

const contact_methods_options = contact_methods.map((e, i) => { return { key: `mtd_opt_${i}`, text: `${e.label}`, value: `${e.name}` } })

//	------------------------	------------------------	------------------------

const autorities = [
    { name: 'auth_nsw',     state: 'state_nsw',     label: 'NSW - Public Health Unit (speficify region in note)' },
    { name: 'auth_vic',     state: 'state_vic',     label: 'VIC - Department of Health' },
    { name: 'auth_qld',     state: 'state_qld',     label: 'QLD - Queensland Health' },
    { name: 'auth_wa',      state: 'state_wa',      label: 'WA - Public Health Unit' },
    { name: 'auth_sa',      state: 'state_sa',      label: 'SA - SA Health' },
    { name: 'auth_act',     state: 'state_act',     label: 'ACT - ACT Health' },
    { name: 'auth_tas',     state: 'state_tas',     label: 'TAS - Public Health Services' },
    { name: 'auth_nt',      state: 'state_nt',      label: 'NT - Public Health Unit' },
    { name: 'auth_other',   state: 'state_other',   label: 'Other (write in note)' },
]

const autorities_options = autorities.map((e, i) => { return { key: `ath_opt_${i}`, text: `${e.label}`, value: `${e.name}` } })

// Notifiable conditions - this may change and need review - https://docs.google.com/spreadsheets/d/18R8NHOc-yt4CrVPQVjGTRonuyIKs42T2RImvVr4uwOM/edit#gid=0

const notifiable = {
    state_nsw:  {condition_chlamydia: false, condition_gonorrhoea:false, condition_syphilis: true,  condition_hep_b: true,  condition_hep_c: true,  condition_hiv: true},
    state_vic:  {condition_chlamydia: false, condition_gonorrhoea:true,  condition_syphilis: true,  condition_hep_b: true,  condition_hep_c: true,  condition_hiv: true},
    state_qld:  {condition_chlamydia: false, condition_gonorrhoea:false, condition_syphilis: false, condition_hep_b: false, condition_hep_c: false, condition_hiv: false},
    state_wa:   {condition_chlamydia: true,  condition_gonorrhoea:true,  condition_syphilis: true,  condition_hep_b: false, condition_hep_c: false, condition_hiv: false},
    state_sa:   {condition_chlamydia: true,  condition_gonorrhoea:true,  condition_syphilis: true,  condition_hep_b: true,  condition_hep_c: true,  condition_hiv: true},
    state_act:  {condition_chlamydia: true,  condition_gonorrhoea:true,  condition_syphilis: true,  condition_hep_b: true,  condition_hep_c: true,  condition_hiv: true},
    state_tas:  {condition_chlamydia: false, condition_gonorrhoea:false, condition_syphilis: true,  condition_hep_b: true,  condition_hep_c: true,  condition_hiv: false},
    state_nt:   {condition_chlamydia: false, condition_gonorrhoea:false, condition_syphilis: true,  condition_hep_b: true,  condition_hep_c: true,  condition_hiv: false},
}

// Minor styling - cbf putting is scss

const styles = {

    hl_style_head: {background: '#0053'},
    hl_style_body: {background: '#0051'},
    rl_style_head: {background: '#0503'},
    rl_style_body: {background: '#0501'},
    rl_style_body_hit: {background: '#a005'},
    em_style: {fontWeight: 800, color: '#f00f'},

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default class NotifyPatho extends Component {

    constructor(props) {

        super(props)

        this.state = {}

        this.setup_debounced()

        this.init({ constructor: true })

    }

    // 		--------------------------------		--------------------------------		---------

    setup_debounced() {

        this.debounce_handle_notify_data = debounce(this.handle_notify_data.bind(this), debounce_time)
        this.debounce_handle_cancel_notify_data = debounce(this.handle_cancel_notify_data.bind(this), debounce_time)
        this.debounce_handle_sent_data = debounce(this.handle_sent_data.bind(this), debounce_time)
        this.debounce_handle_cancel_sent_data = debounce(this.handle_cancel_sent_data.bind(this), debounce_time)

    }

    // 		--------------------------------		--------------------------------		---------

    init(params = {}) {

        this.script_sid = this.props.scr_obj?.sid
        this.has_script = !!(this.script_sid)

        this.populate_data_from_scr()

        if (!params?.constructor) this.setState({})

    }

    // 		--------------------------------		--------------------------------		---------

    populate_data_from_scr() {

        this.clear_data()

        const state = this.get_patient_state()
        const conditions = this.get_patient_conditions({ state })

        this.notify_req_data = { state, conditions, note: '' }
        this.notify_sent_data = {} // might need populating from this?.props?.scr_obj?.meta?.notify_patho?.notification

    }

    // 		--------------------------------		--------------------------------		---------

    clear_data() {

        this.notify_req_data = { state: null, conditions: null, note: '' }
        this.cancel_notify_req_data = { note: '' }
        this.notify_sent_data = {}
        this.cancel_notify_sent_data = { note: '' }

    }

    // 		--------------------------------		--------------------------------		---------

    get_patient_state() {

        const scr_state = `${this?.props?.scr_obj?.spd_data?.full_address?.state}`
        return states.reduce((a, c) => { if (scr_state.match(c.regx)) a = c.name; return a }, null)

    }

    // 		--------------------------------		--------------------------------		---------

    get_patient_conditions(params = {}) {

        if (!params.state) return []

        const state_notifiable = notifiable[params.state]

        if (!state_notifiable) return []

        const patho_data = this?.props?.scr_obj?.attch?.find(o => o.type == 'patho' && o.value)

        if (!Array.isArray(patho_data?.value)) return []

        // Check for hits

        return Object.keys(patho_data.value.reduce((a, c) => {
            const condition = conditions.find(o => o.id == c.id)?.name
            if (state_notifiable[condition] && true == c.result) a[condition] = true
            return a
        }, {}))

    }

    // 		--------------------------------		--------------------------------		---------
    //      Functions
    // 		--------------------------------		--------------------------------		---------

    handle_toggle_notify_requied(params = {}) {

        if (params?.notify_required) {
            if (this.state.showSendNotifyRequired) console.log('Already sending...')
            else this.setState({ showSendNotifyRequired: true, send_notify_req_res: undefined })
        } else {
            if (this.state.showCancelNotifyRequired) console.log('Already sending...')
            else this.setState({ showCancelNotifyRequired: true, send_cancel_notify_req_res: undefined })
        }

    }

    // 		--------------------------------		--------------------------------		---------

    handle_toggle_notify_sent(params = {}) {

        if (params?.notify_complete) {
            if (this.state.showSendNotifySent) console.log('Already sending...')
            else this.setState({ showSendNotifySent: true, send_notify_sent_res: undefined })
        } else {
            if (this.state.showSendCancelNotifySent) console.log('Already sending...')
            else this.setState({ showSendCancelNotifySent: true, send_cancel_notify_sent_res: undefined })
        }

    }

    // 		--------------------------------		--------------------------------		---------

    handle_notify_data(params = {}) {

        this.notify_req_data = { ...this.notify_req_data, ...params }

        this.setState({})

    }
    // 		--------------------------------		--------------------------------		---------

    handle_cancel_notify_data(params = {}) {

        this.cancel_notify_req_data = { ...this.cancel_notify_req_data, ...params }

        this.setState({})

    }

    // 		--------------------------------		--------------------------------		---------

    handle_sent_data(params = {}) {

        this.notify_sent_data = { ...this.notify_sent_data, ...params }

        this.setState({})

    }
    // 		--------------------------------		--------------------------------		---------

    handle_cancel_sent_data(params = {}) {

        this.cancel_notify_sent_data = { ...this.cancel_notify_sent_data, ...params }

        this.setState({})

    }

    // 		--------------------------------		--------------------------------		---------

    async send_notify_req() {

        if (this.state.sending_notify_req) console.log('Already sending, please wait...')
        else this.setState({ sending_notify_req: true, send_notify_req_res: undefined }, async _ => {

            let send_notify_req_res

            try {

                // Precheck
                const pre_check_res = this.check_notify_req_data()

                if (pre_check_res?.res != 'ok') send_notify_req_res = pre_check_res
                else {

                    const send_params = {
                        sid: this?.props?.scr_obj?.sid,
                        state: this.notify_req_data.state,
                        conditions: this.notify_req_data.conditions,
                        note: this.notify_req_data.note,
                        silent: this.notify_req_data.silent,
                    }

                    send_notify_req_res = await API_service.load_data('set_notify_req', send_params) || { res: 'err', err: 'No response' }

                }

            } catch (e) { send_notify_req_res = { ...send_notify_req_res, res: 'err', err: `Error: ${e}` } }

            app.trigger(app.events.SHOW_MESSAGE, 'Send notify request', send_notify_req_res?.res == 'ok' ? `Sent notify request` : `Error sending results: ${send_notify_req_res?.err || 'unknown'}`, send_notify_req_res.res == 'ok' ? 'positive' : 'negative')

            this.setState({ sending_notify_req: false, send_notify_req_res }, async _ => { if (send_notify_req_res?.res == 'ok' && typeof this?.props?.on_update === 'function') this.props.on_update() })

        })

    }

    // 		--------------------------------		--------------------------------		---------

    async send_cancel_notify_req() {

        if (this.state.sending_cancel_notify_req) console.log('Already sending, please wait...')
        else this.setState({ sending_cancel_notify_req: true, send_cancel_notify_req_res: undefined }, async _ => {

            let send_cancel_notify_req_res

            try {

                // Precheck
                const pre_check_res = this.check_cancel_notify_req_data()

                if (pre_check_res?.res != 'ok') send_cancel_notify_req_res = pre_check_res
                else {

                    const send_params = {
                        sid: this?.props?.scr_obj?.sid,
                        note: this.cancel_notify_req_data.note,
                    }

                    send_cancel_notify_req_res = await API_service.load_data('set_cancel_notify_req', send_params) || { res: 'err', err: 'No response' }

                }

            } catch (e) { send_cancel_notify_req_res = { ...send_cancel_notify_req_res, res: 'err', err: `Error: ${e}` } }

            app.trigger(app.events.SHOW_MESSAGE, 'Send cancel notify request', send_cancel_notify_req_res?.res == 'ok' ? `Sent cancel notify request` : `Error sending results: ${send_cancel_notify_req_res?.err || 'unknown'}`, send_cancel_notify_req_res.res == 'ok' ? 'positive' : 'negative')

            this.setState({ sending_cancel_notify_req: false, send_cancel_notify_req_res }, async _ => { if (send_cancel_notify_req_res?.res == 'ok' && typeof this?.props?.on_update === 'function') this.props.on_update() }) // If success need a refresh

        })

    }

    // 		--------------------------------		--------------------------------		---------

    async send_notify_sent() {

        if (this.state.sending_notify_sent) console.log('Already sending, please wait...')
        else this.setState({ sending_notify_sent: true, send_notify_sent_res: undefined }, async _ => {

            let send_notify_sent_res

            try {

                // Precheck
                const pre_check_res = this.check_notify_sent_data()

                if (pre_check_res?.res != 'ok') send_notify_sent_res = pre_check_res
                else {

                    const send_params = {
                        sid: this?.props?.scr_obj?.sid,
                        note: this.notify_sent_data.note,
                        autorities: this.notify_sent_data.autorities,
                        contact_methods: this.notify_sent_data.contact_methods,
                    }

                    send_notify_sent_res = await API_service.load_data('set_notify_sent', send_params) || { res: 'err', err: 'No response' }

                }

            } catch (e) { send_notify_sent_res = { ...send_notify_sent_res, res: 'err', err: `Error: ${e}` } }

            app.trigger(app.events.SHOW_MESSAGE, 'Send notify sent', send_notify_sent_res?.res == 'ok' ? `Sent notify sent` : `Error sending results: ${send_notify_sent_res?.err || 'unknown'}`, send_notify_sent_res?.res == 'ok' ? 'positive' : 'negative')

            this.setState({ sending_notify_sent: false, send_notify_sent_res }, async _ => { if (send_notify_sent_res?.res == 'ok' && typeof this?.props?.on_update === 'function') this.props.on_update() })

        })

    }

    // 		--------------------------------		--------------------------------		---------

    async send_cancel_notify_sent() {

        if (this.state.sending_cancel_notify_sent) console.log('Already sending, please wait...')
        else this.setState({ sending_cancel_notify_sent: true, send_cancel_notify_sent_res: undefined }, async _ => {

            let send_cancel_notify_sent_res

            try {

                // Precheck
                const pre_check_res = this.check_cancel_notify_sent_data()

                if (pre_check_res?.res != 'ok') send_cancel_notify_sent_res = pre_check_res
                else {

                    const send_params = {
                        sid: this?.props?.scr_obj?.sid,
                        note: this.cancel_notify_sent_data.note,
                    }

                    send_cancel_notify_sent_res = await API_service.load_data('set_cancel_notify_sent', send_params) || { res: 'err', err: 'No response' }

                }

            } catch (e) { send_cancel_notify_sent_res = { ...send_cancel_notify_sent_res, res: 'err', err: `Error: ${e}` } }

            app.trigger(app.events.SHOW_MESSAGE, 'Send cancel notify sent', send_cancel_notify_sent_res?.res == 'ok' ? `Sent cancel notify sent` : `Error sending results: ${send_cancel_notify_sent_res?.err || 'unknown'}`, send_cancel_notify_sent_res.res == 'ok' ? 'positive' : 'negative')

            this.setState({ sending_cancel_notify_sent: false, send_cancel_notify_sent_res }, async _ => { if (send_cancel_notify_sent_res?.res == 'ok' && typeof this?.props?.on_update === 'function') this.props.on_update() })

        })

    }

    // 		--------------------------------		--------------------------------		---------

    check_notify_req_data() {

        let result = { res: 'ok' }

        try {

            if (!this?.notify_req_data?.state) throw 'No state - choose state patient resides in'
            if (!Array.isArray(this?.notify_req_data?.conditions) || !(this?.notify_req_data?.conditions?.length > 0)) throw 'No conditions - add one or more notifiable condiditions'
            if (this.notify_req_data.conditions.includes('condition_other') && (!this.notify_req_data.note || this.notify_req_data.note == '')) throw 'No condition specified for other - add to note'

        } catch (e) {

            result = { ...result, res: 'err', err: `Error: ${e}` }

        }

        return result

    }

    // 		--------------------------------		--------------------------------		---------

    check_cancel_notify_req_data() {

        let result = { res: 'ok' }

        try {

            if (!this?.cancel_notify_req_data?.note) throw 'No reason - provide reason why the notification request should be cancelled'

        } catch (e) {

            result = { ...result, res: 'err', err: `Error: ${e}` }

        }

        return result

    }

    // 		--------------------------------		--------------------------------		---------

    check_notify_sent_data() {

        let result = { res: 'ok' }

        try {

            if (!this?.notify_sent_data?.steps) throw 'Not marked as sent and attached'
            if (!Array.isArray(this?.notify_sent_data?.contact_methods) || !(this?.notify_sent_data?.contact_methods?.length > 0)) throw 'No contact method - add one or more'
            if (this.notify_sent_data.contact_methods.includes('method_other') && (!this.notify_sent_data.note || this.notify_sent_data.note == '')) throw 'No method specified for other - add to note'
            if (!Array.isArray(this?.notify_sent_data?.autorities) || !(this?.notify_sent_data?.autorities?.length > 0)) throw 'No authoiry - add one or more'
            if (this.notify_sent_data.autorities.includes('auth_other') && (!this.notify_sent_data.note || this.notify_sent_data.note == '')) throw 'No authority specified for other - add to note'

        } catch (e) {

            result = { ...result, res: 'err', err: `Error: ${e}` }

        }

        return result

    }

    // 		--------------------------------		--------------------------------		---------

    check_cancel_notify_sent_data() {

        let result = { res: 'ok' }

        try {

            if (!this?.cancel_notify_sent_data?.note) throw 'No reason - provide reason why the previous notify did not occur'

        } catch (e) {

            result = { ...result, res: 'err', err: `Error: ${e}` }

        }

        return result

    }

    // 		--------------------------------		--------------------------------		---------
    //      UI
    // 		--------------------------------		--------------------------------		---------

    render() {

        const notify_required = !!(this?.props?.scr_obj?.meta?.notify_patho?.request?.required)
        const notify_complete = !!(this?.props?.scr_obj?.meta?.notify_patho?.notification?.complete)

        return 1 &&

            <React.Fragment>

                <Button
                    content={notify_required ? 'Notifiable' : 'Not notifiable'}
                    icon={notify_required ? 'bell' : 'bell slash'}
                    size='small'
                    color={notify_required ? 'red' : 'grey'}
                    onClick={_ => this.handle_toggle_notify_requied({ notify_required: !notify_required })}
                    //onClick={_ => {}}
                    disabled={!this.has_script}
                />

                <Button
                    content={notify_complete ? 'Notify sent' : 'Notify unsent'}
                    icon={notify_complete ? 'check' : 'close'}
                    size='small'
                    color={notify_complete ? 'green' : notify_required ? 'red' : 'grey'}
                    // onClick={_ => this.handleLock()}
                    onClick={_ => this.handle_toggle_notify_sent({ notify_complete: !notify_complete })}
                    disabled={!this.has_script || !notify_required}
                //loading={this.state.sending_lock}
                />

                {this.render_send_notify_req()}
                {this.render_send_cancel_notify_req()}
                {this.render_send_notify_sent()}
                {this.render_send_cancel_notify_sent()}

            </React.Fragment>
    }

    //	------------------------	------------------------	------------------------

    render_send_notify_req() {

        const request_ok = this.state.send_notify_req_res?.res == 'ok'
        const state_index = states_table_filter.findIndex(o => o.name == this.notify_req_data?.state)
        const condition_indicies = Array.isArray(this.notify_req_data?.conditions) ? this.notify_req_data?.conditions?.map(o => conditions_table_filter.findIndex(e => e.name == o)) : []

        for (let i = 0; i < state_index.length; i++){

        }

        return 1 &&

            <Modal open={!!this.state.showSendNotifyRequired}>

                <Modal.Header><Icon name='bell' /> Set as a notifiable condition</Modal.Header>
                <Modal.Content>

                    <p>Certain conditions must be reported to state or other authorities</p>
                    <p>Support will send this notification to the appropriate authority</p>

                    <h4>Notifiable conditions</h4>

                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center' />
                                {states_table_filter.map((o, i) => {
                                    return <Table.HeaderCell style={i == state_index ? styles.hl_style_head : {}} key={`tr_h_${i}`} textAlign='center'>{o.label}</Table.HeaderCell>
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {conditions_table_filter.map((o, i) => {
                                const is_cond = condition_indicies.includes(i)
                                return <Table.Row key={`tr_b_${i}`}>
                                    <Table.Cell textAlign='left' style={is_cond ? styles.rl_style_head : {}}>{o.label}</Table.Cell>
                                    {states_table_filter.map((p, i) => {
                                        const is_notify = notifiable?.[p.name]?.[o.name]
                                        return <Table.Cell style={i == state_index ? (is_cond ? styles.rl_style_body_hit : styles.hl_style_body) : (is_cond ? styles.rl_style_body : {})} key={`tr_br_${i}`} textAlign='center'>{is_notify?'Y':''}</Table.Cell>
                                    })}
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>

                    <h4>Please enter the required details below and click send</h4>

                    <Form>

                        <Form.Dropdown
                            fluid
                            clearable
                            search
                            selection
                            required
                            label={'State'}
                            placeholder={'State that patient lives in'}
                            options={states_options}
                            defaultValue={this.notify_req_data?.state}
                            onChange={(e, d) => this.handle_notify_data({ state: d.value })}
                            error={!this.notify_req_data?.state}
                        />

                        <Form.Dropdown
                            fluid
                            clearable
                            search
                            selection
                            required
                            multiple
                            label={'Condition(s)'}
                            placeholder={'Condition(s) needing to be notified of'}
                            options={conditions_options}
                            defaultValue={this.notify_req_data?.conditions}
                            onChange={(e, d) => this.handle_notify_data({ conditions: d.value })}
                            error={!this.notify_req_data?.conditions}
                        />

                        <Form.TextArea
                            defaultValue={this.notify_req_data?.notes || ''}
                            onChange={(e, d) => this.debounce_handle_notify_data({ note: d.value })}
                            label={'Notes'}
                            placeholder={'Any further details to support'}
                        />

                        <Form.Field>
                            <label>Silent</label>
                            <Form.Checkbox
                                defaultValue={!!this.notify_req_data?.silent}
                                onChange={(e, d) => this.debounce_handle_notify_data({ silent: d.checked })}
                                label={`Don't send notification to support`}
                            />
                        </Form.Field>

                        {/* <p>{JSON.stringify(this.props.scr_obj)}</p> */}

                    </Form>

                    {(this.state.send_notify_req_res?.res == 'ok') && <h4 style={{ color: 'green' }}>Send successful!</h4>}
                    {(this.state.send_notify_req_res?.res == 'err') && <h4 style={{ color: 'red' }}>Send unsuccessful: {this.state.send_notify_req_res?.err || 'unknown error'}</h4>}

                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={_ => this.send_notify_req()} disabled={this.state.sending_notify_req || request_ok} loading={this.state.sending_notify_req}>
                        <Icon name='checkmark' /> Send
                    </Button>
                    <Button color='red' onClick={_ => this.setState({ showSendNotifyRequired: false })} disabled={this.state.sending_notify_req}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>

            </Modal>

    }


    //	------------------------	------------------------	------------------------

    render_send_cancel_notify_req() {

        const request_ok = this.state.send_cancel_notify_req_res?.res == 'ok'

        const notify_request = this?.props?.scr_obj?.meta?.notify_patho?.request

        const request_conditions = Array.isArray(notify_request?.conditions) && notify_request?.conditions?.length > 0 ? notify_request.conditions.map(e => { return (conditions.find(o => o.name == e) || { label: `Unknown: ${e}` }).label }).join(', ') : '?'

        return 1 &&

            <Modal open={!!this.state.showCancelNotifyRequired}>

                <Modal.Header><Icon name='bell' /> Cancel request for notifiable condition</Modal.Header>
                <Modal.Content>

                    <p style={styles.em_style}>This will cancel the existing request:</p>
                    <p>Conditions: {request_conditions}</p>
                    {notify_request?.note && <p>Note: {`${notify_request?.note}`}</p>}
                    <p>Time: {`${notify_request?.tm || '?'}`}</p>
                    <p>By: {`${notify_request?.user || '?'}`}</p>
                    <h4>Please specify why the notify request should be cancelled.</h4>

                    <Form>

                        <Form.TextArea
                            required
                            defaultValue={this.cancel_notify_req_data?.note || ''}
                            onChange={(e, d) => this.debounce_handle_cancel_notify_data({ note: d.value })}
                            label={'Reason'}
                            placeholder={'Reason for cancelling notify request'}
                        />

                    </Form>

                    {(this.state.send_cancel_notify_req_res?.res == 'ok') && <h4 style={{ color: 'green' }}>Send successful!</h4>}
                    {(this.state.send_cancel_notify_req_res?.res == 'err') && <h4 style={{ color: 'red' }}>Send unsuccessful: {this.state.send_cancel_notify_req_res?.err || 'unknown error'}</h4>}

                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={_ => this.send_cancel_notify_req()} disabled={this.state.sending_cancel_notify_req || request_ok} loading={this.state.sending_cancel_notify_req}>
                        <Icon name='checkmark' /> Send
                    </Button>
                    <Button color='red' onClick={_ => this.setState({ showCancelNotifyRequired: false })} disabled={this.state.sending_cancel_notify_req}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>

            </Modal>

    }

    //	------------------------	------------------------	------------------------

    render_send_notify_sent() {

        const request_ok = this.state.send_notify_sent_res?.res == 'ok'

        const notify_request = this?.props?.scr_obj?.meta?.notify_patho?.request

        const request_conditions = Array.isArray(notify_request?.conditions) && notify_request?.conditions?.length > 0 ? notify_request.conditions.map(e => { return (conditions.find(o => o.name == e) || { label: `Unknown: ${e}` }).label }).join(', ') : '?'
        const request_state = notify_request?.state ? (states.find(o => o.name == notify_request.state) || { label: `Unknown: ${notify_request.state}` }).label : '?'

        const autho = autorities.filter(o => o.state == notify_request?.state).map(o => o.label).join(', ')

        return 1 &&

            <Modal open={!!this.state.showSendNotifySent}>

                <Modal.Header><Icon name='bell' /> Mark notification as complete</Modal.Header>
                <Modal.Content>
                    
                    <h4>Ensure the following is covered in the notification(s)</h4>

                    <p>State: {request_state}</p>
                    <p>Conditions: {request_conditions}</p>
                    <p style={styles.em_style}>Authorities to notify: {autho}</p>

                    <p style={styles.em_style}>A copy of the notification(s) sent should be attached to the results area for reference</p>

                    <p><a style={{color: 'blue'}} href='https://drive.google.com/drive/folders/1Ds3nWgfsuMyfTFt1d_cfYlmFivu23DGy' target='_blank'>Notification forms and information (Google Drive folder)</a></p>
                    <p><a style={{color: 'blue'}} href='https://docs.google.com/document/d/1L-7JIKquy_x9QvfX9sj1EvjkcolHCok_5i6sy0On3Lc/edit#heading=h.kz20ddwc6xws' target='_blank'>Process for notifying authorities (Google Doc)</a></p>

                    <h4>Please enter the required details below and click send</h4>
                    
                    <p>Only complete after all appropriate authorities have been notified</p>

                    <Form>

                        <Form.Dropdown
                            fluid
                            clearable
                            search
                            selection
                            required
                            multiple
                            label={'Notified Authorities'}
                            placeholder={'Notified Authorities as per notifiable disease laws/acts'}
                            options={autorities_options}
                            defaultValue={this.notify_sent_data?.autorities}
                            onChange={(e, d) => this.handle_sent_data({ autorities: d.value })}
                            error={!this.notify_sent_data?.autorities}
                        />

                        <Form.Dropdown
                            fluid
                            clearable
                            search
                            selection
                            required
                            multiple
                            label={'Method(s)'}
                            placeholder={'Method(s) used to contact all authorities'}
                            options={contact_methods_options}
                            defaultValue={this.notify_sent_data?.contact_methods}
                            onChange={(e, d) => this.handle_sent_data({ contact_methods: d.value })}
                            error={!this.notify_sent_data?.contact_methods}
                        />

                        <Form.Checkbox
                            slider
                            label='Appropriate authorities are notified and copies attached to the record'
                            onChange={(e, d) => {console.log(d); this.handle_sent_data({ steps: d.checked })}}
                            error={!this.notify_sent_data?.steps}
                        />

                        <Form.TextArea
                            defaultValue={this.notify_sent_data?.notes || ''}
                            onChange={(e, d) => this.debounce_handle_sent_data({ note: d.value })}
                            label={'Notes'}
                            placeholder={'Any further details about the notification(s) sent.'}
                        />

                    </Form>

                    {(this.state.send_notify_sent_res?.res == 'ok') && <h4 style={{ color: 'green' }}>Send successful!</h4>}
                    {(this.state.send_notify_sent_res?.res == 'err') && <h4 style={{ color: 'red' }}>Send unsuccessful: {this.state.send_notify_sent_res?.err || 'unknown error'}</h4>}

                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={_ => this.send_notify_sent()} disabled={this.state.sending_notify_sent || request_ok} loading={this.state.sending_notify_sent}>
                        <Icon name='checkmark' /> Send
                    </Button>
                    <Button color='red' onClick={_ => this.setState({ showSendNotifySent: false })} disabled={this.state.sending_notify_sent}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>

            </Modal>

    }

    //	------------------------	------------------------	------------------------

    render_send_cancel_notify_sent() {

        const request_ok = this.state.send_cancel_notify_sent_res?.res == 'ok'

        const notify_sent = this?.props?.scr_obj?.meta?.notify_patho?.notification

        const sent_authorities = Array.isArray(notify_sent?.autorities) && notify_sent?.autorities?.length > 0 ? notify_sent.autorities.map(e => { return (autorities.find(o => o.name == e) || { label: `Unknown: ${e}` }).label }).join(', ') : '?'
        const sent_contact_methods = Array.isArray(notify_sent?.contact_methods) && notify_sent?.contact_methods?.length > 0 ? notify_sent.contact_methods.map(e => { return (contact_methods.find(o => o.name == e) || { label: `Unknown: ${e}` }).label }).join(', ') : '?'

        return 1 &&

            <Modal open={!!this.state.showSendCancelNotifySent}>

                <Modal.Header><Icon name='bell' /> Mark notification as not complete</Modal.Header>
                <Modal.Content>

                    <p style={styles.em_style}>This will cancel the existing notification:</p>
                    {notify_sent?.note && <p>Note: {`${notify_sent?.note}`}</p>}
                    <p>Authorities: {sent_authorities}</p>
                    <p>Contact methods: {sent_contact_methods}</p>
                    <p>Time: {`${notify_sent?.tm || '?'}`}</p>
                    <p>By: {`${notify_sent?.user || '?'}`}</p>
                    <h4>Please specify why the notify request should be marked as not completed.</h4>

                    <Form>

                        <Form.TextArea
                            required
                            defaultValue={this.cancel_notify_sent_data?.note || ''}
                            onChange={(e, d) => this.debounce_handle_cancel_sent_data({ note: d.value })}
                            label={'Reason'}
                            placeholder={'Reason for marking the notification request as not completed'}
                        />

                    </Form>

                    {(this.state.send_cancel_notify_sent_res?.res == 'ok') && <h4 style={{ color: 'green' }}>Send successful!</h4>}
                    {(this.state.send_cancel_notify_sent_res?.res == 'err') && <h4 style={{ color: 'red' }}>Send unsuccessful: {this.state.send_cancel_notify_sent_res?.err || 'unknown error'}</h4>}

                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={_ => this.send_cancel_notify_sent()} disabled={this.state.sending_cancel_notify_sent || request_ok} loading={this.state.sending_cancel_notify_sent}>
                        <Icon name='checkmark' /> Send
                    </Button>
                    <Button color='red' onClick={_ => this.setState({ showSendCancelNotifySent: false })} disabled={this.state.sending_cancel_notify_sent}>
                        <Icon name='close' /> Close
                    </Button>
                </Modal.Actions>

            </Modal>

    }

}