import React, { useState, useContext, useEffect, useRef, useCallback, useReducer, useMemo } from "react";

import { useUser } from "xAppLib/Hooks";
import { useDefaultReducer } from "xAppLib/Hooks";

const UserDataContext = React.createContext({});

const DEFAULT = {}

const UserDataProvider = ({ children }) => {
	const user = useUser()
	const [userData, actions, dispatch] = useDefaultReducer({...DEFAULT});
	
	useEffect(() => {
		actions.reset()
	}, [user.uid]);

	const state = useMemo(()=>[userData,actions,dispatch],[userData,actions,dispatch])

	return <UserDataContext.Provider value={state}>{children}</UserDataContext.Provider>;
};

function UserDataConsumer({ children }) {
	return (
		<UserDataContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						"UserDataConsumer must be used within a UserDataProvider"
					);
				}
				return children(context);
			}}
		</UserDataContext.Consumer>
	);
}


export { UserDataContext, UserDataProvider, UserDataConsumer };
