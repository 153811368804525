import React, { useState, useEffect, useRef } from "react";
import UniForm from "xAppLib/UniForm/UniForm";
import script_model from "models/script_model";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import UniFormMed from "views/med/UniFormMed";

const ExtraInfo = (props) => {
	const { med_data, script_type, req_type, Section } = props

	return (
		<UniFormMed
			{...props}
			section="extra"
			fields={script_model.COMM_EXTRAINFO_FIELDS}
		>
			{(values, valids, uf_this, fields) => {
				const args = {values, valids, uf_this}

				return <Section>
						
					<Section.Header>
						Extra request details
					</Section.Header>

				
					<Section.Content>
						<UniFieldSUI fl={{...fields.cons_desc, label:'In as much detail as possible please explain why you are seeking this medication today.'}} {...args} />
					</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);
};

export default ExtraInfo;


