import API_service from "xAppLib/providers/API_service";

const DEBUG = false;

export const get_appointment_types = async (data) => {
    DEBUG && console.log("booking_org_patient :: get_appointment_types", data);
    const res = await API_service.load_data(`booking_org/get_appointment_types`, data);
    return res;
}

export const get_appointment_type_users = async (data) => {
    DEBUG && console.log("booking_org_patient :: get_appointment_type_users", data);
    const res = await API_service.load_data(`booking_org/get_appointment_type_users`, data);
    return res;
}

export const get_appointment_times = async (data) => {
    DEBUG && console.log("booking_org_patient :: get_appointment_times", data);
    const res = await API_service.load_data(`booking_org/get_appointment_times`, data);
    return res;
}

export const make_appointment = async (data) => {
    DEBUG && console.log("booking_org_patient :: make_appointment", data);
    const res = await API_service.load_data(`booking_org/make_appointment`, data);
    return res;
}

export const get_patient_appointments = async (data) => {
    DEBUG && console.log("booking_org_patient :: get_patient_appointments");
    const res = await API_service.load_data(`booking_org/get_patient_appointments`, data);
    return res;
}

export const patient_cancel_appointment = async (data) => {
    DEBUG && console.log("booking_org_patient :: patient_cancel_appointment");
    const res = await API_service.load_data(`booking_org/patient_cancel_appointment`, data);
    return res;
}