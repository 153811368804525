import React from 'react'
import { Icon, Popup, Button } from 'semantic-ui-react'

import db_lib from 'xAppLib/libs/db_lib';


const _LIST_FLDS_ADMIN = [
						{
							name: 'Num',
							jpath: 'nnum',
						},
						// {
						// 	name: 'Enabled',
						// 	jpath: 'enabled',
						// 	template : ({value:enabled}) => <React.Fragment>
						// 					<Icon name={enabled && 'check circle outline' || 'x'} color={enabled&&'green'||'red'} size='large'/>
						// 				</React.Fragment>
						// },
						{
							name: 'Type',
							jpath: 'type',
							template : ({value:type}) => <React.Fragment>
											{type}
											{/*{notes_model.TYPES[type]?.label || type}*/}
										</React.Fragment>
						},

						{
							name: 'Added',
							type: 'multiline',
							lines: [
										{
											jpath: 'cre_tm',
											type:'time'
										},
									],
						},
						{
							name: 'By',
							type: 'multiline',
							lines: [
										{
											jpath: 'cre_by.n',
											// type:'date'
										},
										{type: 'br'},
										{
											jpath: 'cre_by.e',
											// type:'date'
										},
									],
						},
						{
							name: 'Patient',
							type: 'compound',
							parts: [
								{
									name: 'email',
									jpath: 'email'
								},
								{
									name: 'first_name',
									jpath: 'first_name'
								},
								{
									name: 'last_name',
									jpath: 'last_name'
								},
								{
									name: 'uid',
									jpath: 'uid'
								},
							],
							template : ({email, first_name, last_name, uid}) => <>
											{email}
											<br/>
											{first_name} {last_name}
										</>
						},

						{
							name: 'Details',
							type: 'multiline',
							lines: [
										{
											jpath: 'lvl',
										},
										{type: 'br'},
										{
											jpath: 'cont',
											type: 'show_more',
											cut_length: 30,
										},
										{type: 'br'},
										{
											jpath: 'desc',
											type:'show_more',
											cut_length: 30,
										},
									],

						},

					];
					


const _MODEL_NAME = 'notes_model';

const _USE_DATA = 'API'
const _API_NAME = 'notes'


// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class notes_model extends db_lib {

	static get LIST_FLDS_ADMIN() { return _LIST_FLDS_ADMIN; }

	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async load_notes (data) {

		console.log('notes_model :: load_notes sending ', data);

		return await notes_model.get_record(null, null, {act:'notes_list', ...data});

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async patient_notes (data) {

		console.log('notes_model :: patient_notes sending ', data);

		return await notes_model.get_record(null, null, {act:'patient_notes', ...data});

	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async delete_note(nid) { 
		const res = await notes_model.get_record(null, null, {act:'del', nid});
		return res
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async save_note( nid, d ) {
		console.log("save_node",{nid, d});
		let res
		try {
			if (nid) {
				res = await notes_model.get_record(null, null, {act:'upd', nid, data:{...d}});
			} else {
				res = await notes_model.get_record(null, null, { act:'add', data:{...d} } );
			}
		} catch (err) {
			res = { res:'err',err}
		} 
		
		return res
	}

	// ---- --------------------------------------------  --------------------------------------------
	// ---- --------------------------------------------  --------------------------------------------

	static async save_note_fld( nid, fl, vl, ca ) {
		let res
		if (nid && fl) {
			res = await notes_model.get_record(null, null, {act:'upd_fld', nid, data:{fl, vl}});
		}
		console.log({res});
		ca && ca(res)
	}

}
