import React, { Component} from 'react'
import { Link } from 'react-router-dom'

import { Popup, Input, Button, Loader, Icon } from 'semantic-ui-react'

import Tree, { TreeNode } from 'rc-tree';

import user_model from 'models/user_model'


import debounce from '../libs/debounce'

export default class ShowTree extends Component {

	constructor (props) {
		super(props)

		// user_model.limit_access('admin');

		this.state = {
			data_loaded: false,
			tree_data_db: null,
			expandedKeys:[0],
			lastSelectedKeys:[],
			filterValue:'',
			autoExpandParent: true,
		}
		
		this.debouncedSetFilter = debounce(this.setFilter.bind(this),500)
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	componentDidMount () {

		this.load_recs()
		
	}

	// 		--------------------------------		--------------------------------		---------

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (prevProps.refresh != this.props.refresh) {
			this.load_recs()
		}
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	async load_recs () {
		const { par_key, it_key } = this.props
		this.setState({loading:true})
		const tree_data_db = await this.props.get_tree_data();
		this.tree_data_db = tree_data_db
		this.hash = {}
		for (var org of tree_data_db) {
			if (!this.hash[org[par_key]]) {
				this.hash[org[par_key]] = []
			}
			this.hash[org[par_key]].push(org)
		}
		this.tree_data = this.get_tree_data()
		this.setState({
							data_loaded: true,
							loading:false
					})
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	get_nodes_count(itid = null) {
		const { par_key, it_key } = this.props
		const { tree_data_db } = this

		if (!tree_data_db)
			return null

		const ch = this.hash[itid] || []

		let tot = ch.length || 0

		if (ch.length)
			ch.forEach( value=> tot+=this.get_nodes_count(value[it_key]) )

		return tot
	}
	
	
	get_tree_data(parent_id = null) {
		const { par_key, it_key, it_ico } = this.props
		const { tree_data_db } = this

		if (!tree_data_db)
			return null
		
		const itms = (this.hash[parent_id] || [])
						.sort( (o1,o2) => {
							const o1s = (9999 - this.get_nodes_count(o1[it_key])) +"____"+ o1.name
							const o2s = (9999 - this.get_nodes_count(o2[it_key])) +"____"+ o2.name
							return o1s.localeCompare(o2s) 
						} )
						.sort( (o1,o2) => o1.srch&&o1.srch.localeCompare(o2.srch) )

		return itms.map(value=>{
			
			const children = this.get_tree_data(value[it_key]) 
			return ({
				value,
				key:value[it_key],
				title:this.render_node,
				icon:<Icon.Group>
						<Icon name={it_ico} />
						{children && <Icon corner name='add' />}
					</Icon.Group>,
				children
			})
		})
	}
	
	
	render_node = (node) => {
		const { value, children } = node
		const { setBtn, setBtnLabel='Set', onSelect, par_key, it_key, it_ico, it_label, it_id } = this.props

		return <React.Fragment> 
					{!value.active && <Icon name='exclamation' />}
					{value.verif===false && value.type=='cosm' && <Icon name='warning sign' size='tiny' />}
					{value[it_label]||value[it_id]} 
					&nbsp; 
					{children && children.length && `(${this.get_nodes_count(value[it_key])})` || null}
					&nbsp; 
					{setBtn && 
						<Button content={setBtnLabel} size='mini' color='green' onClick={ _=> onSelect && onSelect(value) } />
					}
					
				</React.Fragment>
	}

	
	get_filtered_tree_data(itms) {
		
		const { par_key, it_key} = this.props
		
		
		if (!itms || itms.length == 0)
			return null

		const result =  itms.map(node=>{
			const children = this.get_filtered_tree_data(node.children)
			
			if (!this.filterKeys.includes(node.value[it_key]))
				return null
			
			return {...node,children}
		}).filter(n=>n)
		
		return result
	}
	
	
	calculateFilterKeys(itms) {
		
		const { par_key, it_key, it_ico } = this.props

		if (!itms || itms.length == 0)
			return false

		return itms.map(({value,children})=>{
			const hasChildren = this.calculateFilterKeys(children)
			if (this.filterFn(value) || hasChildren) {
				this.filterKeys.push(value[it_key]);
				return true
			} else {
				if (!children || children.length == 0)
					return null
			}
			return null
			
			
		}).filter(n=>n).length!=0
		
	}
	
	
	
	onSelect = (selectedKeys,e) => {
		const { setBtn, onSelect, it_key } = this.props
		// console.log('onSelected', selectedKeys, e.selectedNodes[0]&&e.selectedNodes[0].key, e.selected, e.selectedNodes, e);
		const itid = selectedKeys[0]
		const itm = this.tree_data_db.filter(value=>value[it_key]==itid).pop()
		const leaf = e.node.isLeaf
		!setBtn && onSelect && onSelect(itm,leaf)
		if (!leaf) {
			const { expandedKeys, lastSelectedKeys } = this.state

			const final = expandedKeys.concat(selectedKeys)
			this.setState({expandedKeys:final})
		}
		this.setState({lastSelectedKeys:selectedKeys})
	}
	
	onExpand = (expandedKeys,e) => {
		this.filterKeys = undefined;
		this.setState({expandedKeys,autoExpandParent: false})
	}
	
	filterTreeNode = (treeNode) => {
		return this.filterFn(treeNode.o);
	}

	filterFn = (obj) => {
		return (
				this.filterValue && this.filterValue.toLowerCase().indexOf('contact:')>=0 && (
									obj.contact?.toLowerCase && obj.contact.toLowerCase().indexOf(this.filterValue.substring(8))>=0
									|| obj.email?.toLowerCase && obj.email.toLowerCase().indexOf(this.filterValue.substring(8))>=0
								)
				||
				this.filterValue && this.filterValue.toLowerCase().indexOf('address:')>=0 && (
									obj.address?.toLowerCase && obj.address.toLowerCase().indexOf(this.filterValue.substring(8))>=0
									|| obj.phone?.toLowerCase && obj.phone.toLowerCase().indexOf(this.filterValue.substring(8))>=0
								)
				||
				this.filterValue && this.filterValue.toLowerCase().indexOf('conf:')>=0 && 
									obj.conf && Object.keys(obj.conf) && Object.keys(obj.conf).length && Object.keys(obj.conf).some( c => c.toLowerCase().indexOf(this.filterValue.substring(5))>=0 )
				||
				this.filterValue && this.filterValue.toLowerCase().indexOf('filt:dis')>=0 && 
									!obj.active
				||
				this.filterValue && obj.title && obj.title.toLowerCase && obj.title.toLowerCase().indexOf(this.filterValue.toLowerCase())>=0
				||
				this.filterValue && obj.name && obj.name.toLowerCase && obj.name.toLowerCase().indexOf(this.filterValue.toLowerCase())>=0
				||
				this.filterValue && obj.oid && obj.oid.toLowerCase && obj.oid.toLowerCase().indexOf(this.filterValue.toLowerCase())>=0
				||
				this.filterValue && obj.onum && obj.onum.toLowerCase && obj.onum.toLowerCase().indexOf(this.filterValue.toLowerCase())>=0
			)
	}

	onChangeFilter = (event) => {
		const value = event.target.value
		this.debouncedSetFilter(value)
		this.setState({
			filterValue:value
		});
	}
	
	setFilter(value) {
		if ( value !='') {
			this.filterKeys = []
			this.filterValue = value
			this.calculateFilterKeys(this.tree_data)
		} else {
			this.filterKeys = null
			this.filterValue = null
		}
		this.setState({
			filterValue:value
		});
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	render () {

		const { data_loaded } = this.state
		const { showSearch = true } = this.props

		let tree_data = this.tree_data
		
		let expandedKeys = this.state.expandedKeys;
		let autoExpandParent = this.state.autoExpandParent;
		
		if (this.filterKeys) {
			tree_data = this.get_filtered_tree_data(tree_data)
			expandedKeys = this.filterKeys;
			autoExpandParent = true;
		}
		
		return <React.Fragment>

					<Loader active={this.state.loading} inline style={{marginTop:5}}/>
			
					{data_loaded && showSearch && 
							<Input 
									fluid 
									placeholder="Search by name / 'contact:*' / 'address:*' / 'conf:*' / filt:dis" 
									size="small" 
									value={this.state.filterValue}
									onChange={this.onChangeFilter} 
									icon={<Icon name={this.filterValue ? 'delete' : 'search'} onClick={ _=> this.filterValue && this.setFilter('') } link={!!this.filterValue} circular={!!this.filterValue} inverted={!!this.filterValue} />}
								/> 
					}
					
					{data_loaded && 
							<Tree
									treeData={tree_data}
									className="orgs-tree" 
									showLine 
									expandedKeys={expandedKeys}
									onSelect={this.onSelect} 
									onExpand={this.onExpand}
									checkable={false}
									autoExpandParent={autoExpandParent}
									leafOnly={false}
								/>
					}
					
				</React.Fragment>
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

}
