import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import IcosmPartners from './components/IcosmPartners';
import IcosmGridInfo from './components/IcosmGridInfo';
import IcosmBanner from './components/IcosmBanner';


const grid_info = ["Trusted", "Innovation", "Commitment", "Leadership" ]

const Values = () => {
    const img_path = "https://storage.googleapis.com/icosm/"

	return <div>

		<Helmet>
			<title>Our Values</title>
		</Helmet>

       <Container>
            <div className="flex flex-col mt-2 mb-9 md:mt-[65px] md:mb-[71px] text-center">
                <h1 className="mb-0">Feel Supported with <br/>InstantCosmetics</h1>
                <p className="text-icosm_md md:text-icosm_base md:w-[778px] mx-auto text-[19px] leading-[23px] my-6 md:my-[40px]">At InstantCosmetics, we’re unapologetic about constantly striving to be better than the rest. In everything we do.<br/><br/>
                    From how we treat our customers, to what we offer, to how we partner<br/>
                    with your business – no matter what size.<br/><br/>
                    It’s not about being the biggest or the loudest, or even the brightest and shiniest.<br/>
                    It’s about clarity, cleverness and class. World-class.<br/><br/>
                    It’s as simple as that.<br/>
                    <span className="italic">InstantCosmetics. Beautifully Simple.</span></p>
                <div className="flex flex-col md:flex-row gap-[26px] justify-center mx-auto">
                   <Button className="cta cta-primary icon" as={Link} size="large" type="button" to="/contact">Contact us</Button>
                   <Button className="cta cta-tertiary icon" as={Link} size="large" type="button" to="/join">Sign me up</Button>
                </div>
            </div>

            <div className="bg-[#D9D9D9] w-full h-[309px] bg-cover bg-center" style={{backgroundImage:`url('${img_path}2/export-rec.png')`}}></div>
            <IcosmGridInfo list={grid_info} dark title={<>Our values<br className='lg'/> are what<br className='lg'/> make us your first choice</>} desc="" cls="pt-9 md:pt-[90px]" link_text="Contact us" />
        </Container>

        <IcosmPartners />

        <IcosmBanner light size="sm" img='2/values.jpg' title={<>Start today<br className='lg'/> with the original <br className='lg'/>scripting platform</>} desc="Ready to start with a system that will help you grow in the aesthetics space? Sign up to get started."/>
	</div>

}

export default Values;
