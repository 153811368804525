import React from 'react'
import { Icon, Popup, Button } from 'semantic-ui-react'

import db_lib from 'xAppLib/libs/db_lib';
import MedSelect from 'xAppLib/UIelems/MedSelect'
import moment from "moment";
import ShowUserName from 'xAppLib/UIelems/ShowUserName'

import presc_model from 'models/presc_model'

import API_service from '../xAppLib/providers/API_service';

const _DOC_FLD = {
	name: 'Doc',
	path: 'doc.doc_id',
	type: 'dict',
	func: d => app.app_data.ws_data?.docs?.flat_cat_list?.[d]?.path_name || null,
};

const _SCHEDULED_PRESC_DETAIL = [
	{type: 'br'},
	{
		show_if: { jpath: 'activate_at' },
		type: 'compound',
		parts: [
			{
				name: 'status',
				jpath: 'status'
			},
			{
				name: 'activate_at',
				jpath: 'activate_at',
				type:'time'
			},
		],
		template: ({ status, activate_at }) => (
			<>
				{!['pending', 'scheduled'].includes(status) && 'Was '}
				Scheduled For: {activate_at}
			</>
		)
	}
]

const _LIST_FLDS_ADMIN = [
						{
							name: 'Num',
							jpath: 'prnum',
						},
						// {
						// 	name: 'Enabled',
						// 	jpath: 'enabled',
						// 	template : ({value:enabled}) => <React.Fragment>
						// 					<Icon name={enabled && 'check circle outline' || 'x'} color={enabled&&'green'||'red'} size='large'/>
						// 				</React.Fragment>
						// },
						{
							name: 'Type',
							type: 'multiline',
							lines: [
										{
											name: 'Type',
											jpath: 'type',
											template : ({value:type}) => <React.Fragment>
															{presc_model.TYPES[type]?.label || type}
														</React.Fragment>
										},
										{type: 'br'},
										{
											jpath: 'ref.apmd',
											type:'b'
										},
									],
						},
						// {
						// 	name: <>
						// 			Configuration
						// 			<Popup
						// 			    trigger={<Button style={{marginLeft:5,transform:'scale(.7,.7)'}} icon='question' size='mini' circular/>}
						// 			    basic
						// 			  >
						// 			  <Popup.Header>Legend</Popup.Header>
						// 			    <Popup.Content>
						// 					<Icon name="shopping basket" /> Medbuy<br/>
						// 					<Icon name="mobile" /> Medcons<br/>
						// 					<Icon name="doctor" /> Doccons<br/>
						// 					<Icon name="call" /> Call a doctor<br/>
						// 					<Icon name="truck" /> Shipping<br/>
						// 					<Icon name="user plus" /> For New users<br/>
						// 					<Icon name="user circle"  /> Restricted to a user<br/>
						// 			    </Popup.Content>
						// 		  </Popup>
						// 		</>,
						// 	jpath: 'conf',
						// 	template : ({value:conf,row}) => {
						// 		const { type } = row
						// 		const { MEDCONS,MEDBUY,CADO,SHIPPING,DOCCONS,new_user, rule,amount} = conf
						// 		return <React.Fragment>
						// 						{(_=>{
						// 							switch (rule) {
						// 								case 'dollar':
						// 									return `$${amount}`
						// 								case 'percentage':
						// 									return `${amount}%`
						// 								case 'free':
						// 									return `FREE`
						// 							}
														
						// 						})()}<br/>
						// 						<Icon name="shopping basket" disabled={!MEDBUY} />
						// 						<Icon name="mobile" disabled={!MEDCONS} />
						// 						<Icon name="call" disabled={!CADO} />
						// 						<Icon name="truck" disabled={!SHIPPING} />
						// 						<Icon name="doctor" disabled={!DOCCONS} />
						// 						<Icon name="user plus" disabled={!new_user}/>
						// 						<Icon name="user circle" disabled={!row?.users?.email} />
						// 					</React.Fragment>
						// 	}
						// },
						{
							name: 'Issued / Expiry',
							type: 'multiline',
							lines: [
										{
											jpath: 'cre_tm',
											type:'time'
										},
										{type: 'br'},
										{
											jpath: 'exp_tm',
											type:'date'
										},
									],

						},
						{
							name: 'Patient',
							type: 'compound',
							parts: [
								{
									name: 'email',
									jpath: 'email'
								},
								{
									name: 'first_name',
									jpath: 'first_name'
								},
								{
									name: 'last_name',
									jpath: 'last_name'
								},
								{
									name: 'uid',
									jpath: 'uid'
								},
							],
							template : ({email, first_name, last_name, uid}) => <>
											{email}
											<br/>
											{first_name} {last_name}
										</>
						},

						_DOC_FLD,

						{
							name: 'Med',
							path: 'med.nm',
							type: 'dict',
							func: d => app.app_data.ws_data?.cat_data?.pharm?.cat_avail_list?.[d]?.n || null,
						},

						{
							name: 'Details',
							type: 'multiline',
							lines: [
										{
											jpath: 'dets.msg',
											type: 'show_more',
											cut_length: 30,
										},
										{type: 'br'},
										{
											jpath: 'dets.note',
											type: 'show_more',
											cut_length: 30,
										},
										{type: 'br'},
										{
											jpath: 'dets.cost',
											// type:'date'
										},
										{show_if: { jpath: 'dets.results_cat' },type: 'br'},
										{
											show_if: { jpath: 'dets.results_cat' },
											type: 'compound',
											parts: [
												{
													name: 'results_cat',
													jpath: 'dets.results_cat'
												}
												
											],
											template: ({ results_cat }) => {
												return <>
													Follow up was <b>{results_cat === 'normal' ? 'Optional' : 'Insisted'}</b>
												</>
											}
											
										},
										..._SCHEDULED_PRESC_DETAIL
									],

						},

						{
							name: 'Usage',
							type: 'compound',
							parts: [
								{
									name: 'max_use',
									jpath: 'max_use'
								},
								{
									name: 'used',
									jpath: 'used'
								},
							],
							template : ({max_use,used,max_use_user}) => <React.Fragment>
											{used !== null && `${used||0} / ${max_use||'∞'}` || '∞'}
										</React.Fragment>
						},


					];
					
const _LIST_FLDS_ORG = [
						{
							name: 'Code',
							jpath: 'code',
						},
						
						{
							name: 'Recipient',
							jpath: 'users',
							template : ({value:users}) => <React.Fragment>
											{users?.email}
										</React.Fragment>
						},

						{
							name: 'Usage',
							type: 'compound',
							parts: [
								{
									name: 'max_use',
									jpath: 'max_use'
								},
								{
									name: 'used',
									jpath: 'used'
								},
							],
							template : ({max_use,used}) => <React.Fragment>
											{max_use && `${used||0}/${max_use}` || '∞'}
										</React.Fragment>
						},


					];

const _LIST_FLDS_DOC = [
	{
		name: 'Num',
		jpath: 'prnum',
	},
	{
		name: 'Type',
		jpath: 'type',
		template : ({value:type}) => <React.Fragment>
						{presc_model.TYPES[type]?.label || type}
					</React.Fragment>
	},
	{
		name: 'Issued / Expiry',
		type: 'multiline',
		lines: [
					{
						jpath: 'cre_tm',
						type:'time'
					},
					{type: 'br'},
					{
						jpath: 'exp_tm',
						type:'date'
					},
				],

	},
	{
		name: 'Patient',
		type: 'compound',
		parts: [
			{
				name: 'email',
				jpath: 'email'
			},
			{
				name: 'first_name',
				jpath: 'first_name'
			},
			{
				name: 'last_name',
				jpath: 'last_name'
			},
			{
				name: 'uid',
				jpath: 'uid'
			},
		],
		template : ({email, first_name, last_name, uid}) => <>
						{email}
						<br/>
						{first_name} {last_name}
					</>
	},
	{
		name: 'Med',
		path: 'med.nm',
		type: 'dict',
		func: d => app.app_data.ws_data?.cat_data?.pharm?.cat_avail_list?.[d]?.n || null,
	},

	{
		name: 'Details',
		type: 'multiline',
		lines: [
					{
						jpath: 'dets.msg',
						type: 'show_more',
						cut_length: 30,
					},
					{type: 'br'},
					{
						jpath: 'dets.note',
						type: 'show_more',
						cut_length: 30,
					},
					{type: 'br'},
					{
						jpath: 'dets.cost',
						// type:'date'
					},
					{type: 'br'},
					{
						show_if: { jpath: 'dets.results_cat' },
						type: 'compound',
						parts: [
							{
								name: 'results_cat',
								jpath: 'dets.results_cat'
							}
							
						],
						template: ({ results_cat }) => {
							return <>
								Follow up was <b>{results_cat === 'normal' ? 'Optional' : 'Insisted'}</b>
							</>
						}
						
					},
					..._SCHEDULED_PRESC_DETAIL
				],

	},

	{
		name: 'Usage',
		type: 'compound',
		parts: [
			{
				name: 'max_use',
				jpath: 'max_use'
			},
			{
				name: 'used',
				jpath: 'used'
			},
		],
		template : ({max_use,used,max_use_user}) => <React.Fragment>
						{used !== null && `${used||0} / ${max_use||'∞'}` || '∞'}
					</React.Fragment>
	},

];

const _LIST_FLDS_DOC_OUTSTANDING = _LIST_FLDS_DOC.concat(_DOC_FLD);

const _LIST_FLDS_UNACTIONED_FOLLOWUP = [
	{
		name: 'Num',
		jpath: 'prnum',
	},
	{
		name: 'Type',
		jpath: 'type',
		template : ({value:type}) => <React.Fragment>
						{presc_model.TYPES[type]?.label || type}
					</React.Fragment>
	},
	{
		name: 'Issued / Expiry',
		type: 'multiline',
		lines: [
					{
						jpath: 'cre_tm',
						type:'time'
					},
					{type: 'br'},
					{
						jpath: 'exp_tm',
						type:'date'
					},
				],

	},
	{
		name: 'Patient',
		type: 'compound',
		parts: [
			{
				name: 'email',
				jpath: 'email'
			},
			{
				name: 'first_name',
				jpath: 'first_name'
			},
			{
				name: 'last_name',
				jpath: 'last_name'
			},
			{
				name: 'mobile',
				jpath: 'mobile'
			},
			
		],
		template : ({email, first_name, last_name, mobile}) => <>
						<span className='font-semibold'>{first_name} {last_name}</span>
						<br/>
						<a href={`mailto:${email}`}>{email}</a>
						<br/>
						<a href={`tel:${mobile}`}>{mobile}</a>
					</>
	},
	{
		name: 'Med',
		path: 'med.mid',
		type: 'dict',
		func: d => app.app_data.ws_data?.cat_data?.pharm?.cat_avail_list?.[d]?.n || null,
	},

	{
		name: 'Details',
		type: 'multiline',
		lines: [
					{
						jpath: 'dets.msg',
						type: 'show_more',
						cut_length: 30,
					},
					{type: 'br'},
					{
						jpath: 'dets.note',
						type: 'show_more',
						cut_length: 30,
					},
					{type: 'br'},
					{
						jpath: 'dets.cost',
						// type:'date'
					},
					..._SCHEDULED_PRESC_DETAIL
				],

	},
	{
		name: 'Reminder History',
		type: 'compound',
		parts: [
			{
				name: 'reminder_hist',
				jpath: 'dets.reminder.hist'
			},
		],
		template : ({reminder_hist}) => {
		
		return <>
			{reminder_hist && reminder_hist.map(rec => {
			const {to, email_sent, sms_sent} = rec
			const rec_time = moment(rec.time).format("DD-MM-YYYY h:mm:ssa")
			
			if(email_sent) return <span>Email sent to {to} on {rec_time}<br/></span>
			if(sms_sent) return <span>SMS sent to {to} on {rec_time}<br/></span>
		})}
		</>
	}},

];		
const _LIST_FLDS_FOLLOWUP_LETTER = [
	{
		name: 'Num',
		jpath: 'prnum',
	},
	{
		name: 'Type',
		jpath: 'type',
		template : ({value:type}) => <React.Fragment>
						{presc_model.TYPES[type]?.label || type}
					</React.Fragment>
	},
	{
		name: 'Patient',
		type: 'compound',
		parts: [
			{
				name: 'email',
				jpath: 'email'
			},
			{
				name: 'first_name',
				jpath: 'first_name'
			},
			{
				name: 'last_name',
				jpath: 'last_name'
			},
			{
				name: 'mobile',
				jpath: 'mobile'
			},
			
		],
		template : ({email, first_name, last_name, mobile}) => <>
						<span className='font-semibold'>{first_name} {last_name}</span>
						<br/>
						<a href={`mailto:${email}`}>{email}</a>
						<br/>
						<a href={`tel:${mobile}`}>{mobile}</a>
					</>
	},
	{
		name: 'Reminder History',
		type: 'compound',
		parts: [
			{
				name: 'reminder_hist',
				jpath: 'dets.reminder.hist'
			},
		],
		template : ({reminder_hist}) => {
		
		return <>
			{reminder_hist && reminder_hist.map(rec => {
			const {to, email_sent, sms_sent} = rec
			const rec_time = moment(rec.time).format("DD-MM-YYYY h:mm:ssa")
			
			if(email_sent) return <span>Email sent to {to} on {rec_time}<br/></span>
			if(sms_sent) return <span>SMS sent to {to} on {rec_time}<br/></span>
		})}
		</>
	}},

];	

const _UNACTIONED_FOLLOWUP_ACT_BTNS = [
	{
	  n: 'Made Contact',
	  a: 'made_contact',
	  c: 'green',
	  i: 'phone',
	  cf: (r, row) => (
		<>
		  <p className="p-3 pb-0">
			Confirming <strong>you have made contact</strong> with the {row.first_name} {row.last_name} and informed them to book a follow up appointment?
		  </p>
		</>
	  ),
	},
	{
	  n: 'Attempted Contact',
	  a: 'attempted_contact',
	  c: 'orange',
	  i: 'bell slash',
	  cf: (r, row) => (
		<>
		  <p className="p-3 pb-0">
			Confirming you <strong>attempted</strong> to make contact with {row.first_name} {row.last_name}
		  </p>
		</>
	  ),
	},
];

const _FOLLOWUP_LETTER_ACT_BTNS = [
	{
	  n: 'View Letter',
	  c: 'blue',
	  u: (row) => API_service.API_BASE_URL+'viewScript?s='+row.sid+`${app.dev_env&&`&ad=_dev`||''}&format=patho_followup_letter`,
	  i: 'file',
	},
	{
	  n: 'Letter Sent',
	  a: 'letter_sent',
	  c: 'purple',
	  i: 'truck',
	  cust: 'stat_w_msg',
	  msg_req: true,
	  pl_hld: 'Tracking Details (Provider - Tracking Number)',
	},
];

const _PTS_CANCEL_PRESC = [
	{
		name: "reason",
		type: "select",
		options: [
			{ key: 'gp', value: "I'll discuss the results with my GP", text: "I'll discuss the results with my GP" },
			{ key: 'instructed', value: "I was instructed to follow up with my GP", text: 'I was instructed to follow up with my GP' },
			{ key: 'price', value: "The InstantScripts price is too high", text: 'The InstantScripts price is too high' },
			{ key: 'service', value: "I get better care from my GP", text: 'I get better care from my GP' },
			{ key: 'other', value: 'Other', text: 'Other' }
		],
		label: "Please provide a reason for cancelation",
	},
	{
		name: "confirm",
		type: "checkbox",
		options: [{
			c:'y',
			n:'I am aware that my doctor would like to discuss the results of my tests with me and not doing so may seriously impact my health, however I do not require a follow-up with InstantScripts at this time.'
		}],
	},
	
]

const _MODEL_NAME = 'prescs_model';

const _USE_DATA = 'API'
const _API_NAME = 'prescs'


// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class prescs_model extends db_lib {

	static get LIST_FLDS_ADMIN() { return _LIST_FLDS_ADMIN; }
	static get LIST_FLDS_ORG() { return _LIST_FLDS_ORG; }
	static get LIST_FLDS_DOC() { return _LIST_FLDS_DOC; }
	static get LIST_FLDS_DOC_OUTSTANDING() { return _LIST_FLDS_DOC_OUTSTANDING; }
	static get LIST_FLDS_UNACTIONED_FOLLOWUP() { return _LIST_FLDS_UNACTIONED_FOLLOWUP; }
	static get PTS_CANCEL_PRESC() { return _PTS_CANCEL_PRESC; }
	static get UNACTIONED_FOLLOWUP_ACT_BTNS() { return _UNACTIONED_FOLLOWUP_ACT_BTNS; }
	static get LIST_FLDS_FOLLOWUP_LETTER() { return _LIST_FLDS_FOLLOWUP_LETTER; }
	static get FOLLOWUP_LETTER_ACT_BTNS() { return _FOLLOWUP_LETTER_ACT_BTNS; }

	static get MODEL_NAME() { return _MODEL_NAME; }
	static get USE_DATA() { return _USE_DATA; }
	static get API_NAME() { return _API_NAME; }



	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	
	static async load_prescs (data) {

		console.log('prescs_model :: load_prescs sending ', data);

		return await prescs_model.get_record(null, null, {act:'prescs_list', ...data});


	}

	static async load_my_prescs (data) {
		console.log('prescs_model :: load_my_prescs sending ', data);
		return await prescs_model.get_record(null, null, {act:'my_prescs_list', ...data});
	}
	
	
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async delete_presc(prid) { 
			const res = await prescs_model.get_record(null, null, {act:'del', prid});
			return res
		}
		
			
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async get_presc_by_code(code) { 
			const res = await prescs_model.get_record(null, null, {act:'from_code', code});
			return res
		}
		
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async get_referral_code(create = false) { 
			const res = await prescs_model.get_record(null, null, {act:'referral_code', create});
			return res
		}
		
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------
		
		static async get_org_referral_code(target_email, create = false) { 
			const res = await prescs_model.get_record(null, null, {act:'org_referral_code', target_email, create});
			return res
		}
	
		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------

		static async save_presc( prid, d, ca ) {
			if (!d.conf)
				d.conf = {}
			let res
			if (prid) {
				res = await prescs_model.get_record(null, null, {act:'upd', prid, data:{...d}});
			} else {
				res = await prescs_model.get_record(null, null, { act:'add', data:{...d} } );
			}
			console.log({res});
			ca && ca(res)
		}

		// ---- --------------------------------------------  --------------------------------------------
		// ---- --------------------------------------------  --------------------------------------------

		static async save_presc_fld( prid, fl, vl, ca ) {
			let res
			if (prid && fl) {
				res = await prescs_model.get_record(null, null, {act:'upd_fld', prid, data:{fl, vl}});
			}
			console.log({res});
			ca && ca(res)
		}

		static async unactioned_followup_presc_list (data) {

			console.log('prescs_model :: unactioned_followup_presc_list sending ', data);
	
			return await prescs_model.get_record(null, null, {act:'unactioned_followup_presc_list', ...data});
	
	
		}
		static async unactioned_followup_letter_list (data) {
			console.log('prescs_model :: unactioned_followup_letter_list sending ', data);
			return await prescs_model.get_record(null, null, {act:'unactioned_followup_letter_list', ...data});
	
	
		}
}
