import { useEffect } from 'react';

export default function useOnSettingsButtonClick(element, id, cb) {

  useEffect(() => {
		const target = element.querySelector(`#${id}`);
		target.addEventListener('click', cb);

		return () => {
			target.removeEventListener('click', cb);
		};
	}, []);

}