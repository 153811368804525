import React from 'react'

import db_lib from 'xAppLib/libs/db_lib'
import { isInRange }  from 'xAppLib/Results/util'

import { Icon } from 'semantic-ui-react'
import moment from 'moment'

const _LIST_FLDS_ADMIN = [
	
	

						{
							name: 'Date',
							jpath: 'cre_tm',
							type: 'time',
						},
						{
							name: 'Record',
							type: 'multiline',
							lines: [
										{
											name: 'rnum',
											type: 'label',
										},
										{
											name: 'rnum',
											jpath: 'rnum',
										},
										{type: 'br'},
										{
											name: 'rid',
											label: 'rid',
											jpath: 'rid',
											type: 'show_more',
										},
										{type: 'br'},
										{
											name: 'File',
											label: 'File',
											jpath: 'file_name',
											type: 'show_more',
											//cut_length: 25,
										},
										{type: 'br'},
										{
											name: 'Type',
											label: 'Type',
											jpath: 'type',
											type: 'show_more',
										},
										{type: 'br'},
										{
											name: 'Origin',
											label: 'Origin',
											jpath: 'origin',
											type: 'show_more',
										},
									]
						},

						{
							name: 'Links',
							type: 'multiline',
							lines: [
										{
											jpath: 'sid',
											template : ({value:result}) => 
											{
												const link = `https://a.iscr.com.au/scripts/list/add_results?srch=${result}`
												return <a href={link} target='_blank'><b>Script ID: </b></a>
											}
										},
										{
											//name: 'Script ID',
											jpath: 'sid',
											type: 'show_more',
										},
										{type: 'br'},
										{
											jpath: 'uid',
											template : ({value:result}) => 
											{
												const link = `https://a.iscr.com.au/patient/uid/${result}`
												return <a href={link} target='_blank'><b>User ID: </b></a>
											}
										},
										{
											//name: 'User ID',
											jpath: 'uid',
											type: 'show_more',
										},
										{type: 'br'},
										{
											jpath: 'uiding',
											template : ({value:result}) => 
											{
												const link = `https://a.iscr.com.au/patient/ui/${result}`
												return <a href={link} target='_blank'><b>UIDing: </b></a>
											}
										},
										{
											//name: 'User ID',
											jpath: 'uiding',
											type: 'show_more',
										},
									]
						},

						{
							name: 'Processing',
							type: 'multiline',
							lines: [
										{type: 'br'}, // Doesnt quite align without this ... ???
										{
											jpath: 'sid_link_res',
											template : ({value:result}) => 
											{
												const result_ok = result && result.res == 'ok'
												return <Icon name={result_ok && 'check circle outline' || 'x'} color={result_ok&&'green'||'red'} size='large'/>
											}
										},
										{
											jpath: 'sid_link_res',
											type: 'popup_json_obj',
										},
										{
											name: 'Script link',
											type: 'label',
										},

										{type: 'br'},
										{
											jpath: 'obs_link_res',
											template : ({value:result}) => 
											{
												const result_ok = result && result.res == 'ok'
												return <Icon name={result_ok && 'check circle outline' || 'x'} color={result_ok&&'green'||'red'} size='large'/>
											}
										},
										{
											jpath: 'obs_link_res',
											type: 'popup_json_obj',
										},
										{
											name: 'Obs extraction',
											type: 'label',
										},

										{type: 'br'},
										{
											jpath: '',
											template : ({value:result}) => 
											{
												const result_proc_res = result && result.proc_res && result.proc_res.res == 'ok'

												let result_obs = false
												if (result && result.obs && Array.isArray(result.obs) && result.obs.length > 0){
													let all_obs_ok = true
													for(let i = 0; i < result.obs.length; i++){
														const obs = result.obs[i]
														if (!(obs && obs.patho_res && obs.patho_res.res == 'ok')){
															all_obs_ok = false
															break
														}
													}
													result_obs = all_obs_ok
												}
												
												return <Icon name={(result_proc_res || result_obs) && 'check circle outline' || 'x'} color={result_proc_res&&'green'||result_obs&&'yellow' ||'red'} size='large'/>
											}
										},
										{
											jpath: 'proc_res',
											type: 'popup_json_obj',
										},
										{
											name: 'Obs processing',
											type: 'label',
										},

									]
						},

						{
							name: 'Script params',
							type: 'multiline',
							lines: [

								{ type: 'br' }, // Needed for alignment
								{
									jpath: 'scr_status',
									template: ({ value: result }) => {
										const good_status = ['patho_res']
										const result_ok = good_status.includes(result)
										return <Icon name={result_ok && 'check circle outline' || 'x'} color={result_ok && 'green' || 'red'} size='large' />
									}
								},
								{
									jpath: 'scr_status',
									type: 'popup_json_obj',
								},
								{
									name: 'Script status',
									type: 'label',
								},

								{ type: 'br' },
								{
									jpath: 'prescs_data',
									template: ({ value: result }) => {
										const result_ok = !!result
										return <Icon name={result_ok && 'check circle outline' || 'x'} color={result_ok && 'green' || 'red'} size='large' />
									}
								},
								{
									jpath: 'prescs_data',
									type: 'popup_json_obj',
								},
								{
									name: 'Followup book',
									type: 'label',
								},

								{ type: 'br' },
								{
									jpath: '', //'scr_old_incomplete_handled',
									template: ({ value: result }) => {

										const {scr_old_incomplete_handled, cre_tm, scr_status} = result

										const good_status = ['patho_res', 'approved']
										const result_ok = good_status.includes(scr_status)

										const need_followup = !result_ok && (moment().diff(moment(cre_tm), 'days') > 7)
										const has_followup = scr_old_incomplete_handled && scr_old_incomplete_handled.res == 'ok'
										
										return <Icon name={has_followup && 'check circle outline' || need_followup && 'x' || 'circle outline'} color={has_followup && 'green' || need_followup && 'red' || 'orange'} size='large' />
									}
								},
								{
									jpath: 'scr_old_incomplete_handled',
									type: 'popup_json_obj',
								},
								{
									name: 'Followup email',
									type: 'label',
								},

								{ type: 'br' },
								{
									jpath: 'scr_patho_release_rdy',
									template: ({ value: result }) => {

										return <Icon name={result && result.res == 'ok' && 'check circle outline' || 'x' } color={result && result.res == 'ok' && 'green' || 'red'} size='large' />
									}
								},
								{
									jpath: 'scr_patho_release_rdy',
									type: 'popup_json_obj',
								},
								{
									name: 'Release ready',
									type: 'label',
								},

								// { type: 'br' },
								// {
								// 	jpath: 'scr_auto_patho_compare',
								// 	template: ({ value: result }) => {

								// 		return <Icon name={result && (result.res == 'ok' && result.match && 'check circle outline' || 'x') ||  'circle outline'} color={result && (result.res == 'ok' && result.match && 'green' || 'red') || 'orange'} size='large' />
								// 	}
								// },
								// {
								// 	jpath: 'scr_auto_patho_compare',
								// 	type: 'popup_json_obj',
								// },
								// {
								// 	name: 'Auto/Manual match',
								// 	type: 'label',
								// },

							]
						},


						{
							name: 'HL7 Data',
							type: 'multiline',
							lines: [
										{
											name: 'Raw',
											label: 'Raw',
											jpath: 'data',
											type: 'popup',
										},
										{type: 'br'},
										{
											name: 'JSON',
											label: 'JSON',
											jpath: 'data_json',
											type: 'popup_json_obj',
										},
									]
						},	
						
						{
							name: 'Details',
							type: 'multiline',
							lines: [
										{
											name: 'first_name',
											jpath: 'data_json.patient.first_name'
										},
										{
											name: 'last_name',
											jpath: 'data_json.patient.last_name'
										},
										{type: 'br'},
										{
											name: 'dob',
											jpath: 'data_json.patient.dob'
										},
										{type: 'br'},
										{
											name: 'medicare',
											jpath: 'data_json.patient.medicare'
										},
										{type: 'br'},
										{
											name: 'street',
											jpath: 'data_json.patient.street'
										},
										{type: 'br'},
										{
											name: 'ord_prov_p',
											jpath: 'data_json.request.ord_prov_p'
										},
										{
											name: 'ord_prov_g_name',
											jpath: 'data_json.request.ord_prov_g_name'
										},
										{
											name: 'ord_prov_f_name',
											jpath: 'data_json.request.ord_prov_f_name'
										},
										{type: 'br'},
										{
											name: 'sid',
											jpath: 'data_json.request.sid'
										},
										{type: 'br'},
										{
											name: 'sn',
											jpath: 'data_json.request.sn'
										},
										{type: 'br'},
										{
											name: 'req_time',
											jpath: 'data_json.request.req_time'
										},
										{type: 'br'},
										{
											name: 'sid_link_res',
											jpath: 'sid_link_res.err',
											type: 'b'
										},
										{type: 'br'},
										{
											name: 'lab',
											jpath: 'data_json.header.send_fac',
										}
									]
						},	
						
						{
							name: 'Observations',
							type: 'multiline',
							lines: [
								{
									jpath: 'data_json.observations',
									template : ({ value: result }) => 
									{
										const obs_all = []
										for(let i = 0; i < result.length; i++) {
											const obs = result[i].obs_id.join(', ').substr(0, 80)
											const val_concat = (Array.isArray(result[i].value) && Array.isArray(result[i].value[0]))
												? result[i].value[0].join(', ')
												: result[i].value[0]
											const val = (val_concat !== undefined)
												? (val_concat.toLowerCase() === 'positive')
														? 'POSITIVE'
														: val_concat.substr(0, 50)
												: ''
											const inRange = isInRange (result[i].value[0], result[i].ref_range)
											obs_all.push(
												<>
													<span>{obs} </span>
													<strong style={(val === 'POSITIVE') ? { color: "red" } : {}}> {val} </strong>
													{(result[i].units !== '') &&
														<span> {result[i].units} </span>
													}
													{(result[i].ref_range !== '') &&
														<span style={{ color: "grey" }}> ({result[i].ref_range}) </span>
													}
													<strong style={{ color: "red" }}> {inRange.toUpperCase()} </strong>
												</>
											)
										}
										const obs_out = obs_all.map((item, i) => <li key={i}>{item}</li>)
										return <ul className="text-sm">{obs_out}</ul>
									}
								}
							]
						},
						
					]
					

const _MODEL_NAME = 'results_model'

const _USE_DATA = 'API'
const _API_NAME = 'results'

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class results_model extends db_lib {

	static get LIST_FLDS_ADMIN() { return _LIST_FLDS_ADMIN }
	static get MODEL_NAME() { return _MODEL_NAME }
	static get USE_DATA() { return _USE_DATA }
	static get API_NAME() { return _API_NAME }

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async load_results (data) {

		console.log('results_model :: load_results sending ', data)

		return await this.get_record(null, null, {act:'results_list', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async run_processing (data) {

		console.log('results_model :: run_processing sending ', data)

		return await this.get_record(null, null, {act:'run_proc', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async manual_sid (data) {

		console.log('results_model :: manual_sid sending ', data)

		return await this.get_record(null, null, {act:'man_sid', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async remove_obs (data) {

		console.log('results_model :: remove_obs sending ', data)

		return await this.get_record(null, null, {act:'rem_obs', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async remove_sid (data) {

		console.log('results_model :: remove_sid sending ', data)

		return await this.get_record(null, null, {act:'rem_sid', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async remove_rid (data) {

		console.log('results_model :: remove_rid sending ', data)

		return await this.get_record(null, null, {act:'rem_rid', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async accumulate_patho (data) {

		console.log('results_model :: accumulate_patho sending ', data)

		return await this.get_record(null, null, {act:'acc_path', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async accumulate_patho_test (data) {

		console.log('results_model :: accumulate_patho_test sending ', data)

		return await this.get_record(null, null, {act:'acc_path_test', ...data})
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async notify_patho (data) {

		console.log('results_model :: notify_patho sending ', data)

		return await this.get_record(null, null, {act:'notify_path', ...data})

	}

	// ----	--------------------------------------------	--------------------------------------------

	static async get_raw_results_by_sid (data) {

		console.log('results_model :: get_raw_results_by_sid sending ', data)

		return await this.get_record(null, null, { act: 'get_raw_results_by_sid', ...data })
	}
	
}
