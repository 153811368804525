import React from "react";
import {
    Grid,
    FormField,
    List,
    ListItem,
    Button,
    Container,
} from "semantic-ui-react";

export const MULTI_LIST_INIT = {
    titleOne: "Title One",
    textOne:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm",
    dataOne: ["item 1", "item 2", "item 3", "item 4"],
    titleTwo: "Title Two",
    textTwo:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm",
    dataTwo: ["item 5", "item 6", "item 7", "item 8"],
    titleThree: "Title Three",
    textThree:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm",
    dataThree: ["item 9", "item 10", "item 11", "item 12"],
};

const cols = ["One", "Two", "Three"];

const MultiList = (props) => {
    const clear = (key) => {
        document.getElementsByName(`title${key}`)[0].value = "";
        document.getElementsByName(`text${key}`)[0].value = "";
        document.getElementsByName(`data${key}`)[0].value = [];
    };

    if (props.editMode)
        return (
            <>
                {cols.map((key) => (
                    <Container key={key} className="mb-4">
                        <h3 className="section-title font-bold float-left">
                            List {key}
                        </h3>
                        <Button
                            className="float-right"
                            onClick={() => clear(key)}
                        >
                            Clear
                        </Button>
                        <FormField>
                            <label>Title</label>
                            <input
                                name={`title${key}`}
                                placeholder="Enter Title"
                                defaultValue={props[`title${key}`]}
                            />
                        </FormField>
                        <FormField>
                            <label>Description</label>
                            <input
                                name={`text${key}`}
                                placeholder="Enter description"
                                defaultValue={props[`text${key}`]}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Data - enter list as JSON, IE: ["item 1","item
                                2","item 3","item 4"]
                            </label>
                            <input
                                name={`data${key}`}
                                placeholder="Enter list as JSON"
                                defaultValue={JSON.stringify(
                                    props[`data${key}`]
                                )}
                            />
                        </FormField>
                    </Container>
                ))}
            </>
        );

    const activeCols = cols.filter(
        (col) =>
            props[`title${col}`] ||
            props[`text${col}`] ||
            props[`data${col}`].length
    );

    return (
        <Grid>
            <Grid.Row>
                {activeCols.map((col) => (
                    <React.Fragment key={col}>
                        <Grid.Column
                            className="computer only"
                            key={col}
                            width={Math.floor(16 / activeCols.length)}
                        >
                            <h3 className="section-title font-bold">
                                {props[`title${col}`]}
                            </h3>
                        </Grid.Column>
                        <Grid.Column className="mobile only" width={16}>
                            <h3 className="section-title font-bold">
                                {props[`title${col}`]}
                            </h3>
                            <p>{props[`text${col}`]}</p>
                            <Container className="px-2">
                                <List bulleted>
                                    {props[`data${col}`]?.map((item, index) => (
                                        <ListItem key={index}>{item}</ListItem>
                                    ))}
                                </List>
                            </Container>
                            <br/>
                        </Grid.Column>
                    </React.Fragment>
                ))}
            </Grid.Row>
            <Grid.Row className="large screen only">
                {activeCols.map((col) => (
                    <Grid.Column
                        key={col}
                        width={Math.floor(16 / activeCols.length)}
                    >
                        <p>{props[`text${col}`]}</p>
                    </Grid.Column>
                ))}
            </Grid.Row>
            <Grid.Row className="large screen only">
                {activeCols.map((col) => (
                    <Grid.Column
                        key={col}
                        width={Math.floor(16 / activeCols.length)}
                    >
                        <Container className="px-2">
                            <List bulleted>
                                {props[`data${col}`]?.map((item, index) => (
                                    <ListItem key={index}>{item}</ListItem>
                                ))}
                            </List>
                        </Container>
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    );
};

export default MultiList;
