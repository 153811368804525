import React from 'react';

import { Form, Icon } from 'semantic-ui-react';
import { ChroCond_keysToLabels } from 'xAppLib/UIelems/ChronicConditionsModal';

const ChroCondField = ({ edit, uf_this, values , valids }) => {
	const handleOpenModal = () => {
		app.trigger(app.events.SHOW_POPUP, {
			txt: 'Select Your Chronic Conditions',
			pn: 'chro-cond',
			noCloseBtn: true,
			cst_modal: true,
			uf_this,
			chroValues: values.chro_cond ?? []
		})
	};

	const conditions = <p className="text grey">{values?.chro_cond?.length > 0 ? ChroCond_keysToLabels(values.chro_cond).join(', ') : 'None provided'}</p>;

	const isValid = edit && valids?.chro_cond !== undefined && !valids?.chro_cond && {
		content: 'Field is missing',
		pointing: 'below',
	};

	return (
		<Form.Field control={Form} error={isValid}>
			<label>Chronic Conditions</label>
			{edit && (
				<div className="flex items-center gap-2 cursor-pointer" onClick={handleOpenModal}>
					<Icon size="small" name="edit" />
					{conditions}
				</div>
			)}

			{!edit && conditions}
		</Form.Field>
	);
};

export default ChroCondField;
