

export default { 
    stp: [
        {
            text: 'Dosing Point',
            value: 'DosingPoint',
        },
        {
            text: 'Staged Supply',
            value: 'StagedSupply',
        },
        {
            text: 'Repeat On File',
            value: 'RepeatOnFile',
        }
    ]
}